import { clone, concat, map, compact } from 'lodash';

/**
 * @function optionContext
 * @param {object} formDefinition
 * @param {array} options
 * @return {object}
 *
 * @description builds options for an option/select input with options passed as params
 */
export const optionContext = function optionContext(
  formDefinition,
  options = []
) {
  const context = clone(formDefinition);

  const placeholder = clone(context.options[0]);
  if (options.length === 0 && formDefinition.emptyPlaceholder && placeholder) {
    placeholder.linkName = formDefinition.emptyPlaceholder;
  }

  const tmpOptions = compact(
    concat(
      placeholder,
      map(options, (option) => ({
        linkName: option.name,
        slug: option.id.toString(),
      }))
    )
  );

  context.options = tmpOptions;

  return context;
};
