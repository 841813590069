import { defineMessages } from 'react-intl';

export default defineMessages({
  createSuccess: {
    id: 'app.containers.CompanyNotes.createSuccess',
    defaultMessage: 'Note created',
  },
  updateSuccess: {
    id: 'app.containers.CompanyNotes.updateSuccess',
    defaultMessage: 'Note updated',
  },
  deleteSuccess: {
    id: 'app.containers.CompanyNotes.deleteSuccess',
    defaultMessage: 'Note deleted',
  },
});
