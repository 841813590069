import { isEmpty } from 'lodash';

export const getMetaContent = (selector) => {
  const meta = document.querySelector(`meta[${selector}]`);
  const content = meta ? meta.getAttribute('content') : null;

  return isEmpty(content) ? null : content;
};

export const processHtmlContent = (html) => {
  if (!html) return '';
  const trimmedHTML = trimEmptyParagraphs(html);

  let index = trimmedHTML.length - 1;

  while (index >= 0) {
    const char = trimmedHTML[index];

    if (char === '>') {
      return trimmedHTML;
    }
    if (char === '<') {
      return trimmedHTML.substring(0, index);
    }

    index -= 1;
  }

  return trimmedHTML;
};

export const removeWrapperTags = (content) =>
  content.replace(/^<p>/i, '').replace(/<\/p>$/i, '');

export const trimEmptyParagraphs = (content) =>
  content
    .replace(/(^|<p>)\s*(<br>)?\s*(<\/p>|$)/g, '')
    .replace(/```/g, '')
    .replace(/html/gi, '');
