export const SUPPLIER_HUB_FETCH_INDUSTRIES_REQUEST =
  'gustav/supplierHub/industries/FETCH_REQUEST';
export const SUPPLIER_HUB_FETCH_INDUSTRIES_SUCCESS =
  'gustav/supplierHub/industries/FETCH_SUCCESS';
export const SUPPLIER_HUB_FETCH_INDUSTRIES_ERROR =
  'gustav/supplierHub/industries/FETCH_ERROR';

export const SUPPLIER_HUB_FETCH_SPECIALIZATIONS_REQUEST =
  'gustav/supplierHub/specializations/FETCH_REQUEST';
export const SUPPLIER_HUB_FETCH_SPECIALIZATIONS_SUCCESS =
  'gustav/supplierHub/specializations/FETCH_SUCCESS';
export const SUPPLIER_HUB_FETCH_SPECIALIZATIONS_ERROR =
  'gustav/supplierHub/specializations/FETCH_ERROR';

export const SUPPLIER_HUB_FETCH_SUPPLIERS_REQUEST =
  'gustav/supplierHub/suppliers/FETCH_REQUEST';
export const SUPPLIER_HUB_FETCH_SUPPLIERS_SUCCESS =
  'gustav/supplierHub/suppliers/FETCH_SUCCESS';
export const SUPPLIER_HUB_FETCH_SUPPLIERS_ERROR =
  'gustav/supplierHub/suppliers/FETCH_ERROR';

export const SUPPLIER_HUB_RESET_SUPPLIERS =
  'gustav/supplierHub/suppliers/RESET';

export const SUPPLIER_HUB_CHANGE_RESULTS_VISIBILITY =
  'gustav/supplierHub/results/CHANGE_VISIBILITY';
export const SUPPLIER_HUB_CHOOSE_SUPPLIER =
  'gustav/supplierHub/supplier/CHOOSE';

export const SUPPLIER_HUB_CHANGE_SEARCH_CONDITIONS =
  'gustav/supplierHub/searchConditions/CHANGE';
export const SUPPLIER_HUB_CHANGE_LOCATION =
  'gustav/supplierHub/location/CHANGE';
export const SUPPLIER_HUB_RESET_SEARCH_CONDITIONS =
  'gustav/supplierHub/searchConditions/RESET';

export const SUPPLIER_HUB_CHANGE_MAP_PARAMS =
  'gustav/supplierHub/map/CHANGE_PARAMS';

export const SUPPLIER_HUB_CHANGE_RESULTS_LOADED =
  'gustav/supplierHub/results/CHANGE_LOADED';
