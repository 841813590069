import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/elements/icon';
import { Colors } from 'components/utils/styles/ui';

import * as styled from '../styles';
import i18n from '../utils/i18n';

const ButtonFn = ({ onClick, disabled }) => (
  <styled.Button
    color="teal"
    size="small"
    disabled={disabled}
    onClick={onClick}
  >
    <Icon icon="UploadSimple" />
    <FormattedMessage {...i18n.buttonSelect} />
  </styled.Button>
);

export const UploadOptionButtonDropdown = (props) => {
  const { disabled, openSelectFile, openBullhornModal, dropdownRef } = props;

  const menuItems = [
    {
      text: i18n.uploadFile,
      description: i18n.uploadFileDescription,
      onClick: (event) => !disabled && openSelectFile(event),
      iconComponent: (
        <styled.DropdownIconWrapper color="tealDark">
          <Icon icon="UploadSimple" color="white" size="larger" />
        </styled.DropdownIconWrapper>
      ),
    },
    {
      text: i18n.importFromBullhorn,
      description: i18n.importFromBullhornDescription,
      onClick: openBullhornModal,
      iconComponent: (
        <styled.DropdownIconWrapper color="bhOrange">
          <Icon icon="Bullhorn" color="white" size="larger" />
        </styled.DropdownIconWrapper>
      ),
    },
  ];

  return (
    <>
      <styled.ButtonDropdownMenu
        dropdownRef={dropdownRef}
        items={menuItems}
        buttonFn={ButtonFn}
        position="top-left"
        size="medium"
        listCss="width: 38.2rem;"
        borderColor={Colors.inputOutline}
      />
    </>
  );
};

ButtonFn.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

UploadOptionButtonDropdown.propTypes = {
  disabled: PropTypes.bool,
  openSelectFile: PropTypes.func,
  openBullhornModal: PropTypes.func,
  dropdownRef: PropTypes.object,
};
