import { defineMessages } from 'react-intl';

export default defineMessages({
  titleSelectIcon: {
    id: 'components.IconPicker.titleSelectIcon',
    defaultMessage: 'Select icon',
  },
  placeholder: {
    id: 'components.IconPicker.placeholder',
    defaultMessage: 'Search...',
  },
  noResults: {
    id: 'components.IconPicker.noResults',
    defaultMessage: 'No icons found',
  },
});
