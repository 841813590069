import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { isEmpty, compact, map, isFunction } from 'lodash';

import { Emoji } from 'components/elements/emoji';
import { anyPropertyInvalid } from 'components/utils/form-utils';
import { objectPropsToSnakeCase } from 'components/utils/object';
import { KEYS } from 'components/utils/keys';
import { AnimatedText } from 'components/text/AnimatedText';
import { acceptedMimeTypes } from 'utils/fileFunctions';

import * as styled from './styles';
import i18n from './utils/i18n';

/* eslint no-underscore-dangle: 0 */
export class DropzoneUpload extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.initialState = {
      error: null,
      files: props.files || [props.file] || [],
      name: '',
      shouldValidate: false,
      data: props.dataEditObject,
      loading: false,
    };
    this.state = {
      ...this.initialState,
      files: compact([...props.files, props.file]),
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { showSubmit, multiple, acceptedFormats, nameChange } = this.props;

    if (!multiple && acceptedFiles.length + rejectedFiles.length > 1) {
      this.setState({ error: 'multiple', files: [] });
      return;
    }

    if (rejectedFiles.length > 0) {
      const error = acceptedFormats.length > 3 ? 'invalidLong' : 'invalidShort';
      this.setState({ error, files: [] });
      return;
    }

    this.setState({ error: null, name: '', files: acceptedFiles }, () => {
      if (!showSubmit && !nameChange) {
        this.onSubmit();
      }
    });
  };

  onSubmit = () => {
    const { nameChange, multiple, clearOnSuccess, onChange } = this.props;
    const { files, name: stateName, data: stateData } = this.state;

    this.setState({ shouldValidate: true });

    if (this.shouldSubmit()) {
      this.setState({ loading: true });

      const name = nameChange ? stateName : files[0].name;
      const data = objectPropsToSnakeCase(stateData);
      const params = multiple
        ? { files, data }
        : { file: files[0], name, data };
      const result = onChange(params);

      if (result instanceof Promise) {
        result
          .then(() => {
            if (this._isMounted) {
              this.setState(
                clearOnSuccess
                  ? this.initialState
                  : { shouldValidate: false, loading: false }
              );
            }
          })
          .catch(() => this.setState(this.initialState));
      }
    }
  };

  onReset = (event) => {
    const { showSubmit, multiple, onChange, onReset } = this.props;

    event.stopPropagation();
    this.setState(this.initialState);
    if (!showSubmit) {
      onChange(multiple ? { files: [] } : { file: null });
    }
    if (isFunction(onReset)) {
      onReset();
    }
  };

  onKeyPress = (event) => {
    if (event.nativeEvent.keyCode === KEYS.ENTER) {
      event.preventDefault();
      this.onSubmit();
    }
  };

  shouldSubmit() {
    const { dataFormDefinition, nameChange } = this.props;
    const { files, name, data } = this.state;

    if (isEmpty(files)) {
      return false;
    }
    if (nameChange && isEmpty(name)) {
      return false;
    }
    if (anyPropertyInvalid(data, dataFormDefinition)) {
      return false;
    }

    return true;
  }

  statusMessage() {
    const { placeholderText, selectedText } = this.props;
    const { files, error, loading } = this.state;

    if (loading) {
      return <FormattedMessage {...i18n.uploading} />;
    }

    if (isEmpty(files)) {
      if (isEmpty(error)) {
        return (
          <styled.Placeholder>
            <FormattedMessage {...(placeholderText || i18n.chooseFile)} />
          </styled.Placeholder>
        );
      }
      return this.errorMessage();
    }

    return (
      <span>
        <FormattedMessage {...(selectedText || i18n.fileSelected)} />{' '}
        <Emoji name="banzai" />
      </span>
    );
  }

  errorMessage() {
    const { acceptedFormats, maxFileSize } = this.props;
    const { error } = this.state;
    const values = {
      accepted: acceptedFormats.join(', '),
      maxFileSize: maxFileSize / (1024 * 1024),
    };

    return (
      <span>
        <FormattedMessage {...i18n[`${error}Error`]} values={values} />{' '}
        <Emoji name="exploding-head" />
      </span>
    );
  }

  closeButton() {
    return (
      <styled.CloseButton type="blank" onClick={this.onReset}>
        <styled.Icon icon="X" />
      </styled.CloseButton>
    );
  }

  nameChangeForm() {
    const { name, shouldValidate } = this.state;
    const { size, nameChangeAsterisk } = this.props;
    const {
      giveName,
      giveName: { defaultMessage },
    } = i18n;

    return (
      <styled.InputContainer
        size={size}
        invalid={shouldValidate && isEmpty(name)}
      >
        <styled.Input
          size={size}
          inputType="text"
          value={name}
          placeholder={{
            ...giveName,
            defaultMessage: nameChangeAsterisk
              ? `${defaultMessage} *`
              : defaultMessage,
          }}
          onKeyPress={this.onKeyPress}
          onChange={(val) => this.setState({ name: val })}
        />
        {this.submitButton()}
      </styled.InputContainer>
    );
  }

  dataForm() {
    const { dataFormDefinition } = this.props;
    const { data, shouldValidate } = this.state;

    return (
      <styled.FormContainer>
        {map(dataFormDefinition, (def, attr) => (
          <styled.Fieldset
            key={attr}
            fullWidth
            context={def}
            inputValue={data[attr]}
            shouldValidate={shouldValidate}
            fn={(val) => this.setState({ data: { ...data, [attr]: val } })}
          />
        ))}
      </styled.FormContainer>
    );
  }

  submitButton() {
    const { isCompact, size } = this.props;

    return (
      <styled.Button compact={isCompact} size={size} onClick={this.onSubmit}>
        <FormattedMessage {...i18n.upload} />
      </styled.Button>
    );
  }

  render() {
    const {
      className,
      acceptedFormats,
      maxFileSize,
      nameChange,
      isCompact,
      showSubmit,
      hideCloseBtn,
      size,
      dataFormDefinition,
      emptyImage,
      required,
      shouldValidate,
      loadingTexts,
      file,
      files: propFiles,
    } = this.props;
    const { files, error, loading } = this.state;

    const showError =
      !isEmpty(error) ||
      (shouldValidate && required && isEmpty(file || propFiles));
    const showClose =
      !hideCloseBtn && !isEmpty(files) && (!loading || showError);
    const showDataForm =
      !isEmpty(dataFormDefinition) && !isEmpty(files) && !loading;
    const showNameChange = !isEmpty(files) && nameChange && !loading;
    const showFilename = !isEmpty(files) && !nameChange && !loading;
    const showUploadIcon = isEmpty(files) && !loading;
    const showSubmitButton =
      showSubmit && !isEmpty(files) && !nameChange && !loading;

    return (
      <styled.Wrapper
        className={className}
        hasError={showError}
        hasFile={!isEmpty(files)}
      >
        <Dropzone
          onDrop={this.onDrop}
          accept={acceptedMimeTypes(acceptedFormats)}
          maxSize={maxFileSize}
          disabled={!isEmpty(files)}
          compact={isCompact ? 1 : 0}
          dataform={showDataForm ? 1 : 0}
          size={size}
        >
          {({ getRootProps, getInputProps }) => (
            <styled.DropzoneBox {...getRootProps()}>
              {showClose && this.closeButton()}

              <styled.MessagesWrapper dataform={showDataForm}>
                {showUploadIcon &&
                  (emptyImage ? (
                    <styled.Image src={emptyImage} />
                  ) : (
                    <styled.Icon icon="DownloadSimple" compact={isCompact} />
                  ))}
                <styled.Text>{this.statusMessage()}</styled.Text>
                {showFilename &&
                  files.map(({ name }) => (
                    <styled.Filename key={`filename-${name}`}>
                      {name}
                    </styled.Filename>
                  ))}
              </styled.MessagesWrapper>

              {loading && (
                <styled.Loading color="white" incrementalFactor={2} />
              )}
              {loading && loadingTexts.length > 0 && (
                <styled.LoadingText>
                  <AnimatedText texts={loadingTexts} />
                </styled.LoadingText>
              )}
              {showDataForm && this.dataForm()}
              {showNameChange && this.nameChangeForm()}
              {showSubmitButton && this.submitButton()}

              <input {...getInputProps()} />
            </styled.DropzoneBox>
          )}
        </Dropzone>
      </styled.Wrapper>
    );
  }
}

DropzoneUpload.defaultProps = {
  acceptedFormats: ['pdf', 'doc', 'image'],
  maxFileSize: 5 * 1024 * 1024,
  isCompact: false,
  size: 'normal',
  nameChange: true,
  showSubmit: true,
  hideCloseBtn: false,
  dataFormDefinition: {},
  dataEditObject: {},
  file: null,
  files: [],
  emptyImage: null,
  required: false,
  shouldValidate: false,
  loadingTexts: [],
  multiple: false,
  clearOnSuccess: true,
};

DropzoneUpload.propTypes = {
  className: PropTypes.string,
  acceptedFormats: PropTypes.array,
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  isCompact: PropTypes.bool,
  size: PropTypes.string,
  nameChange: PropTypes.bool,
  showSubmit: PropTypes.bool,
  hideCloseBtn: PropTypes.bool,
  dataFormDefinition: PropTypes.object,
  dataEditObject: PropTypes.object,
  file: PropTypes.object,
  files: PropTypes.array,
  emptyImage: PropTypes.string,
  required: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  loadingTexts: PropTypes.arrayOf(PropTypes.string),
  placeholderText: PropTypes.object,
  selectedText: PropTypes.object,
  multiple: PropTypes.bool,
  nameChangeAsterisk: PropTypes.bool,
  clearOnSuccess: PropTypes.bool,
};
