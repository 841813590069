import styled from 'styled-components';
import { isArray } from 'lodash';

type EmojiProp = {
  code: string | string[];
};

export const Emoji = styled.span<EmojiProp>`
  &::before {
    font-family: 'Apple Color Emoji', 'Noto Color Emoji';
    content: ${({ code }) =>
      isArray(code)
        ? `"${code.map((c) => `\\${c}`).join(' ')}"`
        : `"\0${code}"`};
  }
`;
