import { get, isEmpty } from 'lodash';

import { Colors } from 'components/utils/styles/ui';

const DefaultSettings = {
  list_settings: {
    button_primary_color: Colors.tealDark,
    button_primary_outline_color: Colors.tealDark,
    button_primary_text_color: Colors.white,
    button_secondary_color: Colors.tealLighter,
    button_secondary_outline_color: Colors.outline,
    button_secondary_text_color: Colors.text,
  },
  candidate_header_image: null,
  candidate_footer_image: null,
  candidate_settings: {
    layout: 'cards',
    candidate_avatar: 'no',
    company_logo: 'yes',
    field_icon: 'yes',
    experience_field_icon: 'yes',
    text_color: Colors.text,
    primary_name_color: Colors.tealDark,
    secondary_name_color: Colors.textLight,
    section_name_color: Colors.text,
    experience_field_title_color: Colors.text,
    field_title_color: Colors.textLight,
    bulletpoint_color: Colors.text,
    highlight_color: Colors.tealLight,
    section_divider_color: Colors.outline,
    experience_field_subtitle_divider_color: Colors.outline,
    label_background_color: Colors.tealLightest,
    label_border_color: Colors.outline,
    label_text_color: Colors.text,
    field_icon_color: Colors.textLight,
    field_icon_background_color: Colors.labelBg,
    experience_field_icon_color: Colors.white,
    experience_field_icon_background_color: Colors.tealDarker,
    start_end_date_format: 'shortened',
    show_duration: 'yes',
  },
};

export const getThemeSetting = (theme, setting, fallback = true) =>
  get(theme || (fallback ? DefaultSettings : null), setting);

export const showAsset = (theme, setting, asset) => {
  const value = getThemeSetting(theme, setting);

  return value === 'yes' || (value === 'uploaded' && !isEmpty(asset));
};
