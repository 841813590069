/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';

import { ButtonAskAI } from './buttonAskAI';
import * as styled from '../styles';

export const TOOLS_OPTIONS = {
  buttonAsk: { name: 'buttonAsk', icon: 'MagicWand' },
  bulletList: { name: 'bulletList', icon: 'ListDashes' },
  orderedList: { name: 'orderedList', icon: 'ListNumbers' },
  bold: { name: 'bold', icon: 'TextBolder' },
  italic: { name: 'italic', icon: 'TextItalic' },
  underline: { name: 'underline', icon: 'TextUnderline' },
  highlight: { name: 'highlight', icon: 'PencilLine' },
};

export function TextToolsOptions({ editor, items, setAskAIOpen }) {
  if (!editor) return false;

  return (
    <>
      {items.map((hashName) => {
        const { name, icon } = TOOLS_OPTIONS[hashName];
        const toggleName = `toggle${name.replace(
          /^.{1}/,
          name[0].toUpperCase()
        )}`;

        if (hashName === 'buttonAsk') {
          return (
            <ButtonAskAI
              icon={icon}
              onClick={() => setAskAIOpen(true)}
              editor={editor}
              floating
            />
          );
        }
        return (
          <styled.MarkButton
            key={name}
            onClick={() => editor.chain().focus()[toggleName]().run()}
            active={editor.isActive(name)}
          >
            <styled.Icon icon={icon} weight="bold" />
          </styled.MarkButton>
        );
      })}
    </>
  );
}

TextToolsOptions.defaultValues = {
  items: [],
};

TextToolsOptions.propTypes = {
  setAskAIOpen: PropTypes.func,
  editor: PropTypes.object,
  items: PropTypes.arrayOf([
    'buttonAsk',
    'bulletList',
    'orderedList',
    'bold',
    'italic',
    'underline',
    'highlight',
  ]),
};
