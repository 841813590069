import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

export const BlockWidget = styled.section`
  border: 0.1rem solid ${Colors.outline};
  padding: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  justify-content: space-between;
`;

export const Title = styled.h3`
  color: ${Colors.red};
  margin: 0;
`;

export const BlockedBy = styled.p`
  font-size: 1.3rem;
  color: ${Colors.textLight};
`;

export const ReasonText = styled.p`
  line-height: 2.1rem;
  white-space: pre-line;
`;
