import { ForwardedRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import i18n from './utils/i18n';
import * as styled from './styles/inputs';

type InputSearchProps = {
  className?: string;
  placeholder?: I18nMessage;
  onChange?: (value: string) => void;
  value?: string;
  dataManual?: string;
};

export const InputSearch = forwardRef(
  (
    { className, placeholder, onChange, value, dataManual }: InputSearchProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const intl = useIntl();

    return (
      <styled.InputWrapper className={className}>
        <styled.Icon icon="MagnifyingGlass" color="greyDarker" />
        <styled.Input
          ref={ref}
          data-manual={dataManual}
          value={value}
          placeholder={intl.formatMessage(placeholder || i18n.search)}
          onChange={(event) => onChange?.(event.target.value)}
        />
        {value && (
          <styled.Clear
            icon="X"
            color="textLighter"
            onClick={() => onChange?.('')}
          />
        )}
      </styled.InputWrapper>
    );
  }
);
