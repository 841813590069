import qs from 'qs';

import { httpGet, httpPost, httpPut, httpDelete } from 'utils';

import { openFlashMessage } from 'components/utils/flashMessages';
import { buildStringifiedQuery } from 'utils/apiHelpers';
import { hideProgress } from 'containers/App/actions/ui';

import { FlashDefinition } from './utils/flashDefinition';

import {
  INTEGRATIONS_FETCH_REQUEST,
  INTEGRATIONS_FETCH_SUCCESS,
  INTEGRATIONS_FETCH_ERROR,
  INTEGRATION_FETCH_REQUEST,
  INTEGRATION_FETCH_SUCCESS,
  INTEGRATION_FETCH_ERROR,
  INTEGRATION_CREATE_REQUEST,
  INTEGRATION_CREATE_SUCCESS,
  INTEGRATION_CREATE_ERROR,
  INTEGRATION_UPDATE_REQUEST,
  INTEGRATION_UPDATE_SUCCESS,
  INTEGRATION_UPDATE_ERROR,
  INTEGRATION_DELETE_REQUEST,
  INTEGRATION_DELETE_SUCCESS,
  INTEGRATION_DELETE_ERROR,
  INTEGRATION_CREDENTIALS_VALIDATION_REQUEST,
  INTEGRATION_CREDENTIALS_VALIDATION_SUCCESS,
  INTEGRATION_CREDENTIALS_VALIDATION_ERROR,
  INTEGRATION_FORCE_SYNC_REQUEST,
  INTEGRATION_FORCE_SYNC_SUCCESS,
  INTEGRATION_FORCE_SYNC_ERROR,
  RESOURCE_FETCH_REQUEST,
  RESOURCE_FETCH_SUCCESS,
  RESOURCE_FETCH_ERROR,
  RESOURCES_FETCH_REQUEST,
  RESOURCES_FETCH_SUCCESS,
  RESOURCES_FETCH_ERROR,
  SETTINGS_REFRESH_REQUEST,
  SETTINGS_REFRESH_SUCCESS,
  SETTINGS_REFRESH_ERROR,
  BULLHORN_CANDIDATES_DOCUMENTS_REQUEST,
  BULLHORN_CANDIDATES_DOCUMENTS_SUCCESS,
  BULLHORN_CANDIDATES_DOCUMENTS_ERROR,
  BULLHORN_CANDIDATES_FETCH_FILE_REQUEST,
  BULLHORN_CANDIDATES_FETCH_FILE_SUCCESS,
  BULLHORN_CANDIDATES_FETCH_FILE_ERROR,
} from './constants';

export function fetchIntegrations() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: INTEGRATIONS_FETCH_REQUEST });
      httpGet('/company/integrations')
        .then((response) => {
          dispatch({ type: INTEGRATIONS_FETCH_SUCCESS, payload: response });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: INTEGRATIONS_FETCH_ERROR, payload: error });
          reject(error);
        });
    });
}

export function fetchIntegration(type) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: INTEGRATION_FETCH_REQUEST, payload: { type } });
      httpGet(`/company/integrations/${type}`)
        .then((response) => {
          dispatch({
            type: INTEGRATION_FETCH_SUCCESS,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: INTEGRATION_FETCH_ERROR, payload: error });
          reject(error);
        });
    });
}

export function createIntegration(integration) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: INTEGRATION_CREATE_REQUEST });
      httpPost('/company/integrations', integration)
        .then((response) => {
          dispatch({
            type: INTEGRATION_CREATE_SUCCESS,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: INTEGRATION_CREATE_ERROR, payload: error });
          reject(error);
        });
    });
}

export function updateIntegration(id, integration) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: INTEGRATION_UPDATE_REQUEST,
        payload: { id, integration },
      });
      httpPut(`/company/integrations/${id}`, integration)
        .then((response) => {
          dispatch({
            type: INTEGRATION_UPDATE_SUCCESS,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: INTEGRATION_UPDATE_ERROR, payload: error });
          reject(error);
        });
    });
}

export function deleteIntegration(id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: INTEGRATION_DELETE_REQUEST, payload: { id } });
      httpDelete(`/company/integrations/${id}`)
        .then((response) => {
          dispatch({ type: INTEGRATION_DELETE_SUCCESS, payload: response });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: INTEGRATION_DELETE_ERROR, payload: error });
          reject(error);
        });
    });
}

export function validateCredentials(type, credentials) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: INTEGRATION_CREDENTIALS_VALIDATION_REQUEST,
        payload: { type, credentials },
      });
      httpPost(`/company/integrations/${type}/validate_credentials`, {
        credentials,
      })
        .then((response) => {
          openFlashMessage(FlashDefinition.credentialsValidationSuccess);
          dispatch({
            type: INTEGRATION_CREDENTIALS_VALIDATION_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          openFlashMessage(FlashDefinition.invalidCredentials);
          dispatch({
            type: INTEGRATION_CREDENTIALS_VALIDATION_ERROR,
            payload: error,
          });
          reject(error);
        });
    });
}

export function fetchResources(integrationId, { type, filtered }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: RESOURCES_FETCH_REQUEST });
      const query = buildStringifiedQuery({
        filtered,
        sorted: [],
        paginated: { page: 1, per_page: 1000 },
      });
      httpGet(`/company/integrations/${integrationId}/${type}?${query}`)
        .then((response) => {
          dispatch({ type: RESOURCES_FETCH_SUCCESS, payload: response });
          if (response.status === 'error') {
            resolve(response.error);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          dispatch({ type: RESOURCES_FETCH_ERROR, payload: error });
          reject(error);
        });
    });
}

export function fetchResource(integrationId, { id, type }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: RESOURCE_FETCH_REQUEST });
      httpGet(`/company/integrations/${integrationId}/${type}/${id}`)
        .then((response) => {
          dispatch({ type: RESOURCE_FETCH_SUCCESS, payload: response });
          if (response.status === 'error') {
            resolve(response.error);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            dispatch({ type: RESOURCE_FETCH_ERROR, payload: error });
          }
          reject(error);
        });
    });
}

export function refreshSettings(id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: SETTINGS_REFRESH_REQUEST,
        payload: { id },
      });
      httpPut(`/company/integrations/${id}/refresh_settings`)
        .then((response) => {
          dispatch({
            type: SETTINGS_REFRESH_SUCCESS,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: SETTINGS_REFRESH_ERROR, payload: error });
          reject(error);
        });
    });
}

export function forceSync(id, params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: INTEGRATION_FORCE_SYNC_REQUEST });
      httpPost(`/company/integrations/${id}/force_sync`, params)
        .then((response) => {
          dispatch({
            type: INTEGRATION_FORCE_SYNC_SUCCESS,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: INTEGRATION_FORCE_SYNC_ERROR, payload: error });
          reject(error);
        });
    });
}

export function fetchBullhornCandidateDocuments(candidateId, acceptedFormats) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: BULLHORN_CANDIDATES_DOCUMENTS_REQUEST });

      const queryString = acceptedFormats
        ? `?${qs.stringify(
            { 'accepted_formats[]': acceptedFormats },
            { indices: false }
          )}`
        : '';

      httpGet(
        `/company/integrations/bullhorn/candidate_documents/${candidateId}${queryString}`
      )
        .then((response) => {
          dispatch({
            type: BULLHORN_CANDIDATES_DOCUMENTS_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: BULLHORN_CANDIDATES_DOCUMENTS_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function fetchBullhornCandidateFile(candidateId, fileId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: BULLHORN_CANDIDATES_FETCH_FILE_REQUEST });

      httpGet(
        `/company/integrations/bullhorn/candidate_file/${candidateId}/${fileId}`
      )
        .then((response) => {
          dispatch({
            type: BULLHORN_CANDIDATES_FETCH_FILE_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          if (error.response.data.error === 'invalid_bullhorn_file') {
            dispatch(hideProgress());
            openFlashMessage(FlashDefinition.invalidBullhornFileError);
          } else {
            dispatch({
              type: BULLHORN_CANDIDATES_FETCH_FILE_ERROR,
              error,
            });
          }

          reject(error);
        });
    });
}
