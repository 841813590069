import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Button as ButtonBase } from 'components/elements/button';

export const Detail = styled.p`
  color: ${Colors.text};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
`;

const disabledMixin = css`
  background-color: ${Colors.greyLighter};

  & ${Detail} {
    color: ${Colors.textLighter};
  }
`;

export const InputContainer = styled.div`
  border: ${(props) =>
    `solid 1px ${props.isValid ? Colors.inputOutline : Colors.red}`};
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: relative;
  width: 100%;
  background-color: ${Colors.white};

  ${({ disabled }) => disabled && disabledMixin}
`;

export const DropzoneBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  &:focus,
  &:hover {
    outline: none;
  }
`;

export const Button = styled(ButtonBase)`
  margin-left: 1rem;
`;
