import styled, { css, keyframes } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.p`
  margin: 0 1rem 0 0;
  color: ${({ labelColor }) => Colors[labelColor]};
  font-size: ${({ labelSize }) => `${labelSize}`}rem;
  font-weight: 600;
`;

const defaultBarWrapperMixin = css`
  background-color: ${Colors.grey};
  border-radius: 0.5rem;
  height: 1rem;
`;

const thinBarWrapperMixin = css`
  display: flex;
`;

const borderedBarWrapperMixin = css`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.outline};
`;

export const BarWrapper = styled.div`
  width: ${({ barWidth }) => `${barWidth}`};
  overflow: hidden;
  ${({ barStyle }) =>
    barStyle === 'thin' ? thinBarWrapperMixin : defaultBarWrapperMixin}
  ${({ bordered }) => bordered && borderedBarWrapperMixin}
`;

const defaultBarMixin = css`
  display: none;
`;

const thinBarMixin = css`
  width: 100%;
  align-self: center;
  background-color: ${Colors.grey};
  height: 0.2rem;
`;

export const Bar = styled.div`
  ${({ barStyle }) => (barStyle === 'thin' ? thinBarMixin : defaultBarMixin)}
`;

const defaultFillMixin = css`
  background-color: ${({ fillColor }) => Colors[fillColor]};
  border-radius: 0.5rem;
  height: 1rem;
`;

const AnimateProgressBar = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 10rem 0;
  }
`;

const stripedFillMixin = css`
  flex-shrink: 0;
  height: 1rem;
  background-image: linear-gradient(
    110deg,
    ${Colors.tealDark} 15%,
    ${Colors.teal} 15%,
    ${Colors.teal} 40%,
    ${Colors.tealDark} 40%,
    ${Colors.tealDark} 65%,
    ${Colors.teal} 65%,
    ${Colors.teal} 90%,
    ${Colors.tealDark} 90%,
    ${Colors.tealDark}
  );
  background-size: 10rem 1rem;
  animation: ${({ progress }) =>
    progress < 100
      ? css`
          ${AnimateProgressBar} 1.5s linear infinite
        `
      : 'none'};
  transition: width 1s ease-in-out;
`;

const borderedFillMixin = css`
  height: 0.8rem;
  box-sizing: content-box;
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: ${({ progress }) => (progress === 0 ? 0 : 1)}px solid
    ${Colors.outline};
`;

export const Fill = styled.div`
  width: ${({ progress }) => progress}%;
  max-width: 100%;
  ${({ fillStyle }) =>
    fillStyle === 'striped' ? stripedFillMixin : defaultFillMixin}
  ${({ bordered }) => bordered && borderedFillMixin}
`;
