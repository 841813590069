import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { ReactPageClick } from 'react-page-click';

import { FormattedMessage } from 'react-intl';
import { isObject } from 'lodash';

import { CustomColorPicker } from 'components/form/colorPicker';
import { Tooltip } from 'components/overlay/Tooltip';

import * as styled from './styles';
import i18n from './utils/i18n';

export const InputColor = (props) => {
  const { className, value, disabled, tooltip, onChange } = props;
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);

  const renderColorPicker = () => (
    <ReactPageClick notify={() => setShowPicker(false)}>
      <styled.ColorPickerWrapper ref={pickerRef}>
        <CustomColorPicker
          color={value}
          onChange={(color) => onChange(color.hex)}
          disabled={disabled}
          disableAlpha
        />
      </styled.ColorPickerWrapper>
    </ReactPageClick>
  );

  const input = (
    <styled.InputColor
      className={className}
      disabled={disabled}
      onClick={() => setShowPicker(true)}
    >
      <styled.InputWrapper
        style={{ backgroundColor: value }}
      ></styled.InputWrapper>
      <styled.ColorCode>{value}</styled.ColorCode>
    </styled.InputColor>
  );

  if (!tooltip) {
    return input;
  }

  return (
    <>
      <Tooltip
        color="dark"
        position="S"
        content={
          <FormattedMessage {...(isObject(tooltip) ? tooltip : i18n.tooltip)} />
        }
      >
        {input}
      </Tooltip>
      {!disabled && showPicker && renderColorPicker()}
    </>
  );
};

InputColor.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func,
};
