import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultPlaceholder: {
    id: 'app.components.tagsDropdown.defaultPlaceholder',
    defaultMessage: 'Select',
  },

  archivedOptionTooltip: {
    id: 'app.components.tagsDropdown.archivedOptionTooltip',
    defaultMessage:
      'This option has been archived and is no longer available for new selections.',
  },
  bullhornOptionTooltip: {
    id: 'app.components.tagsDropdown.bullhornOptionTooltip',
    defaultMessage:
      'This option has been received from Bullhorn but is not available in Candidate.ly for new selections.',
  },
  typeAhead: {
    id: 'app.components.tagsDropdown.typeAheadPlaceholder',
    defaultMessage: 'Filter options...',
  },
});
