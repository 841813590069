import styled, { createGlobalStyle } from 'styled-components';

import { Button as ButtonBase } from 'components/elements/button';
import { Colors } from 'components/utils/styles/ui';

const REDUCTION = 0.95;
const PAGESIZEMM = {
  width: 210 * REDUCTION,
  height: 275 * REDUCTION,
};

const positionCenterSnippet = `
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Fullscreen = styled.div`
  position: fixed;
  z-index: 2500;
  ${positionCenterSnippet}
`;

export const Overlay = styled.div`
  position: absolute;
  overflow: hidden;
  z-index: 1011;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${Colors.blackLessTransparent};
`;

export const Content = styled.div`
  width: 95rem;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  margin: auto;
  z-index: 1012;
  ${positionCenterSnippet}
`;

export const Controls = styled.div`
  padding: 1rem 0 1.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const IconButton = styled(ButtonBase)`
  border-radius: 0.2rem;
  margin-left: 2rem;
  border: none;
  padding: 0 0.9rem;
  width: 4rem;
  height: 4rem;

  && > svg {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

export const ContentFitWrap = styled.div`
  background: ${Colors.white};
  height: calc(100% - 7rem);
  display: flex;
`;

export const Preview = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const PrintGlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
  }
  @page { size: A4; }
  #printContainer{
    visibility: hidden;
    display: none;
  }
  #printOverlay{
    background: rgba(0,0,0,0.2);
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 1000;
  }
  #printBox{
    width: 100px;
    padding: 10px;
    border-radius: 5px;
    background-color: ${Colors.white};
    text-align: center;
    font-size: 20px;
    margin: auto;
    margin-top: calc(26% - 20px);
  }
  @media print{
     html, body {
      background: transparent none;
      margin: 0 0 0 0;
    }
    body * {
      visibility: hidden;
      display: none;
    }
    #printContainer{
      display: block;
      visibility: visible;
    }
    #printContainer canvas {
      position: static;
      padding: 0;
      margin: 0;
    }
    #printContainer * {
      visibility: visible;
    }
    #printContainer {
      overflow: visible;
    }
    /* wrapper around (scaled) print canvas elements */
    #printContainer > div {
      position: relative;
      overflow: hidden;
      page-break-after:avoid;
      page-break-inside: avoid;
      page-break-before: avoid;
      display: block;
      width: ${PAGESIZEMM.width}mm;
      height: ${PAGESIZEMM.height}mm;
      margin: 0;
    }

    #printContainer canvas,
    #printContainer img {
      display: block;
      width: 100%;
      height: 100%;
    }

    #printContainer #printImg {
      width: auto;
      height: auto;
    }
  }
`;
