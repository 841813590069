import styled, { css } from 'styled-components';

import { Icon as IconBase } from 'components/elements/icon';

import { Colors } from '../../../utils/styles/ui';

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isOn, color, theme }) => {
    if (isOn) {
      return color ? Colors[color] : theme.colors.primary;
    }
    return Colors.greyLight;
  }};
  transition: 0.4s;
  border-radius: 4rem;
  border: 1px solid ${Colors.outline};

  &:before {
    position: absolute;
    content: '';
    background-color: ${({ isOn, disabled }) => {
      if (disabled) {
        return isOn ? Colors.grey : Colors.greyDark;
      }
      return Colors.white;
    }};
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 0 1.6rem 0 rgba(19, 41, 61, 0.08),
      0 0.6rem 1.6rem 0 rgba(19, 41, 61, 0.12);
  }
`;

const smallSizeMixin = css`
  width: 3rem;
  height: 1.8rem;

  ${Slider} {
    &:before {
      height: 1.4rem;
      width: 1.4rem;
      left: 0.1rem;
      bottom: 0.1rem;
      transform: ${({ isOn }) => (isOn ? 'translateX(1.2rem)' : 'none')};
    }
  }
`;

const normalSizeMixin = css`
  width: 3.6rem;
  height: 2rem;

  ${Slider} {
    &:before {
      height: 1.6rem;
      width: 1.6rem;
      left: 0.1rem;
      bottom: 0.1rem;
      transform: ${({ isOn }) => (isOn ? 'translateX(1.5rem)' : 'none')};
    }
  }
`;

const largeSizeMixin = css`
  width: 4.4rem;
  height: 2.6rem;

  ${Slider} {
    &:before {
      height: 2rem;
      width: 2rem;
      left: 0.2rem;
      bottom: 0.2rem;
      transform: ${({ isOn }) => (isOn ? 'translateX(1.7rem)' : 'none')};
    }
  }
`;

const xlargeSizeMixin = css`
  width: 5rem;
  height: 3rem;

  ${Slider} {
    &:before {
      height: 2.4rem;
      width: 2.4rem;
      left: 0.2rem;
      bottom: 0.2rem;
      transform: ${({ isOn }) => (isOn ? 'translateX(1.9rem)' : 'none')};
    }
  }
`;

export const Toggle = styled.label`
  position: relative;
  display: inline-block;

  ${({ size }) => {
    switch (size) {
      case 'large':
        return largeSizeMixin;
      case 'xlarge':
        return xlargeSizeMixin;
      case 'small':
        return smallSizeMixin;
      default:
        return normalSizeMixin;
    }
  }}

  & > input {
    display: none;
  }
`;

const smallSizeLabelMixin = css`
  ${({ theme }) => theme.typography.text.normal}
  top: -0.5rem;
  padding-left: 1.2rem;
`;

const defaultLabelMixin = css`
  ${({ theme }) => theme.typography.text.medium}
  top: -0.7rem;
  padding-left: 2rem;
`;

export const Label = styled.span`
  display: inline-block;
  height: 2.6rem;
  line-height: 2.6rem;
  position: relative;

  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  ${({ size }) => (size === 'small' ? smallSizeLabelMixin : defaultLabelMixin)}
`;

const iconNormalMixin = css`
  top: 0.3rem;
  left: 0.3rem;
  width: 1.4rem;
  height: 1.4rem;
  transform: ${({ isOn }) => (isOn ? 'translateX(1.5rem)' : 'none')};
`;

const iconSmallMixin = css`
  top: 0.3rem;
  left: 0.3rem;
  width: 1.2rem;
  height: 1.2rem;
  transform: ${({ isOn }) => (isOn ? 'translateX(1.2rem)' : 'none')};
`;

const iconLargeMixin = css`
  top: 0.4rem;
  left: 0.4rem;
  width: 1.8rem;
  height: 1.8rem;
  transform: ${({ isOn }) => (isOn ? 'translateX(1.7rem)' : 'none')};
`;

const iconXlargeMixin = css`
  top: 0.7rem;
  left: 0.7rem;
  width: 1.6rem;
  height: 1.6rem;
  transform: ${({ isOn }) => (isOn ? 'translateX(1.9rem)' : 'none')};
`;

export const Icon = styled(IconBase)`
  position: absolute;
  transition: 0.4s;
  cursor: pointer;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  ${({ toggleSize }) => {
    switch (toggleSize) {
      case 'xlarge':
        return iconXlargeMixin;
      case 'large':
        return iconLargeMixin;
      case 'small':
        return iconSmallMixin;
      default:
        return iconNormalMixin;
    }
  }}
`;
