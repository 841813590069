import { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { companyID as getCompanyId } from 'utils/authorization/utils/auth';

import { fetchCompanyDomains, updateDomain } from './actions';

export const CompanyDomainsContext = createContext({});

const CompanyDomainsProviderComponent = (props) => {
  const { children, handleFetchCompanyDomains, handleUpdateDomain } = props;

  const [companyDomains, setDomains] = useState([]);

  const companyId = getCompanyId();
  const onFetchCompanyDomains = () =>
    handleFetchCompanyDomains(companyId).then(({ domains }) =>
      setDomains(domains)
    );

  const onUpdateDomain = (domainId, params) =>
    handleUpdateDomain(companyId, domainId, params).then((res) =>
      setDomains(
        companyDomains.map((domain) => (domain.id === res.id ? res : domain))
      )
    );

  const value = {
    companyDomains,
    onUpdateDomain,
    handleFetchCompanyDomains: onFetchCompanyDomains,
  };

  return (
    <CompanyDomainsContext.Provider value={value}>
      {children}
    </CompanyDomainsContext.Provider>
  );
};

CompanyDomainsProviderComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  handleFetchCompanyDomains: PropTypes.func,
  handleUpdateDomain: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  handleFetchCompanyDomains: (companyId) =>
    dispatch(fetchCompanyDomains(companyId)),
  handleUpdateDomain: (companyId, domainId, params) =>
    dispatch(updateDomain(companyId, domainId, params)),
});

const withConnect = connect(null, mapDispatchToProps);

export const CompanyDomainsProvider = withConnect(
  CompanyDomainsProviderComponent
);
