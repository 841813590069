import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import {
  sidebarSubMenuWidth,
  sidebarWidth,
} from 'components/structure/page/utils/calculations';

const CONTAINER_WIDTH = 50;
export const Container = styled.div`
  position: sticky;
  width: ${CONTAINER_WIDTH}rem;
  top: 0;
  left: ${({ sidebarExpanded }) =>
    sidebarExpanded
      ? `calc(50vw - ${
          (sidebarWidth + sidebarSubMenuWidth) / 2 + CONTAINER_WIDTH / 2 + 1.4
        }rem)`
      : `calc(50vw - ${sidebarWidth / 2 + CONTAINER_WIDTH / 2 + 1.4}rem)`};
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IllustrationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ small }) => (small ? '7rem' : '12rem')};
  height: ${({ small }) => (small ? '7rem' : '12rem')};
`;

export const Illustration = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
`;

export const Title = styled.div`
  font-size: ${({ small }) => (small ? '1.6rem' : '2.6rem')};
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryDark};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  ${IllustrationContainer} + ${Title} {
    margin-top: 1.4rem;
  }
`;

export const Body = styled.div`
  display: flex;
  padding: 1rem 0;
  ${({ theme }) => theme.typography.text.large};
  text-align: center;
  color: ${Colors.text};
`;

export const Footer = styled.div`
  padding: 1rem 0;
`;
