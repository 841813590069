import { renderToStaticMarkup } from 'react-dom/server';
import * as PhosphorIcons from 'phosphor-react';
import { ColorKey } from 'components/utils/styles/types';

import { Colors } from '../utils/styles/ui';

import * as CustomIcons from './customIcons';
import * as styled from './styles/icon';
import { ICON_SIZES } from './constants/icons';

import type {
  IconSize,
  IconWeight,
  Color,
  IconName,
  PhosphorIconKeys,
  CustomIconKeys,
} from './types';

type IconProps = {
  xmlns?: string;
  icon?: IconName | 'DotsThreeOutlineVerticalFill';
  size?: IconSize | number;
  color?: Color;
  weight?: IconWeight;
  mirrored?: boolean;
  marginLeft?: string | number | boolean;
  onClick?: (e: React.KeyboardEvent) => void;
};

type IconWrappedProps = {
  className?: string;
  color: ColorKey;
  size: number | IconSize;
  shape?: 'circle' | 'square';
};

type IconCircleProps = {
  className?: string;
  color: ColorKey;
  shadow: ColorKey;
};

type IconCounterProps = {
  className: string;
  color: ColorKey;
  counter: number;
};

const computeSize = (size: number | IconSize) =>
  (typeof size === 'number' ? size : ICON_SIZES[size]) * 10;

/**
 * "#" in URLs starts a fragment identifier.
 * So, in order to url works, it is needed to escape "#" (%23) for colors
 */

export const toStaticMarkup = (props: IconProps) =>
  renderToStaticMarkup(
    <Icon xmlns="http://www.w3.org/2000/svg" {...props} />
  ).replace(/#/g, '%23');

export const Icon = function Icon({
  icon,
  size = 'normal',
  weight = 'regular',
  color = Colors.text,
  ...props
}: IconProps) {
  const computedSize = computeSize(size);
  const phosphorIconName = icon?.replace(/Fill|Mirror$/, '');

  let IconComponent = (PhosphorIcons[phosphorIconName as PhosphorIconKeys] ||
    CustomIcons[icon as CustomIconKeys]) as React.FC<IconProps>;

  if (!icon || !IconComponent) {
    // eslint-disable-next-line no-console
    console.error(
      `>>>>>>> Missing Icon: ${icon}, path: ${window.location.pathname}`
    );
    IconComponent = PhosphorIcons.ImageSquare as React.FC<IconProps>;
  }

  const autoWeight = icon?.includes('Fill') ? 'fill' : undefined;
  const mirror = icon?.includes('Mirror');

  return (
    <IconComponent
      {...props}
      mirrored={mirror}
      weight={autoWeight || weight}
      color={Colors[color as ColorKey] || color}
      size={computedSize}
    />
  );
};

export const IconWrapped = function IconWrapped({
  className,
  color,
  size,
  shape = 'circle',
  ...props
}: IconWrappedProps) {
  const computedSize = computeSize(size) + 2;
  const ShapeWrapper = shape === 'circle' ? styled.Circle : styled.Square;

  return (
    <ShapeWrapper className={className} color={color} size={computedSize}>
      <Icon color="white" size={size} {...props} />
    </ShapeWrapper>
  );
};

export const IconCircle = function IconCircle({
  className,
  color,
  shadow,
  ...props
}: IconCircleProps) {
  return (
    <styled.CircleWrapper className={className} color={color} shadow={shadow}>
      <Icon {...props} size="x2small" color={color} />
    </styled.CircleWrapper>
  );
};

export const IconCounter = function IconCounter({
  className,
  color,
  counter,
  ...props
}: IconCounterProps) {
  return (
    <styled.CounterWrapper className={className}>
      <styled.IconCounter color={color} digits={counter.toString().length}>
        {counter}
      </styled.IconCounter>
      <Icon {...props} color={color} />
    </styled.CounterWrapper>
  );
};
