import { defineMessages } from 'react-intl';

export default defineMessages({
  settingsTitle: {
    id: 'app.components.Settings.header.title',
    defaultMessage: 'Settings',
  },
  integrationsSettingsTitle: {
    id: 'app.components.Settings.Integrations.title',
    defaultMessage: 'Integrations settings',
  },
  settings: {
    id: 'app.components.Settings.settings',
    defaultMessage: 'Settings',
  },

  general: {
    id: 'app.components.Settings.Menu.general',
    defaultMessage: 'General',
  },
  companySettingsTitle: {
    id: 'app.components.Settings.Menu.companySettingsTitle',
    defaultMessage: 'Company',
  },
  usersTitle: {
    id: 'app.components.Settings.Menu.usersTitle',
    defaultMessage: 'Users',
  },
  teamsTitle: {
    id: 'app.components.Settings.Menu.teamsTitle',
    defaultMessage: 'Teams',
  },

  configurationJobs: {
    id: 'app.components.Settings.Menu.configurationJobs',
    defaultMessage: 'Jobs configuration',
  },
  submissionProperties: {
    id: 'app.components.Settings.Menu.submissionProperties',
    defaultMessage: 'Submission properties',
  },
  jobProperties: {
    id: 'app.components.Settings.Menu.jobProperties',
    defaultMessage: 'Job settings',
  },
  sharedListsSettings: {
    id: 'app.components.Settings.Menu.sharedListsSettings',
    defaultMessage: 'List settings',
  },
  sharedListsEmailTemplates: {
    id: 'app.components.Settings.Menu.sharedListsEmailTemplates',
    defaultMessage: `Email templates`,
  },
  sharedListsAppearance: {
    id: 'app.components.Settings.Menu.sharedListsAppearance',
    defaultMessage: 'Branding',
  },
  sharedListsCandidateAppearance: {
    id: 'app.components.Settings.Menu.sharedListsCandidateAppearance',
    defaultMessage: 'Candidate appearance',
  },
  sharedListsCustomFields: {
    id: 'app.components.Settings.Menu.sharedListsCustomFields',
    defaultMessage: 'Field management',
  },
  sharedListsGeneral: {
    id: 'app.components.Settings.Menu.sharedListsGeneral',
    defaultMessage: 'General settings',
  },
  sharedListsClientPortal: {
    id: 'app.components.Settings.Menu.sharedListsClientPortal',
    defaultMessage: 'Client portal',
  },
  sharedListsCandidateStatuses: {
    id: 'app.components.Settings.Menu.sharedListsCandidateStatuses',
    defaultMessage: 'Submission stages',
  },
  sharedListsNotifications: {
    id: 'app.components.Settings.Menu.sharedListsNotifications',
    defaultMessage: 'Client notifications',
  },
  sharedListsCandidatesSettings: {
    id: 'app.components.Settings.Menu.sharedListsCandidatesSettings',
    defaultMessage: 'Candidate settings',
  },

  integrations: {
    id: 'app.components.Settings.Menu.integrations',
    defaultMessage: 'Integrations',
  },
  bullhorn: {
    id: 'app.components.Settings.Menu.bullhorn',
    defaultMessage: 'Bullhorn',
  },

  submitButton: {
    id: 'app.components.Settings.SubmitContainer.submitButton',
    defaultMessage: 'Save changes',
  },
  cancelButton: {
    id: 'app.components.Settings.SubmitContainer.cancelButton',
    defaultMessage: 'Cancel',
  },
  changesMadeMessage: {
    id: 'app.components.Settings.SubmitContainer.changesMadeMessage',
    defaultMessage: "You've made some changes on this page.",
  },

  flashSavedSuccess: {
    id: 'app.components.Settings.Account.flashSavedSuccess',
    defaultMessage: 'Saved successfully!',
  },
});
