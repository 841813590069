import { ReactElement, cloneElement, isValidElement } from 'react';
import { useIntl } from 'react-intl';

import { isEmpty, isString } from 'lodash';

import { DropdownOption } from 'components/elements/dropdown';
import { Menu } from 'components/navigation/menu/menu';

import * as styled from './styles/context-header';

type ContextProps = {
  type: string;
  active: string;
  links: Partial<DropdownOption>[];
  parent: string;
  menuFor: string;
};

type ContextHeaderComponentProps = {
  className?: string;
  context: Partial<ContextProps>;
  breadcrumb?: string[];
  title: string | null;
  badges?: ReactElement[];
  isAdminHeader?: boolean;
};

export const ContextHeader = function ContextHeader({
  className,
  context = {},
  breadcrumb,
  title = null,
  badges = [],
  isAdminHeader = false,
}: ContextHeaderComponentProps) {
  const intl = useIntl();

  const breadcrumbEl = isEmpty(breadcrumb) ? null : (
    <styled.Breadcrumb routeTo={breadcrumb![0]} title={breadcrumb![1]} />
  );

  const titleEl = title ? (
    <styled.Label isAdminHeader={isAdminHeader}>
      {isString(title) || isValidElement(title)
        ? title
        : intl.formatMessage(title)}
    </styled.Label>
  ) : null;

  /* eslint-disable react/no-array-index-key */
  const badgesEl = badges?.map((b, ind) =>
    cloneElement(b, { key: `badge${ind}` })
  );

  const menu = !isEmpty(context) ? (
    <styled.Menu>
      <Menu context={context} maxItems={9} />
    </styled.Menu>
  ) : null;

  return (
    <styled.Header className={className} isAdminHeader={isAdminHeader}>
      {breadcrumbEl}
      {titleEl}

      {!isEmpty(badgesEl) && <styled.Badges>{badgesEl}</styled.Badges>}

      {menu}
    </styled.Header>
  );
};
