import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Tooltip as TooltipBase } from 'components/overlay/Tooltip';
import { AvatarUser as AvatarBase } from 'components/visual/avatar/avatarUser';
import { Icon as IconBase } from 'components/elements/icon';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';
import { iconElementsSelector } from 'components/elements/styles/icon';

export const Tooltip = styled(TooltipBase)``;

export const Avatar = styled(AvatarBase)``;

export const Icon = styled(IconBase)``;

const activeLinkMixin = css`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary} !important;

    ${iconElementsSelector} {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const SmartLink = styled(SmartLinkBase)`
  cursor: auto;
  color: ${Colors.text} !important;

  &:hover {
    color: ${Colors.text};

    ${iconElementsSelector} {
      stroke: ${Colors.text};
    }
  }

  ${({ to }) => to && activeLinkMixin}
`;

export const AvatarLink = styled(SmartLink)`
  display: flex;
  align-items: center;
`;

export const UserLink = styled(SmartLink)`
  display: flex;
  font-weight: 500;
  overflow: hidden;
`;

export const CompanyLink = styled(SmartLink)`
  align-items: center;
  display: flex;
  overflow: hidden;

  ${Icon} {
    flex-shrink: 0;
  }

  ${({ linksUnderlined }) => linksUnderlined && 'text-decoration: underline;'}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0.8rem;
  width: 29.6rem;
  color: ${Colors.text};

  ${Icon}:first-child {
    margin-right: 0.5rem;
  }

  ${Icon}:last-child {
    margin-left: 0.5rem;
  }
`;

export const Items = styled.div`
  overflow: hidden;
`;

export const Item = styled.p`
  margin: 0 0 0.2rem 1.6rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.text.normal};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LinkText = styled.span`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
