import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';

export const Container = styled.div`
  display: flex;
  margin-bottom: -0.1rem;
`;

const activeMixin = css`
  border-left: 0.1rem solid ${Colors.grey};
  border-right: 0.1rem solid ${Colors.grey};
  background-color: ${Colors.white};
`;

const inactiveMixin = css`
  border-bottom: 0.1rem solid ${Colors.grey};
  border-top: 0;

  &:before {
    display: none;
  }
`;

const TabCompactMixin = css`
  height: 3rem;
  padding: 0 1.2rem;
  border-top: 0.1rem solid ${Colors.grey};
  border-radius: 0.2rem 0.2rem 0 0;
`;

const TabNormalMixin = css`
  height: 4rem;
  font-weight: 500;
  padding-top: 0.5rem;
  width: 11.5rem;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 0.3rem;
    width: 100%;
    background-color: ${Colors.text};
    border-radius: 0.2rem 0.2rem 0 0;
  }
`;

export const Tab = styled(SmartLinkBase)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.text};
  ${({ size }) => (size === 'compact' ? TabCompactMixin : TabNormalMixin)};

  ${({ onClick }) => (onClick ? 'cursor: pointer;' : null)}
  ${({ active }) => (active ? activeMixin : inactiveMixin)}
`;

export const Indicator = styled.div`
  background-color: ${({ color }) => Colors[color]};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
  margin-left: 1rem;
`;
