export const currencies = [
  {
    code: 'AED',
    flag: 'AE',
    name: 'United Arab Emirates Dirham',
    symbol: 'د.إ',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'AFN',
    flag: 'AF',
    name: 'Afghan Afghani',
    symbol: '؋',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'ALL',
    flag: 'AL',
    name: 'Albanian Lek',
    symbol: 'L',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'AMD',
    flag: 'AM',
    name: 'Armenian Dram',
    symbol: '֏',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'AOA',
    flag: 'AO',
    name: 'Angolan Kwanza',
    symbol: 'Kz',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'ARS',
    flag: 'AR',
    name: 'Argentine Peso',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'AUD',
    flag: 'AU',
    name: 'Australian Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'AWG',
    flag: 'AW',
    name: 'Aruban Florin',
    symbol: 'ƒ',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'AZN',
    flag: 'AZ',
    name: 'Azerbaijani Manat',
    symbol: '₼',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BAM',
    flag: 'BA',
    name: 'Bosnia-Herzegovina Convertible Mark',
    symbol: 'KM',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BBD',
    flag: 'BB',
    name: 'Barbadian Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BDT',
    flag: 'BD',
    name: 'Bangladeshi Taka',
    symbol: '৳',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BGN',
    flag: 'BG',
    name: 'Bulgarian Lev',
    symbol: 'лв',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'BHD',
    flag: 'BH',
    name: 'Bahraini Dinar',
    symbol: '.د.ب',
    position: 'before',
    decimals: 3,
  },
  {
    code: 'BIF',
    flag: 'BI',
    name: 'Burundian Franc',
    symbol: 'FBu',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'BMD',
    flag: 'BM',
    name: 'Bermudan Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BND',
    flag: 'BN',
    name: 'Brunei Dollar',
    symbol: '$',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'BOB',
    flag: 'BO',
    name: 'Bolivian Boliviano',
    symbol: '$b',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BRL',
    flag: 'BR',
    name: 'Brazilian Real',
    symbol: 'R$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BSD',
    flag: 'BS',
    name: 'Bahamian Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BTN',
    flag: 'BT',
    name: 'Bhutanese Ngultrum',
    symbol: 'Nu.',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BWP',
    flag: 'BW',
    name: 'Botswanan Pula',
    symbol: 'P',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BYN',
    flag: 'BY',
    name: 'Belarusian Ruble',
    symbol: 'р.',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'BZD',
    flag: 'BZ',
    name: 'Belize Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CAD',
    flag: 'CA',
    name: 'Canadian Dollar',
    symbol: '$',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'CDF',
    flag: 'CD',
    name: 'Congolese Franc',
    symbol: 'FC',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CHE',
    flag: 'CH',
    name: 'WIR Euro',
    symbol: '',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CHF',
    flag: 'CH',
    name: 'Swiss Franc',
    symbol: '',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CHW',
    flag: 'CH',
    name: 'WIR Franc',
    symbol: '',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CLF',
    flag: 'CL',
    name: 'Chilean Unit of Account (UF)',
    symbol: '',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'CLP',
    flag: 'CL',
    name: 'Chilean Peso',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CNY',
    flag: 'CN',
    name: 'Chinese Yuan',
    symbol: '¥',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'COP',
    flag: 'CO',
    name: 'Colombian Peso',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'COU',
    flag: 'CO',
    name: 'Colombian Real Value Unit',
    symbol: '',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CRC',
    flag: 'CR',
    name: 'Costa Rican Colón',
    symbol: '₡',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CUC',
    flag: 'CU',
    name: 'Cuban Convertible Peso',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CUP',
    flag: 'CU',
    name: 'Cuban Peso',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'CVE',
    flag: 'CV',
    name: 'Cape Verdean Escudo',
    symbol: '$',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'CZK',
    flag: 'CZ',
    name: 'Czech Koruna',
    symbol: 'Kč',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'DJF',
    flag: 'DJ',
    name: 'Djiboutian Franc',
    symbol: 'Fdj',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'DKK',
    flag: 'DK',
    name: 'Danish Krone',
    symbol: 'kr',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'DOP',
    flag: 'DO',
    name: 'Dominican Peso',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'DZD',
    flag: 'DZ',
    name: 'Algerian Dinar',
    symbol: 'دج',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'EGP',
    flag: 'EG',
    name: 'Egyptian Pound',
    symbol: 'E£',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'ERN',
    flag: 'ER',
    name: 'Eritrean Nakfa',
    symbol: 'Nfk',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'ETB',
    flag: 'ET',
    name: 'Ethiopian Birr',
    symbol: 'Br',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'EUR',
    flag: 'EU',
    name: 'Euro',
    symbol: '€',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'FJD',
    flag: 'FJ',
    name: 'Fijian Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'FKP',
    flag: 'FK',
    name: 'Falkland Islands Pound',
    symbol: '£',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'GBP',
    flag: 'GB',
    name: 'British Pound',
    symbol: '£',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'GEL',
    flag: 'GE',
    name: 'Georgian Lari',
    symbol: '₾',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'GHS',
    flag: 'GH',
    name: 'Ghanaian Cedi',
    symbol: 'GH₵',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'GIP',
    flag: 'GI',
    name: 'Gibraltar Pound',
    symbol: '£',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'GMD',
    flag: 'GM',
    name: 'Gambian Dalasi',
    symbol: 'D',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'GNF',
    flag: 'GN',
    name: 'Guinean Franc',
    symbol: 'FG',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'GTQ',
    flag: 'GT',
    name: 'Guatemalan Quetzal',
    symbol: 'Q',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'GYD',
    flag: 'GY',
    name: 'Guyanaese Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'HKD',
    flag: 'HK',
    name: 'Hong Kong Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'HNL',
    flag: 'HN',
    name: 'Honduran Lempira',
    symbol: 'L',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'HRK',
    flag: 'HR',
    name: 'Croatian Kuna',
    symbol: 'kn',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'HTG',
    flag: 'HT',
    name: 'Haitian Gourde',
    symbol: 'G',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'HUF',
    flag: 'HU',
    name: 'Hungarian Forint',
    symbol: 'Ft',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'IDR',
    flag: 'ID',
    name: 'Indonesian Rupiah',
    symbol: 'Rp',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'ILS',
    flag: 'IL',
    name: 'Israeli New Shekel',
    symbol: '₪',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'INR',
    flag: 'IN',
    name: 'Indian Rupee',
    symbol: '₹',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'IQD',
    flag: 'IQ',
    name: 'Iraqi Dinar',
    symbol: 'ع.د',
    position: 'before',
    decimals: 3,
  },
  {
    code: 'IRR',
    flag: 'IR',
    name: 'Iranian Rial',
    symbol: '﷼',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'ISK',
    flag: 'IS',
    name: 'Icelandic Króna',
    symbol: 'kr',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'JMD',
    flag: 'JM',
    name: 'Jamaican Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'JOD',
    flag: 'JO',
    name: 'Jordanian Dinar',
    symbol: 'JD',
    position: 'before',
    decimals: 3,
  },
  {
    code: 'JPY',
    flag: 'JP',
    name: 'Japanese Yen',
    symbol: '¥',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'KES',
    flag: 'KE',
    name: 'Kenyan Shilling',
    symbol: 'KSh',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'KGS',
    flag: 'KG',
    name: 'Kyrgystani Som',
    symbol: 'лв',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'KHR',
    flag: 'KH',
    name: 'Cambodian Riel',
    symbol: '៛',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'KMF',
    flag: 'KM',
    name: 'Comorian Franc',
    symbol: 'CF',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'KPW',
    flag: 'KP',
    name: 'North Korean Won',
    symbol: '₩',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'KRW',
    flag: 'KR',
    name: 'South Korean Won',
    symbol: '₩',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'KWD',
    flag: 'KW',
    name: 'Kuwaiti Dinar',
    symbol: 'KD',
    position: 'before',
    decimals: 3,
  },
  {
    code: 'KYD',
    flag: 'KY',
    name: 'Cayman Islands Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'KZT',
    flag: 'KZ',
    name: 'Kazakhstani Tenge',
    symbol: '₸',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'LAK',
    flag: 'LA',
    name: 'Laotian Kip',
    symbol: '₭',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'LBP',
    flag: 'LB',
    name: 'Lebanese Pound',
    symbol: 'L£',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'LKR',
    flag: 'LK',
    name: 'Sri Lankan Rupee',
    symbol: 'Rs',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'LRD',
    flag: 'LR',
    name: 'Liberian Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'LSL',
    flag: 'LS',
    name: 'Lesotho Loti',
    symbol: 'M',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'LYD',
    flag: 'LY',
    name: 'Libyan Dinar',
    symbol: 'LD',
    position: 'before',
    decimals: 3,
  },
  {
    code: 'MAD',
    flag: 'MA',
    name: 'Moroccan Dirham',
    symbol: '',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MDL',
    flag: 'MD',
    name: 'Moldovan Leu',
    symbol: 'lei',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MGA',
    flag: 'MG',
    name: 'Malagasy Ariary',
    symbol: 'Ar',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MKD',
    flag: 'MK',
    name: 'Macedonian Denar',
    symbol: 'ден',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MMK',
    flag: 'MM',
    name: 'Myanmar Kyat',
    symbol: 'K',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MNT',
    flag: 'MN',
    name: 'Mongolian Tugrik',
    symbol: '₮',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MOP',
    flag: 'MO',
    name: 'Macanese Pataca',
    symbol: 'MOP$',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'MRU',
    flag: 'MR',
    name: 'Mauritanian Ouguiya',
    symbol: 'UM',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MUR',
    flag: 'MU',
    name: 'Mauritian Rupee',
    symbol: 'Rs',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MVR',
    flag: 'MV',
    name: 'Maldivian Rufiyaa',
    symbol: 'Rf',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MWK',
    flag: 'MW',
    name: 'Malawian Kwacha',
    symbol: 'MK',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MXN',
    flag: 'MX',
    name: 'Mexican Peso',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MXV',
    flag: 'MX',
    name: 'Mexican Investment Unit',
    symbol: '',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MYR',
    flag: 'MY',
    name: 'Malaysian Ringgit',
    symbol: 'RM',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'MZN',
    flag: 'MZ',
    name: 'Mozambican Metical',
    symbol: 'MT',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'NAD',
    flag: 'NA',
    name: 'Namibian Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'NGN',
    flag: 'NG',
    name: 'Nigerian Naira',
    symbol: '₦',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'NIO',
    flag: 'NI',
    name: 'Nicaraguan Córdoba',
    symbol: 'C$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'NOK',
    flag: 'NO',
    name: 'Norwegian Krone',
    symbol: 'kr',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'NPR',
    flag: 'NP',
    name: 'Nepalese Rupee',
    symbol: 'Rs',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'NZD',
    flag: 'NZ',
    name: 'New Zealand Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'OMR',
    flag: 'OM',
    name: 'Omani Rial',
    symbol: '﷼',
    position: 'before',
    decimals: 3,
  },
  {
    code: 'PAB',
    flag: 'PA',
    name: 'Panamanian Balboa',
    symbol: 'B/.',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'PEN',
    flag: 'PE',
    name: 'Peruvian Sol',
    symbol: 'S/.',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'PGK',
    flag: 'PG',
    name: 'Papua New Guinean Kina',
    symbol: 'K',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'PHP',
    flag: 'PH',
    name: 'Philippine Piso',
    symbol: '₱',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'PKR',
    flag: 'PK',
    name: 'Pakistani Rupee',
    symbol: 'Rs',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'PLN',
    flag: 'PL',
    name: 'Polish Zloty',
    symbol: 'zł',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'PYG',
    flag: 'PY',
    name: 'Paraguayan Guarani',
    symbol: '₲',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'QAR',
    flag: 'QA',
    name: 'Qatari Rial',
    symbol: '﷼',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'RON',
    flag: 'RO',
    name: 'Romanian Leu',
    symbol: 'lei',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'RSD',
    flag: 'RS',
    name: 'Serbian Dinar',
    symbol: 'Дин.',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'RUB',
    flag: 'RU',
    name: 'Russian Ruble',
    symbol: '₽',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'RWF',
    flag: 'RW',
    name: 'Rwandan Franc',
    symbol: 'RF',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'SAR',
    flag: 'SA',
    name: 'Saudi Riyal',
    symbol: '﷼',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SBD',
    flag: 'SB',
    name: 'Solomon Islands Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SCR',
    flag: 'SC',
    name: 'Seychellois Rupee',
    symbol: '₨',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SDG',
    flag: 'SD',
    name: 'Sudanese Pound',
    symbol: 'S$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SEK',
    flag: 'SE',
    name: 'Swedish Krona',
    symbol: 'kr',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SGD',
    flag: 'SG',
    name: 'Singapore Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SHP',
    flag: 'SH',
    name: 'St. Helena Pound',
    symbol: '£',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'SLL',
    flag: 'SL',
    name: 'Sierra Leonean Leone',
    symbol: 'Le',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'SOS',
    flag: 'SO',
    name: 'Somali Shilling',
    symbol: 'S',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SRD',
    flag: 'SR',
    name: 'Surinamese Dollar',
    symbol: '$',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'SSP',
    flag: 'SS',
    name: 'South Sudanese Pound',
    symbol: '£',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'STN',
    flag: 'ST',
    name: 'São Tomé & Príncipe Dobra',
    symbol: 'Db',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SVC',
    flag: 'SV',
    name: 'Salvadoran Colón',
    symbol: '$',
    position: 'after',
    decimals: 2,
  },
  {
    code: 'SYP',
    flag: 'SY',
    name: 'Syrian Pound',
    symbol: '£',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'SZL',
    flag: 'SZ',
    name: 'Swazi Lilangeni',
    symbol: 'E',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'THB',
    flag: 'TH',
    name: 'Thai Baht',
    symbol: '฿',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'TJS',
    flag: 'TJ',
    name: 'Tajikistani Somoni',
    symbol: 'SM',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'TMT',
    flag: 'TM',
    name: 'Turkmenistani Manat',
    symbol: 'T',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'TND',
    flag: 'TN',
    name: 'Tunisian Dinar',
    symbol: 'د.ت',
    position: 'before',
    decimals: 3,
  },
  {
    code: 'TOP',
    flag: 'TO',
    name: 'Tongan Paʻanga',
    symbol: 'T$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'TRY',
    flag: 'TR',
    name: 'Turkish Lira',
    symbol: '₺',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'TTD',
    flag: 'TT',
    name: 'Trinidad & Tobago Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'TWD',
    flag: 'TW',
    name: 'New Taiwan Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'TZS',
    flag: 'TZ',
    name: 'Tanzanian Shilling',
    symbol: 'TSh',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'UAH',
    flag: 'UA',
    name: 'Ukrainian Hryvnia',
    symbol: '₴',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'UGX',
    flag: 'UG',
    name: 'Ugandan Shilling',
    symbol: 'USh',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'USD',
    flag: 'US',
    name: 'US Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'UYU',
    flag: 'UY',
    name: 'Uruguayan Peso',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'UZS',
    flag: 'UZ',
    name: 'Uzbekistani Som',
    symbol: 'лв',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'VES',
    flag: 'VE',
    name: 'Venezuelan Bolívar',
    symbol: 'Bs.S',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'VND',
    flag: 'VN',
    name: 'Vietnamese Dong',
    symbol: '₫',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'VUV',
    flag: 'VU',
    name: 'Vanuatu Vatu',
    symbol: 'VT',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'WST',
    flag: 'WS',
    name: 'Samoan Tala',
    symbol: 'WS$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'XAF',
    flag: 'CF',
    name: 'Central African CFA Franc',
    symbol: 'FCFA',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'XCD',
    flag: 'AG',
    name: 'East Caribbean Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'XOF',
    flag: 'ML',
    name: 'West African CFA Franc',
    symbol: 'CFA',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'XPF',
    flag: 'PF',
    name: 'CFP Franc',
    symbol: '₣',
    position: 'before',
    decimals: 0,
  },
  {
    code: 'YER',
    flag: 'YE',
    name: 'Yemeni Rial',
    symbol: '﷼',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'ZAR',
    flag: 'ZA',
    name: 'South African Rand',
    symbol: 'R',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'ZMW',
    flag: 'ZM',
    name: 'Zambian Kwacha',
    symbol: 'ZK',
    position: 'before',
    decimals: 2,
  },
  {
    code: 'ZWL',
    flag: 'ZW',
    name: 'Zimbabwean Dollar',
    symbol: '$',
    position: 'before',
    decimals: 2,
  },
];
