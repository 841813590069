import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

const paragraphStyle = css`
  line-height: 1.4;
  color: ${Colors.text};
`;

export const Section = styled.div`
  padding: 2rem;
  border-radius: 0.3rem 0.3rem 0 0;
  ${paragraphStyle};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

export const Header = styled.h4`
  margin: 0;
  padding: 1rem;
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: ${({ theme, headerColor = null }) =>
    headerColor ? Colors[headerColor] : theme.colors.primary};
  font-weight: 500;
  text-align: center;
  color: white;
`;

export const Card = styled.div`
  border-radius: 0.2rem;
  background-color: ${Colors.white};

  & + & {
    margin-top: 2.4rem;
  }

  ${Section} + ${Section} {
    padding-top: 0;
  }

  ${({ isSimple }) =>
    isSimple
      ? `border: .1rem solid ${Colors.outline};`
      : `box-shadow: 0 5px 4px 0 ${Colors.grey};`}

  & ${Header} {
    ${({ isSimple }) => isSimple && 'margin: -.1rem;'}
  }
`;

export const SectionTitle = styled.h4`
  margin: 0 0 1rem 0;
  color: ${({ theme }) => theme.colors.primaryDark};
  display: flex;
  align-items: center;
`;

export const SectionTitleTooltip = styled.span`
  margin-left: 0.7rem;
  position: relative;
`;
