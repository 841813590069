import i18n from './i18n';

export const FlashDefinition = {
  UpdateAccountSuccess: {
    context: [{ detail: i18n.flashSavedSuccess }],
    status: 'success',
  },
  UpdatePasswordSuccess: {
    context: [{ detail: i18n.flashUpdatePasswordSuccess }],
    status: 'success',
  },
  UpdateAccountWrongPasswordError: {
    context: [{ detail: i18n.flashOldPasswordWrong }],
    status: 'error',
  },
  UpdateAccountPasswordNotSameError: {
    context: [{ detail: i18n.flashPasswordNotSame }],
    status: 'error',
  },
  UpdateAccountPasswordError: {
    context: [{ detail: i18n.flashPasswordError }],
    status: 'error',
  },
  UpdateAccountPasswordBlockedError: {
    context: [{ detail: i18n.flashPasswordBlocked }],
    status: 'error',
  },
  UpdateAccountEmailTakeError: {
    context: [{ detail: i18n.flashEmailTaken }],
    status: 'error',
  },
  CancelChangeEmail: {
    context: [{ detail: i18n.flashCancelChangeEmail }],
    status: 'success',
  },
  UpdateAvatarSuccess: {
    context: [{ detail: i18n.flashAvatarSaved }],
    status: 'success',
  },
  UpdateAvatarError: {
    context: [{ detail: i18n.flashAvatarError }],
    status: 'error',
  },
  UpdateNotificationsSettingsSuccess: {
    context: [{ detail: i18n.flashNotificationsSettingsUpdated }],
    status: 'success',
  },
  ResendVerificationEmail: {
    context: [{ detail: i18n.flashResendVerificationEmail }],
    status: 'success',
  },
};
