import {
  isBoolean,
  isDate,
  isEmpty,
  isString,
  isObject,
  isArray,
  isNumber,
  uniq,
  isFunction,
  has,
} from 'lodash';

import { isValidCompanyName, isValidPersonName } from './name';
import { isValidEmail } from './email';
import { isValidSsnNumber } from './ssn-number';
import { isValidLink } from './url';
import { isValidZipCode } from './zip-code';
import {
  isValidMoney,
  isValidRangeMoneyInputs,
  isValidMinMaxRange,
} from './money';

/**
 * @function typeSpecificValidations
 * @param {string|number|bool} value
 * @param {string} type
 */
function typeSpecificValidations(value, type, required = false) {
  if (type === 'company_name') {
    return !isValidCompanyName(value) || value.length < 3;
  }
  if (type === 'first_name' || type === 'last_name') {
    return !isValidPersonName(value);
  }
  if (type === 'email') {
    return !isValidEmail(value);
  }
  if (type === 'ssn_number') {
    return !isValidSsnNumber(value);
  }
  if (type === 'namedropdown') {
    return !(
      isValid({ inputValue: value.dropdown, required }) &&
      isValid({ inputValue: value.named, required })
    );
  }
  if (type === 'link') {
    return !isValidLink(value);
  }
  if (type === 'zip_code') {
    return !isValidZipCode(value);
  }
  if (type === 'money_range' && isObject(value)) {
    return (
      !isValidRangeMoneyInputs(value, required) || !isValidMinMaxRange(value)
    );
  }
  if (type === 'money' || type === 'money_decimal' || type === 'money_range') {
    if (has(value, 'min') && has(value, 'max')) {
      return (
        !isValidMoney(value.min, required) || !isValidMoney(value.max, required)
      );
    }
    return !isValidMoney(value, required);
  }
  if (type === 'number') {
    return !isNumber(Number.parseFloat(value));
  }
  if (type === 'option' && required) {
    return valueDontExist(has(value, 'value') ? value.value : value);
  }
  if (type === 'tags_dropdown' && required) {
    return valueDontExist(has(value, 'value') ? value.value : value);
  }

  return false;
}

export function valueDontExist(value) {
  if (value === undefined || value === null) {
    return true;
  }

  if (isString(value) && (value.match(/\S/) === null || value === 'none')) {
    return true;
  }

  if (isObject(value) && isEmpty(value)) {
    return true;
  }

  if (isArray(value) && isEmpty(value)) {
    return true;
  }

  return false;
}

/**
 * @function isValid
 * @param {object} property
 * @param {string|number|bool} property.inputValue
 * @param {boolean} property.shouldValidate
 * @param {string} property.inputType
 * @param {boolean} property.required
 * @param {number} property.min
 * @return {boolean}
 */
export const isValid = function isValid(property) {
  const {
    inputValue,
    shouldValidate = true,
    inputType = 'text',
    required = false,
    min,
    max,
    customValidation = undefined,
  } = property;

  if (!shouldValidate) {
    return true;
  }

  if (!required && valueDontExist(inputValue)) {
    return true;
  }

  if (required && valueDontExist(inputValue)) {
    return false;
  }

  if (inputType === 'calendar' && isFunction(customValidation)) {
    return !customValidation();
  }

  if (inputType === 'number' && min && isNumber(min) && inputValue < min) {
    return false;
  }

  if (inputType === 'checkbox') {
    return required ? inputValue : true;
  }

  if (inputType === 'checkbox-list') {
    return !isEmpty(inputValue);
  }

  if (isDate(inputValue)) {
    return true;
  }

  if (inputType === 'money_range' && isFunction(customValidation)) {
    return !customValidation(inputValue);
  }

  if (isObject(inputValue) && isEmpty(inputValue)) {
    return false;
  }

  if (
    (inputType === 'tags' ||
      inputType === 'tags_autocomplete' ||
      inputType === 'tags_dropdown') &&
    ((max && inputValue.length > max) || (min && inputValue.length < min))
  ) {
    return false;
  }

  if (typeSpecificValidations(inputValue, inputType, required)) {
    return false;
  }

  return true;
};

/**
 * @function isPropertyInvalid
 * @param {object} property
 * @param {object} property.obj
 * @param {string} property.key
 * @param {string} property.fieldType
 * @param {boolean} property.required
 * @return {boolean} obj[key] is invalid
 */
export function isPropertyInvalid({
  obj,
  key,
  fieldType = 'text',
  required = false,
}) {
  const blankField = valueDontExist(obj[key]);

  if (required && blankField) {
    return true;
  }

  if (!required && blankField) {
    return false;
  }

  return typeSpecificValidations(obj[key], fieldType, required);
}

/**
 * @function anyPropertyInvalid
 * @param {object} state
 * @param {object} fields
 * @param {function} customValidation if you want to test only an
 *  specific field for others return null;
 * @return {boolean} check if any property of state is invalid
 */
export function anyPropertyInvalid(
  state,
  fields,
  customValidation = undefined
) {
  return Object.keys(fields).some((definition) => {
    const customValidationError = customValidation
      ? customValidation(definition, state, fields[definition])
      : null;

    return (
      customValidationError ||
      isPropertyInvalid({
        obj: state,
        key: definition,
        fieldType: fields[definition].inputType,
        required: fields[definition].required,
      })
    );
  });
}

/**
 * @param {object} state
 * @param {object} fields
 * @param {function} customErrors
 * @return {array} array of error keys
 */
export function getErrors(state, fields, customErrors = undefined) {
  const errors = Object.keys(fields).reduce((acc, definition) => {
    const value = state[definition];
    const blankField = valueDontExist(value);
    const { required, inputType } = fields[definition];

    if (required && blankField) {
      acc.push('missingFieldsValidation');
    }
    if (
      inputType === 'namedropdown' &&
      required &&
      (valueDontExist(value.dropdown) || valueDontExist(value.named))
    ) {
      acc.push('missingFieldsValidation');
    }
    if (!blankField) {
      if (inputType === 'email' && !isValidEmail(value)) {
        acc.push('emailInvalid');
      }
      if (inputType === 'link' && !isValidLink(value)) {
        acc.push('linkInvalid');
      }
      if (inputType === 'password' && !state.passwordValid) {
        acc.push('passwordWeak');
      }
      if (inputType === 'ssn_number' && !isValidSsnNumber(value)) {
        acc.push('ssnInvalid');
      }
      if (inputType === 'zip_code' && !isValidZipCode(value)) {
        acc.push('zipInvalid');
      }
      if (inputType === 'company_name') {
        if (value.length < 3) {
          acc.push('companyNameLengthInvalid');
        }
        if (!isValidCompanyName(value)) {
          acc.push('companyNameInvalid');
        }
      }
      if (inputType === 'first_name' && !isValidPersonName(value)) {
        acc.push('firstNameInvalid');
      }
      if (inputType === 'last_name' && !isValidPersonName(value)) {
        acc.push('lastNameInvalid');
      }
      if (
        (inputType === 'money' || inputType === 'money_decimal') &&
        !isValidMoney(value, required)
      ) {
        acc.push('missingFieldsValidation');
      }
      if (
        (inputType === 'option' || inputType === 'tags_dropdown') &&
        required &&
        valueDontExist(has(value, 'value') ? value.value : value)
      ) {
        acc.push('missingFieldsValidation');
      }
    }

    return acc;
  }, []);

  const customErrorKeys = customErrors ? customErrors(state) : [];
  return uniq([].concat(errors, customErrorKeys));
}

export const anyPropertyFilled = (state, fields) =>
  Object.keys(fields).some((definition) => !isEmpty(state[definition]));
