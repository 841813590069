import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reject, map } from 'lodash';

import { fetchNotes, createNote, updateNote, deleteNote } from './actions';

class CompanyNotesProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
    };
  }

  componentDidMount() {
    const { company, handleFetchNotes } = this.props;

    handleFetchNotes(company.id).then(({ notes }) => {
      this.setState({ notes });
    });
  }

  onCreateNote = (note) => {
    const { company, handleCreateNote } = this.props;

    return handleCreateNote(company.id, note).then((response) => {
      this.setState((state) => ({ notes: [...state.notes, response] }));
    });
  };

  onUpdateNote = (note) => {
    const { company, handleUpdateNote } = this.props;

    return handleUpdateNote(company.id, note).then((response) => {
      this.setState((state) => {
        const notes = map(state.notes, (n) =>
          n.id === response.id ? response : n
        );
        return { notes };
      });
    });
  };

  onDeleteNote = (note) => {
    const { company, handleDeleteNote } = this.props;

    return handleDeleteNote(company.id, note).then(() => {
      this.setState((state) => ({
        notes: reject(state.notes, { id: note.id }),
      }));
    });
  };

  render() {
    const { children } = this.props;
    const { notes } = this.state;

    return children({
      notes,
      onCreateNote: this.onCreateNote,
      onUpdateNote: this.onUpdateNote,
      onDeleteNote: this.onDeleteNote,
    });
  }
}

CompanyNotesProvider.propTypes = {
  children: PropTypes.func,
  company: PropTypes.object,
  handleFetchNotes: PropTypes.func,
  handleCreateNote: PropTypes.func,
  handleUpdateNote: PropTypes.func,
  handleDeleteNote: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    handleFetchNotes: (companyId) => dispatch(fetchNotes(companyId)),
    handleCreateNote: (companyId, note) =>
      dispatch(createNote(companyId, note)),
    handleUpdateNote: (companyId, note) =>
      dispatch(updateNote(companyId, note)),
    handleDeleteNote: (companyId, note) =>
      dispatch(deleteNote(companyId, note)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(CompanyNotesProvider);
