import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Icon } from 'components/elements/icon';

export const List = styled.dl`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.4;
  color: ${Colors.text};
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};

  & + & {
    margin-top: 2rem;
  }
`;

export const Term = styled.dt`
  flex: 0 1 ${({ isEqualWidth }) => (isEqualWidth ? 50 : 30)}%;
  padding: 1rem 2rem 1rem 0;
  border-top: ${({ isBordered }) => (isBordered ? 2 : 0)}px solid
    ${Colors.tealLighter};
  max-width: 50%;
  color: ${({ theme }) => theme.colors.primaryDark};

  &:nth-child(1) {
    border-top: none;
  }
`;

export const Description = styled.dd`
  flex: 1 1 50%;
  margin-left: 0;
  padding: 1rem 0;
  border-top: ${({ isBordered }) => (isBordered ? 2 : 0)}px solid
    ${Colors.tealLighter};
  min-width: 50%;
  word-break: break-word;
  white-space: pre-line;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.typography.text.normal};

  &:nth-child(2) {
    border-top: none;
  }

  & > svg {
    margin: 0 0.8rem 0 0;
  }

  & a {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  & svg {
    margin-left: 0.8rem;
  }
`;

export const TooltipIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.primaryDark,
}))``;
