import PropTypes from 'prop-types';
import { useRef } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { get, isObject, isEmpty } from 'lodash';

import { Icon } from 'components/elements/icon';
import { ValidationErrorIndicator } from 'components/form/validationErrorIndicator';

import { isValid } from 'components/utils/form-utils';
import {
  acceptedOrRejected,
  customValidator,
  filesGetter,
  returnOrNotifyUploadedFiles,
} from 'components/utils/form-dropzone';
import { acceptedMimeTypes } from 'utils/fileFunctions';

import * as styled from './styles/upload';
import i18n from './utils/i18n';

const UploadComponent = function Upload(props) {
  const {
    intl,
    children,
    className,
    shouldValidate,
    detail,
    files,
    required,
    loading,
    disabled,
    clearable,
    onChange,
    onFocus,
    buttonDetail,
    acceptedFormats,
    acceptedDimensions,
    maxFileSize,
  } = props;
  const isInputValid = isValid({ inputValue: files, shouldValidate, required });
  const uploadDetail = isObject(detail) ? intl.formatMessage(detail) : detail;
  const buttonLabel = isObject(buttonDetail)
    ? intl.formatMessage(buttonDetail)
    : buttonDetail;
  const placeholderDetail = !isEmpty(files)
    ? get(files, '0.filename') || get(files, '0.file.name')
    : uploadDetail;
  const restrictions = {
    maxFileSize,
    acceptedFormats,
    acceptedDimensions,
  };
  const dropzoneRef = useRef();
  const openSelectFile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dropzoneRef.current.open();
    if (onFocus) {
      onFocus(event);
    }
  };
  const clearFile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onChange([]);
  };
  const onDrop = (filesToUpload, rejectedFiles) =>
    onChange(
      returnOrNotifyUploadedFiles(
        acceptedOrRejected(filesToUpload, rejectedFiles, restrictions)
      )
    );

  return (
    <styled.InputContainer
      className={className}
      isValid={isInputValid}
      disabled={disabled}
    >
      {shouldValidate && !isInputValid && <ValidationErrorIndicator />}
      <Dropzone
        accept={acceptedMimeTypes(acceptedFormats)}
        maxSize={maxFileSize}
        validator={(file) => customValidator(file, restrictions)}
        disabled={disabled || loading}
        ref={(node) => {
          dropzoneRef.current = node;
        }}
        getFilesFromEvent={(event) => filesGetter(event, restrictions)}
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps }) =>
          children ? (
            children({
              getRootProps,
              getInputProps,
              onDrop,
              openSelectFile,
              clearFile,
              placeholderDetail,
              files,
            })
          ) : (
            <styled.DropzoneBox
              {...getRootProps()}
              onFocus={(event) => {
                if (onFocus) {
                  onFocus(event);
                }
                getRootProps().onFocus(event);
              }}
            >
              <styled.Detail>{placeholderDetail}</styled.Detail>
              {clearable && !disabled && !isEmpty(files) && (
                <styled.Button
                  size="small"
                  color="tealLighter"
                  onClick={clearFile}
                >
                  <Icon icon="XCircle" />
                  <FormattedMessage {...i18n.buttonRemove} />
                </styled.Button>
              )}
              <styled.Button
                size="small"
                loading={loading}
                disabled={disabled}
                onClick={(event) => !disabled && openSelectFile(event)}
              >
                <Icon icon="UploadSimple" />
                {buttonLabel || <FormattedMessage {...i18n.buttonSelect} />}
              </styled.Button>
              <input {...getInputProps()} />
            </styled.DropzoneBox>
          )
        }
      </Dropzone>
    </styled.InputContainer>
  );
};

UploadComponent.defaultProps = {
  acceptedFormats: ['pdf'],
  maxFileSize: 5 * 1024 * 1024,
};

export const UploadPropTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  intl: PropTypes.object,
  className: PropTypes.string,
  shouldValidate: PropTypes.bool,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  files: PropTypes.array,
  buttonDetail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  acceptedFormats: PropTypes.array,
  acceptedDimensions: PropTypes.shape({
    min: {
      width: PropTypes.number,
      height: PropTypes.number,
    },
    max: {
      width: PropTypes.number,
      height: PropTypes.number,
    },
  }),
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

UploadComponent.propTypes = UploadPropTypes;

export const Upload = injectIntl(UploadComponent);
