import { find } from 'lodash';

import * as styled from './styles/emoji';
import { EMOJI_DATA } from './constants/emoji';
import type { EmojiName } from './types';

type EmojiProps = {
  name: EmojiName;
};

export const Emoji = function Emoji({ name, ...props }: EmojiProps) {
  const emoji = find(EMOJI_DATA, { name });

  if (!emoji) {
    throw new Error(`No emoji found with the name: ${name}`);
  }

  const { code } = emoji;

  return <styled.Emoji code={code} {...props} />;
};
