import { defineMessages } from 'react-intl';

import theme from 'themes';

export default defineMessages({
  searchPlaceholder: {
    id: 'app.containers.SupplierHub.scenes.searchPlaceholder',
    defaultMessage: 'Search by name',
  },
  locationPlaceholder: {
    id: 'app.containers.SupplierHub.scenes.locationPlaceholder',
    defaultMessage: 'Headquarters',
  },
  industriesPlaceholder: {
    id: 'app.containers.SupplierHub.scenes.industriesPlaceholder',
    defaultMessage: 'Client industries',
  },
  specializationsPlaceholder: {
    id: 'app.containers.SupplierHub.scenes.specializationsPlaceholder',
    defaultMessage: 'Role specializations',
  },
  staffingAgency: {
    id: 'app.containers.SupplierHub.scenes.staffingAgency',
    defaultMessage: 'Staffing agency?',
  },
  staffingAgencyLink: {
    id: 'app.containers.SupplierHub.scenes.staffingAgencyLink',
    defaultMessage: `Join ${theme.platformName}'s Vendor Hub now`,
  },
  suppliersCounter: {
    id: 'app.containers.SupplierHub.scenes.suppliersCounter',
    defaultMessage:
      '{counter, number} {counter, plural, one {vendor} other {vendors}}',
  },
  supplierHeadquarter: {
    id: 'app.containers.SupplierHub.scenes.supplierHeadquarter',
    defaultMessage: 'Headquarters',
  },
  supplierPhoneNumber: {
    id: 'app.containers.SupplierHub.scenes.supplierPhoneNumber',
    defaultMessage: 'Phone number',
  },
  supplierWebsite: {
    id: 'app.containers.SupplierHub.scenes.supplierWebsite',
    defaultMessage: 'Website',
  },
  noVendorsFoundTitle: {
    id: 'app.containers.SupplierHub.scenes.noVendorsFoundTitle',
    defaultMessage: 'No matching vendors found.',
  },
  noVendorsFoundExplanation: {
    id: 'app.containers.SupplierHub.scenes.noVendorsFoundExplanation',
    defaultMessage:
      'The combination of filters you used has no results. Clear all filters or try different search criteria.',
  },
  deleteAllFilters: {
    id: 'app.containers.SupplierHub.scenes.deleteAllFilters',
    defaultMessage: 'Delete all filters',
  },
  clearAllFilters: {
    id: 'app.containers.SupplierHub.scenes.clearAllFilters',
    defaultMessage: 'Clear all filters',
  },
  viewProfile: {
    id: 'app.containers.SupplierHub.scenes.viewProfile',
    defaultMessage: 'View profile',
  },
  na: {
    id: 'app.containers.SupplierHub.scenes.na',
    defaultMessage: 'n/a',
  },
  ratingCount: {
    id: 'app.containers.CompanyProfile.Ratings.ratingCount',
    defaultMessage:
      '({count, plural, =0 {0 ratings} =1 {1 rating} other {{count} ratings}})',
  },
});
