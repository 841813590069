import { info } from 'js-logger';
import { get } from 'lodash';

import { removeSensitiveData } from 'components/utils/object';

import { axiosInstance as axios } from './api';

export const request = (options) => {
  info(`[API/${options.method}] Request:`, removeSensitiveData(options));

  return axios(options)
    .then((response) => {
      info(`[API/${options.method}] Response:`, removeSensitiveData(response));

      // `data` is the actual response that was provided by the server
      // if error, no `data` is provided, therefore return the full error
      return get(response, 'data', response);
    })
    .catch((error) => {
      info(
        `[API/${options.method}] Response:`,
        removeSensitiveData(get(error, 'response'))
      );

      throw error;
    });
};
