import { Component } from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { ButtonDropdownMenu } from 'components/navigation/ButtonDropdownMenu';
import i18n from '../utils/i18n';
import { companyType as getCompanyType } from '../utils/helpers';
import * as styled from '../styles/companyTypeButton';

const companyProfileTypeColors = {
  vendor: 'grape',
  endClient: 'blue',
  vendorPartner: 'teal',
  blockedVendor: 'red',
};

const companyProfileTypeName = {
  vendor: i18n.vendor,
  endClient: i18n.endClient,
  vendorPartner: i18n.vendorPartner,
  blockedVendor: i18n.blockedVendor,
};

export class CompanyTypeButton extends Component {
  getCompanyType = () => {
    const { company, blockedCompany } = this.props;

    if (blockedCompany) {
      return 'blockedVendor';
    }

    return getCompanyType(company);
  };

  getCompanyColor = () => companyProfileTypeColors[this.getCompanyType()];

  handleModalTrigger = (context) => () => {
    const { openModal, company } = this.props;
    openModal({ context, company });
  };

  renderButton = ({ onClick, active }) => {
    const color = this.getCompanyColor();
    const companyType = this.getCompanyType();

    return (
      <styled.DropdownButton size="medium" color={color} onClick={onClick}>
        <FormattedMessage {...companyProfileTypeName[companyType]} />
        <styled.ButtonArrowIcon icon={active ? 'CaretUp' : 'CaretDown'} />
      </styled.DropdownButton>
    );
  };

  render() {
    const companyType = this.getCompanyType();
    const color = companyProfileTypeColors[companyType];

    const menuItems = compact([
      companyType !== 'blockedVendor' && {
        text: i18n.blockVendor,
        description: i18n.blockVendorDescription,
        onClick: this.handleModalTrigger('block-vendor'),
        icon: 'XCircle',
      },
      companyType === 'blockedVendor' && {
        text: i18n.unblockVendor,
        description: i18n.unblockVendorDescription,
        onClick: this.handleModalTrigger('unblock-vendor'),
        icon: 'Prohibit',
      },
    ]);

    return (
      <styled.DropdownWrapper>
        <ButtonDropdownMenu
          buttonFn={this.renderButton}
          items={menuItems}
          position="right"
          color={color}
          size="small"
        />
      </styled.DropdownWrapper>
    );
  }
}

CompanyTypeButton.propTypes = {
  company: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  blockedCompany: PropTypes.object,
};
