import { Component } from 'react';
import PropTypes from 'prop-types';

export const FirebaseAPI = () =>
  new Promise((resolve, reject) => {
    const getMessaging = (success, failure, timeout) => {
      setTimeout(() => {
        if (window.firebase && window.firebaseMessaging) {
          success(window.firebaseMessaging);
        } else {
          failure('not_supported');
        }
      }, timeout);
    };
    getMessaging(
      resolve,
      () => {
        getMessaging(resolve, reject, 1000);
      },
      0
    );
  });

/* eslint no-param-reassign:0 */
export class Firebase extends Component {
  constructor(props) {
    super(props);

    ((w, d) => {
      this.insertScript(d, '//www.gstatic.com/firebasejs/6.2.0/firebase-app.js')
        .then(() =>
          this.insertScript(
            d,
            '//www.gstatic.com/firebasejs/6.2.0/firebase-messaging.js'
          )
        )
        .then(() => this.initScript(w));
    })(window, document);
  }

  shouldComponentUpdate() {
    return false;
  }

  insertScript = (d, src) => {
    const elem = d.createElement('script');
    elem.type = 'text/javascript';
    elem.src = src;
    elem.async = 'true';

    return new Promise((resolve) => {
      const init = () => resolve();

      if (elem.readyState) {
        // IE
        elem.onreadystatechange = () => {
          if (elem.readyState === 'loaded' || elem.readyState === 'complete') {
            elem.onreadystatechange = null;
            init();
          }
        };
      } else {
        // other browsers
        elem.onload = init;
      }

      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(elem, x);
    });
  };

  initScript = (w) => {
    const { config, vapidKey } = this.props;

    if (w.firebase) {
      w.firebase.initializeApp(config);
      if (w.firebase.messaging.isSupported()) {
        w.firebaseMessaging = w.firebase.messaging();
        w.firebaseMessaging.usePublicVapidKey(vapidKey);
      }
    }
  };

  render() {
    return null;
  }
}

Firebase.propTypes = {
  config: PropTypes.object,
  vapidKey: PropTypes.string,
};
