import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

export const ModalView = styled.div`
  display: flex;
  flex-direction: column;
  width: 74rem;
  min-height: 20rem;
  max-height: 80vh;
  padding: 2rem 3rem;
  position: relative;
  background: ${Colors.white};
  border-radius: 0.6rem;
  ${({ theme }) => theme.typography.text.normal}
  font-weight: 500;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  color: ${Colors.tealDark};
  ${({ theme }) => theme.typography.headline.h2}
  font-weight: 600;
  border-radius: 0.6rem 0.6rem 0 0;
`;

export const ModalSubheader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-bottom: 0.8rem;
`;

export const DocumentsCounter = styled.div`
  color: ${Colors.greyDarker};
`;

export const DocumentsTable = styled.div`
  width: 68rem;
  border: solid 1px ${Colors.outline};
  border-radius: 0.3rem;
  overflow: auto;
`;

export const DocumentsEmptyState = styled(DocumentsTable)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6.8rem 0;

  & img {
    width: 8rem;
  }
`;

export const DocumentTableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem;
  font-size: 1.3rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const DocumentItemName = styled.span`
  width: 21rem;
  padding: 0 0.8rem;
  color: ${Colors.tealDark};
  font-weight: 500;
`;

export const DocumentItemStat = styled.span`
  padding-right: 0.8rem;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 0.8rem;
  }
`;

export const DocumentItemType = styled(DocumentItemStat)`
  width: 10rem;
`;

export const DocumentItemDate = styled(DocumentItemStat)`
  width: 12.5rem;
`;

export const DocumentItemSize = styled(DocumentItemStat)`
  width: 9rem;
`;

export const ButtonWrapper = styled.div`
  padding-left: 1.7rem;
`;
