import styled, { css } from 'styled-components';

import { Colors, mediaSizes } from 'components/utils/styles/ui';
import { EmptyState } from 'components/misc/EmptyState';
import { Icon } from 'components/elements/icon';

import { SupplierCard as SupplierCardBase } from '../supplierCard';
import { mediaMaxWidth } from '../utils/media';
import { isExternalContext } from '../utils/functions';

const media = mediaMaxWidth(mediaSizes.medium);

export const SupplierCard = styled(SupplierCardBase)`
  margin: 2rem 3rem 6rem 3rem;
  border-radius: 0.5rem;

  &:first-child {
    margin-top: 4rem;
  }
`;

export const SearchResultsContainer = styled.div`
  position: ${({ showResults }) => (showResults ? 'relative' : 'initial')};
  z-index: 1;
  width: 50%;
  max-width: 50rem;
  transform: translateX(${({ showResults }) => (showResults ? '0' : '-100%')});
  background-color: ${Colors.white};
  transition: all 0.3s;
`;

export const ClearFiltersButton = styled.span`
  font-size: 1.5rem;
  padding: 0.8rem 1.3rem;
  color: ${Colors.white};
  background-color: ${Colors.textLighter};
  border-radius: 0.3rem;
  cursor: pointer;
  margin-right: 3rem;
`;

export const ClearFilterIcon = styled(Icon).attrs({ color: Colors.white })`
  margin-top: -0.3rem;
  margin-right: 0.7rem;
`;

const handleVisibilitySwitchTop = (showCompleteProfileBanner, context) => {
  if (isExternalContext(context)) return 6.2;
  if (showCompleteProfileBanner) return 16.2;
  return 11.2;
};
export const VisibilitySwitchWrapper = styled.div`
  position: sticky;
  margin-left: 100%;
  top: ${({ showCompleteProfileBanner, context }) =>
    handleVisibilitySwitchTop(showCompleteProfileBanner, context)}rem;
  right: -2.1rem;
  width: 2.1rem;
  height: 4.3rem;
  z-index: 2;

  ${media`
    top: ${({ context }) => (isExternalContext(context) ? '20rem' : '25rem')};
  `}
`;

export const VisibilitySwitch = styled.div`
  padding-top: 1.5rem;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: ${Colors.white};
  border-radius: 0 0 0.3rem 0;
`;

export const SuppliersCounter = styled.div`
  color: ${Colors.greyDarker};
  padding-left: 3rem;
  ${({ showResults }) => (showResults ? '' : 'display: none;')}
`;

export const SuppliersContainer = styled.div`
  margin-top: ${({ context }) =>
    isExternalContext(context) ? '4rem' : '-1rem'};
`;

export const NoData = styled(EmptyState)`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
`;

const externalFixedWrapperMixin = css`
  margin-top: -9.3rem;
  top: 6rem;

  ${media`
    top: 20rem;
  `}
`;
export const FixedWrapper = styled.div`
  position: sticky;
  margin-top: -4.3rem;
  top: 11rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  background-color: ${Colors.white};
  z-index: 999;

  ${media`
    top: 25rem;
  `}

  ${({ context }) => context === 'external' && externalFixedWrapperMixin}
`;
