import { ReactNode, useState, useRef, useContext, createContext } from 'react';
import { noop, isFunction } from 'lodash';
import { useDropzone } from 'react-dropzone';
import type { FileRejection } from 'react-dropzone';

import { Modal } from 'components/overlay/Modal';
import { ImageCrop } from 'components/visual/imageCrop';
import { acceptedMimeTypes } from 'utils/fileFunctions';
import {
  acceptedOrRejected,
  returnOrNotifyUploadedFile,
} from 'components/utils/form-dropzone';

const acceptedFormats = ['image'];
const maxFileSize = 5 * 1024 * 1024;

type ContextProps = {
  onOpen?: (fn: CropCallback) => void;
};

export const Context = createContext<ContextProps>({});

export const useImageSelectContext = () => useContext(Context);

type ProviderProps = {
  children?: ReactNode | undefined;
};

type CropCallback = (cropped: File) => void | Promise<File>;

export const Provider = (props: ProviderProps) => {
  const { children } = props;
  const [image, setImage] = useState<File | null>(null);
  const onChange = useRef<CropCallback>(noop);

  const onDrop = (images: File[], rejectedImages: FileRejection[]) => {
    const files = acceptedOrRejected(images, rejectedImages, {
      maxFileSize,
      acceptedFormats,
    });
    const accepted = returnOrNotifyUploadedFile(files);

    if (accepted) {
      setImage(accepted);
    }
  };

  const onCrop = (cropped: File) => {
    const result = onChange.current(cropped);

    if (result instanceof Promise) {
      result.then(onClose);
    } else {
      onClose();
    }
  };

  const dropzoneRef = useDropzone({
    accept: acceptedMimeTypes(acceptedFormats),
    maxSize: maxFileSize,
    onDrop,
  });

  const onOpen = (fn: CropCallback) => {
    dropzoneRef.open();
    onChange.current = fn;
  };

  const onClose = () => {
    setImage(null);
    onChange.current = noop;
  };

  const value = { onOpen };

  return (
    <Context.Provider value={value}>
      {isFunction(children) ? children(value) : children}
      <input {...dropzoneRef.getInputProps()} />
      {image && (
        <Modal
          noCloseOnBackgroundClick
          item={{
            type: 'custom',
            content: (
              <ImageCrop image={image} onCrop={onCrop} onCancel={onClose} />
            ),
          }}
          onClose={onClose}
        />
      )}
    </Context.Provider>
  );
};
