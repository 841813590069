import { push } from 'redux-first-history';
import { Config } from 'containers/App/config';
import Bowser from 'bowser';

import { httpPost, httpDelete, httpGet } from 'utils';
import { isProductionServer } from 'utils/checkEnvironment';
import { openPageLoading, closePageLoading } from 'containers/App/actions/ui';
import { ipv4Regex, ipv6Regex } from 'utils/regexes';

export function showCompanySettings(id) {
  return (dispatch) => {
    dispatch(push(`/admin/company/${id}/settings`));
  };
}

export function showCompanyUsers(id) {
  return (dispatch) => {
    dispatch(push(`/admin/company/${id}/users`));
  };
}

export function impersonate({ userId, companyId, targetPath }) {
  return (dispatch) => {
    dispatch(openPageLoading());

    const payload = {
      user_id: userId,
      company_id: companyId,
    };

    httpPost('/impersonate', payload)
      .then((response) => {
        const { jwt, exp, admin_membership_id: adminMembershipId } = response;

        localStorage.setItem('phoenixAuthToken', jwt);
        localStorage.setItem('phoenixExpToken', exp);
        localStorage.setItem('adminMembershipId', adminMembershipId);

        dispatch({
          type: 'SET_ADMIN_MEMBERSHIP_ID',
          adminMembershipId,
        });

        document.location.href = targetPath || '/';
      })

      /* eslint no-unused-vars:0 */
      .catch((error) => {
        dispatch(closePageLoading());
      });
  };
}

export function unimpersonate({ password }) {
  return (dispatch) => {
    dispatch(openPageLoading());

    const payload = { password };

    httpDelete('/impersonate', payload)
      .then((response) => {
        const { jwt, exp } = response;

        localStorage.setItem('phoenixAuthToken', jwt);
        localStorage.setItem('phoenixExpToken', exp);
        localStorage.removeItem('adminMembershipId');

        dispatch({
          type: 'DELETE_ADMIN_MEMBERSHIP_ID',
        });

        document.location.href = '/admin';
      })

      .catch((error) => {
        dispatch(closePageLoading());
      });
  };
}

function userDetailsFallback() {
  return new Promise((resolve) => {
    httpGet('https://freeipapi.com/api/json').then((freeIpApiResponse) =>
      resolve({
        ip: freeIpApiResponse.ipAddress,
        country_code: freeIpApiResponse.countryCode,
        country_name: freeIpApiResponse.countryName,
        region_code: 'n/a',
        region_name: freeIpApiResponse.regionName,
        city: freeIpApiResponse.cityName,
        zip: freeIpApiResponse.zipCode,
        latitude: freeIpApiResponse.latitude,
        longitude: freeIpApiResponse.longitude,
      })
    );
  });
}

function determineUserDetails() {
  return new Promise((resolve) => {
    httpGet(
      `https://api.ipstack.com/check?access_key=${Config.IPStackAccessKey}`
    )
      .then((ipstackResponse) => {
        const ip = ipstackResponse?.ip;
        if (ipv4Regex.test(ip) || ipv6Regex.test(ip)) {
          resolve(ipstackResponse);
        } else {
          userDetailsFallback().then(resolve);
        }
      })
      .catch(() => userDetailsFallback().then(resolve));
  });
}

export function logImpersonation(
  impersonatedUserId,
  impersonatedCompanyId,
  impersonationReason
) {
  return () =>
    new Promise((resolve, reject) => {
      if (isProductionServer(window)) {
        determineUserDetails()
          .then((response) => {
            const {
              parsedResult: {
                browser: { name: browserName, version: browserVersion },
                os: {
                  name: OSName,
                  version: OSVersion,
                  versionName: OSVersionName,
                },
                platform: { type: platformType },
                engine: { name: engineName, version: engineVersion },
              },
            } = Bowser.getParser(window.navigator.userAgent);

            const payload = {
              user_id: impersonatedUserId,
              company_id: impersonatedCompanyId,
              message: impersonationReason,
              user_agent: {
                ip_address: response.ip,
                browser_version: `${browserName} ${browserVersion}`,
                os_version: `${OSName} ${OSVersionName}, ${OSVersion}`,
                platform: `${platformType}`,
                engine: `${engineName} ${engineVersion}`,
              },
              location: {
                country_code: response.country_code,
                country_name: response.country_name,
                region_code: response.region_code,
                region_name: response.region_name,
                city: response.city,
                zip: response.zip,
                latitude: response.latitude,
                longitude: response.longitude,
              },
            };

            httpPost('/impersonate/log', payload).then(resolve).catch(reject);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve();
      }
    });
}
