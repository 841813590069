import styled, { css } from 'styled-components';

import { Colors, tableColors } from 'components/utils/styles/ui';
import { Checkbox as CheckboxBase } from 'components/form/checkbox/checkbox';
import { Button as ButtonBase } from 'components/elements/button';

export const Filter = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  input,
  select {
    width: 100%;
  }

  & + & {
    justify-content: flex-end;
    margin-left: 1rem;
  }
`;

const InputBase = styled.input`
  width: 100%;
  height: 3rem;
  padding: 0 0.8rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid ${tableColors.filterBorderColor};
  font-size: 1.3rem;
  line-height: 2.8rem;

  ${({ noAppearance }) =>
    noAppearance &&
    css`
      appearance: none;
    `}

  &:focus {
    outline: none;
    border-color: ${tableColors.filterBorderColorActive};
  }
`;

export const TextInput = InputBase;
export const SelectInput = InputBase.withComponent('select');

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: 400;
`;
export const CheckboxInput = styled(CheckboxBase)`
  margin-left: 0.5rem;
`;

export const Action = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1px;
  right: 1px;
  height: calc(100% - 2px);
  padding-right: 5px;
  background-color: ${Colors.white};
  border-radius: 0 0.5rem 0.5rem 0;
  cursor: pointer;
  pointer-events: ${({ clickThrough }) => (clickThrough ? 'none' : 'initial')};
`;

export const ToolbarFilter = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;
  height: 100%;

  border-radius: 0.5rem;
  border: 1px solid ${tableColors.filterBorderColor};
  background-color: ${Colors.white};
`;

export const ToolbarInput = styled.input`
  flex-grow: 1;
  height: calc(100% - 0.1rem);
  margin-left: 2rem;
  border: none;
  border-radius: 0.5rem;

  &:focus {
    outline: none;
  }
`;

export const ToolbarSubmit = styled(ButtonBase)`
  height: 2.7rem;
  margin-right: 1rem;
`;
