import { defineMessages } from 'react-intl';

export default defineMessages({
  noData: {
    id: 'app.components.DocumentViewer.noData',
    defaultMessage: 'No file specified.',
  },
  loadError: {
    id: 'app.components.DocumentViewer.loadError',
    defaultMessage: 'Failed to load file.',
  },
  notSupported: {
    id: 'app.components.DocumentViewer.notSupported',
    defaultMessage: 'Preview not available for this file.',
  },
  download: {
    id: 'app.components.DocumentViewer.download',
    defaultMessage: 'Download',
  },
  preparing: {
    id: 'app.components.DocumentViewer.preparing',
    defaultMessage: 'Preparing...',
  },
  preparingHeader: {
    id: 'app.components.DocumentViewer.preparingHeader',
    defaultMessage: 'Preparing file for preview, please wait...',
  },
  preparingSubheader: {
    id: 'app.components.DocumentViewer.preparingSubheader',
    defaultMessage: 'Hold tight! This will only take a few seconds.',
  },
});
