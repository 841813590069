import PropTypes from 'prop-types';

import { isIntegrationIframe } from 'utils/checkEnvironment';
import { assignNameToUser } from 'containers/Clients/utils/helpers';

import * as styled from './styles';

export const Contact = ({ user, linksActive, isPublic }) => {
  const { client_contact: contact } = user;
  const { first_name: first, last_name: last, email, client } = contact;
  const hasName = first || last;
  const contactLink = isPublic
    ? ''
    : `/clients/${contact.client_id}/contact/${contact.id}`;
  const clientLink = linksActive ? `/clients/${contact.client_id}` : '';
  const linkTarget = isIntegrationIframe(window) ? '_blank' : null;
  const userWithName = assignNameToUser(user);
  return (
    <styled.Content>
      <styled.AvatarLink to={contactLink} target={linkTarget}>
        <styled.Avatar size="lmedium" user={userWithName} color={user.color} />
      </styled.AvatarLink>
      <styled.Items>
        <styled.Item>
          <styled.UserLink to={contactLink} target={linkTarget}>
            <styled.LinkText>
              {hasName ? `${first} ${last}` : email}
            </styled.LinkText>
          </styled.UserLink>
        </styled.Item>
        <styled.Item>
          <styled.CompanyLink
            linksUnderlined={linksActive}
            to={clientLink}
            target={linkTarget}
          >
            <styled.Icon icon="Briefcase" />
            <styled.LinkText>{client?.name}</styled.LinkText>
          </styled.CompanyLink>
        </styled.Item>
      </styled.Items>
    </styled.Content>
  );
};

Contact.propTypes = {
  user: PropTypes.shape({
    client_contact: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      client: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    email: PropTypes.string,
    is_simple: PropTypes.bool,
    color: PropTypes.string,
  }),
  linksActive: PropTypes.bool,
  isPublic: PropTypes.bool,
};
