import { camelCase, isFunction, isString } from 'lodash';

import { emojisRegex } from './regexes';

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function upperCamelCase(string: string) {
  return capitalizeFirstLetter(camelCase(string));
}

export function removeEmojis(string: string) {
  return isString(string) ? string.replace(emojisRegex, '') : string;
}

export function toWellFormed(string: string) {
  // @ts-expect-error this line checks for the presence of toWellFormed but doesn't call it
  return isFunction(string.toWellFormed)
    ? // @ts-expect-error before using toWellFormed we check for its presence
      string.toWellFormed()
    : removeEmojis(string);
}

/**
 * Highlights backticks in a string.
 * @param value - The string to highlight backticks in.
 * @param regexp - The regular expression to use for matching backticks. Defaults to /`(.*?)`/.
 * @returns An array of JSX elements.
 */
export function highlightBackticks(value: string, regexp = /`(.*?)`/) {
  return value
    .split(regexp)
    .map((part, index) =>
      index % 2 === 1 ? <mark>{part}</mark> : <>{part}</>
    );
}
