import styled, { CSSObject } from 'styled-components';

import { Icon as IconBase } from 'components/elements/icon';
import { Colors } from 'components/utils/styles/ui';
import { ColorKey } from 'components/utils/styles/types';
import { isPositive, isZero } from 'components/utils/numeric';
import { BOX_SIZES } from '../constants/avatar';
import type { BoxSize } from '../constants/avatar';

type RadiusProps = {
  shape?: 'rounded' | 'square-rounded';
};

type ScoreUIDimensionsProps = {
  size: 'large' | 'medium';
};

type WithScoreProps = {
  score: number;
  size: 'large' | 'medium';
};

type AvatarProps = {
  noInitials?: boolean;
  opacity: number;
  shape?: 'rounded' | 'square-rounded';
  src: string;
};

type InitialsProps = {
  shape?: 'rounded' | 'square-rounded';
  opacity: number;
};

type ActivityIndicatorProps = {
  size: BoxSize;
  active?: boolean;
  shape?: 'rounded' | 'square-rounded';
};

type MarkerProps = {
  color?: ColorKey;
};

type WrapperProps = {
  opacity: number;
  shape?: 'rounded' | 'square-rounded';
  clickable?: boolean;
  size: BoxSize;
};

const radiusFor = function radiusFor({ shape }: RadiusProps): string {
  switch (shape) {
    case 'rounded':
      return '50%';
    case 'square-rounded':
      return '0.6rem';
    default:
      return '0';
  }
};

const scoreUIDimensions = function scoreUIDimensions({
  size,
}: ScoreUIDimensionsProps): CSSObject {
  switch (size) {
    case 'large':
      return {
        borderRadius: '2rem',
        padding: '.6rem  1rem .4rem',
        transform: 'translate(-2rem,  4.5rem)',
      };

    case 'medium':
    default:
      return {
        borderRadius: '2rem',
        fontSize: '1.15rem',
        padding: '.5rem .7rem .3rem',
        transform: 'translate(-1rem,  2rem)',
      };
  }
};

export const Wrapper = styled.div<WrapperProps>`
  background: ${({ opacity }) => (opacity === 1 ? 'inherit' : Colors.text)};
  border-radius: ${({ shape }) => radiusFor({ shape })};
  position: relative;
  display: inline-block;
  height: ${(props) => BOX_SIZES[props.size]}rem;
  width: ${(props) => BOX_SIZES[props.size]}rem;
  flex-shrink: 0;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`;

export const Avatar = styled.img<AvatarProps>`
  background-color: ${({ src, noInitials }) =>
    !src && noInitials ? Colors.teal : Colors.white};
  border: solid 1px ${Colors.outline};
  border-radius: ${({ shape }) => radiusFor({ shape })};
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: opacity 0.2s;
  opacity: ${({ opacity }) => opacity};
`;

export const Initials = styled.img<InitialsProps>`
  border-radius: ${({ shape }) => radiusFor({ shape })};
  height: 100%;
  width: 100%;
  transition: opacity 0.2s;
  ${({ opacity }) => `opacity: ${opacity};`};
`;

export const WithScore = styled.div<WithScoreProps>`
  height: auto;
  width: auto;

  &::after {
    background-color: ${({ score }) =>
      isPositive(score) ? Colors.green : Colors.red};
    border: solid 0.2rem ${Colors.white};
    color: ${Colors.white};
    content: ${({ score }) => (isPositive(score) ? score : score * -1)};
    display: ${({ score }) => (isZero(score) ? 'none' : 'inline-block')};
    font-weight: 400;
    position: absolute;
    ${({ size }) => scoreUIDimensions({ size })}
  }
`;

export const ActivityIndicator = styled.div<ActivityIndicatorProps>`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${({ active }) => (active ? Colors.green : Colors.grey)};
  width: ${({ size }) => BOX_SIZES[size] / 3.5}rem;
  height: ${({ size }) => BOX_SIZES[size] / 3.5}rem;
  border: ${({ size }) => BOX_SIZES[size] / 25}rem solid ${Colors.white};
  border-radius: ${({ shape }) => (shape === 'rounded' ? '100%' : '1rem')};
`;

export const Marker = styled(IconBase)<MarkerProps>`
  position: absolute;
  bottom: 0;
  right: -0.3rem;

  path {
    stroke: ${Colors.white} !important;
    fill: ${({ color }) => Colors[color!]};
  }
`;
