import { IconBase } from './iconBase';

import { CustomIconsProp } from '../types';

export const ChevronDownUp = ({ size, color, className }: CustomIconsProp) => (
  <IconBase className={className} viewBox={20} size={size}>
    <path
      d="M15 3.33331L10 8.33331L5 3.33331"
      fill="none"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 16.6667L10 11.6667L15 16.6667"
      fill="none"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconBase>
);
