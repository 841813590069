import { createSelector } from 'reselect';

const rootSelector = createSelector(
  (state) => state.get('supplierHub'),
  (substate) => substate
);

export const industriesSelector = createSelector(
  rootSelector,
  (substate) => substate.industries
);

export const specializationsSelector = createSelector(
  rootSelector,
  (substate) => substate.specializations
);

export const suppliersSelector = createSelector(
  rootSelector,
  (substate) => substate.suppliers
);

export const searchResultsVisibleSelector = createSelector(
  rootSelector,
  (substate) => substate.searchResultsVisible
);

export const chosenSupplierIdSelector = createSelector(
  rootSelector,
  (substate) => substate.chosenSupplierId
);

export const searchConditionsSelector = createSelector(
  rootSelector,
  (substate) => substate.searchConditions
);

export const mapParamsSelector = createSelector(
  rootSelector,
  (substate) => substate.mapParams
);

export const resultsLoadedSelector = createSelector(
  rootSelector,
  (substate) => substate.resultsLoaded
);

export const loadingSelector = createSelector(
  rootSelector,
  (substate) => substate.loading
);
