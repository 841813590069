import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonSelect: {
    id: 'app.components.Form.Upload.buttonSelect',
    defaultMessage: 'Select file',
  },
  buttonRemove: {
    id: 'app.components.Form.Upload.buttonRemove',
    defaultMessage: 'Remove',
  },
});
