import styled, { css } from 'styled-components';
import SliderBase, {
  Range as RangeBase,
  Handle as HandleBase,
} from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Colors } from 'components/utils/styles/ui';
import { Tooltip as TooltipBase } from 'components/overlay/Tooltip';

export const sliderMixin = css`
  & > .rc-slider-rail,
  & .rc-slider-track {
    height: 2px;
  }

  & > .rc-slider-handle,
  & > .rc-slider-handle-dragging {
    margin-top: -6px;
    border-color: ${({ isValid, theme, color }) =>
      isValid
        ? theme.colors[color] || Colors[color]
        : Colors.redLight} !important;
    background-color: ${({ isValid, theme, color }) =>
      isValid ? theme.colors[color] || Colors[color] : Colors.red} !important;
  }

  & > .rc-slider-handle:active,
  & > .rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px ${Colors.tealLighter} !important;
  }

  & > .rc-slider-track {
    background-color: ${({ theme, color }) =>
      theme.colors[color] || Colors[color]};
  }

  & .rc-slider-dot-active {
    border-color: ${Colors.tealDark};
  }

  & .rc-slider-dot-active,
  .rc-slider-dot {
    bottom: -1.5px;
  }
`;

export const Slider = styled(SliderBase)`
  ${sliderMixin}
`;

export const Range = styled(RangeBase)`
  ${sliderMixin}
`;

export const Handle = styled(HandleBase)``;

export const Tooltip = styled(TooltipBase)`
  width: 100%;
  height: 100%;
  content: '';

  & > :first-child {
    margin-top: -6px;
  }
`;
