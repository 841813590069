import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { Variable } from './variable';

export const VariableExtension = ({ i18n, editable }) =>
  Node.create({
    name: 'variable',

    group: 'inline',

    atom: true,

    inline: true,

    addOptions() {
      return { i18n, editable };
    },

    addAttributes() {
      return {
        type: {
          default: 'listUrl',
        },
        fallback: {
          default: '',
        },
        needFallback: {
          default: undefined,
        },
        autofocus: {
          default: false,
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: 'variable',
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return ['variable', mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
      return ReactNodeViewRenderer(Variable);
    },
  });
