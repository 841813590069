/* eslint-disable camelcase */
import { get } from 'lodash';

import { contactInitials } from 'containers/Clients/utils/helpers';

import { Avatar } from './avatar';
import type { BoxSize } from './constants/avatar';

type AvatarUserProps = {
  className?: string;
  size: BoxSize;
  shape?: 'square-rounded' | 'rounded';
  activity?: boolean;
  user?: User;
  marker?: object;
  color?: string;
};

export const AvatarUser = function AvatarUser({
  className,
  size = 'small',
  shape = 'rounded',
  activity,
  user,
  marker,
  color,
}: AvatarUserProps) {
  const email = get(user, 'email');
  const avatar = get(user, 'avatar');

  const lastActivity = get(user, 'last_activity_at');
  const initials = contactInitials(user, true);

  const context = {
    picture: avatar,
    initials,
    marker,
    color,
    email,
  };

  return (
    <Avatar
      className={className}
      size={size}
      shape={shape}
      activity={activity === undefined ? lastActivity : activity}
      context={context}
    />
  );
};
