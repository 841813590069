import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/elements/icon';

import * as styled from './styles';

export class RichCheckbox extends Component {
  updateValue = () => {
    const { value, disabled, onChange } = this.props;

    if (disabled) {
      return;
    }
    onChange(!value);
  };

  render() {
    const {
      check,
      icon,
      color,
      label,
      placeholder,
      value,
      disabled,
      context,
    } = this.props;

    return (
      <styled.RichCheckbox
        color={color}
        value={value}
        disabled={disabled}
        onClick={this.updateValue}
      >
        <styled.Left>
          <styled.Box color={color} value={value} disabled={disabled}>
            {(value || disabled) && (
              <Icon
                icon={check}
                size="large"
                color={color === 'danger' ? 'red' : 'tealDark'}
              />
            )}
          </styled.Box>
        </styled.Left>
        <styled.Right>
          <styled.Label color={color}>
            {icon && (
              <Icon
                icon={icon}
                color={color === 'danger' ? 'red' : 'tealDark'}
              />
            )}
            <FormattedMessage {...label} />
          </styled.Label>
          <styled.Placeholder>
            <FormattedMessage {...placeholder} values={context.i18n} />
          </styled.Placeholder>
        </styled.Right>
      </styled.RichCheckbox>
    );
  }
}

RichCheckbox.propTypes = {
  icon: PropTypes.string,
  check: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'danger']),
  label: PropTypes.object,
  placeholder: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  context: PropTypes.object,
  onChange: PropTypes.func,
};

RichCheckbox.defaultProps = {
  check: 'Check',
  color: 'primary',
  disabled: false,
  context: {},
};
