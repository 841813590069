/* eslint-disable camelcase */
import { httpDelete, httpPost } from 'utils';
import { openFlashMessage } from 'components/utils/flashMessages';

import { FlashDefinition } from './scene/utils/flashDefinition';

export function generate(params: GenerateActionParams) {
  return () =>
    new Promise<Record<string, never>>((resolve, reject) =>
      httpPost(`/openai/suggestions`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.data?.error === 'ask_ai_max_limit') {
            openFlashMessage(FlashDefinition.askAIRequestLimit);
          }
          reject(error);
        })
    );
}

export function cleanUpThreadAndRun(params: {
  thread_id: string | null;
  run_id: string | null;
}) {
  return () =>
    new Promise<null>((resolve, reject) =>
      httpDelete(`/openai/suggestions`, params)
        .then(() => {
          resolve(null);
        })
        .catch((error) => {
          reject(error);
        })
    );
}
