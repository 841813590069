import { fromJS } from 'immutable';
import { COMPANY_FETCH_SUCCESS, COMPANY_UPDATE_SUCCESS } from './constants';

const initialState = fromJS({
  company: {},
  locations: [],
});

export function companyReducer(state = initialState, action) {
  switch (action.type) {
    case COMPANY_FETCH_SUCCESS:
      return state.set('company', fromJS(action.payload.data));

    case COMPANY_UPDATE_SUCCESS:
      return state.set('company', fromJS(action.payload.data));

    default:
      return state;
  }
}
