import styled from 'styled-components';

import { Colors, bottomShadow } from 'components/utils/styles/ui';
import { Icon as IconBase } from 'components/elements/icon';
import { Button as ButtonBase } from 'components/elements/button';
import { Emoji as EmojiBase } from 'components/elements/emoji';
import { LongText } from 'components/text/LongText';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 4rem;
  box-shadow: ${bottomShadow};
`;

export const Header = styled.div`
  height: 3.6rem;
  width: 100%;
  background-color: ${Colors.tealDarker};
  border-radius: 0.4rem 00.4rem 0 0;
  padding: 0.6rem 1.2rem;
  display: flex;
  align-items: center;
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
`;

export const Count = styled.div`
  color: ${Colors.white};
`;

export const Content = styled.div`
  width: 40rem;
  max-height: 20rem;
  min-height: 5rem;
  background-color: ${Colors.tealLightest};
  padding: 1rem 1.2rem;
  border: 1px solid ${Colors.outline};
  overflow-y: auto;
  ${({ theme }) => theme.typography.text.normal};
`;

export const FollowUpPrompt = styled.div`
  padding: 0 0 0.8rem 0;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid ${Colors.outline};
  background-color: ${Colors.tealLightest};
  color: ${Colors.textLight};
  font-weight: 500;
  font-size: 1.4rem;

  &:first-child {
    padding-top: 0;
  }
`;

export const Emoji = styled(EmojiBase)`
  margin-right: 0.8rem;
`;

export const ButtonText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const RegenerateButton = styled(ButtonBase)`
  margin-left: 1.2rem;
`;

export const ReplaceButton = styled(ButtonBase)`
  margin-left: auto;
  height: 2.4rem;
`;

export const SelectedContent = styled(LongText)`
  li {
    margin: 0;
  }
`;

export const NavigationButton = styled(IconBase).attrs<{ disabled: boolean }>(
  ({ disabled }) => ({
    color: disabled ? Colors.inputOutline : Colors.white,
  })
)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;
