import { defineMessages } from 'react-intl';

export default defineMessages({
  inputPlaceholder: {
    id: 'app.form.rteAITextInput.inputPlaceholder',
    defaultMessage: 'Ask AI to edit or generate...',
  },
  regenerate: {
    id: 'app.form.rteAITextInput.regenerate',
    defaultMessage: 'Regenerate',
  },
  replace: {
    id: 'app.form.rteAITextInput.replace',
    defaultMessage: 'Replace selection',
  },
  askAI: {
    id: 'app.form.rteAITextInput.askAI',
    defaultMessage: 'Ask AI',
  },
  confirmDiscard: {
    id: 'app.form.rteAITextInput.confirmDiscard',
    defaultMessage: 'Do you want to discard AI response?',
  },
  buttonDiscard: {
    id: 'app.form.rteAITextInput.buttonDiscard',
    defaultMessage: 'Discard',
  },
  buttonCancel: {
    id: 'app.form.rteAITextInput.buttonCancel',
    defaultMessage: 'Cancel',
  },
  AITyping: {
    id: 'app.form.rteAITextInput.AITyping',
    defaultMessage: 'AI is writing',
  },
  editDisabled: {
    id: 'app.form.rteAITextInput.editDisabled',
    defaultMessage: 'You can only edit or generate the last response',
  },
  placeholderMaxLimitReached: {
    id: 'app.containers.SharedList.RecruiterNote.placeholderMaxLimitReached',
    defaultMessage: 'You can only generate up to {maxLimit} AI responses',
  },
  askAIRequestLimit: {
    id: 'app.containers.SharedList.RecruiterNote.askAIRequestLimit',
    defaultMessage:
      'You’ve reached the Ask AI request limit. Please try again later.',
  },

  anonymize: {
    id: 'app.components.experience.anonymize',
    defaultMessage: 'Anonymize details',
  },
  bullet: {
    id: 'app.components.experience.bullet',
    defaultMessage: 'Make bullet points',
  },
  shorter: {
    id: 'app.components.experience.shorter',
    defaultMessage: 'Make shorter',
  },
  longer: {
    id: 'app.components.experience.longer',
    defaultMessage: 'Make longer',
  },
  firstPerson: {
    id: 'app.components.experience.firstPerson',
    defaultMessage: 'Change to 1st person',
  },
  thirdPerson: {
    id: 'app.components.experience.thirdPerson',
    defaultMessage: 'Change to 3rd person',
  },
  professional: {
    id: 'app.components.experience.professional',
    defaultMessage: 'Make professional',
  },
  friendlier: {
    id: 'app.components.experience.friendlier',
    defaultMessage: 'Make friendlier',
  },
  formal: {
    id: 'app.components.experience.formal',
    defaultMessage: 'Make More Formal',
  },
  casual: {
    id: 'app.components.experience.casual',
    defaultMessage: 'Make More Casual',
  },
  genderNeutral: {
    id: 'app.components.experience.genderNeutral',
    defaultMessage: 'Remove gender terms',
  },
  simplify: {
    id: 'app.components.experience.simplify',
    defaultMessage: 'Simplify Language',
  },
  modernize: {
    id: 'app.components.experience.modernize',
    defaultMessage: 'Modernize Language',
  },
  clarify: {
    id: 'app.components.experience.clarify',
    defaultMessage: 'Clarify Language',
  },
  enhance: {
    id: 'app.components.experience.enhance',
    defaultMessage: 'Enhance Readability',
  },
  standardize: {
    id: 'app.components.experience.standardize',
    defaultMessage: 'Standardize Format',
  },
  fix: {
    id: 'app.components.experience.fix',
    defaultMessage: 'Fix issues with the text',
  },
  stronger: {
    id: 'app.components.experience.stronger',
    defaultMessage: 'Use Stronger Verbs',
  },
  highlight: {
    id: 'app.components.experience.highlight',
    defaultMessage: 'Highlight Achievements',
  },
  quantify: {
    id: 'app.components.experience.quantify',
    defaultMessage: 'Add Quantifiable Data',
  },
});
