import { isString } from 'lodash';

import { Colors } from 'components/utils/styles/ui';

import * as styled from './styles/breadcrumb';

type BreadcrumbProps = {
  className: string;
  routeTo: string | (() => void);
  title: string;
};

export const Breadcrumb = function Breadcrumb({
  className,
  routeTo,
  title,
}: Partial<BreadcrumbProps>) {
  const linkTo = isString(routeTo) ? (
    <styled.Link to={`/${routeTo}`}>
      <styled.Icon color={Colors.text} icon="CaretLeft" />
    </styled.Link>
  ) : (
    <styled.Link onClick={routeTo}>
      <styled.Icon color={Colors.text} icon="CaretLeft" />
    </styled.Link>
  );

  return (
    <styled.Breadcrumb className={className}>
      {linkTo}

      <styled.Label>{title}</styled.Label>
    </styled.Breadcrumb>
  );
};
