import { IconBase } from './iconBase';

import { CustomIconsProp } from '../types';

export const ChevronUpDown = ({ size, color, className }: CustomIconsProp) => (
  <IconBase className={className} viewBox={24} size={size}>
    <path
      d="M18 14L12 20L6 14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M6 10L12 4L18 10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </IconBase>
);
