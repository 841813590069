import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { AvatarUser as AvatarBase } from 'components/visual/avatar/avatarUser';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';
import { selfIconElementsSelector } from 'components/elements/styles/icon';
import { BOX_SIZES } from 'components/visual/avatar/constants/avatar';

const linkMixin = css`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const User = styled(SmartLinkBase)`
  background: ${Colors.greyLight};
  height: 2.4rem;
  display: inline-flex;
  align-items: center;
  border-radius: 10rem;
  padding-right: 0.6rem;
  max-width: 100%;
  color: inherit !important;
  border: 1px solid ${Colors.outline};

  ${({ link }) => !link && 'cursor: default;'}
  ${({ to }) => to && linkMixin}
`;

export const Avatar = styled(AvatarBase)`
  margin-left: -1px;
`;

export const UserName = styled.span`
  margin-left: 0.6rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ theme }) => theme.typography.text.small};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => BOX_SIZES[size] || 2}rem;
  height: ${({ size }) => BOX_SIZES[size] || 2}rem;
  border-radius: 50%;
  background-color: ${Colors.teal};
  margin-left: -1px;

  & > svg {
    width: 1.2rem;
    height: 1.2rem;

    ${selfIconElementsSelector} {
      stroke: ${Colors.white} !important;
    }
  }
`;
