import {
  reduce,
  includes,
  toLower,
  mapKeys,
  camelCase,
  snakeCase,
  isEmpty,
  isArray,
  isObject,
  isString,
  keys,
  every,
  get,
} from 'lodash';

/**
 * @function objectPropsToCamelCase
 * @param {Object} object
 * @return {Object} new Object with properties in camelCase
 */
export const objectPropsToCamelCase = function objectPropsToCamelCase(object) {
  return mapKeys(object, (v, k) => camelCase(k));
};

/**
 * @function objectPropsToSnakeCase
 * @param {Object} object
 * @return {Object} new Object with properties in snake_case
 */
export const objectPropsToSnakeCase = function objectPropsToSnakeCase(object) {
  return mapKeys(object, (v, k) => snakeCase(k));
};

/**
 * @function toToken
 * @param {Object} object
 * @return {String} Object strigified to JSON and base64
 */
export const toToken = function toToken(object) {
  return window.btoa(encodeURIComponent(JSON.stringify(object)));
};

/**
 * @function fromToken
 * @param {String} token
 * @return {Object} new Object with properties encoded from base64
 */
export const fromToken = function fromToken(token) {
  return JSON.parse(decodeURIComponent(window.atob(token)));
};

export const omitDeepBy = function omitDeepBy(value, fn) {
  if (isArray(value)) {
    return value.map((i) => omitDeepBy(i, fn));
  }
  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (fn(value[k])) return newObject;
      return { [k]: omitDeepBy(value[k], fn), ...newObject };
    }, {});
  }
  return value;
};

export const deepIsEmpty = function deepIsEmpty(value) {
  if (isObject(value)) {
    return every(keys(value), (key) => deepIsEmpty(value[key]));
  }

  return isEmpty(value);
};

export const isEqualBy = function isEqualBy(prev, next, attrs) {
  return !attrs.find((key) => get(prev, key) !== get(next, key));
};

/**
 * @function deepMap
 * @param {Object} input object of arbitrary depth
 * @param {iterator} function to which each {value, key} pair with non-object value is passed; it should return the new value for specified key
 * @return {Object} new object
 */
export const deepMap = (obj, iterator) =>
  reduce(
    obj,
    (acc, val, key) => ({
      ...acc,
      [key]: deepMapProcessNode(key, val, iterator),
    }),
    {}
  );

const deepMapProcessNode = (key, val, iterator) => {
  if (isArray(val)) {
    return val.map((element) => deepMapProcessNode(null, element, iterator));
  }

  if (isObject(val)) {
    return deepMap(val, iterator);
  }

  return iterator(val, key);
};

const sensitiveKeys = [
  'password',
  'authorization',
  'authorization-exp',
  'jwt',
  'exp',
];

const removeSensitiveDataFromString = (str) => {
  const jwt = localStorage.getItem('phoenixAuthToken');
  const exp = localStorage.getItem('phoenixExpToken');

  let filtered = str
    .replaceAll(/"jwt":"([^"]+)"/gi, '"jwt":"REMOVED"')
    .replaceAll(/"exp":"([^"]+)"/gi, '"exp":"REMOVED"')
    .replaceAll(/"authorization":"([^"]+)"/gi, '"authorization":"REMOVED"')
    .replaceAll(
      /"authorization-exp":"([^"]+)"/gi,
      '"authorization-exp":"REMOVED"'
    )
    .replaceAll(/"password":"([^"]+)"/gi, '"password":"REMOVED"');

  if (!isEmpty(jwt)) {
    filtered = filtered.replaceAll(jwt, 'REMOVED');
  }
  if (!isEmpty(exp)) {
    filtered = filtered.replaceAll(exp, 'REMOVED');
  }

  return filtered;
};

/**
 * @function removeSensitiveData
 * @param {Object} object
 * @return {Object} new object with ssensitive data replaced with 'REMOVED'
 */
export const removeSensitiveData = (input) => {
  if (isString(input)) {
    return removeSensitiveDataFromString(input);
  }

  return deepMap(input, (val, key) => {
    if (includes(sensitiveKeys, toLower(key))) {
      return 'REMOVED';
    }
    if (isString(val)) {
      return removeSensitiveDataFromString(val);
    }

    return val;
  });
};
