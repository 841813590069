import styled from 'styled-components';

// import { Colors } from 'components/utils/styles/ui';

const baseButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  // inherit font & color from ancestor
  color: inherit;
  font: inherit;
  text-align: inherit;
  line-height: normal;

  // Corrects font smoothing for webkit
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  // Corrects inability to style clickable 'input' types in iOS
  -webkit-appearance: none;

  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem;
  min-height: 3rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;

  ${({ theme }) => theme.typography.text.normal};
`;

export const Message = styled.div`
  padding: 0.6rem 1rem;
  text-align: center;
  flex-grow: 1;
`;

export const Left = styled(baseButton)`
  padding: 0.6rem 0;
  text-align: left;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Right = styled(baseButton)`
  padding: 0.6rem 0;
  text-align: right;
  flex-grow: 0;
  flex-shrink: 0;
`;
