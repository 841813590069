import { fromJS } from 'immutable';
import { cloneDeep, forEach, filter, findIndex } from 'lodash';

import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  STATES_REQUEST,
  STATES_SUCCESS,
} from './constants';

const initialState = fromJS({
  errors: null,
  countries: [],
  fetching: false,
});

export function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_REQUEST:
      return state.set('fetching', true);
    case COUNTRIES_SUCCESS: {
      const countries = cloneDeep(state.get('countries'));

      if (countries.size === 0) {
        return state.set('countries', action.countries).set('fetching', false);
      }

      const updatedCountries = forEach(action.countries, (country) => {
        const existentCountry = filter(countries, { id: country.id })[0];

        if (!existentCountry) {
          return country;
        }

        return existentCountry;
      });

      return state.set('countries', updatedCountries).set('fetching', false);
    }

    case STATES_REQUEST:
      return state.set('fetching', true);
    case STATES_SUCCESS: {
      const { states, id: countryId } = action.states;
      const countries = cloneDeep(state.get('countries'));

      if (countries.size === 0) {
        return state.set('countries', [action.states]);
      }

      const countryIndex = findIndex(countries, { id: countryId });

      countries[countryIndex].states = states;

      return state.set('countries', countries).set('fetching', false);
    }
    default:
      return state;
  }
}
