import i18n from './i18n';

export const FlashDefinition = {
  uploadSuccess: {
    context: [{ detail: i18n.uploadSuccess }],
    status: 'success',
  },
  renameSuccess: {
    context: [{ detail: i18n.renameSuccess }],
    status: 'success',
  },
  deleteSuccess: {
    context: [{ detail: i18n.deleteSuccess }],
    status: 'success',
  },
};
