import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';

export const RequestWrapper = styled.div`
  width: 42.6rem;
  padding: 1.4rem;

  & > * + * {
    margin-top: 2rem;
  }

  & > button {
    width: 100%;
  }
`;

export const AffiliationButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > * + * {
    margin-top: 1rem;
  }
`;

export const Text = styled.span`
  align-items: center;
  display: flex;
  & > svg {
    margin-right: 0.8rem;
  }
`;

export const AffiliationInfo = styled.div`
  display: flex;
  width: 24.7rem;
  padding: 1.4rem;
  cursor: pointer;

  & > svg {
    margin-top: 0.2rem;
  }
`;

export const InfoDescription = styled.div`
  flex: 0 0 85%;
  margin-left: 1rem;
`;

export const InfoHeader = styled.div`
  margin-bottom: 0.7rem;
  font-weight: 500;
`;

export const InfoText = styled.div`
  font-size: 1.3rem;
  color: ${Colors.textLight};
`;

export const ButtonWithInfoWrapper = styled.div`
  width: 23rem;
`;

export const InfoBox = styled.div`
  display: flex;
  padding: 0.6rem;
  margin-bottom: 0.8rem;
  border-radius: 0.3rem;
  border: 1px solid ${Colors.outline};
  background-color: ${Colors.white};

  & > span {
    margin: 0.2rem 0;
    white-space: pre-wrap;
  }

  & > svg {
    margin-right: 0.8rem;
    flex-shrink: 0;

    path {
      fill: ${Colors.tealDark};
    }
  }
`;

export const SmartLink = styled(SmartLinkBase)`
  color: ${Colors.tealDark};
  text-decoration: underline;
`;
