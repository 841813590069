import { filter, reduce, uniq } from 'lodash';

export const paginationWithOffset = (
  currentPage = 0,
  lastPage = 0,
  filler = '…'
) => {
  const firstPage = 1;

  const delta = 2;
  const left = Math.max(0, currentPage - delta);
  const right = Math.min(lastPage, currentPage + delta);

  const pageRange = [...Array(lastPage + 1).keys()];
  const filteredPageRange = filter(
    pageRange,
    (item) =>
      item !== 0 &&
      (item === firstPage ||
        item === lastPage ||
        (item >= left && item <= right))
  );

  const pageRangeWithDots = reduce(
    filteredPageRange,
    (accumulator, currentValue, index) => {
      const previousIndex = index - 1;
      if (
        previousIndex >= 0 &&
        filteredPageRange[previousIndex] + 1 !== currentValue
      ) {
        accumulator.push(filler);
      }

      accumulator.push(currentValue);

      return accumulator;
    },
    [1]
  );

  return uniq(pageRangeWithDots);
};
