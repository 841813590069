import i18n from './i18n';

const performanceTo = {
  header: i18n.performanceToHeader,
  rows: [
    {
      header: i18n.performanceYourActivity,
      columns: [
        {
          key: 'job_posted_total_count',
          type: 'number',
          header: i18n.performanceToJobsPosted,
        },
        {
          key: 'job_posted_open_count',
          type: 'number',
          header: i18n.performanceToJobsOpen,
        },
        {
          key: 'job_last_posted_at',
          type: 'dt',
          header: i18n.performanceToJobsLast,
        },
      ],
    },
    {
      header: i18n.performanceCompanyActivity,
      columns: [
        {
          key: 'job_engaged_total_count',
          type: 'number',
          header: i18n.performanceToJobsEngaged,
        },
        {
          key: 'candidate_supply_count',
          type: 'number',
          header: i18n.performanceToCandidatesSupply,
        },
        {
          key: 'candidate_last_supply_at',
          type: 'dt',
          header: i18n.performanceToCandidatesLast,
        },
      ],
    },
  ],
};

const performanceFrom = {
  header: i18n.performanceFromHeader,
  rows: [
    {
      header: i18n.performanceCompanyActivity,
      columns: [
        {
          key: 'job_posted_total_count',
          type: 'number',
          header: i18n.performanceFromJobsPosted,
        },
        {
          key: 'job_posted_open_count',
          type: 'number',
          header: i18n.performanceFromJobsOpen,
        },
        {
          key: 'job_last_posted_at',
          type: 'dt',
          header: i18n.performanceFromJobsLast,
        },
      ],
    },
    {
      header: i18n.performanceYourActivity,
      columns: [
        {
          key: 'job_engaged_total_count',
          type: 'number',
          header: i18n.performanceFromJobsEngaged,
        },
        {
          key: 'candidate_supply_count',
          type: 'number',
          header: i18n.performanceFromCandidatesSupply,
        },
        {
          key: 'candidate_last_supply_at',
          type: 'dt',
          header: i18n.performanceFromCandidatesLast,
        },
      ],
    },
  ],
};

const performanceOverall = {
  header: i18n.performanceOverallHeader,
  rows: [
    {
      header: i18n.performanceJobsActivity,
      columns: [
        {
          key: 'job_posted_total_count',
          type: 'number',
          header: i18n.performanceOverallJobsPosted,
        },
        {
          key: 'job_posted_open_count',
          type: 'number',
          header: i18n.performanceOverallJobsOpen,
        },
        {
          key: 'job_last_posted_at',
          type: 'dt',
          header: i18n.performanceOverallJobsLast,
        },
      ],
    },
    {
      header: i18n.performanceCandidatesActivity,
      columns: [
        {
          key: 'job_engaged_total_count',
          type: 'number',
          header: i18n.performanceOverallJobsEngaged,
        },
        {
          key: 'candidate_supply_count',
          type: 'number',
          header: i18n.performanceOverallCandidatesSupply,
        },
        {
          key: 'candidate_last_supply_at',
          type: 'dt',
          header: i18n.performanceOverallCandidatesLast,
        },
      ],
    },
  ],
};

export { performanceTo, performanceFrom, performanceOverall };
