import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty, values, map } from 'lodash';

import { Icon } from 'components/elements/icon';
import { ContentList } from 'components/lists/ContentList';
import imageSrc from 'images/illustrations/gears.png';
import { ModalList } from 'components/lists/ModalList';

import { EditModal as Modal } from './modal';
import { Form } from './form';

import i18n from '../utils/i18n';
import * as styled from '../styles/section';
import * as styledUI from '../styles/assocList';
import * as FormDefinitions from '../utils/formDefinition';

export class AssocList extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: false, more: false };
    this.FormDefinition = FormDefinitions[`FormDefinition${props.context}`];
    this.input = props.input || values(this.FormDefinition)[0].id;
  }

  handleSave = (company) => {
    const { saveForm } = this.props;

    saveForm(company).then(() => {
      this.toggleModal(false);
      this.toggleMore(false);
    });
  };

  toggleMore = (more) => {
    this.setState({ more });
  };

  isCompleted() {
    const { company } = this.props;

    return !isEmpty(company[this.input]);
  }

  toggleModal(modal) {
    this.setState({ modal });
  }

  renderModal() {
    const { context, company, items } = this.props;

    return (
      <Modal
        title={i18n[`modalTitle${context}`]}
        onClose={() => this.toggleModal(false)}
      >
        <Form
          editObject={company}
          assocItems={items}
          saveForm={this.handleSave}
          formDefinition={this.FormDefinition}
        />
      </Modal>
    );
  }

  renderList() {
    const { company } = this.props;
    const { more } = this.state;
    const items = map(company[this.input], 'name').sort();
    const valuesLength = items.length;

    return (
      <ContentList
        isBordered
        items={items.slice(0, more && valuesLength <= 10 ? undefined : 5)}
      />
    );
  }

  renderListModal() {
    const { context, company } = this.props;
    const { more } = this.state;

    if (!more || company[this.input].length <= 10) {
      return null;
    }

    return (
      <ModalList
        title={i18n[`modalTitleAll${context}`]}
        items={map(company[this.input], 'name').sort()}
        onClose={() => this.toggleMore(false)}
      />
    );
  }

  renderMoreButton() {
    const { company } = this.props;
    const { more } = this.state;
    const valuesLength = company[this.input].length;

    if (valuesLength <= 5 || (more && valuesLength <= 10)) {
      return null;
    }

    return (
      <styledUI.MoreWrapper>
        <styledUI.ButtonMore type="blank" onClick={() => this.toggleMore(true)}>
          <FormattedMessage
            {...i18n.showMore}
            values={{ count: valuesLength - 5 }}
          />
        </styledUI.ButtonMore>
      </styledUI.MoreWrapper>
    );
  }

  renderEmptyStateText() {
    const { context } = this.props;
    if (['ClientIndustries', 'RoleSpecializations'].includes(context)) {
      return <FormattedMessage {...i18n[`emptyStateNo${context}`]} />;
    }
    return null;
  }

  render() {
    const {
      context,
      edit,
      dataManual,
      className,
      isWideOnNarrowScreen,
      hideOnNarrowScreen,
    } = this.props;
    const { modal } = this.state;

    if (!edit && !this.isCompleted()) {
      return null;
    }

    return (
      <>
        <styled.Card
          className={className}
          isWideOnNarrowScreen={isWideOnNarrowScreen}
          hideOnNarrowScreen={hideOnNarrowScreen}
          reducedMarginOnNarrowScreen
          right
          bordered
          isSimple
        >
          <styled.Section
            title={<FormattedMessage {...i18n[`title${context}`]} />}
            data-manual={dataManual}
          >
            {edit && (
              <styled.ButtonEdit
                color={this.isCompleted() ? 'primary' : 'danger'}
                onClick={() => this.toggleModal(true)}
                type="blank"
                size="large"
              >
                <Icon icon="NotePencil" />
              </styled.ButtonEdit>
            )}
            {this.renderList()}
            {this.renderMoreButton()}
            {this.renderListModal()}
            {!this.isCompleted() && (
              <styled.EmptyState>
                <styled.Image src={imageSrc} />
                {edit ? (
                  <styled.ButtonEditEmpty
                    onClick={() => this.toggleModal(true)}
                  >
                    <FormattedMessage {...i18n[`buttonEmpty${context}`]} />
                  </styled.ButtonEditEmpty>
                ) : (
                  <styled.Message isMissing>
                    {this.renderEmptyStateText()}
                  </styled.Message>
                )}
              </styled.EmptyState>
            )}
          </styled.Section>
        </styled.Card>

        {edit && modal && this.renderModal()}
      </>
    );
  }
}

AssocList.propTypes = {
  className: PropTypes.string,
  context: PropTypes.oneOf([
    'Industry',
    'ClientIndustries',
    'JobDepartments',
    'RoleSpecializations',
    'VerificationDocuments',
  ]).isRequired,
  edit: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  saveForm: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  input: PropTypes.string,
  dataManual: PropTypes.string,
  isWideOnNarrowScreen: PropTypes.bool,
  hideOnNarrowScreen: PropTypes.bool,
};
