import { isValidElement, useCallback, useEffect, useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { isEmpty, isObject } from 'lodash';

import { openFlashMessage } from 'components/utils/flashMessages';
import { ClipboardButton } from 'components/misc/ClipboardButton';
import { Icon } from 'components/elements/icon';

import { FlashDefinition } from './utils/flashDefinition';
import * as styled from './styles';

type TooltipProps = {
  fixed?: boolean;
  position?: string;
  withCopyButton?: boolean;
  forceDisplay?: boolean;
};

type TruncateWithTooltipForTextProps = {
  className?: string;
  children?: string | React.ReactNode;
  lines?: number;
  minLength?: number;
  tooltip?: TooltipProps;
  display?: string;
  dataResume?: string;
  dataTest?: string;
};

export const TruncateWithTooltipForText = ({
  className,
  children,
  lines = 1,
  minLength,
  tooltip,
  display,
  dataTest,
  dataResume,
}: TruncateWithTooltipForTextProps) => {
  const alwaysTooltip =
    minLength && ((children as string).length as number) >= minLength;
  const [disabled, setDisabled] = useState(
    !tooltip?.forceDisplay && !alwaysTooltip
  );
  const handleTruncate = useCallback(
    (wasTruncated) => {
      if (tooltip?.forceDisplay) return;
      setDisabled(!wasTruncated);
    },
    [tooltip?.forceDisplay]
  );

  const [resizeCount, setResizeCount] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      setResizeCount((prevCount) => prevCount + 1);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const content = (
    <>
      {children}
      {tooltip?.withCopyButton && (
        <styled.ClipboardButtonWrapper>
          <ClipboardButton
            type="blank"
            color="tealDark"
            copyText={children as string}
            onCopy={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              openFlashMessage(FlashDefinition.contentCopiedSuccess);
              e.stopPropagation();
            }}
          >
            <Icon icon="Copy" weight="bold" size="large" color="tealDark" />
          </ClipboardButton>
        </styled.ClipboardButtonWrapper>
      )}
    </>
  );

  return (
    <styled.Tooltip
      dataResume={dataResume}
      className={className}
      content={content}
      disabled={disabled}
      display={display}
      {...tooltip}
    >
      {alwaysTooltip ? (
        <styled.Truncate data-test={dataTest}>{children}</styled.Truncate>
      ) : (
        <TruncateMarkup
          lines={lines}
          onTruncate={handleTruncate}
          key={`truncate-${resizeCount}`}
        >
          <styled.Content data-test={dataTest}>{children}</styled.Content>
        </TruncateMarkup>
      )}
    </styled.Tooltip>
  );
};

type TruncateWithTooltipForI18nProps = {
  i18n?: MessageDescriptor;
  values?: Record<string, React.ReactNode>;
} & TruncateWithTooltipForTextProps;

export const TruncateWithTooltipForI18n = ({
  i18n,
  values,
  ...props
}: TruncateWithTooltipForI18nProps) => (
  <FormattedMessage {...i18n} values={values}>
    {(value) => (
      <TruncateWithTooltipForText {...props}>
        {value}
      </TruncateWithTooltipForText>
    )}
  </FormattedMessage>
);

type TruncateWithTooltipProps = (
  | { label: undefined | null }
  | ({ label: MessageDescriptor } & TruncateWithTooltipForI18nProps)
  | ({ label: string | React.ReactNode } & TruncateWithTooltipForTextProps)
) & { na?: MessageDescriptor } & { dataResume?: string };

export const TruncateWithTooltip = ({
  label,
  na,
  dataResume,
  ...props
}: TruncateWithTooltipProps) => {
  if (isEmpty(label) && na) return <FormattedMessage {...na} />;

  if (isObject(label) && !isValidElement(label)) {
    return (
      <TruncateWithTooltipForI18n
        i18n={label as MessageDescriptor}
        {...props}
      />
    );
  }

  return (
    <TruncateWithTooltipForText dataResume={dataResume} {...props}>
      {label}
    </TruncateWithTooltipForText>
  );
};
