import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Config as AppConfig } from 'containers/App/config';
import { Loading } from 'components/feedback/loading/loading';
import { GlobalScrollLock } from 'components/structure/overlay/styles/overlay';

import { SearchResults } from './searchResults';
import { Map } from './map';
import * as styled from './styles/supplierHub';
import i18n from './utils/i18n';
import { LocationInput } from './locationInput';

/*
 * props.context:
 * can be either `internal` or `external`, depending on whether
 * the VendorHub component should be rendered with authentication as a dependency or not.
 * */

export const VendorHubComponent = (props) => {
  const { suppliers, loading, context, searchResultsVisible } = props;
  const [mapsLoaded, setMapsLoaded] = useState(!!window.google);
  const [pageYOffset, setPageYOffset] = useState(0);

  const updatePageYOffset = () => setPageYOffset(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', updatePageYOffset);
    return () => window.removeEventListener('scroll', updatePageYOffset);
  }, []);

  useEffect(() => {
    if (!window.google) {
      ((d) => {
        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.src = `https://maps.googleapis.com/maps/api/js?key=${AppConfig.MapsApiKey}&libraries=places&language=en`;
        el.async = 'true';
        el.onload = () => setMapsLoaded({ mapsLoaded: true });

        const x = d.querySelectorAll('script')[0];
        x.parentNode.insertBefore(el, x);
      })(document);
    }
  }, []);

  const renderSearchResults = () => (
    <SearchResults
      context={context}
      yOffset={pageYOffset}
      {...pick(props, [
        'shownSuppliers',
        'searchResultsVisible',
        'onChangeVisibility',
        'onClickSupplierCard',
        'onResetFilters',
        'loading',
        'clearFiltersVisible',
        'onOpenProfile',
        'showCompleteProfileBanner',
      ])}
    />
  );

  const renderMap = () => (
    <Map
      context={context}
      yOffset={pageYOffset}
      {...pick(props, [
        'shownSuppliers',
        'searchResultsVisible',
        'mapParams',
        'chosenSupplierId',
        'onClickSupplierMarker',
        'onClickMarkerInfoClose',
        'onOpenProfile',
        'onChangeMapParams',
        'onMapContainerRef',
      ])}
    />
  );

  const renderSearchContainer = () => {
    const {
      onChangeSearchConditions,
      onResetLocation,
      onChangeLocation,
      searchConditions,
      industries,
      specializations,
      showCompleteProfileBanner,
    } = props;

    return (
      <styled.SearchContainer
        showCompleteProfileBanner={showCompleteProfileBanner}
        context={context}
      >
        <styled.LeftSide>
          <styled.SearchField
            onChange={(value) => onChangeSearchConditions('name', value)}
            searchTerm={searchConditions.name}
            placeholder={i18n.searchPlaceholder}
          />
          <LocationInput
            onChange={(value) => onChangeSearchConditions('location', value)}
            searchTerm={searchConditions.location}
            onResetLocation={onResetLocation}
            onChangeLocation={onChangeLocation}
            placeholder={i18n.locationPlaceholder}
          />
          <styled.SelectField
            onChange={(value) => onChangeSearchConditions('industries', value)}
            selected={searchConditions.industries}
            options={industries}
          />
          <styled.SelectField
            onChange={(value) =>
              onChangeSearchConditions('specializations', value)
            }
            selected={searchConditions.specializations}
            options={specializations}
          />
        </styled.LeftSide>
        {context === 'external' && (
          <styled.RightSide>
            <styled.StaffingAgencyInfo>
              <FormattedMessage {...i18n.staffingAgency} />
              <styled.StaffingAgencyLink target="_blank" to="/signup">
                <FormattedMessage {...i18n.staffingAgencyLink} />
              </styled.StaffingAgencyLink>
            </styled.StaffingAgencyInfo>
          </styled.RightSide>
        )}
      </styled.SearchContainer>
    );
  };

  const isLoading = (suppliers.length === 0 && loading) || !mapsLoaded;
  /*
   google-maps-react cannot take params to fetch places API.
   This way we load the script and ensure that the map doesn't get shown before.
   */

  return (
    <styled.SupplierHubContainer>
      {!searchResultsVisible && <GlobalScrollLock />}
      {renderSearchContainer()}
      {isLoading ? (
        <Loading />
      ) : (
        <styled.BottomContainer context={context}>
          {renderSearchResults()}
          {renderMap()}
        </styled.BottomContainer>
      )}
    </styled.SupplierHubContainer>
  );
};

/* eslint react/no-unused-prop-types:0 */
VendorHubComponent.propTypes = {
  context: PropTypes.string,
  industries: PropTypes.array,
  specializations: PropTypes.array,
  suppliers: PropTypes.array,
  loading: PropTypes.bool,
  searchResultsVisible: PropTypes.bool,
  clearFiltersVisible: PropTypes.bool,
  searchConditions: PropTypes.object,
  onChangeSearchConditions: PropTypes.func,
  onChangeVisibility: PropTypes.func,
  onClickSupplierCard: PropTypes.func,
  mapParams: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    zoom: PropTypes.number,
  }),
  chosenSupplierId: PropTypes.string,
  onClickSupplierMarker: PropTypes.func,
  onClickMarkerInfoClose: PropTypes.func,
  onResetFilters: PropTypes.func,
  onOpenProfile: PropTypes.func,
  onChangeMapParams: PropTypes.func,
  onChangeLocation: PropTypes.func,
  onResetLocation: PropTypes.func,
  onMapContainerRef: PropTypes.func,
  showCompleteProfileBanner: PropTypes.bool,
  shownSuppliers: PropTypes.array,
};
