import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ReactPageClick } from 'react-page-click';
import { range, some, isEmpty } from 'lodash';
import moment from 'moment';

import { Toggle } from 'components/form/toggle/toggle';
import { monthYearComparable } from 'components/utils/date';

import * as styled from './styles';

export const MonthYear = (props: MonthYearProps) => {
  const {
    value,
    required,
    placeholder,
    shouldValidate,
    onChange,
    minDate,
    maxDate,
    presentSelect,
  } = props;
  const intl = useIntl();
  const today = useMemo<moment.Moment>(() => moment().startOf('year'), []);
  const [year, setYear] = useState<number | null>(value?.year || null);
  const [month, setMonth] = useState<number | null>(value?.month || null);
  const [present, setPresent] = useState<boolean>(value?.present || false);
  const [currentYear, setCurrentYear] = useState<number>(
    year || new Date().getFullYear()
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const mount = useRef(false);
  const isValid = (!shouldValidate ||
    (required && (year || present))) as boolean;

  const isDisabled = useCallback(
    (newYear: number, newMonth: number): boolean => {
      const newDateStr = monthYearComparable({
        year: newYear,
        month: newMonth,
      });
      const minDateStr = monthYearComparable(minDate);
      const maxDateStr = monthYearComparable(maxDate);

      return newDateStr > maxDateStr || newDateStr < minDateStr;
    },
    [minDate, maxDate]
  );

  const changeCurrentYear = useCallback(
    (direction: number) => {
      setCurrentYear(currentYear + direction);
    },
    [currentYear]
  );

  const selectYear = useCallback(() => {
    setYear(currentYear);
    setMonth(null);
    setPresent(false);
    setOpen(false);
  }, [currentYear]);

  const selectMonth = useCallback(
    (newMonth: number) => {
      setYear(currentYear);
      setMonth(newMonth);
      setPresent(false);
      setOpen(false);
    },
    [currentYear]
  );

  const onOpen = useCallback(() => {
    setOpen(true);
    setCurrentYear(year || currentYear);
  }, [currentYear, year]);

  const togglePresent = useCallback(() => {
    setMonth(null);
    setPresent(!present);

    if (present) {
      setYear(currentYear);
    } else {
      setYear(null);
    }
  }, [currentYear, present]);

  const onClear = () => {
    setYear(null);
    setMonth(null);
    setPresent(false);
    setOpen(false);
  };

  useEffect(() => {
    if (presentSelect && present) {
      setInputValue(intl.formatMessage(presentSelect.value));
    } else if (year && month) {
      setInputValue(
        today
          .year(year)
          .month(month - 1)
          .format('MMMM YYYY')
      );
    } else if (year) {
      setInputValue(year.toString());
    } else {
      setInputValue('');
    }

    if (mount.current) {
      if (year === null && month === null && present === false) {
        onChange(null);
      } else if (some([year, month, present], (item) => item !== undefined)) {
        onChange({ year, month, present });
      }
    }
    mount.current = true;
  }, [year, month, present, presentSelect]);

  return (
    <ReactPageClick notify={() => setOpen(false)}>
      <styled.MonthYear>
        <styled.InputWrapper isOpen={open} isValid={isValid}>
          <styled.Input
            type="text"
            value={inputValue}
            placeholder={intl.formatMessage(placeholder)}
            onFocus={onOpen}
          />
          {!isEmpty(inputValue) && (
            <styled.IconClear
              icon="X"
              color="text"
              size="xlarge"
              onClick={onClear}
            />
          )}
        </styled.InputWrapper>
        {open && (
          <styled.Picker>
            <styled.Pointer />
            <styled.Header>
              <styled.Prev onClick={() => changeCurrentYear(-1)}></styled.Prev>
              <styled.Year
                onClick={() => !isDisabled(currentYear, 0) && selectYear()}
                isDisabled={isDisabled(currentYear, 0)}
              >
                {currentYear}
              </styled.Year>
              <styled.Next onClick={() => changeCurrentYear(1)}></styled.Next>
            </styled.Header>
            <styled.Months>
              {range(1, 13).map((currentMonth: number) => {
                const monthDisabled = isDisabled(currentYear, currentMonth);

                return (
                  <styled.Month
                    key={`month-${currentMonth}`}
                    isActive={year === currentYear && month === currentMonth}
                    isDisabled={monthDisabled}
                    onClick={() => !monthDisabled && selectMonth(currentMonth)}
                  >
                    {today.month(currentMonth - 1).format('MMM')}
                  </styled.Month>
                );
              })}
            </styled.Months>
            {presentSelect && (
              <styled.ToggleWrapper>
                <Toggle
                  size="small"
                  color="green"
                  checked={!!present}
                  options={[
                    { checked: true, label: presentSelect.label },
                    { checked: false, label: presentSelect.label },
                  ]}
                  onChange={togglePresent}
                />
              </styled.ToggleWrapper>
            )}
          </styled.Picker>
        )}
      </styled.MonthYear>
    </ReactPageClick>
  );
};
