import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'components/visual/Spinner';

import * as styled from './styles';

export const List = function List(props) {
  const {
    children,
    paginated,
    pageChange,
    height,
    initLoader,
    pagesLoader,
    isFetching,
  } = props;
  const ref = useRef();
  const dataLength =
    paginated.page === paginated.pages
      ? paginated.totalCount
      : paginated.page * paginated.pageSize;
  const hasMore =
    paginated.page === 0 ||
    (paginated.page !== paginated.pages && Math.abs(paginated.pages) > 0);
  const hasLoader = pagesLoader && (paginated.page > 0 || initLoader);
  const scrollComponent = height
    ? ref.current?.el
    : document.getElementById('app');

  useEffect(() => {
    if (
      hasMore &&
      !isFetching &&
      scrollComponent &&
      scrollComponent.scrollHeight <= scrollComponent.clientHeight
    ) {
      pageChange();
    }
  }, [isFetching, hasMore, dataLength]);

  return (
    <styled.InfiniteScroll
      ref={ref}
      next={pageChange}
      scrollThreshold="50px"
      hasMore={hasMore}
      dataLength={dataLength}
      hasChildren={dataLength > 0}
      height={height}
      loader={
        hasLoader && (
          <styled.SpinnerWrapper>
            <Spinner />
          </styled.SpinnerWrapper>
        )
      }
    >
      {children}
    </styled.InfiniteScroll>
  );
};

List.propTypes = {
  children: PropTypes.node,
  paginated: PropTypes.object,
  pageChange: PropTypes.func,
  height: PropTypes.string,
  initLoader: PropTypes.bool,
  pagesLoader: PropTypes.bool,
  isFetching: PropTypes.bool,
};

List.defaultProps = {
  initLoader: true,
  pagesLoader: true,
};
