import React, { useEffect, useState, useRef } from 'react';
import { Editor } from '@tiptap/core';
import { useIntl } from 'react-intl';
import { compact, every, isEmpty, last, trim } from 'lodash';
import { IncompleteJsonParser } from 'incomplete-json-parser';
import cuid from 'cuid';

import { useAppDispatch } from 'store';
import { confirmAlert } from 'components/overlay/Confirm';
import type { EmojiName } from 'components/elements/types';
import { scrollParent } from 'components/utils/scroll';
import { isValidJsonString } from 'components/utils/json';
import { usePhoenixChannel, usePhoenixEvent } from 'utils/hooks/phoenix';

import { Scene } from './scene';
import { generate, cleanUpThreadAndRun } from './actions';
import i18n from './scene/utils/i18n';

export type PromptSuggestion = {
  text: I18nMessage;
  value: string;
  shortValue: string;
  emojiName: EmojiName;
};

type AITextInputProps = {
  editor: Editor;
  maxLimit: number;
  editorRef: React.RefObject<HTMLDivElement>;
  floatingMenuRef: React.RefObject<HTMLDivElement>;
  promptSuggestions: PromptSuggestion[];
  setAskAIOpen: (state: boolean) => void;
};

let editorId: string;
const parser = new IncompleteJsonParser();

export const AITextInput = (props: AITextInputProps) => {
  const {
    editorRef,
    floatingMenuRef,
    editor,
    setAskAIOpen,
    promptSuggestions = [],
    maxLimit = 10,
  } = props;
  const [prompt, setPrompt] = useState<Prompt>({
    value: '',
    short_value: '',
  });
  const [inProgress, setInProgress] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [currentIndex, setIndex] = useState(0);
  const [currentRawContent, setCurrentRawContent] = useState('');
  const [dimensions, setDimensions] = useState({ top: 0, left: 0 });
  const [isReplacing, setIsReplacing] = useState(false);
  const [nextPrompt, setNextPrompt] = useState<{
    value?: Prompt;
    status: boolean;
  }>({ status: false });
  const [currentRunId, setCurrentRunId] = useState<string | null>(null);
  const scrollEl = useRef<HTMLDivElement | null>(null);

  const suggestionsCount = suggestions.length;
  const intl = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    editorId = cuid();
  }, []);

  const streamDataChannel = usePhoenixChannel(`stream:${editorId}`);

  usePhoenixEvent({
    channel: streamDataChannel,
    event: 'stream:data',
    callback: ({
      topic_id: topicId,
      stream_text: streamText,
      data,
      is_complete: isComplete,
    }: SuggestionResponse) => {
      let complete = isComplete;
      if (!isComplete && !data.run_id) {
        parser.write(streamText);
      }

      let noteObject: Content;
      if (!data.run_id) {
        setCurrentRawContent((prev) => prev + streamText);
        noteObject = parser.getObjects();
      }

      if (topicId === editorId) {
        if (inProgress) {
          setInProgress(false);
        }

        if (data.run_id) {
          setCurrentRunId(data.run_id);
        }

        // for cases where openai doesn't send the 'completed' event
        if (currentRawContent.endsWith('}') && !complete) {
          complete = isValidJsonString(currentRawContent);
        }

        setSuggestions((prev) =>
          updateSuggestions(prev, data, noteObject, complete)
        );

        if (complete) {
          parser.reset();
        }
      }
    },
  });

  const updateSuggestions = (
    prev: Suggestion[],
    data: Suggestion,
    noteObject: Content,
    isComplete: boolean = false
  ) =>
    isEmpty(prev)
      ? [{ ...data, content: noteObject, is_complete: false }]
      : prev.map((item, index) =>
          index === prev.length - 1
            ? {
                ...item,
                ...data,
                content: noteObject,
                is_complete: isComplete,
              }
            : item
        );

  const handleGenerateSuggesion = (customPrompt?: Prompt) => {
    if (!isEmpty(suggestions) && !last(suggestions)?.is_complete) {
      setNextPrompt({ value: customPrompt, status: true });

      return;
    }
    const selection = editor.commands.getSelectedHTML().toString();

    if (
      suggestionsCount === 0 &&
      (isEmpty(trim(selection)) ||
        every([prompt, customPrompt], (selectedOrCustomPrompt) =>
          isEmpty(trim(selectedOrCustomPrompt?.value))
        ))
    ) {
      return;
    }

    setInProgress(true);
    setCurrentRawContent('');
    setPrompt({
      value: '',
      short_value: '',
    });
    setNextPrompt({ status: false });
    parser.reset();
    dispatch(
      generate({
        suggestions,
        selection,
        content: editor.getHTML(),
        prompt: customPrompt || prompt!,
        editor_id: editorId,
      })
    );

    setSuggestions((prevSuggestions) =>
      compact([
        ...prevSuggestions,
        !isEmpty(prevSuggestions) && {
          content: { modified_selected_part: '', modified_full_content: '' },
          assistant_id: '',
          followup_prompt: '',
          thread_id: '',
          run_id: '',
          is_complete: false,
        },
      ])
    );
  };

  const handleReplaceContent = (suggestion: Suggestion) => {
    setIsReplacing(true);
    if (suggestion.is_complete) {
      const fullContent = suggestion?.content?.modified_full_content;

      editor.commands.setContent(fullContent, true);
      setAskAIOpen(false);
      setIsReplacing(false);
    }
  };

  const handleOnclose = () => {
    if (!isConfirmOpen && suggestions.length > 0) {
      confirmAlert({
        title: intl.formatMessage(i18n.confirmDiscard),
        confirmLabel: intl.formatMessage(i18n.buttonDiscard),
        cancelLabel: intl.formatMessage(i18n.buttonCancel),
        onConfirm: () => {
          setAskAIOpen(false);
          onCancelAndCleanup();
        },
        onCancel: () => setIsConfirmOpen(false),
      });
      setIsConfirmOpen(true);
    }
    if (suggestions.length === 0) {
      setAskAIOpen(false);
      onCancelAndCleanup();
    }
  };

  const onCancelAndCleanup = () => {
    const threadId = suggestions[0]?.thread_id;

    if (threadId || currentRunId) {
      dispatch(
        cleanUpThreadAndRun({
          thread_id: threadId,
          run_id: currentRunId,
        })
      );
    }
  };

  const onDimensionsChange = () => {
    const topDimension = floatingMenuRef.current?.getBoundingClientRect();
    const leftDimension = editorRef.current?.getBoundingClientRect();

    if (topDimension && leftDimension) {
      const { top, width: floatingMenuWidth } = topDimension;
      const { left } = leftDimension;

      setDimensions({
        top: floatingMenuWidth ? top : window.innerHeight / 2,
        left,
      });
    }
  };

  useEffect(() => {
    scrollEl.current = scrollParent(floatingMenuRef.current);
    onDimensionsChange();

    return () => {
      editor.commands.focus();
    };
  }, []);

  useEffect(() => {
    scrollEl.current = scrollParent(floatingMenuRef.current);
    if (scrollEl.current) {
      scrollEl.current.addEventListener('scroll', onDimensionsChange);
    }

    return () => {
      scrollEl.current?.removeEventListener('scroll', onDimensionsChange);
    };
  }, [floatingMenuRef.current]);

  return (
    <Scene
      intl={intl}
      dimensions={dimensions}
      prompt={prompt}
      suggestions={suggestions}
      suggestionsCount={suggestionsCount}
      isReplacing={isReplacing}
      inProgress={inProgress}
      currentIndex={currentIndex}
      maxLimit={maxLimit}
      promptSuggestions={promptSuggestions}
      setPrompt={setPrompt}
      nextPrompt={nextPrompt}
      onGenerate={handleGenerateSuggesion}
      onReplaceContent={handleReplaceContent}
      onClose={handleOnclose}
      setIndex={setIndex}
    />
  );
};
