import { includes } from 'lodash';

export const isInsuranceRelief = (companyId: string): boolean =>
  companyId === 'huj6wncyM2kma5mMuCMbii';

export const isPrideStaff = (companyId: string): boolean =>
  includes(['r6b6J95nzZWh8bXZN33j8C', 'Fn9M4NFhvgH3bCcufN8xWn'], companyId);

export const shouldHideCandidateDbFromMenu = (companyId: string): boolean =>
  isPrideStaff(companyId) || isInsuranceRelief(companyId);
