/* eslint-disable @typescript-eslint/no-explicit-any */
import { createBrowserHistory } from 'history';
import { stringify, parse } from 'qs';
import qhistory from 'qhistory';
import { createReduxHistoryContext } from 'redux-first-history';
import { createSelector } from 'reselect';
import { useDispatch } from 'react-redux';
import { configureStore } from 'configureStore';
import { AppDispatch } from 'types';

const selectRouterState = createSelector(
  (state: any) => state.get('router'),
  (substate) => substate
);

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history: qhistory(createBrowserHistory(), stringify, parse),
  selectRouterState,
});

// Create redux store with history
const initialState = {};
export const store = configureStore(
  initialState,
  routerMiddleware,
  routerReducer
);
export const history = createReduxHistory(store);

export const useAppDispatch: () => AppDispatch = useDispatch;
