import { IconBase } from './iconBase';

import { CustomIconsProp } from '../types';

export const Certificate = ({ size, color, className }: CustomIconsProp) => (
  <IconBase className={className} viewBox={256} size={size}>
    <rect width="256" height="256" fill="none" />
    <line
      x1="72"
      y1="136"
      x2="120"
      y2="136"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="72"
      y1="104"
      x2="120"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <circle
      cx="196"
      cy="124"
      r="44"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M168,192H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V90.06"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <polyline
      points="168 157.94 168 224 196 208 224 224 224 157.94"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </IconBase>
);
