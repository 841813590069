import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as styled from '../styles';

import i18n from '../utils/i18n';

export function ShowMoreLess({ className, editable, onClick }) {
  const [showMore, setShowMore] = useState(false);

  if (editable) return false;

  const text = showMore ? i18n.showLess : i18n.showMore;
  const handleClick = () => {
    setShowMore(!showMore);
    onClick(!showMore);
  };

  return (
    <styled.ShowMoreLessWrapper className={className}>
      <styled.ShowMoreLessButton
        onClick={handleClick}
        type="blank"
        color="tealDark"
      >
        <FormattedMessage {...text} />
      </styled.ShowMoreLessButton>
    </styled.ShowMoreLessWrapper>
  );
}

ShowMoreLess.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};
