import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanZ from '@thesoulfresh/pan-z';

import * as styled from './styles/image';
import i18n from './utils/i18n';
import { handlePZBounds } from './utils';

export class ImageViewer extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
    this.imgWrapper = createRef();
    this.imgPreview = createRef();
  }

  componentDidMount() {
    const { enableZoom } = this.props;
    if (this.imgPreview?.current && enableZoom) {
      const pz = new PanZ();

      pz.init(this.imgPreview.current, {
        boundingElement: this.imgWrapper.current,
        minZoom: 1,
      });
      pz.on('update', handlePZBounds(pz));
    }
  }

  onError = (error) => {
    const { onDocumentError } = this.props;

    this.setState({ error });

    if (typeof onDocumentError === 'function') {
      onDocumentError(error);
    }
  };

  renderImagePrint = (printContainer) =>
    new Promise((resolve) => {
      const { url } = this.props;

      const img = document.createElement('img');
      img.src = url;
      img.setAttribute('id', 'printImg');

      const wrapper = document.createElement('div');
      wrapper.appendChild(img);
      printContainer.appendChild(wrapper);

      resolve();
    });

  render() {
    const { url } = this.props;
    const { error } = this.state;
    const { enableZoom } = this.props;

    if (!url) {
      return (
        <styled.Error>
          <FormattedMessage {...i18n.noData} />
        </styled.Error>
      );
    }

    if (error) {
      return (
        <styled.Error>
          <FormattedMessage {...i18n.loadError} />
        </styled.Error>
      );
    }

    return (
      <styled.ImageWrapper ref={this.imgWrapper} enableZoom={enableZoom}>
        <styled.ImagePreview
          src={url}
          onError={this.onError}
          ref={this.imgPreview}
        />
      </styled.ImageWrapper>
    );
  }
}

ImageViewer.propTypes = {
  url: PropTypes.string,
  onDocumentError: PropTypes.func,
  enableZoom: PropTypes.bool,
};
