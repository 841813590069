import { personNameRegex, companyNameRegex } from 'utils/regexes';

/**
 * @function isValidPersonName
 * @param {string} str
 * @return {boolean}
 */
export const isValidPersonName = function isValidPersonName(str) {
  return new RegExp(personNameRegex, 'i').test(str);
};

/**
 * @function isValidCompanyName
 * @param {string} str
 * @return {boolean}
 */
export const isValidCompanyName = function isValidCompanyName(str) {
  return companyNameRegex.test(str);
};

export const userName = (user, fullName = true) => {
  const hasName = user.first_name && user.last_name;

  if (!hasName) {
    return user.email;
  }
  return fullName ? `${user.first_name} ${user.last_name}` : user.first_name;
};
