import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Breadcrumb as BreadcrumbBase } from 'components/navigation/breadcrumb/breadcrumb';
import { ContextHeader } from 'components/structure/page/styles/context-header';
import {
  contextHeaderHeight,
  tableMenuHeight,
} from 'components/structure/page/utils/calculations';
import { Badge as BadgeBase } from 'components/misc/Badge';
import { SmartLink as LinkBase } from 'components/navigation/SmartLink';

export const Header = styled(ContextHeader)<{ isAdminHeader: boolean }>`
  ${({ isAdminHeader }) =>
    isAdminHeader && `border-bottom: 1px solid ${Colors.outline}`};
`;

const adminHeaderMixin = css`
  overflow: visible;
  font-weight: 600;
`;

export const Label = styled.div<{ isAdminHeader: boolean }>`
  color: ${Colors.text};
  font-size: 2.2rem;
  line-height: 1;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ isAdminHeader }) => isAdminHeader && adminHeaderMixin};
`;

export const Breadcrumb = styled(BreadcrumbBase)<{
  routeTo: string;
  title: string;
  className?: string;
}>`
  width: auto;
  max-width: 60%;
`;

export const Menu = styled.div`
  height: ${contextHeaderHeight}rem;
  justify-content: flex-end;
  display: flex;
  margin-left: auto;
`;

export const TableMenu = styled.div`
  width: 100%;
  height: ${tableMenuHeight}rem;
  justify-content: flex-start;
  display: flex;
  margin-right: auto;
  background-color: ${Colors.white};
  padding: 1.5rem 2rem;
`;

export const Badges = styled.div`
  align-items: center;
  display: inline-flex;

  & > * {
    margin-left: 2rem;
  }
`;

export const Badge = styled(BadgeBase)`
  padding: 0.5rem 2rem;
  margin-left: 2rem;
  font-size: 2rem;
`;

export const HelpSection = styled.div`
  background-color: ${Colors.headerBG};
  padding: 0 2.4rem 0.01rem;
`;

export const HelpLinks = styled.div`
  display: flex;
  margin: 2rem 0;

  &:empty {
    margin-bottom: 0;
  }

  & > * {
    margin-right: 2rem;
  }
`;

export const HelpLink = styled(LinkBase)`
  color: ${Colors.tealDark};

  &:hover {
    text-decoration: underline;
  }
`;

export const ExtraSection = styled.div`
  padding: 0 2rem;
  z-index: 5;
`;
