import styled, { css } from 'styled-components';

import { Options } from 'containers/SharedList/AccessDropdown/styles';

import { Colors } from 'components/utils/styles/ui';
import { Button as ButtonBase } from 'components/elements/button';
import { Icon as IconBase } from 'components/elements/icon';
import { iconElementsSelector } from 'components/elements/styles/icon';
import { TagCompact } from 'components/navigation/menu/styles/link';
import { styledScrollbarMixin } from 'components/utils/styles/scrollbar';

export const tooltipShadow = css`
  background-color: ${Colors.white};
  border-radius: 0.4rem;
  filter: drop-shadow(0px 0px 16px rgba(19, 41, 61, 0.08))
    drop-shadow(0px 6px 16px rgba(19, 41, 61, 0.12));
`;

export const bubbleMarksStyles = css`
  .tooltip-shadow {
    ${tooltipShadow}
  }

  .tippy-svg-arrow {
    pointer-events: none;
    svg {
      fill: ${Colors.white};
    }
  }
`;

export const ShowMoreLessWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 2.6rem;
`;

export const marginMixin = css`
  margin-top: 0;
  margin-bottom: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const resizableMixin = ({ resizable }: { resizable: boolean }) =>
  resizable &&
  css`
    resize: vertical;
  `;

export const Container = styled.div``;

type WrapperProps = {
  hasFocus: boolean;
  isValid: boolean;
  withOverflow: boolean;
  editable: boolean;
  height: number;
  maxHeight: number;
  minHeight: number;
  resizable: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  border: 1px solid
    ${({ hasFocus, isValid }) => {
      if (hasFocus || (!isValid && hasFocus)) {
        return Colors.teal;
      }
      if (!isValid) {
        return Colors.red;
      }
      return Colors.inputOutline;
    }};
  position: relative;
  border-radius: 0.4rem;

  .ProseMirror {
    word-break: break-word;
    outline: none;
    padding-right: 1.2rem;
    overflow: ${({ withOverflow }) => (withOverflow ? 'auto' : 'hidden')};
    padding: 1.4rem 0
      ${({ withOverflow, editable }) =>
        withOverflow || editable ? '1.4rem' : 0}
      1.2rem;

    ${({ height }) => height && `height: ${height};`}
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
    ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}

    table {
      border-collapse: collapse;

      th {
        background-color: ${Colors.tealLightest};
      }

      td,
      th {
        border: 1px solid ${Colors.outline};
        padding: 0.4rem 0.8rem;
        text-align: center;
        vertical-align: middle;

        &.selectedCell {
          background-color: ${Colors.greyLight};
        }
      }
    }

    p {
      line-height: 2rem;
      vertical-align: middle;
      margin: 0;

      &.is-editor-empty:first-child::before {
        color: ${Colors.textLighter};
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
      }
    }

    ul {
      list-style: unset;
    }

    ul,
    ol {
      ${marginMixin}
    }

    & > ul,
    & > ol {
      padding-left: 2rem;
    }

    ${styledScrollbarMixin}
    ${resizableMixin};
  }

  ${bubbleMarksStyles}

  .variable,
  .node-variable {
    display: inline-block;
    height: 2.7rem;

    ${({ editable }) => !editable && 'pointer-events: none;'}
  }
`;

export const ShowMoreLessButton = styled(ButtonBase)`
  text-decoration: underline;
  padding: 0.4rem 1.4rem 1.2rem 1.4rem;
`;

export const MarkButton = styled(ButtonBase).attrs({
  type: 'blank',
})<{ active: boolean }>`
  margin: auto;
  position: relative;
  height: 3.6rem;
  width: 4rem;

  ${iconElementsSelector} {
    stroke: ${({ active }) =>
      active ? Colors.tealDark : Colors.textLight} !important;
  }

  &:hover {
    background-color: ${Colors.tealLightest};
  }
`;

export const VariablesButton = styled(MarkButton)`
  height: 3rem;
  width: 3rem;
`;

const noRightBordersMixin = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${iconElementsSelector} {
    stroke: ${Colors.grape} !important;
  }
`;

const nonFloatingButtonMixin = ({
  withBarTextTools,
}: {
  withBarTextTools: boolean;
}) => css`
  height: 2.4rem;
  position: absolute;
  right: 0;
  top: ${withBarTextTools ? '-3.5rem' : '-3rem'};
`;

export const AskButton = styled(ButtonBase)<{
  floating: boolean;
  withBarTextTools: boolean;
}>`
  ${({ floating }) => (floating ? noRightBordersMixin : nonFloatingButtonMixin)}
`;

export const AskAIText = styled.div<{ floating: boolean }>`
  ${({ floating }) => floating && `color: ${Colors.grape};`}
  font-weight: 500;
  font-size: 1.4rem;
  margin-left: 0.6rem;
`;

export const Icon = styled(IconBase).attrs({
  size: 'normal',
})``;

export const VariablesList = styled(Options)`
  display: grid;
  grid-auto-rows: 3.6rem;
  align-items: center;
  min-width: 24.2rem;
  right: 3.5rem;
  box-shadow: none;
`;

export const VariablesListTitle = styled.div`
  font-weight: 500;
  padding: 0 1.2rem;
  color: ${Colors.textLighter};
  border-top: 1px solid ${Colors.outline};

  ${({ theme }) => theme.typography.text.small}
  line-height: 3rem;

  text-transform: uppercase;
  &:first-child {
    border-top: none;
  }
`;

export const VariablesListItemHeight = 3.6;

export const VariablesListItem = styled.div`
  font-weight: 500;
  line-height: ${VariablesListItemHeight}rem;
  color: ${Colors.text};
  padding: 0 1.2rem;
  cursor: pointer;

  ${TagCompact} {
    font-weight: 500;
  }

  &:hover {
    background-color: ${Colors.tealLightest};
  }
`;

export const Toolbar = styled.div`
  display: grid;
  margin: 1.2rem 0;
  column-gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, 4rem);
  grid-auto-columns: 4rem;

  ${MarkButton} {
    border: 1px solid ${Colors.outline};
  }
`;

export const CharCount = styled.div`
  margin-top: 1.2rem;
  color: ${Colors.textLight};
  ${({ theme }) => theme.typography.text.normal}
`;
