import { defineMessages } from 'react-intl';

export default defineMessages({
  crop: {
    id: 'app.components.Visual.CropImage.crop',
    defaultMessage: 'Crop',
  },
  cancel: {
    id: 'app.components.Visual.CropImage.cance',
    defaultMessage: 'Cancel',
  },
});
