import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

const borderMixin = ({ focus, isValid, theme }) => {
  const color =
    (focus && theme.colors.primary) ||
    (isValid && Colors.inputOutline) ||
    Colors.red;
  return css`
    border: solid 1px ${color};
  `;
};
export const DatePicker = styled.div`
  border-radius: 0.3rem;
  position: relative;
  width: 100%;

  ${borderMixin}

  & > div {
    width: calc(100% - 4rem);
  }

  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    width: 100%;
  }

  input {
    width: 100%;
    height: 4rem;
    border: 0;
    border-radius: 0.4rem 0 0 0.4rem;

    color: ${Colors.text};
    padding: 0.6rem 1rem;

    ${({ theme }) => theme.typography.text.normal};

    &:focus {
      outline: none;
    }
    &:disabled {
      background-color: ${Colors.greyLighter};
    }
    &::placeholder {
      color: ${Colors.textLighter};
    }
  }

  .react-datepicker-popper {
    .react-datepicker__header {
      background-color: ${Colors.white};
      border: 0;
    }

    // tooltip triangle arrow
    .react-datepicker__triangle {
      border-bottom-color: ${Colors.white} !important;
      &::before {
        border-bottom-color: ${Colors.grey} !important;
      }
    }

    .react-datepicker {
      border: 1px solid ${Colors.grey};
      font-size: 1.4rem;
      padding: 1.6rem;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
      color: ${Colors.text};
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    .react-datepicker__year-dropdown-container {
      margin-bottom: 1rem;

      .react-datepicker__year-dropdown {
        background-color: ${Colors.white};
        border-color: ${Colors.grey};
        color: ${Colors.text};
      }

      .react-datepicker__year-read-view--down-arrow {
        border-top-color: ${Colors.text};
        top: 0.5rem;
      }

      .react-datepicker__year-read-view--selected-year {
        color: ${Colors.text};
        background-color: ${Colors.white};
      }

      // year dropdown up/down navigation arrows
      .react-datepicker__navigation--years {
        height: 2rem;
        border-width: 0.8rem;
      }
      .react-datepicker__navigation--years-upcoming {
        top: -0.6rem;
      }
      .react-datepicker__navigation--years-previous {
        top: 0.6rem;
        margin-bottom: -1rem;
      }
      .react-datepicker__year-option:hover {
        .react-datepicker__navigation--years-upcoming {
          border-bottom-color: ${({ theme }) => theme.colors.primary};
        }
        .react-datepicker__navigation--years-previous {
          border-top-color: ${({ theme }) => theme.colors.primary};
        }
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 4rem;
      margin: 0;
      padding: 1rem;
      color: ${Colors.text};
    }

    .react-datepicker__day-name {
      padding: 0 1rem;
      color: ${Colors.greyDark};
      font-size: 1.2rem;
    }

    .react-datepicker__day--disabled {
      color: ${Colors.greyDark};
    }

    .react-datepicker__day--outside-month {
      color: transparent;
      pointer-events: none;
    }

    [class*='selected'] {
      color: ${Colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 0;
    }
    .react-datepicker__day {
      &:not([class*='selected']):hover {
        background-color: ${Colors.grey};
        border-radius: 0;
      }
    }

    // navigation arrows
    .react-datepicker__navigation--previous {
      border: 0.8rem solid transparent;
      top: 2.8rem;
      border-right-color: ${Colors.text};
      left: 3rem;
    }
    .react-datepicker__navigation--next {
      border: 0.8rem solid transparent;
      top: 2.8rem;
      border-left-color: ${Colors.text};
      right: ${({ timeSelect }) => (timeSelect ? 14 : 3)}rem;
    }

    // style as table with border
    .react-datepicker__month {
      display: table;
      border-collapse: collapse;
      border-spacing: 0;
    }
    .react-datepicker__week {
      display: table-row;
    }
    .react-datepicker__day {
      display: table-cell;
      &:not(.react-datepicker__day--outside-month) {
        border: 1px solid ${Colors.grey};
      }
    }

    // time selection controls
    .react-datepicker__time-container {
      width: auto;
      margin-left: 1.6rem;
      border-color: ${Colors.grey};
      color: ${Colors.text};

      .react-datepicker__time-box {
        width: auto;
      }

      ul.react-datepicker__time-list {
        padding: 0 1rem;
        box-sizing: border-box;

        li.react-datepicker__time-list-item--selected {
          &,
          &:hover {
            background-color: ${({ theme }) => theme.colors.primary};
          }
        }
      }

      .react-datepicker__time-list-item:hover {
        background-color: ${Colors.grey};
      }
    }
  }
`;

export const Indicator = styled.a`
  align-items: center;
  border-left: solid 1px ${Colors.inputOutline};
  border-radius: 0 0.4rem 0.4rem 0;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${Colors.white};
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  font-weight: 400;
  height: 4rem;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
`;
