import styled from 'styled-components';

import { Tags as TagsBase } from 'components/visual/tags';
import { Colors } from 'components/utils/styles/ui';

export const InputContainer = styled.div`
  position: relative;
`;

export const TagsList = styled(TagsBase)`
  margin-top: 1.2rem;
`;

export const QuickContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 2.6rem;
  margin-bottom: 1.4rem;
  font-size: 1.3rem;
`;

export const QuickList = styled(TagsBase)`
  margin-left: 1.2rem;
`;

export const TagsEmptyState = styled.div`
  color: ${Colors.text};
  text-align: center;
  margin: auto;

  ${({ theme }) => theme.typography.text.small};
`;
