import { useIntl } from 'react-intl';
import { parsePhoneNumberOrValue } from 'components/utils/phoneNumber';

import { InputText } from './text';

type PhoneInputProps = {
  placeholder: I18nMessage;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};
export const InputPhone = ({
  placeholder,
  value,
  onChange,
  onFocus,
  disabled,
}: PhoneInputProps) => {
  const intl = useIntl();

  return (
    <InputText
      placeholder={intl.formatMessage(placeholder)}
      value={parsePhoneNumberOrValue(value || '')}
      onChange={onChange}
      onFocus={onFocus}
      disabled={disabled}
    />
  );
};
