import PropTypes from 'prop-types';

import * as styled from './styles/card';

export const Card = function Card({ title, children, headerColor, ...props }) {
  return (
    <styled.Card {...props}>
      {title && (
        <styled.Header headerColor={headerColor}>{title}</styled.Header>
      )}
      {children}
    </styled.Card>
  );
};

Card.propTypes = {
  headerColor: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isSimple: PropTypes.bool,
};

Card.defaultProps = {
  title: null,
};
