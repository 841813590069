import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import i18n from './utils/i18n';
import * as styled from './styles/supplierCard';
import { openInNewTab } from './utils/functions';
import { Ratings } from './ui/ratings';

const prepareWebsite = (website) => {
  if (website.startsWith('http://') || website.startsWith('https://')) {
    return website;
  }

  return `http://${website}`;
};

export function SupplierCard({
  supplier,
  onClick,
  className,
  nameColor,
  children,
  onOpenProfile,
}) {
  const withRating = supplier.ratings.length > 0;

  return (
    <styled.SupplierCard className={className} onClick={onClick}>
      {children}
      {supplier.account_verified && (
        <styled.VerifiedBadge icon="Check" color="secondary" size="normal" />
      )}
      <styled.SupplierLogo
        context={{ picture: supplier.logo, initials: supplier.name[0] }}
        color={supplier.color}
        size="large"
        shape="square-rounded"
      />
      <styled.ViewProfileLink onClick={onOpenProfile}>
        <FormattedMessage {...i18n.viewProfile} />
      </styled.ViewProfileLink>
      <styled.SupplierName color={nameColor}>
        {supplier.name}
      </styled.SupplierName>
      {withRating && <Ratings company={supplier} />}
      <styled.SupplierInfo>
        {supplier.billing_address && (
          <styled.SupplierInfoCard>
            <styled.SupplierInfoCardTitle>
              <FormattedMessage {...i18n.supplierHeadquarter} />
            </styled.SupplierInfoCardTitle>
            <styled.SupplierInfoCardValue>
              <styled.SupplierCardIcon icon="MapPin" />
              <styled.SupplierCardValueText>
                {supplier.billing_address.name}
              </styled.SupplierCardValueText>
            </styled.SupplierInfoCardValue>
          </styled.SupplierInfoCard>
        )}
        <styled.SupplierInfoCard>
          <styled.SupplierInfoCardTitle>
            <FormattedMessage {...i18n.supplierPhoneNumber} />
          </styled.SupplierInfoCardTitle>
          <styled.SupplierInfoCardValue>
            <styled.SupplierCardIcon icon="Phone" />
            <styled.SupplierCardValueText>
              {supplier.phone_number || <FormattedMessage {...i18n.na} />}
            </styled.SupplierCardValueText>
          </styled.SupplierInfoCardValue>
        </styled.SupplierInfoCard>
        <styled.SupplierInfoCard>
          <styled.SupplierInfoCardTitle>
            <FormattedMessage {...i18n.supplierWebsite} />
          </styled.SupplierInfoCardTitle>
          <styled.SupplierWebsite
            onClick={(ev) => openInNewTab(ev, prepareWebsite(supplier.website))}
          >
            <styled.SupplierCardIcon icon="Link" />
            <styled.SupplierCardValueText>
              {prepareWebsite(supplier.website)}
            </styled.SupplierCardValueText>
          </styled.SupplierWebsite>
        </styled.SupplierInfoCard>
      </styled.SupplierInfo>
    </styled.SupplierCard>
  );
}

SupplierCard.propTypes = {
  supplier: PropTypes.object,
  onClick: PropTypes.func,
  onOpenProfile: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.object,
  nameColor: PropTypes.string,
};

SupplierCard.defaultProps = {
  onClick: () => null,
  className: '',
  nameColor: 'text',
};
