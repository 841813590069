import { ssnRegex } from 'utils/regexes';

/**
 * @function isValidSsnNumber
 * @param {string} value
 * @return {boolean}
 */
export const isValidSsnNumber = function isValidSsnNumber(value) {
  return ssnRegex.test(value);
};
