import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

export const TableWrapper = styled.div``;

export const Header = styled.h4`
  margin: 1.8rem 0;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const Th = styled.th`
  padding: 0.8rem 0.8rem 0.8rem 0;
  border-bottom: 0.1rem solid ${Colors.greyLight};
  width: 33%;
  text-align: left;
  color: ${Colors.textLighter};

  &:last-child {
    padding-right: 0;
  }
`;

export const Td = styled.td`
  padding: 0.8rem 0;
  width: 33%;
`;
