import { Component } from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';

import { Button } from 'components/elements/button';

export class ClipboardButton extends Component {
  constructor(props) {
    super(props);
    this.clipboard = null;
    this.copyButton = null;
  }

  componentDidMount() {
    const { copyText, target } = this.props;

    if (!target) {
      this.clipboard = new Clipboard(this.copyButton, {
        text: () => copyText,
      });
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  onClick = (clickEvent) => {
    const { onCopy, target } = this.props;

    clickEvent.preventDefault();

    if (target) {
      const listener = (copyEvent) => {
        copyEvent.clipboardData.setData('text/plain', target.innerText);
        copyEvent.clipboardData.setData('text/html', target.innerHTML);
        copyEvent.preventDefault();
      };

      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);
    }
    if (onCopy) {
      onCopy(clickEvent);
    }
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Button
        onClick={this.onClick}
        ref={(button) => {
          this.copyButton = button;
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  }
}

ClipboardButton.propTypes = {
  copyText: PropTypes.string,
  target: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node.isRequired,
  onCopy: PropTypes.func,
};
