import { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactPageClick } from 'react-page-click';

import * as styled from '../styles/infoButton';

export class InfoButton extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  toggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  render() {
    const { icon, color, text, align, children } = this.props;
    const { open } = this.state;

    return (
      <ReactPageClick notify={() => open && this.toggle()}>
        <styled.Wrapper>
          <styled.Button color={color} onClick={this.toggle}>
            {icon && <styled.Icon size="large" icon={icon} color={color} />}
            <styled.ButtonText>{text}</styled.ButtonText>
            <styled.Icon
              size="large"
              icon={open ? 'CaretUp' : 'CaretDown'}
              color={color}
              right
            />
          </styled.Button>
          {open && <styled.Content align={align}>{children}</styled.Content>}
        </styled.Wrapper>
      </ReactPageClick>
    );
  }
}

InfoButton.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.object,
  align: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
};

InfoButton.defaultProps = {
  align: 'right',
};
