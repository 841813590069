import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactPageClick } from 'react-page-click';

import { getDropdownOptionsSettings } from 'components/utils/dropdown';
import { scrollToOption } from 'components/utils/scroll';
import { KEYS } from 'components/utils/keys';
import { Colors } from 'components/utils/styles/ui';

import {
  isEmpty,
  includes,
  uniq,
  intersection,
  difference,
  noop,
  filter,
} from 'lodash';

import { Icon } from 'components/elements/icon';
import { Tooltip } from 'components/overlay/Tooltip';

import * as styled from './styles';
import i18n from './utils/i18n';

const BORDER_HEIGHT = 0.2;
const FILTER_HEIGHT_PX = 40;

export const TagsDropdown = (props) => {
  const {
    className,
    onChange,
    onFocus,
    options,
    selected,
    bullhornOptions,
    disabledOptions,
    optionsHeight,
    placeholder,
    maxOptionsHeight,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const optionsRef = useRef();
  const typeAheadRef = useRef();
  const [hoveredOption, setHoveredOption] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState('');
  const [optionsPosition, setOptionsPosition] = useState('bottom');
  const [optionsSumHeight, setOptionsSumHeight] = useState(
    options.length * optionsHeight
  );
  const intl = useIntl();
  const selectRef = useRef();

  const onSelect = useCallback(
    (option) => onChange(uniq([...selected, option.slug])),
    [selected]
  );

  const onUnselect = useCallback(
    (option) => onChange(difference(selected, [option])),
    [selected]
  );

  const onClear = () => onChange([]);

  const handleExpandClick = () => {
    if (expanded) return;

    const { position, height } = getDropdownOptionsSettings({
      element: selectRef.current,
      options,
      optionsPosition,
      optionsHeight,
      borderHeight: BORDER_HEIGHT,
    });

    setOptionsPosition(position);
    setOptionsSumHeight(height);
    setExpanded(true);
  };

  const selectedOptions = filter(options, ({ slug }) =>
    includes(selected, slug)
  );

  const bullhornOnlyOptions = difference(
    intersection(selected, bullhornOptions),
    options.map(({ slug }) => slug)
  );
  const archivedOptions = difference(selected, [
    ...options.map(({ slug }) => slug),
    ...bullhornOptions,
  ]);

  const handleTypeAheadChange = (value) => {
    setTypeAheadValue(value);
  };

  useEffect(() => {
    scrollToOption(
      optionsRef,
      hoveredOption,
      optionsPosition === 'top' ? 0 : FILTER_HEIGHT_PX
    );
  }, [hoveredOption, optionsPosition]);

  const filteredOptions = useMemo(
    () =>
      typeAheadValue.trim()
        ? options.filter((option) =>
            option.label.toLowerCase().includes(typeAheadValue.toLowerCase())
          )
        : options,
    [typeAheadValue, options]
  );

  const typeAheadEnabled = () => options && options.length > 6;

  const renderTypeAhead = () => {
    const filterPlaceholder = i18n.typeAhead;

    return (
      <styled.OptionsTypeAhead
        ref={typeAheadRef}
        autoFocus
        placeholder={intl.formatMessage(filterPlaceholder)}
        value={typeAheadValue}
        shouldValidate={false}
        optionsPosition={optionsPosition}
        onChange={(value) => handleTypeAheadChange(value)}
        onFocus={onFocus}
      />
    );
  };

  useEffect(() => {
    if (expanded && typeAheadRef.current) {
      typeAheadRef.current.focus();
    }
  }, [expanded, typeAheadRef.current, selected]);

  const closeDropdown = () => {
    setExpanded(false);
    setTypeAheadValue('');
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!expanded) return;

      let newIndex = hoveredOption;
      switch (event.keyCode) {
        case KEYS.ENTER:
          if (newIndex != null) {
            event.preventDefault();
            onSelect(filteredOptions[hoveredOption]);
          }
          break;
        case KEYS.UP_ARROW:
          event.preventDefault();
          newIndex =
            hoveredOption === null
              ? filteredOptions.length - 1
              : (filteredOptions.length + hoveredOption - 1) %
                filteredOptions.length;
          break;
        case KEYS.DOWN_ARROW:
          event.preventDefault();
          newIndex =
            hoveredOption === null
              ? 0
              : (hoveredOption + 1) % filteredOptions.length;
          break;
        default:
          return;
      }
      setHoveredOption(newIndex);
    };

    if (expanded) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [expanded, hoveredOption, filteredOptions, onSelect]);

  return (
    <ReactPageClick notify={() => closeDropdown()}>
      <styled.InputContainer className={className}>
        <styled.Input onClick={handleExpandClick} ref={selectRef}>
          {isEmpty(selected) && (
            <styled.Placeholder>
              <FormattedMessage {...(placeholder || i18n.defaultPlaceholder)} />
            </styled.Placeholder>
          )}
          <styled.TagsList>
            {selectedOptions.map((option) => (
              <styled.Tag key={option.slug} size="normal">
                {option.label}
                <styled.UnselectIcon onClick={() => onUnselect(option.slug)} />
              </styled.Tag>
            ))}
            {bullhornOnlyOptions.map((option) => (
              <styled.BullhornTag key={option} size="normal">
                <Tooltip
                  size="23rem"
                  fixed
                  content={<FormattedMessage {...i18n.bullhornOptionTooltip} />}
                >
                  <Icon icon="Bullhorn" color="bhOrange" />
                </Tooltip>
                {option}
                <styled.UnselectIcon onClick={() => onUnselect(option)} />
              </styled.BullhornTag>
            ))}
            {archivedOptions.map((option) => (
              <styled.UnavailableTag key={option} size="normal">
                <Tooltip
                  size="23rem"
                  fixed
                  content={<FormattedMessage {...i18n.archivedOptionTooltip} />}
                >
                  <Icon
                    icon="WarningCircle"
                    color="red"
                    weight="bold"
                    size="large"
                  />
                </Tooltip>
                {option}
                <styled.UnselectArchivedIcon
                  onClick={() => onUnselect(option)}
                />
              </styled.UnavailableTag>
            ))}
          </styled.TagsList>
          <styled.IconsWrapper>
            {!isEmpty(selected) && (
              <>
                <styled.ClearIcon onClick={onClear} />
                <styled.IconSpacer />
              </>
            )}
            <styled.DropdownIcon
              expanded={expanded}
              onClick={() => setExpanded(!expanded)}
            />
          </styled.IconsWrapper>
        </styled.Input>
        <styled.OptionsWrapper
          expanded={expanded}
          optionsPosition={optionsPosition}
          height={optionsSumHeight}
        >
          {expanded &&
            optionsPosition === 'bottom' &&
            typeAheadEnabled() &&
            renderTypeAhead()}
          <styled.Options ref={optionsRef} maxOptionsHeight={maxOptionsHeight}>
            {filteredOptions.map((option, i) => {
              const iconColor =
                hoveredOption === i ? Colors.white : Colors.tealDark;
              return (
                <styled.Option
                  key={option.slug}
                  selected={includes(selected, option.slug)}
                  disabled={includes(disabledOptions, option.slug)}
                  onClick={() =>
                    includes(disabledOptions, option.slug)
                      ? noop
                      : onSelect(option)
                  }
                  hovered={hoveredOption === i}
                >
                  {option.label}
                  {includes(selected, option.slug) && (
                    <Icon icon="Check" color={iconColor} size="large" />
                  )}
                </styled.Option>
              );
            })}
          </styled.Options>

          {expanded &&
            optionsPosition === 'top' &&
            typeAheadEnabled() &&
            renderTypeAhead()}
        </styled.OptionsWrapper>
      </styled.InputContainer>
    </ReactPageClick>
  );
};

TagsDropdown.defaultProps = {
  optionsHeight: 4,
  maxOptionsHeight: 24,
  bullhornOptions: [],
};

TagsDropdown.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ slug: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  bullhornOptions: PropTypes.arrayOf(PropTypes.string),
  optionsHeight: PropTypes.number,
  selected: PropTypes.arrayOf(PropTypes.string.isRequired),
  disabledOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.object,
  maxOptionsHeight: PropTypes.number,
};
