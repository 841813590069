import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { map, isString, isNull } from 'lodash';
import { formatDate } from 'components/utils/date';

import i18n from '../utils/i18n';
import * as styled from '../styles/section';
import * as styledUI from '../styles/performance';

export class Performance extends Component {
  renderHeader(columns) {
    return map(columns, (col) => (
      <styledUI.Th key={`th-${col.key}`}>
        <FormattedMessage {...col.header} />
      </styledUI.Th>
    ));
  }

  renderRow(columns) {
    return map(columns, (col) => (
      <styledUI.Td key={`td-${col.key}`}>{this.renderValue(col)}</styledUI.Td>
    ));
  }

  renderValue(col) {
    const { performance } = this.props;
    let value = performance[col.key];

    if (col.type === 'dt' && isString(value)) {
      value = formatDate({ date: value });
    }
    return isNull(value) ? <FormattedMessage {...i18n.na} /> : value;
  }

  /* eslint-disable react/no-array-index-key */
  render() {
    const { company, definition } = this.props;

    return (
      <styled.Card bordered isSimple>
        <styled.Section
          title={
            <FormattedMessage
              {...definition.header}
              values={{ company: company.name }}
            />
          }
          data-manual="profile.performance"
        >
          {definition.rows.map((row, i) => (
            <styledUI.TableWrapper key={`table-${i}`}>
              <styledUI.Header>
                <FormattedMessage
                  {...row.header}
                  values={{ company: company.name }}
                />
              </styledUI.Header>
              <styledUI.Table>
                <thead>
                  <tr>{this.renderHeader(row.columns)}</tr>
                </thead>
                <tbody>
                  <tr>{this.renderRow(row.columns)}</tr>
                </tbody>
              </styledUI.Table>
            </styledUI.TableWrapper>
          ))}
        </styled.Section>
      </styled.Card>
    );
  }
}

Performance.propTypes = {
  company: PropTypes.object,
  definition: PropTypes.object,
  performance: PropTypes.object,
};
