import { trim } from 'lodash';

import { sanitize } from 'utils/dompurify';

import * as styled from './styles';

type LongTextProps = {
  className?: string;
  text: string;
};

/* eslint react/no-danger: 0 */
export const LongText = ({ className, text }: LongTextProps) => (
  <styled.LongText
    className={className}
    dangerouslySetInnerHTML={{
      __html: sanitize(trim(text)),
    }}
  />
);
