import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Button as BaseButton } from 'components/elements/button';
import {
  Header as HeaderDesktop,
  Paragraph as ParagraphDesktop,
} from '../desktop/styles';

export const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
  width: 100%;

  padding: 5rem 2.7rem;
  border-radius: 0;

  background-color: ${Colors.tealLightest};
  font-size: 1.6rem;
  color: ${Colors.text};
`;

export const Header = styled(HeaderDesktop)`
  font-size: 2.6rem;
  line-height: 3rem;
  text-align: center;
`;

export const Paragraph = styled(ParagraphDesktop)`
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: center;
`;

export const Image = styled.img`
  max-width: 25.6rem;
  margin-bottom: 2.4rem;
`;

export const Button = styled(BaseButton)`
  background-color: ${Colors.tealDark};
  margin-top: 2rem;
  bottom: 4.8rem;
  height: 5.6rem;
  position: fixed;
  width: calc(100% - 5.4rem);
  font-size: 1.6rem;
  line-height: 1.6rem;
`;
