import qs from 'qs';

import { httpGet, beacon } from 'utils';
import { buildStringifiedQuery, buildPaginated } from 'utils/apiHelpers';

import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_ERROR,
  FETCH_COUNTERS_REQUEST,
  FETCH_COUNTERS_SUCCESS,
  FETCH_COUNTERS_ERROR,
} from './constants';

export function connectToChannel(socket) {
  return () =>
    new Promise((resolve) => {
      const channel = socket.channel('analytics');

      channel.join().receive('ok', () => {
        resolve(channel);
      });
    });
}

export function leaveChannel(channel) {
  return () => {
    channel.leave();
  };
}

export function trackEvent(channel, event, async) {
  return () =>
    new Promise((resolve, reject) => {
      const authenticated = !!localStorage.getItem('phoenixAuthToken');
      const connected = channel && channel.state === 'joined';

      if (!authenticated) {
        resolve(null);
      } else if (async || !connected) {
        beacon('/analytics', { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      } else if (connected) {
        channel
          .push('track', { event }, 6000)
          .receive('ok', (response) => resolve(response))
          .receive('error', (error) => reject(error));
      } else {
        resolve(null);
      }
    });
}

export function fetchEvents(params, opts = {}) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: FETCH_EVENTS_REQUEST });

      const { other, schedule } = opts;
      const query = buildStringifiedQuery(params);
      const queryOther = other ? qs.stringify(other) : '';

      httpGet(`/analytics${schedule ? '/schedule' : ''}?${queryOther}&${query}`)
        .then((response) => {
          dispatch({
            type: FETCH_EVENTS_SUCCESS,
            payload: response,
          });

          resolve({
            paginated: buildPaginated(response.paginate),
            data: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: FETCH_EVENTS_ERROR, error });

          reject(error);
        });
    });
}

export function fetchCounters(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: FETCH_COUNTERS_REQUEST });

      httpGet(`/analytics/counters?${qs.stringify(params)}`)
        .then((response) => {
          dispatch({
            type: FETCH_COUNTERS_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: FETCH_COUNTERS_ERROR, error });

          reject(error);
        });
    });
}
