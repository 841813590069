import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Table, TableProvider } from 'components/lists/Table';

import * as styled from '../styles';
import * as styledUI from '../styles/section';

import i18n from '../utils/i18n';
import { usersColumns } from '../utils/columns';

function UsersTabComponent(props) {
  const { intl, onFetchUsers } = props;

  return (
    <styled.Section ratio="4/5">
      <styledUI.Card right bordered isSimple>
        <styledUI.TableSection
          title={<FormattedMessage {...i18n.titleUsers} />}
        >
          <TableProvider onChange={onFetchUsers}>
            {(state) => {
              const {
                isFetching,
                computedData,
                pageChange,
                pageSizeChange,
                filteredChange,
                sortedChange,
                expandedChange,
                filtered,
                sorted,
                paginated,
              } = state;

              return (
                <Table
                  loading={isFetching}
                  data={computedData}
                  columns={usersColumns}
                  filtered={filtered}
                  sorted={sorted}
                  paginated={paginated}
                  onPageChange={pageChange}
                  onPageSizeChange={pageSizeChange}
                  onFilteredChange={filteredChange}
                  onSortedChange={sortedChange}
                  onExpandedChange={expandedChange}
                  getTdProps={(_props, _, column) => ({
                    intl,
                    withTooltip: column.withTooltip,
                  })}
                  columnProps={{
                    getHeaderProps: () => ({}),
                  }}
                  totalCountLabel={i18n.usersCount}
                  toolbarFilter={{
                    placeholder: i18n.filterPlaceholder,
                    button: i18n.filterButton,
                  }}
                  actions={[]}
                  filterable={false}
                  resizable={false}
                  scrollable={false}
                  stickyPagination={false}
                />
              );
            }}
          </TableProvider>
        </styledUI.TableSection>
      </styledUI.Card>
    </styled.Section>
  );
}

UsersTabComponent.propTypes = {
  intl: PropTypes.object,
  onFetchUsers: PropTypes.func,
};

export const UsersTab = injectIntl(UsersTabComponent);
