import { fromJS } from 'immutable';

import {
  INDUSTRIES_REQUEST,
  INDUSTRIES_SUCCESS,
  INDUSTRIES_ERROR,
} from './constants';

const initialState = fromJS({
  errors: null,
  loading: null,
  industries: [],
});

export function industriesReducer(state = initialState, action) {
  switch (action.type) {
    case INDUSTRIES_REQUEST:
      return state.set('loading', true);
    case INDUSTRIES_SUCCESS:
      return state.set('loading', false).set('industries', action.industries);
    case INDUSTRIES_ERROR:
      return state.set('loading', false).set('errors', action.errors);
    default:
      return state;
  }
}
