import styled, { css } from 'styled-components';

import { Colors, bottomShadow } from 'components/utils/styles/ui';
import { Icon } from 'components/elements/icon';
import { Tag as TagBase } from 'components/visual/tags/styles';
import { OptionsTypeahead as OptionsTypeAheadBase } from 'components/elements/styles/dropdown';

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border: solid 1px ${Colors.inputOutline};
  background: ${Colors.white};
  border-radius: 0.3rem;
  width: 100%;
  min-height: 4rem;
`;

export const Placeholder = styled.div`
  padding: 0.8rem 1.2rem;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownIcon = styled(Icon).attrs(({ expanded }) => ({
  icon: expanded ? 'CaretUp' : 'CaretDown',
  size: 'large',
  color: 'textLighter',
}))`
  margin: 0 1.3rem;
`;

export const IconSpacer = styled.div`
  width: 0;
  height: calc(100% - 1rem);
  margin: 0.5rem 0;
  border-left: 1px solid ${Colors.inputOutline};
`;

export const ClearIcon = styled(Icon).attrs({
  icon: 'X',
  size: 'large',
  color: 'textLighter',
})`
  margin: 0 1.3rem;
  cursor: pointer;
`;

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled(TagBase)`
  margin: 0.5rem;
  background-color: ${Colors.tealLighter};
  border-color: ${Colors.outline};
`;

export const UnavailableTag = styled(Tag)`
  background-color: ${Colors.redLighter};
  border-color: ${Colors.red};

  & > * {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const BullhornTag = styled(UnavailableTag)`
  background-color: ${Colors.redLighter};
  border-color: ${Colors.bhOrange};
`;

const UnselectIconBase = styled(Icon).attrs({ icon: 'X' })`
  position: relative;
  padding-left: 0.3rem;
  cursor: pointer;
`;

export const UnselectIcon = styled(UnselectIconBase).attrs({
  color: 'tealDark',
})``;

export const UnselectArchivedIcon = styled(UnselectIconBase).attrs({
  color: 'red',
})``;

const optionsMixin = css`
  ${({ optionsPosition }) =>
    optionsPosition === 'top' ? `bottom: 100%;` : `top: 100%;`}
`;

export const OptionsWrapper = styled.ul`
  position: absolute;
  overflow: hidden;
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  padding: 0;
  margin: 0;
  left: -1px;
  right: -1px;
  border: solid 1px ${Colors.inputOutline};
  border-radius: 0.3rem;
  background: ${Colors.white};
  z-index: 1010;
  box-shadow: ${bottomShadow};
  ${optionsMixin};
`;

export const OptionsTypeAhead = styled(OptionsTypeAheadBase)``;

export const Option = styled.li`
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 1.4rem;
  font-weight: 500;
  justify-content: space-between;
  cursor: ${({ selected, disabled }) =>
    selected || disabled ? 'default' : 'pointer'};
  color: ${({ selected, disabled, hovered }) => {
    if (hovered) {
      return Colors.white;
    }
    if (disabled) {
      return Colors.textLighter;
    }
    if (selected) {
      return Colors.tealDark;
    }
    return Colors.text;
  }};

  background-color: ${({ hovered }) => (hovered ? Colors.teal : Colors.white)};

  &:hover {
    background-color: ${({ hovered }) =>
      hovered ? Colors.tealDark : Colors.tealLighter};
  }
`;

export const Options = styled.div`
  overflow: auto;
  max-height: ${({ maxOptionsHeight }) => `${maxOptionsHeight}rem`};
`;
