import * as Icons from 'phosphor-react';

const IconCategory = {
  ARROWS: 'arrows',
  BRAND: 'brands',
  COMMERCE: 'commerce',
  COMMUNICATION: 'communications',
  DESIGN: 'design',
  DEVELOPMENT: 'technology & development',
  OFFICE: 'office',
  EDITOR: 'editor',
  FINANCE: 'finances',
  GAMES: 'games',
  HEALTH: 'health & wellness',
  MAP: 'maps & travel',
  MEDIA: 'media',
  NATURE: 'nature',
  OBJECTS: 'objects',
  PEOPLE: 'people',
  SYSTEM: 'system',
  WEATHER: 'weather',
};

export const icons = [
  {
    name: 'activity',
    categories: [IconCategory.HEALTH],
    tags: [
      'heartbeat',
      'medical',
      'ecg',
      'ekg',
      'vitals',
      'monitor',
      'medicine',
    ],
    Icon: Icons.Activity,
  },
  {
    name: 'address-book',
    categories: [IconCategory.COMMUNICATION],
    tags: ['contacts', 'roledex'],
    Icon: Icons.AddressBook,
  },
  {
    name: 'airplane',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'airports',
      'flights',
      'flying',
      'planes',
      'transit',
      'transportation',
      'traveling',
    ],
    Icon: Icons.Airplane,
  },
  {
    name: 'airplane-in-flight',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'airports',
      'flights',
      'flying',
      'planes',
      'transit',
      'transportation',
      'traveling',
      'arrival',
    ],
    Icon: Icons.AirplaneInFlight,
  },
  {
    name: 'airplane-landing',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'airports',
      'flights',
      'flying',
      'planes',
      'transit',
      'transportation',
      'traveling',
      'arrival',
    ],
    Icon: Icons.AirplaneLanding,
  },
  {
    name: 'airplane-takeoff',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'airports',
      'flights',
      'flying',
      'planes',
      'transit',
      'transportation',
      'traveling',
      'departure',
    ],
    Icon: Icons.AirplaneTakeoff,
  },
  {
    name: 'airplane-tilt',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'airports',
      'flights',
      'flying',
      'planes',
      'transit',
      'transportation',
      'traveling',
      'departure',
    ],
    Icon: Icons.AirplaneTilt,
  },
  {
    name: 'airplay',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['*updated*', 'apple', 'screencasting', 'television', 'tv'],
    Icon: Icons.Airplay,
  },
  {
    name: 'alarm',
    categories: [IconCategory.SYSTEM],
    tags: ['times', 'timer', 'clock', 'schedule', 'events', 'watch'],
    Icon: Icons.Alarm,
  },
  {
    name: 'alien',
    categories: [IconCategory.GAMES],
    tags: [
      '*new*',
      'ufo',
      'space',
      'flying saucer',
      'extra terrestrial',
      'sci-fi',
    ],
    Icon: Icons.Alien,
  },
  {
    name: 'align-top',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['alignment', 'arrangement', 'layout', 'flush top'],
    Icon: Icons.AlignTop,
  },
  {
    name: 'align-bottom',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['alignment', 'arrangement', 'layout', 'flush bottom'],
    Icon: Icons.AlignBottom,
  },
  {
    name: 'align-left',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['alignment', 'arrangement', 'layout', 'flush left'],
    Icon: Icons.AlignLeft,
  },
  {
    name: 'align-right',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['alignment', 'arrangement', 'layout', 'flush right'],
    Icon: Icons.AlignRight,
  },
  {
    name: 'align-center-horizontal',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['alignment', 'arrangement', 'layout', 'centered', 'middle'],
    Icon: Icons.AlignCenterHorizontal,
  },
  {
    name: 'align-center-vertical',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['alignment', 'arrangement', 'layout', 'centered', 'middle'],
    Icon: Icons.AlignCenterVertical,
  },
  {
    name: 'align-bottom-simple',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'alignment', 'arrangement', 'layout', 'flush bottom'],
    Icon: Icons.AlignBottomSimple,
  },
  {
    name: 'align-center-horizontal-simple',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'alignment', 'arrangement', 'layout', 'centered', 'middle'],
    Icon: Icons.AlignCenterHorizontalSimple,
  },
  {
    name: 'align-center-vertical-simple',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'alignment', 'arrangement', 'layout', 'centered', 'middle'],
    Icon: Icons.AlignCenterVerticalSimple,
  },
  {
    name: 'align-left-simple',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'alignment', 'arrangement', 'layout', 'flush left'],
    Icon: Icons.AlignLeftSimple,
  },
  {
    name: 'align-right-simple',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'alignment', 'arrangement', 'layout', 'flush right'],
    Icon: Icons.AlignRightSimple,
  },
  {
    name: 'align-top-simple',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'alignment', 'arrangement', 'layout', 'flush top'],
    Icon: Icons.AlignTopSimple,
  },
  {
    name: 'anchor',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MAP,
      IconCategory.OBJECTS,
    ],
    tags: ['nautical', 'boats', 'ships', 'hope', 'safety', 'insurance'],
    Icon: Icons.Anchor,
  },
  {
    name: 'anchor-simple',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MAP,
      IconCategory.OBJECTS,
    ],
    tags: ['nautical', 'boats', 'ships', 'hope', 'safety', 'insurance'],
    Icon: Icons.AnchorSimple,
  },
  {
    name: 'android-logo',
    categories: [
      IconCategory.BRAND,
      IconCategory.DEVELOPMENT,
      IconCategory.SYSTEM,
    ],
    tags: ['logos', 'google', 'mobile', 'phone', 'cellular', 'cellphone'],
    Icon: Icons.AndroidLogo,
  },
  {
    name: 'angular-logo',
    categories: [IconCategory.BRAND, IconCategory.DEVELOPMENT],
    tags: ['*new*', 'framework', 'javascript', 'google', 'web'],
    Icon: Icons.AngularLogo,
  },
  {
    name: 'aperture',
    categories: [IconCategory.DESIGN, IconCategory.MEDIA],
    tags: ['photography', 'cameras', 'pictures', 'lens'],
    Icon: Icons.Aperture,
  },
  {
    name: 'app-window',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['windows', 'software', 'programs', 'applications'],
    Icon: Icons.AppWindow,
  },
  {
    name: 'apple-logo',
    categories: [IconCategory.BRAND],
    tags: ['macintosh', 'imac', 'iphone', 'ipad', 'macos', 'ios'],
    Icon: Icons.AppleLogo,
  },
  {
    name: 'app-store-logo',
    categories: [IconCategory.BRAND],
    tags: ['*new*', 'macintosh', 'imac', 'iphone', 'ipad', 'macos', 'ios'],
    Icon: Icons.AppStoreLogo,
  },
  {
    name: 'apple-podcasts-logo',
    categories: [IconCategory.BRAND, IconCategory.MEDIA],
    tags: ['*new*', 'macintosh', 'imac', 'iphone', 'ipad', 'macos', 'ios'],
    Icon: Icons.ApplePodcastsLogo,
  },
  {
    name: 'archive',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: [
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'downloaded',
      'downloading',
    ],
    Icon: Icons.Archive,
  },
  {
    name: 'archive-box',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: [
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'downloaded',
      'downloading',
    ],
    Icon: Icons.ArchiveBox,
  },
  {
    name: 'archive-tray',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: [
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'downloaded',
      'downloading',
    ],
    Icon: Icons.ArchiveTray,
  },
  {
    name: 'armchair',
    categories: [IconCategory.OBJECTS],
    tags: ['*updated*', 'seat', 'furniture'],
    Icon: Icons.Armchair,
  },
  {
    name: 'arrow-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowUp,
  },
  {
    name: 'arrow-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowDown,
  },
  {
    name: 'arrow-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowLeft,
  },
  {
    name: 'arrow-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowRight,
  },
  {
    name: 'arrow-up-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowUpLeft,
  },
  {
    name: 'arrow-up-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowUpRight,
  },
  {
    name: 'arrow-down-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowDownLeft,
  },
  {
    name: 'arrow-down-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowDownRight,
  },
  {
    name: 'arrow-circle-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowCircleUp,
  },
  {
    name: 'arrow-circle-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowCircleDown,
  },
  {
    name: 'arrow-circle-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowCircleLeft,
  },
  {
    name: 'arrow-circle-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowCircleRight,
  },
  {
    name: 'arrow-circle-up-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowCircleUpLeft,
  },
  {
    name: 'arrow-circle-up-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowCircleUpRight,
  },
  {
    name: 'arrow-circle-down-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowCircleDownLeft,
  },
  {
    name: 'arrow-circle-down-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowCircleDownRight,
  },
  {
    name: 'arrow-square-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareUp,
  },
  {
    name: 'arrow-square-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareDown,
  },
  {
    name: 'arrow-square-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareLeft,
  },
  {
    name: 'arrow-square-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareRight,
  },
  {
    name: 'arrow-square-up-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareUpLeft,
  },
  {
    name: 'arrow-square-up-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareUpRight,
  },
  {
    name: 'arrow-square-down-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareDownLeft,
  },
  {
    name: 'arrow-square-down-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareDownRight,
  },
  {
    name: 'arrow-square-in',
    categories: [IconCategory.ARROWS],
    tags: ['import', 'directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowSquareIn,
  },
  {
    name: 'arrow-square-out',
    categories: [IconCategory.ARROWS],
    tags: [
      'export',
      'external',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
    ],
    Icon: Icons.ArrowSquareOut,
  },
  {
    name: 'arrow-arc-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowArcLeft,
  },
  {
    name: 'arrow-arc-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowArcRight,
  },
  {
    name: 'arrow-bend-left-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendLeftUp,
  },
  {
    name: 'arrow-bend-right-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendRightUp,
  },
  {
    name: 'arrow-bend-left-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendLeftDown,
  },
  {
    name: 'arrow-bend-right-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendRightDown,
  },
  {
    name: 'arrow-bend-up-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendUpLeft,
  },
  {
    name: 'arrow-bend-down-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendDownLeft,
  },
  {
    name: 'arrow-bend-up-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendUpRight,
  },
  {
    name: 'arrow-bend-down-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendDownRight,
  },
  {
    name: 'arrow-bend-double-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendDoubleUpLeft,
  },
  {
    name: 'arrow-bend-double-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowBendDoubleUpRight,
  },
  {
    name: 'arrow-clockwise',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'redo',
      'refreshing',
      'rotate',
      'spin',
    ],
    Icon: Icons.ArrowClockwise,
  },
  {
    name: 'arrow-counter-clockwise',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'undo',
      'refreshing',
      'rotate',
      'spin',
    ],
    Icon: Icons.ArrowCounterClockwise,
  },
  {
    name: 'arrow-elbow-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowLeft,
  },
  {
    name: 'arrow-elbow-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowRight,
  },
  {
    name: 'arrow-elbow-left-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowLeftUp,
  },
  {
    name: 'arrow-elbow-right-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowRightUp,
  },
  {
    name: 'arrow-elbow-left-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowLeftDown,
  },
  {
    name: 'arrow-elbow-right-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowRightDown,
  },
  {
    name: 'arrow-elbow-up-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowUpLeft,
  },
  {
    name: 'arrow-elbow-down-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowDownLeft,
  },
  {
    name: 'arrow-elbow-up-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowUpRight,
  },
  {
    name: 'arrow-elbow-down-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowElbowDownRight,
  },
  {
    name: 'arrow-fat-up',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'shift',
      'outlined',
    ],
    Icon: Icons.ArrowFatUp,
  },
  {
    name: 'arrow-fat-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatDown,
  },
  {
    name: 'arrow-fat-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatLeft,
  },
  {
    name: 'arrow-fat-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatRight,
  },
  {
    name: 'arrow-fat-line-up',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'caps lock',
      'outlined',
    ],
    Icon: Icons.ArrowFatLineUp,
  },
  {
    name: 'arrow-fat-line-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatLineDown,
  },
  {
    name: 'arrow-fat-line-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatLineLeft,
  },
  {
    name: 'arrow-fat-line-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatLineRight,
  },
  {
    name: 'arrow-fat-lines-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatLinesUp,
  },
  {
    name: 'arrow-fat-lines-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatLinesDown,
  },
  {
    name: 'arrow-fat-lines-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatLinesLeft,
  },
  {
    name: 'arrow-fat-lines-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'outlined'],
    Icon: Icons.ArrowFatLinesRight,
  },
  {
    name: 'arrow-line-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'top'],
    Icon: Icons.ArrowLineUp,
  },
  {
    name: 'arrow-line-down',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'bottom'],
    Icon: Icons.ArrowLineDown,
  },
  {
    name: 'arrow-line-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowLineLeft,
  },
  {
    name: 'arrow-line-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowLineRight,
  },
  {
    name: 'arrow-line-up-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowLineUpLeft,
  },
  {
    name: 'arrow-line-up-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowLineUpRight,
  },
  {
    name: 'arrow-line-down-left',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowLineDownLeft,
  },
  {
    name: 'arrow-line-down-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowLineDownRight,
  },

  {
    name: 'arrow-u-left-up',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'redo',
      'u-turns',
    ],
    Icon: Icons.ArrowULeftUp,
  },
  {
    name: 'arrow-u-right-up',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'redo',
      'u-turns',
    ],
    Icon: Icons.ArrowURightUp,
  },
  {
    name: 'arrow-u-left-down',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'undo',
      'return',
      'u-turns',
    ],
    Icon: Icons.ArrowULeftDown,
  },
  {
    name: 'arrow-u-right-down',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'undo',
      'return',
      'u-turns',
    ],
    Icon: Icons.ArrowURightDown,
  },
  {
    name: 'arrow-u-up-left',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'undo',
      'return',
      'u-turns',
    ],
    Icon: Icons.ArrowUUpLeft,
  },
  {
    name: 'arrow-u-down-left',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'undo',
      'return',
      'u-turns',
    ],
    Icon: Icons.ArrowUDownLeft,
  },
  {
    name: 'arrow-u-up-right',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'redo',
      'u-turns',
    ],
    Icon: Icons.ArrowUUpRight,
  },
  {
    name: 'arrow-u-down-right',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'redo',
      'u-turns',
    ],
    Icon: Icons.ArrowUDownRight,
  },
  {
    name: 'arrows-clockwise',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'redo',
      'refreshing',
      'sync',
      'synchronize',
      'rotate',
      'spin',
    ],
    Icon: Icons.ArrowsClockwise,
  },
  {
    name: 'arrows-counter-clockwise',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'undo',
      'refreshing',
      'rotate',
      'spin',
    ],
    Icon: Icons.ArrowsCounterClockwise,
  },
  {
    name: 'arrows-down-up',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowsDownUp,
  },
  {
    name: 'arrows-left-right',
    categories: [IconCategory.ARROWS],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead'],
    Icon: Icons.ArrowsLeftRight,
  },
  {
    name: 'arrows-horizontal',
    categories: [IconCategory.ARROWS],
    tags: [
      '*new*',
      'directional',
      'pointer',
      'cursor',
      'resize',
      'expand',
      'left',
      'right',
    ],
    Icon: Icons.ArrowsHorizontal,
  },
  {
    name: 'arrows-vertical',
    categories: [IconCategory.ARROWS],
    tags: [
      '*new*',
      'directional',
      'pointer',
      'cursor',
      'resize',
      'expand',
      'up',
      'down',
    ],
    Icon: Icons.ArrowsVertical,
  },
  {
    name: 'arrows-in',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'collapse',
      'minimize',
      'resize',
      'shrink',
    ],
    Icon: Icons.ArrowsIn,
  },
  {
    name: 'arrows-in-cardinal',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'collapse',
      'minimize',
      'resize',
      'shrink',
    ],
    Icon: Icons.ArrowsInCardinal,
  },
  {
    name: 'arrows-in-simple',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'collapse',
      'minimize',
      'resize',
    ],
    Icon: Icons.ArrowsInSimple,
  },
  {
    name: 'arrows-in-line-horizontal',
    categories: [IconCategory.ARROWS, IconCategory.DESIGN, IconCategory.EDITOR],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'close',
      'center',
      'align',
    ],
    Icon: Icons.ArrowsInLineHorizontal,
  },
  {
    name: 'arrows-in-line-vertical',
    categories: [IconCategory.ARROWS, IconCategory.DESIGN, IconCategory.EDITOR],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'close',
      'center',
      'align',
    ],
    Icon: Icons.ArrowsInLineVertical,
  },
  {
    name: 'arrows-out',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'expand',
      'fullscreen',
      'resize',
      'grow',
    ],
    Icon: Icons.ArrowsOut,
  },
  {
    name: 'arrows-out-cardinal',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'expand',
      'fullscreen',
      'resize',
      'pan',
      'move',
      'grow',
    ],
    Icon: Icons.ArrowsOutCardinal,
  },
  {
    name: 'arrows-out-simple',
    categories: [IconCategory.ARROWS],
    tags: [
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'expand',
      'fullscreen',
      'resize',
    ],
    Icon: Icons.ArrowsOutSimple,
  },
  {
    name: 'arrows-out-line-horizontal',
    categories: [IconCategory.ARROWS, IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'open'],
    Icon: Icons.ArrowsOutLineHorizontal,
  },
  {
    name: 'arrows-out-line-vertical',
    categories: [IconCategory.ARROWS, IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['directional', 'pointer', 'pointing', 'arrowhead', 'open'],
    Icon: Icons.ArrowsOutLineVertical,
  },
  {
    name: 'article',
    categories: [IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: [
      'reading',
      'writing',
      'journals',
      'periodicals',
      'text',
      'newspaper',
    ],
    Icon: Icons.Article,
  },
  {
    name: 'article-medium',
    categories: [IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: [
      'reading',
      'writing',
      'journals',
      'periodicals',
      'text',
      'newspaper',
    ],
    Icon: Icons.ArticleMedium,
  },
  {
    name: 'article-ny-times',
    categories: [IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: [
      'reading',
      'writing',
      'journals',
      'periodicals',
      'text',
      'news',
      'newspaper',
      'nyt',
      'new york times',
    ],
    Icon: Icons.ArticleNyTimes,
  },
  // {
  //   name: "artificial-intelligence",
  //   categories: [IconCategory.DEVELOPMENT],
  //   tags: ["ai", "machine learning", "computer", "robot"],
  //   Icon: Icons.ArtificialIntelligence,
  // },
  {
    name: 'asterisk',
    categories: [IconCategory.COMMUNICATION],
    tags: ['star', 'wildcard', 'bullet point', '6', 'emergency'],
    Icon: Icons.Asterisk,
  },
  {
    name: 'asterisk-simple',
    categories: [IconCategory.COMMUNICATION],
    tags: ['*new*', 'star', 'wildcard', 'bullet point', '5', 'emergency'],
    Icon: Icons.AsteriskSimple,
  },
  {
    name: 'at',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      '*updated*',
      '@',
      'address',
      'email',
      'at symbol',
      'commercial at',
      'arobase',
    ],
    Icon: Icons.At,
  },
  {
    name: 'atom',
    categories: [IconCategory.DEVELOPMENT, IconCategory.NATURE],
    tags: [
      'atomic',
      'nucleus',
      'nuclear',
      'reactor',
      'science',
      'physics',
      'electron',
      'automation',
    ],
    Icon: Icons.Atom,
  },
  {
    name: 'baby',
    categories: [IconCategory.PEOPLE, IconCategory.HEALTH],
    tags: ['*updated*', 'infant', 'child', 'children', 'toddler'],
    Icon: Icons.Baby,
  },
  {
    name: 'backpack',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['*new*', 'knapsack', 'camping', 'school', 'bag'],
    Icon: Icons.Backpack,
  },
  {
    name: 'backspace',
    categories: [IconCategory.SYSTEM],
    tags: ['keyboard', 'remove', 'delete'],
    Icon: Icons.Backspace,
  },
  {
    name: 'bag',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['suitcase', 'valise', 'baggage', 'folders', 'portfolio'],
    Icon: Icons.Bag,
  },
  {
    name: 'bag-simple',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['suitcase', 'valise', 'baggage', 'folders', 'portfolio'],
    Icon: Icons.BagSimple,
  },
  {
    name: 'balloon',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['*new*', 'helium', 'birthday', 'party'],
    Icon: Icons.Balloon,
  },
  {
    name: 'bandaids',
    categories: [IconCategory.HEALTH],
    tags: ['bandages', 'medical', 'medicine', 'first aid', 'injury'],
    Icon: Icons.Bandaids,
  },
  {
    name: 'bank',
    categories: [IconCategory.FINANCE],
    tags: [
      'banking',
      'checking',
      'money',
      'savings',
      'deposit',
      'withdraw',
      'places',
      'locations',
    ],
    Icon: Icons.Bank,
  },
  {
    name: 'barbell',
    categories: [IconCategory.HEALTH],
    tags: [
      'gym',
      'weights',
      'dumbbells',
      'strength training',
      'workout',
      'exercises',
      'fitness',
    ],
    Icon: Icons.Barbell,
  },
  {
    name: 'barcode',
    categories: [IconCategory.COMMERCE, IconCategory.SYSTEM],
    tags: ['upc', 'qr', 'products', 'shopping', 'scanner'],
    Icon: Icons.Barcode,
  },
  {
    name: 'barricade',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['*new*', 'construction', 'safety', 'gate'],
    Icon: Icons.Barricade,
  },
  {
    name: 'baseball',
    categories: [IconCategory.GAMES, IconCategory.HEALTH],
    tags: ['*updated*', 'sports', 'mlb'],
    Icon: Icons.Baseball,
  },
  {
    name: 'basketball',
    categories: [IconCategory.GAMES, IconCategory.HEALTH],
    tags: ['*updated*', 'sports', 'nba'],
    Icon: Icons.Basketball,
  },
  {
    name: 'bathtub',
    categories: [IconCategory.OBJECTS],
    tags: ['*new*', 'bath', 'shower', 'bathroom', 'faucet'],
    Icon: Icons.Bathtub,
  },
  {
    name: 'battery-charging',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power'],
    Icon: Icons.BatteryCharging,
  },
  {
    name: 'battery-charging-vertical',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power'],
    Icon: Icons.BatteryChargingVertical,
  },
  {
    name: 'battery-full',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power', 'filled'],
    Icon: Icons.BatteryFull,
  },
  {
    name: 'battery-high',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power'],
    Icon: Icons.BatteryHigh,
  },
  {
    name: 'battery-medium',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power'],
    Icon: Icons.BatteryMedium,
  },
  {
    name: 'battery-low',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power'],
    Icon: Icons.BatteryLow,
  },
  {
    name: 'battery-empty',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power', 'dead'],
    Icon: Icons.BatteryEmpty,
  },
  {
    name: 'battery-plus',
    categories: [IconCategory.SYSTEM],
    tags: ['*new*', 'charged', 'charger', 'charging', 'power'],
    Icon: Icons.BatteryPlus,
  },
  {
    name: 'battery-warning',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power', 'empty', 'critical'],
    Icon: Icons.BatteryWarning,
  },
  {
    name: 'battery-warning-vertical',
    categories: [IconCategory.SYSTEM],
    tags: ['charged', 'charger', 'charging', 'power', 'empty', 'critical'],
    Icon: Icons.BatteryWarningVertical,
  },
  {
    name: 'bed',
    categories: [IconCategory.HEALTH, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'hotels',
      'accommodations',
      'sleeping',
      'places',
      'locations',
      'medical',
      'hospital',
    ],
    Icon: Icons.Bed,
  },
  {
    name: 'beer-bottle',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      '*new*',
      'drinks',
      'beverages',
      'places',
      'locations',
      'bars',
      'restaurants',
      'food',
      'dining',
    ],
    Icon: Icons.BeerBottle,
  },
  {
    name: 'behance-logo',
    categories: [IconCategory.BRAND, IconCategory.DESIGN],
    tags: ['*new*', 'logos', 'illustration', 'ui', 'interface'],
    Icon: Icons.BehanceLogo,
  },
  {
    name: 'bell',
    categories: [IconCategory.SYSTEM],
    tags: [
      'alarm',
      'notifications',
      'times',
      'timer',
      'clock',
      'schedule',
      'events',
      'ringer',
      'calls',
    ],
    Icon: Icons.Bell,
  },
  {
    name: 'bell-ringing',
    categories: [IconCategory.SYSTEM],
    tags: [
      'alarm',
      'notifications',
      'times',
      'timer',
      'clock',
      'schedule',
      'events',
      'ringer',
      'calls',
    ],
    Icon: Icons.BellRinging,
  },
  {
    name: 'bell-slash',
    categories: [IconCategory.SYSTEM],
    tags: [
      'alarm',
      'notifications',
      'times',
      'timer',
      'clock',
      'schedule',
      'events',
      'silent',
      'silenced',
      'ringer',
      'calls',
      'disabled',
    ],
    Icon: Icons.BellSlash,
  },
  {
    name: 'bell-z',
    categories: [IconCategory.SYSTEM],
    tags: [
      'alarm',
      'notifications',
      'times',
      'timer',
      'clock',
      'schedule',
      'events',
      'ringer',
      'snooze',
    ],
    Icon: Icons.BellZ,
  },
  {
    name: 'bell-simple',
    categories: [IconCategory.SYSTEM],
    tags: [
      'alarm',
      'notifications',
      'times',
      'timer',
      'clock',
      'schedule',
      'events',
      'ringer',
      'calls',
    ],
    Icon: Icons.BellSimple,
  },
  {
    name: 'bell-simple-ringing',
    categories: [IconCategory.SYSTEM],
    tags: [
      'alarm',
      'notifications',
      'times',
      'timer',
      'clock',
      'schedule',
      'events',
      'ringer',
      'calls',
    ],
    Icon: Icons.BellSimpleRinging,
  },
  {
    name: 'bell-simple-slash',
    categories: [IconCategory.SYSTEM],
    tags: [
      'alarm',
      'notifications',
      'times',
      'timer',
      'clock',
      'schedule',
      'events',
      'ringer',
      'silent',
      'silenced',
      'disabled',
    ],
    Icon: Icons.BellSimpleSlash,
  },
  {
    name: 'bell-simple-z',
    categories: [IconCategory.SYSTEM],
    tags: [
      'alarm',
      'notifications',
      'times',
      'timer',
      'clock',
      'schedule',
      'events',
      'ringer',
      'snooze',
    ],
    Icon: Icons.BellSimpleZ,
  },
  {
    name: 'bezier-curve',
    categories: [IconCategory.DESIGN],
    tags: ['*new*', 'shapes', 'drawing', 'path', 'pen'],
    Icon: Icons.BezierCurve,
  },
  {
    name: 'bicycle',
    categories: [IconCategory.HEALTH, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'bikers',
      'bicycling',
      'cyclists',
      'transit',
      'transportation',
      'commuter',
      'exercises',
      'fitness',
    ],
    Icon: Icons.Bicycle,
  },
  {
    name: 'binoculars',
    categories: [IconCategory.NATURE, IconCategory.OBJECTS, IconCategory.MAP],
    tags: ['*new*', 'telescope', 'glasses', 'search', 'find', 'explore'],
    Icon: Icons.Binoculars,
  },
  {
    name: 'bird',
    categories: [IconCategory.NATURE],
    tags: ['animals', 'pets'],
    Icon: Icons.Bird,
  },
  {
    name: 'bluetooth',
    categories: [IconCategory.SYSTEM],
    tags: ['wireless', 'connection', 'connected', 'connectivity'],
    Icon: Icons.Bluetooth,
  },
  {
    name: 'bluetooth-connected',
    categories: [IconCategory.SYSTEM],
    tags: ['wireless', 'connection', 'connected', 'connectivity'],
    Icon: Icons.BluetoothConnected,
  },
  {
    name: 'bluetooth-slash',
    categories: [IconCategory.SYSTEM],
    tags: [
      'wireless',
      'connection',
      'connectivity',
      'disconnected',
      'disabled',
    ],
    Icon: Icons.BluetoothSlash,
  },
  {
    name: 'bluetooth-x',
    categories: [IconCategory.SYSTEM],
    tags: ['wireless', 'connection', 'connectivity', 'disconnected', 'errors'],
    Icon: Icons.BluetoothX,
  },
  {
    name: 'boat',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'ferry',
      'ship',
      'cruise',
      'vehicles',
      'public transit',
      'transportation',
      'commuter',
      'traveling',
      'sailing',
      'places',
      'locations',
    ],
    Icon: Icons.Boat,
  },
  {
    name: 'book',
    categories: [IconCategory.OFFICE, IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: ['reading', 'reader', 'novel', 'story', 'library'],
    Icon: Icons.Book,
  },
  {
    name: 'book-bookmark',
    categories: [IconCategory.OFFICE, IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: [
      'reading',
      'reader',
      'novel',
      'story',
      'library',
      'favorites',
      'favorited',
    ],
    Icon: Icons.BookBookmark,
  },
  {
    name: 'book-open',
    categories: [IconCategory.OFFICE, IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: ['reading', 'reader', 'novel', 'story', 'library'],
    Icon: Icons.BookOpen,
  },
  {
    name: 'books',
    categories: [
      IconCategory.OFFICE,
      IconCategory.MAP,
      IconCategory.MEDIA,
      IconCategory.OBJECTS,
    ],
    tags: ['reading', 'reader', 'bookshelf', 'library', 'places', 'locations'],
    Icon: Icons.Books,
  },
  {
    name: 'bookmark',
    categories: [IconCategory.OFFICE, IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: [
      'reading',
      'reader',
      'novel',
      'story',
      'placeholder',
      'favorites',
      'favorited',
      'library',
    ],
    Icon: Icons.Bookmark,
  },
  {
    name: 'bookmark-simple',
    categories: [IconCategory.OFFICE, IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: [
      'reading',
      'reader',
      'novel',
      'story',
      'placeholder',
      'favorites',
      'favorited',
      'library',
    ],
    Icon: Icons.BookmarkSimple,
  },
  {
    name: 'bookmarks',
    categories: [IconCategory.OFFICE, IconCategory.OBJECTS],
    tags: [
      'reading',
      'reader',
      'novel',
      'story',
      'placeholder',
      'favorites',
      'favorited',
      'library',
    ],
    Icon: Icons.Bookmarks,
  },
  {
    name: 'bookmarks-simple',
    categories: [IconCategory.OFFICE, IconCategory.OBJECTS],
    tags: [
      'reading',
      'reader',
      'novel',
      'story',
      'placeholder',
      'favorites',
      'favorited',
      'library',
    ],
    Icon: Icons.BookmarksSimple,
  },
  {
    name: 'bounding-box',
    categories: [IconCategory.DESIGN],
    tags: ['polygon', 'shapes', 'outline', 'corners', 'rectangle'],
    Icon: Icons.BoundingBox,
  },
  {
    name: 'brackets-angle',
    categories: [IconCategory.DEVELOPMENT, IconCategory.EDITOR],
    tags: ['code', 'angle brackets', 'angle braces'],
    Icon: Icons.BracketsAngle,
  },
  {
    name: 'brackets-curly',
    categories: [IconCategory.DEVELOPMENT, IconCategory.EDITOR],
    tags: ['code', 'curly brackets', 'curly braces'],
    Icon: Icons.BracketsCurly,
  },
  {
    name: 'brackets-round',
    categories: [IconCategory.DEVELOPMENT, IconCategory.EDITOR],
    tags: ['code', 'parentheses', 'round brackets', 'round braces'],
    Icon: Icons.BracketsRound,
  },
  {
    name: 'brackets-square',
    categories: [IconCategory.DEVELOPMENT, IconCategory.EDITOR],
    tags: ['code', 'square brackets', 'square braces', 'array'],
    Icon: Icons.BracketsSquare,
  },
  {
    name: 'brain',
    categories: [IconCategory.HEALTH, IconCategory.NATURE],
    tags: ['mind', 'mental'],
    Icon: Icons.Brain,
  },
  {
    name: 'brandy',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'drinks',
      'beverages',
      'whiskey',
      'cocktail',
      'places',
      'locations',
      'bars',
      'restaurants',
      'food',
      'dining',
    ],
    Icon: Icons.Brandy,
  },
  {
    name: 'briefcase',
    categories: [IconCategory.OFFICE, IconCategory.OBJECTS],
    tags: ['suitcase', 'valise', 'baggage', 'folders', 'portfolio'],
    Icon: Icons.Briefcase,
  },
  {
    name: 'briefcase-metal',
    categories: [IconCategory.OFFICE, IconCategory.OBJECTS],
    tags: [
      '*updated*',
      'suitcase',
      'valise',
      'baggage',
      'folders',
      'portfolio',
    ],
    Icon: Icons.BriefcaseMetal,
  },
  {
    name: 'broadcast',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MEDIA,
      IconCategory.SYSTEM,
    ],
    tags: ['radio', 'hotspot', 'wifi', 'emit'],
    Icon: Icons.Broadcast,
  },
  {
    name: 'browser',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: [
      'web browsers',
      'windows',
      'internet',
      'website',
      'webpage',
      'chrome',
      'edge',
      'firefox',
    ],
    Icon: Icons.Browser,
  },
  {
    name: 'browsers',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: [
      'web browsers',
      'windows',
      'internet',
      'website',
      'webpage',
      'chrome',
      'edge',
      'firefox',
    ],
    Icon: Icons.Browsers,
  },
  {
    name: 'buildings',
    categories: [IconCategory.COMMERCE, IconCategory.MAP],
    tags: ['places', 'locations', 'company', 'business'],
    Icon: Icons.Buildings,
  },
  {
    name: 'bug',
    categories: [IconCategory.DEVELOPMENT, IconCategory.NATURE],
    tags: ['debug', 'errors', 'insect', 'ladybug'],
    Icon: Icons.Bug,
  },
  {
    name: 'bug-beetle',
    categories: [IconCategory.DEVELOPMENT, IconCategory.NATURE],
    tags: ['debug', 'errors', 'insect', 'ladybug'],
    Icon: Icons.BugBeetle,
  },
  {
    name: 'bug-droid',
    categories: [IconCategory.DEVELOPMENT, IconCategory.NATURE],
    tags: ['debug', 'errors', 'insect', 'android', 'google'],
    Icon: Icons.BugDroid,
  },
  {
    name: 'bus',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'automobile',
      'public transit',
      'transportation',
      'commuter',
      'traveling',
      'places',
      'locations',
    ],
    Icon: Icons.Bus,
  },
  {
    name: 'butterfly',
    categories: [IconCategory.NATURE],
    tags: ['*new*', 'animals', 'insects', 'moth'],
    Icon: Icons.Butterfly,
  },
  {
    name: 'cactus',
    categories: [IconCategory.NATURE],
    tags: ['*new*', 'plants', 'cacti', 'desert', 'western'],
    Icon: Icons.Cactus,
  },
  {
    name: 'cake',
    categories: [IconCategory.OBJECTS],
    tags: ['dessert', 'birthday', 'celebration', 'event'],
    Icon: Icons.Cake,
  },
  {
    name: 'calculator',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.FINANCE,
      IconCategory.OFFICE,
      IconCategory.OBJECTS,
    ],
    tags: [
      'addition',
      'sum',
      'subtraction',
      'difference',
      'multiply',
      'multiplication',
      'product',
      'divide',
      'division',
      'divisor',
      'dividend',
      'quotient',
      'equals',
      'equality',
      'mathematics',
      'arithmetic',
      '+',
      '-',
      '±',
      '×',
      '÷',
      '=',
    ],
    Icon: Icons.Calculator,
  },
  {
    name: 'calendar',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: ['dates', 'times', 'events', 'schedule', '31'],
    Icon: Icons.Calendar,
  },
  {
    name: 'calendar-blank',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: ['dates', 'times', 'events', 'schedule', 'none'],
    Icon: Icons.CalendarBlank,
  },
  {
    name: 'calendar-check',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: ['dates', 'times', 'events', 'schedule', 'todo', 'checklist'],
    Icon: Icons.CalendarCheck,
  },
  {
    name: 'calendar-plus',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: ['dates', 'times', 'events', 'schedule', 'add'],
    Icon: Icons.CalendarPlus,
  },
  {
    name: 'calendar-x',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: ['dates', 'times', 'events', 'schedule', 'closed', 'cancelled'],
    Icon: Icons.CalendarX,
  },
  {
    name: 'camera',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['photography', 'pictures', 'lens'],
    Icon: Icons.Camera,
  },
  {
    name: 'camera-rotate',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'photography',
      'pictures',
      'orientation',
      'portrait',
      'landscape',
    ],
    Icon: Icons.CameraRotate,
  },
  {
    name: 'camera-slash',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['photography', 'pictures', 'lens', 'disabled'],
    Icon: Icons.CameraSlash,
  },
  {
    name: 'campfire',
    categories: [IconCategory.NATURE],
    tags: ['*new*', 'camping', 'flame', 'bonfire', 'outdoors'],
    Icon: Icons.Campfire,
  },
  {
    name: 'car',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],

    tags: [
      'cars',
      'vehicles',
      'automobile',
      'transit',
      'transportation',
      'traveling',
    ],
    Icon: Icons.Car,
  },
  {
    name: 'car-simple',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'cars',
      'vehicles',
      'automobile',
      'transit',
      'transportation',
      'traveling',
    ],
    Icon: Icons.CarSimple,
  },
  {
    name: 'cards',
    categories: [IconCategory.DESIGN, IconCategory.SYSTEM],
    tags: [
      'card',
      'slides',
      'slideshow',
      'windows',
      'website',
      'webpage',
      'layers',
    ],
    Icon: Icons.Cards,
  },
  {
    name: 'cardholder',
    categories: [
      IconCategory.COMMERCE,
      IconCategory.FINANCE,
      IconCategory.OBJECTS,
    ],
    tags: ['wallet', 'money', 'payment', 'paying', 'purchase'],
    Icon: Icons.Cardholder,
  },
  {
    name: 'caret-up',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretUp,
  },
  {
    name: 'caret-down',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretDown,
  },
  {
    name: 'caret-left',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretLeft,
  },
  {
    name: 'caret-right',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretRight,
  },
  {
    name: 'caret-double-up',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretDoubleUp,
  },
  {
    name: 'caret-double-down',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretDoubleDown,
  },
  {
    name: 'caret-double-left',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretDoubleLeft,
  },
  {
    name: 'caret-double-right',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretDoubleRight,
  },
  {
    name: 'caret-circle-up',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretCircleUp,
  },
  {
    name: 'caret-circle-down',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretCircleDown,
  },

  {
    name: 'caret-circle-left',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretCircleLeft,
  },
  {
    name: 'caret-circle-right',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretCircleRight,
  },
  {
    name: 'caret-circle-double-up',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretCircleDoubleUp,
  },
  {
    name: 'caret-circle-double-down',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretCircleDoubleDown,
  },
  {
    name: 'caret-circle-double-left',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretCircleDoubleLeft,
  },
  {
    name: 'caret-circle-double-right',
    categories: [IconCategory.ARROWS],
    tags: [
      'chevron',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
      'triangle',
    ],
    Icon: Icons.CaretCircleDoubleRight,
  },
  {
    name: 'cat',
    categories: [IconCategory.NATURE],
    tags: ['*updated*', 'pets', 'animals', 'kitty', 'kitten'],
    Icon: Icons.Cat,
  },
  {
    name: 'cell-signal-full',
    categories: [IconCategory.SYSTEM],
    tags: [
      'wireless',
      'cellular',
      'phone',
      'mobile',
      'network',
      'connection',
      'connectivity',
      'reception',
      'service',
    ],
    Icon: Icons.CellSignalFull,
  },
  {
    name: 'cell-signal-high',
    categories: [IconCategory.SYSTEM],
    tags: [
      'wireless',
      'cellular',
      'phone',
      'mobile',
      'network',
      'connection',
      'connectivity',
      'reception',
      'service',
    ],
    Icon: Icons.CellSignalHigh,
  },
  {
    name: 'cell-signal-medium',
    categories: [IconCategory.SYSTEM],
    tags: [
      'wireless',
      'cellular',
      'phone',
      'mobile',
      'network',
      'connection',
      'connectivity',
      'reception',
      'service',
    ],
    Icon: Icons.CellSignalMedium,
  },
  {
    name: 'cell-signal-low',
    categories: [IconCategory.SYSTEM],
    tags: [
      'wireless',
      'cellular',
      'phone',
      'mobile',
      'network',
      'connection',
      'connectivity',
      'reception',
      'service',
    ],
    Icon: Icons.CellSignalLow,
  },
  {
    name: 'cell-signal-none',
    categories: [IconCategory.SYSTEM],
    tags: [
      'wireless',
      'cellular',
      'phone',
      'mobile',
      'network',
      'connection',
      'connectivity',
      'reception',
      'service',
    ],
    Icon: Icons.CellSignalNone,
  },
  {
    name: 'cell-signal-slash',
    categories: [IconCategory.SYSTEM],
    tags: [
      'wireless',
      'cellular',
      'phone',
      'mobile',
      'network',
      'connection',
      'connectivity',
      'disconnected',
      'disabled',
      'reception',
      'service',
    ],
    Icon: Icons.CellSignalSlash,
  },
  {
    name: 'cell-signal-x',
    categories: [IconCategory.SYSTEM],
    tags: [
      '*updated*',
      'wireless',
      'cellular',
      'phone',
      'mobile',
      'network',
      'connection',
      'connectivity',
      'reception',
      'disconnected',
      'errors',
      'service',
    ],
    Icon: Icons.CellSignalX,
  },
  // {
  //   name: "certificate",
  //   categories: [IconCategory.DEVELOPMENT, IconCategory.OFFICE],
  //   tags: [ "diploma", "valid", "authentic"],
  //   Icon: Icons.Certificate,
  // },
  {
    name: 'chalkboard',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'blackboard',
      'whiteboard',
      'classroom',
      'teacher',
      'education',
      'school',
      'college',
      'university',
    ],
    Icon: Icons.Chalkboard,
  },
  {
    name: 'chalkboard-simple',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'blackboard',
      'whiteboard',
      'classroom',
      'teacher',
      'education',
      'school',
      'college',
      'university',
    ],
    Icon: Icons.ChalkboardSimple,
  },
  {
    name: 'chalkboard-teacher',
    categories: [IconCategory.MAP, IconCategory.OBJECTS, IconCategory.PEOPLE],
    tags: [
      'blackboard',
      'whiteboard',
      'classroom',
      'education',
      'school',
      'college',
      'university',
    ],
    Icon: Icons.ChalkboardTeacher,
  },
  {
    name: 'chart-bar',
    categories: [IconCategory.FINANCE],
    tags: [
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'histogram',
      'analyze',
      'analysis',
    ],
    Icon: Icons.ChartBar,
  },
  {
    name: 'chart-bar-horizontal',
    categories: [IconCategory.FINANCE],
    tags: [
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'histogram',
      'analyze',
      'analysis',
    ],
    Icon: Icons.ChartBarHorizontal,
  },
  {
    name: 'chart-line',
    categories: [IconCategory.FINANCE],
    tags: [
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'analyze',
      'analysis',
      'stocks',
    ],
    Icon: Icons.ChartLine,
  },
  {
    name: 'chart-line-up',
    categories: [IconCategory.FINANCE],
    tags: [
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'analyze',
      'analysis',
      'stocks',
    ],
    Icon: Icons.ChartLineUp,
  },
  {
    name: 'chart-pie',
    categories: [IconCategory.FINANCE],
    tags: [
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'circle',
      'analyze',
      'analysis',
    ],
    Icon: Icons.ChartPie,
  },
  {
    name: 'chart-pie-slice',
    categories: [IconCategory.FINANCE],
    tags: [
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'circle',
      'analyze',
      'analysis',
    ],
    Icon: Icons.ChartPieSlice,
  },
  {
    name: 'chat',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'square',
      'bubble',
    ],
    Icon: Icons.Chat,
  },
  {
    name: 'chat-dots',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'square',
      'bubble',
    ],

    Icon: Icons.ChatDots,
  },
  {
    name: 'chat-text',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'square',
      'bubble',
    ],

    Icon: Icons.ChatText,
  },
  {
    name: 'chats',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'square',
      'bubble',
    ],

    Icon: Icons.Chats,
  },
  {
    name: 'chat-centered',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'square',
      'bubble',
    ],
    Icon: Icons.ChatCentered,
  },
  {
    name: 'chat-centered-dots',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'square',
      'bubble',
    ],
    Icon: Icons.ChatCenteredDots,
  },
  {
    name: 'chat-centered-text',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'square',
      'bubble',
    ],
    Icon: Icons.ChatCenteredText,
  },
  {
    name: 'chat-circle',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'round',
      'bubble',
    ],
    Icon: Icons.ChatCircle,
  },
  {
    name: 'chat-circle-dots',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'round',
      'bubble',
    ],
    Icon: Icons.ChatCircleDots,
  },
  {
    name: 'chat-circle-text',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'round',
      'bubble',
    ],

    Icon: Icons.ChatCircleText,
  },
  {
    name: 'chats-circle',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'round',
      'bubble',
    ],
    Icon: Icons.ChatsCircle,
  },
  {
    name: 'chat-teardrop',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'bubble',
    ],
    Icon: Icons.ChatTeardrop,
  },
  {
    name: 'chat-teardrop-dots',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'bubble',
    ],
    Icon: Icons.ChatTeardropDots,
  },
  {
    name: 'chat-teardrop-text',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'bubble',
    ],
    Icon: Icons.ChatTeardropText,
  },
  {
    name: 'chats-teardrop',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'send',
      'sent',
      'messages',
      'messaging',
      'sms',
      'texting',
      'comment',
      'bubble',
    ],
    Icon: Icons.ChatsTeardrop,
  },
  {
    name: 'check',
    categories: [IconCategory.SYSTEM],
    tags: ['todo', 'to-do', 'task', 'list', 'checkbox', 'ok', 'done'],
    Icon: Icons.Check,
  },
  {
    name: 'check-circle',
    categories: [IconCategory.SYSTEM],
    tags: ['todo', 'to-do', 'task', 'list', 'checkbox', 'round', 'ok', 'done'],
    Icon: Icons.CheckCircle,
  },
  {
    name: 'check-square',
    categories: [IconCategory.SYSTEM],
    tags: [
      'todo',
      'to-do',
      'task',
      'list',
      'checkbox',
      'rectangle',
      'ok',
      'done',
    ],
    Icon: Icons.CheckSquare,
  },
  {
    name: 'check-square-offset',
    categories: [IconCategory.SYSTEM],
    tags: [
      'todo',
      'to-do',
      'task',
      'list',
      'checkbox',
      'rectangle',
      'ok',
      'done',
    ],
    Icon: Icons.CheckSquareOffset,
  },
  {
    name: 'checks',
    categories: [IconCategory.SYSTEM],
    tags: ['todo', 'task', 'to-do', 'list', 'checkbox', 'ok', 'done'],
    Icon: Icons.Checks,
  },
  // {
  //   name: "child",
  //   categories: [IconCategory.PEOPLE],
  //   tags: [ "kids", "children", "family"],
  //   Icon: Icons.Child,
  // },
  {
    name: 'circle',
    categories: [IconCategory.DESIGN],
    tags: ['round', 'shapes', 'polygons'],
    Icon: Icons.Circle,
  },
  {
    name: 'circle-dashed',
    categories: [IconCategory.DESIGN],
    tags: ['missing', 'round', 'shapes', 'polygons'],
    Icon: Icons.CircleDashed,
  },
  {
    name: 'circle-half',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*updated*', 'round', 'shapes', 'contrast', 'brightness'],
    Icon: Icons.CircleHalf,
  },
  {
    name: 'circle-half-tilt',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*updated*', 'round', 'shapes', 'contrast', 'brightness'],
    Icon: Icons.CircleHalfTilt,
  },
  {
    name: 'circle-notch',
    categories: [IconCategory.SYSTEM],
    tags: [
      '*new*',
      'round',
      'shapes',
      'loading',
      'loader',
      'spinner',
      'waiting',
      'progress',
    ],
    Icon: Icons.CircleNotch,
  },
  {
    name: 'circle-wavy',
    categories: [IconCategory.DESIGN],
    tags: ['badge', 'verified', 'verification', 'shapes', 'polygons'],
    Icon: Icons.CircleWavy,
  },
  {
    name: 'circle-wavy-check',
    categories: [IconCategory.DESIGN],
    tags: ['badge', 'verified', 'verification', 'shapes', 'polygons'],
    Icon: Icons.CircleWavyCheck,
  },
  {
    name: 'circle-wavy-question',
    categories: [IconCategory.DESIGN],
    tags: ['badge', 'unverified', 'verification', 'shapes', 'polygons'],
    Icon: Icons.CircleWavyQuestion,
  },
  {
    name: 'circle-wavy-warning',
    categories: [IconCategory.DESIGN],
    tags: [
      'badge',
      'unverified',
      'verification',
      'errors',
      'shapes',
      'polygons',
    ],
    Icon: Icons.CircleWavyWarning,
  },
  {
    name: 'circles-three',
    categories: [IconCategory.DESIGN],
    tags: ['round', 'shapes', 'polygons', '3', 'asana'],
    Icon: Icons.CirclesThree,
  },
  {
    name: 'circles-three-plus',
    categories: [IconCategory.DESIGN],
    tags: ['round', 'shapes', 'polygons', '3', '+'],
    Icon: Icons.CirclesThreePlus,
  },
  {
    name: 'circles-four',
    categories: [IconCategory.DESIGN],
    tags: ['round', 'shapes', 'polygons', '4'],
    Icon: Icons.CirclesFour,
  },
  {
    name: 'clipboard',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['copy', 'copied', 'checklist'],
    Icon: Icons.Clipboard,
  },
  {
    name: 'clipboard-text',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['copy', 'copied', 'checklist'],
    Icon: Icons.ClipboardText,
  },
  {
    name: 'clock',
    categories: [IconCategory.SYSTEM],
    tags: ['times', 'timer', 'alarm', 'schedule', 'events', 'watch'],
    Icon: Icons.Clock,
  },
  {
    name: 'clock-afternoon',
    categories: [IconCategory.SYSTEM],
    tags: ['times', 'timer', 'alarm', 'schedule', 'events', 'watch'],
    Icon: Icons.ClockAfternoon,
  },
  {
    name: 'clock-clockwise',
    categories: [IconCategory.SYSTEM],
    tags: [
      'times',
      'timer',
      'alarm',
      'schedule',
      'events',
      'restore',
      'fast forward',
      'update',
    ],
    Icon: Icons.ClockClockwise,
  },
  {
    name: 'clock-counter-clockwise',
    categories: [IconCategory.SYSTEM],
    tags: [
      'times',
      'timer',
      'alarm',
      'schedule',
      'events',
      'backup',
      'rewind',
      'history',
    ],
    Icon: Icons.ClockCounterClockwise,
  },
  {
    name: 'closed-captioning',
    categories: [IconCategory.MEDIA],
    tags: [
      'subtitles',
      'television',
      'tv',
      'transcribed',
      'transcription',
      'accessibility',
      'a11y',
    ],
    Icon: Icons.ClosedCaptioning,
  },
  // {
  //   name: "closed-captioning-slash",
  //   categories: [IconCategory.MEDIA],
  //   tags: [
  //
  //     "subtitles",
  //     "television",
  //     "tv",
  //     "transcribed",
  //     "transcription",
  //     "accessibility",
  //     "a11y",
  //     "disabled",
  //   ],
  //   Icon: Icons.ClosedCaptioningSlash,
  // },
  {
    name: 'cloud',
    categories: [IconCategory.SYSTEM, IconCategory.WEATHER],
    tags: [
      '*updated*',
      'serverless',
      'backup',
      'storage',
      'meteorology',
      'cloudy',
      'overcast',
    ],
    Icon: Icons.Cloud,
  },
  {
    name: 'cloud-arrow-down',
    categories: [IconCategory.SYSTEM],
    tags: ['*updated*', 'serverless', 'backup', 'storage', 'download'],
    Icon: Icons.CloudArrowDown,
  },
  {
    name: 'cloud-arrow-up',
    categories: [IconCategory.SYSTEM],
    tags: ['*updated*', 'serverless', 'backup', 'storage', 'upload'],
    Icon: Icons.CloudArrowUp,
  },
  {
    name: 'cloud-check',
    categories: [IconCategory.SYSTEM],
    tags: [
      '*updated*',
      'serverless',
      'backup',
      'storage',
      'sync',
      'synchronized',
    ],
    Icon: Icons.CloudCheck,
  },
  {
    name: 'cloud-slash',
    categories: [IconCategory.SYSTEM],
    tags: ['serverless', 'backup', 'storage', 'sync', 'disabled'],
    Icon: Icons.CloudSlash,
  },
  {
    name: 'cloud-fog',
    categories: [IconCategory.WEATHER],
    tags: [
      '*updated*',
      'meteorology',
      'cloudy',
      'overcast',
      'foggy',
      'misty',
      'haze',
      'hazy',
    ],
    Icon: Icons.CloudFog,
  },
  {
    name: 'cloud-lightning',
    categories: [IconCategory.WEATHER],
    tags: [
      '*updated*',
      'meteorology',
      'cloudy',
      'overcast',
      'stormy',
      'thunderstorm',
    ],
    Icon: Icons.CloudLightning,
  },
  {
    name: 'cloud-moon',
    categories: [IconCategory.WEATHER],
    tags: ['meteorology', 'cloudy', 'partly cloudy', 'night', 'evening'],
    Icon: Icons.CloudMoon,
  },
  {
    name: 'cloud-rain',
    categories: [IconCategory.WEATHER],
    tags: [
      '*updated*',
      'meteorology',
      'cloudy',
      'rainy',
      'raining',
      'stormy',
      'rainstorm',
    ],
    Icon: Icons.CloudRain,
  },
  {
    name: 'cloud-snow',
    categories: [IconCategory.WEATHER],
    tags: [
      '*updated*',
      'meteorology',
      'cloudy',
      'snowy',
      'snowing',
      'stormy',
      'snowstorm',
    ],
    Icon: Icons.CloudSnow,
  },
  {
    name: 'cloud-sun',
    categories: [IconCategory.WEATHER],
    tags: ['meteorology', 'cloudy', 'partly cloudy', 'partly sunny'],
    Icon: Icons.CloudSun,
  },
  {
    name: 'club',
    categories: [IconCategory.GAMES],
    tags: ['clubs', 'suits', 'cards', 'gambling', 'casino', 'gaming'],
    Icon: Icons.Club,
  },
  {
    name: 'coat-hanger',
    categories: [IconCategory.COMMERCE],
    tags: ['*new*', 'clothing', 'clothes', 'closet'],
    Icon: Icons.CoatHanger,
  },
  {
    name: 'code',
    categories: [IconCategory.DEVELOPMENT, IconCategory.EDITOR],
    tags: ['angle brackets', 'angle braces', 'snippets'],
    Icon: Icons.Code,
  },
  {
    name: 'code-simple',
    categories: [IconCategory.DEVELOPMENT, IconCategory.EDITOR],
    tags: ['angle brackets', 'angle braces', 'snippets'],
    Icon: Icons.CodeSimple,
  },
  {
    name: 'codepen-logo',
    categories: [IconCategory.BRAND, IconCategory.DEVELOPMENT],
    tags: ['*new*', 'ide', 'logos'],
    Icon: Icons.CodepenLogo,
  },
  {
    name: 'codesandbox-logo',
    categories: [IconCategory.BRAND, IconCategory.DEVELOPMENT],
    tags: ['*new*', 'ide', 'logos'],
    Icon: Icons.CodesandboxLogo,
  },
  {
    name: 'coffee',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: [
      'tea',
      'java',
      'beverages',
      'drinks',
      'cafe',
      'cup',
      'mug',
      'espresso',
      'cappuccino',
      'latte',
      'places',
      'locations',
      'bars',
      'restaurants',
      'food',
      'dining',
    ],
    Icon: Icons.Coffee,
  },
  {
    name: 'coin',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: [
      'coins',
      'cents',
      'change',
      'money',
      'currency',
      'payment',
      'paying',
      'purchase',
      'price',
      'sell',
    ],
    Icon: Icons.Coin,
  },
  {
    name: 'coin-vertical',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: [
      '*new*',
      'cents',
      'change',
      'money',
      'currency',
      'payment',
      'paying',
      'purchase',
      'price',
      'sell',
    ],
    Icon: Icons.CoinVertical,
  },
  {
    name: 'coins',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: [
      '*new*',
      'cents',
      'change',
      'money',
      'currency',
      'payment',
      'paying',
      'purchase',
      'price',
      'sell',
    ],
    Icon: Icons.Coins,
  },
  {
    name: 'columns',
    categories: [IconCategory.DESIGN],
    tags: ['2', 'shapes', 'polygons', 'box', 'stack', 'list', 'table', 'cards'],
    Icon: Icons.Columns,
  },
  {
    name: 'command',
    categories: [IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'apple',
      'keyboard',
      'shortcut',
      'modifier',
      'looped square',
      'Bowen knot',
      "Saint John's Arms",
    ],
    Icon: Icons.Command,
  },
  {
    name: 'compass',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['navigation', 'directions', 'maps', 'safari', 'apple'],
    Icon: Icons.Compass,
  },
  {
    name: 'computer-tower',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OBJECTS],
    tags: ['desktop', 'pc', 'imac'],
    Icon: Icons.ComputerTower,
  },
  {
    name: 'confetti',
    categories: [IconCategory.COMMUNICATION],
    tags: ['*new*', 'tada', 'party', 'emoji'],
    Icon: Icons.Confetti,
  },
  {
    name: 'cookie',
    categories: [
      IconCategory.MAP,
      IconCategory.OBJECTS,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['privacy', 'dessert', 'food', 'dining'],
    Icon: Icons.Cookie,
  },
  {
    name: 'cooking-pot',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['stew', 'kitchen', 'steaming', 'restaurants', 'food', 'dining'],
    Icon: Icons.CookingPot,
  },
  {
    name: 'copy',
    categories: [IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['duplicated', 'copied', 'clipboard'],
    Icon: Icons.Copy,
  },
  {
    name: 'copy-simple',
    categories: [IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['duplicated', 'copied', 'clipboard'],
    Icon: Icons.CopySimple,
  },
  {
    name: 'copyright',
    categories: [IconCategory.COMMERCE, IconCategory.MEDIA],
    tags: ['©', 'intellectual property', 'copr.', 'symbol'],
    Icon: Icons.Copyright,
  },
  {
    name: 'copyleft',
    categories: [IconCategory.COMMERCE, IconCategory.MEDIA],
    tags: ['*new*', '🄯', 'intellectual property', 'copr.', 'symbol'],
    Icon: Icons.Copyleft,
  },
  {
    name: 'corners-in',
    categories: [IconCategory.SYSTEM],
    tags: ['collapse', 'windowed', 'minimized'],
    Icon: Icons.CornersIn,
  },
  {
    name: 'corners-out',
    categories: [IconCategory.SYSTEM],
    tags: ['expand', 'fullscreen', 'maximized'],
    Icon: Icons.CornersOut,
  },
  {
    name: 'cpu',
    categories: [IconCategory.DEVELOPMENT],
    tags: ['processor', 'microchip', 'computer', 'circuit'],
    Icon: Icons.Cpu,
  },
  {
    name: 'credit-card',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: [
      'debit',
      'visa',
      'mastercard',
      'money',
      'payment',
      'paying',
      'purchase',
      'swipe',
    ],
    Icon: Icons.CreditCard,
  },
  {
    name: 'crop',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['photography', 'clip', 'screenshots'],
    Icon: Icons.Crop,
  },
  {
    name: 'crosshair',
    categories: [IconCategory.MAP, IconCategory.SYSTEM],
    tags: ['geolocation', 'gps', 'aiming', 'targeting'],
    Icon: Icons.Crosshair,
  },
  {
    name: 'crosshair-simple',
    categories: [IconCategory.MAP, IconCategory.SYSTEM],
    tags: ['geolocation', 'gps', 'aiming', 'targeting'],
    Icon: Icons.CrosshairSimple,
  },
  {
    name: 'crown',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['king', 'queen', 'royalty', 'monarch', 'ruler', 'leader'],
    Icon: Icons.Crown,
  },
  {
    name: 'crown-simple',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['king', 'queen', 'royalty', 'monarch', 'ruler', 'leader'],
    Icon: Icons.CrownSimple,
  },
  {
    name: 'cube',
    categories: [IconCategory.DESIGN, IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['square', 'box', '3d', 'volume', 'blocks'],
    Icon: Icons.Cube,
  },
  {
    name: 'currency-dollar',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'USD', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyDollar,
  },
  {
    name: 'currency-dollar-simple',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'USD', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyDollarSimple,
  },
  {
    name: 'currency-btc',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: [
      'money',
      'BTC',
      'bitcoin',
      'crypto',
      'cryptocurrency',
      'payment',
      'paying',
      'purchase',
    ],
    Icon: Icons.CurrencyBtc,
  },
  {
    name: 'currency-cny',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'yuan', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyCny,
  },
  {
    name: 'currency-eth',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: [
      '*new*',
      'money',
      'ethereum',
      'crypto',
      'cryptocurrency',
      'payment',
      'paying',
      'purchase',
    ],
    Icon: Icons.CurrencyEth,
  },
  {
    name: 'currency-eur',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'euros', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyEur,
  },
  {
    name: 'currency-gbp',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'pounds sterling', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyGbp,
  },
  {
    name: 'currency-inr',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'rupees', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyInr,
  },
  {
    name: 'currency-jpy',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'yen', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyJpy,
  },
  {
    name: 'currency-krw',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'won', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyKrw,
  },
  {
    name: 'currency-kzt',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: [
      '*new*',
      'money',
      'kazakhstan',
      'tenge',
      'payment',
      'paying',
      'purchase',
    ],
    Icon: Icons.CurrencyKzt,
  },
  {
    name: 'currency-ngn',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: [
      '*new*',
      'money',
      'nigeria',
      'naira',
      'payment',
      'paying',
      'purchase',
    ],
    Icon: Icons.CurrencyNgn,
  },
  {
    name: 'currency-rub',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'rubles', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyRub,
  },
  {
    name: 'currency-circle-dollar',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['money', 'USD', 'payment', 'paying', 'purchase'],
    Icon: Icons.CurrencyCircleDollar,
  },
  {
    name: 'cursor',
    categories: [IconCategory.DESIGN, IconCategory.SYSTEM],
    tags: ['pointer', 'arrowhead', 'mouse', 'click'],
    Icon: Icons.Cursor,
  },
  // {
  //   name: "cursor-click",
  //   categories: [IconCategory.DESIGN, IconCategory.SYSTEM],
  //   tags: ["pointer", "arrowhead", "mouse"],
  //   Icon: Icons.CursorClick,
  // },
  {
    name: 'cursor-text',
    categories: [IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['*new*', 'i-beam', 'input', 'select'],
    Icon: Icons.CursorText,
  },
  {
    name: 'cylinder',
    categories: [IconCategory.DESIGN],
    tags: ['*new*', 'shapes', 'tube'],
    Icon: Icons.Cylinder,
  },
  {
    name: 'database',
    categories: [IconCategory.DEVELOPMENT, IconCategory.SYSTEM],
    tags: [
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'hard disk',
      'storage',
      'hdd',
      'servers',
      'databases',
    ],
    Icon: Icons.Database,
  },
  {
    name: 'desktop',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OBJECTS],
    tags: ['computer', 'pc', 'imac', 'tower'],
    Icon: Icons.Desktop,
  },
  {
    name: 'desktop-tower',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OBJECTS],
    tags: ['computer', 'pc', 'imac'],
    Icon: Icons.DesktopTower,
  },
  {
    name: 'detective',
    categories: [IconCategory.PEOPLE, IconCategory.SYSTEM],
    tags: ['*new*', 'incognito', 'police', 'law enforcement', 'spy', 'secret'],
    Icon: Icons.Detective,
  },
  {
    name: 'device-mobile',
    categories: [IconCategory.OBJECTS],
    tags: ['cellphone', 'cellular'],
    Icon: Icons.DeviceMobile,
  },
  {
    name: 'device-mobile-camera',
    categories: [IconCategory.OBJECTS],
    tags: ['cellphone', 'cellular'],
    Icon: Icons.DeviceMobileCamera,
  },
  {
    name: 'device-mobile-speaker',
    categories: [IconCategory.OBJECTS],
    tags: ['cellphone', 'cellular'],
    Icon: Icons.DeviceMobileSpeaker,
  },
  {
    name: 'device-tablet',
    categories: [IconCategory.OBJECTS],
    tags: ['cellphone', 'cellular', 'ipad', 'phablet'],
    Icon: Icons.DeviceTablet,
  },
  {
    name: 'device-tablet-camera',
    categories: [IconCategory.OBJECTS],
    tags: ['cellphone', 'cellular', 'ipad', 'phablet'],
    Icon: Icons.DeviceTabletCamera,
  },
  {
    name: 'device-tablet-speaker',
    categories: [IconCategory.OBJECTS],
    tags: ['cellphone', 'cellular', 'ipad', 'phablet'],
    Icon: Icons.DeviceTabletSpeaker,
  },
  {
    name: 'diamond',
    categories: [IconCategory.DESIGN, IconCategory.GAMES],
    tags: [
      'rectangle',
      'shapes',
      'polygons',
      'diamonds',
      'suits',
      'cards',
      'gambling',
      'casino',

      'gaming',
    ],
    Icon: Icons.Diamond,
  },
  {
    name: 'diamonds-four',
    categories: [IconCategory.DESIGN],
    tags: ['*new*', 'shapes', 'grid'],
    Icon: Icons.DiamondsFour,
  },
  // {
  //   name: "dice",
  //   categories: [IconCategory.OTHER],
  //   tags: [ "die", "rolling", "gamble", "gambling", "casino", "gaming"],
  //   Icon: Icons.Dice,
  // },
  {
    name: 'dice-one',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['die', 'rolling', 'gamble', 'gambling', 'casino', 'gaming', '1'],
    Icon: Icons.DiceOne,
  },
  {
    name: 'dice-two',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['die', 'rolling', 'gamble', 'gambling', 'casino', 'gaming', '2'],
    Icon: Icons.DiceTwo,
  },
  {
    name: 'dice-three',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['die', 'rolling', 'gamble', 'gambling', 'casino', 'gaming', '3'],
    Icon: Icons.DiceThree,
  },
  {
    name: 'dice-four',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['die', 'rolling', 'gamble', 'gambling', 'casino', 'gaming', '4'],
    Icon: Icons.DiceFour,
  },
  {
    name: 'dice-five',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['die', 'rolling', 'gamble', 'gambling', 'casino', 'gaming', '5'],
    Icon: Icons.DiceFive,
  },
  {
    name: 'dice-six',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['die', 'rolling', 'gamble', 'gambling', 'casino', 'gaming', '6'],
    Icon: Icons.DiceSix,
  },
  {
    name: 'disc',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.MEDIA,
      IconCategory.OBJECTS,
    ],
    tags: ['cd-rom', 'compact disk', 'album', 'record'],
    Icon: Icons.Disc,
  },
  {
    name: 'discord-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'messages', 'messaging', 'chat'],
    Icon: Icons.DiscordLogo,
  },
  {
    name: 'divide',
    categories: [IconCategory.DEVELOPMENT, IconCategory.FINANCE],
    tags: [
      'division',
      'divisor',
      'dividend',
      'quotient',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.Divide,
  },
  {
    name: 'dog',
    categories: [IconCategory.NATURE],
    tags: ['pets', 'animals', 'puppy'],
    Icon: Icons.Dog,
  },
  {
    name: 'door',
    categories: [IconCategory.OBJECTS],
    tags: ['entrance', 'exit'],
    Icon: Icons.Door,
  },
  {
    name: 'dots-nine',
    categories: [IconCategory.DESIGN],
    tags: ['grid', 'circles', 'shapes', 'polygons', '9'],
    Icon: Icons.DotsNine,
  },
  {
    name: 'dots-six',
    categories: [IconCategory.SYSTEM],
    tags: ['drag handle', 'knurling', 'circles', 'shapes', 'polygons', '6'],
    Icon: Icons.DotsSix,
  },
  {
    name: 'dots-six-vertical',
    categories: [IconCategory.SYSTEM],
    tags: ['drag handle', 'knurling', 'circles', 'shapes', 'polygons', '6'],
    Icon: Icons.DotsSixVertical,
  },
  {
    name: 'dots-three',
    categories: [IconCategory.SYSTEM],
    tags: [
      'menu',
      'overflow',
      'circles',
      'shapes',
      'polygons',
      '3',
      'ellipsis',
      'ellipses',
      'more',
    ],
    Icon: Icons.DotsThree,
  },
  {
    name: 'dots-three-vertical',
    categories: [IconCategory.SYSTEM],
    tags: [
      'menu',
      'overflow',
      'circles',
      'shapes',
      'polygons',
      '3',
      'ellipsis',
      'ellipses',
      'more',
    ],
    Icon: Icons.DotsThreeVertical,
  },
  {
    name: 'dots-three-outline',
    categories: [IconCategory.SYSTEM],
    tags: [
      'menu',
      'overflow',
      'circles',
      'shapes',
      'polygons',
      '3',
      'ellipsis',
      'ellipses',
      'more',
    ],
    Icon: Icons.DotsThreeOutline,
  },
  {
    name: 'dots-three-outline-vertical',
    categories: [IconCategory.SYSTEM],
    tags: [
      'menu',
      'overflow',
      'circles',
      'shapes',
      'polygons',
      '3',
      'ellipsis',
      'ellipses',
      'more',
    ],
    Icon: Icons.DotsThreeOutlineVertical,
  },
  {
    name: 'dots-three-circle',
    categories: [IconCategory.SYSTEM],
    tags: [
      'menu',
      'overflow',
      'circles',
      'shapes',
      'polygons',
      '3',
      'ellipsis',
      'ellipses',
      'more',
    ],
    Icon: Icons.DotsThreeCircle,
  },
  {
    name: 'dots-three-circle-vertical',
    categories: [IconCategory.SYSTEM],
    tags: [
      'menu',
      'overflow',
      'circles',
      'shapes',
      'polygons',
      '3',
      'ellipsis',
      'ellipses',
      'more',
    ],
    Icon: Icons.DotsThreeCircleVertical,
  },
  {
    name: 'download',
    categories: [IconCategory.SYSTEM],
    tags: [
      '*updated*',
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'downloaded',
      'downloading',
      'hard drive',
      'disk',
    ],
    Icon: Icons.Download,
  },
  {
    name: 'download-simple',
    categories: [IconCategory.SYSTEM],
    tags: [
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'downloaded',
      'downloading',
      'hard drive',
      'disk',
    ],
    Icon: Icons.DownloadSimple,
  },
  {
    name: 'dribbble-logo',
    categories: [IconCategory.BRAND, IconCategory.DESIGN],
    tags: ['*updated*', 'logos', 'round', 'basketball', 'sports', 'design'],
    Icon: Icons.DribbbleLogo,
  },
  {
    name: 'drop',
    categories: [IconCategory.NATURE, IconCategory.WEATHER],
    tags: [
      'droplet',
      'teardrop',
      'raindrop',
      'raining',
      'meteorology',
      'water',
    ],
    Icon: Icons.Drop,
  },
  {
    name: 'drop-half',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.NATURE,
      IconCategory.WEATHER,
    ],
    tags: [
      '*updated*',
      'droplet',
      'teardrop',
      'raindrop',
      'humidity',
      'water',
      'contrast',
      'brightness',
    ],
    Icon: Icons.DropHalf,
  },
  {
    name: 'drop-half-bottom',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.NATURE,
      IconCategory.WEATHER,
    ],
    tags: [
      '*new*',
      'droplet',
      'teardrop',
      'raindrop',
      'humidity',
      'water',
      'contrast',
      'brightness',
    ],
    Icon: Icons.DropHalfBottom,
  },
  // {
  //   name: "dropbox-logo",
  //   categories: [IconCategory.BRAND],
  //   tags: [ "cloud", "backup"],
  //   Icon: Icons.DropboxLogo,
  // },
  {
    name: 'ear',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['hearing', 'audio', 'sound'],
    Icon: Icons.Ear,
  },
  {
    name: 'ear-slash',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['hearing', 'audio', 'sound', 'mute', 'accessible'],
    Icon: Icons.EarSlash,
  },
  {
    name: 'egg',
    categories: [IconCategory.COMMERCE, IconCategory.NATURE],
    tags: ['*new*', 'chicken', 'food', 'meal', 'baby', 'hatch'],
    Icon: Icons.Egg,
  },
  {
    name: 'egg-crack',
    categories: [IconCategory.COMMERCE, IconCategory.NATURE],
    tags: ['*new*', 'chicken', 'food', 'meal', 'baby', 'hatch', 'break'],
    Icon: Icons.EggCrack,
  },
  {
    name: 'eject',
    categories: [IconCategory.MEDIA],
    tags: ['disconnect'],
    Icon: Icons.Eject,
  },
  {
    name: 'eject-simple',
    categories: [IconCategory.MEDIA],
    tags: ['disconnect'],
    Icon: Icons.EjectSimple,
  },
  {
    name: 'equalizer',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['*new*', 'music', 'audio', 'meter', 'volume', 'spectrum', 'eq'],
    Icon: Icons.Equalizer,
  },
  {
    name: 'eraser',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['write', 'writing', 'editing', 'undo', 'deleted'],
    Icon: Icons.Eraser,
  },
  {
    name: 'envelope',
    categories: [IconCategory.COMMUNICATION],
    tags: ['mail', 'email', 'send', 'sent', 'message', 'post', 'letter'],
    Icon: Icons.Envelope,
  },
  {
    name: 'envelope-open',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'mail',
      'email',
      'send',
      'sent',
      'message',
      'read',
      'post',
      'letter',
    ],
    Icon: Icons.EnvelopeOpen,
  },
  {
    name: 'envelope-simple',
    categories: [IconCategory.COMMUNICATION],
    tags: ['mail', 'email', 'send', 'sent', 'message', 'post', 'letter'],
    Icon: Icons.EnvelopeSimple,
  },
  {
    name: 'envelope-simple-open',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'mail',
      'email',
      'send',
      'sent',
      'message',
      'read',
      'post',
      'letter',
    ],
    Icon: Icons.EnvelopeSimpleOpen,
  },
  {
    name: 'equals',
    categories: [IconCategory.DEVELOPMENT, IconCategory.FINANCE],
    tags: [
      '=',
      'equality',
      'equivalent',
      'equivalence',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.Equals,
  },
  {
    name: 'exam',
    categories: [IconCategory.OBJECTS],
    tags: ['*new*', 'text', 'examination', 'paper', 'school', 'grade'],
    Icon: Icons.Exam,
  },
  {
    name: 'export',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['share', 'send to', 'arrows'],
    Icon: Icons.Export,
  },
  {
    name: 'eye',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['visible', 'hidden', 'show', 'hide', 'visibility', 'view'],
    Icon: Icons.Eye,
  },
  {
    name: 'eye-slash',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: [
      'visible',
      'hidden',
      'show',
      'hide',
      'visibility',
      'view',
      'invisible',
      'eyelashes',
      'disabled',
      'private',
    ],
    Icon: Icons.EyeSlash,
  },
  {
    name: 'eye-closed',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: [
      'visible',
      'hidden',
      'show',
      'hide',
      'visibility',
      'view',
      'invisible',
      'private',
    ],
    Icon: Icons.EyeClosed,
  },
  {
    name: 'eyedropper',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['colors', 'color picker', 'sample', 'arts'],
    Icon: Icons.Eyedropper,
  },
  {
    name: 'eyedropper-sample',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['*new*', 'colors', 'color picker', 'arts'],
    Icon: Icons.EyedropperSample,
  },
  {
    name: 'eyeglasses',
    categories: [IconCategory.HEALTH, IconCategory.OBJECTS],
    tags: ['*new*', 'vision', 'spectacles'],
    Icon: Icons.Eyeglasses,
  },
  {
    name: 'face-mask',
    categories: [IconCategory.HEALTH],
    tags: ['ppe', 'facemask', 'covid-19', 'coronavirus', 'flu', 'cold'],
    Icon: Icons.FaceMask,
  },
  {
    name: 'facebook-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['*updated*', 'logos', 'social media'],
    Icon: Icons.FacebookLogo,
  },
  {
    name: 'factory',
    categories: [IconCategory.COMMERCE, IconCategory.MAP],
    tags: ['industry', 'manufacture', 'buildings', 'places', 'locations'],
    Icon: Icons.Factory,
  },
  {
    name: 'faders',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: [
      'music',
      'audio',
      'sliders',
      'filters',
      'equalizer',
      'volume',
      'settings',
      'preferences',
    ],
    Icon: Icons.Faders,
  },
  {
    name: 'faders-horizontal',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: [
      'music',
      'audio',
      'sliders',
      'filters',
      'equalizer',
      'volume',
      'settings',
      'preferences',
    ],
    Icon: Icons.FadersHorizontal,
  },
  // {
  //   name: "family",
  //   categories: [IconCategory.PEOPLE],
  //   tags: [ "group", "community", "society", "parents", "children"],
  //   Icon: Icons.Family,
  // },
  {
    name: 'fast-forward',
    categories: [IconCategory.MEDIA],
    tags: ['audio', 'music', 'seek', 'scrub', 'scan', 'ahead', 'skip'],
    Icon: Icons.FastForward,
  },
  {
    name: 'fast-forward-circle',
    categories: [IconCategory.MEDIA],
    tags: ['audio', 'music', 'seek', 'scrub', 'scan', 'ahead', 'skip'],
    Icon: Icons.FastForwardCircle,
  },
  {
    name: 'figma-logo',
    categories: [IconCategory.BRAND, IconCategory.DESIGN],
    tags: [
      'logos',
      'drawing',
      'art',
      'illustration',
      'ui',
      'interface',
      'prototype',
      'prototyping',
    ],
    Icon: Icons.FigmaLogo,
  },
  {
    name: 'file',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['documents', 'files', 'save', 'write', 'page'],
    Icon: Icons.File,
  },
  {
    name: 'file-arrow-up',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: [
      'documents',
      'files',
      'save',
      'write',
      'upload',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
    ],
    Icon: Icons.FileArrowUp,
  },
  {
    name: 'file-arrow-down',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: [
      'documents',
      'files',
      'save',
      'write',
      'download',
      'directional',
      'pointer',
      'pointing',
      'arrowhead',
    ],
    Icon: Icons.FileArrowDown,
  },
  {
    name: 'file-audio',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.MEDIA],
    tags: ['*new*', 'documents', 'music', 'sound'],
    Icon: Icons.FileAudio,
  },
  {
    name: 'file-plus',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['documents', 'files', 'save', 'write', 'add', 'new', 'create', '+'],
    Icon: Icons.FilePlus,
  },
  {
    name: 'file-minus',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['documents', 'files', 'delete', 'write', 'remove', '-'],
    Icon: Icons.FileMinus,
  },
  {
    name: 'file-cloud',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['*new*', 'documents', 'sync'],
    Icon: Icons.FileCloud,
  },
  {
    name: 'file-code',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents'],
    Icon: Icons.FileCode,
  },
  {
    name: 'file-css',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents', 'code'],
    Icon: Icons.FileCss,
  },
  {
    name: 'file-csv',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['*new*', 'documents', 'data'],
    Icon: Icons.FileCsv,
  },
  {
    name: 'file-doc',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['*new*', 'documents', 'word', 'microsoft'],
    Icon: Icons.FileDoc,
  },
  {
    name: 'file-dotted',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['documents', 'files', 'browse', 'draft', 'open'],
    Icon: Icons.FileDotted,
  },
  {
    name: 'file-html',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents', 'code'],
    Icon: Icons.FileHtml,
  },
  {
    name: 'file-image',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.MEDIA],
    tags: ['*new*', 'documents', 'pictures', 'photograph'],
    Icon: Icons.FileImage,
  },
  {
    name: 'file-jpg',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.MEDIA],
    tags: ['*new*', 'documents', 'pictures', 'photograph', 'jpeg'],
    Icon: Icons.FileJpg,
  },
  {
    name: 'file-js',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents', 'code', 'javascript'],
    Icon: Icons.FileJs,
  },
  {
    name: 'file-jsx',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents', 'code', 'javascript'],
    Icon: Icons.FileJsx,
  },
  {
    name: 'file-lock',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['*new*', 'documents', 'secure', 'locked', 'private'],
    Icon: Icons.FileLock,
  },
  {
    name: 'file-pdf',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['*updated*', 'documents', 'files', 'acrobat'],
    Icon: Icons.FilePdf,
  },
  {
    name: 'file-png',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.MEDIA],
    tags: ['*new*', 'documents', 'pictures', 'photograph'],
    Icon: Icons.FilePng,
  },
  {
    name: 'file-ppt',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['*new*', 'documents', 'powerpoint', 'microsoft'],
    Icon: Icons.FilePpt,
  },
  {
    name: 'file-rs',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents', 'code', 'rust'],
    Icon: Icons.FileRs,
  },
  {
    name: 'file-search',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['documents', 'files', 'find', 'locate', 'browse', 'missing'],
    Icon: Icons.FileSearch,
  },
  {
    name: 'file-text',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['documents', 'files', 'save', 'write'],
    Icon: Icons.FileText,
  },
  {
    name: 'file-ts',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents', 'code', 'typescript'],
    Icon: Icons.FileTs,
  },
  {
    name: 'file-tsx',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents', 'code', 'typescript'],
    Icon: Icons.FileTsx,
  },
  {
    name: 'file-video',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.MEDIA],
    tags: ['*new*', 'documents', 'movie'],
    Icon: Icons.FileVideo,
  },
  {
    name: 'file-vue',
    categories: [
      IconCategory.OFFICE,
      IconCategory.EDITOR,
      IconCategory.DEVELOPMENT,
    ],
    tags: ['*new*', 'documents', 'code'],
    Icon: Icons.FileVue,
  },
  {
    name: 'file-x',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['documents', 'files', 'cancelled', 'deleted', 'removed', 'errors'],
    Icon: Icons.FileX,
  },
  {
    name: 'file-xls',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['*new*', 'documents', 'excel', 'microsoft'],
    Icon: Icons.FileXls,
  },
  {
    name: 'file-zip',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['*new*', 'documents', 'archive', 'compression'],
    Icon: Icons.FileZip,
  },
  {
    name: 'files',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['documents', 'open', 'library'],
    Icon: Icons.Files,
  },
  {
    name: 'film-script',
    categories: [IconCategory.OFFICE, IconCategory.MEDIA],
    tags: ['*new*', 'screenplay', 'movie'],
    Icon: Icons.FilmScript,
  },
  {
    name: 'film-slate',
    categories: [IconCategory.MEDIA],
    tags: ['*new*', 'clapper', 'movie'],
    Icon: Icons.FilmSlate,
  },
  {
    name: 'film-strip',
    categories: [IconCategory.MEDIA],
    tags: ['*updated*', 'camera', 'photography', 'darkroom', 'movie', 'analog'],
    Icon: Icons.FilmStrip,
  },
  {
    name: 'fingerprint',
    categories: [IconCategory.SYSTEM],
    tags: [
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'biometrics',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.Fingerprint,
  },
  {
    name: 'fingerprint-simple',
    categories: [IconCategory.SYSTEM],
    tags: [
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'biometrics',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.FingerprintSimple,
  },
  {
    name: 'finn-the-human',
    categories: [IconCategory.GAMES],
    tags: ['adventure time', 'cartoons', 'television', 'tv', 'character'],
    Icon: Icons.FinnTheHuman,
  },
  {
    name: 'fire',
    categories: [IconCategory.NATURE, IconCategory.WEATHER],
    tags: ['flame', 'burning', 'match', 'lighter'],
    Icon: Icons.Fire,
  },
  {
    name: 'fire-simple',
    categories: [IconCategory.NATURE, IconCategory.WEATHER],
    tags: ['flame', 'burning', 'match', 'lighter'],
    Icon: Icons.FireSimple,
  },
  {
    name: 'first-aid',
    categories: [IconCategory.HEALTH],
    tags: [
      'hospital',
      'cross',
      'medical',
      'medicine',
      'injury',
      'safety',
      'emergency',
      'doctor',
    ],
    Icon: Icons.FirstAid,
  },
  {
    name: 'first-aid-kit',
    categories: [IconCategory.HEALTH],
    tags: [
      'bandages',
      'medical',
      'medicine',
      'injury',
      'safety',
      'emergency',
      'doctor',
    ],
    Icon: Icons.FirstAidKit,
  },
  {
    name: 'fish',
    categories: [IconCategory.NATURE, IconCategory.COMMERCE],
    tags: ['animals', 'pets', 'food', 'seafood', 'restaurants', 'dining'],
    Icon: Icons.Fish,
  },
  {
    name: 'fish-simple',
    categories: [IconCategory.NATURE, IconCategory.COMMERCE],
    tags: ['animals', 'pets', 'food', 'seafood', 'restaurants', 'dining'],
    Icon: Icons.FishSimple,
  },
  {
    name: 'flag',
    categories: [IconCategory.OBJECTS, IconCategory.MAP, IconCategory.SYSTEM],
    tags: ['country', 'countries', 'finished', 'completed', 'flags'],
    Icon: Icons.Flag,
  },
  {
    name: 'flag-banner',
    categories: [IconCategory.OBJECTS, IconCategory.MAP],
    tags: ['ribbon', 'country', 'countries', 'finished', 'completed', 'flags'],
    Icon: Icons.FlagBanner,
  },
  {
    name: 'flag-checkered',
    categories: [IconCategory.MAP, IconCategory.OBJECTS, IconCategory.GAMES],
    tags: ['*new*', 'flags', 'race', 'racing', 'finish line'],
    Icon: Icons.FlagCheckered,
  },
  {
    name: 'flame',
    categories: [IconCategory.NATURE, IconCategory.WEATHER],
    tags: ['fire', 'burning', 'match', 'lighter'],
    Icon: Icons.Flame,
  },
  {
    name: 'flashlight',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: ['torch', 'find', 'search', 'locate'],
    Icon: Icons.Flashlight,
  },
  {
    name: 'flask',
    categories: [IconCategory.DEVELOPMENT, IconCategory.NATURE],
    tags: ['beaker', 'science', 'chemistry', 'experiment', 'erlenmeyer'],
    Icon: Icons.Flask,
  },
  {
    name: 'floppy-disk',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'diskette',
      'directory',
      'directories',
      'folders',
      'documents',
      'files',
      'save',
      'write',
    ],
    Icon: Icons.FloppyDisk,
  },
  {
    name: 'floppy-disk-back',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      '*updated*',
      'diskette',
      'directory',
      'directories',
      'folders',
      'documents',
      'files',
      'save',
      'write',
    ],
    Icon: Icons.FloppyDiskBack,
  },
  {
    name: 'flow-arrow',
    categories: [IconCategory.ARROWS, IconCategory.DESIGN, IconCategory.OFFICE],
    tags: ['flowchart', 'arrowhead'],
    Icon: Icons.FlowArrow,
  },
  {
    name: 'flower',
    categories: [IconCategory.NATURE],
    tags: ['plants', 'green', 'environmental'],
    Icon: Icons.Flower,
  },
  {
    name: 'flower-lotus',
    categories: [IconCategory.NATURE],
    tags: ['plants', 'green', 'environmental', 'spirituality'],
    Icon: Icons.FlowerLotus,
  },
  {
    name: 'flying-saucer',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['*new*', 'ufo', 'space', 'aliens', 'extra terrestrial', 'sci-fi'],
    Icon: Icons.FlyingSaucer,
  },
  {
    name: 'folder',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['directory', 'directories', 'files', 'folders'],
    Icon: Icons.Folder,
  },
  {
    name: 'folder-plus',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'directory',
      'directories',
      'files',
      'save',
      'write',
      'add',
      'new',
      'create',
      '+',
    ],
    Icon: Icons.FolderPlus,
  },
  {
    name: 'folder-minus',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'directory',
      'directories',
      'files',
      'delete',
      'write',
      'remove',
      '-',
    ],
    Icon: Icons.FolderMinus,
  },
  {
    name: 'folder-open',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['directory', 'directories', 'files', 'folders', 'load'],
    Icon: Icons.FolderOpen,
  },
  {
    name: 'folder-dotted',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'directory',
      'directories',
      'files',
      'folders',
      'missing',
      'temporary',
    ],
    Icon: Icons.FolderDotted,
  },
  {
    name: 'folder-lock',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'directory',
      'directories',
      'files',
      'folders',
      'private',
      'secure',
    ],
    Icon: Icons.FolderLock,
  },
  {
    name: 'folder-star',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'directory',
      'directories',
      'files',
      'folders',
      'favorite',
      'starred',
    ],
    Icon: Icons.FolderStar,
  },
  {
    name: 'folder-user',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['*new*', 'directory', 'directories', 'files', 'folders', 'personal'],
    Icon: Icons.FolderUser,
  },
  {
    name: 'folder-notch',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['directory', 'directories', 'files', 'folders'],
    Icon: Icons.FolderNotch,
  },
  {
    name: 'folder-notch-plus',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'directory',
      'directories',
      'files',
      'save',
      'write',
      'add',
      'new',
      'create',
      '+',
    ],
    Icon: Icons.FolderNotchPlus,
  },
  {
    name: 'folder-notch-minus',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'directory',
      'directories',
      'files',
      'delete',
      'write',
      'remove',
      '-',
    ],
    Icon: Icons.FolderNotchMinus,
  },
  {
    name: 'folder-notch-open',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['directory', 'directories', 'files', 'folders', 'load'],
    Icon: Icons.FolderNotchOpen,
  },
  {
    name: 'folder-simple',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['directory', 'directories', 'files', 'folders'],
    Icon: Icons.FolderSimple,
  },
  {
    name: 'folder-simple-plus',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'directory',
      'directories',
      'files',
      'save',
      'write',
      'add',
      'new',
      'create',
      '+',
    ],
    Icon: Icons.FolderSimplePlus,
  },
  {
    name: 'folder-simple-minus',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'directory',
      'directories',
      'files',
      'delete',
      'write',
      'remove',
      '-',
    ],
    Icon: Icons.FolderSimpleMinus,
  },
  {
    name: 'folder-simple-dotted',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'directory',
      'directories',
      'files',
      'folders',
      'missing',
      'temporary',
    ],
    Icon: Icons.FolderSimpleDotted,
  },
  {
    name: 'folder-simple-lock',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'directory',
      'directories',
      'files',
      'folders',
      'private',
      'secure',
    ],
    Icon: Icons.FolderSimpleLock,
  },
  {
    name: 'folder-simple-star',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'directory',
      'directories',
      'files',
      'folders',
      'favorite',
      'starred',
    ],
    Icon: Icons.FolderSimpleStar,
  },
  {
    name: 'folder-simple-user',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['*new*', 'directory', 'directories', 'files', 'folders', 'personal'],
    Icon: Icons.FolderSimpleUser,
  },
  {
    name: 'folders',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: [
      'directory',
      'directories',
      'files',
      'folders',
      'copy',
      'copied',
      'duplicated',
    ],
    Icon: Icons.Folders,
  },
  {
    name: 'football',
    categories: [IconCategory.GAMES, IconCategory.HEALTH],
    tags: ['sports', 'american football', 'nfl'],
    Icon: Icons.Football,
  },
  {
    name: 'fork-knife',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['food', 'meal', 'eating', 'restaurants', 'dining', 'utensils'],
    Icon: Icons.ForkKnife,
  },
  {
    name: 'frame-corners',
    categories: [IconCategory.SYSTEM],
    tags: [
      'expand',
      'fullscreen',
      'maximized',
      'resize',
      'windowed',
      'capture',
    ],
    Icon: Icons.FrameCorners,
  },
  {
    name: 'framer-logo',
    categories: [IconCategory.BRAND, IconCategory.DESIGN],
    tags: ['logos', 'interface', 'ui', 'motion', 'prototype', 'prototyping'],
    Icon: Icons.FramerLogo,
  },
  {
    name: 'function',
    categories: [IconCategory.DEVELOPMENT],
    tags: ['*new*', 'mathematics', 'arithmetic'],
    Icon: Icons.Function,
  },
  {
    name: 'funnel',
    categories: [IconCategory.EDITOR, IconCategory.OBJECTS],
    tags: ['filters', 'refine', 'sorting'],
    Icon: Icons.Funnel,
  },
  {
    name: 'funnel-simple',
    categories: [IconCategory.EDITOR, IconCategory.OBJECTS],
    tags: ['filters', 'refine', 'sorting'],
    Icon: Icons.FunnelSimple,
  },
  {
    name: 'game-controller',
    categories: [IconCategory.GAMES, IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: [
      'gaming',
      'video games',
      'nintendo switch',
      'sony playstation',
      'microsoft xbox',
    ],
    Icon: Icons.GameController,
  },
  {
    name: 'gas-pump',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['gas station', 'petrol', 'fuel', 'gasoline'],
    Icon: Icons.GasPump,
  },
  {
    name: 'gauge',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.OBJECTS,
      IconCategory.SYSTEM,
    ],
    tags: [
      '*updated*',
      'dashboard',
      'meter',
      'speed',
      'speedometer',
      'odometer',
      'performance',
    ],
    Icon: Icons.Gauge,
  },
  {
    name: 'gear',
    categories: [IconCategory.SYSTEM],
    tags: [
      '8',
      'settings',
      'setup',
      'preferences',
      'cogs',
      'gears',
      'machinery',
      'mechanical',
    ],
    Icon: Icons.Gear,
  },
  {
    name: 'gear-six',
    categories: [IconCategory.SYSTEM],
    tags: [
      '6',
      'settings',
      'setup',
      'preferences',
      'cogs',
      'gears',
      'machinery',
      'mechanical',
    ],
    Icon: Icons.GearSix,
  },
  {
    name: 'gender-female',
    categories: [IconCategory.PEOPLE],
    tags: ['woman', 'feminine', 'venus'],
    Icon: Icons.GenderFemale,
  },
  {
    name: 'gender-male',
    categories: [IconCategory.PEOPLE],
    tags: ['man', 'masculine', 'mars'],
    Icon: Icons.GenderMale,
  },
  {
    name: 'gender-intersex',
    categories: [IconCategory.PEOPLE],
    tags: ['transgender', 'non-binary'],
    Icon: Icons.GenderIntersex,
  },
  {
    name: 'gender-transgender',
    categories: [IconCategory.PEOPLE],
    tags: ['intersex', 'non-binary'],
    Icon: Icons.GenderTransgender,
  },
  {
    name: 'gender-nonbinary',
    categories: [IconCategory.PEOPLE],
    tags: ['intersex', 'non-binary'],
    Icon: Icons.GenderNonbinary,
  },
  {
    name: 'gender-neuter',
    categories: [IconCategory.PEOPLE],
    tags: ['agender', 'non-binary', 'asexual'],
    Icon: Icons.GenderNeuter,
  },
  {
    name: 'ghost',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['pac-man', 'spirit', 'scary', 'halloween'],
    Icon: Icons.Ghost,
  },
  {
    name: 'gif',
    categories: [IconCategory.MEDIA],
    tags: ['gifs', '.gif', 'giphy'],
    Icon: Icons.Gif,
  },
  {
    name: 'gift',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['presents', 'holiday', 'birthday'],
    Icon: Icons.Gift,
  },
  {
    name: 'git-branch',
    categories: [IconCategory.DEVELOPMENT],
    tags: [
      'github',
      'vcs',
      'source control',
      'version control',
      'versioning',
      'branches',
    ],
    Icon: Icons.GitBranch,
  },
  {
    name: 'git-commit',
    categories: [IconCategory.DEVELOPMENT],
    tags: [
      'github',
      'vcs',
      'source control',
      'version control',
      'versioning',
      'commits',
    ],
    Icon: Icons.GitCommit,
  },
  {
    name: 'git-diff',
    categories: [IconCategory.DEVELOPMENT],
    tags: [
      'github',
      'vcs',
      'source control',
      'version control',
      'versioning',
      'difference',
      'compare',
    ],
    Icon: Icons.GitDiff,
  },
  {
    name: 'git-fork',
    categories: [IconCategory.DEVELOPMENT],
    tags: [
      'github',
      'vcs',
      'source control',
      'version control',
      'versioning',
      'split',
    ],
    Icon: Icons.GitFork,
  },
  {
    name: 'git-merge',
    categories: [IconCategory.DEVELOPMENT],
    tags: [
      'github',
      'vcs',
      'source control',
      'version control',
      'versioning',
      'split',
    ],
    Icon: Icons.GitMerge,
  },
  {
    name: 'git-pull-request',
    categories: [IconCategory.DEVELOPMENT],
    tags: [
      'github',
      'vcs',
      'source control',
      'version control',
      'versioning',
      'merge request',
    ],
    Icon: Icons.GitPullRequest,
  },
  {
    name: 'github-logo',
    categories: [IconCategory.DEVELOPMENT, IconCategory.BRAND],
    tags: [
      'octocat',
      'vcs',
      'source control',
      'version control',
      'versioning',
      'branches',
    ],
    Icon: Icons.GithubLogo,
  },
  {
    name: 'gitlab-logo',
    categories: [IconCategory.BRAND, IconCategory.DEVELOPMENT],
    tags: [
      'vcs',
      'source control',
      'version control',
      'versioning',
      'branches',
    ],
    Icon: Icons.GitlabLogo,
  },
  {
    name: 'gitlab-logo-simple',
    categories: [IconCategory.BRAND, IconCategory.DEVELOPMENT],
    tags: [
      'vcs',
      'source control',
      'version control',
      'versioning',
      'branches',
    ],
    Icon: Icons.GitlabLogoSimple,
  },
  {
    name: 'globe',
    categories: [IconCategory.MAP],
    tags: [
      'world',
      'earth',
      'global',
      'planet',
      'circle',
      'round',
      'internationalization',
      'i18n',
      'languages',
      'country',
      'countries',
      'geography',
    ],
    Icon: Icons.Globe,
  },
  {
    name: 'globe-simple',
    categories: [IconCategory.MAP],
    tags: [
      'world',
      'earth',
      'global',
      'planet',
      'circle',
      'round',
      'internationalization',
      'i18n',
      'languages',
      'country',
      'countries',
      'geography',
    ],
    Icon: Icons.GlobeSimple,
  },
  {
    name: 'globe-hemisphere-east',
    categories: [IconCategory.MAP],
    tags: [
      'world',
      'earth',
      'global',
      'planet',
      'circle',
      'round',
      'internationalization',
      'i18n',
      'languages',
      'country',
      'countries',
      'geography',
      'europe',
      'africa',
      'asia',
      'australia',
    ],
    Icon: Icons.GlobeHemisphereEast,
  },
  {
    name: 'globe-hemisphere-west',
    categories: [IconCategory.MAP],
    tags: [
      'world',
      'earth',
      'global',
      'planet',
      'circle',
      'round',
      'internationalization',
      'i18n',
      'languages',
      'country',
      'countries',
      'geography',
      'north america',
      'south america',
    ],
    Icon: Icons.GlobeHemisphereWest,
  },
  {
    name: 'globe-stand',
    categories: [IconCategory.MAP],
    tags: [
      'world',
      'global',
      'planet',
      'circle',
      'round',
      'internationalization',
      'i18n',
      'languages',
      'country',
      'countries',
      'geography',
    ],
    Icon: Icons.GlobeStand,
  },
  {
    name: 'google-logo',
    categories: [IconCategory.BRAND],
    tags: ['logos', 'search engine', 'phone', 'mobile', 'android'],
    Icon: Icons.GoogleLogo,
  },
  {
    name: 'google-chrome-logo',
    categories: [IconCategory.BRAND],
    tags: ['*new*', 'web browsers', 'internet'],
    Icon: Icons.GoogleChromeLogo,
  },
  {
    name: 'google-photos-logo',
    categories: [IconCategory.BRAND, IconCategory.MEDIA],
    tags: ['*new*', 'album', 'pictures', 'photography'],
    Icon: Icons.GooglePhotosLogo,
  },
  {
    name: 'google-play-logo',
    categories: [IconCategory.BRAND, IconCategory.SYSTEM, IconCategory.MEDIA],
    tags: [
      'logos',
      'games',
      'apps',
      'applications',
      'play store',
      'app store',
      'phone',
      'mobile',
      'android',
    ],
    Icon: Icons.GooglePlayLogo,
  },
  {
    name: 'google-podcasts-logo',
    categories: [IconCategory.BRAND, IconCategory.MEDIA],
    tags: ['*new*', 'audio'],
    Icon: Icons.GooglePodcastsLogo,
  },
  {
    name: 'gradient',
    categories: [IconCategory.DESIGN],
    tags: ['*new*', 'fade', 'ombre', 'opacity'],
    Icon: Icons.Gradient,
  },
  {
    name: 'graduation-cap',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'classroom',
      'teacher',
      'education',
      'school',
      'college',
      'university',
      'degree',
      'graduate',
    ],
    Icon: Icons.GraduationCap,
  },
  {
    name: 'graph',
    categories: [IconCategory.OFFICE, IconCategory.DEVELOPMENT],
    tags: ['*new*', 'nodes', 'tree'],
    Icon: Icons.Graph,
  },
  {
    name: 'grid-four',
    categories: [IconCategory.DESIGN, IconCategory.SYSTEM],
    tags: [
      '4',
      'apps',
      'applications',
      'squares',
      'tiles',
      'cells',
      'tables',
      'tabular',
      'spreadsheets',
      'excel',
    ],
    Icon: Icons.GridFour,
  },
  {
    name: 'hamburger',
    categories: [IconCategory.COMMERCE, IconCategory.MAP],
    tags: [
      '*new*',
      'fast food',
      'party',
      'places',
      'locations',
      'restaurants',
      'food',
      'dining',
    ],
    Icon: Icons.Hamburger,
  },
  {
    name: 'hand',
    categories: [IconCategory.SYSTEM, IconCategory.PEOPLE],
    tags: ['pointers', 'cursors', 'emoji', 'backhand'],
    Icon: Icons.Hand,
  },
  {
    name: 'hand-eye',
    categories: [IconCategory.PEOPLE],
    tags: ['*new*', 'pointers', 'cursors', 'emoji', 'hamsa', 'evil eye'],
    Icon: Icons.HandEye,
  },
  {
    name: 'hand-fist',
    categories: [IconCategory.PEOPLE],
    tags: ['pointers', 'cursors', 'emoji', 'power', 'protest', 'blm'],
    Icon: Icons.HandFist,
  },
  {
    name: 'hand-grabbing',
    categories: [IconCategory.SYSTEM, IconCategory.PEOPLE],
    tags: ['pointers', 'cursors', 'emoji', 'drag', 'hold'],
    Icon: Icons.HandGrabbing,
  },
  // {
  //   name: "hand-ok",
  //   categories: [IconCategory.PEOPLE],
  //   tags: [ "pointers", "emoji", "good", "nice"],
  //   Icon: Icons.HandOk,
  // },
  {
    name: 'hand-palm',
    categories: [IconCategory.SYSTEM, IconCategory.PEOPLE],
    tags: [
      'pointers',
      'cursors',
      'emoji',
      'palm',
      'stop',
      'wait',
      'hamsa',
      '5',
    ],
    Icon: Icons.HandPalm,
  },
  // {
  //   name: "hand-peace",
  //   categories: [IconCategory.PEOPLE],
  //   tags: [
  //
  //     "pointers",
  //     "cursors",
  //     "emoji",
  //     "love",
  //     "hippies",
  //     "peace sign"
  //   ],
  //   Icon: Icons.HandPeace,
  // },
  {
    name: 'hand-pointing',
    categories: [IconCategory.SYSTEM, IconCategory.PEOPLE],
    tags: ['pointers', 'cursors', 'emoji', 'fingers', 'clicks', 'mouse'],
    Icon: Icons.HandPointing,
  },
  {
    name: 'hand-waving',
    categories: [IconCategory.SYSTEM, IconCategory.PEOPLE],
    tags: ['pointers', 'cursors', 'emoji', 'palm', 'wave', 'hello', 'goodbye'],
    Icon: Icons.HandWaving,
  },
  {
    name: 'hands-clapping',
    categories: [IconCategory.SYSTEM, IconCategory.PEOPLE],
    tags: ['*updated*', 'emoji', 'clap', 'applause'],
    Icon: Icons.HandsClapping,
  },
  {
    name: 'handshake',
    categories: [IconCategory.PEOPLE, IconCategory.COMMERCE],
    tags: ['pointers', 'cursors', 'emoji', 'deal', 'agreement'],
    Icon: Icons.Handshake,
  },
  {
    name: 'hand-soap',
    categories: [IconCategory.HEALTH],
    tags: ['dispenser', 'pump', 'sanitizer', 'disinfectant'],
    Icon: Icons.HandSoap,
  },
  {
    name: 'handbag',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['suitcases', 'valises', 'baggage', 'purses'],
    Icon: Icons.Handbag,
  },
  {
    name: 'handbag-simple',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['suitcases', 'valises', 'baggage', 'purses'],
    Icon: Icons.HandbagSimple,
  },
  {
    name: 'hard-drive',
    categories: [IconCategory.SYSTEM],
    tags: [
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'hard disk',
      'storage',
      'hdd',
      'servers',
      'databases',
    ],
    Icon: Icons.HardDrive,
  },
  {
    name: 'hard-drives',
    categories: [IconCategory.SYSTEM],
    tags: [
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'hard disk',
      'storage',
      'hdd',
      'servers',
      'databases',
    ],
    Icon: Icons.HardDrives,
  },
  {
    name: 'hash',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'hashtag',
      'octothorpe',
      'pound sign',
      'number sign',
      'tic-tac-toe',
      'symbol',
    ],
    Icon: Icons.Hash,
  },
  {
    name: 'hash-straight',
    categories: [IconCategory.COMMUNICATION],
    tags: [
      'hashtag',
      'octothorpe',
      'pound sign',
      'number sign',
      'tic-tac-toe',
      'symbol',
    ],
    Icon: Icons.HashStraight,
  },
  {
    name: 'headlights',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['brights', 'high beams'],
    Icon: Icons.Headlights,
  },
  {
    name: 'headphones',
    categories: [IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: ['music', 'audio', 'listening'],
    Icon: Icons.Headphones,
  },
  {
    name: 'headset',
    categories: [IconCategory.MEDIA, IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['music', 'audio', 'listening', 'gaming', 'voice chat', 'microphone'],
    Icon: Icons.Headset,
  },
  {
    name: 'heart',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.GAMES,
      IconCategory.HEALTH,
    ],
    tags: [
      'wellness',
      'love',
      'healthy',
      'like',
      'favorites',
      'favorited',
      'suits',
      'cards',
      'gambling',
      'casino',
      'gaming',
    ],
    Icon: Icons.Heart,
  },
  {
    name: 'heart-break',
    categories: [IconCategory.COMMUNICATION],
    tags: ['*new*', 'love', 'hate', 'crack', 'split', 'divorce', 'emoji'],
    Icon: Icons.HeartBreak,
  },
  {
    name: 'heart-straight',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.GAMES,
      IconCategory.HEALTH,
    ],
    tags: [
      'wellness',
      'love',
      'healthy',
      'like',
      'favorites',
      'favorited',
      'suits',
      'cards',
      'gambling',
      'casino',
      'gaming',
    ],
    Icon: Icons.HeartStraight,
  },
  {
    name: 'heart-straight-break',
    categories: [IconCategory.COMMUNICATION],
    tags: ['*new*', 'love', 'hate', 'crack', 'split', 'divorce', 'emoji'],
    Icon: Icons.HeartStraightBreak,
  },
  {
    name: 'heartbeat',
    categories: [IconCategory.HEALTH, IconCategory.SYSTEM],
    tags: [
      '*updated*',
      'wellness',
      'healthy',
      'ecg',
      'ekg',
      'vitals',
      'monitor',
    ],
    Icon: Icons.Heartbeat,
  },
  {
    name: 'hexagon',
    categories: [IconCategory.DESIGN],
    tags: ['6', 'shapes', 'polygons'],
    Icon: Icons.Hexagon,
  },
  {
    name: 'highlighter-circle',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['write', 'writing', 'editing', 'drawing'],
    Icon: Icons.HighlighterCircle,
  },
  {
    name: 'horse',
    categories: [IconCategory.GAMES, IconCategory.HEALTH, IconCategory.NATURE],
    tags: ['animals', 'equestrian', 'chess', 'knight', 'sports'],
    Icon: Icons.Horse,
  },
  // {
  //   name: "hospital",
  //   categories: [IconCategory.HEALTH, IconCategory.MAP],
  //   tags: [ "medical", "medicine", "injury", "safety", "emergency"],
  //   Icon: Icons.Hospital,
  // },
  {
    name: 'hourglass',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'times',
      'timer',
      'alarms',
      'clock',
      'schedule',
      'events',
      'waiting',
      'progress',
    ],
    Icon: Icons.Hourglass,
  },
  {
    name: 'hourglass-high',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'times',
      'timer',
      'alarms',
      'clock',
      'schedule',
      'events',
      'waiting',
      'progress',
    ],
    Icon: Icons.HourglassHigh,
  },
  {
    name: 'hourglass-medium',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'times',
      'timer',
      'alarms',
      'clock',
      'schedule',
      'events',
      'waiting',
      'progress',
    ],
    Icon: Icons.HourglassMedium,
  },
  {
    name: 'hourglass-low',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'times',
      'timer',
      'alarms',
      'clock',
      'schedule',
      'events',
      'waiting',
      'progress',
    ],
    Icon: Icons.HourglassLow,
  },
  {
    name: 'hourglass-simple',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'times',
      'timer',
      'alarms',
      'clock',
      'schedule',
      'events',
      'waiting',
      'progress',
    ],
    Icon: Icons.HourglassSimple,
  },
  {
    name: 'hourglass-simple-high',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'times',
      'timer',
      'alarms',
      'clock',
      'schedule',
      'events',
      'waiting',
      'progress',
    ],
    Icon: Icons.HourglassSimpleHigh,
  },
  {
    name: 'hourglass-simple-medium',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'times',
      'timer',
      'alarms',
      'clock',
      'schedule',
      'events',
      'waiting',
      'progress',
    ],
    Icon: Icons.HourglassSimpleMedium,
  },
  {
    name: 'hourglass-simple-low',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'times',
      'timer',
      'alarms',
      'clock',
      'schedule',
      'events',
      'waiting',
      'progress',
    ],
    Icon: Icons.HourglassSimpleLow,
  },
  {
    name: 'house',
    categories: [IconCategory.MAP, IconCategory.SYSTEM],
    tags: ['homes', 'buildings', 'places', 'locations'],
    Icon: Icons.House,
  },
  {
    name: 'house-line',
    categories: [IconCategory.MAP, IconCategory.SYSTEM],
    tags: ['homes', 'buildings', 'places', 'locations'],
    Icon: Icons.HouseLine,
  },
  {
    name: 'house-simple',
    categories: [IconCategory.MAP, IconCategory.SYSTEM],
    tags: ['homes', 'buildings', 'places', 'locations'],
    Icon: Icons.HouseSimple,
  },
  // {
  //   name: "ice-cubes",
  //   categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
  //   tags: [
  //
  //     "drinks",
  //     "beverages",
  //     "locations",
  //     "bars",
  //     "restaurants",
  //     "food",
  //     "dining",
  //   ],
  //   Icon: Icons.IceCubes,
  // },
  {
    name: 'identification-badge',
    categories: [IconCategory.PEOPLE],
    tags: ['license', 'credentials', 'nametag', 'user', 'verification'],
    Icon: Icons.IdentificationBadge,
  },
  {
    name: 'identification-card',
    categories: [IconCategory.PEOPLE],
    tags: [
      'license',
      'badge',
      'credentials',
      'nametag',
      'user',
      'verification',
    ],
    Icon: Icons.IdentificationCard,
  },
  {
    name: 'image',
    categories: [IconCategory.MEDIA],
    tags: [
      'pictures',
      'photographs',
      'photography',
      'wallpapers',
      'gallery',
      'landscape',
    ],
    Icon: Icons.Image,
  },
  {
    name: 'image-square',
    categories: [IconCategory.MEDIA],
    tags: [
      'pictures',
      'photographs',
      'photography',
      'wallpapers',
      'gallery',
      'landscape',
    ],
    Icon: Icons.ImageSquare,
  },
  {
    name: 'infinity',
    categories: [IconCategory.DEVELOPMENT, IconCategory.FINANCE],
    tags: [
      'infinite',
      'figure-eight',
      'mathematics',
      'arithmetic',
      'calculator',
      '∞',
    ],
    Icon: Icons.Infinity,
  },
  {
    name: 'info',
    categories: [IconCategory.SYSTEM],
    tags: ['information', 'help', 'support'],
    Icon: Icons.Info,
  },
  {
    name: 'instagram-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'social media', 'photography', 'camera'],
    Icon: Icons.InstagramLogo,
  },
  {
    name: 'intersect',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: [
      'round',
      'joining',
      'union',
      'merged',
      'merging',
      'intersecting',
      'intersection',
    ],
    Icon: Icons.Intersect,
  },
  {
    name: 'jeep',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      '*updated*',
      'vehicles',
      'automobile',
      'suv',
      'cars',
      'trucks',
      'wrangler',
      'off-road',
      'transit',
      'transportation',
      'traveling',
    ],
    Icon: Icons.Jeep,
  },
  {
    name: 'kanban',
    categories: [IconCategory.OFFICE],
    tags: [
      '*new*',
      'scheduling',
      'tasks',
      'project management',
      'process',
      'lean',
      'agile',
    ],
    Icon: Icons.Kanban,
  },
  {
    name: 'key',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.Key,
  },
  // {
  //   name: "key-escape",
  //   categories: [IconCategory.SYSTEM],
  //   tags: [ "keyboard", "exit", "back"],
  //   Icon: Icons.KeyEscape,
  // },
  {
    name: 'key-return',
    categories: [IconCategory.SYSTEM],
    tags: ['keyboard', 'enter'],
    Icon: Icons.KeyReturn,
  },
  {
    name: 'keyboard',
    categories: [IconCategory.SYSTEM],
    tags: ['typing', 'type', 'keys', 'input'],
    Icon: Icons.Keyboard,
  },
  {
    name: 'keyhole',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'lock',
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'encrypted',
      'encryption',
      'privacy',
      'private',
    ],
    Icon: Icons.Keyhole,
  },
  {
    name: 'knife',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: [
      'tools',
      'food',
      'meal',
      'eating',
      'restaurants',
      'dining',
      'utensils',
    ],
    Icon: Icons.Knife,
  },
  {
    name: 'ladder',
    categories: [IconCategory.OBJECTS],
    tags: ['*new*', 'stairs', 'steps', 'climbing'],
    Icon: Icons.Ladder,
  },
  {
    name: 'ladder-simple',
    categories: [IconCategory.OBJECTS],
    tags: ['*new*', 'stairs', 'steps', 'climbing'],
    Icon: Icons.LadderSimple,
  },
  {
    name: 'lamp',
    categories: [IconCategory.OBJECTS],
    tags: ['light', 'furniture', 'appliances'],
    Icon: Icons.Lamp,
  },
  {
    name: 'laptop',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OBJECTS],
    tags: ['computer', 'notebook', 'pc', 'macbook'],
    Icon: Icons.Laptop,
  },
  {
    name: 'layout',
    categories: [IconCategory.DESIGN],
    tags: ['wireframe', 'sidebar', 'ui', 'interface'],
    Icon: Icons.Layout,
  },
  {
    name: 'leaf',
    categories: [IconCategory.NATURE],
    tags: [
      'plants',
      'trees',
      'branches',
      'leaves',
      'nodes',
      'green',
      'environmental',
    ],
    Icon: Icons.Leaf,
  },
  {
    name: 'lifebuoy',
    categories: [
      IconCategory.HEALTH,
      IconCategory.OBJECTS,
      IconCategory.SYSTEM,
    ],
    tags: [
      'lifebelt',
      'lifesaver',
      'safety',
      'help',
      'support',
      'nautical',
      'boats',
      'ships',
    ],
    Icon: Icons.Lifebuoy,
  },
  {
    name: 'lightbulb',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: ['flashlight', 'bulbs', 'lighting', 'led', 'energy', 'idea'],
    Icon: Icons.Lightbulb,
  },
  {
    name: 'lightbulb-filament',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: ['flashlight', 'bulbs', 'lighting', 'led', 'energy', 'idea'],
    Icon: Icons.LightbulbFilament,
  },
  {
    name: 'lightning',
    categories: [IconCategory.WEATHER, IconCategory.SYSTEM],
    tags: [
      'meteorology',
      'stormy',
      'thunderstorm',
      'thunderbolt',
      'charged',
      'charger',
      'charging',
      'power',
      'electricity',
    ],
    Icon: Icons.Lightning,
  },
  {
    name: 'lightning-slash',
    categories: [IconCategory.SYSTEM],
    tags: [
      'thunderbolt',
      'charged',
      'charger',
      'charging',
      'power',
      'electricity',
      'disabled',
    ],
    Icon: Icons.LightningSlash,
  },
  {
    name: 'line-segment',
    categories: [IconCategory.DESIGN],
    tags: ['shapes', 'drawing', 'path', 'pen'],
    Icon: Icons.LineSegment,
  },
  {
    name: 'line-segments',
    categories: [IconCategory.DESIGN],
    tags: ['shapes', 'drawing', 'path', 'pen'],
    Icon: Icons.LineSegments,
  },
  {
    name: 'link',
    categories: [IconCategory.COMMUNICATION, IconCategory.OBJECTS],
    tags: ['anchor', 'hyperlink', 'hypertext', 'chains', 'chained'],
    Icon: Icons.Link,
  },
  {
    name: 'link-break',
    categories: [IconCategory.COMMUNICATION, IconCategory.OBJECTS],
    tags: [
      'anchor',
      'hyperlink',
      'hypertext',
      'chains',
      'chained',
      'errors',
      'broken',
    ],
    Icon: Icons.LinkBreak,
  },
  {
    name: 'link-simple',
    categories: [IconCategory.COMMUNICATION, IconCategory.OBJECTS],
    tags: ['anchor', 'hyperlink', 'hypertext', 'chains', 'chained'],
    Icon: Icons.LinkSimple,
  },
  {
    name: 'link-simple-break',
    categories: [IconCategory.COMMUNICATION, IconCategory.OBJECTS],
    tags: [
      'anchor',
      'hyperlink',
      'hypertext',
      'chains',
      'chained',
      'errors',
      'broken',
    ],
    Icon: Icons.LinkSimpleBreak,
  },
  {
    name: 'link-simple-horizontal',
    categories: [IconCategory.COMMUNICATION, IconCategory.OBJECTS],
    tags: ['anchor', 'hyperlink', 'hypertext', 'chains', 'chained'],
    Icon: Icons.LinkSimpleHorizontal,
  },
  {
    name: 'link-simple-horizontal-break',
    categories: [IconCategory.COMMUNICATION, IconCategory.OBJECTS],
    tags: [
      'anchor',
      'hyperlink',
      'hypertext',
      'chains',
      'chained',
      'errors',
      'broken',
    ],
    Icon: Icons.LinkSimpleHorizontalBreak,
  },
  {
    name: 'linkedin-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'jobs', 'employment', 'social media'],
    Icon: Icons.LinkedinLogo,
  },
  {
    name: 'linux-logo',
    categories: [IconCategory.BRAND, IconCategory.DEVELOPMENT],
    tags: ['*new*', 'penguin', 'computer', 'animals'],
    Icon: Icons.LinuxLogo,
  },
  {
    name: 'list',
    categories: [IconCategory.SYSTEM, IconCategory.EDITOR],
    tags: [
      'hamburger menu',
      'overflow menu',
      'sidebar',
      '3',
      'ul',
      'ol',
      'unordered list',
      'ordered list',
      'checklist',
    ],
    Icon: Icons.List,
  },
  {
    name: 'list-bullets',
    categories: [IconCategory.EDITOR],
    tags: ['ul', 'unordered list', 'bulleted list', 'checklist'],
    Icon: Icons.ListBullets,
  },
  {
    name: 'list-checks',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['*new*', 'checklist', 'todo'],
    Icon: Icons.ListChecks,
  },
  {
    name: 'list-dashes',
    categories: [IconCategory.EDITOR],
    tags: ['ul', 'unordered list', 'dashed list', 'checklist'],
    Icon: Icons.ListDashes,
  },
  {
    name: 'list-numbers',
    categories: [IconCategory.EDITOR],
    tags: ['ol', 'ordered list', 'numbered list', 'checklist'],
    Icon: Icons.ListNumbers,
  },
  {
    name: 'list-plus',
    categories: [IconCategory.EDITOR],
    tags: [
      'ul',
      'ol',
      'unordered list',
      'ordered list',
      'checklist',
      'add',
      '+',
    ],
    Icon: Icons.ListPlus,
  },
  {
    name: 'lock',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'encrypted',
      'encryption',
      'privacy',
      'private',
    ],
    Icon: Icons.Lock,
  },
  {
    name: 'lock-open',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'unsecured',
      'authentication',
      'unauthenticated',
      'login',
      'unlocked',
      'unencrypted',
      'encryption',
      'privacy',
    ],
    Icon: Icons.LockOpen,
  },
  {
    name: 'lock-key',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'encrypted',
      'encryption',
      'privacy',
      'private',
    ],
    Icon: Icons.LockKey,
  },
  {
    name: 'lock-key-open',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'unsecured',
      'authentication',
      'unauthenticated',
      'login',
      'unlocked',
      'unencrypted',
      'encryption',
      'privacy',
    ],
    Icon: Icons.LockKeyOpen,
  },
  {
    name: 'lock-laminated',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'encrypted',
      'encryption',
      'privacy',
      'private',
    ],
    Icon: Icons.LockLaminated,
  },
  {
    name: 'lock-laminated-open',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'unsecured',
      'authentication',
      'unauthenticated',
      'login',
      'unlocked',
      'unencrypted',
      'encryption',
      'privacy',
      'private',
    ],
    Icon: Icons.LockLaminatedOpen,
  },
  {
    name: 'lock-simple',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'encrypted',
      'encryption',
      'privacy',
      'private',
    ],
    Icon: Icons.LockSimple,
  },
  {
    name: 'lock-simple-open',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      'padlock',
      'security',
      'unsecured',
      'authentication',
      'unauthenticated',
      'login',
      'unlocked',
      'unencrypted',
      'encryption',
      'privacy',
      'private',
    ],
    Icon: Icons.LockSimpleOpen,
  },
  {
    name: 'magic-wand',
    categories: [IconCategory.DESIGN, IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['selection', 'wizard', 'games'],
    Icon: Icons.MagicWand,
  },
  {
    name: 'magnet',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OBJECTS],
    tags: ['magnetism', 'science', 'physics'],
    Icon: Icons.Magnet,
  },
  {
    name: 'magnet-straight',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OBJECTS],
    tags: ['magnetism', 'science', 'physics'],
    Icon: Icons.MagnetStraight,
  },
  {
    name: 'magnifying-glass',
    categories: [IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['search', 'find', 'locate', 'query', 'inspect'],
    Icon: Icons.MagnifyingGlass,
  },
  {
    name: 'magnifying-glass-plus',
    categories: [IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['search', 'find', 'locate', 'query', 'inspect', 'zoom in', '+'],
    Icon: Icons.MagnifyingGlassPlus,
  },
  {
    name: 'magnifying-glass-minus',
    categories: [IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['search', 'find', 'locate', 'query', 'inspect', 'zoom out', '-'],
    Icon: Icons.MagnifyingGlassMinus,
  },
  {
    name: 'map-pin',
    categories: [IconCategory.MAP],
    tags: ['maps', 'places', 'markers', 'pins', 'locations'],
    Icon: Icons.MapPin,
  },
  {
    name: 'map-pin-line',
    categories: [IconCategory.MAP],
    tags: ['maps', 'places', 'markers', 'pins', 'locations'],
    Icon: Icons.MapPinLine,
  },
  {
    name: 'map-trifold',
    categories: [IconCategory.MAP],
    tags: ['maps', 'places', 'locations', 'cartography', 'geography'],
    Icon: Icons.MapTrifold,
  },
  {
    name: 'marker-circle',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['write', 'writing', 'editing', 'drawing'],
    Icon: Icons.MarkerCircle,
  },
  {
    name: 'martini',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'glass',
      'drinks',
      'beverages',
      'cocktails',
      'places',
      'locations',
      'bars',
      'restaurants',
      'food',
      'dining',
    ],
    Icon: Icons.Martini,
  },
  {
    name: 'mask-happy',
    categories: [IconCategory.COMMUNICATION, IconCategory.GAMES],
    tags: ['*new*', 'theater', 'costume', 'smile', 'smiling', 'thalia'],
    Icon: Icons.MaskHappy,
  },
  {
    name: 'mask-sad',
    categories: [IconCategory.COMMUNICATION, IconCategory.GAMES],
    tags: ['*new*', 'theater', 'costume', 'cry', 'crying', 'melpomene'],
    Icon: Icons.MaskSad,
  },
  {
    name: 'math-operations',
    categories: [IconCategory.DEVELOPMENT, IconCategory.FINANCE],
    tags: [
      'addition',
      'sum',
      'subtraction',
      'difference',
      'multiply',
      'multiplication',
      'product',
      'divide',
      'division',
      'divisor',
      'dividend',
      'quotient',
      'equals',
      'equality',
      'mathematics',
      'arithmetic',
      'calculator',
      '+',
      '-',
      '±',
      '×',
      '÷',
      '=',
    ],
    Icon: Icons.MathOperations,
  },
  {
    name: 'medal',
    categories: [IconCategory.OBJECTS],
    tags: ['ribbons', 'winning', 'victory', 'awards'],
    Icon: Icons.Medal,
  },
  {
    name: 'medium-logo',
    categories: [IconCategory.BRAND],
    tags: ['logos', 'reading', 'writing', 'news', 'social media'],
    Icon: Icons.MediumLogo,
  },
  {
    name: 'megaphone',
    categories: [IconCategory.COMMUNICATION, IconCategory.OBJECTS],
    tags: ['bullhorn', 'announcements', 'loudspeaker', 'broadcast'],
    Icon: Icons.Megaphone,
  },
  {
    name: 'megaphone-simple',
    categories: [IconCategory.COMMUNICATION, IconCategory.OBJECTS],
    tags: ['bullhorn', 'announcements', 'loudspeaker', 'broadcast'],
    Icon: Icons.MegaphoneSimple,
  },
  {
    name: 'messenger-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'facebook', 'social media'],
    Icon: Icons.MessengerLogo,
  },
  {
    name: 'microphone',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MEDIA,
      IconCategory.SYSTEM,
    ],
    tags: ['audio', 'recording', 'music', 'sound', 'podcast', 'studio'],
    Icon: Icons.Microphone,
  },
  {
    name: 'microphone-slash',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MEDIA,
      IconCategory.SYSTEM,
    ],
    tags: [
      'audio',
      'recording',
      'music',
      'sound',
      'podcast',
      'studio',
      'muted',
      'disabled',
    ],
    Icon: Icons.MicrophoneSlash,
  },
  {
    name: 'microphone-stage',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MEDIA,
      IconCategory.SYSTEM,
    ],
    tags: [
      '*new*',
      'audio',
      'recording',
      'music',
      'sound',
      'performance',
      'concert',
    ],
    Icon: Icons.MicrophoneStage,
  },
  {
    name: 'microsoft-excel-logo',
    categories: [IconCategory.BRAND, IconCategory.OFFICE],
    tags: ['*new*', 'tables', 'spreadsheets', 'tabular'],
    Icon: Icons.MicrosoftExcelLogo,
  },
  {
    name: 'microsoft-powerpoint-logo',
    categories: [IconCategory.BRAND, IconCategory.OFFICE],
    tags: ['*new*', 'slides', 'slideshow', 'presentation'],
    Icon: Icons.MicrosoftPowerpointLogo,
  },
  {
    name: 'microsoft-teams-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['*new*', 'chat', 'video conference'],
    Icon: Icons.MicrosoftTeamsLogo,
  },
  {
    name: 'microsoft-word-logo',
    categories: [IconCategory.BRAND, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['*new*', 'documents', 'word processor', 'doc', 'docx'],
    Icon: Icons.MicrosoftWordLogo,
  },
  {
    name: 'minus',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.FINANCE,
      IconCategory.SYSTEM,
    ],
    tags: [
      '-',
      'subtraction',
      'difference',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.Minus,
  },
  {
    name: 'minus-circle',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.FINANCE,
      IconCategory.SYSTEM,
    ],
    tags: [
      '-',
      'subtraction',
      'difference',
      'mathematics',
      'arithmetic',
      'calculator',
      'round',
    ],
    Icon: Icons.MinusCircle,
  },
  {
    name: 'money',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['cash', 'dollars', 'paper bills', 'payment', 'paying', 'purchase'],
    Icon: Icons.Money,
  },
  {
    name: 'monitor',
    categories: [IconCategory.SYSTEM],
    tags: ['screen', 'television', 'tv', 'displays'],
    Icon: Icons.Monitor,
  },
  {
    name: 'monitor-play',
    categories: [IconCategory.SYSTEM, IconCategory.MEDIA],
    tags: [
      'screen',
      'television',
      'tv',
      'displays',
      'screencast',
      'video',
      'movie',
    ],
    Icon: Icons.MonitorPlay,
  },
  {
    name: 'moon',
    categories: [
      IconCategory.NATURE,
      IconCategory.SYSTEM,
      IconCategory.WEATHER,
    ],
    tags: [
      'night',
      'evening',
      'clear',
      'sleep',
      'snooze',
      'night mode',
      'dark mode',
      'astronomy',
      'stargazing',
    ],
    Icon: Icons.Moon,
  },
  {
    name: 'moon-stars',
    categories: [IconCategory.NATURE, IconCategory.WEATHER],
    tags: [
      'night',
      'evening',
      'clear',
      'sleep',
      'snooze',
      'night mode',
      'dark mode',
      'astronomy',
      'stargazing',
      'constellation',
    ],
    Icon: Icons.MoonStars,
  },
  {
    name: 'mountains',
    categories: [IconCategory.NATURE],
    tags: ['*new*', 'hills', 'outdoors', 'terrain', 'geology', 'adventure'],
    Icon: Icons.Mountains,
  },
  {
    name: 'mouse',
    categories: [IconCategory.SYSTEM],
    tags: ['clicks', 'input'],
    Icon: Icons.Mouse,
  },
  {
    name: 'mouse-simple',
    categories: [IconCategory.SYSTEM],
    tags: ['clicks', 'input'],
    Icon: Icons.MouseSimple,
  },
  {
    name: 'music-note',
    categories: [IconCategory.MEDIA],
    tags: ['songs', 'audio', 'playlist', 'albums'],
    Icon: Icons.MusicNote,
  },
  {
    name: 'music-notes',
    categories: [IconCategory.MEDIA],
    tags: ['songs', 'audio', 'playlist', 'albums'],
    Icon: Icons.MusicNotes,
  },
  {
    name: 'music-notes-plus',
    categories: [IconCategory.MEDIA],
    tags: ['*new*', 'songs', 'audio', 'playlist', 'albums', 'add'],
    Icon: Icons.MusicNotesPlus,
  },
  {
    name: 'music-note-simple',
    categories: [IconCategory.MEDIA],
    tags: ['songs', 'audio', 'playlist', 'albums'],
    Icon: Icons.MusicNoteSimple,
  },
  {
    name: 'music-notes-simple',
    categories: [IconCategory.MEDIA],
    tags: ['songs', 'audio', 'playlist', 'albums'],
    Icon: Icons.MusicNotesSimple,
  },
  {
    name: 'navigation-arrow',
    categories: [IconCategory.MAP],
    tags: ['location', 'directions', 'compass', 'gps'],
    Icon: Icons.NavigationArrow,
  },
  {
    name: 'needle',
    categories: [IconCategory.OBJECTS, IconCategory.COMMERCE],
    tags: ['*new*', 'sewing', 'thread', 'awl', 'tailor'],
    Icon: Icons.Needle,
  },
  {
    name: 'newspaper',
    categories: [IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: ['reading', 'writing', 'journals', 'periodicals'],
    Icon: Icons.Newspaper,
  },
  {
    name: 'newspaper-clipping',
    categories: [IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: ['reading', 'writing', 'journals', 'periodicals'],
    Icon: Icons.NewspaperClipping,
  },
  {
    name: 'note',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['notes', 'note-taking', 'memorandum', 'post-it', 'reminders'],
    Icon: Icons.Note,
  },
  {
    name: 'note-blank',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['notes', 'note-taking', 'memorandum', 'post-it', 'reminders'],
    Icon: Icons.NoteBlank,
  },
  {
    name: 'note-pencil',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['notes', 'note-taking', 'memorandum', 'post-it', 'reminders'],
    Icon: Icons.NotePencil,
  },
  {
    name: 'notebook',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: [
      'notes',
      'note-taking',
      'memorandum',
      'journal',
      'diary',
      'logs',
      'logbook',
    ],
    Icon: Icons.Notebook,
  },
  {
    name: 'notepad',
    categories: [IconCategory.OFFICE, IconCategory.EDITOR],
    tags: [
      'logs',
      'logbook',
      'notes',
      'note-taking',
      'memorandum',
      'journal',
      'diary',
    ],
    Icon: Icons.Notepad,
  },
  {
    name: 'notification',
    categories: [IconCategory.SYSTEM],
    tags: ['badge', 'pip'],
    Icon: Icons.Notification,
  },
  {
    name: 'number-zero',
    categories: [IconCategory.FINANCE],
    tags: [
      '0',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberZero,
  },

  {
    name: 'number-one',
    categories: [IconCategory.FINANCE],
    tags: [
      '1',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberOne,
  },

  {
    name: 'number-two',
    categories: [IconCategory.FINANCE],
    tags: [
      '2',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberTwo,
  },

  {
    name: 'number-three',
    categories: [IconCategory.FINANCE],
    tags: [
      '3',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberThree,
  },

  {
    name: 'number-four',
    categories: [IconCategory.FINANCE],
    tags: [
      '4',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberFour,
  },

  {
    name: 'number-five',
    categories: [IconCategory.FINANCE],
    tags: [
      '5',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberFive,
  },

  {
    name: 'number-six',
    categories: [IconCategory.FINANCE],
    tags: [
      '6',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSix,
  },

  {
    name: 'number-seven',
    categories: [IconCategory.FINANCE],
    tags: [
      '7',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSeven,
  },

  {
    name: 'number-eight',
    categories: [IconCategory.FINANCE],
    tags: [
      '8',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberEight,
  },

  {
    name: 'number-nine',
    categories: [IconCategory.FINANCE],
    tags: [
      '9',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberNine,
  },
  {
    name: 'number-circle-zero',
    categories: [IconCategory.FINANCE],
    tags: [
      '0',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleZero,
  },
  {
    name: 'number-circle-one',
    categories: [IconCategory.FINANCE],
    tags: [
      '1',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleOne,
  },
  {
    name: 'number-circle-two',
    categories: [IconCategory.FINANCE],
    tags: [
      '2',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleTwo,
  },
  {
    name: 'number-circle-three',
    categories: [IconCategory.FINANCE],
    tags: [
      '3',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleThree,
  },
  {
    name: 'number-circle-four',
    categories: [IconCategory.FINANCE],
    tags: [
      '4',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleFour,
  },
  {
    name: 'number-circle-five',
    categories: [IconCategory.FINANCE],
    tags: [
      '5',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleFive,
  },
  {
    name: 'number-circle-six',
    categories: [IconCategory.FINANCE],
    tags: [
      '6',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleSix,
  },
  {
    name: 'number-circle-seven',
    categories: [IconCategory.FINANCE],
    tags: [
      '7',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleSeven,
  },
  {
    name: 'number-circle-eight',
    categories: [IconCategory.FINANCE],
    tags: [
      '8',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleEight,
  },
  {
    name: 'number-circle-nine',
    categories: [IconCategory.FINANCE],
    tags: [
      '9',
      'round',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberCircleNine,
  },
  {
    name: 'number-square-zero',
    categories: [IconCategory.FINANCE],
    tags: [
      '0',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareZero,
  },
  {
    name: 'number-square-one',
    categories: [IconCategory.FINANCE],
    tags: [
      '1',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareOne,
  },
  {
    name: 'number-square-two',
    categories: [IconCategory.FINANCE],
    tags: [
      '2',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareTwo,
  },
  {
    name: 'number-square-three',
    categories: [IconCategory.FINANCE],
    tags: [
      '3',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareThree,
  },
  {
    name: 'number-square-four',
    categories: [IconCategory.FINANCE],
    tags: [
      '4',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareFour,
  },
  {
    name: 'number-square-five',
    categories: [IconCategory.FINANCE],
    tags: [
      '5',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareFive,
  },
  {
    name: 'number-square-six',
    categories: [IconCategory.FINANCE],
    tags: [
      '6',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareSix,
  },
  {
    name: 'number-square-seven',
    categories: [IconCategory.FINANCE],
    tags: [
      '7',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareSeven,
  },
  {
    name: 'number-square-eight',
    categories: [IconCategory.FINANCE],
    tags: [
      '8',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareEight,
  },
  {
    name: 'number-square-nine',
    categories: [IconCategory.FINANCE],
    tags: [
      '9',
      'numbers',
      'numerals',
      'digits',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.NumberSquareNine,
  },
  {
    name: 'nut',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: ['bolts', 'screws', 'machinery', 'tools', 'hexagon'],
    Icon: Icons.Nut,
  },
  {
    name: 'ny-times-logo',
    categories: [IconCategory.BRAND],
    tags: [
      'nyt',
      'new york times',

      'logos',
      'reading',
      'writing',
      'news',
      'newspaper',
    ],
    Icon: Icons.NyTimesLogo,
  },
  {
    name: 'octagon',
    categories: [IconCategory.DESIGN],
    tags: ['8', 'shapes', 'polygons'],
    Icon: Icons.Octagon,
  },
  {
    name: 'option',
    categories: [IconCategory.SYSTEM, IconCategory.EDITOR],
    tags: ['*new*', 'keyboard', 'shortcut', 'modifier'],
    Icon: Icons.Option,
  },
  {
    name: 'package',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OBJECTS],
    tags: [
      'packages',
      'boxes',
      'delivery',
      'mail',
      'postal service',
      'bundles',
      'library',
      'libraries',
      'shipping',
    ],
    Icon: Icons.Package,
  },
  {
    name: 'paint-brush',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['colors', 'color picker', 'arts'],
    Icon: Icons.PaintBrush,
  },
  {
    name: 'paint-brush-household',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['colors', 'color picker', 'arts'],
    Icon: Icons.PaintBrushHousehold,
  },
  {
    name: 'paint-brush-broad',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['fill', 'colors', 'color picker', 'arts'],
    Icon: Icons.PaintBrushBroad,
  },
  {
    name: 'paint-bucket',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['paintbucket', 'colors', 'color picker', 'fill', 'arts'],
    Icon: Icons.PaintBucket,
  },
  {
    name: 'paint-roller',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['colors', 'color picker', 'fill', 'arts', 'theme'],
    Icon: Icons.PaintRoller,
  },
  {
    name: 'palette',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['paint', 'colors', 'color picker', 'arts'],
    Icon: Icons.Palette,
  },
  {
    name: 'paper-plane',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MAP,
      IconCategory.OBJECTS,
    ],
    tags: [
      '*updated*',
      'mail',
      'email',
      'send',
      'sent',
      'messages',
      'messaging',
      'toys',
      'games',
    ],
    Icon: Icons.PaperPlane,
  },
  {
    name: 'paper-plane-right',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MAP,
      IconCategory.OBJECTS,
    ],
    tags: [
      '*updated*',
      'mail',
      'email',
      'send',
      'sent',
      'messages',
      'messaging',
      'toys',
      'games',
    ],
    Icon: Icons.PaperPlaneRight,
  },
  {
    name: 'paper-plane-tilt',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MAP,
      IconCategory.OBJECTS,
    ],
    tags: [
      '*updated*',
      'mail',
      'email',
      'send',
      'sent',
      'messages',
      'messaging',
      'toys',
      'games',
    ],
    Icon: Icons.PaperPlaneTilt,
  },
  {
    name: 'paperclip',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.EDITOR,
      IconCategory.OFFICE,
      IconCategory.OBJECTS,
    ],
    tags: ['attachments', 'mail', 'email', 'office'],
    Icon: Icons.Paperclip,
  },
  {
    name: 'paperclip-horizontal',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.EDITOR,
      IconCategory.OFFICE,
      IconCategory.OBJECTS,
    ],
    tags: ['attachments', 'mail', 'email', 'office'],
    Icon: Icons.PaperclipHorizontal,
  },
  {
    name: 'parachute',
    categories: [IconCategory.OBJECTS, IconCategory.DEVELOPMENT],
    tags: ['*new*', 'skydiving', 'safety'],
    Icon: Icons.Parachute,
  },
  // {
  //   name: "party-hat",
  //   categories: [IconCategory.OBJECT],
  //   tags: [ "birthday", "celebration", "event"],
  //   Icon: Icons.PartyHat,
  // },
  {
    name: 'password',
    categories: [IconCategory.SYSTEM],
    tags: [
      '*new*',
      'security',
      'secured',
      'authentication',
      'authenticated',
      'login',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.Password,
  },
  {
    name: 'path',
    categories: [IconCategory.DESIGN, IconCategory.MAP],
    tags: [
      'transit',
      'travel',
      'trail',
      'gps',
      'navigation',
      'route',
      'destination',
    ],
    Icon: Icons.Path,
  },
  {
    name: 'pause',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'resume', 'start', 'stop'],
    Icon: Icons.Pause,
  },
  {
    name: 'pause-circle',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'resume', 'start', 'stop', 'round'],
    Icon: Icons.PauseCircle,
  },
  {
    name: 'paw-print',
    categories: [
      IconCategory.NATURE,
      IconCategory.COMMERCE,
      IconCategory.HEALTH,
    ],
    tags: [
      'pets',
      'pet store',
      'pet shop',
      'animals',
      'cat',
      'dog',
      'veterinarian',
    ],
    Icon: Icons.PawPrint,
  },
  {
    name: 'peace',
    categories: [IconCategory.COMMUNICATION],
    tags: ['love', 'hippies', 'peace sign', 'symbols'],
    Icon: Icons.Peace,
  },
  {
    name: 'person',
    categories: [IconCategory.MAP, IconCategory.PEOPLE],
    tags: [
      'walking',
      'human',
      'woman',
      'man',
      'body',
      'transit',
      'transportation',
      'travel',
      'commuter',
      'user',
    ],
    Icon: Icons.Person,
  },
  {
    name: 'person-simple',
    categories: [IconCategory.MAP, IconCategory.PEOPLE, IconCategory.HEALTH],
    tags: [
      'pedestrian',
      'walking',
      'human',
      'woman',
      'man',
      'body',
      'transit',
      'transportation',
      'travel',
      'commuter',
      'user',
      'exercise',
    ],
    Icon: Icons.PersonSimple,
  },
  {
    name: 'person-simple-walk',
    categories: [IconCategory.MAP, IconCategory.PEOPLE, IconCategory.HEALTH],
    tags: [
      'pedestrian',
      'walking',
      'human',
      'woman',
      'man',
      'body',
      'transit',
      'transportation',
      'travel',
      'commuter',
      'user',
      'exercise',
    ],
    Icon: Icons.PersonSimpleWalk,
  },
  {
    name: 'person-simple-run',
    categories: [IconCategory.MAP, IconCategory.PEOPLE, IconCategory.HEALTH],
    tags: [
      'pedestrian',
      'running',
      'human',
      'woman',
      'man',
      'body',
      'transit',
      'transportation',
      'travel',
      'commuter',
      'user',
      'exercise',
    ],
    Icon: Icons.PersonSimpleRun,
  },
  {
    name: 'pen',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['ink', 'write', 'writing', 'editing', 'sign', 'signature'],
    Icon: Icons.Pen,
  },
  {
    name: 'pen-nib',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: [
      'ink',
      'write',
      'writing',
      'editing',
      'sign',
      'signature',
      'fountain pen',
      'illustrator',
    ],
    Icon: Icons.PenNib,
  },
  {
    name: 'pen-nib-straight',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: [
      'ink',
      'write',
      'writing',
      'editing',
      'sign',
      'signature',
      'fountain pen',
      'illustrator',
    ],
    Icon: Icons.PenNibStraight,
  },
  {
    name: 'pencil',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['*updated*', 'write', 'writing', 'editing', 'sign', 'signature'],
    Icon: Icons.Pencil,
  },
  {
    name: 'pencil-circle',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['write', 'writing', 'editing', 'sign', 'signature'],
    Icon: Icons.PencilCircle,
  },
  {
    name: 'pencil-line',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['*updated*', 'write', 'writing', 'editing', 'sign', 'signature'],
    Icon: Icons.PencilLine,
  },
  {
    name: 'pencil-simple',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['*updated*', 'write', 'writing', 'editing', 'sign', 'signature'],
    Icon: Icons.PencilSimple,
  },
  {
    name: 'pencil-simple-line',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['*new*', 'write', 'writing', 'editing', 'sign', 'signature'],
    Icon: Icons.PencilSimpleLine,
  },
  {
    name: 'percent',
    categories: [IconCategory.DEVELOPMENT, IconCategory.FINANCE],
    tags: [
      '%',
      'percentage',
      'percentile',
      'ratio',
      'delta',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.Percent,
  },
  {
    name: 'perspective',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', '3d', 'skew', 'warp', 'trapezoid'],
    Icon: Icons.Perspective,
  },
  {
    name: 'phone',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['calls', 'telephone', 'landline', 'talk'],
    Icon: Icons.Phone,
  },
  {
    name: 'phone-call',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['calls', 'calling', 'ringing', 'telephone', 'landline', 'talk'],
    Icon: Icons.PhoneCall,
  },
  {
    name: 'phone-disconnect',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['calls', 'hang up', 'disconnected', 'telephone', 'landline', 'talk'],
    Icon: Icons.PhoneDisconnect,
  },
  {
    name: 'phone-incoming',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['calls', 'calling', 'telephone', 'landline', 'talk'],
    Icon: Icons.PhoneIncoming,
  },
  {
    name: 'phone-outgoing',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['calls', 'calling', 'telephone', 'landline', 'talk'],
    Icon: Icons.PhoneOutgoing,
  },
  {
    name: 'phone-slash',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: [
      'calls',
      'disabled',
      'disconnected',
      'telephone',
      'landline',
      'talk',
    ],
    Icon: Icons.PhoneSlash,
  },
  {
    name: 'phone-x',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['calls', 'missed', 'errors', 'telephone', 'landline', 'talk'],
    Icon: Icons.PhoneX,
  },
  {
    name: 'phosphor-logo',
    categories: [IconCategory.BRAND],
    tags: ['logos'],
    Icon: Icons.PhosphorLogo,
  },
  {
    name: 'piano-keys',
    categories: [IconCategory.MEDIA, IconCategory.OBJECTS],
    tags: ['*new*', 'music', 'instrument', 'keyboard'],
    Icon: Icons.PianoKeys,
  },
  {
    name: 'picture-in-picture',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['pip', 'pop-out', 'minimize', 'maximize'],
    Icon: Icons.PictureInPicture,
  },
  {
    name: 'pill',
    categories: [IconCategory.HEALTH],
    tags: [
      'capsule',
      'medicine',
      'rx',
      'pharmacy',
      'pharmacist',
      'pharmaceuticals',
      'prescription',
      'drugs',
    ],
    Icon: Icons.Pill,
  },
  {
    name: 'pinterest-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['*updated*', 'logos', 'vision board', 'mood board', 'social media'],
    Icon: Icons.PinterestLogo,
  },
  {
    name: 'pinwheel',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['*new*', 'toys', 'whirligig'],
    Icon: Icons.Pinwheel,
  },
  {
    name: 'pizza',
    categories: [IconCategory.COMMERCE, IconCategory.MAP],
    tags: [
      'fast food',
      'party',
      'places',
      'locations',
      'restaurants',
      'food',
      'dining',
    ],
    Icon: Icons.Pizza,
  },
  {
    name: 'placeholder',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['empty', 'notdef', 'tofu', 'rectangle slash', 'missing'],
    Icon: Icons.Placeholder,
  },
  {
    name: 'planet',
    categories: [IconCategory.NATURE],
    tags: ['saturn', 'world', 'globe', 'astronomy', 'space'],
    Icon: Icons.Planet,
  },
  {
    name: 'play',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'resume', 'start'],
    Icon: Icons.Play,
  },
  {
    name: 'play-circle',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'resume', 'start', 'round'],
    Icon: Icons.PlayCircle,
  },
  {
    name: 'playlist',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'queue'],
    Icon: Icons.Playlist,
  },
  {
    name: 'plug',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: ['*new*', 'outlet', 'socket', 'plugin', 'integration'],
    Icon: Icons.Plug,
  },
  {
    name: 'plugs',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      '*new*',
      'outlet',
      'socket',
      'plugin',
      'integration',
      'disconnected',
    ],
    Icon: Icons.Plugs,
  },
  {
    name: 'plugs-connected',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: ['*new*', 'outlet', 'socket', 'plugin', 'integration'],
    Icon: Icons.PlugsConnected,
  },
  {
    name: 'plus',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.FINANCE,
      IconCategory.SYSTEM,
    ],
    tags: ['addition', 'sum', 'mathematics', 'arithmetic', 'calculator', '+'],
    Icon: Icons.Plus,
  },
  {
    name: 'plus-circle',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.FINANCE,
      IconCategory.SYSTEM,
    ],
    tags: [
      'addition',
      'sum',
      'mathematics',
      'arithmetic',
      'calculator',
      'round',
      '+',
    ],
    Icon: Icons.PlusCircle,
  },
  {
    name: 'plus-minus',
    categories: [IconCategory.DEVELOPMENT, IconCategory.FINANCE],
    tags: [
      'plus or minus',
      'plus/minus',
      'add/subtract',
      'addition',
      'sum',
      'subtraction',
      'difference',
      'mathematics',
      'arithmetic',
      'calculator',
      '+',
      '-',
      '±',
    ],
    Icon: Icons.PlusMinus,
  },
  {
    name: 'poker-chip',
    categories: [IconCategory.GAMES],
    tags: ['chips', 'tokens', 'cards', 'gambling', 'casino'],
    Icon: Icons.PokerChip,
  },
  // {
  //   name: "police",
  //   categories: [IconCategory.MAP, IconCategory.PEOPLE],
  //   tags: [ "law enforcement", "emergency", "safety", "security"],
  //   Icon: Icons.Police,
  // },
  {
    name: 'police-car',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['*new*', 'vehicles', 'cars', 'automobiles', 'law enforcement'],
    Icon: Icons.PoliceCar,
  },
  {
    name: 'polygon',
    categories: [IconCategory.DESIGN],
    tags: ['shapes', 'drawing'],
    Icon: Icons.Polygon,
  },
  {
    name: 'popcorn',
    categories: [IconCategory.MAP, IconCategory.COMMERCE],
    tags: ['*new*', 'food', 'movies', 'theater'],
    Icon: Icons.Popcorn,
  },
  {
    name: 'power',
    categories: [IconCategory.SYSTEM],
    tags: [
      'charged',
      'charger',
      'charging',
      'on',
      'off',
      'on/off',
      'switch',
      'power switch',
    ],
    Icon: Icons.Power,
  },
  {
    name: 'prescription',
    categories: [IconCategory.HEALTH],
    tags: [
      'rx',
      'medicine',
      'drugs',
      'pharmacy',
      'pharmacist',
      'pharmaceuticals',
      'doctor',
    ],
    Icon: Icons.Prescription,
  },
  {
    name: 'presentation',
    categories: [IconCategory.FINANCE, IconCategory.OFFICE],
    tags: [
      'whiteboard',
      'flipchart',
      'charts',
      'statistics',
      'analyze',
      'analysis',
      'meeting',
    ],
    Icon: Icons.Presentation,
  },
  {
    name: 'presentation-chart',
    categories: [IconCategory.FINANCE, IconCategory.OFFICE],
    tags: [
      'whiteboard',
      'flipchart',
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'analyze',
      'analysis',
      'meeting',
    ],
    Icon: Icons.PresentationChart,
  },
  {
    name: 'printer',
    categories: [IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['printing'],
    Icon: Icons.Printer,
  },
  {
    name: 'prohibit',
    categories: [IconCategory.MAP, IconCategory.SYSTEM],
    tags: [
      'forbidden',
      'prohibited',
      'cancelled',
      'prevent',
      'stop',
      'do not enter',
    ],
    Icon: Icons.Prohibit,
  },
  {
    name: 'prohibit-inset',
    categories: [IconCategory.MAP, IconCategory.SYSTEM],
    tags: [
      'forbidden',
      'prohibited',
      'cancelled',
      'prevent',
      'stop',
      'do not enter',
    ],
    Icon: Icons.ProhibitInset,
  },
  {
    name: 'projector-screen',
    categories: [IconCategory.FINANCE, IconCategory.MEDIA, IconCategory.OFFICE],
    tags: [
      'projection',
      'presentation',
      'slideshow',
      'movies',
      'charts',
      'statistics',
      'analyze',
      'analysis',
    ],
    Icon: Icons.ProjectorScreen,
  },
  {
    name: 'projector-screen-chart',
    categories: [IconCategory.FINANCE, IconCategory.OFFICE],
    tags: [
      'projection',
      'presentation',
      'slideshow',
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'analyze',
      'analysis',
    ],
    Icon: Icons.ProjectorScreenChart,
  },
  {
    name: 'push-pin',
    categories: [IconCategory.OFFICE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['favorites', 'favorited', 'pushpin'],
    Icon: Icons.PushPin,
  },
  {
    name: 'push-pin-slash',
    categories: [IconCategory.OFFICE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['favorites', 'favorited', 'pushpin', 'disabled'],
    Icon: Icons.PushPinSlash,
  },
  {
    name: 'push-pin-simple',
    categories: [IconCategory.OFFICE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['favorites', 'favorited', 'pushpin'],
    Icon: Icons.PushPinSimple,
  },
  {
    name: 'push-pin-simple-slash',
    categories: [IconCategory.OFFICE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['favorites', 'favorited', 'pushpin', 'disabled'],
    Icon: Icons.PushPinSimpleSlash,
  },
  {
    name: 'puzzle-piece',
    categories: [IconCategory.GAMES, IconCategory.DEVELOPMENT],
    tags: ['board game', 'element', 'component', 'extension', 'plugin'],
    Icon: Icons.PuzzlePiece,
  },
  {
    name: 'qr-code',
    categories: [IconCategory.SYSTEM],
    tags: ['upc', 'barcode', 'products', 'shopping', 'scanner'],
    Icon: Icons.QrCode,
  },
  {
    name: 'question',
    categories: [IconCategory.SYSTEM],
    tags: ['information', 'help', 'support', 'questions'],
    Icon: Icons.Question,
  },
  {
    name: 'queue',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'playlist'],
    Icon: Icons.Queue,
  },
  {
    name: 'quotes',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.EDITOR,
      IconCategory.MEDIA,
    ],
    tags: ['quoations', 'quotation marks', 'double-quotes', 'writing', 'books'],
    Icon: Icons.Quotes,
  },
  {
    name: 'radical',
    categories: [IconCategory.DEVELOPMENT, IconCategory.FINANCE],
    tags: [
      '√',
      'radix',
      'radicand',
      'square root',
      'squareroot',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.Radical,
  },
  {
    name: 'radio',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MEDIA,
      IconCategory.OBJECTS,
    ],
    tags: ['broadcast', 'fm', 'am', 'xm', 'transmitter', 'receiver'],
    Icon: Icons.Radio,
  },
  {
    name: 'radio-button',
    categories: [IconCategory.SYSTEM],
    tags: ['*new*', 'input', 'checkbox', 'checked'],
    Icon: Icons.RadioButton,
  },
  {
    name: 'rainbow',
    categories: [IconCategory.WEATHER],
    tags: ['meteorology', 'rainstorm', 'arc', 'pride', 'LGBTQ+', 'leprechaun'],
    Icon: Icons.Rainbow,
  },
  {
    name: 'rainbow-cloud',
    categories: [IconCategory.WEATHER],
    tags: [
      'meteorology',
      'rainstorm',
      'cloudy',
      'partly cloudy',
      'partly sunny',
      'pride',
      'LGBTQ+',
      'leprechaun',
    ],
    Icon: Icons.RainbowCloud,
  },
  {
    name: 'receipt',
    categories: [IconCategory.COMMERCE, IconCategory.FINANCE],
    tags: ['purchased', 'money', 'clipping', 'expenses'],
    Icon: Icons.Receipt,
  },
  {
    name: 'record',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'recording', 'recorder', 'voice memo'],
    Icon: Icons.Record,
  },
  {
    name: 'rectangle',
    categories: [IconCategory.DESIGN],
    tags: ['4', 'shapes', 'polygons', 'box'],
    Icon: Icons.Rectangle,
  },
  {
    name: 'recycle',
    categories: [IconCategory.ARROWS, IconCategory.NATURE],
    tags: ['recycling', 'trash', 'environmental', 'green'],
    Icon: Icons.Recycle,
  },
  {
    name: 'reddit-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'subreddit', 'snoo', 'social media'],
    Icon: Icons.RedditLogo,
  },
  {
    name: 'repeat',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'recycle'],
    Icon: Icons.Repeat,
  },
  {
    name: 'repeat-once',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'recycle'],
    Icon: Icons.RepeatOnce,
  },
  {
    name: 'rewind',
    categories: [IconCategory.MEDIA],
    tags: [
      'music',
      'audio',
      'seek',
      'scrub',
      'scan',
      'skip',
      'back',
      'backwards',
      'reverse',
    ],
    Icon: Icons.Rewind,
  },
  {
    name: 'rewind-circle',
    categories: [IconCategory.MEDIA],
    tags: [
      'music',
      'audio',
      'seek',
      'scrub',
      'scan',
      'skip',
      'back',
      'backwards',
      'reverse',
    ],
    Icon: Icons.RewindCircle,
  },
  {
    name: 'robot',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OBJECTS],
    tags: ['automaton', 'artificial intelligence'],
    Icon: Icons.Robot,
  },
  {
    name: 'rocket',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.MAP,
      IconCategory.OBJECTS,
    ],
    tags: ['spaceship', 'launch', 'deployment', 'rocketship'],
    Icon: Icons.Rocket,
  },
  {
    name: 'rocket-launch',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.MAP,
      IconCategory.OBJECTS,
    ],
    tags: ['spaceship', 'flying', 'blastoff', 'deployment', 'rocketship'],
    Icon: Icons.RocketLaunch,
  },
  {
    name: 'rows',
    categories: [IconCategory.DESIGN],
    tags: ['2', 'shapes', 'polygons', 'box', 'stack', 'list', 'table', 'cards'],
    Icon: Icons.Rows,
  },
  {
    name: 'rss',
    categories: [IconCategory.COMMUNICATION],
    tags: ['radio', 'broadcast', 'web feed', 'news', 'aggregator'],
    Icon: Icons.Rss,
  },
  {
    name: 'rss-simple',
    categories: [IconCategory.COMMUNICATION],
    tags: ['radio', 'broadcast', 'web feed', 'news', 'aggregator'],
    Icon: Icons.RssSimple,
  },
  {
    name: 'rug',
    categories: [IconCategory.OBJECTS],
    tags: ['*new*', 'tapestry', 'carpet'],
    Icon: Icons.Rug,
  },
  {
    name: 'ruler',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['*updated*', 'measure', 'scale', 'distance'],
    Icon: Icons.Ruler,
  },
  {
    name: 'scales',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['measure', 'balance', 'law', 'justice', 'government'],
    Icon: Icons.Scales,
  },
  // {
  //   name: "scalpel",
  //   categories: [IconCategory.DESIGN, IconCategory.HEALTH],
  //   tags: [ "x-acto", "hobby knife", "craft knife", "razor", "slice"],
  //   Icon: Icons.Scalpel,
  // },
  {
    name: 'scan',
    categories: [IconCategory.SYSTEM],
    tags: ['*new*', 'upc', 'barcode', 'products', 'shopping', 'scanner'],
    Icon: Icons.Scan,
  },
  {
    name: 'scissors',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OFFICE,
      IconCategory.SYSTEM,
    ],
    tags: ['cut', 'snip', 'clipboard'],
    Icon: Icons.Scissors,
  },
  {
    name: 'screencast',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: [
      'apple',
      'airplay',
      'screencasting',
      'screen share',
      'television',
      'tv',
    ],
    Icon: Icons.Screencast,
  },
  {
    name: 'scribble-loop',
    categories: [IconCategory.DESIGN],
    tags: ['doodles', 'drawing', 'sign', 'signature'],
    Icon: Icons.ScribbleLoop,
  },
  {
    name: 'scroll',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['*new*', 'parchment', 'paper', 'script', 'spell', 'fantasy'],
    Icon: Icons.Scroll,
  },
  {
    name: 'selection',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['marquis', 'select'],
    Icon: Icons.Selection,
  },
  {
    name: 'selection-all',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['marquis', 'select all'],
    Icon: Icons.SelectionAll,
  },
  {
    name: 'selection-inverse',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['marquis', 'invert'],
    Icon: Icons.SelectionInverse,
  },
  {
    name: 'selection-plus',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['marquis', 'add'],
    Icon: Icons.SelectionPlus,
  },
  {
    name: 'selection-slash',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['marquis', 'unselect'],
    Icon: Icons.SelectionSlash,
  },
  {
    name: 'selection-foreground',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['marquis'],
    Icon: Icons.SelectionForeground,
  },
  {
    name: 'selection-background',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['marquis'],
    Icon: Icons.SelectionBackground,
  },
  {
    name: 'share',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['send to', 'export', 'arrows'],
    Icon: Icons.Share,
  },
  {
    name: 'share-network',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['send to', 'export'],
    Icon: Icons.ShareNetwork,
  },
  {
    name: 'shield',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'security',
      'secured',
      'defense',
      'defended',
      'authentication',
      'authenticated',
      'guarded',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.Shield,
  },
  {
    name: 'shield-check',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'security',
      'secured',
      'defense',
      'defended',
      'authentication',
      'authenticated',
      'guarded',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.ShieldCheck,
  },
  {
    name: 'shield-checkered',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'security',
      'secured',
      'defense',
      'defended',
      'authentication',
      'authenticated',
      'guarded',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.ShieldCheckered,
  },
  {
    name: 'shield-chevron',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'security',
      'secured',
      'defense',
      'defended',
      'authentication',
      'authenticated',
      'guarded',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.ShieldChevron,
  },
  {
    name: 'shield-plus',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'security',
      'secured',
      'defense',
      'defended',
      'authentication',
      'authenticated',
      'guarded',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.ShieldPlus,
  },
  {
    name: 'shield-slash',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'security',
      'unsecured',
      'defense',
      'undefended',
      'authentication',
      'unauthenticated',
      'unguarded',
      'unlocked',
      'unencrypted',
      'encryption',
      'disabled',
    ],
    Icon: Icons.ShieldSlash,
  },
  {
    name: 'shield-star',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: [
      '*new*',
      'badge',
      'security',
      'defense',
      'authentication',
      'authenticated',
      'guarded',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.ShieldStar,
  },
  {
    name: 'shield-warning',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'security',
      'unsecured',
      'defense',
      'undefended',
      'authentication',
      'unauthenticated',
      'unguarded',
      'unlocked',
      'unencrypted',
      'encryption',
      'errors',
    ],
    Icon: Icons.ShieldWarning,
  },
  {
    name: 'shopping-bag',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'ecommerce',
      'market',
      'basket',
      'buying',
      'checkout',
      'places',
      'locations',
    ],
    Icon: Icons.ShoppingBag,
  },
  {
    name: 'shopping-bag-open',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'ecommerce',
      'market',
      'basket',
      'buying',
      'checkout',
      'places',
      'locations',
    ],
    Icon: Icons.ShoppingBagOpen,
  },
  {
    name: 'shopping-cart',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'ecommerce',
      'market',
      'basket',
      'buying',
      'groceries',
      'checkout',
      'places',
      'locations',
    ],
    Icon: Icons.ShoppingCart,
  },
  {
    name: 'shopping-cart-simple',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'ecommerce',
      'market',
      'basket',
      'buying',
      'groceries',
      'checkout',
      'places',
      'locations',
    ],
    Icon: Icons.ShoppingCartSimple,
  },
  {
    name: 'shower',
    categories: [IconCategory.OBJECTS],
    tags: ['bath', 'bathtub', 'bathroom', 'faucet'],
    Icon: Icons.Shower,
  },
  {
    name: 'shuffle',
    categories: [IconCategory.MEDIA, IconCategory.ARROWS],
    tags: ['music', 'audio', 'randomize', 'crossed'],
    Icon: Icons.Shuffle,
  },
  {
    name: 'shuffle-angular',
    categories: [IconCategory.MEDIA, IconCategory.ARROWS],
    tags: ['music', 'audio', 'randomize', 'crossed'],
    Icon: Icons.ShuffleAngular,
  },
  {
    name: 'shuffle-simple',
    categories: [IconCategory.MEDIA, IconCategory.ARROWS],
    tags: ['music', 'audio', 'randomize', 'crossed'],
    Icon: Icons.ShuffleSimple,
  },
  {
    name: 'sidebar',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'left rail', 'wireframe', 'ui', 'interface'],
    Icon: Icons.Sidebar,
  },
  {
    name: 'sidebar-simple',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'left rail', 'wireframe', 'ui', 'interface'],
    Icon: Icons.SidebarSimple,
  },
  {
    name: 'sign-in',
    categories: [IconCategory.SYSTEM],
    tags: ['signin', 'login', 'log in', 'enter'],
    Icon: Icons.SignIn,
  },
  {
    name: 'sign-out',
    categories: [IconCategory.SYSTEM],
    tags: ['signout', 'logout', 'log out', 'exit'],
    Icon: Icons.SignOut,
  },
  {
    name: 'signpost',
    categories: [IconCategory.MAP],
    tags: [
      '*new*',
      'direction',
      'traffic',
      'road sign',
      'transit',
      'transportation',
    ],
    Icon: Icons.Signpost,
  },
  {
    name: 'sim-card',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: ['cellular', 'cellphone', 'mobile'],
    Icon: Icons.SimCard,
  },
  {
    name: 'sketch-logo',
    categories: [IconCategory.DESIGN],
    tags: [
      'drawing',
      'art',
      'illustration',
      'ui',
      'interface',
      'prototype',
      'prototyping',
      'gemstone',
      'diamond',
    ],
    Icon: Icons.SketchLogo,
  },
  {
    name: 'skip-back',
    categories: [IconCategory.MEDIA],
    tags: [
      'music',
      'audio',
      'seek',
      'scrub',
      'scan',
      'back',
      'backwards',
      'reverse',
      'previous',
    ],
    Icon: Icons.SkipBack,
  },
  {
    name: 'skip-back-circle',
    categories: [IconCategory.MEDIA],
    tags: [
      'music',
      'audio',
      'seek',
      'scrub',
      'scan',
      'back',
      'backwards',
      'reverse',
      'previous',
    ],
    Icon: Icons.SkipBackCircle,
  },
  {
    name: 'skip-forward',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'seek', 'scrub', 'scan', 'ahead', 'next'],
    Icon: Icons.SkipForward,
  },
  {
    name: 'skip-forward-circle',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'seek', 'scrub', 'scan', 'ahead', 'next'],
    Icon: Icons.SkipForwardCircle,
  },
  {
    name: 'skull',
    categories: [IconCategory.GAMES],
    tags: ['*new*', 'death', 'dead', 'kill'],
    Icon: Icons.Skull,
  },
  {
    name: 'slack-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'messaging'],
    Icon: Icons.SlackLogo,
  },
  {
    name: 'sliders',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: [
      'music',
      'audio',
      'faders',
      'filters',
      'equalizer',
      'volume',
      'settings',
      'preferences',
    ],
    Icon: Icons.Sliders,
  },
  {
    name: 'sliders-horizontal',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: [
      'music',
      'audio',
      'faders',
      'filters',
      'equalizer',
      'volume',
      'settings',
      'preferences',
    ],
    Icon: Icons.SlidersHorizontal,
  },
  {
    name: 'smiley',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['face', 'emoji', 'happy', 'grinning', 'smiling'],
    Icon: Icons.Smiley,
  },
  {
    name: 'smiley-blank',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['face', 'emoji', 'unimpressed', 'no face'],
    Icon: Icons.SmileyBlank,
  },
  {
    name: 'smiley-meh',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['face', 'emoji', 'unimpressed', 'neutral'],
    Icon: Icons.SmileyMeh,
  },
  {
    name: 'smiley-nervous',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: [
      'face',
      'smiley',
      'emoji',
      'anxious',
      'uncomfortable',
      'uneasy',
      'queasy',
      'sick',
      'ill',
    ],
    Icon: Icons.SmileyNervous,
  },
  {
    name: 'smiley-sad',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['face', 'emoji', 'unhappy', 'frowning'],
    Icon: Icons.SmileySad,
  },
  {
    name: 'smiley-sticker',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['face', 'emoji', 'happy', 'grinning', 'smiling'],
    Icon: Icons.SmileySticker,
  },
  {
    name: 'smiley-wink',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['face', 'emoji', 'winking', 'flirting', 'cute'],
    Icon: Icons.SmileyWink,
  },
  {
    name: 'smiley-x-eyes',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['face', 'emoji', 'dead', 'killed', 'unconscious'],
    Icon: Icons.SmileyXEyes,
  },
  {
    name: 'snapchat-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'messaging', 'social media'],
    Icon: Icons.SnapchatLogo,
  },
  {
    name: 'snowflake',
    categories: [IconCategory.WEATHER],
    tags: ['meteorology', 'snowy', 'snowing', 'snowstorm'],
    Icon: Icons.Snowflake,
  },
  {
    name: 'soccer-ball',
    categories: [IconCategory.GAMES, IconCategory.HEALTH],
    tags: ['sports', 'football', 'mls'],
    Icon: Icons.SoccerBall,
  },
  {
    name: 'sort-ascending',
    categories: [IconCategory.EDITOR],
    tags: ['sorted', 'sorting', 'increasing', 'a to z', 'arrows'],
    Icon: Icons.SortAscending,
  },
  {
    name: 'sort-descending',
    categories: [IconCategory.EDITOR],
    tags: ['sorted', 'sorting', 'decreasing', 'z to a', 'arrows'],
    Icon: Icons.SortDescending,
  },
  {
    name: 'spade',
    categories: [IconCategory.GAMES],
    tags: ['spades', 'suits', 'cards', 'gambling', 'casino', 'gaming'],
    Icon: Icons.Spade,
  },
  {
    name: 'sparkle',
    categories: [IconCategory.COMMUNICATION, IconCategory.NATURE],
    tags: ['star', 'rate', 'ratings', 'favorites', 'favorited'],
    Icon: Icons.Sparkle,
  },
  {
    name: 'speaker-high',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'volume', 'sound'],
    Icon: Icons.SpeakerHigh,
  },
  {
    name: 'speaker-low',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'volume', 'sound'],
    Icon: Icons.SpeakerLow,
  },
  {
    name: 'speaker-none',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'muted', 'volume', 'sound'],
    Icon: Icons.SpeakerNone,
  },
  {
    name: 'speaker-slash',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'muted', 'volume', 'sound', 'disabled'],
    Icon: Icons.SpeakerSlash,
  },
  {
    name: 'speaker-x',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'muted', 'volume', 'sound', 'disabled', 'errors'],
    Icon: Icons.SpeakerX,
  },
  {
    name: 'speaker-simple-high',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'volume', 'sound'],
    Icon: Icons.SpeakerSimpleHigh,
  },
  {
    name: 'speaker-simple-low',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'volume', 'sound'],
    Icon: Icons.SpeakerSimpleLow,
  },
  {
    name: 'speaker-simple-none',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'muted', 'volume', 'sound'],
    Icon: Icons.SpeakerSimpleNone,
  },
  {
    name: 'speaker-simple-slash',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'muted', 'volume', 'sound', 'disabled'],
    Icon: Icons.SpeakerSimpleSlash,
  },
  {
    name: 'speaker-simple-x',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['music', 'audio', 'muted', 'volume', 'sound', 'disabled', 'errors'],
    Icon: Icons.SpeakerSimpleX,
  },
  {
    name: 'spinner',
    categories: [IconCategory.SYSTEM],
    tags: ['loading', 'loader', 'waiting', 'progress'],
    Icon: Icons.Spinner,
  },
  {
    name: 'spinner-gap',
    categories: [IconCategory.SYSTEM],
    tags: ['loading', 'loader', 'waiting', 'progress'],
    Icon: Icons.SpinnerGap,
  },
  {
    name: 'spiral',
    categories: [IconCategory.COMMUNICATION, IconCategory.DESIGN],
    tags: ['*new*', 'spin', 'rotate', 'dizzy'],
    Icon: Icons.Spiral,
  },
  {
    name: 'spotify-logo',
    categories: [IconCategory.BRAND, IconCategory.MEDIA],
    tags: ['music', 'player', 'streaming'],
    Icon: Icons.SpotifyLogo,
  },
  {
    name: 'square',
    categories: [IconCategory.DESIGN],
    tags: ['4', 'shapes', 'polygons', 'box'],
    Icon: Icons.Square,
  },
  {
    name: 'square-half',
    categories: [IconCategory.DESIGN],
    tags: [
      '*updated*',
      '4',
      'shapes',
      'polygons',
      'box',
      'columns',
      'sidebar',
      'split vertical',
    ],
    Icon: Icons.SquareHalf,
  },
  {
    name: 'square-half-bottom',
    categories: [IconCategory.DESIGN],
    tags: [
      '*new*',
      '4',
      'shapes',
      'polygons',
      'box',
      'columns',
      'sidebar',
      'split vertical',
    ],
    Icon: Icons.SquareHalfBottom,
  },
  {
    name: 'squares-four',
    categories: [IconCategory.DESIGN, IconCategory.SYSTEM],
    tags: [
      '4',
      'shapes',
      'polygons',
      'apps',
      'applications',
      'grid',
      'table',
      'microsoft',
      'logos',
    ],
    Icon: Icons.SquaresFour,
  },
  {
    name: 'square-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMERCE],
    tags: ['squareup', 'payment'],
    Icon: Icons.SquareLogo,
  },
  {
    name: 'stack',
    categories: [IconCategory.DESIGN, IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['cards', 'layers'],
    Icon: Icons.Stack,
  },
  {
    name: 'stack-simple',
    categories: [IconCategory.DESIGN, IconCategory.OFFICE, IconCategory.EDITOR],
    tags: ['cards', 'layers'],
    Icon: Icons.StackSimple,
  },
  {
    name: 'stack-overflow-logo',
    categories: [IconCategory.BRAND, IconCategory.DEVELOPMENT],
    tags: ['*new*', 'logos', 'code'],
    Icon: Icons.StackOverflowLogo,
  },
  {
    name: 'stamp',
    categories: [IconCategory.DESIGN, IconCategory.OBJECTS],
    tags: ['*new*', 'clone', 'seal', 'official'],
    Icon: Icons.Stamp,
  },
  {
    name: 'star',
    categories: [
      IconCategory.COMMUNICATION,
      IconCategory.MAP,
      IconCategory.NATURE,
    ],
    tags: ['rate', 'ratings', 'favorites', 'favorited'],
    Icon: Icons.Star,
  },
  {
    name: 'star-half',
    categories: [IconCategory.COMMUNICATION],
    tags: ['*updated*', 'rate', 'ratings'],
    Icon: Icons.StarHalf,
  },
  {
    name: 'star-four',
    categories: [IconCategory.COMMUNICATION, IconCategory.NATURE],
    tags: ['rate', 'ratings', 'favorites', 'favorited'],
    Icon: Icons.StarFour,
  },
  // {
  //   name: "steam-logo",
  //   categories: [IconCategory.BRAND, IconCategory.GAMES],
  //   tags: [ "logos","gaming", "valve"],
  //   Icon: Icons.SteamLogo,
  // },
  {
    name: 'sticker',
    categories: [IconCategory.COMMUNICATION],
    tags: ['stickers', 'sticker pack', 'labels'],
    Icon: Icons.Sticker,
  },
  {
    name: 'stop',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio'],
    Icon: Icons.Stop,
  },
  {
    name: 'stop-circle',
    categories: [IconCategory.MEDIA],
    tags: ['music', 'audio', 'round'],
    Icon: Icons.StopCircle,
  },
  {
    name: 'storefront',
    categories: [IconCategory.COMMERCE, IconCategory.MAP],
    tags: [
      'shops',
      'shopping',
      'markets',
      'stores',
      'buildings',
      'places',
      'locations',
    ],
    Icon: Icons.Storefront,
  },
  {
    name: 'strategy',
    categories: [IconCategory.GAMES, IconCategory.FINANCE],
    tags: ['*new*', 'sports', 'strategem', 'plan', 'tic-tac-toe'],
    Icon: Icons.Strategy,
  },
  {
    name: 'stripe-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMERCE],
    tags: ['payment'],
    Icon: Icons.StripeLogo,
  },
  {
    name: 'student',
    categories: [IconCategory.PEOPLE],
    tags: [
      'pupil',
      'classroom',
      'teacher',
      'education',
      'school',
      'college',
      'university',
    ],
    Icon: Icons.Student,
  },
  {
    name: 'suitcase',
    categories: [IconCategory.OFFICE, IconCategory.OBJECTS],
    tags: ['briefcase', 'valise', 'baggage', 'folders', 'portfolio'],
    Icon: Icons.Suitcase,
  },
  {
    name: 'suitcase-simple',
    categories: [IconCategory.OFFICE, IconCategory.OBJECTS],
    tags: ['briefcase', 'valise', 'baggage', 'folders', 'portfolio'],
    Icon: Icons.SuitcaseSimple,
  },
  {
    name: 'sun',
    categories: [
      IconCategory.NATURE,
      IconCategory.SYSTEM,
      IconCategory.WEATHER,
    ],
    tags: [
      'day',
      'daytime',
      'daylight',
      'clear',
      'sunny',
      'sunshine',
      'light mode',
      'brightness',
      'lighten',
      'brighten',
    ],
    Icon: Icons.Sun,
  },
  {
    name: 'sun-dim',
    categories: [
      IconCategory.NATURE,
      IconCategory.SYSTEM,
      IconCategory.WEATHER,
    ],
    tags: [
      'day',
      'daytime',
      'daylight',
      'clear',
      'sunny',
      'sunshine',
      'light mode',
      'brightness',
      'darken',
    ],
    Icon: Icons.SunDim,
  },
  {
    name: 'sun-horizon',
    categories: [IconCategory.NATURE, IconCategory.WEATHER],
    tags: [
      'day',
      'daytime',
      'daylight',
      'clear',
      'sunny',
      'sunshine',
      'sunrise',
      'sunset',
    ],
    Icon: Icons.SunHorizon,
  },
  {
    name: 'sunglasses',
    categories: [IconCategory.HEALTH, IconCategory.OBJECTS],
    tags: ['*new*', 'vision', 'sun', 'spectacles'],
    Icon: Icons.Sunglasses,
  },
  {
    name: 'swap',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['layers', 'replace', 'exchange', 'reverse'],
    Icon: Icons.Swap,
  },
  {
    name: 'swatches',
    categories: [
      IconCategory.DESIGN,
      IconCategory.EDITOR,
      IconCategory.OBJECTS,
    ],
    tags: ['colors', 'color picker', 'samples', 'pantone'],
    Icon: Icons.Swatches,
  },
  {
    name: 'sword',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: [
      'weapon',
      'knife',
      'dagger',
      'gladius',
      'video games',
      'rpg',
      'gaming',
    ],
    Icon: Icons.Sword,
  },
  {
    name: 'syringe',
    categories: [IconCategory.HEALTH],
    tags: [
      '*new*',
      'needle',
      'vaccine',
      'medicine',
      'doctor',
      'shot',
      'hospital',
    ],
    Icon: Icons.Syringe,
  },
  {
    name: 't-shirt',
    categories: [IconCategory.OBJECTS],
    tags: ['clothes', 'clothing'],
    Icon: Icons.TShirt,
  },
  // {
  //   name: "tab",
  //   categories: [IconCategory.SYSTEM],
  //   tags: [ "tabs", "browser", "internet", "interface"],
  //   Icon: Icons.Tab,
  // },
  {
    name: 'tabs',
    categories: [IconCategory.SYSTEM],
    tags: ['*new*', 'browser', 'window', 'folders', 'files'],
    Icon: Icons.Tabs,
  },
  {
    name: 'table',
    categories: [
      IconCategory.FINANCE,
      IconCategory.OFFICE,
      IconCategory.EDITOR,
    ],
    tags: ['tables', 'tabular', 'speadsheets', 'excel', 'grid', 'form'],
    Icon: Icons.Table,
  },
  {
    name: 'tag',
    categories: [
      IconCategory.COMMERCE,
      IconCategory.DEVELOPMENT,
      IconCategory.OBJECTS,
    ],
    tags: ['tags', 'hashtag', 'labels', 'sale', 'sell', 'price', 'discount'],
    Icon: Icons.Tag,
  },
  {
    name: 'tag-simple',
    categories: [
      IconCategory.COMMERCE,
      IconCategory.DEVELOPMENT,
      IconCategory.OBJECTS,
    ],
    tags: ['tags', 'hashtag', 'labels', 'sale', 'sell', 'price', 'discount'],
    Icon: Icons.TagSimple,
  },
  {
    name: 'tag-chevron',
    categories: [
      IconCategory.COMMERCE,
      IconCategory.DEVELOPMENT,
      IconCategory.OBJECTS,
    ],
    tags: ['tags', 'hashtag', 'labels', 'sale'],
    Icon: Icons.TagChevron,
  },
  {
    name: 'taxi',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      '*new*',
      'vehicles',
      'cars',
      'automobiles',
      'livery',
      'limousine',
      'uber',
    ],
    Icon: Icons.Taxi,
  },
  {
    name: 'target',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['bullseye', 'radar', 'archery', 'accuracy', 'precision'],
    Icon: Icons.Target,
  },
  {
    name: 'telegram-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'messages', 'messaging'],
    Icon: Icons.TelegramLogo,
  },
  {
    name: 'television',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: ['screen', 'tv', 'displays'],
    Icon: Icons.Television,
  },
  {
    name: 'television-simple',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: ['screen', 'tv', 'displays'],
    Icon: Icons.TelevisionSimple,
  },
  {
    name: 'tennis-ball',
    categories: [IconCategory.GAMES, IconCategory.HEALTH],
    tags: ['*updated*', 'sports', 'mlb'],
    Icon: Icons.TennisBall,
  },
  {
    name: 'terminal',
    categories: [IconCategory.DEVELOPMENT, IconCategory.SYSTEM],
    tags: ['command line', 'cli', 'bash', 'shell', 'caret'],
    Icon: Icons.Terminal,
  },
  {
    name: 'terminal-window',
    categories: [IconCategory.DEVELOPMENT, IconCategory.SYSTEM],
    tags: ['command line', 'cli', 'bash', 'shell', 'caret'],
    Icon: Icons.TerminalWindow,
  },
  {
    name: 'test-tube',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.NATURE,
      IconCategory.HEALTH,
    ],
    tags: ['science', 'chemistry', 'experiment', 'vial'],
    Icon: Icons.TestTube,
  },
  {
    name: 'text-align-center',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['typography', 'print', 'font', 'alignment', 'centered'],
    Icon: Icons.TextAlignCenter,
  },
  {
    name: 'text-align-justify',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['typography', 'print', 'font', 'alignment', 'justified'],
    Icon: Icons.TextAlignJustify,
  },
  {
    name: 'text-align-left',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['typography', 'print', 'font', 'alignment', 'flush left'],
    Icon: Icons.TextAlignLeft,
  },
  {
    name: 'text-align-right',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['typography', 'print', 'font', 'alignment', 'flush right'],
    Icon: Icons.TextAlignRight,
  },
  {
    name: 'text-aa',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['typography', 'typeface', 'print', 'font'],
    Icon: Icons.TextAa,
  },
  {
    name: 'text-t',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['typography', 'typeface', 'print', 'font'],
    Icon: Icons.TextT,
  },
  {
    name: 'text-bolder',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['typography', 'typeface', 'print', 'font', 'boldface', 'emphasis'],
    Icon: Icons.TextBolder,
  },
  {
    name: 'text-h',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['heading', 'typography', 'print'],
    Icon: Icons.TextH,
  },
  {
    name: 'text-h-one',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['heading', 'h1', 'typography', 'print'],
    Icon: Icons.TextHOne,
  },
  {
    name: 'text-h-two',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['heading', 'h2', 'typography', 'print'],
    Icon: Icons.TextHTwo,
  },
  {
    name: 'text-h-three',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['heading', 'h3', 'typography', 'print'],
    Icon: Icons.TextHThree,
  },
  {
    name: 'text-h-four',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['heading', 'h4', 'typography', 'print'],
    Icon: Icons.TextHFour,
  },
  {
    name: 'text-h-five',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['heading', 'h5', 'typography', 'print'],
    Icon: Icons.TextHFive,
  },
  {
    name: 'text-h-six',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['heading', 'h6', 'typography', 'print'],
    Icon: Icons.TextHSix,
  },
  {
    name: 'text-indent',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'alignment', 'tab'],
    Icon: Icons.TextIndent,
  },
  {
    name: 'text-outdent',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR],
    tags: ['*new*', 'alignment', 'tab', 'unindent', 'dedent'],
    Icon: Icons.TextOutdent,
  },
  {
    name: 'text-italic',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: [
      'typography',
      'typeface',
      'print',
      'font',
      'slant',
      'oblique',
      'stress',
      'emphasis',
      'calligraphy',
    ],
    Icon: Icons.TextItalic,
  },
  {
    name: 'text-underline',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: ['typography', 'typeface', 'print', 'font', 'underscore', 'emphasis'],
    Icon: Icons.TextUnderline,
  },
  {
    name: 'text-strikethrough',
    categories: [IconCategory.DESIGN, IconCategory.EDITOR, IconCategory.OFFICE],
    tags: [
      'typography',
      'typeface',
      'print',
      'font',
      'struck',
      'remove',
      'delete',
      'change',
    ],
    Icon: Icons.TextStrikethrough,
  },
  {
    name: 'textbox',
    categories: [IconCategory.EDITOR, IconCategory.SYSTEM],
    tags: ['*new*', 'input', 'cursor', 'field'],
    Icon: Icons.Textbox,
  },
  {
    name: 'thermometer',
    categories: [
      IconCategory.WEATHER,
      IconCategory.HEALTH,
      IconCategory.OBJECTS,
    ],
    tags: [
      'meteorology',
      'temperature',
      'degrees',
      '°',
      'celcius',
      'centigrade',
      'kelvin',
      'fahrenheit',
      'hot',
      'warm',
      'cold',
    ],
    Icon: Icons.Thermometer,
  },
  {
    name: 'thermometer-simple',
    categories: [
      IconCategory.WEATHER,
      IconCategory.HEALTH,
      IconCategory.OBJECTS,
    ],
    tags: [
      'meteorology',
      'temperature',
      'degrees',
      '°',
      'celcius',
      'centigrade',
      'kelvin',
      'fahrenheit',
      'hot',
      'warm',
      'cold',
    ],
    Icon: Icons.ThermometerSimple,
  },
  {
    name: 'thermometer-cold',
    categories: [
      IconCategory.WEATHER,
      IconCategory.HEALTH,
      IconCategory.OBJECTS,
    ],
    tags: [
      'meteorology',
      'temperature',
      'degrees',
      '°',
      'celcius',
      'centigrade',
      'kelvin',
      'fahrenheit',
    ],
    Icon: Icons.ThermometerCold,
  },
  {
    name: 'thermometer-hot',
    categories: [
      IconCategory.WEATHER,
      IconCategory.HEALTH,
      IconCategory.OBJECTS,
    ],
    tags: [
      'meteorology',
      'temperature',
      'degrees',
      '°',
      'celcius',
      'centigrade',
      'kelvin',
      'fahrenheit',
      'warm',
    ],
    Icon: Icons.ThermometerHot,
  },
  {
    name: 'thumbs-up',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['like', 'love', 'favorited', 'favorites', 'emoji', 'yes'],
    Icon: Icons.ThumbsUp,
  },
  {
    name: 'thumbs-down',
    categories: [IconCategory.COMMUNICATION, IconCategory.PEOPLE],
    tags: ['dislike', 'hate', 'emoji', 'no'],
    Icon: Icons.ThumbsDown,
  },
  {
    name: 'ticket',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['ticketstub', 'movie ticket', 'entry', 'admissions', 'events'],
    Icon: Icons.Ticket,
  },
  {
    name: 'tiktok-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'social media'],
    Icon: Icons.TiktokLogo,
  },
  {
    name: 'timer',
    categories: [IconCategory.SYSTEM],
    tags: ['clock', 'alarm', 'schedule', 'events', 'stopwatch', 'sports'],
    Icon: Icons.Timer,
  },
  {
    name: 'toggle-left',
    categories: [IconCategory.SYSTEM],
    tags: ['switch', 'controls', 'settings', 'preferences'],
    Icon: Icons.ToggleLeft,
  },
  {
    name: 'toggle-right',
    categories: [IconCategory.SYSTEM],
    tags: ['switch', 'controls', 'settings', 'preferences'],
    Icon: Icons.ToggleRight,
  },
  {
    name: 'toilet',
    categories: [IconCategory.HEALTH, IconCategory.OBJECTS],
    tags: ['*new*', 'bathroom', 'restroom', 'lavatory', 'water closet'],
    Icon: Icons.Toilet,
  },
  {
    name: 'toilet-paper',
    categories: [IconCategory.HEALTH, IconCategory.OBJECTS],
    tags: ['bathroom', 'restroom', 'lavatory', 'water closet'],
    Icon: Icons.ToiletPaper,
  },
  {
    name: 'tote',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['suitcases', 'valises', 'baggage', 'tote-bag', 'portfolios'],
    Icon: Icons.Tote,
  },
  {
    name: 'tote-simple',
    categories: [IconCategory.COMMERCE, IconCategory.OBJECTS],
    tags: ['suitcases', 'valises', 'baggage', 'tote-bag', 'portfolios'],
    Icon: Icons.ToteSimple,
  },
  {
    name: 'trademark-registered',
    categories: [IconCategory.COMMERCE],
    tags: ['*new*', '™', 'intellectual property', ''],
    Icon: Icons.TrademarkRegistered,
  },
  {
    name: 'traffic-cone',
    categories: [IconCategory.MAP],
    tags: ['*new*', 'pylon', 'safety', 'transit', 'transportation'],
    Icon: Icons.TrafficCone,
  },
  {
    name: 'traffic-sign',
    categories: [IconCategory.MAP],
    tags: ['road signs', 'transit', 'transportation'],
    Icon: Icons.TrafficSign,
  },
  {
    name: 'traffic-signal',
    categories: [IconCategory.MAP],
    tags: ['*new*', 'stop light', 'safety', 'transit', 'transportation'],
    Icon: Icons.TrafficSignal,
  },
  {
    name: 'train',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'subway',
      'light rail',
      'public transit',
      'transportation',
      'commuter',
      'traveling',
      'places',
      'locations',
    ],
    Icon: Icons.Train,
  },
  {
    name: 'train-regional',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'subway',
      'railroad',
      'public transit',
      'transportation',
      'commuter',
      'freight',
      'shipping',
      'traveling',
      'places',
      'locations',
    ],
    Icon: Icons.TrainRegional,
  },
  {
    name: 'train-simple',
    categories: [IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'vehicles',
      'subway',
      'light rail',
      'public transit',
      'transportation',
      'commuter',
      'traveling',
      'places',
      'locations',
    ],
    Icon: Icons.TrainSimple,
  },
  {
    name: 'translate',
    categories: [IconCategory.COMMUNICATION, IconCategory.SYSTEM],
    tags: [
      'translation',
      'languages',
      'internationalization',
      'i18n',
      'speech',
    ],
    Icon: Icons.Translate,
  },
  {
    name: 'trash',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: ['garbage', 'remove', 'delete', 'destroy', 'recycle', 'recycling'],
    Icon: Icons.Trash,
  },
  {
    name: 'trash-simple',
    categories: [IconCategory.OFFICE, IconCategory.SYSTEM],
    tags: ['garbage', 'remove', 'delete', 'destroy', 'recycle', 'recycling'],
    Icon: Icons.TrashSimple,
  },
  {
    name: 'tray',
    categories: [
      IconCategory.OFFICE,
      IconCategory.COMMUNICATION,
      IconCategory.SYSTEM,
    ],
    tags: ['inbox', 'mailbox', 'bin'],
    Icon: Icons.Tray,
  },
  {
    name: 'tree',
    categories: [IconCategory.NATURE],
    tags: ['plants', 'branches', 'leaves', 'green', 'environmental'],
    Icon: Icons.Tree,
  },
  {
    name: 'tree-evergreen',
    categories: [IconCategory.NATURE],
    tags: [
      'plants',
      'branches',
      'leaves',
      'pine',
      'conifer',
      'green',
      'environmental',
    ],
    Icon: Icons.TreeEvergreen,
  },
  {
    name: 'tree-structure',
    categories: [IconCategory.DEVELOPMENT, IconCategory.OFFICE],
    tags: [
      'data structures',
      'family tree',
      'genealogy',
      'hierarchy',
      'taxonomy',
      'charts',
      'flowchart',
    ],
    Icon: Icons.TreeStructure,
  },
  {
    name: 'trend-up',
    categories: [IconCategory.FINANCE],
    tags: [
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'analyze',
      'analysis',
      'increase',
      'arrows',
    ],
    Icon: Icons.TrendUp,
  },
  {
    name: 'trend-down',
    categories: [IconCategory.FINANCE],
    tags: [
      'graphs',
      'graphing',
      'charts',
      'statistics',
      'analyze',
      'analysis',
      'decrease',
      'arrows',
    ],
    Icon: Icons.TrendDown,
  },
  {
    name: 'triangle',
    categories: [IconCategory.DESIGN],
    tags: ['3', 'shapes', 'polygons'],
    Icon: Icons.Triangle,
  },
  {
    name: 'trophy',
    categories: [IconCategory.GAMES, IconCategory.OBJECTS],
    tags: ['ribbons', 'medals', 'winning', 'victory', 'awards', 'prize'],
    Icon: Icons.Trophy,
  },
  {
    name: 'truck',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: ['trucks', 'cars', 'vehicles', 'automobile', 'shipping', 'delivery'],
    Icon: Icons.Truck,
  },
  {
    name: 'twitch-logo',
    categories: [
      IconCategory.BRAND,
      IconCategory.COMMUNICATION,
      IconCategory.GAMES,
    ],
    tags: [
      'logos',
      'streaming',
      'livestream',
      'gaming',
      'video games',
      'social media',
    ],
    Icon: Icons.TwitchLogo,
  },
  {
    name: 'twitter-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'social media', 'tweets', 'birds'],
    Icon: Icons.TwitterLogo,
  },
  {
    name: 'umbrella',
    categories: [IconCategory.OBJECTS, IconCategory.WEATHER],
    tags: ['raining', 'rainy', 'insurance'],
    Icon: Icons.Umbrella,
  },
  {
    name: 'umbrella-simple',
    categories: [IconCategory.OBJECTS, IconCategory.WEATHER],
    tags: ['raining', 'rainy', 'insurance'],
    Icon: Icons.UmbrellaSimple,
  },
  {
    name: 'upload',
    categories: [IconCategory.SYSTEM],
    tags: [
      '*updated*',
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'uploaded',
      'uploading',
      'hard drive',
      'disk',
    ],
    Icon: Icons.Upload,
  },
  {
    name: 'upload-simple',
    categories: [IconCategory.SYSTEM],
    tags: [
      'saved',
      'saving',
      'archived',
      'archiving',
      'archival',
      'uploaded',
      'uploading',
      'hard drive',
      'disk',
    ],
    Icon: Icons.UploadSimple,
  },
  {
    name: 'user',
    categories: [IconCategory.PEOPLE],
    tags: ['person', 'users', 'profile', 'account', 'contact', 'login'],
    Icon: Icons.User,
  },
  {
    name: 'user-focus',
    categories: [IconCategory.PEOPLE],
    tags: [
      'identification',
      'biometrics',
      'facial recognition',
      'profile',
      'person',
      'account',
      'autofocus',
    ],
    Icon: Icons.UserFocus,
  },
  {
    name: 'user-gear',
    categories: [IconCategory.PEOPLE],
    tags: [
      'person',
      'users',
      'profile',
      'account',
      'contact',
      'settings',
      'preferences',
    ],
    Icon: Icons.UserGear,
  },
  {
    name: 'user-list',
    categories: [IconCategory.PEOPLE],
    tags: [
      'person',
      'users',
      'profiles',
      'accounts',
      'members',
      'address book',
    ],
    Icon: Icons.UserList,
  },
  {
    name: 'user-plus',
    categories: [IconCategory.PEOPLE],
    tags: [
      'person',
      'users',
      'profile',
      'account',
      'contact',
      'add',
      'create',
      '+',
    ],
    Icon: Icons.UserPlus,
  },
  {
    name: 'user-minus',
    categories: [IconCategory.PEOPLE],
    tags: [
      'person',
      'users',
      'profile',
      'account',
      'contact',
      'delete',
      'remove',
      '-',
    ],
    Icon: Icons.UserMinus,
  },
  {
    name: 'user-switch',
    categories: [IconCategory.PEOPLE],
    tags: [
      '*new*',
      'person',
      'users',
      'profile',
      'account',
      'login',
      'logout',
      'signin',
      'signout',
      'settings',
      'preferences',
    ],
    Icon: Icons.UserSwitch,
  },
  {
    name: 'user-circle',
    categories: [IconCategory.PEOPLE],
    tags: ['person', 'users', 'profile', 'account', 'contact', 'login'],
    Icon: Icons.UserCircle,
  },
  {
    name: 'user-circle-gear',
    categories: [IconCategory.PEOPLE],
    tags: [
      'person',
      'users',
      'profile',
      'account',
      'contact',
      'settings',
      'preferences',
    ],
    Icon: Icons.UserCircleGear,
  },
  {
    name: 'user-circle-plus',
    categories: [IconCategory.PEOPLE],
    tags: [
      'person',
      'users',
      'profile',
      'account',
      'contact',
      'add',
      'create',
      '+',
    ],
    Icon: Icons.UserCirclePlus,
  },
  {
    name: 'user-circle-minus',
    categories: [IconCategory.PEOPLE],
    tags: [
      'person',
      'users',
      'profile',
      'account',
      'contact',
      'delete',
      'remove',
      '-',
    ],
    Icon: Icons.UserCircleMinus,
  },
  {
    name: 'user-rectangle',
    categories: [IconCategory.PEOPLE],
    tags: ['person', 'users', 'profile', 'account', 'contact', 'login'],
    Icon: Icons.UserRectangle,
  },
  {
    name: 'user-square',
    categories: [IconCategory.PEOPLE],
    tags: ['person', 'users', 'profile', 'account', 'contact', 'login'],
    Icon: Icons.UserSquare,
  },
  {
    name: 'users',
    categories: [IconCategory.PEOPLE],
    tags: [
      'user',
      'group',
      'team',
      'people',
      'profiles',
      'accounts',
      'contacts',
    ],
    Icon: Icons.Users,
  },
  {
    name: 'users-three',
    categories: [IconCategory.PEOPLE],
    tags: [
      'user',
      'group',
      'team',
      'community',
      'people',
      'profiles',
      'accounts',
      'contacts',
    ],
    Icon: Icons.UsersThree,
  },
  {
    name: 'users-four',
    categories: [IconCategory.PEOPLE],
    tags: [
      'user',
      'group',
      'team',
      'department',
      'community',
      'people',
      'profiles',
      'accounts',
      'contacts',
    ],
    Icon: Icons.UsersFour,
  },
  {
    name: 'vault',
    categories: [
      IconCategory.FINANCE,
      IconCategory.SYSTEM,
      IconCategory.OBJECTS,
    ],
    tags: [
      '*new*',
      'safe',
      'bank',
      'security',
      'secured',
      'authentication',
      'authenticated',
      'locked',
      'encrypted',
      'encryption',
    ],
    Icon: Icons.Vault,
  },
  {
    name: 'vibrate',
    categories: [IconCategory.SYSTEM],
    tags: [
      'audio',
      'volume',
      'viration',
      'ringer',
      'calls',
      'silent',
      'silenced',
    ],
    Icon: Icons.Vibrate,
  },
  {
    name: 'video-camera',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['videography', 'films', 'movies', 'recording'],
    Icon: Icons.VideoCamera,
  },
  {
    name: 'video-camera-slash',
    categories: [IconCategory.MEDIA, IconCategory.SYSTEM],
    tags: ['videography', 'films', 'movies', 'recording', 'disabled'],
    Icon: Icons.VideoCameraSlash,
  },
  {
    name: 'vignette',
    categories: [IconCategory.DESIGN],
    tags: ['*new*', 'photography', 'darkroom', 'movie', 'analog'],
    Icon: Icons.Vignette,
  },
  // {
  //   name: "virus",
  //   categories: [IconCategory.HEALTH],
  //   tags: [ "illness", "disease", "covid-19", "coronavirus", "flu", "cold"],
  //   Icon: Icons.Virus,
  // },
  {
    name: 'voicemail',
    categories: [IconCategory.SYSTEM],
    tags: ['phonecalls', 'missed', 'recording', 'telephone', 'landline'],
    Icon: Icons.Voicemail,
  },
  {
    name: 'volleyball',
    categories: [IconCategory.GAMES, IconCategory.HEALTH],
    tags: ['sports'],
    Icon: Icons.Volleyball,
  },
  {
    name: 'wall',
    categories: [IconCategory.OBJECTS, IconCategory.SYSTEM],
    tags: ['*updated*', 'firewall', 'security', 'secured', 'blocks', 'bricks'],
    Icon: Icons.Wall,
  },
  {
    name: 'wallet',
    categories: [
      IconCategory.COMMERCE,
      IconCategory.FINANCE,
      IconCategory.OBJECTS,
    ],
    tags: ['money', 'payment', 'paying', 'purchase'],
    Icon: Icons.Wallet,
  },
  {
    name: 'warning',
    categories: [IconCategory.SYSTEM],
    tags: ['alert', 'danger', 'dangerous', 'caution', 'errors'],
    Icon: Icons.Warning,
  },
  {
    name: 'warning-circle',
    categories: [IconCategory.SYSTEM],
    tags: ['alert', 'danger', 'dangerous', 'caution', 'errors', 'round'],
    Icon: Icons.WarningCircle,
  },
  {
    name: 'warning-octagon',
    categories: [IconCategory.SYSTEM],
    tags: ['alert', 'danger', 'dangerous', 'caution', 'errors', '8', 'eight'],
    Icon: Icons.WarningOctagon,
  },
  {
    name: 'watch',
    categories: [IconCategory.SYSTEM],
    tags: [
      'times',
      'timer',
      'alarm',
      'schedule',
      'events',
      'clock',
      'wristwatch',
      'wearable',
    ],
    Icon: Icons.Watch,
  },
  {
    name: 'wave-sawtooth',
    categories: [IconCategory.MEDIA],
    tags: [
      '*new*',
      'synth',
      'synthesizer',
      'sound',
      'audio',
      'music',
      'waveform',
    ],
    Icon: Icons.WaveSawtooth,
  },
  {
    name: 'wave-sine',
    categories: [IconCategory.MEDIA],
    tags: [
      '*new*',
      'synth',
      'synthesizer',
      'sound',
      'audio',
      'music',
      'waveform',
    ],
    Icon: Icons.WaveSine,
  },
  {
    name: 'wave-square',
    categories: [IconCategory.MEDIA],
    tags: [
      '*new*',
      'synth',
      'synthesizer',
      'sound',
      'audio',
      'music',
      'waveform',
    ],
    Icon: Icons.WaveSquare,
  },
  {
    name: 'wave-triangle',
    categories: [IconCategory.MEDIA],
    tags: [
      '*new*',
      'synth',
      'synthesizer',
      'sound',
      'audio',
      'music',
      'waveform',
    ],
    Icon: Icons.WaveTriangle,
  },
  {
    name: 'waves',
    categories: [IconCategory.NATURE, IconCategory.WEATHER],
    tags: ['ocean', 'tides', 'surf'],
    Icon: Icons.Waves,
  },
  {
    name: 'webcam',
    categories: [
      IconCategory.COMMERCE,
      IconCategory.OBJECTS,
      IconCategory.SYSTEM,
    ],
    tags: ['*new*', 'camera', 'video conference'],
    Icon: Icons.Webcam,
  },
  {
    name: 'whatsapp-logo',
    categories: [IconCategory.BRAND, IconCategory.COMMUNICATION],
    tags: ['logos', 'messages', 'messaging'],
    Icon: Icons.WhatsappLogo,
  },
  {
    name: 'wheelchair',
    categories: [IconCategory.HEALTH, IconCategory.MAP, IconCategory.PEOPLE],
    tags: [
      'handicapped',
      'medical',
      'disabled',
      'differently abled',
      'accessible',
      'accessibility',
      'a11y',
    ],
    Icon: Icons.Wheelchair,
  },
  // {
  //   name: "wheelchair-motion",
  //   categories: [IconCategory.HEALTH, IconCategory.MAP, IconCategory.PEOPLE],
  //   tags: [
  //
  //     "handicapped",
  //     "medical",
  //     "disabled",
  //     "differently abled",
  //     "accessible",
  //     "accessibility",
  //     "a11y",
  //   ],
  //   Icon: Icons.WheelchairMotion,
  // },
  {
    name: 'wifi-high',
    categories: [IconCategory.SYSTEM],
    tags: ['wireless', 'internet', 'network', 'connection', 'connectivity'],
    Icon: Icons.WifiHigh,
  },
  {
    name: 'wifi-medium',
    categories: [IconCategory.SYSTEM],
    tags: ['wireless', 'internet', 'network', 'connection', 'connectivity'],
    Icon: Icons.WifiMedium,
  },
  {
    name: 'wifi-low',
    categories: [IconCategory.SYSTEM],
    tags: ['wireless', 'internet', 'network', 'connection', 'connectivity'],
    Icon: Icons.WifiLow,
  },
  {
    name: 'wifi-none',
    categories: [IconCategory.SYSTEM],
    tags: ['wireless', 'internet', 'network', 'connection', 'connectivity'],
    Icon: Icons.WifiNone,
  },
  {
    name: 'wifi-slash',
    categories: [IconCategory.SYSTEM],
    tags: [
      'wireless',
      'internet',
      'network',
      'connection',
      'connectivity',
      'disabled',
      'disconnected',
    ],
    Icon: Icons.WifiSlash,
  },
  {
    name: 'wifi-x',
    categories: [IconCategory.SYSTEM],
    tags: [
      '*updated*',
      'wireless',
      'internet',
      'network',
      'connection',
      'connectivity',
      'disconnected',
      'errors',
    ],
    Icon: Icons.WifiX,
  },
  {
    name: 'wind',
    categories: [IconCategory.WEATHER],
    tags: ['meteorology', 'windy', 'stormy', 'blustery', 'gusty', 'air'],
    Icon: Icons.Wind,
  },
  {
    name: 'windows-logo',
    categories: [IconCategory.BRAND, IconCategory.DEVELOPMENT],
    tags: ['microsoft', 'computers'],
    Icon: Icons.WindowsLogo,
  },
  {
    name: 'wine',
    categories: [IconCategory.COMMERCE, IconCategory.MAP, IconCategory.OBJECTS],
    tags: [
      'drinks',
      'beverages',
      'vineyard',
      'places',
      'locations',
      'bars',
      'restaurants',
      'food',
      'dining',
    ],
    Icon: Icons.Wine,
  },
  {
    name: 'wrench',
    categories: [IconCategory.SYSTEM, IconCategory.OBJECTS],
    tags: [
      'settings',
      'setup',
      'preferences',
      'tools',
      'machinery',
      'mechanical',
      'repairs',
    ],
    Icon: Icons.Wrench,
  },
  {
    name: 'x',
    categories: [
      IconCategory.DEVELOPMENT,
      IconCategory.FINANCE,
      IconCategory.SYSTEM,
    ],
    tags: [
      '×',
      'closed',
      'cancelled',
      'dismissed',
      'times',
      'multiply',
      'mulitplication',
      'product',
      'mathematics',
      'arithmetic',
      'calculator',
    ],
    Icon: Icons.X,
  },
  {
    name: 'x-circle',
    categories: [IconCategory.SYSTEM],
    tags: ['closed', 'cancelled', 'dismissed', 'round'],
    Icon: Icons.XCircle,
  },
  {
    name: 'x-square',
    categories: [IconCategory.SYSTEM],
    tags: ['closed', 'cancelled', 'dismissed'],
    Icon: Icons.XSquare,
  },
  {
    name: 'yin-yang',
    categories: [IconCategory.COMMUNICATION],
    tags: ['*new*', 'symbol', 'good', 'evil', 'black', 'white'],
    Icon: Icons.YinYang,
  },
  {
    name: 'youtube-logo',
    categories: [
      IconCategory.BRAND,
      IconCategory.COMMUNICATION,
      IconCategory.MEDIA,
    ],
    tags: ['logos', 'google', 'videos', 'movies', 'social media'],
    Icon: Icons.YoutubeLogo,
  },
];
