import styled from 'styled-components';

import { Button as ButtonBase } from 'components/elements/button';
import CropperBase from 'react-cropper';

export const CropContainer = styled.div``;

export const Cropper = styled(CropperBase)`
  width: 48rem;
  height: 48rem;
`;

export const Controls = styled.div`
  margin: 1rem 0 0;
  text-align: right;
  width: 100%;
`;

export const Button = styled(ButtonBase)`
  margin-left: 1rem;
`;
