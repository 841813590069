import { get } from 'lodash';

export const jobSubmissionListsEnabled = (company) =>
  !!get(company, 'subscription_plan.features.job_submission_lists', false);

export const marketingListsEnabled = (company) =>
  !!get(company, 'subscription_plan.features.marketing_lists', false);

export const pdfExportEnabled = (company) =>
  !!get(company, 'subscription_plan.features.pdf_export', false);

export const listsEnabled = (company) =>
  jobSubmissionListsEnabled(company) || marketingListsEnabled(company);

export const pdfExportOnly = (company) =>
  !marketingListsEnabled(company) &&
  !jobSubmissionListsEnabled(company) &&
  pdfExportEnabled(company);

export const noFeatureEnabled = (company) =>
  !jobSubmissionListsEnabled(company) &&
  !marketingListsEnabled(company) &&
  !pdfExportEnabled(company);
