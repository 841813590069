import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isFunction } from 'lodash';

import { Icon } from 'components/elements/icon';
import { downloadFile } from 'utils/fileFunctions';
import { KEYS } from 'components/utils/keys';
import { GlobalScrollLock } from 'components/structure/overlay/styles/overlay';

import * as styled from './styles/modal';
import i18n from './utils/i18n';

class ModalViewerComponent extends Component {
  constructor(props) {
    super(props);

    this.printContainer = null;
    this.modalContainer = null;
    this.canPrint = isFunction(props.renderPrintContent);
  }

  componentDidMount() {
    if (this.canPrint) {
      this.PrintGlobalStyle = styled.PrintGlobalStyle;

      const body = document.querySelector('body');
      this.printContainer = document.createElement('div');
      this.printContainer.setAttribute('id', 'printContainer');
      body.appendChild(this.printContainer);

      window.addEventListener('keydown', this.keyHandler, false);
    }
  }

  componentWillUnmount() {
    if (this.canPrint) {
      if (this.printContainer) {
        this.printContainer.parentNode.removeChild(this.printContainer);
      }
      window.removeEventListener('keydown', this.keyHandler, false);
    }
  }

  onDownloadFile = (event) => {
    const { url } = this.props;

    event.preventDefault();
    event.stopPropagation();
    downloadFile(url);
  };

  onPrintFile = (event) => {
    const { renderPrintContent } = this.props;

    event.preventDefault();
    event.stopPropagation();

    this.showPrint();
    /* eslint-disable @typescript-eslint/no-this-alias */
    const that = this;
    renderPrintContent(this.printContainer)
      .then(() => {
        that.closePrint();
        return new Promise((resolve) => {
          // Push window.print in the macrotask queue to avoid being affected by
          // the deprecation of running print() code in a microtask, see
          // https://github.com/mozilla/pdf.js/issues/7547.
          setTimeout(() => {
            window.print.call(window);
            // Delay promise resolution in case print() was not synchronous.
            setTimeout(resolve, 20); // Tidy-up.
          }, 0);
        });
      })
      .catch((e) => {
        /* eslint no-console: 0 */
        // Ignore any error messages.
        console.warn('Print error:', e);
      });
  };

  keyHandler = (event) => {
    // Intercept Cmd/Ctrl + P in all browsers.
    // Also intercept Cmd/Ctrl + Shift + P in Chrome and Opera
    if (
      event.keyCode === KEYS.KEY_P &&
      (event.ctrlKey || event.metaKey) &&
      !event.altKey &&
      (!event.shiftKey || window.chrome || window.opera)
    ) {
      this.onPrintFile();
      event.preventDefault();
      if (event.stopImmediatePropagation) {
        event.stopImmediatePropagation();
      } else {
        event.stopPropagation();
      }
    }
  };

  showPrint() {
    const { intl } = this.props;
    const body = document.querySelector('body');
    this.modalContainer = document.createElement('div');
    this.modalContainer.setAttribute('id', 'printOverlay');
    body.appendChild(this.modalContainer);
    const box = document.createElement('div');
    box.setAttribute('id', 'printBox');
    box.setAttribute('style', 'width:150px;font-size:15px;');
    box.textContent = intl.formatMessage(i18n.preparing);
    this.modalContainer.appendChild(box);
  }

  closePrint() {
    this.modalContainer.parentNode.removeChild(this.modalContainer);
  }

  render() {
    const { children, onClose } = this.props;

    return (
      <>
        {this.canPrint && <this.PrintGlobalStyle />}
        <styled.Fullscreen>
          <GlobalScrollLock />
          <styled.Overlay onClick={onClose} />
          <styled.Content>
            <styled.Controls>
              <styled.IconButton
                type="inverted"
                color="monoDarker"
                onClick={this.onDownloadFile}
              >
                <Icon icon="DownloadSimple" />
              </styled.IconButton>
              {this.canPrint && (
                <styled.IconButton
                  type="inverted"
                  color="monoDarker"
                  onClick={this.onPrintFile}
                >
                  <Icon icon="Printer" />
                </styled.IconButton>
              )}
              <styled.IconButton color="primary" onClick={onClose}>
                <Icon icon="X" />
              </styled.IconButton>
            </styled.Controls>
            <styled.ContentFitWrap>
              <styled.Preview>{children}</styled.Preview>
            </styled.ContentFitWrap>
          </styled.Content>
        </styled.Fullscreen>
      </>
    );
  }
}

ModalViewerComponent.propTypes = {
  intl: PropTypes.object,
  children: PropTypes.node,
  url: PropTypes.string,
  onClose: PropTypes.func,
  renderPrintContent: PropTypes.func,
};

export const ModalViewer = injectIntl(ModalViewerComponent);
