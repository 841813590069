import { Colors } from 'components/utils/styles/ui';

import noResult from 'images/svg/no-results.svg';

import { CandidatelyLogo } from './assets/images/candidately-logo';
import candidatelyOpenPositionsAndEngagedJobs from './assets/images/open-positions-and-engaged-jobs.png';
import candidatelyNewPositionsAndMarketplace from './assets/images/new-positions-and-marketplace.png';
import candidatelySubmittedCandidates from './assets/images/submitted-candidates.png';
import candidatelyCandidatesEmpty from './assets/images/candidates-empty.png';
import candidatelyJobsEmpty from './assets/images/jobs-empty.png';
import candidatelySupplier from './assets/images/supplier.png';
import { ReactComponent as LogoComponent } from './assets/images/logo.svg';
import { ReactComponent as LogoWhiteComponent } from './assets/images/logo_white.svg';
import LargeLogoSrc, {
  ReactComponent as LargeLogoComponent,
} from './assets/images/candidately-logo.svg';
import * as typography from '../typography';

export const candidatelyTheme = {
  logo: CandidatelyLogo,
  logoComponent: LogoComponent,
  logoWhiteComponent: LogoWhiteComponent,
  logoLargeComponent: LargeLogoComponent,
  logoLargeSrc: LargeLogoSrc,
  domain: 'app.candidate.ly',
  favicon: '/candidately-favicon.png',
  additionalDomains: [
    'candidately.localhost.com',
    'app.candidately.dev',
    'my.candidately.dev',
    'staging.candidately.dev',
    'staging-my.candidate.ly',
    'my.candidate.ly',
  ],
  logoLink: 'https://www.candidate.ly/',
  platformName: 'Candidate.ly',
  platformNameComponent: (
    <span>
      Candidate<span style={{ color: Colors.teal }}>.</span>ly
    </span>
  ),
  typography,
  fonts: {
    headers: "'TT Interphases', sans-serif",
    text: "'TT Interphases', sans-serif",
  },
  colors: {
    primary: Colors.teal,
    primaryDark: Colors.tealDark,
    secondary: Colors.green,
    danger: Colors.red,
    warning: Colors.yellow,
    mono: Colors.textLighter,
    monoLight: Colors.inputOutline,
    monoLighter: Colors.greyLighter,
    monoDarker: Colors.text,
    columnLeftBackground: Colors.white,
  },
  images: {
    supplierSrc: candidatelySupplier,
    notFoundSrc: noResult,
    openPositionsAndEngagedJobs: candidatelyOpenPositionsAndEngagedJobs,
    newPositionsAndMarketplace: candidatelyNewPositionsAndMarketplace,
    submittedCandidates: candidatelySubmittedCandidates,
    candidatesEmpty: candidatelyCandidatesEmpty,
    jobsEmpty: candidatelyJobsEmpty,
  },
};
