import { isEmpty } from 'lodash';

import { fileExtensionRegex } from 'utils/regexes';

export const buildFileName = ({ name, fileExtension }) => {
  if (isEmpty(fileExtension)) {
    return name;
  }

  const [nameMatch, extensionMatch] = name.match(fileExtensionRegex) || [];

  if (nameMatch && extensionMatch) {
    return nameMatch.replace(`.${extensionMatch}`, fileExtension);
  }

  return `${name}${fileExtension}`;
};
