export const ACCOUNT_FETCH_REQUEST = 'gustav/settings/ACCOUNT_FETCH_REQUEST';
export const ACCOUNT_FETCH_SUCCESS = 'gustav/settings/ACCOUNT_FETCH_SUCCESS';
export const ACCOUNT_FETCH_ERROR = 'gustav/settings/ACCOUNT_FETCH_ERROR';

export const ACCOUNT_UPDATE_REQUEST = 'gustav/settings/ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'gustav/settings/ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_ERROR = 'gustav/settings/ACCOUNT_UPDATE_ERROR';

export const ACCOUNT_UPLOAD_AVATAR_REQUEST =
  'gustav/settings/ACCOUNT_UPLOAD_AVATAR_REQUEST';
export const ACCOUNT_UPLOAD_AVATAR_SUCCESS =
  'gustav/settings/ACCOUNT_UPLOAD_AVATAR_SUCCESS';
export const ACCOUNT_UPLOAD_AVATAR_ERROR =
  'gustav/settings/ACCOUNT_UPLOAD_AVATAR_ERROR';

export const NOTIFICATIONS_SETTINGS_UPDATE_REQUEST =
  'gustav/settings/NOTIFICATIONS_SETTINGS_UPDATE_REQUEST';
export const NOTIFICATIONS_SETTINGS_UPDATE_SUCCESS =
  'gustav/settings/NOTIFICATIONS_SETTINGS_UPDATE_SUCCESS';
export const NOTIFICATIONS_SETTINGS_UPDATE_ERROR =
  'gustav/settings/NOTIFICATIONS_SETTINGS_UPDATE_ERROR';

export const RESEND_VERIFICATION_EMAIL_REQUEST =
  'gustav/settings/RESEND_VERIFICATION_EMAIL_REQUEST';
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  'gustav/settings/RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_ERROR =
  'gustav/settings/RESEND_VERIFICATION_EMAIL_ERROR';

export const CANCEL_EMAIL_CHANGE_SUCCESS =
  'gustav/settings/CANCEL_EMAIL_CHANGE_SUCCESS';

export const UPDATE_PASSWORD_SUCCESS =
  'gustav/settings/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'gustav/settings/UPDATE_PASSWORD_ERROR';
