import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/elements/icon';
import { ProgressBar } from 'components/misc/ProgressBar';
import { Tooltip } from 'components/overlay/Tooltip';

import i18n from '../i18n';
import * as styled from '../styles/bottomSection';

export const HotlistLimit = ({ company }) => {
  const left = company && 100;

  return (
    <styled.Bottom>
      <styled.HotlistLimit>
        <styled.Header>
          <FormattedMessage {...i18n.subscriptionHotlistLeft} />
          <Tooltip
            position="N"
            content={<FormattedMessage {...i18n.subscriptionHotlistTooltip} />}
          >
            <Icon icon="Info" />
          </Tooltip>
        </styled.Header>
        <styled.ProgressBar>
          <ProgressBar
            progress={left}
            hasLabel={false}
            fillColor="green"
            bordered
          />
        </styled.ProgressBar>
        <styled.SmartLink to="/subscription-plan">
          <FormattedMessage {...i18n.subscriptionUpgrade} />
        </styled.SmartLink>
      </styled.HotlistLimit>
    </styled.Bottom>
  );
};

HotlistLimit.propTypes = {
  company: PropTypes.object,
};
