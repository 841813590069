import { get, reduce, omit } from 'lodash';

import {
  INTEGRATIONS_FETCH_REQUEST,
  INTEGRATIONS_FETCH_SUCCESS,
  INTEGRATIONS_FETCH_ERROR,
  INTEGRATION_FETCH_REQUEST,
  INTEGRATION_FETCH_SUCCESS,
  INTEGRATION_FETCH_ERROR,
  INTEGRATION_CREATE_REQUEST,
  INTEGRATION_CREATE_SUCCESS,
  INTEGRATION_CREATE_ERROR,
  INTEGRATION_UPDATE_REQUEST,
  INTEGRATION_UPDATE_SUCCESS,
  INTEGRATION_UPDATE_ERROR,
  INTEGRATION_DELETE_REQUEST,
  INTEGRATION_DELETE_SUCCESS,
  INTEGRATION_CREDENTIALS_VALIDATION_SUCCESS,
  INTEGRATION_SYNC_UPDATE,
  INTEGRATION_SYNC_PROGRESS_UPDATE,
  INTEGRATION_FORCE_SYNC_SUCCESS,
  SETTINGS_REFRESH_SUCCESS,
} from './constants';

export const initialState = {
  integrations: {},
  loading: false,
};

export const integrationsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case INTEGRATIONS_FETCH_REQUEST:
    case INTEGRATION_FETCH_REQUEST:
    case INTEGRATION_CREATE_REQUEST:
    case INTEGRATION_UPDATE_REQUEST:
    case INTEGRATION_DELETE_REQUEST:
      return { ...state, loading: true };

    case INTEGRATIONS_FETCH_SUCCESS:
      return {
        ...state,
        integrations: reduce(
          payload.integrations,
          (acc, i) => ({ ...acc, [i.type]: i }),
          {}
        ),
        loading: false,
      };

    case INTEGRATION_FETCH_SUCCESS:
    case INTEGRATION_CREATE_SUCCESS:
    case INTEGRATION_UPDATE_SUCCESS:
    case INTEGRATION_SYNC_UPDATE:
    case INTEGRATION_CREDENTIALS_VALIDATION_SUCCESS:
    case INTEGRATION_FORCE_SYNC_SUCCESS:
    case SETTINGS_REFRESH_SUCCESS:
      return {
        ...state,
        integrations: { ...state.integrations, [payload.type]: payload },
        loading: false,
      };

    case INTEGRATION_DELETE_SUCCESS:
      return {
        ...state,
        integrations: omit(state.integrations, payload.type),
        loading: false,
      };

    case INTEGRATIONS_FETCH_ERROR:
    case INTEGRATION_FETCH_ERROR:
    case INTEGRATION_CREATE_ERROR:
    case INTEGRATION_UPDATE_ERROR:
      return { ...state, loading: false };

    case INTEGRATION_SYNC_PROGRESS_UPDATE:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [payload.type]: {
            ...get(state.integrations, payload.type, {}),
            data: {
              ...get(state.integrations, `${payload.type}.data`, {}),
              ...payload.data,
            },
          },
        },
      };

    default:
      return state;
  }
};
