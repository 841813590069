import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reject, map } from 'lodash';

import {
  fetchDocuments,
  uploadDocument,
  renameDocument,
  deleteDocument,
} from './actions';

class CompanyDocumentsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
    };
  }

  componentDidMount() {
    const { company, type, handleFetchDocuments } = this.props;

    handleFetchDocuments(company.id, type).then(({ documents }) => {
      this.setState({ documents });
    });
  }

  onUploadDocument = (doc) => {
    const { company, handleUploadDocument } = this.props;

    return handleUploadDocument(company.id, doc).then((response) => {
      this.setState((state) => ({ documents: [...state.documents, response] }));
      return response;
    });
  };

  onRenameDocument = (doc) => {
    const { company, handleRenameDocument } = this.props;

    return handleRenameDocument(company.id, doc).then((response) => {
      this.setState((state) => {
        const documents = map(state.documents, (d) =>
          d.id === response.id ? response : d
        );
        return { documents };
      });
    });
  };

  onDeleteDocument = (doc) => {
    const { company, handleDeleteDocument } = this.props;

    return handleDeleteDocument(company.id, doc).then(() => {
      this.setState((state) => ({
        documents: reject(state.documents, { id: doc.id }),
      }));
    });
  };

  render() {
    const { children } = this.props;
    const { documents } = this.state;

    return children({
      documents,
      onUploadDocument: this.onUploadDocument,
      onRenameDocument: this.onRenameDocument,
      onDeleteDocument: this.onDeleteDocument,
    });
  }
}

CompanyDocumentsProvider.propTypes = {
  children: PropTypes.func,
  company: PropTypes.object,
  type: PropTypes.string,
  handleFetchDocuments: PropTypes.func,
  handleUploadDocument: PropTypes.func,
  handleRenameDocument: PropTypes.func,
  handleDeleteDocument: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    handleFetchDocuments: (companyId, type) =>
      dispatch(fetchDocuments(companyId, type)),
    handleUploadDocument: (companyId, doc) =>
      dispatch(uploadDocument(companyId, doc)),
    handleRenameDocument: (companyId, doc) =>
      dispatch(renameDocument(companyId, doc)),
    handleDeleteDocument: (companyId, doc) =>
      dispatch(deleteDocument(companyId, doc)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(CompanyDocumentsProvider);
