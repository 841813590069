import { fromJS } from 'immutable';

import { DEPARTMENTS_SUCCESS, DEPARTMENTS_ERROR } from './constants';

const initialState = fromJS({
  errors: null,
  departments: [],
});

export function departmentsReducer(state = initialState, action) {
  switch (action.type) {
    case DEPARTMENTS_ERROR:
      return state.set('errors', action.errors);
    case DEPARTMENTS_SUCCESS:
      return state.set('departments', action.departments);
    default:
      return state;
  }
}
