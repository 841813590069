import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  isEmpty,
  difference,
  differenceBy,
  identity,
  first,
  isObject,
} from 'lodash';

import { isValid as isFieldValid } from 'components/utils/form-utils';
import { ValidationErrorIndicator } from 'components/form/validationErrorIndicator';
import { InputAutocomplete } from 'components/form/input/autocomplete';

import * as styled from './styles';
import i18n from './utils/i18n';

export class TagsAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = { items: props.value };
  }

  componentDidUpdate(prevProps) {
    const { items } = this.state;
    if (prevProps.value && items && prevProps.value.length !== items.length) {
      const { value } = this.props;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ items: value });
    }
  }

  updateValue = (value) => {
    const { max, onFormat, onChange } = this.props;
    const { items } = this.state;
    const newValue = onFormat(value);

    if (
      (!max || items.length < max) &&
      !isEmpty(newValue) &&
      !items.includes(newValue)
    ) {
      const newItems = [...items, newValue];
      this.setState({ items: newItems });
      onChange(newItems);
    }
  };

  removeItem(item) {
    const { items } = this.state;
    const { onChange } = this.props;
    items.splice(item, 1);
    this.setState({ items });
    onChange(items);
  }

  render() {
    const {
      id,
      className,
      children,
      dropdownFooter,
      required,
      value,
      quick,
      freetext,
      placeholder,
      disabled,
      inputType,
      min,
      max,
      minlength,
      maxlength,
      isValid,
      shouldValidate,
      inputIcon,
      onAutocomplete,
      onFocus,
      quickListSimple,
      customDescription,
      tagsEmptyState,
      fnFormatQuickTag,
      dropdownFooterHeight,
    } = this.props;
    const { items } = this.state;

    const isInputValid = isValid
      ? isFieldValid({
          inputValue: value,
          inputType,
          shouldValidate,
          required,
          min,
          max,
        })
      : isValid;

    const quickSuggestions = isObject(first(quick))
      ? differenceBy(quick, items, 'name')
      : difference(quick, items);

    return (
      <styled.InputContainer className={className}>
        {shouldValidate && !isInputValid && (
          <ValidationErrorIndicator inputType="tagsAutocomplete" />
        )}

        {!isEmpty(quick) && (
          <styled.QuickContainer>
            {quickListSimple && <FormattedMessage {...i18n.quickAdd} />}
            {isEmpty(quickSuggestions) && tagsEmptyState ? (
              <styled.TagsEmptyState>
                <FormattedMessage {...tagsEmptyState} />
              </styled.TagsEmptyState>
            ) : (
              <styled.QuickList
                tags={quickSuggestions}
                onAdd={(i) => this.updateValue(i)}
                formatFn={fnFormatQuickTag}
              />
            )}
          </styled.QuickContainer>
        )}

        {customDescription}

        <InputAutocomplete
          id={id}
          value=""
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          inputType="autocomplete"
          minlength={minlength}
          maxlength={maxlength}
          freetext={freetext}
          isValid={isInputValid}
          shouldValidate={false}
          inputIcon={inputIcon}
          clearOnChange
          onChange={this.updateValue}
          onAutocomplete={onAutocomplete}
          onFocus={onFocus}
          dropdownFooter={dropdownFooter}
          dropdownFooterHeight={dropdownFooterHeight}
        >
          {children}
        </InputAutocomplete>

        {quickListSimple && (
          <styled.TagsList tags={items} onRemove={(i) => this.removeItem(i)} />
        )}
      </styled.InputContainer>
    );
  }
}

TagsAutocomplete.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  dropdownFooter: PropTypes.node,
  quickListSimple: PropTypes.bool,
  customDescription: PropTypes.node,
  tagsEmptyState: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.array,
  quick: PropTypes.array,
  freetext: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  inputType: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  minlength: PropTypes.number,
  maxlength: PropTypes.number,
  isValid: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  inputIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onAutocomplete: PropTypes.func,
  onFormat: PropTypes.func,
  onFocus: PropTypes.func,
  fnFormatQuickTag: PropTypes.func,
  dropdownFooterHeight: PropTypes.number,
};

TagsAutocomplete.defaultProps = {
  isValid: true,
  onFormat: identity,
  quickListSimple: true,
};
