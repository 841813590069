export const COMPANY_PROFILE_FETCH_REQUEST =
  'gustav/company/profile/FETCH_REQUEST';
export const COMPANY_PROFILE_FETCH_SUCCESS =
  'gustav/company/profile/FETCH_SUCCESS';
export const COMPANY_PROFILE_FETCH_ERROR = 'gustav/company/profile/FETCH_ERROR';

export const COMPANY_PROFILE_UPDATE_REQUEST =
  'gustav/company/profile/UPDATE_REQUEST';
export const COMPANY_PROFILE_UPDATE_SUCCESS =
  'gustav/company/profile/UPDATE_SUCCESS';
export const COMPANY_PROFILE_UPDATE_ERROR =
  'gustav/company/profile/UPDATE_ERROR';

export const COMPANY_LOGO_UPLOAD_REQUEST =
  'gustav/company/profile/LOGO_UPLOAD_REQUEST';
export const COMPANY_LOGO_UPLOAD_SUCCESS =
  'gustav/company/profile/LOGO_UPLOAD_SUCCESS';
export const COMPANY_LOGO_UPLOAD_ERROR =
  'gustav/company/profile/LOGO_UPLOAD_ERROR';

export const COMPANY_LOGO_DELETE_REQUEST =
  'gustav/company/profile/LOGO_DELETE_REQUEST';
export const COMPANY_LOGO_DELETE_SUCCESS =
  'gustav/company/profile/LOGO_DELETE_SUCCESS';
export const COMPANY_LOGO_DELETE_ERROR =
  'gustav/company/profile/LOGO_DELETE_ERROR';

export const COMPANY_RATE_REQUEST = 'gustav/company/profile/RATE_REQUEST';
export const COMPANY_RATE_SUCCESS = 'gustav/company/profile/RATE_SUCCESS';
export const COMPANY_RATE_ERROR = 'gustav/company/profile/RATE_ERROR';

export const COMPANY_USERS_FETCH_REQUEST = 'gustav/company/users/FETCH_REQUEST';
export const COMPANY_USERS_FETCH_SUCCESS = 'gustav/company/users/FETCH_SUCCESS';
export const COMPANY_USERS_FETCH_ERROR = 'gustav/company/users/FETCH_ERROR';
