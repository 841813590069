import * as styled from './styles';

import { CustomIconsProp } from '../types';

export const IconBase = ({
  viewBox,
  size,
  children,
  className,
}: CustomIconsProp) => (
  <styled.Svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${viewBox} ${viewBox}`}
    className={className}
  >
    {children}
  </styled.Svg>
);
