import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Icon } from 'components/elements/icon';

export const PickerWrapper = styled.div`
  width: 24rem;
  background: ${Colors.white};
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SaturationWrapper = styled.div`
  width: 100%;
  height: 15rem;
  position: relative;
  margin-bottom: 1rem;
`;

export const PreviewAndHueWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
`;

export const PreviewCircle = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: 1px solid ${Colors.grey};
  margin-right: 1rem;
`;

export const HueWrapper = styled.div`
  width: calc(100% - 7rem);
  height: 1.2rem;
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
`;

export const InputFieldContainer = styled.div`
  display: flex;
`;

export const ToggleIcon = styled(Icon)`
  margin-top: 0.4rem;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;

export const EyedropperIcon = styled(Icon)`
  margin-top: 0.25rem;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 1.2rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const InputLabel = styled.div`
  margin-top: 1rem;
  font-size: 1.4rem;
  color: ${Colors.greyDarker};
  text-align: center;
`;
