import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Fieldset as FieldsetBase } from 'components/form/fieldset/fieldset';
import { Submit as SubmitBase } from 'components/form/submit/submit';
import { CheckboxListFieldset as CheckboxListFieldsetBase } from 'components/structure/form/styles/form';
import {
  List as CheckboxList,
  Label as CheckboxListLabel,
} from 'components/form/checkboxList/styles/checkboxList';
import { Label as LabelBase } from 'components/form/label/styles/label';

export const Form = styled.form`
  width: 100%;
  color: ${Colors.text};
`;

export const Fieldset = styled(FieldsetBase)`
  margin-bottom: 3rem;

  textarea {
    height: 20rem;
  }
`;

export const Submit = styled(SubmitBase)`
  float: right;
`;

export const CheckboxListFieldset = styled(CheckboxListFieldsetBase)`
  ${CheckboxList} {
    width: 100%;
  }

  ${CheckboxListLabel} {
    justify-content: space-between !important;
    padding-right: 0;

    & > * {
      margin-right: 0 !important;
    }
  }
`;

export const Paragraph = styled.p`
  margin: 0 0 ${({ marginBottom = '1rem' }) => marginBottom};
`;

export const SelectedCount = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Label = styled(LabelBase)`
  & > * {
    margin-left: 1rem;
  }
`;

export const SubLabel = styled(LabelBase)`
  font-size: 1.3rem;
  margin-bottom: 0.6rem;
`;

export const LocationFooter = styled.div`
  text-align: right;
  padding: 0 1rem;

  & > img {
    width: 12rem;
  }
`;
