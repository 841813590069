import styled from 'styled-components';
import { SmartLink } from 'components/navigation/SmartLink';

import { Colors } from 'components/utils/styles/ui';

import { Icon as IconBase } from 'components/elements/icon';

export const Breadcrumb = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Icon = styled(IconBase)`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  margin-top: -0.2rem;
`;

export const Label = styled.h2`
  margin: 0;
  color: ${Colors.text};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Link = styled(SmartLink)``;
