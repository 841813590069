export function parseJsonMessage(content, variables, message = '') {
  return content
    .reduce((acc, { type, text, attrs, content: nestedContent }) => {
      if (['paragraph'].includes(type)) {
        return parseJsonMessage(nestedContent, variables, `${acc}\n`);
      }

      if (type === 'text') {
        return `${acc}${text}`;
      }

      if (type === 'variable') {
        const variable = variables[attrs.type] || attrs.fallback;
        return `${acc}${variable}`;
      }

      return acc;
    }, message)
    .trim();
}

export function parseHtml(content, variables) {
  const matches =
    content.match(
      /<variable type="[A-Za-z]*" fallback="[^<>]*"><\/variable>/g
    ) || [];

  return matches.reduce((message, tag) => {
    const type = tag.match(/type="([^<> ]*)"/)[1];
    const fallabck = tag.match(/fallback="([^<> ]*)"/)?.[1] || '';
    let value = variables[type] || fallabck;

    if (type === 'listUrl') {
      value = `<a href="${value}" alt="list link">${value}</a>`;
    }
    return message.replace(tag, value);
  }, content);
}

export const PLAIN_TEXT_OPTS = {
  blockSeparator: ' ',
  textSerializers: {
    variable: ({ node }) => {
      const {
        attrs: { type, fallback = '' },
      } = node;
      return `<variable type="${type}" fallback="${fallback}"></variable>`;
    },
  },
};
