import { createRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactPageClick } from 'react-page-click';
import { FormattedMessage } from 'react-intl';
import { map, get, isFunction, isObject } from 'lodash';

import { scrollParent } from 'components/utils/scroll';
import { Portal } from 'components/structure/Portal';
import { Icon } from 'components/elements/icon';
import { Tooltip } from 'components/overlay/Tooltip';
import { Colors } from 'components/utils/styles/ui';

import * as styled from './styles';

const iconsDefinition = {
  chevron: {
    true: 'CaretUp',
    false: 'CaretDown',
  },
};

const MenuItem = function MenuItem(props) {
  const { children, item, size, onSelect, active, disabled } = props;
  const other = isObject(item.other) ? item.other : {};

  if (isFunction(item.onClick)) {
    const menuItem = (
      <styled.Item
        hasDescription={!!item.description}
        highlight={!!item.highlight}
        size={size}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onSelect();
          item.onClick(event);
        }}
        active={active}
        disabled={disabled}
        {...other}
      >
        <styled.ItemContent active={active} disabled={disabled}>
          {children}
        </styled.ItemContent>
        {active && <Icon icon="Check" color="tealDark" weight="bold" />}
      </styled.Item>
    );

    return (
      <>
        {item?.tooltipContent ? (
          <Tooltip position="S" fixed content={item?.tooltipContent}>
            {menuItem}
          </Tooltip>
        ) : (
          menuItem
        )}
      </>
    );
  }

  return (
    <styled.Item
      hasDescription={!!item.description}
      highlight={!!item.highlight}
      size={size}
    >
      <styled.Link to={item.link} onClick={onSelect} {...other}>
        {children}
      </styled.Link>
    </styled.Item>
  );
};

export class ButtonDropdownMenu extends Component {
  constructor(props) {
    super(props);

    this.containerRef = createRef();
    this.state = {
      active: false,
      coords: null,
    };
  }

  componentDidMount() {
    const { active } = this.props;
    this.setDropdownRef();
    if (active) {
      this.handleParentScroll();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { active, coords } = this.state;
    const { color, dropdownRef, loading } = this.props;

    return (
      nextState.active !== active ||
      nextState.coords !== coords ||
      nextProps.color !== color ||
      nextProps.dropdownRef !== dropdownRef ||
      nextProps.loading !== loading
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { onOpen, dropdownRef } = this.props;
    const { active } = this.state;

    if (dropdownRef && !prevProps.dropdownRef) {
      this.setDropdownRef();
    }

    if (onOpen && prevState.active !== active) {
      onOpen(active);
    }
  }

  setDropdownRef = () => {
    const { dropdownRef } = this.props;

    if (dropdownRef) {
      dropdownRef.current = { toggleMenu: this.toggleMenu };
    }
  };

  itemSelected = () => this.setState({ active: false, coords: null });

  toggleMenu = (event) => {
    const { fixed } = this.props;
    const { active } = this.state;
    event.preventDefault();
    event.stopPropagation();

    if (!active) {
      this.setState({ active: true, coords: this.calculateCoordinates() });
    } else {
      this.setState({ active: false, coords: null });
    }

    if (fixed) {
      this.toggleScrollWatch(!active);
    }
  };

  handleParentScroll = () => {
    this.setState({ coords: this.calculateCoordinates() });
  };

  calculateCoordinates() {
    const { position, fixed } = this.props;
    const target = this.containerRef.current;

    if (!fixed || !target) {
      return {};
    }

    const scrollEl = scrollParent(target);
    const targetDimensions = target.getBoundingClientRect();
    const scrollDimensions = scrollEl.getBoundingClientRect();
    const top = targetDimensions.bottom + document.defaultView.pageYOffset;
    const left =
      (position === 'left' ? targetDimensions.right : targetDimensions.left) +
      document.defaultView.pageXOffset;
    let zIndex = {};

    if (
      targetDimensions.bottom < scrollDimensions.top ||
      targetDimensions.bottom > scrollDimensions.bottom
    ) {
      zIndex = { zIndex: -1 };
    }

    return { top: `${top}px`, left: `${left}px`, ...zIndex };
  }

  toggleScrollWatch(active) {
    const scrollEl = scrollParent(this.containerRef.current);

    if (!scrollEl) {
      return;
    }
    if (active) {
      scrollEl.addEventListener('scroll', this.handleParentScroll);
    } else {
      scrollEl.removeEventListener('scroll', this.handleParentScroll);
    }
  }

  render() {
    const {
      className,
      dataManual,
      items,
      itemsSize,
      position,
      size,
      color,
      type,
      label,
      icon,
      iconPosition,
      buttonFn,
      loading,
      disabled,
      fixed,
      listCss,
      borderColor,
      spinnerColor,
    } = this.props;
    const { active, coords } = this.state;
    const WrapperButton = ({ children }) =>
      active ? (
        <ReactPageClick notify={() => this.setState({ active: false })}>
          {children}
        </ReactPageClick>
      ) : (
        <>{children}</>
      );

    const WrapperItems = fixed ? Portal : Fragment;

    const iconActive = () => get(iconsDefinition, [icon, active], icon);
    const ButtonEl = buttonFn ? (
      buttonFn({ onClick: this.toggleMenu, active, disabled, loading })
    ) : (
      <styled.Button
        type={type}
        size={size}
        color={color}
        disabled={disabled}
        loading={loading}
        spinnerColor={spinnerColor}
        onClick={this.toggleMenu}
      >
        {iconPosition === 'left' && <Icon icon={iconActive()} />}
        {label && <FormattedMessage {...label} />}
        {iconPosition === 'right' && <Icon icon={iconActive()} />}
      </styled.Button>
    );

    return (
      <WrapperButton>
        <styled.Wrapper
          className={className}
          data-manual={dataManual}
          ref={this.containerRef}
        >
          {ButtonEl}

          {active && (
            <WrapperItems>
              <styled.ItemsWrapper
                position={position}
                fixed={fixed}
                size={size}
                style={coords}
                borderColor={borderColor}
              >
                <styled.ItemsList css={listCss}>
                  {map(items, (item, i) => (
                    <MenuItem
                      key={`item-${i}`}
                      item={item}
                      size={itemsSize}
                      onSelect={this.itemSelected}
                      active={item.active}
                      disabled={item.disabled}
                    >
                      {item.iconComponent}
                      <styled.ItemTopLine>
                        {item.icon && (
                          <Icon
                            icon={item.icon}
                            size={itemsSize}
                            color={item.iconColor}
                          />
                        )}
                        <styled.Text
                          size={itemsSize}
                          hasDescription={!!item.description}
                        >
                          <FormattedMessage {...item.text} />
                        </styled.Text>
                        {item.highlight && item.highlight.badge}
                      </styled.ItemTopLine>
                      {item.additionalContent}
                      {item.description && (
                        <styled.Description>
                          {get(item, 'description.defaultMessage') ? (
                            <FormattedMessage {...item.description} />
                          ) : (
                            item.description
                          )}
                        </styled.Description>
                      )}
                      {item.highlight && (
                        <styled.LinkText>
                          <FormattedMessage {...item.highlight.link} />
                        </styled.LinkText>
                      )}
                    </MenuItem>
                  ))}
                </styled.ItemsList>
              </styled.ItemsWrapper>
            </WrapperItems>
          )}
        </styled.Wrapper>
      </WrapperButton>
    );
  }
}

ButtonDropdownMenu.propTypes = {
  className: PropTypes.string,
  dataManual: PropTypes.string,
  label: PropTypes.object,
  size: PropTypes.string,
  type: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      iconColor: PropTypes.string,
      iconComponent: PropTypes.node,
      text: PropTypes.object,
      description: PropTypes.object,
      link: PropTypes.string,
      onClick: PropTypes.func,
      highlight: PropTypes.object,
      other: PropTypes.object,
      tooltipContent: PropTypes.node,
      additionalContent: PropTypes.node,
    })
  ),
  itemsSize: PropTypes.oneOf(['large', 'normal', 'xsmall']),
  position: PropTypes.oneOf(['left', 'right', 'top-left', 'top-right']),
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  buttonFn: PropTypes.func,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
  dropdownRef: PropTypes.object,
  borderColor: PropTypes.string,
};

ButtonDropdownMenu.defaultProps = {
  size: 'small',
  type: 'inverted',
  color: 'mono',
  items: [],
  itemsSize: 'normal',
  position: 'right',
  icon: 'DotsThreeOutlineVerticalFill',
  iconColor: 'text',
  iconPosition: 'left',
  disabled: false,
  loading: false,
  fixed: false,
  borderColor: Colors.greyLight,
};
