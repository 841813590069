import { isObject } from 'lodash';

import { currencySymbolFor } from 'components/utils/conversions';

export const labelProperties = function labelProperties(context) {
  const { inputType, value } = context;

  switch (inputType) {
    case 'money':
    case 'money_decimal':
      return {
        literals: isObject(value) ? { currency: currencySymbolFor(value) } : {},
      };
    case 'calendar':
    case 'calendar_datetime':
      return {
        elementToRefer: undefined,
      };

    default:
      return {};
  }
};
