import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

import { Avatar as AvatarBase } from 'components/visual/avatar/avatar';
import { Icon as IconBase } from 'components/elements/icon';
import { Tooltip as TooltipBase } from 'components/overlay/Tooltip';

export const List = styled.ul`
  border: solid 1px ${Colors.inputOutline};
  border-radius: 0.3rem;
  padding: 0;
  font-weight: 500;
  color: ${Colors.text};

  & > div > div {
    -ms-overflow-style: scrollbar;
  }
`;

export const ListItem = styled.li`
  &:first-child > * {
    padding-top: 1.2rem;
  }

  &:last-child > * {
    padding-bottom: 1.2rem;
  }
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
`;

export const LabelTitle = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
`;

export const Avatar = styled(AvatarBase)`
  margin-right: 1rem;

  & {
    height: 3.2rem;
    width: 3.2rem;
  }
`;

export const Icon = styled(IconBase)`
  margin-right: 0.8rem;
`;

export const Tooltip = styled(TooltipBase)`
  margin-right: 1.3rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
