import { isStaleIntegrationIframe } from 'utils/checkEnvironment';

import * as styled from './styles';

type LayoutProps = {
  icon: React.ReactNode;
  title?: React.ReactNode;
  children?: React.ReactNode;
};

export const Layout = ({ icon, title, children }: LayoutProps) => (
  <styled.Container isStaleIntegration={isStaleIntegrationIframe(window)}>
    <styled.Box>
      {icon}

      {title && <styled.Title>{title}</styled.Title>}

      {children && <styled.Body>{children}</styled.Body>}
    </styled.Box>
  </styled.Container>
);
