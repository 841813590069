import styled from 'styled-components';

import { InputText as InputBase } from 'components/form/input/styles/inputs';

export const Input = styled(InputBase)`
  border: none;
  padding: 0;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 25%;
  height: 100%;
  ${({ value }) => value.length < 4 && 'text-align: center;'}

  &:focus {
    border: none;
  }
`;

export const InputSizer = styled.span`
  visibility: hidden;
  position: absolute;
  z-index: -1;
`;
