// Needed for redux-saga es6 generator support
import 'babel-polyfill';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { some } from 'lodash';

import { SegmentAPI } from 'components/scripts/segment';
import { IntercomAPI } from 'components/scripts/intercom';
import { ThemeProvider } from 'styled-components';
import Logger from 'js-logger';

// Import redux store and history
import { store, history } from 'store';

// Import themes
import theme from 'themes';

// Import routes
import { Routes } from 'routes';

// Import Language Provider
import { LanguageProvider } from 'containers/LanguageProvider';

import { isProduction, isDevelopment } from 'utils/node-env-vars';
import { isProductionServer } from 'utils/checkEnvironment';
import { isAdministrator } from 'utils/authorization/utils/auth';
import {
  isCustomDomainPath,
  isCustomDomainPage,
} from 'containers/App/utils/helpers';

import { SettingsProvider } from 'containers/Settings/Company/SettingsContext';

// Import i18n messages
import { translationMessages } from 'i18n';

// Import CSS reset and Global Styles
import GlobalStyles from './global-styles';

/* DOTENV load */
/* eslint global-require: 0 */
if (process.env.NODE_ENV === 'test') {
  require('dotenv').config();
}
/* END DOTENV load */

// Setup Logger
// Available levels: debug, info, time, warn, error
// * Production: Enable info and above
// * Development: Enable debug and above
Logger.useDefaults();
if (isProduction()) {
  Logger.setLevel(Logger.INFO);
}
if (isDevelopment()) {
  Logger.setLevel(Logger.DEBUG);
}

const MOUNT_NODE = document.getElementById('app');

const NO_SCROLL_PATHS = [
  /\/marketplace.*(supplied-candidates|discover|saved|assigned)/,
  /\/hotlist\//,
  /\/sl\//,
  /\/cp\//,
  /\/shared-lists\/.*\/candidates/,
  /\/settings\/members(\/.*)|/,
];
// Listen for router location change:
// * Refresh Intercom chat setting
// * Trigger analytics page view
history.listen((location) => {
  if (
    (isProductionServer(window) || isCustomDomainPage()) &&
    !isAdministrator()
  ) {
    SegmentAPI('page');
    IntercomAPI('update');
  }

  // Prevent scrolling to top on route change for certain pages:
  // * opening applicant modal from supplier candidates view
  // * opening notification settings modal from marketplace
  // * opening candidate modal from hotlist
  if (
    some(NO_SCROLL_PATHS, (path) => path.test(location.pathname)) ||
    isCustomDomainPath(location)
  ) {
    return;
  }

  window.scrollTo(0, 0);
});

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ThemeProvider theme={theme}>
          <>
            <SettingsProvider>
              <Router history={history}>{renderRoutes(Routes)}</Router>
            </SettingsProvider>
            <GlobalStyles />
          </>
        </ThemeProvider>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
  /* eslint no-console:0 */
  module.hot.accept((err) => console.log(err));
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en'),
        import('intl/locale-data/jsonp/de'),
      ])
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
