import { render } from 'react-dom';

import { Confirm } from './Confirm';

// If <FormattedMessage> component passed down, the following error will occur:
// Uncaught Error: [React Intl] Could not find required `intl` object. <IntlProvider> needs to exist in the component ancestry.
// TODO: Figure out a way to mount confirmAlert within component ancestry

function confirmAlert(props) {
  // Mount modals in separate root
  // https://facebook.github.io/react/docs/portals.html#event-bubbling-through-portals
  const { children, targetElementContainerId, ...rest } = props;

  const target = document.createElement('div');

  const targetElement = targetElementContainerId
    ? document.getElementById(targetElementContainerId)
    : document.body;

  targetElement.appendChild(target);

  render(<Confirm {...rest}>{children}</Confirm>, target);
}

export { Confirm, confirmAlert };
