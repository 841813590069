import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { meanBy } from 'lodash';

import { Icon } from 'components/elements/icon';
import { Colors } from 'components/utils/styles/ui';

import i18n from '../utils/i18n';
import * as styled from '../styles/ratings';

export function Ratings(props) {
  const { company } = props;
  const ratingOverall = meanBy(company.ratings, 'rating');

  return (
    <styled.RatingWrapper>
      <styled.RatingComponent
        stop={10}
        step={2}
        initialRating={Math.round(ratingOverall)}
        emptySymbol={
          <Icon size="large" icon="StarFill" color={Colors.inputOutline} />
        }
        fullSymbol={<Icon size="large" icon="StarFill" color={Colors.yellow} />}
      />
      <styled.RatingCount>
        <FormattedMessage
          {...i18n.ratingCount}
          values={{ count: company.ratings.length }}
        />
      </styled.RatingCount>
    </styled.RatingWrapper>
  );
}

Ratings.propTypes = {
  company: PropTypes.object.isRequired,
};
