import DOMPurify from 'dompurify';

const { sanitize: dompurifySanitize } = DOMPurify;

const defaultConfig = {
  ALLOWED_TAGS: [
    'p',
    'ul',
    'ol',
    'li',
    'strong',
    'b',
    'em',
    'u',
    'mark',
    'pre',
    'code',
    'blockquote',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'div',
    'span',
    'br',
    'hr',
  ],
};

export const sanitize = (val: string, config = defaultConfig) =>
  dompurifySanitize(val, { ...defaultConfig, ...config });
