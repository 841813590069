export const COMPANY_DOMAINS_FETCH_REQUEST =
  'gustav/company/domains/FETCH_REQUEST';
export const COMPANY_DOMAINS_FETCH_SUCCESS =
  'gustav/company/domains/FETCH_SUCCESS';
export const COMPANY_DOMAINS_FETCH_ERROR = 'gustav/company/domains/FETCH_ERROR';
export const COMPANY_DOMAINS_UPDATE_REQUEST =
  'gustav/company/domains/UPDATE_REQUEST';
export const COMPANY_DOMAINS_UPDATE_SUCCESS =
  'gustav/company/domains/UPDATE_SUCCESS';
export const COMPANY_DOMAINS_UPDATE_ERROR =
  'gustav/company/domains/UPDATE_ERROR';
