import { get } from 'lodash';

// Use https://emojipedia.org to search for new emoji codes
export const EMOJI_DATA = [
  { name: 'star-struck', code: '1F929' },
  { name: 'clapping-hands', code: '1F44F' },
  { name: 'handshake', code: '1F91D' },
  { name: 'banzai', code: '1F64C' },
  { name: 'exploding-head', code: '1F92F' },
  { name: 'man-detective', code: '1F575' },
  { name: 'rocket', code: '1F680' },
  { name: 'light-bulb', code: '1F4A1' },
  { name: 'pencil', code: '270F' },
  { name: 'fire', code: '1F525' },
  { name: 'bust-in-silhouette', code: '1F464' },
  { name: 'busts-in-silhouette', code: '1F465' },
  { name: 'globe-with-meridians', code: '1F310' },
  { name: 'waving-hand', code: '1F44B' },
  { name: 'incoming-envelope', code: '1F4E8' },
  { name: 'party-popper', code: '1F389' },
  { name: 'high-voltage', code: '26A1' },
  { name: 'sun-with-face', code: '1F31E' },
  { name: 'desktop-computer', code: '1F5A5' },
  { name: 'eyes', code: '1F440' },
  { name: 'necktie', code: '1F454' },
  { name: 'grinning-face', code: '1F600' },
  { name: 'scissors', code: '2702' },
  { name: 'memo', code: '1F4DD' },
  { name: 'round-pushpin', code: '1F4CD' },
  { name: 'bullseye', code: '1F3AF' },
  { name: 'link', code: '1F517' },
  { name: 'triangular-ruler', code: '1F4D0' },
  { name: 'straight-ruler', code: '1F4CF' },
  { name: 'speaking-head', code: '1F5E3' },
  { name: 'bar-chart', code: '1F4CA' },
  { name: 'chart-increasing', code: '1F4C8' },
  { name: 'glowing-star', code: '1F31F' },
  { name: 'puzzle-piece', code: '1F9E9' },
  { name: 'bookmark', code: '1F516' },
  { name: 'seedling', code: '1F331' },
  { name: 'herb', code: '1F33F' },
  { name: 'robot-face', code: '1F916' },
  { name: 'slightly-smiling-face', code: '1F642' },
  { name: 'calendar', code: '1F4C5' },
  { name: 'medal', code: '1F3C5' },
  { name: 'magnifying-glass', code: '1F50D' },
  { name: 'magnifying-glass-tilted-right', code: '1F50E' },
  { name: 'trophy', code: '1F3C6' },
  { name: 'key', code: '1F511' },
  { name: 'hourglass-not-done', code: '23F3' },
  { name: 'hourglass-done', code: '231B' },
  { name: 'briefcase', code: '1F4BC' },
  { name: 'books', code: '1F4DA' },
  { name: 'balloon', code: '1F388' },
  { name: 'speech-balloon', code: '1F4AC' },
  { name: 'card-index-dividers', code: '1F5C2' },
  { name: 'office-worker', code: ['1F469', '200D', '1F4BC'] },
  { name: 'teacher', code: ['1F469', '200D', '1F3EB'] },
  { name: 'mechanic', code: ['1F469', '200D', '1F527'] },
  { name: 'clipboard', code: '1F4CB' },
  { name: 'input-Latin-letters', code: '1F524' },
  { name: 'top-hat', code: '1F3A9' },
  { name: 't-shirt', code: '1F455' },
  { name: 'restroom', code: '1F6BB' },
  { name: 'open-book', code: '1F4D6' },
  { name: 'lower-fountain-pen', code: '1F58B' },
  { name: 'wrench', code: '1F527' },
  { name: 'flexed-biceps', code: '1F4AA' },
  { name: 'upwards-trend', code: '1F4C8' },
];

export const EMOJI_NAMES = EMOJI_DATA.map((emoji) => get(emoji, 'name'));
