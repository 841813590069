import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { trim, isEmpty } from 'lodash';

import { formatDate } from 'components/utils/date';
import { ButtonDropdownMenu } from 'components/navigation/ButtonDropdownMenu';
import * as styled from '../styles/blockWidget';
import i18n from '../utils/i18n';

export class BlockWidget extends Component {
  handleModalTrigger = (context) => () => {
    const { openModal } = this.props;

    openModal({ context });
  };

  render() {
    const { company, blockedCompany } = this.props;

    const menuItems = [
      {
        text: i18n.editReason,
        onClick: this.handleModalTrigger('update-blocking-reason'),
        icon: 'NotePencil',
      },
      {
        text: i18n.unblockVendor,
        onClick: this.handleModalTrigger('unblock-vendor'),
        icon: 'Prohibit',
      },
    ];

    return (
      <styled.BlockWidget>
        <styled.Header>
          <styled.Title>
            <FormattedMessage
              {...i18n.blockWidgetHeader}
              values={{ vendorName: company.name }}
            />
          </styled.Title>

          <ButtonDropdownMenu
            icon="DotsThreeOutlineVerticalFill"
            items={menuItems}
            position="left"
            size="small"
          />
        </styled.Header>

        <styled.BlockedBy>
          <FormattedMessage
            {...i18n.blockWidgetBlockedBy}
            values={{
              user: `${blockedCompany.author.first_name} ${blockedCompany.author.last_name}`,
              date: formatDate({
                date: blockedCompany.inserted_at,
              }),
            }}
          />
        </styled.BlockedBy>
        <styled.ReasonText>
          {isEmpty(trim(blockedCompany.reason)) ? (
            <FormattedMessage {...i18n.na} />
          ) : (
            blockedCompany.reason
          )}
        </styled.ReasonText>
      </styled.BlockWidget>
    );
  }
}

BlockWidget.propTypes = {
  company: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  blockedCompany: PropTypes.object.isRequired,
};
