export const TEAM_FETCH_REQUEST = 'gustav/team/FETCH_REQUEST';
export const TEAM_FETCH_SUCCESS = 'gustav/team/FETCH_SUCCESS';
export const TEAM_FETCH_ERROR = 'gustav/team/FETCH_ERROR';

export const TEAM_UPDATE_REQUEST = 'gustav/team/UPDATE_REQUEST';
export const TEAM_UPDATE_SUCCESS = 'gustav/team/UPDATE_SUCCESS';
export const TEAM_UPDATE_ERROR = 'gustav/team/UPDATE_ERROR';

export const TEAM_DELETE_REQUEST = 'gustav/team/DELETE_REQUEST';
export const TEAM_DELETE_SUCCESS = 'gustav/team/DELETE_SUCCESS';
export const TEAM_DELETE_ERROR = 'gustav/team/DELETE_ERROR';
