import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Button as ButtonBase } from 'components/elements/button';
import { Icon as IconBase } from 'components/elements/icon';
import { iconElementsSelector } from 'components/elements/styles/icon';

export const Wrapper = styled.div`
  position: relative;
`;

const buttonColors = {
  blue: {
    fg: Colors.tealDark,
    bg: Colors.tealLighter,
  },
  green: {
    fg: Colors.greenDark,
    bg: Colors.greenLighter,
  },
  grey: {
    fg: Colors.textLight,
    bg: Colors.greyLight,
  },
};

export const Button = styled(ButtonBase)`
  min-width: 22rem;
  height: 3rem;
  padding: 0.2rem 1.3rem 0.1rem 1.3rem;
  border-radius: 1.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${({ color }) => buttonColors[color].fg};
  background-color: ${({ color }) => buttonColors[color].bg};
  border: 0.1rem solid ${Colors.outline};
  justify-content: flex-start;

  &:hover,
  &:focus {
    background-color: ${({ color }) => buttonColors[color].bg};
  }

  & > svg {
    width: 1.8rem !important;
    height: 1.8rem !important;
  }

  & ${iconElementsSelector} {
    stroke: ${({ color }) => buttonColors[color].fg} !important;
  }
`;

export const ButtonText = styled.div`
  margin: 0 1rem;
`;

export const Icon = styled(IconBase)`
  ${({ right }) =>
    right &&
    `
    margin-left: auto;
  `};
`;

export const Content = styled.div`
  position: absolute;
  z-index: 1;
  transform: translateY(0.8rem);
  background-color: ${Colors.white};
  border: 0.1rem solid ${Colors.outline};
  border-radius: 0.3rem;
  ${({ align }) => (align === 'left' ? 'left: 0;' : 'right: 0;')}
`;
