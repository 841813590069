import { COMPANY_PROFILE_UPDATE_SUCCESS } from 'containers/CompanyProfile/constants';
import { SUBSCRIPTION_CHANGE_SUCCESS } from 'containers/SubscriptionPlan/constants';
import { CONFIRM_EMAIL_EMAIL_CONFIRMED_SUCCESS } from 'containers/ConfirmEmail/constants';

import { COMPANY_UPDATE_SUCCESS } from 'containers/Settings/Company/constants';
import { ONBOARDING_SUCCESS } from 'containers/Onboarding/constants';
import {
  GET_SESSION_SUCCESS,
  GET_SESSION_VIA_TOKEN_SUCCESS,
} from 'containers/Login/constants';
import { TEAM_UPDATE_SUCCESS } from 'containers/Settings/Teams/EditTeam/constants';
import { TEAM_MEMBERSHIPS_UPDATE_SUCCESS } from 'containers/Settings/Members/Teams/constants';
import { MEMBER_UPDATE_SUCCESS } from 'containers/Settings/Members/constants';

import { userReducer as User } from './user';
import { companyReducer as Company } from './company';

import { Constants } from '../../constants';

const sessionInitialState = {
  socket: null,
  user: User(),
  company: Company(),
  error: null,
  release: null,
  counters: { candidates: {}, jobs: {}, partners: {} },
};

export const session = (state = sessionInitialState, action) => {
  switch (action.type) {
    case 'SOCKET_CONNECTED':
      return {
        ...state,
        socket: action.socket,
        error: null,
      };

    case 'USER_CHANNEL_JOINED':
    case 'USER_CHANNEL_LEFT':
    case 'USER_AVATAR_UPLOAD':
    case 'UPDATE_USER':
    case CONFIRM_EMAIL_EMAIL_CONFIRMED_SUCCESS:
    case GET_SESSION_SUCCESS:
    case GET_SESSION_VIA_TOKEN_SUCCESS:
    case TEAM_UPDATE_SUCCESS:
    case TEAM_MEMBERSHIPS_UPDATE_SUCCESS:
    case MEMBER_UPDATE_SUCCESS:
      return {
        ...state,
        user: User(state.user, action),
      };

    case 'COMPANY_CHANNEL_JOINED':
    case 'COMPANY_CHANNEL_LEFT':
    case COMPANY_PROFILE_UPDATE_SUCCESS:
    case SUBSCRIPTION_CHANGE_SUCCESS:
    case COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        company: Company(state.company, action),
      };

    case ONBOARDING_SUCCESS:
      return {
        ...state,
        company: Company(state.company, action),
        user: User(state.user, action),
      };

    case 'RELEASE_CHANNEL_JOINED':
      return {
        ...state,
        release: action.payload,
      };

    case Constants.FETCH_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.payload.counters,
      };

    case Constants.MOCK_SESSION:
      if (action.payload.authenticated) {
        return {
          ...action.payload.session,
        };
      }
      return { ...sessionInitialState };

    default:
      return state;
  }
};
