import styled from 'styled-components';

import { Colors, mediaSizes } from 'components/utils/styles/ui';
import { Icon } from 'components/elements/icon';
import { Avatar as AvatarBase } from 'components/visual/avatar/avatar';
import { IconBadge } from 'components/misc/Badge';
import { mediaMaxWidth } from 'scenes/vendorHub/utils/media';

const media = mediaMaxWidth(mediaSizes.medium);

export const SupplierCard = styled.div`
  border: 0.1rem solid ${Colors.outline};
  position: relative;
  background-color: ${Colors.white};
  cursor: pointer;
  ${({ theme }) => theme.typography.text.normal};
`;

export const SupplierLogo = styled(AvatarBase)`
  position: absolute;
  box-sizing: content-box;
  top: -2.7rem;
  left: 2rem;
  border: solid 0.1rem ${Colors.outline};
  border-radius: 0.7rem;
  background: ${Colors.white};
  z-index: 2;
`;

export const SupplierName = styled.div`
  margin-left: 11rem;
  padding-top: 1.1rem;
  padding-right: 9.5rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ color }) => Colors[color]};
  ${({ theme }) => theme.typography.headline.h2};
`;

export const SupplierInfo = styled.div`
  margin: 3.6rem 2rem 2.8rem;
  display: flex;

  ${media`
    flex-direction: column;
  `}
`;

export const SupplierInfoCard = styled.div`
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media`
    margin: 0.6rem 0;
  `}
`;

export const SupplierInfoCardTitle = styled.div`
  color: ${Colors.tealDark};
  font-weight: 500;
`;

export const SupplierInfoCardValue = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0.8rem;
  flex-shrink: 0;

  ${media`
    margin-top: 0;
  `}
`;

export const SupplierWebsite = styled(SupplierInfoCardValue)`
  &:hover {
    text-decoration: underline;
  }
`;

export const SupplierCardIcon = styled(Icon)`
  margin-right: 0.5rem;
  flex-shrink: 0;
`;

export const SupplierCardValueText = styled.span`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SupplierCardLink = styled(Icon).attrs(({ iconColor }) => ({
  color: Colors[iconColor],
}))`
  margin-left: 0.5rem;
  margin-top: -0.5rem;
`;

export const ViewProfileLink = styled.span`
  position: absolute;
  top: 1.8rem;
  right: 2rem;
  font-weight: 500;

  color: ${Colors.tealDark};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

export const VerifiedBadge = styled(IconBadge)`
  position: absolute;
  top: 2.5rem;
  left: 7.5rem;
  z-index: 3;
`;
