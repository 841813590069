import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'app.components.Form.save',
    defaultMessage: 'Save',
  },
  saveChanges: {
    id: 'app.components.Form.saveChanges',
    defaultMessage: 'Save changes',
  },

  companyName: {
    id: 'app.components.companySettings.companyName',
    defaultMessage: 'Company name',
  },
  billingAddress: {
    id: 'app.components.Form.billingAddress',
    defaultMessage: 'Headquarters (city)',
  },
  email: {
    id: 'app.components.Form.email',
    defaultMessage: 'Email address',
  },
  password: {
    id: 'app.components.Form.password',
    defaultMessage: 'Password',
  },
  firstName: {
    id: 'app.components.Form.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'app.components.Form.lastName',
    defaultMessage: 'Last name',
  },
  passwordConfirmation: {
    id: 'app.components.Form.passwordConfirmation',
    defaultMessage: 'Password validation',
  },

  phoneNumber: {
    id: 'app.components.Form.phoneNumber',
    defaultMessage: 'Phone number',
  },

  industry: {
    id: 'app.components.companySettings.industry',
    defaultMessage: 'Industry',
  },
  companySize: {
    id: 'app.components.companySettings.companySize',
    defaultMessage: 'Company size / employees',
  },
  currency: {
    id: 'app.components.companySettings.currency',
    defaultMessage: 'Default currency',
  },
  currencyDescription: {
    id: 'app.components.Form.currencyDescription',
    defaultMessage: 'Set a default currency for your company',
  },

  firstNamePlaceholder: {
    id: 'app.components.Form.placeholder.firstName',
    defaultMessage: 'Enter first name',
  },
  lastNamePlaceholder: {
    id: 'app.components.Form.placeholder.lastName',
    defaultMessage: 'Enter last name',
  },

  passwordConfirmationPlaceholder: {
    id: 'app.components.Form.placeholder.passwordConfirmation',
    defaultMessage: 'Enter password again',
  },

  emailPlaceholder: {
    id: 'app.components.Form.placeholderemail',
    defaultMessage: 'Enter your work email address',
  },
  passwordPlaceholder: {
    id: 'app.components.Form.placeholder.password',
    defaultMessage: 'Enter your password',
  },

  phoneNumberPlaceholder: {
    id: 'app.components.Form.placeholder.phoneNumber',
    defaultMessage: 'Enter phone number',
  },

  companyNamePlaceholder: {
    id: 'app.components.JobForm.placeholder.companyNamePlaceholder',
    defaultMessage: 'Enter company name',
  },
  billingAddressPlaceholder: {
    id: 'app.components.Form.placeholder.billingAddressPlaceholder',
    defaultMessage: 'Enter the city where your headquarters is located',
  },

  industrySelectPlaceholder: {
    id: 'app.components.companySettings.select.placeholder.industry',
    defaultMessage: 'Select industry',
  },
  companySizePlaceholder: {
    id: 'app.components.companySettings.placeholder.companySize',
    defaultMessage: 'Select company size',
  },

  uploadDocSizeError: {
    id: 'app.components.Form.uploadDocSizeError',
    defaultMessage: 'The file is too large. Files must be smaller than {max}.',
  },
  uploadDocExtError: {
    id: 'app.components.Form.uploadDocExtError',
    defaultMessage:
      'Uploading {ext} files is not allowed. Please check our Help Center for "Permitted file extensions".',
  },
  uploadDimensionsMinError: {
    id: 'app.components.Form.uploadDimensionsMinError',
    defaultMessage: 'Image too small, please check the minimum dimensions.',
  },
  uploadDimensionsMaxError: {
    id: 'app.components.Form.uploadDimensionsMaxError',
    defaultMessage: 'Image too big, please check the maximum dimensions.',
  },
  uploadFilesLimitError: {
    id: 'app.components.Form.uploadFilesLimitError',
    defaultMessage:
      'Too many files selected. Please upload a maximum of {max} files at once.',
  },

  uploadPdfReq: {
    id: 'app.components.Form.uploadDoc.uploadPdfReq',
    defaultMessage: 'Only PDF files with a maximum size of 10MB are allowed',
  },
  uploadPdfDocReq: {
    id: 'app.components.Form.uploadDoc.uploadPdfDocReq',
    defaultMessage:
      'Only PDF and DOC files with a maximum size of 10MB are allowed',
  },
  uploadPdfImageReq: {
    id: 'app.components.Form.uploadDoc.uploadPdfImageReq',
    defaultMessage:
      'Only PDF, JPG and PNG files with a maximum size of 10MB are allowed',
  },
  uploadPdfDocImageReq: {
    id: 'app.components.Form.uploadDoc.uploadPdfDocImageReq',
    defaultMessage:
      'Only PDF, DOC, JPG and PNG files with a maximum size of {size}MB are allowed',
  },

  dateFormat: {
    id: 'app.components.Form.dateFormat',
    defaultMessage: 'Date Format',
  },
  dateFormatDescription: {
    id: 'app.components.Form.dateFormatDescription',
    defaultMessage: 'Set a default date format for your company',
  },
});
