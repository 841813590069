import PropTypes from 'prop-types';

/* eslint react/style-prop-object: 0 */
export const CandidatelyLogo = function Candidately({
  className,
  incrementalFactor = 1,
}) {
  const width = 45 * incrementalFactor;
  const height = 65 * incrementalFactor;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 185 35"
    >
      <path
        d="M48.9745 21.4446C49.3581 21.4446 49.7167 21.3957 50.0507 21.2974C50.3845 21.1987 50.6598 21.0804 50.8767 20.9408C51.0936 20.8016 51.2856 20.662 51.4529 20.5224C51.6195 20.3832 51.7365 20.2644 51.8034 20.1657L51.9034 20.0185L53.881 21.9854C53.8309 22.0511 53.7643 22.1326 53.681 22.2309C53.5976 22.3291 53.3967 22.5056 53.0805 22.7592C52.7631 23.0139 52.4291 23.2389 52.0792 23.4355C51.7286 23.6325 51.2736 23.809 50.7149 23.9637C50.1557 24.1199 49.5757 24.1978 48.9749 24.1978C47.1888 24.1978 45.6789 23.604 44.444 22.4154C43.209 21.2272 42.5918 19.7811 42.5918 18.0762C42.5918 16.3553 43.209 14.9047 44.444 13.7246C45.6789 12.5444 47.1892 11.9539 48.9749 11.9539C49.9095 11.9539 50.781 12.1344 51.591 12.495C52.4002 12.8557 52.9715 13.2167 53.3057 13.5769L53.8313 14.1181L51.7535 15.9369C51.6865 15.839 51.5823 15.7243 51.4407 15.5931C51.2985 15.4623 50.9857 15.2822 50.5016 15.0523C50.0173 14.8233 49.5084 14.7082 48.9745 14.7082C47.9897 14.7082 47.1884 15.0359 46.5712 15.6918C45.9536 16.3476 45.6449 17.1419 45.6449 18.0762C45.6449 19.0105 45.9532 19.8059 46.5712 20.461C47.1884 21.1173 47.9897 21.4446 48.9745 21.4446Z"
        fill="#0B3954"
      />
      <path
        d="M64.3951 12.2499H67.1737V23.9032H64.3951V22.3793C64.3616 22.4282 64.3199 22.4936 64.2697 22.5754C64.22 22.6573 64.0858 22.8049 63.8694 23.0183C63.6521 23.2313 63.4189 23.4158 63.169 23.5711C62.9182 23.7275 62.5842 23.8703 62.1674 24.0015C61.7501 24.133 61.2996 24.1982 60.8155 24.1982C59.2467 24.1982 57.8907 23.6168 56.7479 22.4523C55.6047 21.2886 55.0332 19.83 55.0332 18.0762C55.0332 16.3224 55.6047 14.8638 56.7479 13.7001C57.8907 12.5364 59.2467 11.9539 60.8155 11.9539C61.5494 11.9539 62.2132 12.1059 62.8059 12.4088C63.3976 12.7125 63.8111 13.0197 64.0452 13.331L64.3956 13.7739V12.2499H64.3951ZM64.17 19.822V16.3308C64.153 16.2819 64.1194 16.2205 64.0696 16.1467C64.0197 16.0725 63.911 15.9461 63.7442 15.7656C63.5771 15.5851 63.394 15.4214 63.1935 15.2734C62.9934 15.1262 62.7298 14.995 62.4047 14.8802C62.0792 14.7659 61.7325 14.7082 61.3661 14.7082C60.3646 14.7082 59.5673 15.0239 58.9752 15.6549C58.3824 16.2855 58.0867 17.0926 58.0867 18.0762C58.0867 19.0598 58.3829 19.8669 58.9752 20.4975C59.5673 21.1285 60.3646 21.4442 61.3661 21.4442C61.933 21.4442 62.4505 21.3086 62.9178 21.0382C63.3847 20.7679 63.7102 20.4935 63.8945 20.2147L64.17 19.822Z"
        fill="#0B3954"
      />
      <path
        d="M76.5356 11.9542C77.7368 11.9542 78.7633 12.3522 79.6143 13.1472C80.4656 13.9419 80.8912 15.011 80.8912 16.3556V23.9032H77.8871V17.0925C77.8871 16.2898 77.6702 15.6917 77.2369 15.2978C76.8026 14.9047 76.2353 14.7077 75.5344 14.7077C75.251 14.7077 74.9752 14.761 74.7086 14.8674C74.442 14.9749 74.216 15.0968 74.0331 15.236C73.8492 15.3764 73.6908 15.5188 73.5571 15.666C73.4236 15.8133 73.3322 15.9364 73.282 16.0351L73.1819 16.2076V23.9028H70.1777V12.2495H72.9818V13.9455C73.0148 13.8962 73.0605 13.8268 73.1193 13.7365C73.1778 13.6463 73.3113 13.4866 73.5197 13.2571C73.7279 13.0277 73.9579 12.8271 74.2083 12.6546C74.4588 12.4825 74.7919 12.3229 75.2093 12.1761C75.6256 12.028 76.0678 11.9542 76.5356 11.9542Z"
        fill="#0B3954"
      />
      <path
        d="M95.2343 5.31642V23.9032H92.4557V22.3792C92.4217 22.4281 92.3806 22.4935 92.3307 22.5753C92.2805 22.6572 92.147 22.8048 91.9305 23.0182C91.7133 23.2312 91.4796 23.4157 91.2292 23.571C90.9787 23.7274 90.6447 23.8702 90.228 24.0015C89.8107 24.133 89.3601 24.1979 88.8756 24.1979C87.3072 24.1979 85.9508 23.6167 84.8084 22.4522C83.6648 21.2885 83.0938 19.8299 83.0938 18.0761C83.0938 16.3223 83.6648 14.8637 84.8084 13.7C85.9508 12.5362 87.3072 11.9538 88.8756 11.9538C89.5436 11.9538 90.1566 12.0805 90.7157 12.3349C91.2749 12.5896 91.6712 12.8395 91.9046 13.0846L92.2303 13.4777V5.31561H95.2343V5.31642ZM92.2299 19.8219V16.3307C92.1632 16.2164 92.0673 16.0691 91.9423 15.8878C91.8168 15.7081 91.5081 15.4662 91.0159 15.1625C90.5233 14.8593 89.9854 14.7076 89.401 14.7076C88.3998 14.7076 87.607 15.0233 87.0234 15.6544C86.4385 16.285 86.1468 17.0921 86.1468 18.0757C86.1468 19.0593 86.4385 19.8664 87.0234 20.497C87.607 21.128 88.3998 21.4437 89.401 21.4437C89.7682 21.4437 90.119 21.3863 90.4527 21.2716C90.7859 21.1569 91.0572 21.0217 91.2664 20.866C91.475 20.71 91.654 20.5548 91.8047 20.3983C91.9546 20.2431 92.0632 20.1079 92.1302 19.9928L92.2299 19.8219Z"
        fill="#0B3954"
      />
      <path
        d="M99.1636 9.86498C98.7799 9.48789 98.5879 9.029 98.5879 8.48745C98.5879 7.94671 98.7795 7.48779 99.1636 7.11073C99.5472 6.73365 100.015 6.5451 100.566 6.5451C101.116 6.5451 101.584 6.73365 101.968 7.11073C102.352 7.48739 102.544 7.94671 102.544 8.48745C102.544 9.029 102.352 9.48749 101.968 9.86498C101.584 10.2417 101.116 10.4298 100.566 10.4298C100.015 10.4298 99.5472 10.242 99.1636 9.86498ZM99.0641 23.9032V12.2498H102.068V23.9032H99.0641Z"
        fill="#0B3954"
      />
      <path
        d="M116.889 5.31642V23.9032H114.11V22.3792C114.076 22.4281 114.035 22.4935 113.985 22.5753C113.935 22.6572 113.801 22.8048 113.585 23.0182C113.367 23.2312 113.134 23.4157 112.884 23.571C112.633 23.7274 112.299 23.8702 111.883 24.0015C111.465 24.133 111.014 24.1979 110.53 24.1979C108.962 24.1979 107.606 23.6167 106.463 22.4522C105.32 21.2885 104.748 19.8299 104.748 18.0761C104.748 16.3223 105.32 14.8637 106.463 13.7C107.606 12.5362 108.962 11.9538 110.53 11.9538C111.198 11.9538 111.811 12.0805 112.37 12.3349C112.929 12.5896 113.326 12.8395 113.559 13.0846L113.885 13.4777V5.31561H116.889V5.31642ZM113.885 19.8219V16.3307C113.818 16.2164 113.722 16.0691 113.597 15.8878C113.472 15.7081 113.163 15.4662 112.671 15.1625C112.178 14.8593 111.64 14.7076 111.057 14.7076C110.055 14.7076 109.262 15.0233 108.678 15.6544C108.094 16.285 107.802 17.0921 107.802 18.0757C107.802 19.0593 108.093 19.8664 108.678 20.497C109.262 21.128 110.055 21.4437 111.057 21.4437C111.423 21.4437 111.774 21.3863 112.108 21.2716C112.441 21.1569 112.712 21.0217 112.921 20.866C113.13 20.71 113.309 20.5548 113.46 20.3983C113.61 20.2431 113.718 20.1079 113.785 19.9928L113.885 19.8219Z"
        fill="#0B3954"
      />
      <path
        d="M128.804 12.2499H131.583V23.9032H128.804V22.3793C128.77 22.4282 128.729 22.4936 128.679 22.5754C128.629 22.6573 128.495 22.8049 128.279 23.0183C128.061 23.2313 127.828 23.4158 127.577 23.5711C127.327 23.7275 126.993 23.8703 126.576 24.0015C126.159 24.133 125.708 24.1982 125.224 24.1982C123.655 24.1982 122.299 23.6168 121.156 22.4523C120.013 21.2886 119.441 19.83 119.441 18.0762C119.441 16.3224 120.013 14.8638 121.156 13.7001C122.299 12.5364 123.655 11.9539 125.224 11.9539C125.959 11.9539 126.621 12.1059 127.215 12.4088C127.807 12.7125 128.22 13.0197 128.454 13.331L128.804 13.7739V12.2499ZM128.579 19.822V16.3308C128.562 16.2819 128.529 16.2205 128.479 16.1467C128.429 16.0725 128.32 15.9461 128.154 15.7656C127.986 15.5851 127.803 15.4214 127.603 15.2734C127.403 15.1262 127.139 14.995 126.814 14.8802C126.489 14.7659 126.142 14.7082 125.775 14.7082C124.774 14.7082 123.977 15.0239 123.384 15.6549C122.792 16.2855 122.496 17.0926 122.496 18.0762C122.496 19.0598 122.792 19.8669 123.384 20.4975C123.977 21.1285 124.774 21.4442 125.775 21.4442C126.342 21.4442 126.859 21.3086 127.327 21.0382C127.794 20.7679 128.12 20.4935 128.304 20.2147L128.579 19.822Z"
        fill="#0B3954"
      />
      <path
        d="M133.635 14.905V12.2502H135.962V8.78314H138.967V12.2502H142.546V14.905H138.967V19.6008C138.967 20.8299 139.484 21.4449 140.519 21.4449C140.719 21.4449 140.923 21.4124 141.132 21.3466C141.34 21.2812 141.495 21.2154 141.595 21.1497L141.77 21.0265L142.872 23.4362C142.121 23.9448 141.169 24.1983 140.018 24.1983C138.85 24.1983 137.881 23.8176 137.114 23.0555C136.346 22.2929 135.962 21.2973 135.962 20.0682V14.905H133.635Z"
        fill="#0B3954"
      />
      <path
        d="M150.157 11.9542C151.826 11.9542 153.24 12.5287 154.4 13.6755C155.56 14.8232 156.14 16.2734 156.14 18.0276C156.14 18.4704 156.131 18.8387 156.115 19.1339H147.253C147.387 19.7244 147.757 20.2648 148.367 20.7566C148.976 21.2484 149.715 21.4938 150.583 21.4938C151.2 21.4938 151.78 21.396 152.322 21.199C152.865 21.0025 153.253 20.8063 153.487 20.609L153.837 20.3141L155.339 22.5272C155.205 22.658 155.005 22.8144 154.738 22.9942C154.472 23.1747 153.924 23.4206 153.098 23.7319C152.272 24.0431 151.434 24.1987 150.583 24.1987C148.78 24.1987 147.266 23.6091 146.039 22.4289C144.813 21.2488 144.199 19.7978 144.199 18.0769C144.199 16.356 144.766 14.9055 145.901 13.7253C147.036 12.5447 148.455 11.9542 150.157 11.9542ZM147.253 16.9946H153.136C153.102 16.4041 152.823 15.8638 152.297 15.3724C151.771 14.8802 151.058 14.6347 150.157 14.6347C149.272 14.6347 148.584 14.877 148.092 15.3595C147.599 15.8437 147.32 16.3885 147.253 16.9946Z"
        fill="#0B3954"
      />
      <path
        d="M158.717 23.6327C158.333 23.2561 158.141 22.7963 158.141 22.2556C158.141 21.7149 158.333 21.2564 158.717 20.8785C159.101 20.5022 159.567 20.3133 160.118 20.3133C160.669 20.3133 161.136 20.5022 161.52 20.8785C161.904 21.256 162.096 21.7149 162.096 22.2556C162.096 22.7963 161.904 23.2557 161.52 23.6327C161.136 24.0089 160.669 24.1979 160.118 24.1979C159.567 24.1983 159.101 24.0093 158.717 23.6327Z"
        fill="#F36B21"
      />
      <path
        d="M165.25 23.9031V5.31638H168.254V23.9031H165.25Z"
        fill="#0B3954"
      />
      <path
        d="M170.608 28.6238L172.01 26.3374C172.544 26.8456 173.07 27.0995 173.587 27.0995C174.105 27.0995 174.501 26.9892 174.777 26.7673C175.052 26.546 175.331 26.0829 175.615 25.3781L176.091 24.1737L170.309 12.2499H173.637L177.643 20.7811L180.772 12.2499H184.001L178.244 26.509C177.342 28.7383 175.857 29.853 173.788 29.853C173.253 29.853 172.719 29.7508 172.186 29.5457C171.651 29.3403 171.259 29.1318 171.009 28.9188L170.608 28.6238Z"
        fill="#0B3954"
      />
      <circle cx="27.0827" cy="29.25" r="5.41667" fill="#F36B21" />
      <mask
        id="mask0_165_19290"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="35"
        height="35"
      >
        <circle
          r="17.3333"
          transform="matrix(-1 0 0 1 17.3327 17.3333)"
          fill="#F36B21"
        />
      </mask>
      <g mask="url(#mask0_165_19290)">
        <rect
          width="16.8889"
          height="34.6667"
          transform="matrix(-1 0 0 1 16.8887 0)"
          fill="#0B748C"
        />
        <rect
          width="16.8889"
          height="34.6667"
          transform="matrix(-1 0 0 1 16.8887 -0.0176697)"
          fill="#0B3954"
        />
      </g>
    </svg>
  );
};

CandidatelyLogo.propTypes = {
  className: PropTypes.string,
  incrementalFactor: PropTypes.number,
};
