import {
  showLoading,
  hideLoading,
  resetLoading,
} from 'react-redux-loading-bar';

import { Constants } from '../constants';

export {
  showLoading as showProgress,
  hideLoading as hideProgress,
  resetLoading as resetProgress,
};

export function openPageLoading() {
  return {
    type: Constants.OPEN_PAGE_LOADING,
  };
}
export function closePageLoading() {
  return {
    type: Constants.CLOSE_PAGE_LOADING,
  };
}

// CONFIRMATION MODAL USAGE
// this.props.dispatch(UiActions.openModal({
//   type: 'confirmation',
//   title: 'Are you sure you want to destroy our precious planet?',
//   confirmLabel: 'Yes, destroy',
//   cancelLabel: 'No, cancel',
//   onConfirm: () => console.log('onConfirm'),
//   onCancel: () =>console.log('cancel'),
//   component: <CustomModalContent /> ,
// }))

export function openModal(obj) {
  return {
    type: Constants.OPEN_MODAL,
    obj,
  };
}
export function closeModal(obj) {
  return {
    type: Constants.CLOSE_MODAL,
    obj,
  };
}

export function openSideModal(payload) {
  return {
    type: Constants.OPEN_SIDEMODAL,
    payload,
  };
}
export function closeSideModal(payload) {
  return {
    type: Constants.CLOSE_SIDEMODAL,
    payload,
  };
}
