import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

import { Button as ButtonBase } from 'components/elements/button';
import { DeleteButton as DeleteButtonBase } from 'components/visual/buttons/delete';

import { FormLabel as LabelBase } from 'components/form/label/label';

export const Button = styled(ButtonBase)`
  height: 3rem;
  margin: ${({ itemsCount }) => (itemsCount > 0 ? '2rem 0' : '-1rem 0 2rem')};
`;

export const Label = styled(LabelBase)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Input = styled.div`
  border-top: ${({ noSeparator, index = 1 }) => {
    if (noSeparator) {
      return 0;
    }

    return index > 1 ? `solid 1px ${Colors.greyDark}` : 0;
  }};
  position: relative;
  margin: 2rem 0;
  padding: ${({ index = 1 }) => (index > 1 ? '1rem 0 0' : '0')};
  width: 100%;

  &:first-child {
    margin: 0;
  }
`;

export const Delete = styled(DeleteButtonBase)`
  position: absolute;
  right: -2.5rem;
  top: ${({ index }) => (index === 1 ? 4 : 5)}rem;
  ${({ noLabelCount }) => (noLabelCount ? 'top: 2rem;' : null)}
`;
