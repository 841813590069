import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';

import { Icon } from 'components/elements/icon';
import { ProgressBar } from 'components/misc/ProgressBar';

import i18n from '../i18n';
import * as styled from '../styles/bottomSection';

export const JobsLimit = (props) => {
  const { company } = props;
  const jobs = get(company, 'subscription_period.jobs', 0);
  const limit =
    isEmpty(company.communities) && !company.subscription_plan ? 5 : null;
  const left = Math.max(0, limit - jobs);

  if (!limit) {
    return null;
  }

  return (
    <styled.Bottom>
      <styled.JobsLimit>
        <styled.Header>
          <Icon icon="FileText" />
          <FormattedMessage
            {...i18n.subscriptionJobsLeft}
            values={{ limit, jobs: left }}
          />
        </styled.Header>
        <styled.ProgressBar>
          <ProgressBar
            progress={(100 * left) / limit}
            hasLabel={false}
            fillColor="green"
            bordered
          />
        </styled.ProgressBar>
        <styled.SmartLink to="/subscription-plan">
          <FormattedMessage {...i18n.subscriptionUpgrade} />
        </styled.SmartLink>
      </styled.JobsLimit>
    </styled.Bottom>
  );
};

JobsLimit.propTypes = {
  company: PropTypes.object,
};
