import { css } from 'styled-components';

import { iconElementsSelector } from 'components/elements/styles/icon';

import { Colors } from './ui';

type MenuActiveItemIconProps = {
  skipIcon: boolean;
  active: boolean;
};

type MenuActiveText = {
  active: boolean;
};

type ThemedButtonProps = {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
};

export const menuActiveItemIcon = ({
  skipIcon,
  active,
}: MenuActiveItemIconProps) =>
  !skipIcon &&
  active &&
  css`
    ${iconElementsSelector} {
      stroke: ${Colors.tealDark};
    }
  `;

export const menuActiveText = ({ active }: MenuActiveText) =>
  active &&
  css`
    font-weight: 500;
    color: ${Colors.tealDark};
  `;

export const menuItemMixin = () =>
  css`
    ${menuActiveText}
    ${menuActiveItemIcon}

    &:hover {
      background-color: ${Colors.tealLightest};
    }
  `;

export const themedButtonMixin = ({
  textColor,
  backgroundColor,
  borderColor,
}: ThemedButtonProps) => css`
  color: ${textColor};

  &,
  &:hover,
  &:focus {
    background-color: ${backgroundColor};
    border-color: ${borderColor};
  }

  & ${iconElementsSelector} {
    stroke: ${textColor};
  }
`;
