import styled from 'styled-components';

import { Colors, bottomShadow } from 'components/utils/styles/ui';
import { Button as BaseButton } from 'components/elements/button';
import { Icon as IconBase } from 'components/elements/icon';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 1.6rem;
  left: 7rem;
  z-index: 999999;
  width: 30rem;
  padding: 2rem;
  border-radius: 0.4rem;
  border: 1px solid ${Colors.outline};
  background-color: ${Colors.tealLightest};
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: ${Colors.text};
  box-shadow: ${bottomShadow};
`;

export const Header = styled.h4`
  color: ${Colors.text};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 1.2rem 0;
  color: ${Colors.textLight};
`;

export const Button = styled(BaseButton)`
  background-color: ${Colors.tealDark};
  width: 100%;
`;

export const Icon = styled(IconBase)`
  margin-right: 0.6rem;
`;
