import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isObject, reject } from 'lodash';

import { KEYS } from 'components/utils/keys';

import * as styled from './styles';

class ToolbarFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  handleApplyFilter = () => {
    const { onChange, filtered } = this.props;
    const { value } = this.state;

    onChange([
      ...reject(filtered, { id: 'g-query' }),
      { id: 'g-query', value },
    ]);
  };

  handleOnChange = (event) => {
    const { value } = event.target;

    this.setState({ value });
  };

  render() {
    const { intl, placeholder, button } = this.props;
    const { value } = this.state;

    return (
      <styled.ToolbarFilter>
        <styled.ToolbarInput
          type="text"
          value={value}
          placeholder={
            isObject(placeholder)
              ? intl.formatMessage(placeholder)
              : placeholder
          }
          onChange={this.handleOnChange}
          onKeyDown={(event) =>
            event.keyCode === KEYS.ENTER && this.handleApplyFilter()
          }
        />
        <styled.ToolbarSubmit size="small" onClick={this.handleApplyFilter}>
          <FormattedMessage {...button} />
        </styled.ToolbarSubmit>
      </styled.ToolbarFilter>
    );
  }
}

ToolbarFilterComponent.propTypes = {
  intl: PropTypes.object,
  filtered: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.object,
  button: PropTypes.object,
};

export const ToolbarFilter = injectIntl(ToolbarFilterComponent);
