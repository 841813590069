import { IconBase } from './iconBase';

import { CustomIconsProp } from '../types';

export const Gustav = ({ size, color, className }: CustomIconsProp) => (
  <IconBase className={className} viewBox={30.54} size={size}>
    <g fill={color} fillRule="nonzero" transform="translate(5)">
      <path d="M10.856 23.215c-2.994 0-5.554-.85-7.646-2.552L.289 24.137c2.92 2.374 6.491 3.58 10.567 3.58 4.147 0 7.718-1.206 10.675-3.58l-2.921-3.474c-2.092 1.702-4.689 2.552-7.754 2.552zM10.567.319c-2.633.07-4.869.992-6.672 2.765-1.911 1.843-2.885 4.11-2.885 6.77 0 2.657.974 4.926 2.885 6.769 1.912 1.843 4.256 2.764 6.997 2.764 2.74 0 5.085-.921 6.997-2.764 1.911-1.843 2.885-4.112 2.885-6.77 0-1.985-.541-3.721-1.551-5.245h2.705V.319h-11.36zm4.003 5.813c.938 1.027 1.407 2.268 1.407 3.721 0 1.453-.469 2.694-1.407 3.722-.937 1.028-2.163 1.524-3.714 1.524-1.551 0-2.777-.496-3.715-1.524-.938-1.028-1.407-2.269-1.407-3.722 0-1.453.47-2.694 1.407-3.721.938-1.028 2.164-1.524 3.715-1.524 1.55 0 2.777.496 3.714 1.524z" />
      <path d="M10.7432876,23.3990802 C7.69933916,23.3990802 5.09663108,22.5304037 2.96973058,20.7910066 L0,24.3413388 C2.9687139,26.7675013 6.59928832,28 10.7432876,28 C14.9594713,28 18.5900458,26.7675013 21.5963769,24.3413388 L18.6266463,20.7910066 C16.4997458,22.5304037 13.8594205,23.3990802 10.7432876,23.3990802 Z M10.4494662,0 C7.77254032,0.0715380685 5.49923749,1.01379663 3.66615833,2.8257537 C1.72327742,4.70924885 0.733028328,7.0260603 0.733028328,9.7445069 C0.733028328,12.4598876 1.72327742,14.778743 3.66615833,16.6622381 C5.61005592,18.5457333 7.9931605,19.4869699 10.7798882,19.4869699 C13.5655991,19.4869699 15.9497204,18.5457333 17.893618,16.6622381 C19.8364989,14.778743 20.826748,12.4598876 20.826748,9.74348493 C20.826748,7.7148697 20.2767226,5.9407256 19.2498729,4.38323965 L22,4.38323965 L22,0 L10.4504829,0 L10.4494662,0 Z M14.5192477,5.9407256 C15.4728962,6.99029126 15.9497204,8.25855902 15.9497204,9.74348493 C15.9497204,11.2284108 15.4728962,12.4966786 14.5192477,13.5472662 C13.5666158,14.5978539 12.3201627,15.1047522 10.7432876,15.1047522 C9.1664125,15.1047522 7.91995933,14.5978539 6.96631083,13.5472662 C6.01266232,12.4966786 5.53583807,11.2284108 5.53583807,9.74348493 C5.53583807,8.25855902 6.01367901,6.99029126 6.96631083,5.9407256 C7.91995933,4.89013797 9.1664125,4.38323965 10.7432876,4.38323965 C12.319146,4.38323965 13.5666158,4.89013797 14.5192477,5.9407256 Z" />
    </g>
  </IconBase>
);
