import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

import { SmartLink } from '../../SmartLink';

export const TextSmartLink = styled(SmartLink)`
  color: ${({ color }) => (color ? Colors[color] : Colors.text)};
  ${({ underline }) => (underline ? 'text-decoration: underline;' : null)}

  &:hover {
    text-decoration: underline;
    color: ${Colors.tealDark};
  }
`;
