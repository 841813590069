export const COMPANY_DOCUMENTS_FETCH_REQUEST =
  'gustav/company/documents/FETCH_REQUEST';
export const COMPANY_DOCUMENTS_FETCH_SUCCESS =
  'gustav/company/documents/FETCH_SUCCESS';
export const COMPANY_DOCUMENTS_FETCH_ERROR =
  'gustav/company/documents/FETCH_ERROR';

export const COMPANY_DOCUMENT_UPLOAD_REQUEST =
  'gustav/company/documents/UPLOAD_REQUEST';
export const COMPANY_DOCUMENT_UPLOAD_SUCCESS =
  'gustav/company/documents/UPLOAD_SUCCESS';
export const COMPANY_DOCUMENT_UPLOAD_ERROR =
  'gustav/company/documents/UPLOAD_ERROR';

export const COMPANY_DOCUMENT_RENAME_REQUEST =
  'gustav/company/documents/RENAME_REQUEST';
export const COMPANY_DOCUMENT_RENAME_SUCCESS =
  'gustav/company/documents/RENAME_SUCCESS';
export const COMPANY_DOCUMENT_RENAME_ERROR =
  'gustav/company/documents/RENAME_ERROR';

export const COMPANY_DOCUMENT_DELETE_REQUEST =
  'gustav/company/documents/DELETE_REQUEST';
export const COMPANY_DOCUMENT_DELETE_SUCCESS =
  'gustav/company/documents/DELETE_SUCCESS';
export const COMPANY_DOCUMENT_DELETE_ERROR =
  'gustav/company/documents/DELETE_ERROR';
