import { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, keys } from 'lodash';

import magnifyingGlassIllustration from 'images/illustrations/magnifyingGlass.png';
import briefcaseIllustration from 'images/illustrations/briefcase.png';
import certificateIllustration from 'images/illustrations/certificate.png';
import clipboardIllustration from 'images/illustrations/clipboard.png';
import handshakeIllustration from 'images/illustrations/handshake.png';
import searchIllustration from 'images/illustrations/search.png';

import { SidebarConsumer } from 'components/structure/SidebarContext';
import { ButtonSet } from 'components/misc/ButtonSet';

import * as styled from './styles';
import i18n from './utils/i18n';

const ILLUSTRATIONS = {
  magnifyingGlass: magnifyingGlassIllustration,
  briefcase: briefcaseIllustration,
  certificate: certificateIllustration,
  clipboard: clipboardIllustration,
  handshake: handshakeIllustration,
  search: searchIllustration,
};

const Props = {
  title: PropTypes.node,
  children: PropTypes.node,
  illustration: PropTypes.oneOf(keys(ILLUSTRATIONS)),
  actions: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  small: PropTypes.bool,
};

const DefaultProps = {
  title: <FormattedMessage {...i18n.defaultTitle} />,
  children: null,
  illustration: 'magnifyingGlass',
  actions: [],
  className: '',
  small: false,
};

export const EmptyState = function EmptyState(props) {
  const { title, children, illustration, actions, className, small } = props;

  return (
    <SidebarConsumer>
      {({ expanded: sidebarExpanded }) => (
        <styled.Container
          className={className}
          sidebarExpanded={sidebarExpanded}
        >
          <styled.Content>
            <styled.Header>
              <styled.IllustrationContainer small={small}>
                <styled.Illustration
                  src={get(
                    ILLUSTRATIONS,
                    illustration,
                    magnifyingGlassIllustration
                  )}
                />
              </styled.IllustrationContainer>
              <styled.Title small={small}>{title}</styled.Title>
            </styled.Header>

            <styled.Body>{children}</styled.Body>

            <styled.Footer>
              <ButtonSet align="center">
                {Children.map(actions, (action) =>
                  cloneElement(action, { key: 'action' })
                )}
              </ButtonSet>
            </styled.Footer>
          </styled.Content>
        </styled.Container>
      )}
    </SidebarConsumer>
  );
};

EmptyState.propTypes = Props;
EmptyState.defaultProps = DefaultProps;

export { Props, DefaultProps };
