import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { Colors } from 'components/utils/styles/ui';
import { Icon as IconBase } from 'components/elements/icon';

export const Icon = styled(IconBase)`
  position: absolute;
  left: 1rem;
  top: 0.4rem;
`;

export const Text = styled.span`
  margin-left: 2.4rem;
`;

const variableThemeMixin = ({ color, borderColor, backgroundColor }) =>
  css`
    background-color: ${Colors[backgroundColor]};
    color: ${Colors[color]};
    border-color: ${Colors[borderColor]};
  `;

export const VariableContent = styled.div`
  display: inline-block;
`;

const editableWrapperMixin = css`
  cursor: pointer;
`;

const noneditableWrapperMixin = css`
  cursor: default;
`;

export const VariableWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  line-height: 2.4rem;
  padding: 0 1rem;
  margin: 0 0.1rem;
  border-radius: 0.2rem;
  border-width: 1px;
  border-style: solid;
  height: 2.6rem;

  ${({ editable }) =>
    editable ? editableWrapperMixin : noneditableWrapperMixin}

  ${variableThemeMixin};
`;

export const TooltipWrapper = styled.div`
  display: inline;
`;

export const Tooltip = styled(Tippy)`
  display: inline;

  .tippy-svg-arrow {
    svg {
      fill: ${({ arrowColor }) => Colors[arrowColor]};
    }
  }
`;

export const TooltipContent = styled.div`
  display: grid;
  background-color: ${Colors.white};
  border-radius: 0.3rem;
  grid-template-areas: 'title title' 'label input';
  grid-template-columns: auto 1fr;
  filter: drop-shadow(0px 0px 16px rgba(19, 41, 61, 0.08))
    drop-shadow(0px 6px 16px rgba(19, 41, 61, 0.12));
`;

export const TooltipContentTitle = styled.div`
  padding: 0.8rem 1.2rem;
  grid-area: title;
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: ${Colors.labelBg};
  color: ${Colors.textLight};
`;

export const TooltipContentLabel = styled.div`
  padding: 0.8rem 1.2rem;
  grid-area: label;
  border-bottom-left-radius: 0.3rem;
  color: ${Colors.text};
  font-weight: 500;
  border-right: 1px solid ${Colors.outline};
`;

export const TooltipContentInput = styled.input`
  padding: 0.8rem 1.2rem;
  grid-area: input;
  border-bottom-right-radius: 0.3rem;
  color: ${Colors.text};
  width: 100%;
  border: none;
  outline: none;

  &::placeholder {
    color: ${Colors.textLighter};
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
    visibility: hidden;
  }
`;
