const filterByName = (supplier, filters) => {
  if (!filters.name) {
    return true;
  }
  return supplier.name.toLowerCase().includes(filters.name.toLowerCase());
};

const filterByHeadquarters = (supplier, filters) => {
  if (!filters.city || !filters.state || !supplier.billing_address) {
    return true;
  }

  return (
    filters.city === supplier.billing_address.city &&
    filters.state === supplier.billing_address.state_id
  );
};

const filterByIndustries = (supplier, filters) => {
  if (!filters.industries) {
    return true;
  }
  if (filters.industries && !supplier.industries) {
    return false;
  }
  return (
    supplier.industries.filter(
      (supplierIndustry) => supplierIndustry.id === filters.industries
    ).length > 0
  );
};

const filterBySpecializations = (supplier, filters) => {
  if (!filters.specializations) {
    return true;
  }
  if (filters.specializations || !supplier.specializations) {
    return false;
  }
  return (
    supplier.specializations.filter(
      (supplierSpecialization) =>
        supplierSpecialization.id === filters.specializations
    ).length > 0
  );
};

export const filterSuppliers = (suppliers, filters) => {
  if (!suppliers) {
    return [];
  }
  return suppliers.filter(
    (supplier) =>
      filterByName(supplier, filters) &&
      filterByHeadquarters(supplier, filters) &&
      filterByIndustries(supplier, filters) &&
      filterBySpecializations(supplier, filters)
  );
};
