import { fromJS } from 'immutable';

import { SPECIALIZATIONS_SUCCESS, SPECIALIZATIONS_ERROR } from './constants';

const initialState = fromJS({
  errors: null,
  specializations: [],
});

export function specializationsReducer(state = initialState, action) {
  switch (action.type) {
    case SPECIALIZATIONS_ERROR:
      return state.set('errors', action.errors);
    case SPECIALIZATIONS_SUCCESS:
      return state.set('specializations', action.specializations);
    default:
      return state;
  }
}
