import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonSelect: {
    id: 'app.components.Form.UploadWithBullhorn.buttonSelect',
    defaultMessage: 'Select file',
  },
  buttonRemove: {
    id: 'app.components.Form.UploadWithBullhorn.buttonRemove',
    defaultMessage: 'Remove',
  },
  inputPlaceholder: {
    id: 'app.components.Form.UploadWithBullhorn.inputPlaceholder',
    defaultMessage: 'Select a file',
  },
  uploadFile: {
    id: 'app.components.form.UploadWithBullhorn.uploadFile',
    defaultMessage: 'Upload a file',
  },
  uploadFileDescription: {
    id: 'app.components.form.UploadWithBullhorn.uploadFileDescription',
    defaultMessage: 'Select a file from your computer',
  },
  importFromBullhorn: {
    id: 'app.components.form.UploadWithBullhorn.importFromBullhorn',
    defaultMessage: 'Import file from candidate in Bullhorn',
  },
  importFromBullhornDescription: {
    id: 'app.components.form.UploadWithBullhorn.importFromBullhornDescription',
    defaultMessage: 'Select a file from a candidate record',
  },
  bullhornModalTitle: {
    id: 'app.components.form.UploadWithBullhorn.bullhornModalTitle',
    defaultMessage: 'Select file from Bullhorn',
  },
  bullhornModalDescription: {
    id: 'app.components.form.UploadWithBullhorn.bullhornModalDescription',
    defaultMessage: 'Select “{fieldName}” for {candidateName}',
  },
  documentsCount: {
    id: 'app.components.form.UploadWithBullhorn.documentsCount',
    defaultMessage:
      '{documents, plural, one {1 document} other {{documents} documents}}',
  },
  bullhornModalSelectButton: {
    id: 'app.components.form.UploadWithBullhorn.bullhornModalSelectButton',
    defaultMessage: 'Select',
  },
  bullhornModalSelectTooltip: {
    id: 'app.components.form.UploadWithBullhorn.bullhornModalSelectTooltip',
    defaultMessage: 'Select document',
  },
  bullhornModalDownloadTooltip: {
    id: 'app.components.form.UploadWithBullhorn.bullhornModalDownloadTooltip',
    defaultMessage: 'Download',
  },

  notAvailable: {
    id: 'app.components.form.UploadWithBullhorn.notAvailable',
    defaultMessage: 'n/a',
  },
  noFilesAvailable: {
    id: 'app.components.form.UploadWithBullhorn.noFilesAvailable',
    defaultMessage: 'No files available for this candidate',
  },
});
