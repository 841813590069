import { Component } from 'react';
import PropTypes from 'prop-types';

import { some, isEmpty, noop } from 'lodash';

import { KEYS } from 'components/utils/keys';
import { Icon } from 'components/elements/icon';

import * as styled from './styles';

/* eslint react/no-did-update-set-state: 0 */
class TextFilter extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = { value };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ value });
    }
  }

  handleResetFilter = () => {
    const { onChange } = this.props;

    onChange(TextFilter.defaultProps.value);
  };

  handleApplyFilter = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    onChange(value);
  };

  handleOnChange = (event) => {
    const { value } = event.target;

    this.setState({ value });
  };

  render() {
    const {
      value: defaultValue,
      placeholder,
      disabled,
      defaultFiltered,
    } = this.props;

    const { value } = this.state;

    const isDefaultFilter = some(defaultFiltered, { value: defaultValue });

    return (
      <styled.Filter>
        <styled.TextInput
          type="text"
          value={value}
          placeholder={placeholder}
          disabled={disabled || isDefaultFilter}
          onKeyDown={(event) =>
            event.keyCode === KEYS.ENTER && this.handleApplyFilter()
          }
          onChange={this.handleOnChange}
        />
        {!isDefaultFilter && (
          <styled.Action>
            {isEmpty(value) || value !== defaultValue ? (
              <Icon
                size={1.15}
                icon="ArrowBendDownLeft"
                onClick={this.handleApplyFilter}
              />
            ) : (
              <Icon size={1.15} icon="X" onClick={this.handleResetFilter} />
            )}
          </styled.Action>
        )}
      </styled.Filter>
    );
  }
}

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultFiltered: PropTypes.array,
};

const defaultProps = {
  value: '',
  placeholder: 'Search',
  disabled: false,
  onChange: noop,
};

TextFilter.propTypes = propTypes;

TextFilter.defaultProps = defaultProps;

const BaseTextFilter = ({ filter, ...rest }) => (
  <TextFilter value={filter?.value || ''} {...rest} />
);

BaseTextFilter.propTypes = {
  ...propTypes,
  filter: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

BaseTextFilter.defaultProps = {
  filter: { value: '' },
  ...defaultProps,
};

export { TextFilter, BaseTextFilter };
