import i18nShared from 'scenes/utils/i18n';

import i18n from './i18n';

export const FormDefinitionInfo = {
  companySize: {
    id: 'company_size',
    label: i18n.labelCompanySize,
    inputType: 'option',
    required: true,
    listWithoutPlaceholder: true,
    placeholder: {
      linkName: i18n.placeholderCompanySize,
      slug: null,
    },
    options: [
      {
        linkName: i18nShared.companySize0,
        slug: 'employees_0',
      },
      {
        linkName: i18nShared.companySize2,
        slug: 'employees_2',
      },
      {
        linkName: i18nShared.companySize11,
        slug: 'employees_11',
      },
      {
        linkName: i18nShared.companySize51,
        slug: 'employees_51',
      },
      {
        linkName: i18nShared.companySize201,
        slug: 'employees_201',
      },
      {
        linkName: i18nShared.companySize501,
        slug: 'employees_501',
      },
      {
        linkName: i18nShared.companySize1001,
        slug: 'employees_1001',
      },
      {
        linkName: i18nShared.companySize5001,
        slug: 'employees_5001',
      },
      {
        linkName: i18nShared.companySize10001,
        slug: 'employees_10001',
      },
    ],
  },
  revenueSize: {
    id: 'revenue_size',
    label: i18n.labelRevenueSize,
    inputType: 'option',
    required: false,
    listWithoutPlaceholder: true,
    placeholder: {
      slug: null,
      linkName: i18n.placeholderRevenueSize,
    },
    options: [
      {
        linkName: i18n.revenue_0,
        slug: 'revenue_0',
      },
      {
        linkName: i18n.revenue_500k,
        slug: 'revenue_500k',
      },
      {
        linkName: i18n.revenue_1m,
        slug: 'revenue_1m',
      },
      {
        linkName: i18n.revenue_5m,
        slug: 'revenue_5m',
      },
      {
        linkName: i18n.revenue_10m,
        slug: 'revenue_10m',
      },
      {
        linkName: i18n.revenue_50m,
        slug: 'revenue_50m',
      },
      {
        linkName: i18n.revenue_100m,
        slug: 'revenue_100m',
      },
    ],
  },
  labels: {
    id: 'labels',
    label: i18n.labelCertifications,
    inputType: 'checkboxList',
    idSelector: 'id',
    nameSelector: 'label',
    required: false,
    options: [
      {
        id: 'label-diversity_supplier',
        label: i18n.diversity_supplier,
        value: 'diversity_supplier',
      },
      {
        id: 'label-goverment_contracts',
        label: i18n.goverment_contracts,
        value: 'goverment_contracts',
      },
      {
        id: 'label-small_business_certified',
        label: i18n.small_business_certified,
        value: 'small_business_certified',
      },
      {
        id: 'label-security_clearance',
        label: i18n.security_clearance,
        value: 'security_clearance',
      },
    ],
  },
};

export const FormDefinitionLogo = {
  logo: {
    id: 'logo',
    required: false,
    detail: i18n.placeholderLogo,
    acceptedDimensions: { min: { width: 260, height: 260 } },
  },
};

export const FormDefinitionSidebar = {
  billingAddress: {
    id: 'billingAddress',
    label: i18n.labelBillingAddress,
    placeholder: i18n.placeholderBillingAddress,
    inputType: 'autocomplete',
    required: false,
    minlength: 3,
  },
  contactPerson: {
    id: 'contact_person',
    label: i18n.labelContactPerson,
    inputType: 'option',
    required: false,
    listWithoutPlaceholder: true,
    placeholder: {
      slug: null,
      linkName: i18n.placeholderContactPerson,
    },
    options: [],
  },
};

export const FormDefinitionIndustry = {
  industry: {
    id: 'industries',
    label: i18n.labelIndustry,
    inputType: 'option',
    required: true,
    listWithoutPlaceholder: true,
    placeholder: {
      linkName: i18n.placeholderIndustry,
      slug: null,
    },
    options: [],
  },
};

export const FormDefinitionClientIndustries = {
  industries: {
    id: 'industries',
    label: i18n.labelIndustries,
    inputType: 'checkboxList',
    idSelector: 'id',
    nameSelector: 'name',
    required: true,
    options: [],
  },
};

export const FormDefinitionJobDepartments = {
  departments: {
    id: 'departments',
    label: i18n.labelDepartments,
    inputType: 'checkboxList',
    idSelector: 'id',
    nameSelector: 'name',
    required: true,
    options: [],
  },
};

export const FormDefinitionRoleSpecializations = {
  specializations: {
    id: 'specializations',
    label: i18n.labelSpecializations,
    inputType: 'checkboxList',
    idSelector: 'id',
    nameSelector: 'name',
    required: true,
    options: [],
  },
};

export const FormDefinitionHubSwitch = {
  hubActive: {
    id: 'hub_active',
    label: false,
    options: [
      {
        checked: true,
      },
      {
        checked: false,
      },
    ],
    inputType: 'toggle',
    required: false,
  },
};
