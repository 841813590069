import { FormattedMessage } from 'react-intl';

import { get } from 'lodash';

import Bowser from 'bowser';

import { SmartLink } from 'components/navigation/SmartLink';

import * as styled from './styles';
import i18n from '../utils/i18n';

export const BrowserBanner = function BrowserBanner() {
  const browser = Bowser.getParser(window.navigator.userAgent);

  // versions available in mid 2021
  if (
    get(browser, 'parsedResult.platform.type') !== 'desktop' ||
    browser.satisfies({
      chrome: '>90.0',
      firefox: '>88.0',
      safari: '>14.0',
      edge: '>90.0',
    })
  ) {
    return null;
  }

  return (
    <styled.BrowserBanner key="browser-banner">
      <FormattedMessage
        {...i18n.browserNotSupported}
        values={{
          chrome: (
            <SmartLink to="https://www.google.com/chrome/" target="_blank">
              Chrome
            </SmartLink>
          ),
          firefox: (
            <SmartLink
              to="https://www.mozilla.org/en-US/firefox/"
              target="_blank"
            >
              Firefox
            </SmartLink>
          ),
          safari: (
            <SmartLink
              to="https://support.apple.com/en-us/HT204416"
              target="_blank"
            >
              Safari
            </SmartLink>
          ),
          edge: (
            <SmartLink
              to="https://www.microsoft.com/en-us/edge"
              target="_blank"
            >
              Edge
            </SmartLink>
          ),
        }}
      />
    </styled.BrowserBanner>
  );
};
