import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { find, isEmpty } from 'lodash';
import { rgba } from 'polished';

import {
  companyProfileTypeColors,
  reviewStatusColors,
  premiumFeatureColors,
  featureVersionColors,
  Colors,
  userTablePermissionsColors,
  hlCandidateStatusColors,
} from 'components/utils/styles/ui';

import * as styled from './styles/badge';
import i18n from './utils/i18n';

export const Badge = function Badge({ children, ...props }) {
  return (
    <styled.Badge {...props} data-manual={props.dataManual}>
      {children}
    </styled.Badge>
  );
};

Badge.propTypes = {
  color: PropTypes.oneOf(
    Object.keys(styled.BADGE_COLORS).concat(Object.keys(Colors))
  ),
  dataManual: PropTypes.string,
  fontColor: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(styled.BADGE_SIZES)),
  header: PropTypes.bool,
  upperCase: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  inline: PropTypes.bool,
};

Badge.defaultProps = {
  color: 'primary',
  fontColor: 'white',
  size: 'normal',
  header: false,
  upperCase: false,
  inline: false,
};

export const IconBadge = function IconBadge({
  icon,
  iconColor,
  size,
  ...props
}) {
  return (
    <styled.IconBadge size={size} {...props}>
      <styled.Icon color={iconColor} icon={icon} size={size} />
    </styled.IconBadge>
  );
};

IconBadge.propTypes = {
  color: PropTypes.oneOf(
    Object.keys(styled.BADGE_COLORS).concat(Object.keys(Colors))
  ),
  size: PropTypes.oneOf(Object.keys(styled.BADGE_SIZES)),
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  withPadding: PropTypes.bool,
};

IconBadge.defaultProps = {
  color: 'primary',
  iconColor: 'white',
  size: 'normal',
  icon: 'Check',
  withPadding: false,
};

export const CompanyProfileTypeBadge = ({ companyType, ...props }) => {
  if (i18n.companyProfileTypeBadge[companyType]) {
    return (
      <styled.CompanyProfileTypeBadge companyType={companyType} {...props}>
        <FormattedMessage {...i18n.companyProfileTypeBadge[companyType]} />
      </styled.CompanyProfileTypeBadge>
    );
  }
  return null;
};

CompanyProfileTypeBadge.propTypes = {
  companyType: PropTypes.oneOf(Object.keys(companyProfileTypeColors)),
};

export const ReviewStatusBadge = ({ reviewStatus, ...props }) => {
  if (i18n.reviewStatusBadge[reviewStatus]) {
    return (
      <styled.ReviewStatusBadge reviewStatus={reviewStatus} {...props}>
        <FormattedMessage {...i18n.reviewStatusBadge[reviewStatus]} />
      </styled.ReviewStatusBadge>
    );
  }
  return null;
};

ReviewStatusBadge.propTypes = {
  reviewStatus: PropTypes.oneOf(Object.keys(reviewStatusColors)),
};

export const CommunityBadge = (props) => (
  <styled.CommunityBadge {...props}></styled.CommunityBadge>
);

export const PremiumFeatureBadge = ({ type, ...props }) => {
  if (i18n.premiumFeatureBadge[type]) {
    const icon = type === 'addon' ? 'PuzzlePiece' : 'ShieldFill';
    const weight = type === 'addon' ? 'fill' : 'regular';

    return (
      <styled.PremiumFeatureBadge type={type} {...props}>
        <styled.Icon color="white" size="small" icon={icon} weight={weight} />
        <FormattedMessage {...i18n.premiumFeatureBadge[type]} />
      </styled.PremiumFeatureBadge>
    );
  }
  return null;
};

PremiumFeatureBadge.propTypes = {
  type: PropTypes.oneOf(Object.keys(premiumFeatureColors)),
  size: PropTypes.oneOf(Object.keys(styled.BADGE_SIZES)),
};

PremiumFeatureBadge.defaultProps = {
  size: 'normal',
  type: 'feature',
};

export const FeatureVersionBadge = ({ version, ...props }) => {
  if (i18n.featureVersionBadge[version]) {
    return (
      <styled.FeatureVersionBadge version={version} {...props}>
        <FormattedMessage {...i18n.featureVersionBadge[version]} />
      </styled.FeatureVersionBadge>
    );
  }
  return null;
};

FeatureVersionBadge.propTypes = {
  version: PropTypes.oneOf(Object.keys(featureVersionColors)),
  type: PropTypes.oneOf(Object.keys(featureVersionColors)),
  size: PropTypes.oneOf(Object.keys(styled.BADGE_SIZES)),
};

FeatureVersionBadge.defaultProps = {
  size: 'normal',
  type: 'beta',
};

export function InfoBadge({
  className,
  iconPreName,
  iconAffName,
  iconColor,
  text,
  fontColor,
  color,
}) {
  return (
    <styled.InfoBadge className={className} fontColor={fontColor} color={color}>
      {iconPreName && (
        <styled.InfoBadgeIcon icon={iconPreName} color={iconColor} />
      )}
      <FormattedMessage {...text} />
      {iconAffName && (
        <styled.InfoBadgeIcon icon={iconAffName} color={iconColor} />
      )}
    </styled.InfoBadge>
  );
}

InfoBadge.propTypes = {
  fontColor: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  iconPreName: PropTypes.string,
  iconAffName: PropTypes.string,
  text: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const cvIcons = {
  created: 'User',
  requested: 'FileText',
  contacted: 'Envelope',
  revealed: 'FileText',
  declined: 'XCircle',
  interview: 'ChatsCircle',
  to_review: 'Star',
};

export const CvStatusBadge = (props) => {
  const {
    withIcon,
    className,
    status,
    size,
    colors,
    customStatuses,
    filled,
    rightIcon,
    withPlaceholder,
  } = props;
  const noStatus = isEmpty(status) || status === 'no_status';
  const isCustom = !i18n.cvStatus[status];
  if (noStatus && !withPlaceholder) {
    return null;
  }

  const custom = isCustom ? find(customStatuses || [], { id: status }) : null;
  let colorKey = status;
  if (isCustom && !custom) {
    colorKey = 'no_status';
  }

  const mainColor = (() => {
    if (filled) return Colors.white;
    if (custom) return custom.color;
    return colors?.[colorKey]?.main || colors[colorKey];
  })();
  const bgColor = (() => {
    if (custom && filled) {
      return custom.color;
    }
    if (custom) {
      return rgba(custom.color, 0.1);
    }
    if (filled) return colors[colorKey];
    return colors?.[colorKey]?.bg || Colors.white;
  })();
  const borderColor = (() => {
    if (custom) {
      return custom.color;
    }
    return (
      colors?.[colorKey]?.border || colors?.[colorKey]?.main || colors[colorKey]
    );
  })();

  return (
    <styled.CvStatusBadge
      className={className}
      mainColor={mainColor}
      bgColor={bgColor}
      borderColor={borderColor}
      size={size}
      inline
    >
      {withIcon && !noStatus && (
        <styled.InfoBadgeIcon
          icon={custom?.icon || cvIcons[status] || 'Activity'}
          color={mainColor}
        />
      )}
      <styled.Label>
        {isCustom && (custom?.label || status)}
        {!isCustom && <FormattedMessage {...i18n.cvStatus[status]} />}
      </styled.Label>
      {rightIcon && (
        <styled.RightBadgeIcon icon={rightIcon} color={mainColor} />
      )}
    </styled.CvStatusBadge>
  );
};

CvStatusBadge.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large']),
  colors: PropTypes.object,
  customStatuses: PropTypes.array,
  filled: PropTypes.bool,
  withIcon: PropTypes.bool,
  rightIcon: PropTypes.string,
  withPlaceholder: PropTypes.bool,
};

CvStatusBadge.defaultProps = {
  colors: hlCandidateStatusColors,
  filled: false,
  withPlaceholder: false,
  status: 'no_status',
};

export const BaseBadge = (props) => {
  const { className, value, label, size, colors, rightIcon } = props;
  const mainColor = colors?.[value]?.main || colors[value];
  const bgColor = colors?.[value]?.bg || colors[value];
  const borderColor = colors?.[value]?.border || colors[value];

  return (
    <styled.BaseBadge
      className={className}
      mainColor={mainColor}
      bgColor={bgColor}
      borderColor={borderColor}
      size={size}
      inline
    >
      <styled.Label>
        <FormattedMessage {...label} />
      </styled.Label>
      {rightIcon && (
        <styled.RightBadgeIcon icon={rightIcon} color={mainColor} />
      )}
    </styled.BaseBadge>
  );
};

BaseBadge.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  size: PropTypes.oneOf(['normal', 'large']),
  colors: PropTypes.object,
  rightIcon: PropTypes.string,
};

export const RoleBadge = (props) => {
  const { className, role, size, colors, rightIcon } = props;

  if (isEmpty(role)) {
    return null;
  }

  return (
    <BaseBadge
      className={className}
      value={role}
      label={i18n.membershipRoles[role]}
      size={size}
      colors={colors}
      rightIcon={rightIcon}
    />
  );
};

RoleBadge.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large']),
  colors: PropTypes.object,
  rightIcon: PropTypes.string,
};

RoleBadge.defaultProps = {
  colors: userTablePermissionsColors,
  role: 'member',
};
