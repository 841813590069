import styled from 'styled-components';

import { Activator as TooltipActivator } from 'components/overlay/Tooltip/styles/tooltip';
import { Button as ButtonBase } from 'components/elements/button';
import { Card as CardBase } from './section';

export const Card = styled(CardBase)`
  margin-bottom: 0.3rem;
`;

export const Paragraph = styled.p`
  font-weight: 500;
  margin: 2rem 0 1rem 0;

  &:first-child {
    margin-top: 0;
  }
`;

export const RatingWrapper = styled.div`
  display: flex;

  &,
  ${TooltipActivator} {
    & > span > span {
      margin: 0 0.3rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const RatingCount = styled.span`
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
`;

export const ButtonEdit = styled(ButtonBase)`
  font-weight: 400;
  text-decoration: underline;
  margin-left: 1rem;
`;
