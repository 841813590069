import { defineMessages } from 'react-intl';

import theme from 'themes';

export default defineMessages({
  title: {
    id: 'app.releaseUpdate.title',
    defaultMessage: 'A new version is available',
  },
  pageTitle: {
    id: 'app.releaseUpdate.pageTitle',
    defaultMessage: `${theme.platformName} Release Update`,
  },
  subtitle: {
    id: 'app.releaseUpdate.subtitle',
    defaultMessage:
      'The application has been updated, you need to refresh the page.',
  },
  button: {
    id: 'app.releaseUpdate.button',
    defaultMessage: 'Refresh now',
  },
});
