import React, { isValidElement } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import isString from 'lodash/isString';

import theme from 'themes';
import { Tooltip } from 'components/overlay/Tooltip';
import { Icon } from 'components/elements/icon';
import { highlightBackticks } from 'utils/stringFunctions';

import type { IconName } from 'components/elements/types';
import * as styled from './styles/label';
import i18n from './utils/i18n';

const requiredIndicator = function requiredIndicator(
  required: boolean,
  intl: IntlShape,
  includeIndicator: boolean
) {
  if (!includeIndicator) {
    return null;
  }

  return required ? (
    <styled.RequiredIndicator>*</styled.RequiredIndicator>
  ) : (
    <styled.OptionalIndicator>
      {intl.formatMessage(i18n.optional)}
    </styled.OptionalIndicator>
  );
};

const tooltip = function tooltip(tooltipContent: React.ReactNode | null) {
  if (tooltipContent === null) {
    return null;
  }

  return (
    <Tooltip content={tooltipContent}>
      <styled.TooltipIcon>
        <Icon icon="Info" color={theme.colors.primary} size="large" />
      </styled.TooltipIcon>
    </Tooltip>
  );
};

const iconLabel = function iconLabel(labelIcon: string | undefined) {
  if (!labelIcon) {
    return null;
  }
  return <styled.Icon icon={labelIcon as IconName} size="larger" />;
};

const labelValue = (
  value: string | MessageDescriptor | React.ReactNode,
  intl: IntlShape,
  literals?: Record<string, string | number>
) => {
  if (isValidElement(value)) return value;

  const label = isString(value)
    ? value
    : intl.formatMessage(value as MessageDescriptor, literals);

  return <span> {highlightBackticks(label)} </span>;
};

type FormLabelProps = {
  className?: string;
  literals?: Record<string, string | number>;
  highlight?: string;
  labelBreakline?: boolean;
  elementToRefer?: string;
  value: string | MessageDescriptor | React.ReactNode;
  required?: boolean;
  LabelUI?: React.FC<{
    className?: string;
    htmlFor?: string;
    children?: React.ReactNode;
  }>;
  includeIndicator?: boolean;
  tooltipContent?: React.ReactNode;
  labelIcon?: string;
};

/* eslint react/no-danger: 0 */
export const FormLabel = function FormLabel({
  className,
  value,
  literals,
  highlight,
  labelBreakline,
  elementToRefer,
  labelIcon,
  required = false,
  LabelUI = styled.Label,
  includeIndicator = true,
  tooltipContent = null,
}: FormLabelProps) {
  const intl = useIntl();

  const highlightTag = highlight ? (
    <styled.Highlight>
      {isString(highlight) ? highlight : intl.formatMessage(highlight)}
    </styled.Highlight>
  ) : null;

  const breakline = labelBreakline ? <br /> : null;

  return (
    <LabelUI className={className} htmlFor={elementToRefer}>
      {iconLabel(labelIcon)}
      {labelValue(value, intl, literals)}
      {breakline}
      {highlightTag}
      {requiredIndicator(required, intl, includeIndicator)}
      {tooltip(tooltipContent)}
    </LabelUI>
  );
};
