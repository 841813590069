import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { NodeViewWrapper } from '@tiptap/react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { roundArrow } from 'tippy.js';
import { isEmpty } from 'lodash';
import 'tippy.js/dist/svg-arrow.css';

import { KEYS } from 'components/utils/keys';
import { Tooltip } from 'components/overlay/Tooltip';

import * as styled from './styles';

function getVariableStyleProps({ type, fallback, needFallback }, isSelected) {
  if (type === 'listUrl') {
    return {
      icon: 'Link',
      iconColor: 'tealDark',
      color: 'tealDark',
      borderColor: isSelected ? 'tealDark' : 'outline',
      backgroundColor: 'tealLighter',
    };
  }

  if (!!needFallback && !fallback) {
    return {
      icon: 'Warning',
      iconColor: 'yellowDark',
      color: 'text',
      borderColor: isSelected ? 'yellowDark' : 'yellow',
      backgroundColor: 'yellowLight',
    };
  }

  return {
    icon: 'ArrowsOutLineHorizontal',
    iconColor: 'textLight',
    color: 'text',
    borderColor: isSelected ? 'tealDark' : 'outline',
    backgroundColor: 'labelBg',
  };
}

export const VariableContent = ({
  className,
  i18n,
  attrs,
  editable,
  isSelected,
}) => {
  const {
    icon,
    iconColor,
    color,
    borderColor,
    backgroundColor,
  } = getVariableStyleProps(attrs, isSelected);

  const content = (
    <styled.VariableWrapper
      className={className}
      color={color}
      editable={editable}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      <styled.Icon icon={icon} color={iconColor} weight="bold" />
      <styled.Text>
        <FormattedMessage {...i18n[attrs.type]} />
      </styled.Text>
    </styled.VariableWrapper>
  );

  if (editable) {
    return content;
  }

  return (
    <Tooltip
      position="S"
      content={<FormattedMessage {...i18n.tooltipToPersonalizationTokens} />}
      fixed
      size="40rem"
    >
      {content}
    </Tooltip>
  );
};

VariableContent.propTypes = {
  i18n: PropTypes.object,
  attrs: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  editable: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export const VariableComponent = (props) => {
  const {
    updateAttributes,
    node,
    editor,
    getPos,
    selected,
    intl,
    extension: {
      options: { i18n, editable },
    },
  } = props;

  const onChange = (event) => {
    updateAttributes({
      fallback: event.target.value,
    });
  };

  const [visible, setVisible] = useState(false);
  const [isFallbackFocused, setIsFallbackFocused] = useState(false);

  const inputRef = useRef();

  const handleKeyDown = (e) => {
    let nextMoveCursor = 1;

    if (e.shiftKey && e.keyCode === KEYS.TAB) {
      nextMoveCursor = -1;
    }

    if (
      e.keyCode === KEYS.TAB ||
      e.keyCode === KEYS.ESCAPE ||
      e.keyCode === KEYS.ENTER
    ) {
      e.preventDefault();
      e.stopPropagation();
      setVisible(false);
      editor.commands.focus(getPos() + nextMoveCursor);
    }
  };

  useEffect(() => {
    if (!inputRef.current || !visible) return;

    if (!isEmpty(inputRef.current.value)) return;

    inputRef.current.focus();
  }, [visible]);

  useEffect(() => {
    if (visible === selected || isFallbackFocused) {
      return;
    }

    setVisible(selected);
  }, [selected]);

  useEffect(() => {
    const { autofocus } = node.attrs;
    if (!autofocus || !editable) return;
    editor.commands.focus(getPos() + 1);
  }, []);

  if (!editable) {
    return (
      <NodeViewWrapper className="variable">
        <styled.VariableContent>
          <VariableContent
            attrs={node.attrs}
            i18n={i18n}
            isSelected={visible}
          />
        </styled.VariableContent>
      </NodeViewWrapper>
    );
  }

  const { type, needFallback } = node.attrs;
  const hasFallback = !!needFallback;

  return (
    <NodeViewWrapper className="variable">
      <styled.Tooltip
        interactive
        appendTo={() => document.body}
        content={
          <styled.TooltipContent>
            <styled.TooltipContentTitle>
              {'{...} '}
              <FormattedMessage {...i18n.fallback[type]} />
            </styled.TooltipContentTitle>
            {hasFallback && (
              <>
                <styled.TooltipContentLabel>
                  <FormattedMessage {...i18n.fallback.label} />
                </styled.TooltipContentLabel>
                <styled.TooltipContentInput
                  onKeyDown={handleKeyDown}
                  value={node.attrs.fallback}
                  onChange={onChange}
                  onFocus={() => setIsFallbackFocused(true)}
                  onBlur={() => setIsFallbackFocused(false)}
                  placeholder={intl.formatMessage(
                    i18n.fallback[`${type}Placeholder`]
                  )}
                  ref={inputRef}
                />
              </>
            )}
          </styled.TooltipContent>
        }
        visible={visible}
        onClickOutside={() => setVisible(false)}
        arrow={roundArrow}
        arrowColor={hasFallback ? 'white' : 'labelBg'}
      >
        <styled.VariableContent>
          <VariableContent
            attrs={node.attrs}
            i18n={i18n}
            isSelected={visible}
            editable
          />
        </styled.VariableContent>
      </styled.Tooltip>
    </NodeViewWrapper>
  );
};

VariableComponent.propTypes = {
  intl: PropTypes.object,
  updateAttributes: PropTypes.func.isRequired,
  node: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  getPos: PropTypes.func.isRequired,
  extension: PropTypes.object,
  selected: PropTypes.bool,
};

export const Variable = injectIntl(VariableComponent);
