/* eslint-disable no-param-reassign */
import { forwardRef } from 'react';
import 'tippy.js/dist/svg-arrow.css';
import { compact } from 'lodash';

import PropTypes from 'prop-types';
import { BubbleMenu } from '@tiptap/react';

import { roundArrow } from 'tippy.js';

import { TextToolsOptions } from './textToolsOptions';

import * as styled from '../styles';

const execSetPlacement = (editor) => (instance) => {
  const { placement } = instance.props;
  if (
    placement !== 'bottom' &&
    editor.state.selection.node?.type.name === 'variable'
  ) {
    instance.props.placement = 'bottom';
    return;
  }

  if (
    placement !== 'top' &&
    editor.state.selection.node?.type.name !== 'variable'
  ) {
    instance.props.placement = 'top';
  }
};

const ToolsFloating = forwardRef((props, ref) => {
  const {
    editor: propsEditor,
    items,
    setAskAIOpen,
    withButtonAskAI,
    onShow,
  } = props;

  const itemsWithAskAI = compact([withButtonAskAI && 'buttonAsk', ...items]);

  const handleOnShow = () => {
    execSetPlacement(propsEditor);
    onShow();
  };

  return (
    <BubbleMenu
      className="tooltip-shadow"
      editor={propsEditor}
      tippyOptions={{
        duration: 100,
        arrow: roundArrow,
        onShow: handleOnShow,
      }}
    >
      <div ref={ref}></div>
      <TextToolsOptions
        editor={propsEditor}
        items={itemsWithAskAI}
        setAskAIOpen={setAskAIOpen}
      />
    </BubbleMenu>
  );
});

ToolsFloating.defaultProps = {
  items: [
    'bulletList',
    'orderedList',
    'bold',
    'italic',
    'underline',
    'highlight',
  ],
};

ToolsFloating.propTypes = {
  ...TextToolsOptions.propTypes,
  onShow: PropTypes.func,
  items: PropTypes.array,
};

function ToolsBar({ editor, items }) {
  return (
    <styled.Toolbar>
      <TextToolsOptions editor={editor} items={items} />
    </styled.Toolbar>
  );
}

ToolsBar.defaultProps = {
  items: [
    'bulletList',
    'orderedList',
    'bold',
    'italic',
    'underline',
    'highlight',
  ],
};

ToolsBar.propTypes = {
  ...TextToolsOptions.propTypes,
};

export const TextTools = forwardRef((props, ref) => {
  const { editor, items, type, setAskAIOpen, withButtonAskAI, onShow } = props;
  if (!editor) return null;

  if (type === 'toolbar') {
    return <ToolsBar items={items} editor={editor} />;
  }

  return (
    <ToolsFloating
      editor={editor}
      items={items}
      setAskAIOpen={setAskAIOpen}
      withButtonAskAI={withButtonAskAI}
      onShow={onShow}
      ref={ref}
    />
  );
});

TextTools.defaultValues = {
  type: 'floating',
};

TextTools.propTypes = {
  ...TextToolsOptions.propTypes,
  onShow: PropTypes.func,
  type: PropTypes.oneOf(['toolbar', 'floating']),
};
