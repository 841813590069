import styled from 'styled-components';
import Rating from 'react-rating';

export const RatingCount = styled.span`
  margin-top: 0.3rem;
  margin-left: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.typography.text.small};

  @media screen and (min-width: 0px) and (max-width: 940px) {
    display: none;
  }
`;

export const RatingWrapper = styled.div`
  margin: 0.5rem 0 -2rem 11rem;
  ${({ theme }) => theme.typography.text.medium};
  display: flex;
  align-items: center;
`;

export const RatingComponent = styled(Rating).attrs({ readonly: true })`
  & > span {
    width: 1.4rem;
    height: 1.4rem;
    margin: 0 0.2rem;
  }

  & svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;
