import { Colors } from 'components/utils/styles/ui';

import noResult from 'images/svg/no-results.svg';

import { GustavLogo } from './assets/images/gustav-logo';
import gustavOpenPositionsAndEngagedJobs from './assets/images/open-positions-and-engaged-jobs.png';
import gustavNewPositionsAndMarketplace from './assets/images/new-positions-and-marketplace.png';
import gustavSubmittedCandidates from './assets/images/submitted-candidates.png';
import gustavCandidatesEmpty from './assets/images/candidates-empty.png';
import gustavJobsEmpty from './assets/images/jobs-empty.png';
import gustavSupplier from './assets/images/supplier.png';
import { ReactComponent as LogoComponent } from './assets/images/logo.svg';
import LargeLogoSrc, {
  ReactComponent as LargeLogoComponent,
} from './assets/images/gustav-logo.svg';
import * as typography from '../typography';

export const gustavTheme = {
  logo: GustavLogo,
  logoComponent: LogoComponent,
  logoWhiteComponent: LogoComponent,
  logoLargeComponent: LargeLogoComponent,
  logoLargeSrc: LargeLogoSrc,
  domain: 'app.hellogustav.com',
  favicon: '/gustav-favicon.png',
  additionalDomains: [
    'staging.localhost.com',
    'app.gustav.build',
    'staging.gustav.build',
  ],
  logoLink: 'https://hellogustav.com',
  platformName: 'Gustav',
  platformNameComponent: 'Gustav',
  typography,
  fonts: {
    headers: "'TT Interphases', sans-serif",
    text: "'TT Interphases', sans-serif",
  },
  colors: {
    primary: Colors.teal,
    primaryDark: Colors.tealDark,
    secondary: Colors.green,
    danger: Colors.red,
    warning: Colors.yellow,
    mono: Colors.textLighter,
    monoLight: Colors.inputOutline,
    monoLighter: Colors.greyLighter,
    monoDarker: Colors.text,
    columnLeftBackground: Colors.white,
  },
  images: {
    supplierSrc: gustavSupplier,
    notFoundSrc: noResult,
    openPositionsAndEngagedJobs: gustavOpenPositionsAndEngagedJobs,
    newPositionsAndMarketplace: gustavNewPositionsAndMarketplace,
    submittedCandidates: gustavSubmittedCandidates,
    candidatesEmpty: gustavCandidatesEmpty,
    jobsEmpty: gustavJobsEmpty,
  },
};
