import {
  SETUP_P20_USER_SUCCESS,
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  GET_SESSION_VIA_TOKEN_REQUEST,
  GET_SESSION_VIA_TOKEN_SUCCESS,
  GET_SESSION_VIA_TOKEN_ERROR,
  SETUP_BULLHORN_USER_REQUEST,
  SETUP_BULLHORN_USER_ERROR,
  SETUP_BULLHORN_USER_SUCCESS,
} from 'containers/Login/constants';
import { ONBOARDING_SUCCESS } from 'containers/Onboarding/constants';

import { Constants } from '../constants';

const initialState = {
  isFetching: false,
  authenticated: false,
  permissions: false,
  companyType: false,
  membershipRole: null,
  adminMembershipId: null,
  bullhorn: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
    case GET_SESSION_REQUEST:
    case GET_SESSION_VIA_TOKEN_REQUEST:
      return { ...state, isFetching: true };

    case SIGN_IN_ERROR:
    case GET_SESSION_ERROR:
    case GET_SESSION_VIA_TOKEN_ERROR:
      return { ...state, isFetching: false };

    case SIGN_IN_SUCCESS:
    case GET_SESSION_SUCCESS:
    case GET_SESSION_VIA_TOKEN_SUCCESS:
    case SETUP_P20_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authenticated: true,
        companyType: action.company.company_type,
        features: action.company.features,
        membershipRole: action.role,
      };

    case ONBOARDING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authenticated: true,
        companyType: action.payload.company.company_type,
        features: action.payload.company.features,
        membershipRole: action.payload.role,
      };

    case 'SET_ADMIN_MEMBERSHIP_ID':
      return {
        ...state,
        adminMembershipId: action.adminMembershipId,
      };

    case 'DELETE_ADMIN_MEMBERSHIP_ID':
      return {
        ...state,
        adminMembershipId: null,
      };

    case Constants.MOCK_SESSION:
      if (action.payload.authenticated) {
        return { ...action.payload.auth };
      }
      return { ...initialState };

    case SETUP_BULLHORN_USER_REQUEST:
      return { ...state, bullhorn: { isFetching: true } };
    case SETUP_BULLHORN_USER_SUCCESS:
    case SETUP_BULLHORN_USER_ERROR:
      return { ...state, bullhorn: { isFetching: false } };

    default:
      return state;
  }
};
