import { defineMessages } from 'react-intl';

export default defineMessages({
  uploadSuccess: {
    id: 'app.containers.CompanyDocuments.uploadSuccess',
    defaultMessage: 'Document uploaded',
  },
  renameSuccess: {
    id: 'app.containers.CompanyDocuments.renameSuccess',
    defaultMessage: 'Document renamed',
  },
  deleteSuccess: {
    id: 'app.containers.CompanyDocuments.deleteSuccess',
    defaultMessage: 'Document deleted',
  },
});
