import styled from 'styled-components';

import { ButtonDropdownMenu as ButtonDropdownMenuBase } from 'components/navigation/ButtonDropdownMenu';
import { ItemsWrapper } from 'components/navigation/ButtonDropdownMenu/styles';
import { Colors } from 'components/utils/styles/ui';
import {
  Button as ButtonBase,
  BUTTON_HEIGHTS,
} from 'components/elements/button';
export { DropzoneBox, Detail } from 'components/form/upload/styles/upload';

export const DropdownIconWrapper = styled.div`
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${({ color }) => Colors[color]};
  margin: 0.4rem 0.6rem 0 -0.5rem;
`;

export const Button = styled(ButtonBase)`
  margin-left: 1rem;
`;

export const ButtonDropdownMenu = styled(ButtonDropdownMenuBase)`
  position: static;

  ${ItemsWrapper} {
    bottom: ${({ size }) => `calc(${BUTTON_HEIGHTS[size]} + 1rem)`};
  }
`;
