/**
 * Create the store with dynamic reducers
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  createStore,
  applyMiddleware,
  compose,
  Store,
  Action,
  Reducer,
  Middleware,
} from 'redux';
import { fromJS } from 'immutable';
import { RouterState } from 'redux-first-history';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import { promiseTypeSuffixes } from 'utils/errorLogging';

import { createReducer } from './reducers';
import { crashReporter as crashReporterMiddleware } from './utils/middlewares/crashReporter';
import { routeChange as routeChangeMiddleware } from './utils/middlewares/routeChange';

const sagaMiddleware = createSagaMiddleware();

export const configureStore = function configureStore(
  initialState = {},
  routerMiddleware?: Middleware,
  routerReducer?: Reducer<RouterState>
) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    thunkMiddleware,
    sagaMiddleware,
    crashReporterMiddleware,
    routeChangeMiddleware,
    loadingBarMiddleware({ promiseTypeSuffixes }),
    LogRocket.reduxMiddleware(),
    ...(routerMiddleware ? [routerMiddleware] : []),
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose;
  /* eslint-enable */
  const injectedReducers = {
    ...(routerReducer ? { router: routerReducer } : {}),
  };

  const store = createStore(
    createReducer(injectedReducers),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  const appStore = {
    ...store,
    runSaga: sagaMiddleware.run,
    injectedReducers, // Reducer registry
    injectedSagas: {}, // Saga registry
  };

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      appStore.replaceReducer(createReducer(appStore.injectedReducers));
    });
  }

  return appStore as Store<object, Action<unknown>> & {
    dispatch: ThunkDispatch<object, undefined, Action<unknown>>;
  };
};
