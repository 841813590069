import type { ColorKey } from 'components/utils/styles/types';
import type { ButtonType, ButtonColor } from 'components/elements/types';

import * as styled from './styles/buttons';

import { Icon } from '../../elements/icon';

import { Colors } from '../../utils/styles/ui';

export type DeleteButtonProps = {
  className?: string;
  color?: ButtonColor | ColorKey;
  fn: VoidCallback;
  type?: ButtonType;
};

export const DeleteButton = function DeleteButton({
  className,
  color = 'monoLight',
  fn,
  type,
}: DeleteButtonProps) {
  return (
    <styled.Delete
      className={className}
      type={type}
      color={color}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        fn();
      }}
    >
      <Icon icon="X" color={Colors.white} size={1.5} />
    </styled.Delete>
  );
};
