import * as styled from './styles/buttons';

import { Icon } from '../../elements/icon';

import { Colors } from '../../utils/styles/ui';

type CloseButtonProps = {
  className?: string;
  fn: VoidCallback;
};

export const CloseButton = function CloseButton({
  className,
  fn,
}: CloseButtonProps) {
  return (
    <styled.Close className={className} color="primaryDark" onClick={fn}>
      <Icon icon="X" color={Colors.white} size={1.6} />
    </styled.Close>
  );
};
