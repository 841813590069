import { Component, createRef, ReactNode } from 'react';

import { ReactPageClick } from 'react-page-click';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor,
  PrimitiveType,
} from 'react-intl';
import { isEmpty, isString, get, isNil } from 'lodash';

import { Menu } from 'components/navigation/menu/menu';
import { getDropdownOptionsSettings } from 'components/utils/dropdown';

import * as styled from './styles/dropdown';

import i18n from './utils/i18n';

export type DropdownOption = {
  slug: string | null;
  linkName?: string | I18nMessage | ReactNode;
  textValue?: string;
  values?: Record<string, PrimitiveType>;
  fn: (
    e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
  ) => void;
};

type DropdownComponentProps = {
  className: string;
  optionsPosition?: 'top' | 'bottom';
  options: DropdownOption[];
  padding: string;
  size: 'small' | 'default';
  onSelect: (optionItemSlug: string | null) => void;
  onFocus: (focused: boolean) => void;
  intl: IntlShape;
  typeaheadPlaceholder?: string;
  children: React.ReactNode | React.ReactNode[];
  typeahead?: boolean;
  onTypeaheadChange: (value: string) => void;
  selectTag: string;
  disabled: boolean;
  onOptionsPosition: (position: string) => void;
  optionsHeight: number;
  displayClear: boolean;
  dataManual: string;
  active: boolean;
};

type SelectedTagProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  onKeyUp: (e: React.KeyboardEvent<HTMLElement>) => void;
  padding: string | null;
};

type State = {
  activeMenu: boolean;
  focused: boolean;
  typeaheadValue: string;
  selectedHighlighted: number | null;
  hidden: boolean;
  options: DropdownOption[];
};

// menu max div height
const MAX_OPTIONS_SIZE = 25;

function getTypeaheadHeight(isEnabled: boolean, size: string) {
  if (!isEnabled) return 0;
  if (size === 'small') return 3.5;
  return 4.5;
}

class DropdownComponent extends Component<DropdownComponentProps, State> {
  static defaultProps = {
    size: 'default' as 'small' | 'default',
    options: [],
    optionsPosition: 'bottom' as 'top' | 'bottom' | undefined,
    selectTag: 'link',
    disabled: false,
    optionsHeight: 5,
  };

  constructor(props: DropdownComponentProps) {
    super(props);

    this.state = {
      activeMenu: false,
      focused: false,
      typeaheadValue: '',
      selectedHighlighted: null,
      hidden: true,
      options: props.options,
    };
  }

  componentDidUpdate() {
    const { activeMenu, hidden } = this.state;
    if (this.typeaheadInput?.current && activeMenu) {
      this.typeaheadInput?.current.focus();
    }

    if (activeMenu && hidden) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hidden: false });
    }
  }

  handleTypeaheadChange(value: string) {
    this.setState({ typeaheadValue: value, selectedHighlighted: null }, () => {
      const { options: unfilteredOptions } = this.props;
      if (!value) {
        this.setState({ options: unfilteredOptions });
      } else {
        this.setState({
          options: this.filterOptions(unfilteredOptions, value),
        });
      }
    });
  }

  handleKeyUp(e: React.KeyboardEvent<HTMLElement>) {
    const options = this.optionMenuItems().links;
    const { selectedHighlighted } = this.state;

    switch (e.keyCode) {
      case 13: {
        if (selectedHighlighted != null) {
          options[selectedHighlighted].fn!(e);
        }
        break;
      }
      case 38: {
        const nextSelectedHighlighted =
          selectedHighlighted === null ? options.length : selectedHighlighted;
        const index =
          (options.length + nextSelectedHighlighted - 1) % options.length;

        this.setState({ selectedHighlighted: index });
        this.optionsMenu?.scrollTo(index);
        break;
      }
      case 40: {
        const nextSelectedHighlighted =
          selectedHighlighted === null ? -1 : selectedHighlighted;
        const index = (nextSelectedHighlighted + 1) % options.length;

        this.setState({ selectedHighlighted: index });
        this.optionsMenu?.scrollTo(index);
        break;
      }
      default:
        break;
    }
  }

  handleKeyDown(e: React.KeyboardEvent<HTMLElement>) {
    const {
      nativeEvent: { keyCode },
    } = e;

    if (keyCode === 38 || keyCode === 40) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleKeyPress(e: React.KeyboardEvent<HTMLElement>) {
    if (e.nativeEvent.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleClearSelection = (e: React.MouseEvent<HTMLElement>) => {
    this.optionSelected(e, { slug: null, fn: () => {} });
  };

  getFilterText(option: DropdownOption, intl: IntlShape) {
    const label = get(option, 'label');
    if (!isNil(label)) {
      return label;
    }

    const textValue = get(option, 'textValue');
    if (!isNil(textValue)) {
      return textValue;
    }

    if (get(option, 'linkName.id')) {
      const formattedMessage = intl.formatMessage(
        option.linkName as MessageDescriptor,
        option.values
      );
      return formattedMessage;
    }

    return isString(option?.linkName) ? option.linkName : '';
  }

  filterOptions(options: DropdownOption[], value: string) {
    const { intl } = this.props;
    const normalizedValue = value.toLowerCase();

    return options.filter((option) => {
      const filterText = this.getFilterText(option, intl);
      return filterText.toLowerCase().includes(normalizedValue);
    });
  }

  optionsMenu?: Menu | null;

  typeaheadInput?: React.RefObject<HTMLInputElement>;

  selectRef?: React.RefObject<HTMLDivElement> = createRef();

  recalculateOptionsPosition = () => {
    const { onOptionsPosition, optionsHeight, size } = this.props;
    const { options: filteredOptions } = this.state;
    const typeaheadHeight = getTypeaheadHeight(this.typeaheadEnabled(), size);

    const { position } = getDropdownOptionsSettings({
      element: this.selectRef?.current,
      options: filteredOptions,
      optionsHeight,
      maxOptionsHeight: MAX_OPTIONS_SIZE,
      extraContentHeight: typeaheadHeight,
    });

    onOptionsPosition(position);
  };

  optionSelected(
    e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>,
    optionItem: DropdownOption
  ) {
    const { onSelect } = this.props;
    e.preventDefault();
    e.stopPropagation();

    const optionItemSlug = optionItem.slug;

    if (onSelect) {
      onSelect(optionItemSlug);
    }

    if (optionItem.fn) {
      optionItem.fn(e);
    }

    this.setState(
      { selectedHighlighted: null, activeMenu: false, focused: false },
      () => {
        this.handleTypeaheadChange('');
      }
    );
  }

  optionMenuItems() {
    const { active } = this.props;
    const { selectedHighlighted } = this.state;
    const options = this.selectOptions();
    const links = options.map((optionItem) => {
      const item = { ...optionItem };
      item.fn = (event) => this.optionSelected(event, optionItem);
      return item;
    });

    return {
      type: 'verticalbox',
      selected: selectedHighlighted,
      active,
      links,
    };
  }

  toggleOption(e: React.MouseEvent<HTMLElement>) {
    const { activeMenu } = this.state;
    e.preventDefault();
    e.stopPropagation();

    this.recalculateOptionsPosition();

    this.setState({ activeMenu: !activeMenu });
  }

  focusOption(e: React.FocusEvent<HTMLElement>, focused: boolean) {
    const { onFocus } = this.props;

    if (e.type === 'focus' || e.nativeEvent.relatedTarget) {
      this.setState({ focused });
    }

    if (onFocus) {
      onFocus(focused);
    }
  }

  typeaheadEnabled() {
    const { options: unfilteredOptions } = this.props;
    return unfilteredOptions && unfilteredOptions.length > 6;
  }

  selectOptions() {
    const { options: filteredOptions, typeaheadValue } = this.state;
    const { options: unfilteredOptions } = this.props;
    return this.typeaheadEnabled() && typeaheadValue
      ? filteredOptions
      : unfilteredOptions;
  }

  renderTypeahead() {
    const { size, intl, typeaheadPlaceholder } = this.props;

    const { typeaheadValue } = this.state;

    const placeholder = typeaheadPlaceholder || i18n.typeahead;

    return (
      <styled.OptionsTypeahead
        size={size}
        placeholder={intl.formatMessage(placeholder as MessageDescriptor)}
        onInnerRef={(input: React.RefObject<HTMLInputElement>) => {
          this.typeaheadInput = input;
        }}
        value={typeaheadValue}
        shouldValidate={false}
        onChange={(value: string) => this.handleTypeaheadChange(value)}
        onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => this.handleKeyUp(e)}
        onKeyPress={(e: React.KeyboardEvent<HTMLElement>) =>
          this.handleKeyPress(e)
        }
        onFocus={(e: React.FocusEvent<HTMLElement>) =>
          this.focusOption(e, true)
        }
        onBlur={(e: React.FocusEvent<HTMLElement>) =>
          this.focusOption(e, false)
        }
      />
    );
  }

  render() {
    const {
      className,
      padding = null,
      size = 'default',
      children,
      selectTag,
      disabled,
      optionsPosition = 'bottom',
      displayClear,
      dataManual,
      options: unfilteredOptions,
    } = this.props;
    const { activeMenu: stateActiveMenu, focused, hidden } = this.state;
    const options = this.selectOptions();
    const activeMenu = !disabled && (stateActiveMenu || focused);
    const iconDirection = activeMenu ? 'CaretUp' : 'CaretDown';
    const SelectedTag = (selectTag === 'button'
      ? styled.OptionSelectedButton
      : styled.OptionSelectedLink) as React.FC<SelectedTagProps>;
    const optionMenuItems = this.optionMenuItems();

    return (
      <ReactPageClick
        notify={() =>
          activeMenu &&
          this.setState(
            { activeMenu: false, focused: false, hidden: true },
            () => {
              this.setState({ options: unfilteredOptions });
              this.handleTypeaheadChange('');
            }
          )
        }
      >
        <styled.Select
          className={className}
          ref={this.selectRef as React.RefObject<HTMLDivElement>}
          data-manual={dataManual}
        >
          {displayClear ? (
            <styled.ClearSelection
              onClick={this.handleClearSelection}
              type="blank"
            >
              <styled.Icon icon="X" />
              <styled.Divider />
            </styled.ClearSelection>
          ) : null}
          <SelectedTag
            onClick={(e: React.MouseEvent<HTMLElement>) => this.toggleOption(e)}
            onFocus={(e: React.FocusEvent<HTMLElement>) =>
              this.focusOption(e, true)
            }
            onBlur={(e: React.FocusEvent<HTMLElement>) =>
              this.focusOption(e, false)
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
              this.handleKeyDown(e)
            }
            onKeyUp={(e: React.KeyboardEvent<HTMLElement>) =>
              this.handleKeyUp(e)
            }
            padding={padding}
          >
            {children}
            <styled.Icon
              icon={iconDirection}
              marginLeft={displayClear && '3.4rem'}
            />
          </SelectedTag>
          <styled.OptionsWrapper
            activeMenu={activeMenu}
            size={size}
            hidden={hidden}
          >
            {optionsPosition === 'bottom' &&
              this.typeaheadEnabled() &&
              this.renderTypeahead()}
            {!isEmpty(options) ? (
              <styled.Options
                context={optionMenuItems}
                dropdownSize={25}
                maxItems={5}
                ref={(optionsItems) => {
                  this.optionsMenu = optionsItems;
                }}
              />
            ) : (
              <styled.EmptyState>
                <FormattedMessage {...i18n.noRecords} />
              </styled.EmptyState>
            )}
            {optionsPosition === 'top' &&
              this.typeaheadEnabled() &&
              this.renderTypeahead()}
          </styled.OptionsWrapper>
        </styled.Select>
      </ReactPageClick>
    );
  }
}

export const Dropdown = injectIntl(DropdownComponent);
