import styled from 'styled-components';

import InfiniteScrollBase from 'react-infinite-scroll-component';

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: -3rem 0 3rem 0;
  overflow: hidden;
`;

export const InfiniteScroll = styled(InfiniteScrollBase)``;
