import { Extension, Editor } from '@tiptap/core';
import { DOMSerializer } from 'prosemirror-model';

declare module '@tiptap/core' {
  interface Commands {
    getSelectedHTML: {
      getSelectedHTML: () => ({ editor }: { editor: Editor }) => string;
    };
  }
}

export const GetSelectedHTML = Extension.create({
  name: 'getSelectedHTML',
  addCommands: () => ({
    getSelectedHTML: () => ({ editor }) => {
      const slice = editor.state.selection.content();
      const serializer = DOMSerializer.fromSchema(editor.schema);
      const fragment = serializer.serializeFragment(slice.content);
      const div = document.createElement('div');
      div.appendChild(fragment);

      return div.innerHTML;
    },
  }),
});
