import PropTypes from 'prop-types';
import numeral from 'numeral';
import { find, isEmpty, isNumber, isObject, isString, toString } from 'lodash';

import { currencies } from 'ui-definitions/currency';
import i18n from 'scenes/utils/i18n';

export const formatRateRecurrence = function formatRateRecurrence(props) {
  const { intl, recurrence, rate } = props;

  if (!rate) {
    return intl.formatMessage(i18n.abbreviationNotApplicable);
  }

  const recurrenceAbbr =
    {
      yearly: i18n.abbreviationYear,
      monthly: i18n.abbreviationMonth,
      daily: i18n.abbreviationDay,
      hourly: i18n.abbreviationHour,
    }[recurrence] || i18n.abbreviationHour;

  if (isObject(rate) && !isEmpty(rate.min) && !isEmpty(rate.max)) {
    return `${formatMoneyRange(
      rate.min,
      rate.max,
      'short',
      true
    )}/${intl.formatMessage(recurrenceAbbr)}`;
  }

  return `${formatMoney(rate)}/${intl.formatMessage(recurrenceAbbr)}`;
};

export const formatMoneyRange = function formatMoneyRange(
  from,
  to,
  format,
  compact
) {
  if (compact) {
    return `${formatMoney(from, format, false)}-${formatMoney(
      to,
      format,
      false,
      false
    )} ${from.currency}`;
  }

  return `${formatMoney(from, format, false)} - ${formatMoney(
    to,
    format,
    false
  )} ${from.currency}`;
};

export const formatAmount = function formatAmount(amount, decimals = false) {
  if (!amount || amount === '') {
    return '';
  }

  const str = String(amount)
    .replace(/[^\d.]/g, '')
    .replace(/^([^.]*\.)|\./g, '$1');
  const [int, dec] = str.split('.');
  const formattedInt = Number(int).toLocaleString('en-US');

  if (decimals === false || decimals === 0 || !str.includes('.')) {
    return formattedInt;
  }

  return `${formattedInt}.${toString(dec).slice(0, decimals)}`;
};

export const formatMoney = function formatMoney(
  money,
  format = 'long',
  showCode = true,
  showSymbol = true
) {
  if (!money) {
    return '';
  }

  const { amount, currency } = money;
  const { symbol: currencySymbol, position } = findCurrency(currency);

  let formatted;
  if (format === 'short') {
    formatted = numeral(amount).format('0.[0]a');
  } else {
    formatted = Number(Number(amount).toFixed(2)).toLocaleString('en-US');
  }
  const code = showCode ? ` ${currency}` : '';
  const symbol = showSymbol ? currencySymbol : '';

  if (position === 'after') {
    return `${formatted}${symbol}${code}`;
  }
  return `${symbol}${formatted}${code}`;
};

export const loadMoney = function loadMoney(money) {
  if (isString(money.amount)) {
    return { ...money, amount: parseFloat(money.amount) };
  }

  if (money.min && money.max) {
    return {
      min: parseFloat(money.min.amount),
      max: parseFloat(money.max.amount),
      currency: money.currency,
    };
  }

  return money;
};

export const moneyToNumber = function moneyToNumber(money, decimals = false) {
  if (!money || money === '') {
    return 0;
  }

  return Number(formatAmount(money, decimals).replace(/,/g, ''));
};

export const parseMoney = function parseMoney(money) {
  if (isObject(money) && money.amount) {
    return {
      amount: toString(money.amount),
      currency: money.currency,
    };
  }

  if (isObject(money) && money.min && money.max) {
    return {
      min: { amount: toString(money.min), currency: money.currency },
      max: { amount: toString(money.max), currency: money.currency },
      currency: money.currency,
    };
  }

  return null;
};

export const isValidMoney = function isValidMoney(money, required) {
  if (required && (!isNumber(money?.amount) || Number(money?.amount) <= 0)) {
    return false;
  }

  return true;
};

export const isValidRangeMoneyInput = function isValidRangeMoneyInput(money) {
  if (isNumber(money) && Number(money) > 0) {
    return true;
  }
  return false;
};

export const isValidMinMaxRange = function isValidMinMaxRange(money) {
  if (money?.min <= money?.max) {
    return true;
  }
  return false;
};

export const isValidRangeMoneyInputs = function isValidRangeMoneyInputs(
  money,
  required = false
) {
  /* range is considered valid if its not required and both min && max are empty or invalid (isValidRangeMoneyInput func above)
  it should be invalid if its not required and one of the inputs is valid
  helpful when shouldValidate is true */
  if (
    !required &&
    !isValidRangeMoneyInput(money.min) &&
    !isValidRangeMoneyInput(money.max)
  ) {
    return true;
  }
  if (
    !isValidRangeMoneyInput(money.min) ||
    !isValidRangeMoneyInput(money.max)
  ) {
    return false;
  }

  return true;
};

export const moneyShape = PropTypes.shape({
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
});

export const findCurrency = (code) =>
  find(currencies, { code: code.toUpperCase() });
