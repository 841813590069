import { defineMessages } from 'react-intl';

export default defineMessages({
  chooseFile: {
    id: 'app.components.DropzoneUpload.chooseFile',
    defaultMessage: 'Choose a file or drag it here.',
  },
  giveName: {
    id: 'app.components.DropzoneUpload.giveName',
    defaultMessage: 'Give your file a name',
  },
  upload: {
    id: 'app.components.DropzoneUpload.upload',
    defaultMessage: 'Upload',
  },
  uploading: {
    id: 'app.components.DropzoneUpload.uploading',
    defaultMessage: 'Uploading...',
  },
  fileSelected: {
    id: 'app.components.DropzoneUpload.fileSelected',
    defaultMessage: 'File successfully selected!',
  },
  multipleError: {
    id: 'app.components.DropzoneUpload.multipleError',
    defaultMessage: 'Please drop only one file at a time!',
  },
  invalidShortError: {
    id: 'app.components.DropzoneUpload.invalidShortError',
    defaultMessage:
      'Only {accepted} files with a maximum size of {maxFileSize}MB are allowed!',
  },
  invalidLongError: {
    id: 'app.components.DropzoneUpload.invalidLongError',
    defaultMessage:
      'This file cannot be uploaded. Please check our Help Center for "Permitted file extensions" and make sure the file is smaller than {maxFileSize}MB.',
  },
});
