import { cloneDeep, isNumber } from 'lodash';
import {
  SUPPLIER_HUB_FETCH_INDUSTRIES_SUCCESS,
  SUPPLIER_HUB_FETCH_SPECIALIZATIONS_SUCCESS,
  SUPPLIER_HUB_FETCH_SUPPLIERS_REQUEST,
  SUPPLIER_HUB_FETCH_SUPPLIERS_SUCCESS,
  SUPPLIER_HUB_RESET_SUPPLIERS,
  SUPPLIER_HUB_CHANGE_RESULTS_VISIBILITY,
  SUPPLIER_HUB_CHOOSE_SUPPLIER,
  SUPPLIER_HUB_RESET_SEARCH_CONDITIONS,
  SUPPLIER_HUB_CHANGE_SEARCH_CONDITIONS,
  SUPPLIER_HUB_CHANGE_MAP_PARAMS,
  SUPPLIER_HUB_CHANGE_RESULTS_LOADED,
  SUPPLIER_HUB_CHANGE_LOCATION,
} from './constants';

const defaultSearchConditions = () =>
  ['name', 'city', 'location', 'state', 'industries', 'specializations'].reduce(
    (previous, key) => ({ ...previous, [key]: '' }),
    {}
  );
const defaultMapParams = {
  center: {
    // center of the USA - approximately
    lat: 40.743297,
    lng: -100.379961,
  },
  zoom: 4,
};

const initialState = {
  specializations: [],
  industries: [],
  suppliers: [],
  loading: false,
  searchResultsVisible: true,
  chosenSupplierId: null,
  searchConditions: defaultSearchConditions(),
  resultsLoaded: false,
  mapParams: defaultMapParams,
};

export function supplierHubReducer(state = initialState, action) {
  switch (action.type) {
    case SUPPLIER_HUB_FETCH_INDUSTRIES_SUCCESS:
      return {
        ...state,
        industries: action.payload.industries,
      };

    case SUPPLIER_HUB_FETCH_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        specializations: action.payload.specializations,
      };

    case SUPPLIER_HUB_FETCH_SUPPLIERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SUPPLIER_HUB_FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: action.payload.data.filter(
          ({ billing_address: { lat, lng } }) =>
            isNumber(parseFloat(lat)) && isNumber(parseFloat(lng))
        ),
        loading: false,
      };

    case SUPPLIER_HUB_RESET_SUPPLIERS:
      return {
        ...state,
        loading: true,
        suppliers: cloneDeep(initialState.suppliers),
      };

    case SUPPLIER_HUB_CHANGE_RESULTS_VISIBILITY:
      return {
        ...state,
        searchResultsVisible: !state.searchResultsVisible,
      };

    case SUPPLIER_HUB_CHOOSE_SUPPLIER:
      return {
        ...state,
        chosenSupplierId: action.payload.id,
        mapParams: !action.payload.id ? defaultMapParams : state.mapParams,
      };

    case SUPPLIER_HUB_RESET_SEARCH_CONDITIONS:
      return {
        ...state,
        loading: true,
        searchConditions: defaultSearchConditions(),
        mapParams: cloneDeep(initialState.mapParams),
      };

    case SUPPLIER_HUB_CHANGE_SEARCH_CONDITIONS:
      return {
        ...state,
        searchConditions: {
          ...state.searchConditions,
          [action.payload.name]: action.payload.value,
        },
      };

    case SUPPLIER_HUB_CHANGE_LOCATION:
      return {
        ...state,
        searchConditions: {
          ...state.searchConditions,
          city: action.payload.city,
          state: action.payload.state,
        },
      };

    case SUPPLIER_HUB_CHANGE_MAP_PARAMS:
      return {
        ...state,
        mapParams: action.payload,
      };

    /*
       when we get back from the company profile, we shouldn't reload the results.
       For example the previous chosen point could be on page 2 - therefore unavailable after reloading.
       The results should be the same - and this flag shows the state - new / "back from profile"
    */
    case SUPPLIER_HUB_CHANGE_RESULTS_LOADED:
      return {
        ...state,
        resultsLoaded: action.payload.resultsLoaded,
      };

    default:
      return state;
  }
}
