/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';

import { globalReducer } from 'containers/App/reducers';
import { locationsReducer } from 'containers/Locations/reducer';
import { industriesReducer } from 'containers/Industries/reducer';
import { departmentsReducer } from 'containers/Departments/reducer';
import { specializationsReducer } from 'containers/Specializations/reducer';
import { languageProviderReducer } from 'containers/LanguageProvider/reducer';
import { loadingBarReducer } from 'react-redux-loading-bar';

/**
 * Creates the main reducer with the dynamically injected ones
 */
/* eslint no-param-reassign: 0 */
export function createReducer(injectedReducers) {
  const appReducer = combineReducers({
    // TODO: Split `appReducers` into smaller reducers
    // TODO: Combine industries, locations and departments and nest under common name
    // TODO: Capitalize reducer names
    appReducers: globalReducer,
    industries: industriesReducer,
    locations: locationsReducer,
    departments: departmentsReducer,
    specializations: specializationsReducer,
    language: languageProviderReducer,
    loadingBar: loadingBarReducer,
    ...injectedReducers,
  });

  const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT') {
      state = undefined;
    }
    return appReducer(state, action);
  };

  return rootReducer;
}
