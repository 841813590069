import { defineMessages } from 'react-intl';

export default defineMessages({
  hex: {
    id: 'app.components.ColorPicker.Hex',
    defaultMessage: 'HEX',
  },
  red: {
    id: 'app.components.ColorPicker.RGB.Red',
    defaultMessage: 'R',
  },
  green: {
    id: 'app.components.ColorPicker.RGB.Green',
    defaultMessage: 'G',
  },
  blue: {
    id: 'app.components.ColorPicker.RGB.Blue',
    defaultMessage: 'B',
  },
  hue: {
    id: 'app.components.ColorPicker.HSLA.Hue',
    defaultMessage: 'H',
  },
  saturation: {
    id: 'app.components.ColorPicker.HSLA.Saturation',
    defaultMessage: 'S',
  },
  lightness: {
    id: 'app.components.ColorPicker.HSLA.Lightness',
    defaultMessage: 'L',
  },
  alpha: {
    id: 'app.components.ColorPicker.HSLA.Alpha',
    defaultMessage: 'A',
  },
});
