/* eslint-disable camelcase */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { PureComponent } from 'react';
import { MapStateToProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IntlShape, injectIntl } from 'react-intl';
import { isEmpty, get } from 'lodash';

import { AppDispatch } from 'types';

import { unimpersonate } from 'containers/Admin/actions';
import { Nag } from 'components/feedback/Nag';
import { confirmAlert } from 'components/overlay/Confirm';

import {
  userSelector,
  companySelector,
  adminMembershipIdSelector,
} from 'containers/App/selectors';

import i18n from './utils/i18n';

type UnimpersonateParams = {
  password: string;
};

type AdminHeaderComponentProps = {
  intl: IntlShape;
  user: {
    first_name: string;
    last_name: string;
  };
  company: Company;
  adminMembershipId: string;
  handleUnimpersonate: (params: UnimpersonateParams) => void;
};

class AdminHeaderComponent extends PureComponent<AdminHeaderComponentProps> {
  render() {
    const { adminMembershipId } = this.props;

    if (isEmpty(adminMembershipId)) {
      return null;
    }

    const { intl, user, company, handleUnimpersonate } = this.props;

    const firstName = get(user, 'first_name');
    const lastName = get(user, 'last_name');
    const companyName = get(company, 'name');

    return firstName && lastName && companyName ? (
      <Nag
        message={intl.formatMessage(i18n.title, {
          firstName,
          lastName,
          companyName,
        })}
        leftAction={{
          content: intl.formatMessage(i18n.buttonBackToAdmin),
          onClick: () =>
            confirmAlert({
              title: intl.formatMessage(i18n.confirmTitle),
              message: intl.formatMessage(i18n.confirmMessage),
              confirmLabel: intl.formatMessage(i18n.confirmButtonConfirm),
              cancelLabel: intl.formatMessage(i18n.confirmButtonCancel),
              children: <input type="password" autoFocus />,
              onConfirm: (textarea: { value: string }) =>
                handleUnimpersonate({ password: textarea.value }),
            }),
        }}
      />
    ) : null;
  }
}

const mapStateToProps: MapStateToProps<
  Record<string, any>,
  AdminHeaderComponentProps,
  Record<string, any>
> = createStructuredSelector({
  user: userSelector,
  company: companySelector,
  adminMembershipId: adminMembershipIdSelector,
});

export function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    handleUnimpersonate: (params: UnimpersonateParams) =>
      dispatch(unimpersonate(params)),
  };
}

export const AdminHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AdminHeaderComponent));
