import styled, { css } from 'styled-components';

import { Icon as IconBase } from 'components/elements/icon';
import { Button as ButtonBase } from 'components/elements/button';
import { Fieldset as FieldsetBase } from 'components/form/fieldset/fieldset';
import { InfoBox as InfoBoxBase } from 'components/text/InfoBox';
import {
  CompanyProfileTypeBadge,
  IconBadge,
  CommunityBadge as CommunityBadgeBase,
} from 'components/misc/Badge';
import { Activator } from 'components/overlay/Tooltip/styles/tooltip';
import { Colors } from 'components/utils/styles/ui';

const LabelStyle = css`
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeaderRow = styled.div`
  display: flex;
  padding: 3rem 0 0 0;
  margin-bottom: 2rem;
  height: 15.5rem;
  align-items: center;
  justify-content: space-between;
`;

export const SubHeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SubHeader = styled.div``;

export const CompleteProfileBox = styled.div`
  border: 0.1rem solid ${Colors.outline};
  background: ${Colors.tealLightest};
  padding: 1rem;
  min-width: 43.4rem;
  ul {
    margin: 0;
    padding: 0;
    padding-left: 0.5rem;
    list-style-type: '•';
  }
  li {
    line-height: 2.1rem;
    padding-left: 0.4rem;
  }
`;

export const CompleteProfileTitle = styled.h5`
  color: ${Colors.tealDark};
  line-height: 2.1rem;
  font-weight: 500;
  font-size: 1.3rem;
  margin: 0;
`;

export const Header = styled.h1`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 1rem 0 0;
  flex: 1;
  min-width: 0;
  margin-left: 2rem;
`;

export const Meta = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  margin-bottom: 1.3rem;
  font-size: 2.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ConsistentBadge = `
  height: 3rem;
  font-weight: 600;
  padding: 1.5rem 1.4rem;
  margin-left: 0;
`;

export const Badge = styled(CompanyProfileTypeBadge)`
  margin-right: 3rem;
  ${ConsistentBadge}
`;

export const VerifiedBadge = styled(IconBadge)`
  margin-left: 2rem;
`;

export const CommunityBadge = styled(CommunityBadgeBase)`
  ${ConsistentBadge}
  border: 1px solid ${Colors.outline};
`;

export const Label = styled.span`
  ${LabelStyle}
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProgressWrap = styled.div`
  width: 18.5rem;
  ${Label} {
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

export const Card = styled.div`
  padding: 0.8rem 1rem;
  border-radius: 0.3rem;
  border: 0.1rem solid ${Colors.outline};
  background: ${Colors.white};
  width: 20.7rem;
  height: 7rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
`;

export const RightSideWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 2.7rem;
  min-width: 20.7rem;
  width: 20.7rem;
  flex: 1;
`;

export const LeftSideWrapper = styled.div``;

export const HubSwitchWrap = styled.div`
  min-width: 18.7rem;
  ${Label} {
    margin-bottom: 0.4rem;
    width: 100%;
  }

  ${Activator} {
    display: flex;
  }
`;

export const SwitchFieldset = styled(FieldsetBase)``;

export const LabelsRow = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-bottom: 1rem;
  }
  ${({ wrappable }) => (wrappable ? 'flex-wrap: wrap;' : '')}
`;

export const InfoCell = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 3rem;

  & > span:first-child {
    font-size: 1.1rem;
    ${LabelStyle}
  }

  & > span:last-child {
    ${({ theme }) => theme.typography.text.large};
  }
`;

export const IconTag = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  ${({ theme }) => theme.typography.text.small}

  ${LabelStyle}
`;

export const Icon = styled(IconBase)`
  margin-right: 0.6rem;
`;

export const ButtonEdit = styled(ButtonBase)`
  margin-left: 1rem;
`;

export const Message = styled.span`
  color: ${({ theme, isMissing }) =>
    isMissing ? Colors.red : theme.colors.primary};
`;

export const NotAcceptingRequestsInfoBox = styled(InfoBoxBase).attrs({
  iconSize: 'small',
  backgroundColor: 'grey',
  borderColor: 'outline',
  color: 'textLight',
})`
  width: 17.7rem;
  padding: 0.8rem 0.6rem;

  & > div:last-child {
    font-size: 1.3rem;
    line-height: 1.38;
    color: ${Colors.textLight};
    margin-top: 0;
    padding-left: 0.8rem;
  }

  & svg {
    margin-right: 0;
  }
`;
