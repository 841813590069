import PropTypes from 'prop-types';

import { FormLabel } from '../label/label';

import { InputSlider as Slider } from '../slider/slider';

import * as styled from './styles/fieldset';

export const SliderFieldset = function SliderFieldset(props) {
  const {
    className,
    amount,
    context,
    fn,
    includeIndicator = true,
    descriptionUI = null,
    shouldValidate,
    children,
    tooltip,
    formatValue,
  } = props;
  const { id, label, min, max, step = 1, required } = context;

  return (
    <styled.Fieldset className={className}>
      <FormLabel
        required={required}
        includeIndicator={includeIndicator}
        value={label}
        elementToRefer={id}
      />

      {descriptionUI}

      <styled.SliderField>
        <Slider
          min={min}
          max={max}
          step={step}
          tooltip={tooltip}
          inputValue={amount}
          formatValue={formatValue}
          shouldValidate={shouldValidate}
          onChange={(value) => fn(value)}
        />
        {children}
      </styled.SliderField>
    </styled.Fieldset>
  );
};

SliderFieldset.propTypes = {
  className: PropTypes.string,
  includeIndicator: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  amount: PropTypes.number,
  context: PropTypes.object,
  descriptionUI: PropTypes.object,
  fn: PropTypes.func,
  children: PropTypes.object,
  tooltip: PropTypes.bool,
  formatValue: PropTypes.func,
};
