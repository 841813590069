import qs from 'qs';
import equal from 'fast-deep-equal';
import { get, reduce, reject, omit, find, isEmpty, trim } from 'lodash';

const stringifyPagination = ({ page, pageSize }, defaultPageSize) => ({
  ...(page > 1 ? { page } : {}),
  ...(pageSize !== defaultPageSize ? { page_size: pageSize } : {}),
});

const stringifySorting = (sorted, defaultSorted) => {
  if (sorted.length > 0 && !equal(sorted, defaultSorted)) {
    return {
      sort_by: sorted[0].id,
      sort_order: sorted[0].desc ? 'DESC' : 'ASC',
    };
  }

  return {};
};

const stringifyFilters = (filtered, defaultFiltered) => {
  const withoutDefaults = reject(
    filtered,
    ({ id, value }) =>
      find(defaultFiltered, { id }) &&
      find(defaultFiltered, { id }).value === value
  );
  const withoutToolbar = reject(withoutDefaults, { id: 'g-query' });

  return reduce(
    withoutToolbar,
    (acc, { id, value }) => ({ ...acc, [id]: value }),
    {}
  );
};

export const toQueryString = ({ filtered, sorted, paginated }, defaults) => {
  const {
    defaultFiltered,
    externalFiltered = [],
    defaultSorted,
    defaultPageSize,
  } = defaults;
  const queryParams = {
    ...stringifyPagination(paginated, defaultPageSize),
    ...stringifySorting(sorted, defaultSorted),
    ...stringifyFilters([...filtered, ...externalFiltered], defaultFiltered),
  };

  return isEmpty(queryParams) ? '' : `?${qs.stringify(queryParams)}`;
};

const parseSorting = (params) =>
  get(params, 'sort_by') && get(params, 'sort_order')
    ? [{ id: params.sort_by, desc: params.sort_order === 'DESC' }]
    : [];

const parseFilters = (params) => {
  const filters = omit(params, ['page', 'page_size', 'sort_by', 'sort_order']);
  return Object.keys(filters).map((f) => ({ id: f, value: filters[f] }));
};

export const fromQueryString = (query) => {
  const params = qs.parse(trim(query, '?'));

  return {
    filtered: parseFilters(params),
    sorted: parseSorting(params),
    paginated: {
      ...(get(params, 'page') && { page: parseInt(params.page, 10) }),
      ...(get(params, 'page_size') && {
        pageSize: parseInt(params.page_size, 10),
      }),
    },
  };
};
