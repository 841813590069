import PropTypes from 'prop-types';

import { Upload, UploadPropTypes } from '../upload/index';

import * as styled from './styles/fieldset';

export const UploadFieldset = function UploadFieldset(props) {
  const {
    className,
    children,
    files,
    loading,
    disabled,
    shouldValidate,
    includeIndicator,
    fn,
    context: {
      id,
      detail,
      buttonDetail,
      required,
      inputDescription,
      label,
      acceptedFormats,
      acceptedDimensions,
      maxFileSize,
    },
  } = props;

  const description = inputDescription ? (
    <styled.DescriptionLabel
      value={inputDescription}
      required={false}
      includeIndicator={false}
    />
  ) : null;

  return (
    <styled.Fieldset className={className}>
      <styled.FormLabel
        margin="0 0 1rem"
        includeIndicator={includeIndicator}
        value={label}
        required={required}
        elementToRefer={id}
      />
      {description}
      <styled.FieldsetUploadWrapper>
        <Upload
          id={id}
          detail={detail}
          buttonDetail={buttonDetail}
          files={files}
          acceptedFormats={acceptedFormats}
          acceptedDimensions={acceptedDimensions}
          maxFileSize={maxFileSize}
          loading={loading}
          disabled={disabled}
          required={required}
          shouldValidate={shouldValidate}
          onChange={(value) => fn(value)}
        />
        {children}
      </styled.FieldsetUploadWrapper>
    </styled.Fieldset>
  );
};

UploadFieldset.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  context: PropTypes.shape({
    id: PropTypes.string,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonDetail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inputDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    acceptedFormats: PropTypes.array,
    acceptedDimensions: UploadPropTypes.acceptedDimensions,
    maxFileSize: PropTypes.number,
    required: PropTypes.bool,
    label: PropTypes.string,
  }),
  files: PropTypes.array,
  includeIndicator: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  fn: PropTypes.func,
};
