import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Flexbox } from 'components/structure/page/styles/containers';
import * as styledModal from 'components/overlay/Modal/styles/modal';

import { Textarea as TextareaBase } from 'components/form/textarea/textarea';
import { Submit as SubmitBase } from 'components/form/submit/submit';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';

export const Form = styled.form`
  width: 100%;
`;

export const Textarea = styled(TextareaBase)`
  margin-top: 2rem;
  color: ${Colors.text};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0;
`;

export const Submit = styled(SubmitBase)``;

export const List = styled.ul`
  padding: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 2rem 0 3rem 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Image = styled.img`
  flex: 0 0 auto;
`;

export const ContentWrap = styled.div`
  flex: 1 1 auto;
  margin: 0 2rem;
`;

export const Type = styled.p`
  margin: 0;
  color: ${Colors.red};
`;

export const Content = styled.p`
  margin: 0;
  white-space: pre-line;
`;

export const Detail = styled.p`
  margin: 0.5rem 0;
  ${({ theme }) => theme.typography.text.small};
  color: ${Colors.textLighter};
`;

export const User = styled(SmartLinkBase)`
  margin: 0.5rem 0;
  ${({ theme }) => theme.typography.link.medium};
  color: ${Colors.textLighter};

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Actions = styled.div`
  flex: 0 0 auto;
`;

export const ModalView = styled(Flexbox)`
  flex-direction: column;
  align-items: flex-start;
  background-color: ${Colors.white};
  border-radius: 0.6rem;
  min-width: 50rem;
  position: relative;
  padding: 3rem 5rem;

  ${ButtonWrapper} {
    margin-bottom: 0;
  }
`;

export const FormTitle = styledModal.Title;
