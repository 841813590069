import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import {
  Flexbox,
  GrayContainer,
} from 'components/structure/page/styles/containers';
import * as styledModal from 'components/overlay/Modal/styles/modal';

export const ModalView = styled(Flexbox)`
  flex-direction: column;
  align-items: flex-start;
  background-color: ${Colors.white};
  border-radius: 0.6rem;
  width: ${({ size }) => (size === 'large' ? 60 : 40)}rem;
  position: relative;
  padding: 3rem;
`;

export const Title = styled(styledModal.Title)`
  margin-bottom: 3rem;
  font-weight: 500;
  color: ${Colors.tealDark};
`;

export const Wrapper = styled.div`
  width: 100%;

  ${GrayContainer} {
    padding: 0;
    justify-content: left;
    background-color: ${Colors.white};
  }
`;
