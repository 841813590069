import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { isEmpty } from 'lodash';

import { Portal } from 'components/structure/Portal';
import { Upload, UploadPropTypes } from 'components/form/upload';
import { Icon } from 'components/elements/icon';

import { UploadOptionButtonDropdown } from './ui/uploadOptionButtonDropdown';
import { BullhornModal } from './ui/bullhornModal';

import * as styled from './styles';
import i18n from './utils/i18n';

export const UploadWithBullhorn = (props) => {
  const { bullhornFilesProps, acceptedFormats, clearable, disabled } = props;

  const [showBullhornModal, setShowBullhornModal] = useState(false);
  const dropdownRef = useRef();

  return (
    <Upload {...props} detail={i18n.inputPlaceholder}>
      {({
        getRootProps,
        getInputProps,
        onDrop,
        openSelectFile,
        clearFile,
        placeholderDetail,
        files,
      }) => (
        <>
          <styled.DropzoneBox
            {...getRootProps()}
            onClick={(event) => dropdownRef.current.toggleMenu(event)}
          >
            <styled.Detail>{placeholderDetail}</styled.Detail>
            {clearable && !disabled && !isEmpty(files) && (
              <styled.Button
                size="small"
                color="tealLighter"
                onClick={clearFile}
              >
                <Icon icon="XCircle" />
                <FormattedMessage {...i18n.buttonRemove} />
              </styled.Button>
            )}
            <UploadOptionButtonDropdown
              openSelectFile={openSelectFile}
              openBullhornModal={() => setShowBullhornModal(true)}
              bullhornFilesProps={bullhornFilesProps}
              dropdownRef={dropdownRef}
            />
            <input {...getInputProps()} />
          </styled.DropzoneBox>
          {showBullhornModal && (
            <Portal>
              <BullhornModal
                acceptedFormats={acceptedFormats}
                bullhornFilesProps={bullhornFilesProps}
                onDrop={onDrop}
                onClose={() => setShowBullhornModal(false)}
              />
            </Portal>
          )}
        </>
      )}
    </Upload>
  );
};

UploadWithBullhorn.defaultProps = {
  acceptedFormats: ['pdf'],
};

UploadWithBullhorn.propTypes = {
  ...UploadPropTypes,
  getRootProps: PropTypes.func,
  placeholderDetail: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dropzoneRef: PropTypes.node,
  acceptedFormats: PropTypes.array,
  bullhornFilesProps: PropTypes.shape({
    bullhornData: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    fieldName: PropTypes.string,
  }),
};
