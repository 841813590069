import { FormattedMessage } from 'react-intl';

import { Spinner, SPINNER_TYPES } from 'components/visual/Spinner';
import type { IconName } from 'components/elements/types';

import { Layout } from './Layout';
import { FallbackActions } from './FallbackActions';

type CustomFallbackProps = {
  showReload?: boolean;
  showDashboard?: boolean;
  showSupport: boolean;
  showLogout?: boolean;
  title: I18nMessage;
  custom: {
    action: string;
    icon: IconName;
    text: I18nMessage;
  };
};

export const CustomFallback = (props: CustomFallbackProps) => {
  const {
    showReload = false,
    showDashboard = false,
    showSupport = false,
    showLogout = false,
    title,
    custom,
  } = props;

  return (
    <Layout
      icon={<Spinner type={SPINNER_TYPES.error} />}
      title={<FormattedMessage {...title} />}
    >
      <FallbackActions
        showReload={showReload}
        showDashboard={showDashboard}
        showSupport={showSupport}
        showLogout={showLogout}
        custom={custom}
      />
    </Layout>
  );
};
