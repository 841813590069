import { httpGet, httpPost, httpPatch, httpDelete } from 'utils';

import { openFlashMessage } from 'components/utils/flashMessages';

import {
  COMPANY_NOTES_FETCH_REQUEST,
  COMPANY_NOTES_FETCH_SUCCESS,
  COMPANY_NOTES_FETCH_ERROR,
  COMPANY_NOTE_CREATE_REQUEST,
  COMPANY_NOTE_CREATE_SUCCESS,
  COMPANY_NOTE_CREATE_ERROR,
  COMPANY_NOTE_UPDATE_REQUEST,
  COMPANY_NOTE_UPDATE_SUCCESS,
  COMPANY_NOTE_UPDATE_ERROR,
  COMPANY_NOTE_DELETE_REQUEST,
  COMPANY_NOTE_DELETE_SUCCESS,
  COMPANY_NOTE_DELETE_ERROR,
} from './constants';

import { FlashDefinition } from './utils/flashDefinition';

export function fetchNotes(companyId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_NOTES_FETCH_REQUEST });

      httpGet(`/company/${companyId}/notes`)
        .then((response) => {
          dispatch({
            type: COMPANY_NOTES_FETCH_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_NOTES_FETCH_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function createNote(companyId, note) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_NOTE_CREATE_REQUEST });

      httpPost(`/company/${companyId}/notes`, { note })
        .then((response) => {
          dispatch({
            type: COMPANY_NOTE_CREATE_SUCCESS,
            payload: response,
          });

          openFlashMessage(FlashDefinition.createSuccess);

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_NOTE_CREATE_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function updateNote(companyId, note) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_NOTE_UPDATE_REQUEST });

      httpPatch(`/company/${companyId}/notes/${note.id}`, { note })
        .then((response) => {
          dispatch({
            type: COMPANY_NOTE_UPDATE_SUCCESS,
            payload: response,
          });

          openFlashMessage(FlashDefinition.updateSuccess);

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_NOTE_UPDATE_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function deleteNote(companyId, note) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_NOTE_DELETE_REQUEST });

      httpDelete(`/company/${companyId}/notes/${note.id}`)
        .then((response) => {
          dispatch({
            type: COMPANY_NOTE_DELETE_SUCCESS,
            payload: response,
          });

          openFlashMessage(FlashDefinition.deleteSuccess);

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_NOTE_DELETE_ERROR,
            error,
          });

          reject(error);
        });
    });
}
