import { endsWith, forEach, forOwn, isNil } from 'lodash';

if (process.env.NODE_ENV !== 'test') {
  require('formdata-polyfill'); // eslint-disable-line
}

/**
 * @function getPropertyValue
 * @param {object} object
 * @param {array} propertyPath
 *   (e.g ['name'] or ['employer', 'name'] or ['candidates', 2, 'name'])
 *   in the last example 2 is the index to get
 * @return {} value associated to property
 * @description it's a recursive function that navigates
 * the object until it gets the value of the propertyPath
 */
export function getPropertyValue(object, propertyPath) {
  if (!object) {
    return null;
  }

  if (propertyPath.length === 1) {
    return object[propertyPath];
  }

  const objectToRecurse = object[propertyPath[0]];

  return getPropertyValue(objectToRecurse, propertyPath.slice(1));
}

export function getSelectValue(value) {
  return value === 'none' ? null : value;
}

/**
 * @function valueForProperty
 * @param {string} propertyRoute (e.g. salary.min)
 *   (e.g 'name' or 'employer.name' or 'candidates.2.name')
 *   in the last example 2 is the index to get
 * @param {} defaultValue - value to assign to property of obj with propertyRoute param
 * @param {object} editObj - obj to use to map propertyRoute
 * @return {} defaultValue || propertyValue
 */
export function valueForProperty(propertyRoute, defaultValue, editObj) {
  if (!editObj) {
    return defaultValue;
  }

  const propertyValue = getPropertyValue(editObj, propertyRoute.split('.'));

  return isNil(propertyValue) ? defaultValue : propertyValue;
}

/**
 * @function isInstanceOfFile
 * @param {File|Blob|object|null} value
 * @return {boolean} is passed param upload File object
 */
export function isInstanceOfFile(value) {
  return (
    value &&
    (value instanceof File ||
      value instanceof Blob ||
      (value.preview && value.preview.startsWith('blob:http://')))
  );
}

/**
 * @function objectToFormData
 * @param {object} obj
 * @param {FormData} form
 * @param {string} property
 * @return {FormData}
 */
/* eslint no-underscore-dangle: 0 */
export function objectToFormData(obj, form, namespace) {
  const formData = form || new FormData();
  let formKey;

  forOwn(obj, (value, property) => {
    if (namespace) {
      formKey = `${namespace}[${property}]`;
    } else {
      formKey = property;
    }

    // if the property is an object, but not a File, use recursivity.
    const isArrayProperty = endsWith(property, '[]');
    const isTypeOfObject = typeof obj[property] === 'object';
    let isFile = isInstanceOfFile(obj[property]);

    if (isTypeOfObject && !isFile && !isArrayProperty) {
      objectToFormData(obj[property], formData, formKey);
    } else if (isArrayProperty) {
      forEach(value, (arrVal) => {
        isFile = isInstanceOfFile(arrVal);

        if (isFile) {
          formData.append(formKey, arrVal);
        } else {
          formData.append(formKey, JSON.stringify(arrVal));
        }
      });
    } else {
      // if it's a string or a File object
      formData.append(formKey, obj[property]);
    }
  });

  return formData._blob ? formData._blob() : formData;
}
