import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Tags as TagsBase } from 'components/visual/tags';

export const InputContainer = styled.div`
  padding: 1rem 1.4rem 0.8rem 1.4rem;
  border: 0.1rem solid
    ${({ theme, focus, isValid }) => {
      if (focus) {
        return theme.colors.primary;
      }
      if (isValid) {
        return Colors.grey;
      }
      return Colors.red;
    }};
  border-radius: 0.3rem;
`;

export const TagsList = styled(TagsBase)`
  margin-bottom: 0;
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  color: ${Colors.text};

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${Colors.greyDark};
  }
`;
