import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { occupiedHeight } from 'components/structure/page/utils/calculations';
import { ContextHeader as ContextHeaderBase } from 'components/structure/context-header';
import { Icon as IconBase } from 'components/elements/icon';
import { InfoBox as InfoBoxBase } from 'components/text/InfoBox';
import { Card } from './section';

export const Wrapper = styled.div`
  color: ${Colors.text};
  background: ${Colors.white};
  background-image: linear-gradient(
    to bottom,
    ${Colors.tealLighter} 0%,
    #fafcff 100%
  );
  background-size: 100% 15rem;
  background-repeat: no-repeat;
  min-width: 80rem;
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 140rem;
  min-height: calc(
    100vh - ${occupiedHeight({ withToolbar: false, withContext: false })}rem -
      ${({ isPublic }) => (isPublic ? '.3' : '0')}rem
  );
`;

export const ContextHeader = styled(ContextHeaderBase)`
  border-top: 0.3rem solid ${Colors.greyLighter};
`;

export const Top = styled.div``;

export const Layout = styled.main`
  display: flex;
  margin: 0 2rem;
  border-bottom: ${({ hasBorder }) =>
    hasBorder ? `.1rem solid ${Colors.greyLight}` : 'none'};
  margin-bottom: ${({ hasMargin }) => (hasMargin ? '2rem' : '0')};
`;

export const Section = styled.section`
  box-sizing: border-box;
  flex: ${({ ratio }) => (ratio ? `0 0 calc(${ratio} * 100%)` : '1 1 auto')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}` : '')}
  ${({ centerColumn }) =>
    centerColumn
      ? `@media (max-width: 1350px) {
          > div {
            margin-right: 0;
          }
        }`
      : ''}
`;

export const BottomRightSection = styled(Section)`
  display: flex;
  flex-flow: row wrap;

  @media (max-width: 1350px) {
    display: block;
    flex: auto;
  }
`;

export const WarningMessage = styled.p`
  color: ${Colors.red};
  margin: 0 0 1rem 0;
`;

export const Icon = styled(IconBase)``;

export const CheckedItem = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;

  ${Icon} path {
    fill: ${Colors.green};
  }
`;

export const InfoBox = styled(InfoBoxBase)`
  margin: 0 2rem 2rem 0;
`;

export const NarrowInline = styled.div`
  display: flex;
  justify-content: space-between;

  && > ${Card} {
    margin-top: 0;
    margin-bottom: 2rem;
    width: calc(50% - 1rem);
  }

  ${({ hideOnLargeScreen }) =>
    hideOnLargeScreen
      ? `@media (min-width: 1351px) {
          display: none;
        }`
      : ''}
`;
