import PropTypes from 'prop-types';

import * as styled from './styles';

export const ProgressBar = function ProgressBar(props) {
  const {
    className,
    hasLabel,
    progress,
    barStyle,
    fillColor,
    fillStyle,
    bordered,
    labelColor,
    labelSize,
    barWidth,
  } = props;

  return (
    <styled.ProgressBar className={className}>
      {hasLabel && (
        <styled.Label labelColor={labelColor} labelSize={labelSize}>
          {Math.round(progress)}%
        </styled.Label>
      )}
      <styled.BarWrapper
        barStyle={barStyle}
        bordered={bordered}
        barWidth={barWidth}
      >
        <styled.Fill
          progress={progress}
          fillColor={fillColor}
          fillStyle={fillStyle}
          bordered={bordered}
        />
        <styled.Bar barStyle={barStyle} />
      </styled.BarWrapper>
    </styled.ProgressBar>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number,
  hasLabel: PropTypes.bool,
  barStyle: PropTypes.string,
  fillColor: PropTypes.string,
  fillStyle: PropTypes.string,
  bordered: PropTypes.bool,
  labelColor: PropTypes.string,
  labelSize: PropTypes.number,
  barWidth: PropTypes.string,
};

ProgressBar.defaultProps = {
  progress: 0,
  hasLabel: true,
  barStyle: 'default',
  fillColor: 'greenLight',
  fillStyle: 'default',
  bordered: false,
  labelColor: 'teal',
  labelSize: 2,
  barWidth: '100%',
};
