import { useEffect } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { ReactPageClick } from 'react-page-click';
import { isEmpty, get, includes } from 'lodash';

import { Emoji } from 'components/elements/emoji';
import { Icon } from 'components/elements/icon';
import { DotTyping } from 'components/feedback/DotTyping';

import * as styled from './styles';
import i18n from './utils/i18n';
import { Suggestions } from './ui/suggestions';
import type { PromptSuggestion } from '..';

const WrapperWidth = 400;

type SceneProps = {
  intl: IntlShape;
  dimensions: { top: number; left: number };
  inProgress: boolean;
  isReplacing: boolean;
  prompt: Prompt;
  suggestions: Suggestion[];
  currentIndex: number;
  suggestionsCount: number;
  maxLimit: number;
  promptSuggestions: PromptSuggestion[];
  nextPrompt: { value?: Prompt; status: boolean };
  onGenerate: (value?: Prompt) => void;
  onReplaceContent: (value: Suggestion) => void;
  onClose: VoidCallback;
  setPrompt: (value: Prompt) => void;
  setIndex: (value: number) => void;
};

export const Scene = (props: SceneProps) => {
  const {
    intl,
    dimensions,
    prompt,
    promptSuggestions,
    suggestions,
    suggestionsCount,
    maxLimit,
    inProgress,
    isReplacing,
    nextPrompt,
    currentIndex,
    setPrompt,
    onGenerate,
    onReplaceContent,
    onClose,
    setIndex,
  } = props;

  const isLast = currentIndex === suggestionsCount - 1;
  const currentSuggestion = get(suggestions, currentIndex);
  const isGenerating = isLast && !currentSuggestion.is_complete;
  const isGeneratingSelectedDone = !isEmpty(
    currentSuggestion?.content?.modified_full_content
  );
  const maxReached = suggestionsCount === maxLimit;
  const inputDisabled =
    (isGenerating && !isGeneratingSelectedDone) ||
    (isGeneratingSelectedDone && nextPrompt?.status) ||
    (!isLast && suggestionsCount > 0) ||
    maxReached;
  const loadingSpinner =
    isGenerating && (!isGeneratingSelectedDone || nextPrompt?.status);

  const filteredSuggestions = promptSuggestions.filter(({ value }) =>
    includes(value.toLowerCase(), prompt.value.toLowerCase())
  );

  const handleOnGenerate = (promptValue?: Prompt) => {
    if (!inputDisabled) {
      onGenerate(promptValue);
    }
  };

  useEffect(() => {
    if (isLast && nextPrompt?.status && currentSuggestion?.is_complete) {
      onGenerate(nextPrompt.value);
    }
  }, [nextPrompt, currentSuggestion, isLast]);

  const placeholder = () => {
    if (inputDisabled && maxReached) {
      return intl.formatMessage(i18n.placeholderMaxLimitReached, { maxLimit });
    }
    if (inputDisabled) {
      return i18n.editDisabled;
    }

    return i18n.inputPlaceholder;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Enter') {
      handleOnGenerate();
    }
  };

  return (
    <ReactPageClick notify={onClose}>
      <styled.Wrapper
        style={{
          top: dimensions.top,
          left: dimensions.left - WrapperWidth - 80,
        }}
      >
        <styled.Top>
          {!!suggestionsCount && (
            <Suggestions
              suggestions={suggestions}
              promptSuggestions={promptSuggestions}
              inProgress={inProgress}
              isReplacing={isReplacing}
              onReplaceContent={onReplaceContent}
              onGenerate={handleOnGenerate}
              currentIndex={currentIndex}
              setIndex={setIndex}
            />
          )}
          <styled.InputWrapper
            hasRoundCorners={!suggestionsCount}
            disabled={inputDisabled}
            inProgress={loadingSpinner || inProgress}
          >
            {((isGenerating &&
              isGeneratingSelectedDone &&
              !nextPrompt?.status) ||
              (!inProgress && !isGenerating)) && (
              <styled.TextInput
                inputType="text"
                disabled={inputDisabled}
                value={prompt.value}
                onChange={(value: string) =>
                  setPrompt({
                    value,
                    short_value: value,
                  })
                }
                placeholder={placeholder()}
                onKeyDown={handleKeyDown}
              />
            )}
            {(inProgress || loadingSpinner) && (
              <styled.Loading>
                <Icon icon="MagicWand" weight="bold" color="grape" />
                <styled.LoadingText>
                  <FormattedMessage {...i18n.AITyping} />
                </styled.LoadingText>
                <DotTyping />
              </styled.Loading>
            )}
            <styled.SubmitIcon
              icon="PaperPlaneTilt"
              color="textLighter"
              size="xlarge"
              disabled={inputDisabled}
              onClick={() => handleOnGenerate()}
            />
          </styled.InputWrapper>
        </styled.Top>
        {!isEmpty(filteredSuggestions) &&
          !inputDisabled &&
          !nextPrompt?.status &&
          !inProgress && (
            <styled.DropDownWrapper>
              {filteredSuggestions.map(
                ({ emojiName, value, text, shortValue }) => (
                  <styled.DropDownOption
                    onClick={() =>
                      handleOnGenerate({ value, short_value: shortValue })
                    }
                    key={`dropdown-options-${shortValue}-${emojiName}`}
                  >
                    <Emoji name={emojiName} />
                    <styled.OptionText>
                      <FormattedMessage {...text} />
                    </styled.OptionText>
                  </styled.DropDownOption>
                )
              )}
            </styled.DropDownWrapper>
          )}
      </styled.Wrapper>
    </ReactPageClick>
  );
};
