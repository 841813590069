import { httpGet, httpPost, httpPatch, httpUploadPut, httpDelete } from 'utils';

import { openFlashMessage } from 'components/utils/flashMessages';

import {
  COMPANY_DOCUMENTS_FETCH_REQUEST,
  COMPANY_DOCUMENTS_FETCH_SUCCESS,
  COMPANY_DOCUMENTS_FETCH_ERROR,
  COMPANY_DOCUMENT_UPLOAD_REQUEST,
  COMPANY_DOCUMENT_UPLOAD_SUCCESS,
  COMPANY_DOCUMENT_UPLOAD_ERROR,
  COMPANY_DOCUMENT_RENAME_REQUEST,
  COMPANY_DOCUMENT_RENAME_SUCCESS,
  COMPANY_DOCUMENT_RENAME_ERROR,
  COMPANY_DOCUMENT_DELETE_REQUEST,
  COMPANY_DOCUMENT_DELETE_SUCCESS,
  COMPANY_DOCUMENT_DELETE_ERROR,
} from './constants';

import { FlashDefinition } from './utils/flashDefinition';

export function fetchDocuments(comapnyId, type) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_DOCUMENTS_FETCH_REQUEST });

      httpGet(`/company/${comapnyId}/documents?type=${type}`)
        .then((response) => {
          dispatch({
            type: COMPANY_DOCUMENTS_FETCH_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_DOCUMENTS_FETCH_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function uploadDocument(comapnyId, { file, name, type, data }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_DOCUMENT_UPLOAD_REQUEST });

      const payload = { document: file.name, name, type, meta: data || {} };
      let doc;

      httpPost(`/company/${comapnyId}/documents`, payload)
        .then((response) => {
          doc = response.document;
          return httpUploadPut(response.s3_upload_url, file);
        })
        .then(() => {
          httpPatch(`/company/${comapnyId}/documents/${doc.id}`, {
            document: { status: 'available' },
          });

          dispatch({
            type: COMPANY_DOCUMENT_UPLOAD_SUCCESS,
            payload: doc,
          });

          openFlashMessage(FlashDefinition.uploadSuccess);

          resolve(doc);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_DOCUMENT_UPLOAD_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function renameDocument(comapnyId, doc) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_DOCUMENT_RENAME_REQUEST });

      httpPatch(`/company/${comapnyId}/documents/${doc.id}`, { document: doc })
        .then((response) => {
          dispatch({
            type: COMPANY_DOCUMENT_RENAME_SUCCESS,
            payload: response,
          });

          openFlashMessage(FlashDefinition.renameSuccess);

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_DOCUMENT_RENAME_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function deleteDocument(comapnyId, doc) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_DOCUMENT_DELETE_REQUEST });

      httpDelete(`/company/${comapnyId}/documents/${doc.id}`)
        .then((response) => {
          dispatch({
            type: COMPANY_DOCUMENT_DELETE_SUCCESS,
            payload: response,
          });

          openFlashMessage(FlashDefinition.deleteSuccess);

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_DOCUMENT_DELETE_ERROR,
            error,
          });

          reject(error);
        });
    });
}
