import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { SpinnerSmall } from 'components/visual/Spinner/styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ withBorder }) => withBorder && `border: 1px solid ${Colors.outline};`}
  background-color: ${Colors.white};
`;

export const Preparing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-self: unset;
  width: 34rem;
  height: 14rem;
  margin: 5rem auto;
  border-radius: 0.3rem;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
`;

export const Spinner = styled(SpinnerSmall)`
  width: 2.4rem;
  height: 2.4rem;
  margin: 3rem 0 1.6rem 0;
  border-color: ${Colors.tealDark};
`;

export const Header = styled.h4`
  margin: 0;
  margin-bottom: 1.2rem;
`;

export const Subheader = styled.div`
  font-size: 1.3rem;
  color: ${Colors.textLight};
`;

export const Failed = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.typography.text.medium};

  & > img {
    width: 12.4rem;
  }

  & > p {
    margin: 0 0 2.4rem 0;
    line-height: 2.4rem;
  }
`;
