import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Button as ButtonBase, BUTTON_SIZES } from 'components/elements/button';
import { DescriptionList as DescriptionListBase } from 'components/lists/DescriptionList';
import { Term } from 'components/lists/DescriptionList/styles/descriptionList';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';
import { TruncateWithTooltip as TruncateWithTooltipBase } from 'components/text/TruncateWithTooltip';

export const TruncateWithTooltip = styled(TruncateWithTooltipBase)`
  display: inline-block;
  width: calc(100% - 2.6rem);
`;

export const DescriptionList = styled(DescriptionListBase)`
  & > dt,
  & > dd {
    min-width: 100%;
  }

  & > dt {
    padding: 1rem 2rem 0.5rem 0;
    font-weight: 500;
  }

  & > dd {
    padding: 0 0 1rem 0;
    max-width: 21rem;

    & > svg {
      margin-left: 0;
    }
  }

  ${Term} {
    color: ${Colors.text};
  }
`;

export const SmartLink = styled(SmartLinkBase)`
  color: ${Colors.text} !important;
  width: 100%;

  &:hover {
    text-decoration: underline;
    ${({ companyLink }) =>
      companyLink && `color: ${Colors.tealDark} !important;`}
  }
`;

export const ButtonEdit = styled(ButtonBase)`
  position: absolute;
  top: 2rem;
  right: 4rem;
`;

export const TooltipButton = styled(ButtonBase).attrs({
  size: BUTTON_SIZES.medium,
})`
  position: absolute;
  top: 3rem;
  transform: translateX(-0.5rem);

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: calc(50% - 0.5rem);
    border: 0.6rem solid ${Colors.transparent};
    border-bottom-color: ${Colors.teal};
  }
`;
