import { useState } from 'react';
import { CustomPicker } from 'react-color';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Saturation,
  Hue,
  EditableInput,
} from 'react-color/lib/components/common';
import { EditableInputProps } from 'react-color/lib/components/common/EditableInput';
import useEyeDropper from 'use-eye-dropper';

import { Colors } from 'components/utils/styles/ui';

import * as styled from './styles';
import type {
  ColorPickerProps,
  RGBKeys,
  HSLKeys,
  RGBInputFields,
  HSLInputFields,
  InputField,
  Color,
} from './types';
import i18n from './utils/i18n';

const ColorPicker = (props: ColorPickerProps) => {
  const { hex, rgb, hsl, onChange } = props;
  const intl = useIntl();

  const { open, isSupported } = useEyeDropper();
  const [inputType, setInputType] = useState<'hex' | 'rgb' | 'hsl'>('hex');

  const handleColorChange = (
    color: Partial<
      ColorPickerProps['rgb'] & ColorPickerProps['hsl'] & { hex: string }
    >
  ) => {
    onChange(color);
  };

  const handleOpenEyedropper = async (event: React.KeyboardEvent) => {
    event.stopPropagation();
    const { sRGBHex } = await open();
    onChange({ hex: sRGBHex });
  };

  const handleToggleInputType = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    setInputType((prev) => {
      if (prev === 'hex') {
        return 'rgb';
      }
      if (prev === 'rgb') {
        return 'hsl';
      }
      return 'hex';
    });
  };

  const handleRGBChange = (key: RGBKeys, value: string | number) => {
    handleColorChange({
      rgb: { ...rgb, [key]: Number(value) },
    } as Partial<Color>);
  };

  const handleHSLChange = (key: HSLKeys, value: string | number) => {
    handleColorChange({
      hsl: { ...hsl, [key]: Number(value) },
    } as Partial<Color>);
  };

  const renderInputField = () => {
    const editableInputStyles: EditableInputProps['style'] = {
      input: {
        width: inputType === 'hex' ? '18.6rem' : '5.4rem',
        fontSize: '1.4rem',
        border: `1px solid ${Colors.grey}`,
        padding: '0.5rem',
        textAlign: 'center' as const,
      },
    };

    const rgbFields: RGBInputFields = [
      { label: intl.formatMessage(i18n.red), value: rgb.r, key: 'r' },
      { label: intl.formatMessage(i18n.green), value: rgb.g, key: 'g' },
      { label: intl.formatMessage(i18n.blue), value: rgb.b, key: 'b' },
    ];

    const hslFields: HSLInputFields = [
      {
        label: intl.formatMessage(i18n.hue),
        value: Math.round(hsl.h),
        key: 'h',
      },
      {
        label: intl.formatMessage(i18n.saturation),
        value: Math.round(hsl.s * 100),
        key: 's',
      },
      {
        label: intl.formatMessage(i18n.lightness),
        value: Math.round(hsl.l * 100),
        key: 'l',
      },
    ];

    switch (inputType) {
      case 'rgb':
        return renderFields(rgbFields, editableInputStyles, handleRGBChange);

      case 'hsl':
        return renderFields(hslFields, editableInputStyles, handleHSLChange);

      case 'hex':
      default:
        return (
          <styled.InputFieldWrapper>
            <EditableInput
              style={editableInputStyles}
              value={hex}
              onChange={(e) => handleColorChange({ hex: e })}
            />
            <styled.InputLabel>
              <FormattedMessage {...i18n.hex} />
            </styled.InputLabel>
          </styled.InputFieldWrapper>
        );
    }
  };

  const renderFields = <T extends string>(
    inputFields: InputField<T>[],
    editableInputStyles: EditableInputProps['style'],
    handleChange: (key: T, value: string | number) => void
  ) =>
    inputFields.map(({ label, value, key }) => (
      <styled.InputFieldWrapper key={key}>
        <EditableInput
          style={editableInputStyles}
          value={value}
          onChange={(newValue) => handleChange(key, newValue)}
        />
        <styled.InputLabel>{label}</styled.InputLabel>
      </styled.InputFieldWrapper>
    ));

  return (
    <styled.PickerWrapper>
      <styled.SaturationWrapper>
        <Saturation {...props} onChange={handleColorChange} />
      </styled.SaturationWrapper>

      <styled.PreviewAndHueWrapper>
        {isSupported() && (
          <styled.EyedropperIcon
            icon="Eyedropper"
            onClick={handleOpenEyedropper}
          />
        )}
        <styled.PreviewCircle color={hex} />
        <styled.HueWrapper>
          <Hue {...props} onChange={handleColorChange} />
        </styled.HueWrapper>
      </styled.PreviewAndHueWrapper>

      <styled.InputWrapper>
        <styled.InputFieldContainer>
          {renderInputField()}
        </styled.InputFieldContainer>
        <styled.ToggleIcon icon="Palette" onClick={handleToggleInputType} />
      </styled.InputWrapper>
    </styled.PickerWrapper>
  );
};

export const CustomColorPicker = CustomPicker(ColorPicker);
