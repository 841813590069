import { useContext } from 'react';

import SettingsContext from 'containers/Settings/Company/SettingsContext';

import { DEFAULT_DATE_FORMAT } from 'components/utils/date';

function useDateFormat() {
  const { companySettings } = useContext(SettingsContext);

  return companySettings?.date_format || DEFAULT_DATE_FORMAT;
}

export default useDateFormat;
