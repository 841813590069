/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/elements/icon';
import { Button } from 'components/elements/button';
import { ButtonSet } from 'components/misc/ButtonSet';
import { IntercomAPI } from 'components/scripts/intercom';
import type { IconName } from 'components/elements/types';

import i18n from './utils/i18n';

const handleReloadApp = () => (window.location.reload as any)(true);
const handleDashboard = () => {
  window.location.href = '/';
};
const handleContactSupport = () => IntercomAPI('show');
const handleLogout = () => {
  window.location.href = '/logout';
};
const handleCustomAction = (path: string) => {
  window.location.href = path;
};

type FallbackActionsProps = {
  showReload?: boolean;
  showDashboard?: boolean;
  showSupport?: boolean;
  showLogout?: boolean;
  custom?: {
    action: string;
    icon: IconName;
    text: I18nMessage;
  };
};

export const FallbackActions = (props: FallbackActionsProps) => {
  const {
    showReload = true,
    showDashboard = true,
    showSupport = true,
    showLogout = true,
    custom = null,
  } = props;

  return (
    <ButtonSet isVertical isPadded align="stretch">
      {custom && (
        <Button
          onClick={() => handleCustomAction(custom.action)}
          color="primary"
        >
          <Icon icon={custom.icon} />
          <FormattedMessage {...custom.text} />
        </Button>
      )}

      {showReload && (
        <Button onClick={handleReloadApp} color="primary">
          <Icon icon="ArrowsCounterClockwise" />
          <FormattedMessage {...i18n.buttonReloadApp} />
        </Button>
      )}

      {showDashboard && (
        <Button onClick={handleDashboard} color="primary">
          <Icon icon="ArrowLeft" />
          <FormattedMessage {...i18n.buttonDashboard} />
        </Button>
      )}

      {showSupport && (
        <Button onClick={handleContactSupport} color="primary" type="outline">
          <Icon icon="AnchorSimple" />
          <FormattedMessage {...i18n.buttonSupport} />
        </Button>
      )}

      {showLogout && (
        <Button onClick={handleLogout} color="danger" type="inverted">
          <Icon icon="SignOut" />
          <FormattedMessage {...i18n.buttonLogout} />
        </Button>
      )}
    </ButtonSet>
  );
};
