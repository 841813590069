import { noop } from 'lodash';

/**
 * @function inputProperties
 * @param {object} props
 * @return {object} the input to be build with its properties
 */
export const inputProperties = function inputProperties(
  {
    context,
    children,
    date = null,
    selected = null,
    checked = false,
    disabled = false,
    loading = false,
    fn = null,
    fnMin = null,
    fnMax = null,
    fnAutocomplete = null,
    fnFormat = undefined,
    fnFormatQuickTag = undefined,
    shouldValidate = false,
    isValid = true,
    customValidation,
    inputIndicator = null,
    inputIconIndicator = null,
    icon = null,
    disabledIcon = null,
    inputSuggestion,
    onKeyUp = null,
    onKeyPress = null,
    checkboxOnLeft = false,
    disabledOptions = [],
    optionsHeight = undefined,
    optionsPosition = undefined,
    optionsTypeahead = undefined,
    optionsFn = null,
    onFocus = noop,
    onBlur = noop,
    size = 'default',
    color = null,
    dropdownFooter,
    dropdownFooterHeight,
    clearOnChange = undefined,
    autoFocus: propAutoFocus,
    validationErrorIndicator = true,
    clearable,
    showRightSideLabel = false,
    rightLabelText = '',
    onClear,
    onInnerRef,
    withSelector,
    quickListSimple,
    customDescription,
    tagsEmptyState,
    getTextRef,
    dataManual,
    limit,
    editable,
    extraFloatingContentHeight,
    dropdownSize = 24,
    tooltip,
    ...props
  },
  position = ''
) {
  const {
    id,
    label,
    inputType,
    options = null,
    bullhornOptions = [],
    selectionSource = null,
    inputDescription = null,
    required = false,
    min = 0,
    max = 0,
    name = null,
    minlength = undefined,
    maxlength = undefined,
    minDate = undefined,
    maxDate = undefined,
    readonly = false,
    yearDropdown = true,
    presentSelect = undefined,
    idSelector = null,
    nameSelector = null,
    iconContextSelector = null,
    fullWidth = undefined,
    showCharactersCounter = false,
    charactersCounterIntl = undefined,
    rows = null,
    quick = [],
    freetext = false,
    button = null,
    acceptedFormats = undefined,
    acceptedDimensions = undefined,
    maxFileSize = undefined,
    bullhornFilesProps = undefined,
    autoFocus: contextAutoFocus,
    height,
    minHeight,
    maxHeight,
    listWithoutPlaceholder,
    withFloatingTextTools,
    promptSuggestions,
    withButtonAskAI,
    withBarTextTools,
    variablesList,
    variablesI18n,
    resizable,
    noNewLineOnEnter,
  } = context;

  let inputChangeFunction = null;

  if (fn) {
    inputChangeFunction = fn;
  }
  if (fnMin) {
    inputChangeFunction = fnMin;
  }
  if (fnMax && position === 'Second') {
    inputChangeFunction = fnMax;
  }

  return {
    id,
    name,
    key: `${id}-${position}`,
    children,
    readonly,
    minlength,
    maxlength,
    label,
    inputDescription,
    inputIndicator,
    inputIconIndicator,
    icon,
    disabledIcon,
    button,
    placeholder: context[`placeholder${position}`] || {},
    minPlaceholder: context.minPlaceholder || {},
    maxPlaceholder: context.maxPlaceholder || {},
    inputValue: props[`inputValue${position}`] || '',
    inputSuggestion,
    date,
    inputType,
    options,
    bullhornOptions,
    optionsFn,
    selected,
    selectionSource,
    checked,
    disabled,
    loading,
    min,
    max,
    rows,
    required,
    shouldValidate,
    customValidation,
    isValid,
    minDate,
    maxDate,
    yearDropdown,
    presentSelect,
    onKeyUp,
    onFocus,
    onBlur,
    onKeyPress,
    idSelector,
    nameSelector,
    iconContextSelector,
    fullWidth,
    checkboxOnLeft,
    disabledOptions,
    optionsHeight,
    optionsPosition,
    optionsTypeahead,
    size,
    color,
    quick,
    freetext,
    fnAutocomplete,
    fnFormat,
    fnFormatQuickTag,
    bullhornFilesProps,
    clearOnChange,
    dropdownFooter,
    dropdownFooterHeight,
    showCharactersCounter,
    charactersCounterIntl,
    showRightSideLabel,
    rightLabelText,
    fn: inputChangeFunction,
    autoFocus: propAutoFocus || contextAutoFocus,
    validationErrorIndicator,
    clearable,
    onClear,
    onInnerRef,
    withSelector,
    acceptedFormats,
    acceptedDimensions,
    maxFileSize,
    quickListSimple,
    customDescription,
    tagsEmptyState,
    withFloatingTextTools,
    promptSuggestions,
    withButtonAskAI,
    withBarTextTools,
    height,
    minHeight,
    maxHeight,
    listWithoutPlaceholder,
    getTextRef,
    variablesList,
    variablesI18n,
    resizable,
    dataManual,
    limit,
    noNewLineOnEnter,
    editable,
    extraFloatingContentHeight,
    dropdownSize,
    tooltip,
  };
};
