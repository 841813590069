import { FormattedMessage } from 'react-intl';
import i18n from '../utils/i18n';
import * as styled from '../styles/info';

export const CompleteProfileBox = () => (
  <styled.CompleteProfileBox>
    <styled.CompleteProfileTitle>
      <FormattedMessage {...i18n.completeProfile} />
    </styled.CompleteProfileTitle>
    <ul>
      <li>
        <FormattedMessage {...i18n.completeProfileContent1} />
      </li>
      <li>
        <FormattedMessage {...i18n.completeProfileContent2} />
      </li>
      <li>
        <FormattedMessage {...i18n.completeProfileContent3} />
      </li>
    </ul>
  </styled.CompleteProfileBox>
);
