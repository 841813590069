/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'react';
import { injectIntl, WrappedComponentProps, IntlShape } from 'react-intl';

import canvasToBlob from 'blueimp-canvas-to-blob';

import 'cropperjs/dist/cropper.css';

import { Icon } from 'components/elements/icon';

import * as styled from './styles/index';
import i18n from './utils/i18n';

type ImageCropProps = {
  image: File | null;
  aspectRatio?: number;
  cropProps?: { width: number; height: number };
  onCrop: (file: File) => void;
  onCancel: VoidCallback;
  intl: IntlShape;
};

class ImageCropComponent extends Component<
  ImageCropProps & WrappedComponentProps
> {
  cropper: any;

  preview?: string;

  constructor(props: ImageCropProps & WrappedComponentProps) {
    super(props);

    this.cropper = null;
    this.croppedImage = this.croppedImage.bind(this);
    this.preview = URL.createObjectURL(props.image as File);
  }

  croppedImage() {
    const {
      image,
      onCrop,
      cropProps = { width: 512, height: 512 },
    } = this.props;
    if (typeof this.cropper!.cropper!.getCroppedCanvas() === 'undefined') {
      return;
    }
    const imgUrl = this.cropper!.cropper.getCroppedCanvas(
      cropProps
    ).toDataURL();
    const type = imgUrl.split(',')[0].replace(/(data:|;base64)/g, '');
    const ext = type.split('/')[1];
    const baseName = image!.name.replace(/\.[^/.]+$/, '');
    const filename = `${baseName}.${ext}`;
    const blob = canvasToBlob(imgUrl);

    interface imageFileProp extends File {
      preview: string;
      filename: string;
    }

    try {
      const imageFile = new File([blob], filename, { type }) as imageFileProp;
      imageFile.preview = imgUrl;
      imageFile.filename = filename;

      onCrop(imageFile);
    } catch (e) {
      // this scenario works for IE (no words can describe my hate to IE)
      blob.preview = imgUrl;
      blob.name = filename;
      blob.filename = filename;
      blob.modifiedDate = new Date();
      blob.lastModifiedDate = new Date();
      blob.type = type;

      onCrop(blob);
    }
  }

  render() {
    const { aspectRatio = 1, onCancel, intl } = this.props;
    return (
      <div>
        <styled.Cropper
          ref={(ref) => {
            this.cropper = ref;
          }}
          src={this.preview}
          aspectRatio={aspectRatio}
          viewMode={1}
          autoCropArea={1}
          guides={false}
        />
        <styled.Controls>
          <styled.Button color="mono" onClick={onCancel}>
            {intl.formatMessage(i18n.cancel)}
          </styled.Button>

          <styled.Button onClick={this.croppedImage}>
            <Icon icon="Crop" />
            {intl.formatMessage(i18n.crop)}
          </styled.Button>
        </styled.Controls>
      </div>
    );
  }
}

export const ImageCrop = injectIntl(ImageCropComponent);
