import styled, { css } from 'styled-components';

import {
  Button as ButtonBase,
  BUTTON_HEIGHTS,
} from 'components/elements/button';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';
import { Colors } from 'components/utils/styles/ui';
import {
  menuActiveItemIcon,
  menuActiveText,
} from 'components/utils/styles/mixins';
import { Activator } from 'components/overlay/Tooltip/styles/tooltip';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  ${({ theme }) => theme.typography.text.medium};
`;

export const Button = styled(ButtonBase)`
  box-shadow: none !important;

  &:hover,
  &:focus {
    background-color: ${Colors.grey};
    border-color: ${Colors.grey};
  }
`;

const leftMixin = css`
  right: 0;
  top: ${({ size }) => `calc(${BUTTON_HEIGHTS[size]} + 0.5rem)`};
`;

const rightMixin = css`
  left: 0;
  top: ${({ size }) => `calc(${BUTTON_HEIGHTS[size]} + 0.5rem)`};
`;

const topLeftMixin = css`
  right: 0;
  bottom: ${({ size }) => `calc(${BUTTON_HEIGHTS[size]} + 0.5rem)`};
`;

const topRightMixin = css`
  left: 0;
  bottom: ${({ size }) => `calc(${BUTTON_HEIGHTS[size]} + 0.5rem)`};
`;

const fixedMixin = css`
  top: unset;
  bottom: unset;
  left: unset;
  right: unset;
  z-index: 20000;
  margin-top: 0.4rem;
`;

const leftFixedMixin = css`
  ${fixedMixin}
  transform: translateX(-100%);
`;

const rightFixedMixin = css`
  ${fixedMixin}
`;

export const ItemsWrapper = styled.div`
  background-color: ${Colors.white};
  border: solid 1px ${({ borderColor }) => borderColor};
  border-radius: 0.6rem;
  position: absolute;
  z-index: 2;

  ${({ position, fixed }) => {
    if (!fixed) {
      switch (position) {
        case 'left':
          return leftMixin;
        case 'right':
          return rightMixin;
        case 'top-left':
          return topLeftMixin;
        case 'top-right':
          return topRightMixin;
        default:
          return null;
      }
    } else {
      switch (position) {
        case 'left':
          return leftFixedMixin;
        case 'right':
          return rightFixedMixin;
        default:
          return null;
      }
    }
  }}
`;

export const ItemsList = styled.ul`
  margin: 0;
  padding: 0;

  & ${Activator} {
    margin: 0;
    padding: 0;
  }
`;

export const Text = styled.div`
  font-weight: ${({ hasDescription }) => (hasDescription ? 500 : 400)};
  font-size: ${({ size }) => (size === 'xsmall' ? 1.3 : 1.4)}rem;
`;

export const Description = styled.div`
  color: ${Colors.text};
  font-weight: 500;
  margin-left: 2.6rem;
  ${({ theme }) => theme.typography.text.small};
`;

export const LinkText = styled.div`
  color: ${Colors.tealDark};
  font-weight: 500;
  margin-left: 2.6rem;
  text-decoration: underline;
  ${({ theme }) => theme.typography.text.small};
`;

const highlightItemMixin = css`
  background-color: ${Colors.tealLighter};
  border-top: 0.4rem solid ${Colors.tealDark};
`;

const descriptionItemMixin = css`
  padding: 0.6rem 4rem 0.6rem 1.6rem;
`;

const xsmallItemMixin = css`
  padding: 0.4rem 1.2rem;
`;

export const ItemContent = styled.div`
  align-items: center;
  cursor: pointer;
  color: ${({ disabled }) => (disabled ? Colors.textLighter : Colors.text)};
  padding-right: 1.4rem;
  white-space: pre;

  ${({ size }) => size === 'xsmall' && xsmallItemMixin}
  ${({ highlight }) => highlight && highlightItemMixin}
  ${({ hasDescription }) => hasDescription && descriptionItemMixin}

  & ${Text} {
    white-space: nowrap;
    ${menuActiveText}
  }

  ${menuActiveItemIcon}

  svg {
    flex-shrink: 0;
  }
`;

const disabledItemMixin = `
  pointer-events: none;
  color: ${Colors.textLighter};
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 1.6rem 0.4rem 1.6rem;
  white-space: pre;

  & > svg {
    flex-shrink: 0;
  }

  &:hover {
    background-color: ${Colors.tealLightest};
  }

  &:first-child {
    padding-top: 0.7rem;
  }

  &:last-child {
    padding-bottom: 0.7rem;
  }

  ${({ disabled }) => disabled && disabledItemMixin}
`;

export const ItemTopLine = styled.div`
  display: flex;
  align-items: center;
  white-space: normal;

  & > svg {
    margin-right: 1rem;
  }

  & > span {
    margin-left: auto;

    path {
      fill: ${Colors.white} !important;
    }
  }
`;

export const Link = styled(SmartLinkBase)`
  color: ${Colors.text};
`;
