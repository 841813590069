import styled from 'styled-components';

import { Avatar as AvatarBase } from 'components/visual/avatar/avatar';
import { Avatar } from 'components/visual/avatar/styles/avatar';
import { Colors } from 'components/utils/styles/ui';
import { BOX_SIZES } from 'components/visual/avatar/constants/avatar';
import { ImageUpload as ImageUploadBase } from 'components/visual/imageUpload';

export const Wrapper = styled.div`
  ${Avatar} {
    border: 0.1rem solid ${Colors.outline};
  }
`;

export const Image = styled(AvatarBase)``;

export const ImageUpload = styled(ImageUploadBase)`
  height: ${({ size = 'x3large' }) => BOX_SIZES[size]}rem;
  width: ${({ size = 'x3large' }) => BOX_SIZES[size]}rem;
`;
