import styled from 'styled-components';

import { CloseButton } from 'components/visual/buttons/close';
import { Colors, mediaSizes } from 'components/utils/styles/ui';

import { SupplierCard as SupplierCardBase } from '../supplierCard';
import { mediaMaxWidth } from '../utils/media';

const media = mediaMaxWidth(mediaSizes.medium);

const determineHeight = (scrolled, context) => {
  if (context === 'external') return 6.2;
  if (scrolled > 0 && scrolled < 95) return 20.2 - Math.round(scrolled / 10);
  if (scrolled > 95) return 11.2;
  return 20.2;
};
const determineTop = (scrolled, context) => {
  if (context === 'external') return 6.2;
  if (scrolled > 0 && scrolled < 95) return 20 - Math.round(scrolled / 10);
  if (scrolled > 95) return 11;
  return 20;
};
const determineMobileHeight = (scrolled, context) => {
  if (context === 'external') return 20;
  if (scrolled > 0) return 25;
  return 34;
};
const determineMobileTop = (scrolled, context) => {
  if (context === 'external') return 20;
  if (scrolled > 0) return 25;
  return 34;
};
export const MapContainer = styled.div`
  position: ${({ showResults }) => (showResults ? 'sticky' : 'fixed')};
  width: ${({ showResults }) => (showResults ? 'initial' : '100%')};
  top: ${({ scrolled, context }) => determineTop(scrolled, context)}rem;
  flex: 3 0 0;
  transition: flex-basis 0.3s, height 0.3s;
  height: calc(
    100vh - ${({ context, scrolled }) => determineHeight(scrolled, context)}rem
  );

  ${media`
    height: calc(100vh - ${({ scrolled, context }) =>
      determineMobileHeight(scrolled, context)}rem);
    top: ${({ scrolled, context }) => determineMobileTop(scrolled, context)}rem;
  `}
`;

export const SupplierCard = styled(SupplierCardBase)`
  width: 52rem;
  position: absolute;
  top: -15rem;
  left: -24rem;
  border-radius: 0.4rem;
  z-index: 3;

  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    top: 100%;
    border-top: solid 2rem ${Colors.white};
    border-left: solid 2rem transparent;
    border-right: solid 2rem transparent;
    margin: 0 auto;
  }
`;

export const CloseMapPoint = styled(CloseButton)`
  position: absolute;
  top: -1.8rem;
  right: -1.8rem;
  z-index: 2;
  transform: scale(0.9);

  &:hover,
  & {
    background-color: ${Colors.tealDark};
    border-color: ${Colors.tealDark};
  }
`;

export const PointWrapper = styled.div`
  position: relative;
`;

export const MapPoint = styled.div`
  position: absolute;
  top: -2rem;
  left: -2rem;
  cursor: pointer;
`;

export const ClusterCounter = styled.div`
  position: absolute;
  z-index: 1;
  width: 4rem;
  height: 4.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
  font-weight: 500;
  color: ${Colors.white};
`;
