import Bowser from 'bowser';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const INTEGRATION_FRAME_HEIGHT = 10000;

const browser = Bowser.getParser(window.navigator.userAgent);
export const IS_MOBILE = browser.isPlatform('mobile');

export const TERMS_LINK = 'https://www.gustavtech.com/terms-of-use';
export const POLICY_LINK = 'https://www.gustavtech.com/privacy-policy';
