import * as styled from './styles/buttonSet';

export type Align =
  | 'flex-start'
  | 'center'
  | 'stretch'
  | 'flex-end'
  | 'space-between';

type ButtonSetProps = {
  align: Align;
  isVertical?: boolean;
  isPadded?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

export const ButtonSet = function ButtonSet({
  align,
  isVertical,
  isPadded,
  children,
}: ButtonSetProps) {
  return (
    <styled.ButtonSet align={align} isVertical={isVertical} isPadded={isPadded}>
      {children}
    </styled.ButtonSet>
  );
};
