import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Flexbox } from 'components/structure/page/styles/containers';
import * as styledModal from 'components/overlay/Modal/styles/modal';
import { Scrollbars as ScrollbarsBase } from 'components/misc/Scrollbars';

export const ModalView = styled(Flexbox)`
  background-color: ${Colors.white};
  border-radius: 0.6rem;
  width: 40rem;
  padding: 2rem 3rem;
`;

export const { Title } = styledModal;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Scrollbars = styled(ScrollbarsBase)`
  height: 24rem;
  padding: 1rem 2rem 1rem 1rem;
  border: 0.1rem solid ${Colors.greyLight};
  border-radius: 0.6rem;
`;
