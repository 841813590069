import styled from 'styled-components';

import { Flexbox } from '../../../structure/page/styles/containers';

import { InputText as TextBase } from '../../input/text';
import { Select as SelectBase } from '../../select/select';

export const Container = styled.div`
  align-items: center;
`;

export const Input = styled(Flexbox)`
  align-items: center;
`;

export const Text = styled(TextBase)`
  border-radius: 0;
  border-right-width: 0.1rem;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
`;

export const Select = styled(SelectBase)`
  border-radius: 0;
  border-left-width: 0;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
`;
