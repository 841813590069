import { push } from 'redux-first-history';
import { httpGet } from 'utils';
import {
  SUPPLIER_HUB_FETCH_INDUSTRIES_REQUEST,
  SUPPLIER_HUB_FETCH_INDUSTRIES_SUCCESS,
  SUPPLIER_HUB_FETCH_INDUSTRIES_ERROR,
  SUPPLIER_HUB_FETCH_SPECIALIZATIONS_REQUEST,
  SUPPLIER_HUB_FETCH_SPECIALIZATIONS_SUCCESS,
  SUPPLIER_HUB_FETCH_SPECIALIZATIONS_ERROR,
  SUPPLIER_HUB_FETCH_SUPPLIERS_REQUEST,
  SUPPLIER_HUB_RESET_SUPPLIERS,
  SUPPLIER_HUB_CHANGE_RESULTS_VISIBILITY,
  SUPPLIER_HUB_CHOOSE_SUPPLIER,
  SUPPLIER_HUB_RESET_SEARCH_CONDITIONS,
  SUPPLIER_HUB_CHANGE_SEARCH_CONDITIONS,
  SUPPLIER_HUB_CHANGE_MAP_PARAMS,
  SUPPLIER_HUB_CHANGE_RESULTS_LOADED,
  SUPPLIER_HUB_CHANGE_LOCATION,
} from './constants';

export function fetchIndustries() {
  return (dispatch) => {
    dispatch({
      type: SUPPLIER_HUB_FETCH_INDUSTRIES_REQUEST,
    });

    httpGet('/resources/industries')
      .then((industries) =>
        dispatch({
          type: SUPPLIER_HUB_FETCH_INDUSTRIES_SUCCESS,
          payload: {
            industries,
          },
        })
      )
      .catch((error) =>
        dispatch({
          type: SUPPLIER_HUB_FETCH_INDUSTRIES_ERROR,
          error,
        })
      );
  };
}

export function fetchSpecializations() {
  return (dispatch) => {
    dispatch({
      type: SUPPLIER_HUB_FETCH_SPECIALIZATIONS_REQUEST,
    });

    httpGet('/resources/specializations')
      .then((specializations) =>
        dispatch({
          type: SUPPLIER_HUB_FETCH_SPECIALIZATIONS_SUCCESS,
          payload: {
            specializations,
          },
        })
      )
      .catch((error) =>
        dispatch({
          type: SUPPLIER_HUB_FETCH_SPECIALIZATIONS_ERROR,
          error,
        })
      );
  };
}

export function fetchSuppliers({ filters, isPublic }) {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_FETCH_SUPPLIERS_REQUEST,
      payload: {
        filters,
        isPublic,
      },
    });
}

export function resetSuppliers() {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_RESET_SUPPLIERS,
    });
}

export function changeVisibility() {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_CHANGE_RESULTS_VISIBILITY,
    });
}

export function openProfile(url) {
  return (dispatch) => {
    dispatch(changeResultsLoaded(true));
    dispatch(push(url));
  };
}

export function chooseSupplier(id) {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_CHOOSE_SUPPLIER,
      payload: {
        id,
      },
    });
}

export function resetSearchConditions() {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_RESET_SEARCH_CONDITIONS,
    });
}

export function changeSearchCondition(name, value) {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_CHANGE_SEARCH_CONDITIONS,
      payload: {
        name,
        value,
      },
    });
}

export function changeLocation({
  city: { long_name: cityLongName = '' } = {},
  state: { short_name: stateShortName = '' },
} = {}) {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_CHANGE_LOCATION,
      payload: {
        city: cityLongName,
        state: stateShortName,
      },
    });
}

export function changeMapParams(params) {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_CHANGE_MAP_PARAMS,
      payload: params,
    });
}

export function changeResultsLoaded(resultsLoaded) {
  return (dispatch) =>
    dispatch({
      type: SUPPLIER_HUB_CHANGE_RESULTS_LOADED,
      payload: {
        resultsLoaded,
      },
    });
}
