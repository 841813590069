import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.AdminHeader.title',
    defaultMessage: 'Logged in as {firstName} {lastName} ({companyName})',
  },
  buttonBackToAdmin: {
    id: 'app.containers.AdminHeader.buttonBackToAdmin',
    defaultMessage: '< back to admin',
  },

  confirmTitle: {
    id: 'app.containers.AdminHeader.confirmTitle',
    defaultMessage: 'Back to admin',
  },
  confirmMessage: {
    id: 'app.containers.AdminHeader.confirmMessage',
    defaultMessage: 'Please enter your admin password',
  },
  confirmButtonConfirm: {
    id: 'app.containers.AdminHeader.confirmButtonConfirm',
    defaultMessage: 'Switch to admin',
  },
  confirmButtonCancel: {
    id: 'app.containers.AdminHeader.confirmButtonCancel',
    defaultMessage: 'Cancel',
  },
});
