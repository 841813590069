import styled, { css } from 'styled-components';

import { Icon as IconBase } from 'components/elements/icon';
import { InputText as InputBase } from 'components/form/input/text';
import { Button as ButtonBase } from 'components/elements/button';
import { iconElementsSelector } from 'components/elements/styles/icon';

import { Colors } from 'components/utils/styles/ui';
import { Fieldset as FieldsetBase } from 'components/structure/form/styles/form';
import { rotate360 } from 'components/utils/styles/animations';

const errorMixin = css`
  background-color: ${Colors.redLight};

  & > * {
    border-color: ${Colors.red};
  }
`;

const fileMixin = css`
  background-color: ${Colors.tealLighter};
`;

export const DropzoneBox = styled.div`
  display: flex;
  flex-direction: ${({ compact, size }) =>
    compact && size !== 'small' ? 'row' : 'column'};
  justify-content: ${({ compact, dataform }) =>
    compact && dataform ? 'space-between' : 'center'};
  align-items: center;
  cursor: pointer;
  border-radius: 0.6rem;
  height: ${({ compact }) => (compact ? 8 : 13)}rem;
  padding: ${({ size }) => (size === 'small' ? '0 3rem' : '0 5rem')};

  border: 2px dashed ${Colors.grey};
  &:focus,
  &:active {
    border: 2px dashed ${Colors.grey};
  }

  &[aria-disabled='true'] {
    cursor: default;
  }

  &:focus,
  &:hover {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  border-radius: 0.6rem;
  padding: 0.5rem;
  background-color: ${Colors.white};

  & > ${DropzoneBox} {
    border: 2px dashed ${Colors.grey};
    position: relative;
  }

  ${({ hasError }) => (hasError ? errorMixin : null)};
  ${({ hasFile }) => (hasFile ? fileMixin : null)};
`;

const iconCompact = css`
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 0 0.6rem 0;
`;

const iconNormal = css`
  width: 3rem;
  height: 3rem;
  margin: 0 0 1.2rem 0;
`;

export const Icon = styled(IconBase)`
  ${({ compact }) => (compact ? iconCompact : iconNormal)}
`;

export const Placeholder = styled.span``;

export const Image = styled.img`
  width: 8rem;
`;

export const MessagesWrapper = styled.div`
  text-align: center;
  margin: 0 ${({ dataform }) => (dataform ? '0' : 'auto')};
  max-width: 100%;
`;

export const Text = styled.p`
  font-weight: 500;
  margin: 0;
`;

export const Filename = styled.p`
  margin: 0.6rem 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const smallInputMixin = css`
  width: 100%;
  margin: 0;
  padding: 0.3rem;
`;

export const InputContainer = styled.div`
  width: auto;
  min-width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  padding: 0.5rem 0.8rem;
  background-color: ${Colors.white};
  border-radius: 0.6rem;
  border: 2px solid ${({ invalid }) => (invalid ? Colors.red : Colors.grey)};
  ${({ size }) => (size === 'small' ? smallInputMixin : null)}
`;

export const Input = styled(InputBase)`
  border: none !important;
  padding: 0 1rem;
  height: ${({ size }) => (size === 'small' ? 2 : 3)}rem;
`;

export const Button = styled(ButtonBase)`
  ${({ compact }) => (compact ? null : 'height: 2.8rem;')}
`;

export const CloseButton = styled(ButtonBase)`
  position: absolute;
  top: 1rem;
  right: 1rem;

  & > ${iconElementsSelector} {
    stroke: ${Colors.text} !important;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Fieldset = styled(FieldsetBase)`
  margin-bottom: 0;
  width: 24rem;

  &:not(:first-child) {
    margin-left: 4rem;
  }
`;

export const Loading = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  margin-top: 1.5rem;

  border: 2px solid ${Colors.textLighter};
  border-radius: 1.1rem;
  border-right-color: transparent;
  border-top-color: transparent;

  content: '';

  animation: ${rotate360} 500ms infinite linear;
`;

export const LoadingText = styled.div`
  padding-top: 2rem;
  font-weight: 500;
  color: ${Colors.tealDark};

  ${({ theme }) => theme.typography.text.small};
`;
