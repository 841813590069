import { filter, isEmpty, isObject } from 'lodash';

import { currencies } from '../../ui-definitions/currency';

import { profileInitialsColors } from './styles/ui';

/* eslint no-bitwise: 0 */
const hashCode = function hashCode(s: string): number {
  let h: number = 0;
  for (let i = 0; i < s.length; i += 1) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }
  return Math.abs(h);
};

/**
 * @function colorForText
 * @param {string} text
 * @return {string} color to use
 * @description this function matches an specified color from the color palette to the first
 * letter of the text.
 * It's a simple and quick solution to the InitialProfile ui-component
 */
export const colorForText = function colorForText(text: string): string {
  const normalized: string = text ? text.normalize('NFD') : '';
  const position: number = hashCode(normalized) % profileInitialsColors.length;

  return profileInitialsColors[position];
};

/**
 * @typedef {Object} money
 * @property {number} amount: The amount value
 * @property {string} currency: The 3-letter currency code
 */

/**
 * @function currencySymbolFor
 * @param {string|money} currency
 * @return {string} currencySymbol
 */
type CurrencyInput = string | { currency: string };

export const currencySymbolFor = function currencySymbolFor(
  input: CurrencyInput = 'USD'
): string {
  const currency = (isObject(input) && input.currency
    ? input.currency
    : input) as string;
  const symbol = filter(currencies, { code: currency.toUpperCase() });

  if (isEmpty(symbol)) {
    return '$';
  }

  return symbol[0].symbol;
};

/**
 * @function calculateFeeAmount
 * @param {string} fieldKey: recruiterFee || salaryRange
 * @param {number|object} value
 * @param {object} offerProps
 * @return {array} array of [min, max] values
 */

type SalaryRange = { min: { amount: number }; max: { amount: number } };

export const calculateFeeAmount = function calculateFeeAmount(
  fieldKey: 'recruiterFee' | 'salaryRange',
  value: number | SalaryRange,
  offerProps: { recruiterFee: number; salaryRange: SalaryRange }
): number[] {
  const {
    recruiterFee,
    salaryRange: { min, max },
  } = offerProps;

  const feeAmount: number[] = [];

  if (fieldKey === 'recruiterFee') {
    feeAmount.push(
      ...[
        min.amount * ((value as number) / 100),
        max.amount * ((value as number) / 100),
      ]
    );
  }

  if (fieldKey === 'salaryRange') {
    const { min: salaryMin, max: salaryMax } = value as SalaryRange;
    feeAmount.push(
      ...[
        salaryMin.amount * (recruiterFee / 100),
        salaryMax.amount * (recruiterFee / 100),
      ]
    );
  }

  return feeAmount;
};
