import styled, { css } from 'styled-components';
import { Colors } from 'components/utils/styles/ui';
import { ButtonGroup as ButtonGroupBase } from 'components/elements/styles/button';

import theme from 'themes';

export const Confirm = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 2rem 3rem;
  width: ${({ width }) => width || '55rem'};
  max-height: calc(100vh - 4rem);
  overflow: auto;
  background-color: ${Colors.white};
  border-radius: .6rem;
  ${theme.typography.text.medium};

  & textarea {
    width: 100%;
    border-radius: .3rem;
    border-color: ${Colors.inputOutline};
      &:focus {
        outline: none;
        border: solid 1px ${theme.colors.primary};
      }
    }
  }
`;

export const Title = styled.h1`
  margin: 0 0 2.4rem;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 2.6rem;
  color: ${({ color }) => theme.colors[color]};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const Message = styled.div`
  margin: 0 0 2.4rem;
  font-size: 1.4rem;
  color: ${Colors.text};
  white-space: pre-wrap;
`;

const flexMixin = css`
  display: flex;
  justify-content: space-between;

  & > :only-child {
    margin-left: auto;
  }
`;

export const ButtonGroup = styled(ButtonGroupBase)`
  padding-top: 2rem;
  border-top: 1px solid ${Colors.outline};

  ${({ buttonFullWidth }) => !buttonFullWidth && flexMixin};
`;
