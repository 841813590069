import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { get, compact } from 'lodash';

import { Tabs } from 'components/structure/Tabs';

import * as styled from './styles';
import i18n from './utils/i18n';
import {
  isAffiliated as isAffiliatedFn,
  sameCommunity as sameCommunityFn,
} from './utils/helpers';

import { Info } from './ui/info';
import { Sidebar } from './ui/sidebar';
import { Ratings } from './ui/ratings';

import { AboutTab } from './tabs/about';
import { UsersTab } from './tabs/users';

class CompanyProfileSceneComponent extends Component {
  constructor(props) {
    super(props);

    this.rootPath = props.match.url.replace(/(\/users|\/about)$/, '');
  }

  canSeeUsers = () => {
    const { company, currentCompany } = this.props;

    const isAffiliated = isAffiliatedFn(company);
    const isCurrent = get(company, 'id') === get(currentCompany, 'id');
    const inTheSameCommunity = sameCommunityFn(
      get(company, 'communities'),
      get(currentCompany, 'communities')
    );

    return isAffiliated || isCurrent || inTheSameCommunity;
  };

  renderTab() {
    const {
      edit,
      editDisabled,
      currentUser,
      currentCompany,
      company,
      saveForm,
      onFetchUsers,
      departments,
      industries,
      specializations,
      isPublic,
      match: {
        params: { tab },
      },
      openModal,
      blockedCompany,
    } = this.props;
    const premiumFeatures = get(
      currentCompany,
      'subscription_plan.features',
      {}
    );

    switch (tab) {
      case 'users':
        return this.canSeeUsers() ? (
          <UsersTab onFetchUsers={onFetchUsers} />
        ) : null;

      case 'about':
      default:
        return (
          <AboutTab
            edit={edit}
            editDisabled={editDisabled}
            currentUser={currentUser}
            premiumFeatures={premiumFeatures}
            company={company}
            saveForm={saveForm}
            departments={departments}
            industries={industries}
            specializations={specializations}
            isPublic={isPublic}
            openModal={openModal}
            blockedCompany={blockedCompany}
          />
        );
    }
  }

  render() {
    const {
      edit,
      currentCompany,
      currentUser,
      company,
      saveForm,
      uploadLogo,
      rateCompany,
      openModal,
      onRevokeAffiliation,
      onRespondAffiliation,
      onFetchPlaces,
      members,
      isPublic,
      blockedCompany,
      match: {
        params: { tab },
      },
    } = this.props;
    const canSeeUsers = this.canSeeUsers();
    const tabs = compact([
      { label: i18n.tabAbout, link: `${this.rootPath}/about`, slug: 'about' },
      canSeeUsers && {
        label: i18n.tabUsers,
        link: `${this.rootPath}/users`,
        slug: 'users',
      },
    ]);

    return (
      <styled.Wrapper>
        <styled.Container isPublic={isPublic}>
          <styled.Layout hasMargin>
            <styled.Section>
              <Info
                uploadLogo={uploadLogo}
                edit={edit}
                company={company}
                currentCompany={currentCompany}
                saveForm={saveForm}
                openModal={openModal}
                onRevokeAffiliation={onRevokeAffiliation}
                onRespondAffiliation={onRespondAffiliation}
                isPublic={isPublic}
                blockedCompany={blockedCompany}
              />
            </styled.Section>
          </styled.Layout>

          <styled.Layout hasMargin hasBorder>
            <styled.Section>
              {!isPublic && <Tabs tabs={tabs} active={tab || 'about'} />}
            </styled.Section>
          </styled.Layout>

          <styled.Layout>
            <styled.Section ratio="1/5" minWidth="25rem">
              <Ratings
                company={company}
                currentUser={currentUser}
                rateCompany={rateCompany}
              />
              <Sidebar
                edit={edit}
                company={company}
                members={members}
                saveForm={saveForm}
                isPublic={isPublic}
                canSeeUsers={canSeeUsers}
                rootPath={this.rootPath}
                onFetchPlaces={onFetchPlaces}
              />
            </styled.Section>
            {this.renderTab()}
          </styled.Layout>
        </styled.Container>
      </styled.Wrapper>
    );
  }
}

CompanyProfileSceneComponent.propTypes = {
  match: PropTypes.object,
  edit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  currentCompany: PropTypes.object,
  currentUser: PropTypes.object,
  company: PropTypes.object.isRequired,
  saveForm: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  rateCompany: PropTypes.func.isRequired,
  isPublic: PropTypes.bool,
  openModal: PropTypes.func,
  onRevokeAffiliation: PropTypes.func,
  onRespondAffiliation: PropTypes.func,
  onFetchUsers: PropTypes.func,
  onFetchPlaces: PropTypes.func,
  members: PropTypes.array,
  departments: PropTypes.array,
  industries: PropTypes.array,
  specializations: PropTypes.array,
  blockedCompany: PropTypes.object,
};

export const CompanyProfileScene = withRouter(CompanyProfileSceneComponent);
