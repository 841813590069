import PropTypes from 'prop-types';

import { TextFilter, BaseTextFilter } from './TextFilter';
import { SelectFilter } from './SelectFilter';
import { CheckboxFilter } from './CheckboxFilter';
import { ToolbarFilter } from './ToolbarFilter';

const FilterProps = {
  filter: PropTypes.shape({
    value: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export {
  TextFilter,
  BaseTextFilter,
  SelectFilter,
  CheckboxFilter,
  ToolbarFilter,
  FilterProps,
};
