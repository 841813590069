import { last } from 'lodash';

import { toPathname } from 'components/utils/url';

export const IMAGES_SUPPORTED = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];
export const DOCUMENTS_SUPPORTED = ['doc', 'docx', 'txt', 'rtf'];
export const SUPPORTED = ['pdf', ...DOCUMENTS_SUPPORTED, ...IMAGES_SUPPORTED];

export const extension = (url) =>
  last(toPathname(url).split('.')).toLowerCase();

export const canConvert = (url) => DOCUMENTS_SUPPORTED.includes(extension(url));

export const canPreview = (url) => SUPPORTED.includes(extension(url));

export const handlePZBounds = (pz) => ({
  x,
  y,
  scale,
  width,
  height,
  unscaledWidth,
}) => {
  const maxX = unscaledWidth <= width ? unscaledWidth - width : 0;
  const maxY = window.innerHeight <= height ? window.innerHeight - height : 0;
  let dX = 0;
  let dY = 0;

  if (x < maxX) {
    dX = x - maxX;
  }

  if (scale === 1 || x > 0) {
    dX = x;
  }

  if (y < maxY) {
    dY = y - maxY;
  }

  if (y > 0) {
    dY = y;
  }

  if (dX || dY) {
    pz.panBy(-dX, -dY);
  }
};
