import { httpGet, httpPatch } from 'utils';

import { openFlashMessage } from 'components/utils/flashMessages';

import {
  COMPANY_DOMAINS_FETCH_REQUEST,
  COMPANY_DOMAINS_FETCH_SUCCESS,
  COMPANY_DOMAINS_FETCH_ERROR,
  COMPANY_DOMAINS_UPDATE_REQUEST,
  COMPANY_DOMAINS_UPDATE_SUCCESS,
  COMPANY_DOMAINS_UPDATE_ERROR,
} from './constants';

import { FlashDefinition } from './utils/flashDefinition';

export function fetchCompanyDomains(companyId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_DOMAINS_FETCH_REQUEST });

      httpGet(`/company/${companyId}/domains`)
        .then((response) => {
          dispatch({
            type: COMPANY_DOMAINS_FETCH_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_DOMAINS_FETCH_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function updateDomain(companyId, id, params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_DOMAINS_UPDATE_REQUEST });

      httpPatch(`/company/${companyId}/domains/${id}`, {
        domain: params,
      })
        .then((response) => {
          dispatch({
            type: COMPANY_DOMAINS_UPDATE_SUCCESS,
            payload: response,
          });

          openFlashMessage(FlashDefinition.updateSuccess);

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_DOMAINS_UPDATE_ERROR,
            error,
          });

          reject(error);
        });
    });
}
