import i18n from './i18n';

export const FlashDefinition = {
  updateSuccess: {
    context: [{ detail: i18n.updateSuccess }],
    status: 'success',
  },
  uploadLogoSuccess: {
    context: [{ detail: i18n.uploadLogoSuccess }],
    status: 'success',
  },
  uploadLogoError: {
    context: [{ detail: i18n.uploadLogoError }],
    status: 'error',
  },
  rateSuccess: {
    context: [{ detail: i18n.rateSuccess }],
    status: 'success',
  },
};
