import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

import emptyImageSrc from 'images/illustrations/eye.png';
import { Loading } from 'components/feedback/loading/loading';
import { Overlay } from 'components/structure/overlay/overlay';
import {
  fetchBullhornCandidateDocuments,
  fetchBullhornCandidateFile,
} from 'containers/Integrations/Provider/actions';

import { BullhornDocument } from './bullhornDocument';

import * as styled from '../styles/bullhornModal';
import i18n from '../utils/i18n';

const BullhornModalComponent = (props) => {
  const {
    handleFetchBullhornCandidateDocuments,
    handleFetchBullhornCandidateFile,
    bullhornFilesProps: {
      bullhornData: {
        id: candidateBullhornId,
        first_name: firstName,
        last_name: lastName,
      },
      fieldName,
    },
    acceptedFormats,
    onClose,
    onDrop,
  } = props;

  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    setLoading(true);
    handleFetchBullhornCandidateDocuments(
      candidateBullhornId,
      acceptedFormats
    ).then((resp) => {
      setDocuments(resp.documents);
      setLoading(false);
    });
  }, [candidateBullhornId]);

  const candidateName = `${firstName} ${lastName}`;

  return (
    <Overlay closeOverlay={onClose} noCloseOnBackgroundClick>
      <styled.ModalView>
        <styled.ModalHeader>
          <FormattedMessage {...i18n.bullhornModalTitle} />
        </styled.ModalHeader>
        <styled.ModalSubheader>
          <FormattedMessage
            {...i18n.bullhornModalDescription}
            values={{ candidateName, fieldName }}
          />

          <styled.DocumentsCounter>
            <FormattedMessage
              {...i18n.documentsCount}
              values={{ documents: documents.length }}
            />
          </styled.DocumentsCounter>
        </styled.ModalSubheader>
        {loading && <Loading />}
        {!loading && isEmpty(documents) && (
          <styled.DocumentsEmptyState>
            <img src={emptyImageSrc} role="presentation" alt="presentation" />
            <FormattedMessage {...i18n.noFilesAvailable} />
          </styled.DocumentsEmptyState>
        )}

        {!loading && !isEmpty(documents) && (
          <styled.DocumentsTable>
            {documents.map((documentDetails) => (
              <BullhornDocument
                key={documentDetails.id}
                candidateBullhornId={candidateBullhornId}
                documentDetails={documentDetails}
                onFetchFile={handleFetchBullhornCandidateFile}
                onClose={onClose}
                onDrop={onDrop}
              />
            ))}
          </styled.DocumentsTable>
        )}
      </styled.ModalView>
    </Overlay>
  );
};

BullhornModalComponent.propTypes = {
  handleFetchBullhornCandidateDocuments: PropTypes.func,
  handleFetchBullhornCandidateFile: PropTypes.func,
  bullhornFilesProps: PropTypes.shape({
    bullhornData: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    fieldName: PropTypes.string,
  }),
  acceptedFormats: PropTypes.array,
  onClose: PropTypes.func,
  onDrop: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    handleFetchBullhornCandidateDocuments: (candidateId, acceptedFormats) =>
      dispatch(fetchBullhornCandidateDocuments(candidateId, acceptedFormats)),
    handleFetchBullhornCandidateFile: (candidateId, fileId) =>
      dispatch(fetchBullhornCandidateFile(candidateId, fileId)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export const BullhornModal = withConnect(BullhornModalComponent);
