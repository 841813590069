import { find, isEqual, isFunction } from 'lodash';

import { isCustomDomainPage } from 'containers/App/utils/helpers';

/* eslint security/detect-unsafe-regex:0 */
export const EVENTS = [
  {
    regex: /^\/cp\/[a-zA-Z0-9]{22}\/(?<source_id>[a-zA-Z0-9]{22})(\/requests)?$/,
    type: 'view',
    source_type: 'shared_list',
    target_type: null,
  },
  {
    regex: /^\/(?<source_id>[a-zA-Z0-9]{22})(\/requests)?$/,
    type: 'view',
    source_type: 'shared_list',
    target_type: null,
    condition: isCustomDomainPage,
  },
  {
    regex: /^\/cp\/[a-zA-Z0-9]{22}\/(?<source_id>[a-zA-Z0-9]{22})\/(candidate|requests)\/(?<target_id>[a-zA-Z0-9]{22})$/,
    type: 'view',
    source_type: 'shared_list',
    target_type: 'candidate',
  },
  {
    regex: /^\/(?<source_id>[a-zA-Z0-9]{22})\/(candidate|requests)\/(?<target_id>[a-zA-Z0-9]{22})$/,
    type: 'view',
    source_type: 'shared_list',
    target_type: 'candidate',
    condition: isCustomDomainPage,
  },
];

export function startView(prevLocation, currLocation) {
  const event = find(EVENTS, (def) => {
    const match = currLocation.pathname.match(def.regex);
    const customCondition = isFunction(def.condition)
      ? def.condition(def)
      : true;

    return match && customCondition;
  });

  if (!event) {
    return null;
  }

  const prevMatch = prevLocation.pathname.match(event.regex);
  const currMatch = currLocation.pathname.match(event.regex);

  if (prevMatch && isEqual(prevMatch.groups, currMatch.groups)) {
    return null;
  }

  return {
    ...event,
    ...currMatch.groups,
    path: currLocation.pathname,
  };
}

export function endView(event, prevLocation, currLocation) {
  const prevMatch = prevLocation.pathname.match(event.regex);
  const currMatch = currLocation.pathname.match(event.regex);
  if (prevMatch && currMatch && isEqual(prevMatch.groups, currMatch.groups)) {
    return false;
  }

  return true;
}
