export const ASSET_FETCH_REQUEST = 'gustav/assets/FETCH_REQUEST';
export const ASSET_FETCH_SUCCESS = 'gustav/assets/FETCH_SUCCESS';
export const ASSET_FETCH_ERROR = 'gustav/assets/FETCH_ERROR';

export const ASSET_UPLOAD_REQUEST = 'gustav/assets/UPLOAD_REQUEST';
export const ASSET_UPLOAD_SUCCESS = 'gustav/assets/UPLOAD_SUCCESS';
export const ASSET_UPLOAD_ERROR = 'gustav/assets/UPLOAD_ERROR';

export const ASSET_DELETE_REQUEST = 'gustav/assets/DELETE_REQUEST';
export const ASSET_DELETE_SUCCESS = 'gustav/assets/DELETE_SUCCESS';
export const ASSET_DELETE_ERROR = 'gustav/assets/DELETE_ERROR';

export const ASSET_RENAME_REQUEST = 'gustav/assets/RENAME_REQUEST';
export const ASSET_RENAME_SUCCESS = 'gustav/assets/RENAME_SUCCESS';
export const ASSET_RENAME_ERROR = 'gustav/assets/RENAME_ERROR';
