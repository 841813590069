export const TEAM_MEMBERSHIPS_FETCH_REQUEST =
  'gustav/mebers/team_memberships/FETCH_REQUEST';
export const TEAM_MEMBERSHIPS_FETCH_SUCCESS =
  'gustav/mebers/team_memberships/FETCH_SUCCESS';
export const TEAM_MEMBERSHIPS_FETCH_ERROR =
  'gustav/mebers/team_memberships/FETCH_ERROR';

export const TEAM_MEMBERSHIPS_UPDATE_REQUEST =
  'gustav/mebers/team_memberships/UPDATE_REQUEST';
export const TEAM_MEMBERSHIPS_UPDATE_SUCCESS =
  'gustav/mebers/team_memberships/UPDATE_SUCCESS';
export const TEAM_MEMBERSHIPS_UPDATE_ERROR =
  'gustav/mebers/team_memberships/UPDATE_ERROR';

export const TEAMS_FETCH_REQUEST = 'gustav/mebers/teams/FETCH_REQUEST';
export const TEAMS_FETCH_SUCCESS = 'gustav/mebers/teams/FETCH_SUCCESS';
export const TEAMS_FETCH_ERROR = 'gustav/mebers/teams/FETCH_ERROR';
