import { defineMessages } from 'react-intl';

export default defineMessages({
  search: {
    id: 'app.components.Form.search.placeholder',
    defaultMessage: 'Search',
  },
  defaultCharactersCounter: {
    id: 'app.components.Form.input.defaultCharactersCounter',
    defaultMessage: '{currentCount}/{maxCharacters}',
  },
});
