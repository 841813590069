import {
  httpGet,
  httpPatch,
  httpPostFormData,
  httpUploadPut,
  httpDelete,
} from 'utils';

import { openFlashMessage } from 'components/utils/flashMessages';

import {
  COMPANY_FETCH_REQUEST,
  COMPANY_FETCH_SUCCESS,
  COMPANY_FETCH_ERROR,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_ERROR,
} from './constants';
import { FlashDefinition } from '../utils/flashDefinition';

export function fetchCompany() {
  return (dispatch) =>
    dispatch({
      type: COMPANY_FETCH_REQUEST,
    });
}

export function patchCompany(company) {
  return (dispatch) =>
    dispatch({
      type: COMPANY_UPDATE_REQUEST,
      payload: { company },
    });
}

export const fetchCompanySettings = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: COMPANY_FETCH_REQUEST });

    httpGet('/company/settings')
      .then((response) => {
        resolve(response);

        dispatch({
          type: COMPANY_FETCH_SUCCESS,
          payload: { data: response },
        });
      })
      .catch((error) => {
        reject(error);

        dispatch({ type: COMPANY_FETCH_ERROR, error });
      });
  });

export const updateCompanySettings = (settings) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: COMPANY_UPDATE_REQUEST });

    httpPatch('/company/settings', { settings })
      .then((response) => {
        resolve(response);

        openFlashMessage(FlashDefinition.UpdateAccountSuccess);
        dispatch({
          type: COMPANY_UPDATE_SUCCESS,
          payload: { settings: response },
        });
      })
      .catch((error) => {
        reject(error);

        dispatch({ type: COMPANY_UPDATE_ERROR, error });
      });
  });

/* eslint no-underscore-dangle: 0, no-return-assign: 0 */
export const uploadAsset = (file, type) => (dispatch) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('asset', file.name);
    const payload = formData._blob ? formData._blob() : formData;
    let asset;

    httpPostFormData('/company/asset', payload)
      .then((response) => {
        asset = response.asset;
        return httpUploadPut(response.s3_upload_url, file);
      })
      .then(() =>
        httpPatch(`/assets/${asset.id}`, {
          asset: { status: 'available' },
        })
      )
      .then(() => resolve(asset))
      .catch((error) => reject(error));
  });

export const deleteAsset = (type) => (dispatch) =>
  new Promise((resolve, reject) => {
    httpDelete('/company/asset', { type })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
