import PropTypes from 'prop-types';

import { formatDate } from 'components/utils/date';

export const ComputedDate = ({
  value: date,
  tdProps: {
    rest: { intl },
  },
}) => formatDate({ intl, date });

ComputedDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  tdProps: PropTypes.object,
};

ComputedDate.defaultProps = {
  tdProps: {
    rest: {},
  },
};
