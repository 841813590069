import { css, createGlobalStyle } from 'styled-components';

import { Colors } from './ui';
import { occupiedHeight } from '../../structure/page/utils/calculations';

type ContainedElement =
  | 'column'
  | 'applicantProfile'
  | 'applicantProfileMenuAndInteraction'
  | 'applicantProfileInteraction'
  | 'menu'
  | 'adminMainContainer'
  | 'pageContainer'
  | 'columnsContainer'
  | 'candidatesContainer'
  | 'candidateProfile'
  | 'mainContainer';

export const GlobalScrollYLock = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

export const scrollbarDimensions = function scrollbarDimensions(
  containedElement: ContainedElement
) {
  switch (containedElement) {
    case 'column':
      return {
        height: 'calc(100vh - 27.7rem)',
        width: '30.2rem',
      };

    case 'applicantProfile':
      return {
        height: 'calc(100% - 18.2rem)',
        marginTop: '1.4rem',
        width: '100%',
      };

    case 'applicantProfileMenuAndInteraction':
      return {
        height: 'calc(100% - 5.5rem)',
        marginTop: '0',
        width: '99.5%',
      };

    case 'applicantProfileInteraction':
      return {
        height: 'calc(100vh - 13.2rem)',
        marginTop: '0',
        width: '99.5%',
      };

    case 'menu':
      return {
        height: '28rem',
        marginTop: '0',
        width: '100%',
      };

    case 'adminMainContainer':
      return {
        height: '100vh',
        width: '100%',
      };

    case 'pageContainer':
      return {
        height: '100vh',
        width: '100%',
      };

    case 'columnsContainer':
      return {
        overflowY: 'hidden',
        height: `calc(100vh - ${occupiedHeight({ withTableMenu: true })}rem)`,
        width: '100%',
      };

    case 'candidatesContainer':
      return {
        height: `calc(100vh - ${occupiedHeight()}rem)`,
        width: 'calc(100% - 30rem)',
      };

    case 'candidateProfile':
      return {
        height: 'calc(100vh - 32rem)',
        width: '100%',
      };

    case 'mainContainer':
    default:
      return {
        height: `calc(100vh - ${occupiedHeight()}rem)`,
        width: '100%',
      };
  }
};

export const scrollContainer = {
  display: 'flex',
  backgroundColor: Colors.grey,
  padding: '2rem',
};

export const scrollContainerColumn = {
  display: 'block',
};

export const scrollColumnsContainer = {
  ...scrollContainer,
  display: 'block',
  backgroundColor: Colors.white,
  overflowY: 'hidden',
  padding: '0',
};

export const scrollContainerMenu = {
  display: 'block',
};

export const scrollContainerPage = {
  display: 'block',
  overflowX: 'hidden',
};

export const scrollContainerCandidates = {
  display: 'block',
  backgroundColor: Colors.greyLighter,
  padding: '2rem',
};

export const scrollContainerVerticalColumn = {
  overflow: 'hidden scroll',
  marginBottom: '0',
};

export const scrollbarYProps = {
  borderRadius: '.3rem',
  bottom: '0',
  right: '.1rem',
  top: '2.1rem',
  width: '.5rem',
  display: 'block',
  zIndex: '2',
};

export const scrollbarHideYProps = {
  display: 'none',
};

export const scrollbarHideThumb = {
  display: 'none',
};

export const scrollbarXProps = (position = 'bottom') => ({
  backgroundColor: Colors.greyLight,
  borderRadius: '0.4rem',
  [position]: '.8rem',
  height: '1.2rem',
  width: '96.70%',
  marginLeft: '2.7rem',
});

export const scrollbarThumb = {
  backgroundColor: Colors.greyDark,
  borderRadius: '.4rem',
};

export const styledScrollbarMixin = css`
  &::-webkit-scrollbar-track {
    background-color: ${Colors.greyLighter};
    border-radius: 0.4rem;
  }
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.4rem;
    background-color: ${Colors.greyLighter};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    background-color: ${Colors.greyDarker};
  }
`;
