import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { get, compact, filter } from 'lodash';

import { downloadFile } from 'utils/fileFunctions';
import { formatMoney } from 'components/utils/money';
import { Icon } from 'components/elements/icon';
import { AssetViewer, canPreview } from 'containers/Assets/Viewer';
import { ButtonDropdownMenu } from 'components/navigation/ButtonDropdownMenu';
import { formatDate } from 'components/utils/date';

import i18n from '../utils/i18n';
import * as styled from '../styles/section';
import * as styledUI from '../styles/documents';

/* eslint react/no-did-update-set-state: 0 */
export class VerificationDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  handleCloseModal = () => {
    this.setState({ modal: null });
  };

  handlePreviewDocument = (doc) => {
    this.setState({ modal: doc });
  };

  handleDownloadDocument = (doc) => {
    downloadFile(doc.asset.original_url, doc.name);
  };

  renderModal() {
    const { modal } = this.state;

    if (modal) {
      return (
        <AssetViewer asset={modal.asset} onClose={this.handleCloseModal} />
      );
    }

    return null;
  }

  renderDocumentRow = (doc) => {
    const { id, type, meta, inserted_at: insertedAt } = doc;
    const extension = doc.asset.meta.original_extension;
    const isCertificate = doc.type === 'certificate_of_insurance';
    const previewEnabled = canPreview(extension);

    const menuItems = compact([
      previewEnabled && {
        text: i18n.buttonPreview,
        onClick: () => this.handlePreviewDocument(doc),
        icon: 'Eye',
      },
      {
        text: i18n.buttonDownload,
        onClick: () => this.handleDownloadDocument(doc),
        icon: 'DownloadSimple',
      },
    ]);
    const name = <FormattedMessage {...i18n[type]} />;

    return (
      <tr key={`document-${id}`}>
        <styledUI.Td>
          <Icon icon="FileText" size="large" />
        </styledUI.Td>
        <styledUI.Td color={previewEnabled ? 'primary' : null}>
          {previewEnabled ? (
            <styledUI.Button
              type="blank"
              onClick={() => this.handlePreviewDocument(doc)}
            >
              {name}
            </styledUI.Button>
          ) : (
            name
          )}
        </styledUI.Td>
        <styledUI.Td>{formatDate({ date: insertedAt })}</styledUI.Td>
        <styledUI.Td>
          {isCertificate && get(meta, 'insurance_amount')
            ? formatMoney(meta.insurance_amount)
            : '-'}
        </styledUI.Td>
        <styledUI.Td>
          {isCertificate
            ? formatDate({ date: moment(get(meta, 'expiration_date')) })
            : '-'}
        </styledUI.Td>
        <styledUI.Td>
          <ButtonDropdownMenu items={menuItems} />
        </styledUI.Td>
      </tr>
    );
  };

  render() {
    const { documents } = this.props;

    return (
      <styled.Card bordered isSimple>
        <styled.Section
          title={<FormattedMessage {...i18n.titleVerificationDocuments} />}
        >
          {this.renderModal()}

          <styledUI.Table>
            <thead>
              <tr>
                <styledUI.Th></styledUI.Th>
                <styledUI.Th>
                  <FormattedMessage {...i18n.headerName} />
                </styledUI.Th>
                <styledUI.Th>
                  <FormattedMessage {...i18n.headerDate} />
                </styledUI.Th>
                <styledUI.Th>
                  <FormattedMessage {...i18n.headerAmount} />
                </styledUI.Th>
                <styledUI.Th>
                  <FormattedMessage {...i18n.headerExpirationDate} />
                </styledUI.Th>
                <styledUI.Th></styledUI.Th>
              </tr>
            </thead>
            <tbody>
              {filter(documents, (doc) => doc.asset).map(
                this.renderDocumentRow
              )}
            </tbody>
          </styledUI.Table>
        </styled.Section>
      </styled.Card>
    );
  }
}

VerificationDocuments.propTypes = {
  documents: PropTypes.array,
};
