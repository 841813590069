import theme from 'themes';
import { INTEGRATION_FRAME_HEIGHT } from 'utils/constants';
import { getMetaContent } from 'components/utils/html';

/**
 * Checks if app is running on real production server, not only on server with NODE_ENV == 'production';
 * If window location origin matches theme.domain - it's production, otherwise it's local dev, staging or dev server (app.gustav.build).
 */

const hostnames = {
  localhost: 'dev',
  'app.gustav.build': 'dev',
  'app.candidately.dev': 'dev',
  'my.candidately.dev': 'dev',
  'staging.hellogustav.com': 'staging',
  'staging-new.hellogustav.com': 'staging',
  'staging.gustav.build': 'staging',
  'staging.candidately.dev': 'staging',
  'staging-my.candidate.ly': 'staging',
  'app.hellogustav.com': 'prod',
  'app.candidate.ly': 'prod',
  'my.candidate.ly': 'prod',
};

export const getServerEnv = (window) =>
  hostnames[window.location.hostname] || 'prod';

export const isProductionServer = (window) =>
  window.location.origin.endsWith(theme.domain);

export const isDevServer = (window) =>
  hostnames[window.location.hostname] === 'dev';

export const isStagingServer = (window) =>
  hostnames[window.location.hostname] === 'staging';

export const isIntegrationIframe = (window) => window.top !== window.self;

export const isStaleIntegrationIframe = (window) =>
  isIntegrationIframe(window) &&
  window.innerHeight === INTEGRATION_FRAME_HEIGHT;

export const sharedListFallbackDomain = () => {
  switch (getServerEnv(window)) {
    case 'prod':
      return 'my.candidate.ly';
    case 'staging':
      return 'staging-my.candidate.ly';
    case 'dev':
      return 'my.candidately.dev';
    default:
      return window.location.host;
  }
};

const alternativePortalDomains = [
  'my.candidate.ly',
  'staging-my.candidate.ly',
  'my.candidately.dev',
];

export const isAlternativePortalDomain = () =>
  alternativePortalDomains.includes(window.location.hostname);

export const appEnv = getMetaContent("name='gustav:env'");
