import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { iconElementsSelector } from 'components/elements/styles/icon';

export const Wrapper = styled.div`
  padding: 2rem 4rem;
  width: 70rem;
  border-radius: 6px;
  background-color: white;
  ${({ theme }) => theme.typography.text.medium};
  color: ${Colors.text};
`;

export const Header = styled.h4`
  font-size: 2rem;
  font-weight: 500;
  color: ${Colors.tealDark};
`;

export const Paragraph = styled.p`
  line-height: 2.6rem;
  white-space: pre-line;
`;

export const List = styled.ul`
  margin: 3rem 0;
  padding: 0;

  li {
    margin: 1rem 0;
  }

  & svg {
    margin-right: 1rem;
  }

  & ${iconElementsSelector} {
    stroke: ${Colors.teal};
  }
`;

export const ButtonWrapper = styled.div`
  margin: 2rem 0;
  text-align: center;
`;
