import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';
import { isEmpty } from 'lodash';

import { Icon } from 'components/elements/icon';
import { Tooltip } from 'components/overlay/Tooltip';
import { Button } from 'components/elements/button';
import { TruncateWithTooltip } from 'components/text/TruncateWithTooltip';
import documentIconSrc from 'images/icons/document.svg';
import { formatDate } from 'components/utils/date';
import { buildFileName } from 'components/utils/files';
import { humanReadebleFileSize, downloadFile } from 'utils/fileFunctions';

import * as styled from '../styles/bullhornModal';
import i18n from '../utils/i18n';

export const BullhornDocument = (props) => {
  const {
    candidateBullhornId,
    documentDetails,
    documentDetails: { id, type, dateAdded, fileSize },
    onFetchFile,
    onClose,
    onDrop,
  } = props;

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [importInProgress, setImportInProgress] = useState(false);

  const handleDownloadFile = () => {
    setDownloadInProgress(true);
    onFetchFile(candidateBullhornId, id)
      .then(({ file }) => {
        downloadFile(
          `data:${file.contentType};base64,${file.fileContent}`,
          fileName
        );
        setDownloadInProgress(false);
      })
      .catch(() => setDownloadInProgress(false));
  };

  const handleImport = () => {
    setImportInProgress(true);
    onFetchFile(candidateBullhornId, id)
      .then(({ file }) => {
        onClose();
        const dataUrl = `data:${file.contentType};base64,${file.fileContent}`;
        fetch(dataUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const f = new File([blob], fileName, { type: file.contentType });
            onDrop([f]);
            setImportInProgress(false);
          });
      })
      .catch(() => setImportInProgress(false));
  };

  const fileName = buildFileName(documentDetails);

  return (
    <styled.DocumentTableRow>
      <img src={documentIconSrc} role="presentation" alt="" />
      <styled.DocumentItemName>
        <TruncateWithTooltip label={fileName} tooltip={{ fixed: true }} />
      </styled.DocumentItemName>
      <styled.DocumentItemType>
        <Icon icon="FileText" />
        {isEmpty(type) ? (
          <FormattedMessage {...i18n.notAvailable} />
        ) : (
          <TruncateWithTooltip
            label={type}
            tooltip={{ fixed: true, position: 'S' }}
          />
        )}
      </styled.DocumentItemType>
      <styled.DocumentItemDate>
        <Icon icon="CalendarBlank" />
        {formatDate({ date: moment.unix(dateAdded / 1000).local() })}
      </styled.DocumentItemDate>
      <styled.DocumentItemSize>
        <Icon icon="FloppyDisk" />
        {humanReadebleFileSize(fileSize, true)}
      </styled.DocumentItemSize>
      <styled.ButtonWrapper>
        <Tooltip
          content={<FormattedMessage {...i18n.bullhornModalDownloadTooltip} />}
          color="dark"
          fixed
        >
          <Button
            color="primaryDark"
            loading={downloadInProgress}
            onClick={handleDownloadFile}
          >
            <Icon icon="DownloadSimple" />
          </Button>
        </Tooltip>
      </styled.ButtonWrapper>
      <styled.ButtonWrapper>
        <Tooltip
          content={<FormattedMessage {...i18n.bullhornModalSelectTooltip} />}
          color="dark"
          fixed
        >
          <Button
            color="primaryDark"
            loading={importInProgress}
            onClick={handleImport}
          >
            <FormattedMessage {...i18n.bullhornModalSelectButton} />
          </Button>
        </Tooltip>
      </styled.ButtonWrapper>
    </styled.DocumentTableRow>
  );
};

BullhornDocument.propTypes = {
  candidateBullhornId: PropTypes.number,
  documentDetails: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    dateAdded: PropTypes.number,
    fileSize: PropTypes.number,
  }),
  onFetchFile: PropTypes.func,
  onClose: PropTypes.func,
  onDrop: PropTypes.func,
};
