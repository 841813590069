import { info } from 'js-logger';

import { objectToFormData } from 'scenes/utils/form/parsing-json';

import { apiHost } from './api-host';

export const beacon = (path, data) => {
  info(`[API/beacon]`, path, data);

  return new Promise((resolve, reject) => {
    const headers = {
      authorization: localStorage.getItem('phoenixAuthToken'),
    };
    const formData = objectToFormData({ ...data, _headers: headers });

    if (navigator.sendBeacon) {
      const success = navigator.sendBeacon(`${apiHost}/v1${path}`, formData);
      info(`[API/beacon] Response:`, success);

      resolve(success);
    } else {
      info('[API/beacon] Not supported');
      reject({ error: 'not_supported' });
    }
  });
};
