import { defineMessages } from 'react-intl';

export default defineMessages({
  editorPlaceholder: {
    id: 'app.form.rte.editorPlaceholder',
    defaultMessage: 'Enter text here...',
  },
  showMore: {
    id: 'app.form.rte.showMore',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'app.form.rte.showLess',
    defaultMessage: 'Show less',
  },
  askAI: {
    id: 'app.form.rte.askAI',
    defaultMessage: 'Ask AI',
  },
});
