import { Component } from 'react';
import PropTypes from 'prop-types';

export const DelightedAPI = (...args) => {
  if (window.delighted) {
    const [method, ...rest] = args;
    window.delighted[method].apply(null, rest);
  }
};

/* eslint no-param-reassign: 0 */
/* eslint prefer-rest-params: 0 */
/* eslint func-names: 0 */
export class Delighted extends Component {
  constructor(props) {
    super(props);

    const { apiKey } = props;

    (function (w, d, r, n, a) {
      if (!w[a]) {
        w[a] = [];
        const i = w[a];
        for (let s = 0; s < r.length; s += 1) {
          const c = r[s];
          i[c] =
            i[c] ||
            (function (e) {
              return function () {
                const t = Array.prototype.slice.call(arguments);
                i.push([e, t]);
              };
            })(c);
        }
        i.SNIPPET_VERSION = '1.0.1';
        const o = d.createElement('script');
        o.type = 'text/javascript';
        o.async = !0;
        o.src = `https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/${n}/${a}.js`;
        const u = d.getElementsByTagName('script')[0];
        u.parentNode.insertBefore(o, u);
      }
    })(
      window,
      document,
      [
        'survey',
        'reset',
        'config',
        'init',
        'set',
        'get',
        'event',
        'identify',
        'track',
        'page',
        'screen',
        'group',
        'alias',
      ],
      apiKey,
      'delighted'
    );
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return false;
  }
}

Delighted.propTypes = {
  apiKey: PropTypes.string,
};
