import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

export const Item = styled.li`
  margin: 0;
  padding: 0;

  border-bottom: ${({ isBordered }) =>
    isBordered ? `.1rem solid ${Colors.greyLight}` : 0};
  padding-bottom: ${({ isBordered }) => (isBordered ? 0.6 : 0)}rem;

  & + & {
    margin-top: 0.6rem;
  }
  &:last-child {
    border-bottom: 0;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  & + & {
    margin-top: 2rem;
  }
`;

export const OrderedList = styled(List)`
  padding-left: 2.2rem;
  list-style: disc;

  ${Item} {
    padding-left: 1rem;
  }
`;

export const NumberedList = styled(OrderedList.withComponent('ol'))`
  padding-left: 2.2rem;
  list-style: decimal;

  ${Item} {
    padding-left: 1rem;
  }
`;
