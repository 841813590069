import { FormattedMessage } from 'react-intl';

import * as styled from './styles';
import i18n from '../utils/i18n';

export const ReleaseUpdateComponent = ({
  onClick,
}: {
  onClick: VoidCallback;
}) => (
  <styled.Wrapper>
    <styled.Header>
      <FormattedMessage {...i18n.title} />
    </styled.Header>
    <styled.Paragraph>
      <FormattedMessage {...i18n.subtitle} />
    </styled.Paragraph>
    <styled.Button onClick={onClick} size="small">
      <styled.Icon color="white" icon="ArrowsClockwise" />
      <FormattedMessage {...i18n.button} />
    </styled.Button>
  </styled.Wrapper>
);
