import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Channel, Socket } from 'phoenix';

import { socketSelector } from 'containers/App/selectors';
import { openFlashMessage } from 'components/utils/flashMessages';

import { FlashDefinition } from './flashDefinition';

const usePhoenixSocket: () => Socket | undefined = () =>
  useSelector(socketSelector);

export const usePhoenixChannel = (topic?: string, params?: object) => {
  const [channel, setChannel] = useState<Channel | undefined>();

  const socket = usePhoenixSocket();

  useEffect(() => {
    if (!socket || !topic) return undefined;

    const newChannel = socket.channel(topic, params);
    setChannel(newChannel);

    newChannel?.join().receive('error', (response) => {
      console.error(`Failed to join channel ${topic}`, response);
    });

    return () => {
      setChannel(undefined);

      newChannel.leave().receive('error', (response) => {
        console.error(`Failed to leave channel ${topic}`, response);
      });
    };
  }, [socket, topic, params]);

  return channel;
};

type PhoenixEventProps<T> = {
  channel?: Channel;
  event: string;
  callback: (payload: T) => void;
};
export const usePhoenixEvent = <T>({
  channel,
  event,
  callback,
}: PhoenixEventProps<T>) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    if (!channel) return undefined;

    const handleRef = channel.on(event, (...params) =>
      callbackRef.current(...params)
    );

    return () => {
      channel.off(event, handleRef);
    };
  }, [channel, event]);
};

export const usePhoenixPush = <T>(
  channel: Channel | undefined,
  event: string,
  callback?: (params: Record<string, unknown>, response: T) => unknown
) =>
  useCallback(
    (params: Record<string, unknown>) =>
      new Promise<void>((resolve, reject) => {
        if (!callback) return resolve();

        channel
          ?.push(event, params, 6000)
          ?.receive('ok', (response) => {
            callback(params, response);
            resolve(response);
          })
          .receive('error', (error) => {
            console.error('post message error: ', error);
            reject(error);
          })
          .receive('timeout', () => {
            openFlashMessage(FlashDefinition.networkError);
          });

        return undefined;
      }),
    [channel, event, callback]
  );
