import styled from 'styled-components';

import { Button as ButtonBase } from 'components/elements/button';
import { Colors } from 'components/utils/styles/ui';

export const MoreWrapper = styled.div`
  border-top: 0.1rem solid ${Colors.greyLight};
  padding-top: 0.6rem;
`;

export const ButtonMore = styled(ButtonBase)``;
