import { takeLatest, call, put } from 'redux-saga/effects';
import { pickBy, identity, cloneDeep } from 'lodash';
import qs from 'qs';

import { httpGet } from 'utils';

import {
  SUPPLIER_HUB_FETCH_SUPPLIERS_REQUEST,
  SUPPLIER_HUB_FETCH_SUPPLIERS_SUCCESS,
  SUPPLIER_HUB_FETCH_SUPPLIERS_ERROR,
} from './constants';

const API = {
  fetchSuppliers: (params) =>
    httpGet(`/employers/suppliers?${qs.stringify(params)}`),
  fetchPublicHub: (params) =>
    httpGet(`/public/suppliers?${qs.stringify(params)}`),
};

function* fetchSuppliers({ payload: { filters, isPublic } }) {
  try {
    const newFilters = cloneDeep(filters);

    if (filters.city && filters.state) {
      newFilters.location = `${filters.city}, US-${filters.state}`;
      delete newFilters.city;
      delete newFilters.state;
    }

    const params = { filters: pickBy(newFilters, identity) };

    const { data } = yield call(
      isPublic ? API.fetchPublicHub : API.fetchSuppliers,
      params
    );

    yield put({
      type: SUPPLIER_HUB_FETCH_SUPPLIERS_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: SUPPLIER_HUB_FETCH_SUPPLIERS_ERROR,
      error,
    });
  }
}

export function* supplierHubSaga() {
  yield takeLatest(SUPPLIER_HUB_FETCH_SUPPLIERS_REQUEST, fetchSuppliers);
}
