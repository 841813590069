import { IconBase } from './iconBase';

import { CustomIconsProp } from '../types';

export const BracketsDots = ({ size, color, className }: CustomIconsProp) => (
  <IconBase className={className} viewBox={16} size={size}>
    <path
      d="M4.86207 2C1 2 4.86207 8 1 8C4.86207 8 1 14 4.86207 14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M11.1387 2C15.0007 2 11.1387 8 15.0007 8C11.1387 8 15.0007 14 11.1387 14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <ellipse cx="5.0957" cy="7.77679" rx="0.75" ry="0.776786" fill={color} />
    <ellipse cx="7.99953" cy="7.75" rx="0.724138" ry="0.75" fill={color} />
    <ellipse cx="10.9312" cy="7.75" rx="0.724138" ry="0.75" fill={color} />
  </IconBase>
);
