import { FormattedMessage } from 'react-intl';

import { IconName } from 'components/elements/types';

import * as styled from '../styles';
import i18n from '../utils/i18n';

type ButtonAskAIProps = {
  onClick: VoidCallback;
  icon: IconName;
  floating: boolean;
  withBarTextTools: boolean;
  disabled: boolean;
};

export const ButtonAskAI = ({
  onClick,
  icon,
  floating,
  withBarTextTools,
  disabled,
}: ButtonAskAIProps) => (
  <styled.AskButton
    disabled={disabled}
    onClick={onClick}
    color={floating ? 'grapeLighter' : 'grape'}
    floating={floating}
    withBarTextTools={withBarTextTools}
  >
    <styled.Icon icon={icon} weight="bold" color="grape" />
    <styled.AskAIText floating={floating}>
      <FormattedMessage {...i18n.askAI} />
    </styled.AskAIText>
  </styled.AskButton>
);
