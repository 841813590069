import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Flexbox } from 'components/structure/page/styles/containers';
import * as styledModal from 'components/overlay/Modal/styles/modal';

import { InputText as InputBase } from 'components/form/input/text';
import { Submit as SubmitBase } from 'components/form/submit/submit';
import { Avatar as AvatarBase } from 'components/visual/avatar/avatar';
import { Button as ButtonBase } from 'components/elements/button';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';

export const Form = styled.form`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0;
`;

export const Submit = styled(SubmitBase)``;

export const ModalView = styled(Flexbox)`
  flex-direction: column;
  align-items: flex-start;
  background-color: ${Colors.white};
  border-radius: 0.6rem;
  min-width: 50rem;
  position: relative;
  padding: 3rem 5rem;

  ${ButtonWrapper} {
    margin-bottom: 0;
  }
`;

export const FormTitle = styledModal.Title;

export const Table = styled.table`
  width: 100%;
  margin: 4rem 0 1rem 0;
`;

export const Th = styled.th`
  font-weight: 500;
  color: ${Colors.textLighter};
  text-align: left;
  padding: 0.8rem 0;
`;

export const Td = styled.td`
  border-top: 0.1rem solid ${Colors.greyLight};
  padding: 0.8rem 0;
  ${({ color, theme }) => (color ? `color: ${theme.colors[color]};` : null)}

  &:last-child {
    text-align: right;
  }
`;

export const User = styled(SmartLinkBase)`
  display: flex;
  align-items: center;
  color: inherit !important;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Avatar = styled(AvatarBase)`
  margin-right: 1rem;
`;

export const Input = styled(InputBase)`
  margin-top: 1rem;
  color: ${Colors.text};
`;

export const Label = styled.label``;

export const Button = styled(ButtonBase)`
  &:hover {
    text-decoration: underline;
  }
`;
