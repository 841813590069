import { IconBase } from './iconBase';

import { CustomIconsProp } from '../types';

export const Review = ({ size, color, className }: CustomIconsProp) => (
  <IconBase className={className} viewBox={24} size={size}>
    <path
      d="M14 10V8.41667V6.08333L9.8002 2H2.59997C2.44085 2 2.28824 2.06146 2.17573 2.17085C2.06321 2.28025 2 2.42862 2 2.58333V15.4167C2 15.5714 2.06321 15.7198 2.17573 15.8291C2.28824 15.9385 2.44085 16 2.59997 16H5.29999"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.71466 2V6H13.8287"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 13C10 13 8 17.5005 8 17.5005C8 17.5005 10 22 15 22C20 22 22 17.5005 22 17.5005C22 17.5005 20 13 15 13Z"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 20.0005C16.3807 20.0005 17.5 18.8812 17.5 17.5005C17.5 16.1198 16.3807 15.0005 15 15.0005C13.6193 15.0005 12.5 16.1198 12.5 17.5005C12.5 18.8812 13.6193 20.0005 15 20.0005Z"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconBase>
);
