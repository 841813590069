import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

export const NoNewLineOnEnter = Extension.create({
  name: 'no_new_line_on_enter',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleKeyDown: (view, event) =>
            event.key === 'Enter' && !event.shiftKey,
        },
      }),
    ];
  },
});
