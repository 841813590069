import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { iconElementsSelector } from 'components/elements/styles/icon';

import { roundToNearestEvenNumber } from 'components/utils/numeric';
import {
  Colors,
  candidateStatusColors,
  statusColors,
  companyProfileTypeColors,
  reviewStatusColors,
  workOrderStatusColors,
  jobSharingColors,
  affiliationRequestStatusColors,
  premiumFeatureColors,
  featureVersionColors,
} from 'components/utils/styles/ui';
import { Icon as IconBase } from 'components/elements/icon';

import theme from 'themes';

export const BADGE_COLORS = {
  primary: theme.colors.primary,
  primaryDark: theme.colors.primaryDark,
  secondary: theme.colors.secondary,
  danger: theme.colors.danger,
  warning: theme.colors.warning,
  mono: theme.colors.mono,
  monoLight: theme.colors.monoLight,
  monoLighter: theme.colors.monoLighter,
  tealLighter: Colors.tealLighter,

  ...statusColors,
};

export const BADGE_SIZES = {
  xsmall: 1.0,
  small: 1.2,
  normal: 1.4,
  large: 1.6,
  xlarge: 2,
};

const headerBadgeMixin = css`
  padding: 0 1.4rem;
  height: 3rem;
  font-weight: 500;
`;

const borderedMixin = css`
  border: 1px solid ${Colors.outline};
`;

export const Badge = styled.span`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  border-radius: 100px;
  color: ${({ fontColor }) => Colors[fontColor] || Colors.white};
  min-height: 2.2rem;
  min-width: 0rem;
  white-space: nowrap;
  background-color: ${({ color }) => BADGE_COLORS[color] || Colors[color]};

  font-weight: 400;
  ${({ size = 'normal' }) => theme.typography.text[size]};
  ${({ bordered }) => bordered && borderedMixin}
  ${({ upperCase }) => upperCase && `text-transform: uppercase;`}

  & + & {
    margin-left: 1rem;
  }

  ${({ header }) => header && headerBadgeMixin}
`;

export const CandidateStatusBadge = styled(Badge)`
  background-color: ${({ candidateStatus }) =>
    candidateStatusColors[candidateStatus]};
  color: ${({ candidateStatus }) =>
    candidateStatus === 'submitted' ? Colors.text : Colors.white};
`;

export const ExpirationBadge = styled(Badge)`
  padding: 0;

  & > div {
    align-items: center;
    display: flex;
    height: 100%;
    line-height: 2.8rem;
    vertical-align: middle;
    padding: 0.1rem 1.2rem 0.1rem 1rem;
    font-weight: 500;

    & svg {
      margin-right: 0.6rem;
    }
  }

  & > div:first-child {
    background-color: ${({ displayedStatus }) => statusColors[displayedStatus]};
    border-radius: 1.5rem 0 0 1.5rem;
  }

  & > div:last-child {
    background-color: ${Colors.white};
    color: ${Colors.text};
    border: 1px solid ${Colors.outline};
    border-radius: 0 1.5rem 1.5rem 0;
  }
`;

export const TimeToExpire = styled.span`
  margin-left: 0.4rem;
  color: ${({ days }) => {
    if (days > 7) {
      return Colors.green;
    }
    if (days > 2) {
      return Colors.yellow;
    }
    if (days >= 0) {
      return Colors.bhOrange;
    }
    return Colors.red;
  }};
`;

export const TimeSinceClosed = styled.span`
  color: ${Colors.red};
`;

export const CompanyProfileTypeBadge = styled(Badge)`
  background-color: ${({ companyType }) =>
    companyProfileTypeColors[companyType]};
`;

export const ReviewStatusBadge = styled(Badge)`
  background-color: ${({ reviewStatus }) => reviewStatusColors[reviewStatus]};
`;

export const WorkOrderStatusBadge = styled(Badge)`
  background-color: ${({ workOrderStatus }) =>
    workOrderStatusColors[workOrderStatus]};
`;

export const PremiumFeatureBadge = styled(Badge)`
  background-color: ${({ type }) => premiumFeatureColors[type]};
  ${theme.typography.text.small};

  & > svg {
    margin-right: 0.5rem;
    width: ${({ size }) => BADGE_SIZES[size]}rem;
    height: ${({ size }) => BADGE_SIZES[size]}rem;
  }
`;

export const FeatureVersionBadge = styled(Badge)`
  background-color: ${({ version }) => featureVersionColors[version]};
`;

export const AffiliationRequestStatusBadge = styled(Badge)`
  background-color: ${({ status }) => affiliationRequestStatusColors[status]};
  color: ${({ status }) =>
    ['accepted', 'rejected'].includes(status)
      ? Colors.white
      : Colors.textLight};
  border: ${({ status }) =>
    status === 'null' ? `.1rem solid ${Colors.textLight}` : 'none'};
`;

export const CommunityBadge = styled(Badge)`
  background-color: ${Colors.white};
  color: ${Colors.text};
  border: 1px solid ${Colors.greyLight};
  margin-left: 1rem;
  padding: 0.3rem 1.25rem 0;
`;

export const SharedToBadge = styled(Badge)`
  background-color: ${({ badgeType }) => jobSharingColors[badgeType]};
  color: ${({ badgeType }) =>
    badgeType === 'global' ? Colors.text : Colors.white};
  border: ${({ badgeType }) =>
    badgeType === 'global' ? `1px solid ${Colors.outline}` : 'none'};
`;

export const SharedToBadgeExpiration = styled(Badge)`
  padding: 0;
  border: 1px solid ${Colors.outline};
  border-radius: 1.1rem;

  & > div {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 2.2rem;
    vertical-align: middle;
    padding: 0 0.8rem 0 0.6rem;

    & svg {
      margin: 0.4rem;
    }
  }

  & > div:first-child {
    background-color: ${jobSharingColors.global};
    color: ${Colors.text};
    border-radius: 1.1rem 0 0 1.1rem;
    padding-left: 1.2rem;
  }

  & > div:last-child {
    background-color: ${Colors.white};
    color: ${Colors.text};
    border-left: 1px solid ${Colors.outline};
    border-radius: 0 1.1rem 1.1rem 0;
    padding-right: 1.2rem;
  }
`;

export const Icon = styled(IconBase)``;

export const IconBadge = styled.span`
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: ${({ size }) =>
    roundToNearestEvenNumber(BADGE_SIZES[size] * 15) / 10}rem;
  height: ${({ size }) =>
    roundToNearestEvenNumber(BADGE_SIZES[size] * 15) / 10}rem;
  background-color: ${({ color }) => BADGE_COLORS[color] || Colors[color]};
  border: ${({ bordered, color }) =>
    bordered
      ? `1px solid ${darken(0.1, BADGE_COLORS[color] || Colors[color])}`
      : 'none'};
  border-radius: 100px;
  ${({ withPadding, size }) =>
    withPadding ? `padding: ${(BADGE_SIZES[size] * 3) / 10}rem;` : null}

  & svg {
    margin: 0;
  }
`;

export const InfoBadgeIcon = styled(IconBase)`
  margin-right: 0.6rem;
`;

export const RightBadgeIcon = styled(IconBase)`
  margin-left: 0.6rem;
`;

export const InfoBadge = styled(Badge)`
  box-shadow: 0 0.2rem 2rem 0 rgba(0, 0, 0, 0.2);
  height: 3rem;
  font-size: 1.3rem;

  & > ${InfoBadgeIcon} {
    margin-top: -0.2rem;
  }

  & > ${InfoBadgeIcon}:nth-child(2) {
    margin: -0.2rem -0rem 0 0.6rem;
  }
`;

const cvLargeMixin = css`
  height: 3rem;
  padding: 0 1.2rem;
  border-radius: 1.5rem;
`;

export const CvStatusBadge = styled(Badge)`
  background-color: ${({ bgColor }) => bgColor}};
  color: ${({ mainColor }) => mainColor};
  border: 1px solid ${({ borderColor }) => borderColor};

  ${theme.typography.text.small};

  ${({ size }) => size === 'large' && cvLargeMixin}

  ${iconElementsSelector} {
    stroke: ${({ mainColor }) => mainColor} !important;
  }
`;

export const BaseBadge = styled(CvStatusBadge)`
  font-size: 1.2rem;
  height: 3rem;
`;

export const RoleBadge = styled(BaseBadge);

export const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
