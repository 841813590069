import styled from 'styled-components';

import { Button as ButtonBase, BUTTON_SIZES } from 'components/elements/button';
import { Card as CardBase, CardSection } from 'components/misc/Card';
import { Colors } from 'components/utils/styles/ui';
import { InfoBox as InfoBoxBase } from 'components/text/InfoBox';
import { SectionTitle } from 'components/misc/Card/styles/card';

export const Card = styled(CardBase)`
  position: relative;
  margin-bottom: 2rem;
  margin-right: ${({ right }) => (right ? 0 : 2)}rem;
  border-width: ${({ bordered }) => (bordered ? 0.1 : 0)}rem;

  ${SectionTitle} {
    ${({ theme }) => theme.typography.text.large};
  }

  ${({ topBorder }) =>
    topBorder && `border-top: .4rem solid ${Colors.tealDark};`}

  ${({ isWideOnNarrowScreen }) =>
    isWideOnNarrowScreen
      ? `@media (max-width: 1350px) {
          min-width: 100%;
        }`
      : ''}

  ${({ hideOnNarrowScreen }) =>
    hideOnNarrowScreen
      ? `@media (max-width: 1350px) {
          display: none;
        }`
      : ''}

  ${({ reducedMarginOnNarrowScreen }) =>
    reducedMarginOnNarrowScreen
      ? `@media (max-width: 1350px) {
          && {
            margin-top: 0;
          }
        }`
      : ''}
`;

export const Section = styled(CardSection)`
  & > :first-child {
    ${({ theme }) => theme.typography.text.large};
  }
`;

export const TableSection = styled(CardSection)`
  padding: 0;

  & > :first-child {
    margin: 2rem 2rem 1rem 2rem;
  }
`;

export const ButtonEdit = styled(ButtonBase)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonEditEmpty = styled(ButtonBase).attrs({
  size: BUTTON_SIZES.medium,
})``;

export const Message = styled.span`
  color: ${({ theme, isMissing }) =>
    isMissing ? Colors.red : theme.colors.primary};
`;

export const Text = styled.p`
  white-space: pre-wrap;
`;

export const Image = styled.img`
  max-width: 10rem;
  margin: 1rem 0;
`;

export const InfoBox = styled(InfoBoxBase)`
  margin-bottom: 2rem;
  margin-right: 2rem;
`;
