import { defineMessages } from 'react-intl';

const i18n = defineMessages({
  title: {
    id: 'app.trialExpired.title',
    defaultMessage: 'Your trial has ended!',
  },
  subtitle: {
    id: 'app.trialExpired.subtitle',
    defaultMessage: `Hi {userName},\nIt seems your account is now inactive. Don’t worry — subscribe to regain full access and continue where you left off.`,
  },
  check1: {
    id: 'app.trialExpired.check1',
    defaultMessage:
      'All your data is safe and still available in your account.',
  },
  check2: {
    id: 'app.trialExpired.check2',
    defaultMessage:
      'As soon as you subscribe, your account will be fully restored and upgraded.',
  },
  button: {
    id: 'app.trialExpired.button',
    defaultMessage: 'Talk to Sales',
  },
});

export default i18n;
