export const COMPANY_NOTES_FETCH_REQUEST = 'gustav/company/notes/FETCH_REQUEST';
export const COMPANY_NOTES_FETCH_SUCCESS = 'gustav/company/notes/FETCH_SUCCESS';
export const COMPANY_NOTES_FETCH_ERROR = 'gustav/company/notes/FETCH_ERROR';

export const COMPANY_NOTE_CREATE_REQUEST =
  'gustav/company/notes/CREATE_REQUEST';
export const COMPANY_NOTE_CREATE_SUCCESS =
  'gustav/company/notes/CREATE_SUCCESS';
export const COMPANY_NOTE_CREATE_ERROR = 'gustav/company/notes/CREATE_ERROR';

export const COMPANY_NOTE_UPDATE_REQUEST =
  'gustav/company/notes/UPDATE_REQUEST';
export const COMPANY_NOTE_UPDATE_SUCCESS =
  'gustav/company/notes/UPDATE_SUCCESS';
export const COMPANY_NOTE_UPDATE_ERROR = 'gustav/company/notes/UPDATE_ERROR';

export const COMPANY_NOTE_DELETE_REQUEST =
  'gustav/company/notes/DELETE_REQUEST';
export const COMPANY_NOTE_DELETE_SUCCESS =
  'gustav/company/notes/DELETE_SUCCESS';
export const COMPANY_NOTE_DELETE_ERROR = 'gustav/company/notes/DELETE_ERROR';
