import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { find, isObject } from 'lodash';

import * as styled from './styles';

export class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = { active: props.active };
  }

  onClick(active) {
    const { tabs } = this.props;
    const tab = find(tabs, { slug: active });

    this.setState({ active });
    if (tab.fn) {
      tab.fn(tab);
    }
  }

  render() {
    const { className, tabs, size, active: propActive } = this.props;
    const { active: stateActive } = this.state;

    return (
      <styled.Container className={className}>
        {tabs.map(({ label, slug, link, indicatorColor }) => (
          <styled.Tab
            size={size}
            key={`tab-${slug}`}
            active={slug === (propActive || stateActive) ? 1 : 0}
            onClick={() => this.onClick(slug)}
            to={link}
          >
            {isObject(label) ? <FormattedMessage {...label} /> : label}
            {indicatorColor && <styled.Indicator color={indicatorColor} />}
          </styled.Tab>
        ))}
      </styled.Container>
    );
  }
}

Tabs.defaultProps = {
  size: 'normal',
};

Tabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      slug: PropTypes.string,
      fn: PropTypes.function,
      link: PropTypes.string,
      indicatorColor: PropTypes.string,
    })
  ),
  active: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'compact']),
};
