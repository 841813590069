import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { isIntegrationIframe } from 'utils/checkEnvironment';
import { isLoginPath, isLogoutPath } from 'containers/App/utils/helpers';

import { CookieScriptGlobalStyles } from './styles';

const RETRY_TIMES = 10;
const RETRY_TIMEOUT = 1000;
const HIDE_EVENTS = ['CookieScriptLoaded', 'CookieScriptAcceptAll'];

export const CookieScriptAPI = (...args) => {
  const [method, rest] = args;
  const cookieScript = window.CookieScript;
  if (method === 'showBadge') {
    return showBadge(rest);
  }
  if (cookieScript?.instance) {
    return cookieScript.instance[method].apply(null, rest);
  }

  return null;
};

let timeoutId;

export const showBadge = (show) => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  const retry = (times) => {
    const badgeEl = document.getElementById('cookiescript_badge');

    if (badgeEl) {
      if (show) {
        badgeEl.style.setProperty('visibility', 'visible');
      } else {
        CookieScriptAPI('hide');
        badgeEl.style.setProperty('visibility', 'hidden');
      }
    } else if (times > 0) {
      timeoutId = setTimeout(() => retry(times - 1), RETRY_TIMEOUT);
    }
  };
  retry(RETRY_TIMES);
};

export const CookieScript = (props) => {
  const { apiKey, isAuthenticated } = props;
  const isAuthenticatedRef = useRef(null);
  const loginPaths =
    isLoginPath(window.location) || isLogoutPath(window.location);

  useEffect(() => {
    if (!window.CookieScript) {
      const n = document.createElement('script');
      n.type = 'text/javascript';
      n.async = true;
      n.src = `https://geo.cookie-script.com/s/${apiKey}.js?country=gb-no-is-li-br-za-ca&region=eu`;
      const o = document.getElementsByTagName('script')[0];
      o.parentNode.insertBefore(n, o);
    }
    const hideBadge = () => {
      if (isAuthenticatedRef.current) {
        showBadge(false);
      }
    };

    HIDE_EVENTS.forEach((event) => window.addEventListener(event, hideBadge));

    return () =>
      HIDE_EVENTS.forEach((event) =>
        window.removeEventListener(event, hideBadge)
      );
  }, []);

  useEffect(() => {
    showBadge(!isAuthenticated);
    isAuthenticatedRef.current = isAuthenticated;
  }, [isAuthenticated]);

  return (
    isIntegrationIframe(window) && !loginPaths && <CookieScriptGlobalStyles />
  );
};

CookieScript.propTypes = {
  apiKey: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};
