import { Component } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import {
  noop,
  reject,
  map,
  find,
  get,
  unionBy,
  isEmpty,
  isArray,
  uniqBy,
} from 'lodash';

import { sortedFastEqual } from 'components/utils/array';

import { toQueryString, fromQueryString } from './utils/urlQuery';

export const DefaultState = {
  isFetching: false,

  data: [],

  filtered: [],
  externalFiltered: [],
  sorted: [],
};

const DefaultPageSize = 25;

export class TableProvider extends Component {
  isMountedInDOM = false;

  // NOTE:
  // Preparation for upcoming expandable rows feature
  expandedChange = noop;

  constructor(props) {
    super(props);

    const {
      defaultFiltered,
      externalFiltered,
      filtered,
      defaultSorted,
    } = this.props;

    const stateFromUrl = fromQueryString(window.location.search);

    this.state = {
      ...DefaultState,
      filtered: [
        ...(defaultFiltered || []),
        ...(externalFiltered || []),
        ...(filtered || []),
        ...stateFromUrl.filtered,
      ],
      sorted: [...(defaultSorted || []), ...stateFromUrl.sorted],
      paginated: {
        page: stateFromUrl.paginated.page || 1,
        pages: -1,
        pageSize: stateFromUrl.paginated.pageSize || DefaultPageSize,
        pageSizeOptions: [10, 25, 50, 100],
        totalCount: 0,
      },
      filterable: false,
    };
  }

  componentDidMount() {
    this.isMountedInDOM = true;
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const {
      filtered: stateFiltered,
      externalFiltered: stateExternalFiltered,
    } = this.state;
    const { externalFiltered, filtered, noMergedFilters } = this.props;
    const filteredChanged = !equal(prevProps.filtered, filtered);
    const externalFilteredChanged =
      (isEmpty(stateExternalFiltered) && !isEmpty(externalFiltered)) ||
      !equal(prevProps.externalFiltered, externalFiltered);
    if (filteredChanged || externalFilteredChanged) {
      const filteredMerged = unionBy(filtered, stateFiltered, 'id').filter(
        ({ value }) => !isEmpty(value)
      );
      if (
        !sortedFastEqual(filteredMerged, stateFiltered, 'id') ||
        externalFilteredChanged
      ) {
        this.filteredChange(
          noMergedFilters ? filtered : filteredMerged,
          externalFiltered
        );
      }
    }
  }

  componentWillUnmount() {
    const { onInit } = this.props;
    this.isMountedInDOM = false;
    onInit(null);
  }

  getRecord = (by) => {
    const { data } = this.state;
    return find(data, by);
  };

  getRecords = () => {
    const { data } = this.state;
    return data;
  };

  getStateData = (by) => get(this.state, by);

  recordChange = ({ id, key, value, refetch = true }) => {
    const { onChangeRecord } = this.props;
    const { data } = this.state;

    if (!refetch && value !== undefined) {
      this.setState({
        data: map(data, (item) => {
          if (item.id !== id) {
            return item;
          }
          return { ...item, ...(key ? { [key]: value } : value) };
        }),
      });
    }

    if (refetch) {
      this.setState({ isFetching: true }, () => {
        Promise.resolve(onChangeRecord({ id, key, value })).then(
          this.updateData
        );
      });
    } else {
      onChangeRecord({ id, key, value });
    }
  };

  addRecord = (record) => {
    const { data, paginated } = this.state;

    if (find(data, { id: record.id })) {
      return;
    }

    this.setState({
      data: [record, ...data],
      paginated: {
        ...paginated,
        totalCount: paginated.totalCount + 1,
      },
    });
  };

  removeRecord = (id) => {
    const { data, paginated } = this.state;
    this.setState({
      data: reject(data, { id }),
      paginated: {
        ...paginated,
        totalCount: paginated.totalCount - 1,
      },
    });
  };

  pageChange = (page) => {
    this.setState(
      (prevState) => ({ paginated: { ...prevState.paginated, page } }),
      this.updateData
    );
    this.scrollToTop();
  };

  pageSizeChange = (newPageSize) => {
    this.setState(
      (prevState) => ({
        paginated: {
          ...prevState.paginated,
          page: 1,
          pageSize: newPageSize,
        },
      }),
      this.updateData
    );
  };

  filteredChange = (filtered, externalFiltered) => {
    const { paginated } = this.state;
    const stateUpdates = {
      filtered,
      ...(isArray(externalFiltered) ? { externalFiltered } : {}),
      paginated: { ...paginated, page: 1 },
    };

    this.setState(stateUpdates, this.updateData);
    this.scrollToTop();
  };

  sortedChange = (sorted) => {
    const { paginated } = this.state;

    this.setState(
      { sorted, paginated: { ...paginated, page: 1 } },
      this.updateData
    );
    this.scrollToTop();
  };

  filterableChange = () => {
    const { filterable } = this.state;
    this.setState({ filterable: !filterable });
  };

  updateData = () => {
    const { filtered, externalFiltered, sorted, paginated } = this.state;
    const { onChange, onInit, defaultFiltered, defaultSorted } = this.props;

    const allFiltered = uniqBy(
      [...(externalFiltered || []), ...filtered],
      'id'
    );

    this.setState({ isFetching: true }, () => {
      onChange({
        filtered: allFiltered,
        sorted,
        paginated,
      }).then(({ data, paginated: _paginated }) => {
        if (this.isMountedInDOM) {
          this.setState({
            isFetching: false,
            data,
            sorted,
            paginated: _paginated,
          });
        }
        onInit(this);
      });
    });

    const query = toQueryString(this.state, {
      defaultFiltered,
      externalFiltered,
      defaultSorted,
      defaultPageSize: DefaultPageSize,
    });
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${query}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  };

  scrollToTop() {
    document.documentElement.scrollTop = 0;
  }

  render() {
    const { children } = this.props;
    const {
      isFetching,
      data,
      filtered,
      externalFiltered,
      sorted,
      paginated,
      filterable,
    } = this.state;

    return children({
      isFetching,
      computedData: data,

      filtered,
      externalFiltered,
      sorted,
      paginated,
      filterable,

      pageChange: this.pageChange,
      pageSizeChange: this.pageSizeChange,
      filteredChange: this.filteredChange,
      sortedChange: this.sortedChange,
      expandedChange: this.expandedChange,
      filterableChange: this.filterableChange,
      recordChange: this.recordChange,
      updateData: this.updateData,
    });
  }
}

export const Props = {
  children: PropTypes.func,
  onChange: PropTypes.func,
  onChangeRecord: PropTypes.func,
  onInit: PropTypes.func,
  noMergedFilters: PropTypes.bool,
  filtered: PropTypes.array,
  externalFiltered: PropTypes.array,
  defaultFiltered: PropTypes.array,
  defaultSorted: PropTypes.array,
};
TableProvider.propTypes = Props;

TableProvider.defaultProps = {
  children: null,
  onChange: noop,
  onChangeRecord: noop,
  onInit: noop,
  noMergedFilters: false,
  filtered: [],
};
