import { Icon } from '../../elements/icon';

import * as styled from './styles/buttons';

import { Colors } from '../../utils/styles/ui';

type GoToButtonProps = {
  className?: string;
  prev?: boolean;
  next?: boolean;
  fn: VoidCallback;
};

export const GoToButton = function GoToButton({
  className,
  prev,
  next,
  fn,
}: GoToButtonProps) {
  if (prev) {
    return (
      <styled.Arrow className={className} onClick={fn}>
        <Icon icon="CaretLeft" color={Colors.white} size={4} />
      </styled.Arrow>
    );
  }

  if (next) {
    return (
      <styled.Arrow className={className} onClick={fn}>
        <Icon icon="CaretRight" color={Colors.white} size={4} />
      </styled.Arrow>
    );
  }
  return null;
};
