import PropTypes from 'prop-types';

import { isIntegrationIframe } from 'utils/checkEnvironment';

import * as styled from './styles';

export const User = ({ user, linksActive, isPublic }) => {
  const {
    first_name: first,
    last_name: last,
    position: userPosition,
    is_simple: simple,
    email,
    company,
  } = user;
  const hasName = first && last;
  const userProfileLink =
    isPublic || !user.id ? '' : `/user/profile/${user.id}`;
  const companyProfileLink =
    linksActive && company ? `/company/profile/${company.id}` : '';
  const linkTarget = isIntegrationIframe(window) ? '_blank' : null;

  return (
    <styled.Content>
      <styled.AvatarLink to={simple ? '' : userProfileLink} target={linkTarget}>
        <styled.Avatar size="lmedium" user={user} color={user.color} />
      </styled.AvatarLink>
      <styled.Items>
        <styled.Item>
          <styled.UserLink
            to={simple ? '' : userProfileLink}
            target={linkTarget}
          >
            <styled.LinkText>
              {hasName ? `${first} ${last}` : email}
            </styled.LinkText>
          </styled.UserLink>
        </styled.Item>
        {!simple && (
          <styled.Item>
            <styled.Icon icon="User" />
            <styled.LinkText>{userPosition || '-'}</styled.LinkText>
          </styled.Item>
        )}
        {company && (
          <styled.Item>
            <styled.CompanyLink
              linksUnderlined={simple || linksActive}
              to={simple ? '' : companyProfileLink}
              target={simple || isIntegrationIframe(window) ? '_blank' : null}
            >
              <styled.Icon icon="Briefcase" />
              <styled.LinkText>{company.name}</styled.LinkText>
              {simple && <styled.Icon icon="ArrowSquareOut" />}
            </styled.CompanyLink>
          </styled.Item>
        )}
      </styled.Items>
    </styled.Content>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    position: PropTypes.string,
    avatar: PropTypes.string,
    color: PropTypes.string,
    last_activity_at: PropTypes.string,
    is_simple: PropTypes.bool,
    company: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  linksActive: PropTypes.bool,
  isPublic: PropTypes.bool,
};
