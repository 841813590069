import styled from 'styled-components';

import { Colors, bottomShadow } from 'components/utils/styles/ui';
import { InputText as InputTextBase } from 'components/form/input/text';
import { Icon as IconBase } from 'components/elements/icon';

export const Wrapper = styled.div`
  position: fixed;
  width: 40rem;
  z-index: 101;
`;
export const InputWrapper = styled.div<{
  hasRoundCorners: boolean;
  disabled: boolean;
  inProgress: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  border: 1px solid ${Colors.teal};
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 0 1.2rem;
  background-color: ${({ disabled, inProgress }) =>
    !disabled || inProgress ? Colors.white : Colors.greyLight};
  ${({ hasRoundCorners }) => hasRoundCorners && 'border-radius: 0.4rem'};
`;

export const SubmitIcon = styled(IconBase)<{ disabled: boolean }>`
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')} !important;
  }
`;

export const TextInput = styled(InputTextBase)<{ disabled: boolean }>`
  padding-left: 0;
  height: 100%;
  border: none;

  background-color: ${({ disabled }) =>
    disabled ? Colors.greyLight : Colors.white} !important;

  &:focus {
    border: none;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 2.6rem 0 0.6rem;
  color: ${Colors.grape};
`;

export const Top = styled.div`
  position: relative;
  box-shadow: ${bottomShadow};
`;

export const DropDownWrapper = styled.div`
  width: 100%;
  max-height: 40rem;
  border: 1px solid ${Colors.outline};
  border-radius: 0.4rem;
  background-color: ${Colors.white};
  margin-top: 1.2rem;
  box-shadow: ${bottomShadow};
  overflow-y: auto;
`;

export const DropDownOption = styled.div`
  width: 100%;
  height: 4rem;
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: start;

  &:hover {
    background-color: ${Colors.tealLightest};
    cursor: pointer;
  }
`;

export const OptionText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.text};
  margin-left: 0.6rem;
`;
