import styled, { css } from 'styled-components';

import { Colors, bottomShadow } from 'components/utils/styles/ui';
import { Icon as IconBase } from 'components/elements/icon';
import { menuActiveText } from 'components/utils/styles/mixins';

export const Wrapper = styled.div`
  position: relative;
  width: inherit;
`;

export const SelectIcon = styled(IconBase)`
  margin-right: 1.2rem;
`;

export const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.tealLightest};
  }

  & > svg {
    margin-left: auto;
  }

  & > * {
    ${({ isSelected }) => menuActiveText({ active: isSelected })}
  }
`;

export const Select = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ compact }) => (compact ? 3.6 : 6.8)}rem;
  border: 1px solid ${Colors.outline};
  border-radius: 0.4rem;
  background-color: ${Colors.white};

  ${Option} {
    &:hover {
      background-color: inherit;
    }
  }
`;

const optionsTopMixin = css`
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  margin-top: -0.6rem;
`;

const optionsBottomMixin = css`
  position: absolute;
  top: 100%;
  margin-top: 0.6rem;
`;

const optionsCenterMixin = css`
  position: fixed;
  z-index: 1000;
  width: inherit;
  max-height: calc(100vh - 2 * 1rem);
`;

export const Options = styled.div`
  z-index: 10;
  width: 100%;
  overflow: auto;
  border: 1px solid ${Colors.outline};
  border-radius: 0.4rem;
  box-shadow: ${bottomShadow};
  background-color: ${Colors.white};
  max-height: ${({ distanceToBottom, distanceToTop, position }) =>
    position === 'bottom'
      ? `${distanceToBottom - 20}px`
      : `${distanceToTop - 166}px`};

  ${({ position }) => {
    switch (position) {
      case 'top':
        return optionsTopMixin;
      case 'bottom':
        return optionsBottomMixin;
      case 'center':
        return optionsCenterMixin;
      default:
        return null;
    }
  }}

  ${Option} {
    height: 6.2rem;
  }
`;

export const OptionsHead = styled.div`
  height: 4.4rem;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  font-weight: 500;
  color: ${({ color }) => Colors[color]};
`;

export const Divider = styled.div`
  margin: 1rem 1.2rem;
  border-top: 1px solid ${Colors.outline};
`;

export const OptionTop = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
`;

export const OptionBottom = styled.div`
  margin-top: 0.4rem;
  color: ${Colors.textLight};
`;

export const OptionIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => Colors[color]};
`;

export const Badge = styled.div`
  height: 2.2rem;
  margin-left: 0.8rem;
  border-radius: 5rem;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => Colors[color]};
  background-color: ${({ background }) => Colors[background]};

  & > * {
    margin-right: 0.5rem;
  }
`;
