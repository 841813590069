import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { get, isObject } from 'lodash';
import * as pdfjsLib from 'pdfjs-dist';
import {
  PDFViewer,
  EventBus,
  PDFLinkService,
  PDFFindController as PDFFindControllerBase,
} from 'pdfjs-dist/web/pdf_viewer';
import 'pdfjs-dist/build/pdf.worker.entry';
import PanZ from '@thesoulfresh/pan-z';

import { Spinner } from 'components/visual/Spinner';
import { getScrollbarWidth } from 'components/utils/scroll';

import * as styled from './styles/pdf';
import { handlePZBounds } from './utils';

/* eslint max-classes-per-file: 0 */
export class PDFFindController extends PDFFindControllerBase {
  scrollMatchIntoView() {}
}

/* eslint prefer-arrow-callback: 0 */
/* eslint no-unused-expressions: 0 */
/* eslint func-names: 0 */
export class PdfViewer extends Component {
  constructor() {
    super();
    this.currentPage = -1;
    this.scratchCanvas = document.createElement('canvas');
    this.viewerContainer = createRef();
    this.pdfContainer = createRef();

    this.eventBus = new EventBus();
    this.setLinkService();
    this.setPdfFindController();

    this.state = {
      pdf: null,
      loadingTask: false,
    };
  }

  componentDidMount() {
    this.setViewer();
    this.loadPDFDocument(this.props);

    const { enableZoom } = this.props;

    if (this.pdfContainer?.current && enableZoom) {
      const pz = new PanZ();

      pz.init(this.pdfContainer.current, {
        boundingElement: this.viewerContainer.current,
        minZoom: 1,
      });
      pz.on('update', handlePZBounds(pz));
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { pdf } = this.state;
    const { width, keywords } = this.props;
    return (
      nextState.pdf !== pdf ||
      nextProps.width !== width ||
      nextProps.keywords !== keywords
    );
  }

  componentDidUpdate(prevProps) {
    const { width, file, content } = this.props;
    const { pdf } = this.state;

    if (width !== prevProps.width) {
      this.updateScale(width);
    }

    const widthChange = width !== prevProps.width;
    const fileChange = file && file !== prevProps.file;

    if (
      fileChange ||
      (widthChange && pdf) ||
      (content && content !== prevProps.content)
    ) {
      !widthChange && this.setViewer();
      this.loadPDFDocument(this.props, fileChange);
    }
  }

  componentWillUnmount() {
    const { loadingTask } = this.state;
    if (loadingTask) {
      loadingTask.destroy();
    }
    this.scratchCanvas = null;
  }

  onDocumentComplete = (pdf) => {
    const { onDocumentComplete } = this.props;
    const { numPages } = pdf;

    this.pdfViewer.setDocument(pdf);
    this.pdfLinkService.setDocument(pdf, null);
    this.setState({ pdf });

    if (typeof onDocumentComplete === 'function') {
      onDocumentComplete(numPages, pdf);
    }
  };

  onDocumentError = (error) => {
    this.setState({ pdf: false });

    const { onDocumentError } = this.props;

    if (typeof onDocumentError === 'function') {
      onDocumentError(error);
    }
  };

  setViewer = () => {
    const container = this.viewerContainer.current;

    if (container) {
      this.pdfViewer = new PDFViewer({
        container,
        eventBus: this.eventBus,
        linkService: this.pdfLinkService,
        findController: this.pdfFindController,
        removePageBorders: false,
      });
      this.pdfLinkService.setViewer(this.pdfViewer);
    }

    this.eventBus.on('pagesinit', this.handlePagesInit);
  };

  setLinkService = () => {
    this.pdfLinkService = new PDFLinkService({
      eventBus: this.eventBus,
    });
  };

  setPdfFindController = () => {
    this.pdfFindController = new PDFFindController({
      eventBus: this.eventBus,
      linkService: this.pdfLinkService,
    });
  };

  updateScale = (width) => {
    const widthWithoutScrollbar = Math.max(width - getScrollbarWidth(), 1);
    const viewport = get(this.pdfViewer, '_pages.0.viewport');

    if (get(viewport, 'scale') && get(viewport, 'viewBox.2')) {
      const pageWidth = Math.round(viewport.viewBox[2] * viewport.scale);
      if (widthWithoutScrollbar !== pageWidth) {
        this.pdfViewer.currentScale = widthWithoutScrollbar / pageWidth;
      }
    }
  };

  handlePagesInit = () => {
    const { width } = this.props;
    if (width) {
      this.updateScale(width);
    }
    this.renderKeywords();
  };

  loadByteArray(byteArray) {
    pdfjsLib
      .getDocument(byteArray)
      .then(this.onDocumentComplete)
      .catch(this.onDocumentError);
  }

  loadPDFDocument(props, fileChange) {
    const hasFile = !!props.file;
    const hasContent = !!props.content;
    const { loadingTask: task } = this.state;
    const currentLoadingTask = !fileChange && task;

    if (hasFile) {
      if (typeof props.file === 'string') {
        const loadingTask = isObject(currentLoadingTask)
          ? currentLoadingTask
          : pdfjsLib.getDocument({ url: props.file });

        if (!isObject(currentLoadingTask)) {
          this.setState({ loadingTask });
        }

        return loadingTask.promise
          .then(this.onDocumentComplete)
          .catch(this.onDocumentError);
      }

      // is a File object
      const reader = new FileReader();

      reader.onloadend = () => {
        this.loadByteArray(new Uint8Array(reader.result));
      };
      return reader.readAsArrayBuffer(props.file);
    }
    if (hasContent) {
      const bytes = window.atob(props.content);
      const byteLength = bytes.length;
      const byteArray = new Uint8Array(new ArrayBuffer(byteLength));

      for (let i = 0; i < byteLength; i += 1) {
        byteArray[i] = bytes.charCodeAt(i);
      }

      return this.loadByteArray(byteArray);
    }
    throw new Error(
      'PDFjs works with a file (URL) or content ' +
        '(base64). At least one needs to be provided!'
    );
  }

  renderKeywords = () => {
    const { keywords } = this.props;

    if (keywords) {
      this.pdfFindController.executeCommand('find', {
        query: keywords,
        caseSensitive: false,
        findPrevious: false,
        highlightAll: true,
      });
    }
  };

  renderLoader() {
    const { loading } = this.props;

    if (loading) {
      return <div>{loading}</div>;
    }
    return (
      <styled.SpinnerWrapper>
        <Spinner animated />
      </styled.SpinnerWrapper>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div style={{ fontSize: 15, textAlign: 'center', padding: 20 }}>
        {error}
      </div>
    );
  }

  renderNoData() {
    const { noData } = this.props;
    return <div>{noData}</div>;
  }

  renderContent() {
    const { file } = this.props;
    const { pdf, page } = this.state;

    if (!file) {
      return this.renderNoData();
    }

    if (pdf === false || page === false) {
      return this.renderError();
    }

    if (pdf === null) {
      return this.renderLoader();
    }

    return null;
  }

  render() {
    const {
      withScrollbars,
      withBorder,
      maxHeight,
      style,
      width,
      enableZoom,
    } = this.props;

    if (withScrollbars && !enableZoom) {
      return (
        <styled.Scrollbars
          vertical
          width={width ? `${width}px` : `100%`}
          maxHeight={maxHeight}
          border={withBorder}
        >
          <styled.Viewer ref={this.viewerContainer} style={style}>
            <div
              id="viewer"
              className="pdfViewer"
              ref={this.pdfContainer}
            ></div>
            {this.renderContent()}
          </styled.Viewer>
        </styled.Scrollbars>
      );
    }

    return (
      <styled.Viewer ref={this.viewerContainer} style={style}>
        <div id="viewer" className="pdfViewer" ref={this.pdfContainer}></div>
        {this.renderContent()}
      </styled.Viewer>
    );
  }
}

PdfViewer.propTypes = {
  content: PropTypes.string,
  file: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noData: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  withBorder: PropTypes.bool,
  withScrollbars: PropTypes.bool,
  maxHeight: PropTypes.string,
  width: PropTypes.number,
  onDocumentComplete: PropTypes.func,
  onDocumentError: PropTypes.func,
  style: PropTypes.object,
  keywords: PropTypes.string,
  enableZoom: PropTypes.bool,
};

PdfViewer.defaultProps = {
  withScrollbars: false,
  withBorder: false,
  error: 'Failed to load PDF file.',
  noData: 'No PDF file specified.',
};
