export const TAG_SIZES = {
  small: {
    height: 2.0,
    line: 1.6,
    font: 1.2,
  },
  normal: {
    height: 2.6,
    line: 2.0,
    font: 1.3,
  },
};
