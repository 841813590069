import {
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { isFunction } from 'lodash';

export const SharedListTypeContext = createContext<{
  sharedListType?: SharedListType;
  setSharedListType?: (type: SharedListType) => void;
}>({});

export const SharedListTypeProvider = ({
  pathname,
  children,
}: {
  pathname?: string;
  children?: ReactNode | undefined;
}) => {
  const [sharedListType, setSharedListType] = useState<
    SharedListType | undefined
  >('submission');
  const value = { sharedListType, setSharedListType };

  useEffect(() => {
    const listTypeMatch = pathname?.match(
      /\/(submission|marketing|shared)-lists/
    )?.[1];
    const newType = (listTypeMatch === 'shared'
      ? 'submission'
      : listTypeMatch) as SharedListType | undefined;

    if (sharedListType !== listTypeMatch) {
      setSharedListType(newType || undefined);
    }
  }, [pathname, sharedListType]);

  return (
    <SharedListTypeContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </SharedListTypeContext.Provider>
  );
};

export const useSharedListTypeContext = () => useContext(SharedListTypeContext);
