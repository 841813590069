import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import { isString } from 'lodash';

import { BOX_SIZES } from 'components/visual/avatar/constants/avatar';
import { isValid } from 'components/utils/form-utils';
import { acceptedOrRejected } from 'components/utils/form-dropzone';
import { acceptedMimeTypes } from 'utils/fileFunctions';
import { Colors } from 'components/utils/styles/ui';

import * as styled from './styles/image';

const ImageComponent = function Image(props) {
  const {
    intl,
    className,
    id,
    picture,
    initials,
    size,
    shape,
    detail,
    required,
    shouldValidate,
    isImageLoading,
    onChange,
    icon,
    opacity,
    color,
    dropZoneProps,
  } = props;
  const inputDetail =
    detail && (isString(detail) ? detail : intl.formatMessage(detail));

  const pictureContext = { picture, ...(initials ? { initials } : {}) };
  const opaque = (picture && picture !== null) || initials;

  const valid = !required
    ? true
    : isValid({
        inputValue: picture,
        shouldValidate,
        inputType: 'image',
        required,
      });

  const acceptedFormats = ['image'];
  const maxFileSize = 5 * 1024 * 1024;

  if (isImageLoading) {
    return (
      <styled.Input className={className} id={id}>
        <styled.ImageInput size={size} shape={shape} isAbsolute={false}>
          <styled.LoadingLogo color="white" />
        </styled.ImageInput>
      </styled.Input>
    );
  }

  return (
    <styled.Input className={className} id={id}>
      <styled.Avatar
        key={picture}
        noInitials={!initials}
        isValid={valid}
        size={size}
        shape={shape}
        context={pictureContext}
        opacity={opacity}
        color={color}
      />
      <styled.ImageInput size={size} isOpaque={opaque} shape={shape}>
        {!icon.dropZoneControlled && icon.content}
        <Dropzone
          accept={acceptedMimeTypes(acceptedFormats)}
          maxSize={maxFileSize}
          onDrop={(images, rejectedImages) =>
            onChange(
              acceptedOrRejected(images, rejectedImages, {
                maxFileSize,
                acceptedFormats,
              })
            )
          }
          {...dropZoneProps}
        >
          {({ getRootProps, getInputProps }) => (
            <styled.DropzoneBox
              size={size}
              shape={shape}
              withLabel={!!inputDetail}
              {...getRootProps()}
            >
              {icon.dropZoneControlled && icon.content}
              {inputDetail && <styled.Detail>{inputDetail}</styled.Detail>}
              <input {...getInputProps()} />
            </styled.DropzoneBox>
          )}
        </Dropzone>
      </styled.ImageInput>
    </styled.Input>
  );
};

ImageComponent.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isImageLoading: PropTypes.bool,
  intl: PropTypes.object,
  picture: PropTypes.string,
  initials: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(Object.keys(BOX_SIZES)),
  shape: PropTypes.oneOf(['square-rounded', 'rounded']),
  shouldValidate: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.shape({
    content: PropTypes.node,
    dropZoneControlled: PropTypes.bool,
  }),
  opacity: PropTypes.number,
  color: PropTypes.string,
  dropZoneProps: PropTypes.object,
};

ImageComponent.defaultProps = {
  picture: null,
  size: 'x3large',
  shape: 'square-rounded',
  isImageLoading: false,
  initials: false,
  icon: {
    content: <styled.CameraIcon color={Colors.white} icon="Camera" size={3} />,
    dropZoneControlled: true,
  },
  opacity: 1,
};

export const ImageInput = injectIntl(ImageComponent);
