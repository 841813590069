export const INTEGRATIONS_FETCH_REQUEST =
  'gustav/integrations/INTEGRATIONS_FETCH_REQUEST';
export const INTEGRATIONS_FETCH_SUCCESS =
  'gustav/integrations/INTEGRATIONS_FETCH_SUCCESS';
export const INTEGRATIONS_FETCH_ERROR =
  'gustav/integrations/INTEGRATIONS_FETCH_ERROR';

export const INTEGRATION_FETCH_REQUEST =
  'gustav/integrations/INTEGRATION_FETCH_REQUEST';
export const INTEGRATION_FETCH_SUCCESS =
  'gustav/integrations/INTEGRATION_FETCH_SUCCESS';
export const INTEGRATION_FETCH_ERROR =
  'gustav/integrations/INTEGRATION_FETCH_ERROR';

export const INTEGRATION_CREATE_REQUEST =
  'gustav/integrations/INTEGRATION_CREATE_REQUEST';
export const INTEGRATION_CREATE_SUCCESS =
  'gustav/integrations/INTEGRATION_CREATE_SUCCESS';
export const INTEGRATION_CREATE_ERROR =
  'gustav/integrations/INTEGRATION_CREATE_ERROR';

export const INTEGRATION_UPDATE_REQUEST =
  'gustav/integrations/INTEGRATION_UPDATE_REQUEST';
export const INTEGRATION_UPDATE_SUCCESS =
  'gustav/integrations/INTEGRATION_UPDATE_SUCCESS';
export const INTEGRATION_UPDATE_ERROR =
  'gustav/integrations/INTEGRATION_UPDATE_ERROR';

export const INTEGRATION_DELETE_REQUEST =
  'gustav/integrations/INTEGRATION_DELETE_REQUEST';
export const INTEGRATION_DELETE_SUCCESS =
  'gustav/integrations/INTEGRATION_DELETE_SUCCESS';
export const INTEGRATION_DELETE_ERROR =
  'gustav/integrations/INTEGRATION_DELETE_ERROR';

export const RESOURCE_FETCH_REQUEST =
  'gustav/integrations/RESOURCE_FETCH_REQUEST';
export const RESOURCE_FETCH_SUCCESS =
  'gustav/integrations/RESOURCE_FETCH_SUCCESS';
export const RESOURCE_FETCH_ERROR = 'gustav/integrations/RESOURCE_FETCH_ERROR';

export const RESOURCES_FETCH_REQUEST =
  'gustav/integrations/RESOURCES_FETCH_REQUEST';
export const RESOURCES_FETCH_SUCCESS =
  'gustav/integrations/RESOURCES_FETCH_SUCCESS';
export const RESOURCES_FETCH_ERROR =
  'gustav/integrations/RESOURCES_FETCH_ERROR';

export const SETTINGS_REFRESH_REQUEST =
  'gustav/integrations/SETTINGS_REFRESH_REQUEST';
export const SETTINGS_REFRESH_SUCCESS =
  'gustav/integrations/SETTINGS_REFRESH_SUCCESS';
export const SETTINGS_REFRESH_ERROR =
  'gustav/integrations/SETTINGS_REFRESH_ERROR';

export const INTEGRATION_CREDENTIALS_VALIDATION_REQUEST =
  'gustav/integrations/INTEGRATION_CREDENTIALS_VALIDATION_REQUEST';
export const INTEGRATION_CREDENTIALS_VALIDATION_SUCCESS =
  'gustav/integrations/INTEGRATION_CREDENTIALS_VALIDATION_SUCCESS';
export const INTEGRATION_CREDENTIALS_VALIDATION_ERROR =
  'gustav/integrations/INTEGRATION_CREDENTIALS_VALIDATION_ERROR';

export const INTEGRATION_SYNC_UPDATE =
  'gustav/integrations/INTEGRATION_SYNC_UPDATE';
export const INTEGRATION_SYNC_PROGRESS_UPDATE =
  'gustav/integrations/INTEGRATION_SYNC_PROGRESS_UPDATE';

export const INTEGRATION_FORCE_SYNC_REQUEST =
  'gustav/integrations/INTEGRATION_FORCE_SYNC_REQUEST';
export const INTEGRATION_FORCE_SYNC_SUCCESS =
  'gustav/integrations/INTEGRATION_FORCE_SYNC_SUCCESS';
export const INTEGRATION_FORCE_SYNC_ERROR =
  'gustav/integrations/INTEGRATION_FORCE_SYNC_ERROR';

export const BULLHORN_CANDIDATES_DOCUMENTS_REQUEST =
  'gustav/integrations/DOCUMENTS_REQUEST';
export const BULLHORN_CANDIDATES_DOCUMENTS_SUCCESS =
  'gustav/integrations/DOCUMENTS_SUCCESS';
export const BULLHORN_CANDIDATES_DOCUMENTS_ERROR =
  'gustav/integrations/DOCUMENTS_ERROR';

export const BULLHORN_CANDIDATES_FETCH_FILE_REQUEST =
  'gustav/integrations/FETCH_FILE_REQUEST';
export const BULLHORN_CANDIDATES_FETCH_FILE_SUCCESS =
  'gustav/integrations/FETCH_FILE_SUCCESS';
export const BULLHORN_CANDIDATES_FETCH_FILE_ERROR =
  'gustav/integrations/FETCH_FILE_ERROR';
