import styled from 'styled-components';
import { darken } from 'polished';

import { Colors } from 'components/utils/styles/ui';
import { Button as ButtonBase } from 'components/elements/button';
import { Icon as IconBase } from 'components/elements/icon';
import { selfIconElementsSelector } from 'components/elements/styles/icon';

export const Button = styled(ButtonBase)`
  background-color: ${({ selected, disabled, theme }) => {
    if (selected && disabled) {
      return Colors.greyLight;
    }
    return selected ? theme.colors.primary : Colors.white;
  }};
  border: solid 1px ${Colors.inputOutline};
  border-radius: 0;
  color: ${({ selected }) => (selected ? Colors.white : Colors.textLighter)};
  height: 4rem;
  width: 100%;
  margin: 0 -1px;

  &:hover {
    background-color: ${({ selected, disabled, theme }) => {
      if (selected && disabled) {
        return Colors.greyLight;
      }
      return selected
        ? darken(0.05, theme.colors.primary)
        : darken(0.025, 'white');
    }};
  }

  &:focus {
    box-shadow: none;
  }

  ${({ selected, selectedMixin }) => selected && selectedMixin};
`;

export const Switch = styled.div`
  border-radius: 0.4rem;
  display: flex;

  & > * {
    flex: 1 1 100%;
  }

  & > ${Button}:first-child, & > *:first-child ${Button} {
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
  }

  & > ${Button}:last-child, & > *:last-child ${Button} {
    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
`;

export const Icon = styled(IconBase)`
  margin-left: 1rem;
  margin-right: 1rem;

  /** This is needed because button overwrite the css */
  ${selfIconElementsSelector} {
    stroke: ${({ color }) => Colors[color]} !important;
  }
`;

export const DisabledIcon = styled(IconBase)`
  margin-right: 1rem;

  /** This is needed because button overwrite the css */
  ${selfIconElementsSelector} {
    stroke: ${({ color }) => Colors[color]} !important;
  }
`;
