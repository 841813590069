import { IconWeight } from 'phosphor-react';
import { IconBase } from './iconBase';
import { ICON_WEIGHTS } from '../constants/icons';

import { CustomIconsProp } from '../types';

export const MailBack = ({
  size,
  color,
  className,
  weight,
}: CustomIconsProp) => {
  const strokeWidth = ICON_WEIGHTS[weight as IconWeight] as number;

  return (
    <IconBase className={className} viewBox={24} size={size}>
      <path
        d="M3 10V19.75C3 19.9489 3.07902 20.1397 3.21967 20.2803C3.36032 20.421 3.55109 20.5 3.75 20.5H20.25C20.4489 20.5 20.6397 20.421 20.7803 20.2803C20.921 20.1397 21 19.9489 21 19.75V10L12 4L3 10Z"
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10L10 10"
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12L9 10L11 8"
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 10L13.6364 15.25H10.3636L3 10"
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
