import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Select as SelectBase } from 'components/form/select/select';
import { InputText as InputBase } from 'components/form/input/styles/inputs';

export const MoneyContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-radius: 0.3rem;
  border: 1px solid ${({ theme, isValid, focused, disabled }) => {
    if (focused && !disabled) {
      return theme.colors.primary;
    }
    return isValid ? Colors.inputOutline : Colors.red;
  }}};
  background-color: ${({ readOnly, disabled }) =>
    readOnly || disabled ? Colors.greyLighter : Colors.white};
  color: ${({ readOnly, theme }) =>
    readOnly ? theme.colors.primary : Colors.text};
  height: 4rem;
`;

export const RangeInputsContainer = styled.div`
  width: calc(100% - 10rem);
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
`;

export const CurrencySymbol = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 500;
  color: ${Colors.tealDark};
  ${({ noSymbol }) => (noSymbol ? 'width: 1rem' : 'width: 3rem')};
`;

export const CurrencyCode = styled.span`
  min-width: 3.6rem;
  text-align: right;
`;

export const CurrencyWrapper = styled.div`
  display: flex;
`;

export const NameAndSymbol = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FixedInputMoney = styled(InputBase)`
  border: none;
  padding: 0;
  height: 100%;

  &:focus {
    border: none;
  }
`;

export const InputDivider = styled.span`
  display: inline-block;
  width: 1rem;
  height: 0.15rem;
  background-color: ${Colors.tealDark};
  margin: auto 1rem;
  vertical-align: middle;
`;

export const Select = styled(SelectBase)`
  position: static;
  flex-shrink: 0;
  min-width: unset;
  width: 7rem;
  border: none;
  border-left: solid 1px ${Colors.inputOutline};
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: ${Colors.tealLightest};
  height: 100%;

  & > a {
    padding: 0 0.8rem;
    font-weight: 500;
    color: ${Colors.tealDark};
  }

  & > a span {
    margin-right: 0 !important;
  }

  & > a svg {
    flex-shrink: 0;

    path {
      fill: ${Colors.tealDark};
    }
  }

  & li {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const CurrencyIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 5rem;
  border-left: solid 1px ${Colors.inputOutline};
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: ${Colors.tealLightest};
  padding: 0 0.8rem;
  font-weight: 500;
  color: ${Colors.tealDark};
`;
