import {
  httpGet,
  httpPatch,
  httpUploadPut,
  httpPost,
  httpPostFormData,
  httpDelete,
} from 'utils';
import { buildStringifiedQuery, buildPaginated } from 'utils/apiHelpers';

import { openFlashMessage } from 'components/utils/flashMessages';

import {
  COMPANY_PROFILE_FETCH_REQUEST,
  COMPANY_PROFILE_FETCH_SUCCESS,
  COMPANY_PROFILE_FETCH_ERROR,
  COMPANY_PROFILE_UPDATE_REQUEST,
  COMPANY_PROFILE_UPDATE_SUCCESS,
  COMPANY_PROFILE_UPDATE_ERROR,
  COMPANY_LOGO_UPLOAD_REQUEST,
  COMPANY_LOGO_UPLOAD_SUCCESS,
  COMPANY_LOGO_UPLOAD_ERROR,
  COMPANY_RATE_REQUEST,
  COMPANY_RATE_SUCCESS,
  COMPANY_RATE_ERROR,
  COMPANY_USERS_FETCH_REQUEST,
  COMPANY_USERS_FETCH_SUCCESS,
  COMPANY_USERS_FETCH_ERROR,
  COMPANY_LOGO_DELETE_REQUEST,
  COMPANY_LOGO_DELETE_SUCCESS,
  COMPANY_LOGO_DELETE_ERROR,
} from './constants';

import { FlashDefinition } from './scene/utils/flashDefinition';

export function fetchProfile(id, isPublic) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_PROFILE_FETCH_REQUEST });

      let link = '';
      if (isPublic) {
        link = '/public/suppliers';
      } else {
        link = '/company/profile';
      }

      httpGet(`${link}/${id}`)
        .then((response) => {
          dispatch({
            type: COMPANY_PROFILE_FETCH_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_PROFILE_FETCH_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function updateProfile(company) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_PROFILE_UPDATE_REQUEST });

      httpPatch('/company/profile', { settings: company })
        .then((response) => {
          dispatch({
            type: COMPANY_PROFILE_UPDATE_SUCCESS,
            payload: response,
          });

          openFlashMessage(FlashDefinition.updateSuccess);

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_PROFILE_UPDATE_ERROR,
            error,
          });

          reject(error);
        });
    });
}

/* eslint no-underscore-dangle: 0 */
export function uploadLogo(image, source) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_LOGO_UPLOAD_REQUEST });

      const formData = new FormData();
      formData.append('type', 'logo');
      formData.append('asset', image.name);
      const payload = formData._blob ? formData._blob() : formData;
      let companyLogo;

      httpPostFormData('/company/asset', payload)
        .then(({ s3_upload_url: s3UploadUrl, asset }) => {
          companyLogo = asset;
          return httpUploadPut(s3UploadUrl, image);
        })
        .then(() => {
          dispatch({
            type: COMPANY_LOGO_UPLOAD_SUCCESS,
            payload: companyLogo,
          });

          if (source !== 'settings') {
            openFlashMessage(FlashDefinition.uploadLogoSuccess);
          }

          resolve(source === 'settings' ? companyLogo : companyLogo.url);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_LOGO_UPLOAD_ERROR,
            error,
          });

          openFlashMessage(FlashDefinition.uploadLogoError);

          reject(error);
        });
    });
}

export function deleteLogo() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_LOGO_DELETE_REQUEST });

      httpDelete('/company/asset', { type: 'logo' })
        .then((response) => {
          dispatch({
            type: COMPANY_LOGO_DELETE_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_LOGO_DELETE_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function rateCompany(id, rating) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_RATE_REQUEST });

      httpPost(`/company/${id}/ratings`, { rating })
        .then((response) => {
          dispatch({
            type: COMPANY_RATE_SUCCESS,
            payload: response,
          });

          openFlashMessage(FlashDefinition.rateSuccess);

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: COMPANY_RATE_ERROR,
            error,
          });

          reject(error);
        });
    });
}

export function fetchUsers(id, { paginated, sorted, filtered }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_USERS_FETCH_REQUEST });

      const query = buildStringifiedQuery({ paginated, sorted, filtered });
      httpGet(`/company/${id}/members?${query}`)
        .then((response) => {
          dispatch({
            type: COMPANY_USERS_FETCH_SUCCESS,
            payload: response,
          });

          resolve({
            paginated: buildPaginated(response.paginate),
            data: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: COMPANY_USERS_FETCH_ERROR, error });

          reject(error);
        });
    });
}
