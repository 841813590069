import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';

export const Bottom = styled.div`
  margin: auto 1rem 0 0;
  padding-top: 1.5rem;
  border-top: 1px solid ${Colors.outline};
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
`;

export const Header = styled.div``;

export const JobsLimit = styled(BottomContent)`
  & > ${Header} > * {
    margin-right: 0.5rem;
  }
`;

export const HotlistLimit = styled(BottomContent)`
  & > ${Header} > * {
    margin-left: 0.5rem;

    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  margin: 1rem 0.5rem;
`;

export const SmartLink = styled(SmartLinkBase)`
  color: ${Colors.tealDark};
  text-decoration: underline;
  font-weight: 400;
`;
