import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { iconElementsSelector } from 'components/elements/styles/icon';

export const RichCheckbox = styled.div`
  display: flex;
  border: 0.1rem solid ${Colors.outline};
  border-radius: 0.3rem;
  padding: 1.5rem;
  background-color: ${({ color, value }) => {
    if (color === 'danger' && value) {
      return Colors.redLight;
    }
    if (value) {
      return Colors.tealLighter;
    }
    return Colors.greyLighter;
  }};
  ${({ disabled }) => (disabled ? null : 'cursor: pointer;')}
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
`;

export const Right = styled.div``;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.3rem;
  border: 0.1rem solid
    ${({ color, value }) =>
      color === 'danger' && value ? Colors.red : Colors.inputOutline};
  background-color: ${Colors.white};

  & > ${iconElementsSelector} {
    stroke: ${({ color, value, disabled }) => {
      if (color === 'danger') {
        return Colors.red;
      }
      if (!disabled || value) {
        return Colors.tealDark;
      }
      return Colors.inputOutline;
    }};
  }
`;

export const Label = styled.div`
  font-weight: 500;
  color: ${({ color }) => (color === 'danger' ? Colors.red : Colors.tealDark)};

  & > svg {
    margin-right: 0.8rem;
  }
`;

export const Placeholder = styled.div`
  margin-top: 0.8rem;
  line-height: 2rem;
`;
