import { isDevServer } from 'utils/checkEnvironment';
import { isTest } from 'utils/node-env-vars';
import { INTEGRATION_FRAME_HEIGHT } from 'utils/constants';

/*
 * Resolve whether a feature should be enabled in the UI. Disable everything by
 * default on prod. Enable everything by default on dev. Allow overriding with
 * environment variables.
 */

function resolveFeatureFlag(flagName) {
  if (isDevServer(window) || isTest()) {
    return process.env[flagName] || true;
  }
  return process.env[flagName] || false;
}

/*
 * App Config
 */

export const Config = {
  RecaptchaKey: '6LctZjMeAAAAADjroKVf8erjHUwXY6RILDUNqGDK',
  UpscopeApiKey: 'gwkUNZzQbFLjPdNcrYTZWtZB',
  CookieScriptKey: '5071bbdae32bf782cd3b20190f02d59c',
  InlineManualApiKey: '4c1a9c54dfada08aae9adda157a2c602',
  SegmentApiKey: {
    dev: 'CFlL5IU26mqpBa1kiagB654rrPQKV5CP',
    prod: 'PCUONL292CbFJqAzVCse57jCbsM0uWDF',
  },
  DelightedApiKey: 'Kbwrlueg0WoEV1wF',
  LogRocketAppId: 'nsjiqg/gustav-production',
  MapsApiKey: 'AIzaSyBLg5Or18GqLEcmX9R1fvUfBDCejIz96Ko',
  ZapierDisposableEmailKey: '1024275/oi0q8wl',
  IPStackAccessKey: 'dcdbb67d2b15cbf3202704e77108cdbe',
  TrackJsToken: 'b4661870c2164371b781a25b5e05e4a3',
  DataDog: {
    applicationId: 'be95e592-35bc-40e9-b988-afe2dc50a8fd',
    clientToken: 'pubb8e661c4bdc027b091111a31694b4e2a',
  },
  FirebaseConfig: {
    apiKey: 'AIzaSyCV5mFTbmprSGAbmTY6TwmVxSfN_V8NX_k',
    authDomain: 'gustav-push-notifications.firebaseapp.com',
    databaseURL: 'https://gustav-push-notifications.firebaseio.com',
    projectId: 'gustav-push-notifications',
    storageBucket: 'gustav-push-notifications.appspot.com',
    messagingSenderId: '357973667805',
    appId: '1:357973667805:web:227040fe3a9532a9',
  },
  FirebaseVapidKey:
    'BJ14crqnvxYozqixVdYGcx1GasLNHBl9vc1pjTxtbc9Io9vuryEbq7t_WFSK0m6Q3f9JlIMURmHtog1fWoPIsoE',
  Mapbox: {
    username: 'bengerson',
    style: 'cjyirvris04jl1cqoshdghy7v',
    mobileStyle: 'ckm3lxqeo15jb17pghpq4fo0i',
    AccessToken:
      'pk.eyJ1IjoiYmVuZ2Vyc29uIiwiYSI6ImNqeWcwOHJnaTAyZ3YzbnJ3Zzd5cGlqejcifQ.-WFvS9x1LaCmU2Dm9qyBTg',
  },
  Bullhorn: {
    clientId: '0b51ba02-e5f2-4094-b335-bb63a5efc031',
  },
  People20: {
    dev: {
      baseUrl: 'https://recruiter.people20.net/GatewayDEV',
      partnerGuid: '8c272ac7-7400-40e9-a065-7ed252d609b1',
      subscriptionGuid: 'adb42709-01f4-4074-8bd5-88c058f942ec',
      apiKey: 'c50bca66-cf9f-458c-bb83-313dab5fa5c6',
    },
    production: {
      baseUrl: 'https://recruiter.people20.net/Gateway',
      partnerGuid: '8c272ac7-7400-40e9-a065-7ed252d609b1',
      subscriptionGuid: 'adb42709-01f4-4074-8bd5-88c058f942ec',
      apiKey: 'B49526FE-F53B-41AD-A2BE-36C4D658FC0D',
    },
  },
  ITServe: {
    signupUrl: 'https://itserve.glueup.com/membership/3748/apply',
    forgotPasswordUrl: 'https://itserve.glueup.com/account/recover',
  },
  Chargebee: {
    dev: {
      site: 'hellogustav-test',
      publishableKey: 'test_ta9cdcdY6tXWOigm7Ncd8PWEMtqRFCBeiJ6',
    },
    prod: {
      site: 'hellogustav',
      publishableKey: 'live_ETAQQTl9yUVlokrcdcdJ7cdZLwrUUS7rohU',
    },
  },
  // Feature flags below
  CandidatesEnabled: resolveFeatureFlag('CANDIDATES_ENABLED'),
  WorkOrdersEnabled: resolveFeatureFlag('WORK_ORDERS_ENABLED'),
  IntegrationFrameHeight: INTEGRATION_FRAME_HEIGHT,
};
