import styled from 'styled-components';

type FlagProps = {
  first: string;
  last: string;
};

export const Flag = styled.span<FlagProps>`
  &::before {
    font-family: 'Apple Color Emoji', 'Noto Color Emoji';
    content: ${({ first }) => `"\0${first}"`};
  }
  &::after {
    font-family: 'Apple Color Emoji', 'Noto Color Emoji';
    content: ${({ last }) => `"\0${last}"`};
  }
`;
