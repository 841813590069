import PropTypes from 'prop-types';

import { User } from './user';
import { Contact } from './contact';
import * as styled from './styles';

export const UserTooltip = (props) => {
  const { children, user, position, fixed, linksActive, isPublic } = props;
  const ContentEl = user.client_contact ? Contact : User;

  return (
    <styled.Tooltip
      fixed={fixed}
      delay={500}
      position={position}
      content={
        <ContentEl user={user} isPublic={isPublic} linksActive={linksActive} />
      }
    >
      {children}
    </styled.Tooltip>
  );
};

UserTooltip.propTypes = {
  children: PropTypes.node,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    position: PropTypes.string,
    avatar: PropTypes.string,
    color: PropTypes.string,
    last_activity_at: PropTypes.string,
    is_simple: PropTypes.bool,
    company: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    client_contact: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      client: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
  position: PropTypes.string,
  fixed: PropTypes.bool,
  linksActive: PropTypes.bool,
  isPublic: PropTypes.bool,
};

UserTooltip.defaultProps = {
  linksActive: true,
};
