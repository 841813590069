import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { find, get, isObject } from 'lodash';

import * as styled from './styles/toggle';

export const Toggle = function Toggle(props) {
  const {
    className,
    options,
    onChange,
    checked,
    disabled,
    size,
    color,
    icon,
    disabledIcon,
  } = props;
  const label = get(find(options, { checked }), 'label');

  const labelComponent = isObject(label) ? (
    <FormattedMessage {...label} />
  ) : (
    label
  );

  return (
    <>
      <styled.Toggle className={className} size={size} isOn={checked}>
        <input
          type="checkbox"
          onChange={() => !disabled && onChange(!checked)}
        />
        <styled.Slider isOn={checked} disabled={disabled} color={color} />
        {icon && (
          <styled.Icon
            icon={icon}
            weight="bold"
            toggleSize={size}
            isOn={checked}
            show={checked || !disabledIcon}
          />
        )}
        {disabledIcon && (
          <styled.Icon
            icon={disabledIcon}
            weight="bold"
            toggleSize={size}
            isOn={checked}
            show={!checked}
          />
        )}
      </styled.Toggle>
      {label && (
        <styled.Label
          key="label"
          size={size}
          disabled={disabled}
          onClick={() => !disabled && onChange(!checked)}
        >
          {labelComponent}
        </styled.Label>
      )}
    </>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'large', 'xlarge']),
  icon: PropTypes.string,
  disabledIcon: PropTypes.string,
};

Toggle.defaultProps = {
  options: [],
  disabled: false,
  size: 'large',
};
