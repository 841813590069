import { warn } from 'js-logger';
import { commitRef } from 'utils/node-env-vars';
import { TrackJsAPI } from 'components/scripts/trackjs';

export const promiseTypeSuffixes = ['_REQUEST', '_SUCCESS', '_ERROR'];
const [PENDING, FULFILLED, REJECTED] = promiseTypeSuffixes;

const isPendingSuffix = new RegExp(`${PENDING}$`, 'g');
const isFulfilledSuffix = new RegExp(`${FULFILLED}$`, 'g');
const isRejectedSuffix = new RegExp(`${REJECTED}$`, 'g');

export const isPending = (actionType) => actionType.match(isPendingSuffix);
export const isFulfilled = (actionType) => actionType.match(isFulfilledSuffix);
export const isRejected = (actionType) => actionType.match(isRejectedSuffix);

// Associate errors with user data
export const setUserContextForErrorReporting = (user, company) => {
  TrackJsAPI('configure', { userId: user.email });
  TrackJsAPI('addMetadata', 'id', user.id);
  TrackJsAPI('addMetadata', 'email', user.email);
  TrackJsAPI('addMetadata', 'firstName', user.first_name);
  TrackJsAPI('addMetadata', 'lastName', user.last_name);
  TrackJsAPI('addMetadata', 'companyId', company.id);
  TrackJsAPI('addMetadata', 'companyName', company.name);
  TrackJsAPI('addMetadata', 'companyType', company.company_type);
};

// Assign tags to events, which can later be used as a breakdown or quick access to finding related events
// Always attach git commit reference
export const setTagsContextForErrorReporting = (tags = {}) => {
  const tagsContext = {
    git_commit: commitRef(),
    ...tags,
  };

  Object.keys(tagsContext).forEach((key) => {
    TrackJsAPI('addMetadata', key, tagsContext[key]);
  });
};

// Explicitly capture and report potentially problematic code
export const trackException = (error, { level, extra, tags }) => {
  const payload = { error, level: level || 'error', extra, tags };

  warn('[ErrorLogging:captureException]', error, payload);

  TrackJsAPI('track', error);
};
