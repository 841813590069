import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Content } from 'components/text/Truncate/styles';

export const LongText = styled.div`
  & > :first-child,
  ${Content} > :first-child {
    margin-top: 0;
    white-space: normal;
    overflow-wrap: anywhere;
    hyphens: auto;
  }

  & > :last-child,
  ${Content} > :last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
    min-height: 2rem;
  }

  ul {
    list-style: disc;
  }

  ul,
  ol {
    margin: 0.6rem 0;
    padding-left: 2rem;
  }

  li {
    margin: 0.6rem 0;
  }

  em {
    font-style: italic;
  }

  blockquote {
    border-left: 2px solid ${Colors.textLighter};
    margin: 1rem 1rem 1rem 0;
    padding-left: 1rem;
    color: ${Colors.textLighter};
  }

  table {
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 1px solid ${Colors.outline};
  }

  th,
  td {
    padding: 0.4rem 2.4rem;
    text-align: center;
    vertical-align: middle;
  }

  th {
    background: ${Colors.tealLightest};
  }

  & > * > * > :first-child {
    margin-top: 0;
  }

  & > * > * > :last-child {
    margin-bottom: 0;
  }
`;
