export const isProduction = function isProduction() {
  return process.env.NODE_ENV === 'production';
};

export const isDevelopment = function isDevelopment() {
  return process.env.NODE_ENV === 'development';
};

export const isTest = function isTest() {
  return process.env.NODE_ENV === 'test';
};

export const isStorybook = function isStorybook() {
  return process.env.NODE_ENV === 'storybook';
};

export const commitRef = () => process.env.COMMIT_REF;
