import { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ReactReduxContext } from 'react-redux';

import { getInjectors } from './reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export const injectReducer = ({ key, reducer }) => (WrappedComponent) => {
  class ReducerInjector extends Component {
    // eslint-disable-next-line react/destructuring-assignment
    injectors = getInjectors(this.props.store);

    UNSAFE_componentWillMount() {
      this.injectors.injectReducer(key, reducer);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  ReducerInjector.WrappedComponent = WrappedComponent;

  ReducerInjector.propTypes = {
    store: PropTypes.object.isRequired,
  };

  const ReducerInjectorWithContext = (props) => {
    const { context } = props;
    const { store } = useContext(context || ReactReduxContext);

    return <ReducerInjector store={store} {...props} />;
  };

  ReducerInjectorWithContext.displayName = `withReducer(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return hoistNonReactStatics(ReducerInjectorWithContext, WrappedComponent);
};
