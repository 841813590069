import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, find, has, startCase, isEmpty } from 'lodash';

import theme from 'themes';

import { formHOC } from 'scenes/utils/form/hoc';
import { anyPropertyInvalid, getErrors } from 'components/utils/form-utils';
import { optionContext } from 'scenes/utils/form/options-context';

import { InputSearch } from 'components/form/input/InputSearch';
import { CheckboxList } from 'components/form/checkboxList';
import { FormLabel } from 'components/structure/form/styles/form';
import { Icon } from 'components/elements/icon';
import { Tooltip } from 'components/overlay/Tooltip';
import googleLogo from 'images/powered-by-google.png';

import i18n from '../utils/i18n';
import * as styled from '../styles/form';
import { companyObject } from '../utils/companyObject';
import { companyAsJSON } from '../utils/companyAsJson';
import { isEndClient as isEndClientFn } from '../utils/helpers';

@formHOC()
export class Form extends Component {
  constructor(props) {
    super(props);

    this.state = { keyword: '' };

    this.FormDefinition = props.formDefinition;
  }

  onChangeKeyword = (keyword) => {
    this.setState({ keyword });
  };

  getErrorKeys(formObject) {
    return getErrors(formObject, this.FormDefinition);
  }

  shouldSubmit(formObject) {
    return !anyPropertyInvalid(formObject, this.FormDefinition);
  }

  parseObject(object) {
    return companyObject(object);
  }

  objectToSave(objectForm) {
    return companyAsJSON(objectForm, this.FormDefinition);
  }

  inputChange(fieldKey, value) {
    const { inputChange } = this.props;

    if (fieldKey === 'billingAddress') {
      if (isEmpty(value)) {
        inputChange(fieldKey, {});
      } else {
        inputChange(fieldKey, value);
      }
    } else {
      inputChange(fieldKey, value);
    }
  }

  renderChecboxListControl(input) {
    const { formObject, shouldValidate, assocItems } = this.props;
    const { keyword } = this.state;

    return (
      <div>
        <FormLabel
          value={i18n[`label${startCase(input)}`]}
          required={this.FormDefinition[input].required}
          elementToRefer={this.FormDefinition[input].id}
        />

        <InputSearch
          value={keyword}
          onChange={this.onChangeKeyword}
          placeholder={i18n[`placeholder${startCase(input)}`]}
        />

        <CheckboxList
          items={assocItems}
          checked={formObject[input]}
          idSelector={this.FormDefinition[input].idSelector}
          nameSelector={this.FormDefinition[input].nameSelector}
          filterKeyword={keyword}
          scrollbarsHeight="20rem"
          onChange={(value) => this.inputChange(input, value)}
          shouldValidate={shouldValidate}
          unchecking
        />

        <styled.SelectedCount>
          <FormattedMessage
            {...i18n.selectedCount}
            values={{ count: formObject[input].length }}
          />
        </styled.SelectedCount>
      </div>
    );
  }

  render() {
    const {
      formObject,
      editObject,
      assocItems,
      members,
      saveForm,
      shouldValidate,
      onFetchPlaces,
    } = this.props;
    const isEndClient = isEndClientFn(editObject);

    const renderInput = (input) => has(this.FormDefinition, input);
    const contactPerson = find(members, { id: formObject.contactPerson });

    const googleLocationFooter = (
      <styled.LocationFooter>
        <img src={googleLogo} role="presentation" alt="" />
      </styled.LocationFooter>
    );

    return (
      <styled.Form>
        {renderInput('companySize') && (
          <styled.Fieldset
            context={this.FormDefinition.companySize}
            selected={formObject.companySize}
            shouldValidate={shouldValidate}
            fn={(value) => this.inputChange('companySize', value)}
          />
        )}
        {renderInput('revenueSize') && (
          <styled.Fieldset
            context={this.FormDefinition.revenueSize}
            selected={formObject.revenueSize}
            shouldValidate={shouldValidate}
            fn={(value) => this.inputChange('revenueSize', value)}
          />
        )}
        {renderInput('labels') && !isEndClient && (
          <styled.CheckboxListFieldset
            fullWidth
            includeIndicator
            inline={false}
            checkboxOnLeft={false}
            context={this.FormDefinition.labels}
            selected={formObject.labels}
            shouldValidate={shouldValidate}
            fn={(value) => this.inputChange('labels', value)}
          />
        )}
        {renderInput('about') && (
          <div>
            <styled.Paragraph marginBottom="1rem">
              <FormattedMessage {...i18n.descriptionAbout} />
            </styled.Paragraph>
            <styled.Fieldset
              context={this.FormDefinition.about}
              inputValue={formObject.about}
              shouldValidate={shouldValidate}
              fn={(value) => this.inputChange('about', value)}
            />
          </div>
        )}
        {renderInput('billingAddress') && (
          <styled.Fieldset
            context={this.FormDefinition.billingAddress}
            inputValue={get(formObject.billingAddress, 'name')}
            shouldValidate={shouldValidate}
            inputIconIndicator="MapPinFill"
            dropdownFooter={googleLocationFooter}
            dropdownFooterHeight={2}
            fnAutocomplete={(query, token) =>
              onFetchPlaces(query, token, '(cities)')
            }
            fn={(value) => this.inputChange('billingAddress', value)}
          />
        )}
        {renderInput('contactPerson') && (
          <div>
            <styled.Fieldset
              context={optionContext(
                this.FormDefinition.contactPerson,
                members
              )}
              selected={formObject.contactPerson}
              shouldValidate={shouldValidate}
              fn={(value) => this.inputChange('contactPerson', value)}
            />
            <styled.Label>
              <FormattedMessage {...i18n.labelContactPhone} />
              <Tooltip
                content={<FormattedMessage {...i18n.tooltipContactPhone} />}
              >
                <Icon icon="Info" color={theme.colors.primary} />
              </Tooltip>
            </styled.Label>
            <styled.Paragraph marginBottom="3rem">
              {get(contactPerson, 'phone_number')}
            </styled.Paragraph>
          </div>
        )}
        {renderInput('industry') && (
          <styled.Fieldset
            context={optionContext(this.FormDefinition.industry, assocItems)}
            selected={formObject.industry}
            shouldValidate={shouldValidate}
            fn={(value) => this.inputChange('industry', value)}
          />
        )}
        {renderInput('industries') &&
          this.renderChecboxListControl('industries')}
        {renderInput('departments') &&
          this.renderChecboxListControl('departments')}
        {renderInput('specializations') &&
          this.renderChecboxListControl('specializations')}
        <styled.Submit value={i18n.save} onClick={(event) => saveForm(event)} />
      </styled.Form>
    );
  }
}

/* eslint react/no-unused-prop-types:0 */
Form.propTypes = {
  formObject: PropTypes.object,
  editObject: PropTypes.object,
  shouldValidate: PropTypes.bool,
  inputChange: PropTypes.func,
  saveForm: PropTypes.func,

  formDefinition: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  assocItems: PropTypes.array,
  members: PropTypes.array,
  onFetchPlaces: PropTypes.func,
};
