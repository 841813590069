import PropTypes from 'prop-types';

import * as styled from './styles/fieldset';

export const ReplicateDoubleSided = function ReplicateDoubleSided(props) {
  const {
    className,
    label,
    secondLabel,
    inputDescription,
    includeIndicator,
    secondIncludeIndicator,
    buttonLabel,
    noLabelCount,
    noSeparator,
    required,
    secondRequired,
    shouldValidate,
    children,
    defaultValue,
    objValues,
    fn,
  } = props;

  const description = inputDescription ? (
    <styled.DescriptionLabel
      value={inputDescription}
      required={false}
      includeIndicator={false}
    />
  ) : null;

  const formLabelMargin = inputDescription ? '0 0 1rem' : '0 0 2rem';

  return (
    <styled.Fieldset className={className}>
      <styled.DoubleContainer alignItems="flex-start">
        <div>
          <styled.FormLabel
            margin={formLabelMargin}
            value={label}
            required={required}
            shouldValidate={shouldValidate}
            includeIndicator={includeIndicator}
          />

          {description}
        </div>

        <styled.FormLabel
          margin={formLabelMargin}
          value={secondLabel}
          required={secondRequired}
          shouldValidate={shouldValidate}
          includeIndicator={secondIncludeIndicator}
        />
      </styled.DoubleContainer>

      <styled.Replicate
        noLabelCount={noLabelCount}
        noSeparator={noSeparator}
        buttonLabel={buttonLabel}
        required={required}
        shouldValidate={shouldValidate}
        defaultValue={defaultValue}
        objValues={objValues}
        onChange={(valueStates) => fn(valueStates)}
      >
        {children}
      </styled.Replicate>
    </styled.Fieldset>
  );
};

ReplicateDoubleSided.propTypes = {
  children: PropTypes.node,
  defaultValue: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.shape({
      index: PropTypes.number,
      value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }),
  }),
  objValues: PropTypes.shape({
    key: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number,
        value: PropTypes.oneOfType([
          PropTypes.array,
          PropTypes.object,
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      })
    ),
  }),
  className: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  secondRequired: PropTypes.bool,
  includeIndicator: PropTypes.bool,
  secondIncludeIndicator: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  noLabelCount: PropTypes.bool,
  noSeparator: PropTypes.bool,
  fn: PropTypes.func,
};

ReplicateDoubleSided.defaultProps = {
  includeIndicator: true,
  secondIncludeIndicator: true,
  buttonLabel: 'add another',
};
