import { get, map, isEmpty, flatten, uniqBy } from 'lodash';
import type { FileRejection } from 'react-dropzone';

import i18nForm from 'scenes/utils/form/i18n';
import { openFlashMessage } from 'components/utils/flashMessages';

type Restrictions = {
  maxFileSize: number;
  acceptedFormats: string[];
  acceptedDimensions?: {
    min: { width: number; height: number };
    max: { width: number; height: number };
  };
  maxFiles?: number;
  custom?: I18nMessage;
};

type EnhancedFile = File & {
  dimensions: {
    width: number;
    height: number;
  };
};

type ValidationMessage = {
  detail: I18nMessage;
  values?: object;
};

export type AcceptedFile = {
  status: 'accepted';
  file: File;
  filename: string;
  ext: string;
};

export type RejectedFile = {
  status: 'rejected';
  file: File;
  filename: string;
  ext: string;
  error?: string;
  restrictions: Restrictions;
};

export type AcceptedOrRejectedFiles = {
  accepted: AcceptedFile[];
  rejected: RejectedFile[];
};

/**
 * @function acceptedOrRejected
 * @param {array} acceptedFiles
 * @param {array} rejectedFiles
 * @param {object} restrictions
 * @param {number} restrictions.maxFileSize
 * @param {string} restrictions.acceptedFormats
 * @return {array}
 */
/* eslint no-param-reassign:0 */
export function acceptedOrRejected(
  acceptedFiles: File[],
  rejectedFiles: FileRejection[],
  restrictions: Restrictions
): AcceptedOrRejectedFiles {
  const accepted = map(acceptedFiles, (file) => ({
    status: 'accepted' as const,
    file,
    filename: file.name,
    ext: file.name.split('.').pop() as string,
  }));

  const rejected = map(rejectedFiles, ({ file, errors }) => ({
    file,
    status: 'rejected' as const,
    error: get(errors, '0.code'),
    filename: file.name,
    ext: file.name.split('.').pop() as string,
    restrictions,
  }));

  return { accepted, rejected };
}

/**
 * @function rejectedFilesMessages
 * @param {array} files
 * @return {array}
 */
export function rejectedFilesMessages(
  files: RejectedFile[]
): ValidationMessage[] {
  return uniqBy(
    flatten(
      map(files, (file) => {
        const {
          restrictions: { custom, maxFileSize, maxFiles },
          error,
        } = file;

        switch (error) {
          case 'file-too-large':
            return [
              {
                detail: custom || i18nForm.uploadDocSizeError,
                values: {
                  max: `${maxFileSize / (1024 * 1024)}MB`,
                  total: files.length,
                },
              },
            ];

          case 'file-invalid-type':
            return [
              {
                detail: custom || i18nForm.uploadDocExtError,
                values: { ext: file.ext.toUpperCase(), total: files.length },
              },
            ];

          case 'file-dimensions-too-small':
            return [
              {
                detail: i18nForm.uploadDimensionsMinError,
              },
            ];

          case 'file-dimensions-too-high':
            return [
              {
                detail: i18nForm.uploadDimensionsMaxError,
              },
            ];

          case 'too-many-files':
            return [
              {
                detail: i18nForm.uploadFilesLimitError,
                values: { max: maxFiles },
              },
            ];

          default:
            return [];
        }
      })
    ),
    'detail.id'
  );
}

/**
 * @function returnOrNotifyUploadedFile
 * @param {array} files
 * @return {object | null}
 */
export function returnOrNotifyUploadedFile({
  accepted,
  rejected,
}: AcceptedOrRejectedFiles) {
  if (isEmpty(accepted) && isEmpty(rejected)) {
    return null;
  }
  const selected = accepted[0];
  if (selected) {
    return selected.file;
  }
  openFlashMessage(
    {
      context: rejectedFilesMessages(rejected),
      status: 'error',
    },
    { highlight: 'bold' }
  );
  return null;
}

/**
 * @function returnOrNotifyUploadedFiles
 * @param {array} files
 * @return {array | null}
 */
export function returnOrNotifyUploadedFiles({
  accepted,
  rejected,
}: AcceptedOrRejectedFiles) {
  if (isEmpty(accepted) && isEmpty(rejected)) {
    return null;
  }

  if (rejected.length > 0) {
    openFlashMessage(
      {
        context: rejectedFilesMessages(rejected),
        status: 'error',
      },
      { highlight: 'bold' }
    );
  }

  if (accepted.length > 0) {
    return accepted;
  }

  return null;
}

/* eslint func-names: 0 */
export function filesGetter(event: DragEvent, restrictions: Restrictions) {
  const files = event.dataTransfer
    ? event.dataTransfer.files
    : (event.target as HTMLInputElement).files;

  if (restrictions.acceptedDimensions) {
    const promises: Promise<File>[] = map(
      files,
      (file) =>
        new Promise((resolve): void => {
          const preview = URL.createObjectURL(file);
          const img = new Image();
          img.onload = function () {
            Object.defineProperty(file, 'dimensions', {
              value: { width: img.width, height: img.height },
            });
            resolve(file);
            URL.revokeObjectURL(preview);
          };
          img.onerror = function () {
            resolve(file);
            URL.revokeObjectURL(preview);
          };
          img.src = preview;
        })
    );

    return Promise.all(promises);
  }

  const promises: Promise<File>[] = map(
    files,
    (file) => new Promise((resolve) => resolve(file))
  );
  return Promise.all(promises);
}

export function customValidator(
  file: EnhancedFile,
  restrictions: Restrictions
) {
  const min = get(restrictions, 'acceptedDimensions.min');
  const max = get(restrictions, 'acceptedDimensions.max');
  const dim = file.dimensions;

  if (min && dim && (dim.width < min.width || dim.height < min.height)) {
    return {
      code: 'file-dimensions-too-small',
      message: 'Image dimensions too small',
    };
  }
  if (max && dim && (dim.width > max.width || dim.height > max.height)) {
    return {
      code: 'file-dimensions-too-high',
      message: 'Image dimensions too high',
    };
  }
  return null;
}
