import i18n from './i18n';

export const FlashDefinition = {
  createSuccess: {
    context: [{ detail: i18n.createSuccess }],
    status: 'success',
  },
  updateSuccess: {
    context: [{ detail: i18n.updateSuccess }],
    status: 'success',
  },
  deleteSuccess: {
    context: [{ detail: i18n.deleteSuccess }],
    status: 'success',
  },
};
