import { Component } from 'react';
import PropTypes from 'prop-types';

export const UpscopeAPI = (...args) => {
  if (window.Upscope) {
    window.Upscope.apply(null, args);
  }
};

/* eslint no-param-reassign: 0 */
/* eslint no-inner-declarations: 0 */
/* eslint prefer-rest-params: 0 */
/* eslint func-names: 0 */
export class Upscope extends Component {
  constructor(props) {
    super(props);

    const { apiKey } = props;

    (function (w, u, d) {
      if (typeof u !== 'function') {
        const i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Upscope = i;

        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://code.upscope.io/${apiKey}.js`;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        l();
      }
    })(window, window.Upscope, document);

    if (window.Upscope) {
      window.Upscope('init', {});
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return false;
  }
}

Upscope.propTypes = {
  apiKey: PropTypes.string,
};
