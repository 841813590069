import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { Overlay } from 'components/structure/overlay/overlay';

import * as styled from './styles';
import i18n from '../utils/i18n';

export const ReleaseUpdateComponent = ({
  onClick,
}: {
  onClick: VoidCallback;
}) => {
  const intl = useIntl();

  return (
    <Overlay noClose fitScreen>
      <Helmet title={intl.formatMessage(i18n.pageTitle)}>
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
      </Helmet>
      <styled.Wrapper>
        <styled.Image src="https://enroll-static-assets.s3.us-west-2.amazonaws.com/images/gears.png" />
        <styled.Header>
          <FormattedMessage {...i18n.title} />
        </styled.Header>
        <styled.Paragraph>
          <FormattedMessage {...i18n.subtitle} />
        </styled.Paragraph>

        <styled.Button onClick={onClick}>
          <FormattedMessage {...i18n.button} />
        </styled.Button>
      </styled.Wrapper>
    </Overlay>
  );
};
