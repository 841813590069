import { get, some, intersectionBy } from 'lodash';

export const isEndClient = ({ features, special_type: specialType }) => {
  const isSupplier = features.supplier;
  const isEmployer = features.employer;
  const isMsp = specialType === 'msp';

  return isEmployer && !isSupplier && !isMsp;
};

export const isVendorPartner = (company) =>
  company.affiliated.employer && company.affiliated.supplier;

export const companyType = (company) => {
  if (isEndClient(company)) {
    return 'endClient';
  }
  if (isVendorPartner(company)) {
    return 'vendorPartner';
  }

  return 'vendor';
};

export const isAffiliated = ({ affiliated }) =>
  affiliated.employer || affiliated.supplier;

export const shouldShowPerformance = (company, affiliation) => {
  if (company.affiliated[affiliation]) {
    return true;
  }

  const performance = get(
    company.performance,
    affiliation === 'employer' ? 'from' : 'to',
    {}
  );
  return some(performance, (value) => value !== null && value !== 0);
};

export const sameCommunity = (firstCommunities, secondCommunities) =>
  intersectionBy(firstCommunities, secondCommunities, 'id').length > 0;
