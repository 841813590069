import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

export const DotTyping = styled.div`
  position: relative;
  left: -9999px;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: ${Colors.grape};
  color: ${Colors.grape};
  box-shadow: 9984px 0 0 0 ${Colors.grape}, 9999px 0 0 0 ${Colors.grape},
    10014px 0 0 0 ${Colors.grape};
  animation: dot-typing 1.5s infinite linear;

  @keyframes dot-typing {
    0% {
      box-shadow: 9984px 0 0 0 ${Colors.grape}, 9999px 0 0 0 ${Colors.grape},
        10014px 0 0 0 ${Colors.grape};
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 ${Colors.grape}, 9999px 0 0 0 ${Colors.grape},
        10014px 0 0 0 ${Colors.grape};
    }
    33.333% {
      box-shadow: 9984px 0 0 0 ${Colors.grape}, 9999px 0 0 0 ${Colors.grape},
        10014px 0 0 0 ${Colors.grape};
    }
    50% {
      box-shadow: 9984px 0 0 0 ${Colors.grape}, 9999px -10px 0 0 ${Colors.grape},
        10014px 0 0 0 ${Colors.grape};
    }
    66.667% {
      box-shadow: 9984px 0 0 0 ${Colors.grape}, 9999px 0 0 0 ${Colors.grape},
        10014px 0 0 0 ${Colors.grape};
    }
    83.333% {
      box-shadow: 9984px 0 0 0 ${Colors.grape}, 9999px 0 0 0 ${Colors.grape},
        10014px -10px 0 0 ${Colors.grape};
    }
    100% {
      box-shadow: 9984px 0 0 0 ${Colors.grape}, 9999px 0 0 0 ${Colors.grape},
        10014px 0 0 0 ${Colors.grape};
    }
  }
`;
