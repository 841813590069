import PropTypes from 'prop-types';

import { Icon } from 'components/elements/icon';
import { Tooltip } from 'components/overlay/Tooltip';

import * as styled from './styles/descriptionList';

export const DescriptionList = function DescriptionList({ items, ...props }) {
  /* eslint-disable react/no-array-index-key */
  const ListItems = items?.reduce(
    (allItems, { term, description, tooltip, icon }, index) => [
      ...allItems,
      <styled.Term key={`term-${index}`} {...props}>
        {term}
      </styled.Term>,
      <styled.Description key={`description-${index}`} {...props}>
        {icon && <Icon icon={icon} size="large" />}
        {description}
        {tooltip && (
          <Tooltip content={tooltip}>
            <styled.TooltipIcon icon="Info" size="normal" />
          </Tooltip>
        )}
      </styled.Description>,
    ],
    []
  );

  return <styled.List {...props}>{ListItems}</styled.List>;
};

DescriptionList.propTypes = {
  isEqualWidth: PropTypes.bool,
  isBordered: PropTypes.bool,
  isVertical: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
      ]),
      slug: PropTypes.string,
      section: PropTypes.string,
      tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      icon: PropTypes.string,
    })
  ).isRequired,
};

DescriptionList.defaultProps = {
  isEqualWidth: false,
  isBordered: true,
  isVertical: false,
};
