import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 2rem;
  overflow: hidden;
`;

export const List = styled.div`
  position: relative;
  transform: ${({ index }) => `translate(0, ${-2 * index}rem)`};
  transition: transform 0.3s ease-in-out;
`;

export const Text = styled.div`
  height: 2rem;
  line-height: 2rem;
  text-align: center;
`;
