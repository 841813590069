import { request } from './request';

export { beacon } from './beacon';

export function httpGet(path) {
  return request({
    method: 'get',
    url: path,
  });
}

export function httpGetBlob(path) {
  return request({
    method: 'get',
    url: path,
    responseType: 'blob',
  });
}

export function httpPost(path, data, headers = {}) {
  return request({
    method: 'post',
    url: path,
    headers,
    data,
  });
}

export function httpPostFormData(path, data, headers = {}) {
  return request({
    method: 'post',
    url: path,
    headers,
    data,
  });
}

export function httpPostBlob(path, data, headers = {}) {
  return request({
    method: 'post',
    url: path,
    headers,
    data,
    responseType: 'blob',
  });
}

export function httpPut(path, data, headers = {}) {
  return request({
    method: 'put',
    url: path,
    headers,
    data,
  });
}

export const httpUploadPut = (path, data, headers = {}) =>
  httpPut(path, data, { ...headers, 'Content-Type': data.type });

export function httpPatch(path, data, headers = {}) {
  return request({
    method: 'patch',
    url: path,
    headers,
    data,
  });
}

export function httpDelete(path, data, headers = {}) {
  return request({
    method: 'delete',
    url: path,
    headers,
    data,
  });
}
