export const COUNTRIES_REQUEST = 'gustav/locations/countries/FETCH_REQUEST';
export const COUNTRIES_ERROR = 'gustav/locations/countries/FETCH_ERROR';
export const COUNTRIES_SUCCESS = 'gustav/locations/countries/FETCH_SUCCESS';

export const STATES_REQUEST = 'gustav/locations/states/FETCH_REQUEST';
export const STATES_SUCCESS = 'gustav/locations/states/FETCH_SUCCESS';
export const STATES_ERROR = 'gustav/locations/states/FETCH_ERROR';

export const PLACES_REQUEST = 'gustav/locations/places/FETCH_REQUEST';
export const PLACES_SUCCESS = 'gustav/locations/places/FETCH_SUCCESS';
export const PLACES_ERROR = 'gustav/locations/places/FETCH_ERROR';
