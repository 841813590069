import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.CompanyProfile.PremiumFeature.header',
    defaultMessage: 'Your notes & documents',
  },
  description: {
    id: 'app.containers.CompanyProfile.PremiumFeature.description',
    defaultMessage: 'Store your own notes and documents on vendor profiles',
  },
  linkMore: {
    id: 'app.containers.CompanyProfile.PremiumFeature.linkMore',
    defaultMessage: 'Learn more',
  },

  labelDocuments: {
    id: 'app.containers.CompanyProfile.PremiumFeature.labelDocuments',
    defaultMessage: 'Documents',
  },
  labelNotes: {
    id: 'app.containers.CompanyProfile.PremiumFeature.labelNotes',
    defaultMessage: 'Notes',
  },
  feature1: {
    id: 'app.containers.CompanyProfile.PremiumFeature.feature1',
    defaultMessage: 'Upload your documents to vendor profiles.',
  },
  feature2: {
    id: 'app.containers.CompanyProfile.PremiumFeature.feature2',
    defaultMessage: 'Store files such as MSAs, NDAs or W9s all in one place.',
  },
  feature3: {
    id: 'app.containers.CompanyProfile.PremiumFeature.feature3',
    defaultMessage: 'Store your notes on vendor profiles.',
  },
  feature4: {
    id: 'app.containers.CompanyProfile.PremiumFeature.feature4',
    defaultMessage: 'Share information that is only visible to your team.',
  },

  buttonUpgrade: {
    id: 'app.containers.CompanyProfile.PremiumFeature.buttonUpgrade',
    defaultMessage: 'Upgrade to Pro',
  },
});
