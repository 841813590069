import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import {
  Button as ButtonBase,
  BUTTON_SIZES,
  buttonSizeMixin,
} from 'components/elements/button';
import { ButtonLink } from 'components/elements/styles/button';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';

export const Header = styled.h3`
  margin: 0;
  height: 5.4rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  color: ${Colors.tealDark};
  background-color: ${Colors.headerBG};

  & > span {
    margin-left: 2rem;
  }

  & > ${ButtonLink} {
    ${(props) => buttonSizeMixin({ ...props, size: BUTTON_SIZES.medium })};
  }
`;

export const HeaderLink = styled(SmartLinkBase)`
  margin-left: auto;
  color: ${Colors.tealDark};
  text-decoration: underline;
`;

export const Container = styled.div`
  padding: 2rem 3rem;
  text-align: center;
`;

export const Subheader = styled.h3`
  margin: 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${Colors.outline};
`;

export const Columns = styled.div`
  display: flex;
  margin-top: 3rem;
`;

export const Col = styled.div`
  flex: 0 0 50%;
`;

export const Cell = styled.div``;

export const Image = styled.img`
  height: 8.2rem;
`;

export const Label = styled.div`
  margin: ${({ margin = '1rem 0 0 0' }) => margin};
  font-weight: 500;
  line-height: 2.8rem;
`;

export const List = styled.ul`
  margin: 0.6rem 0 2rem 0;
  padding-right: 4rem;
  text-align: left;
  list-style: disc;
  border-right: ${({ bordered }) =>
    bordered ? `1px solid ${Colors.outline}` : '0'};

  & > li:not(:last-child) {
    margin-bottom: 1.4rem;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 2rem;
  text-align: center;
  border-top: 1px solid ${Colors.outline};
`;

export const Button = styled(ButtonBase).attrs({
  size: BUTTON_SIZES.medium,
})`
  padding-top: 0.4rem;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.4rem;
  }
`;
