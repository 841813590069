/* eslint no-undef:0 */
import { gustavTheme as gustav } from 'themes/gustav/gustav';
import { candidatelyTheme as candidately } from 'themes/candidately/candidately';
import { get, includes, forOwn } from 'lodash';

const themes = {
  gustav,
  candidately,
};

function matchHostnameToTheme() {
  let matchedTheme = false;
  forOwn(themes, (themeConf, theme) => {
    const themeDomain = get(themeConf, 'domain').toUpperCase();
    const themeAdditionalDomains = get(
      themeConf,
      'additionalDomains',
      []
    ).map((d) => d.toUpperCase());
    const domain = window.location.hostname.toUpperCase();
    if (
      (themeDomain && domain === themeDomain) ||
      includes(themeAdditionalDomains, domain)
    ) {
      matchedTheme = theme;
    }
  });
  return matchedTheme;
}

export const THEME = matchHostnameToTheme() || 'candidately';
export default themes[THEME];
