import styled from 'styled-components';

import { Icon as IconBase } from 'components/elements/icon';
import { Button as ButtonBase } from 'components/elements/button';

export const ButtonArrowIcon = styled(IconBase)`
  margin-left: 1.5rem;
`;

export const DropdownButton = styled(ButtonBase)`
  border-radius: 10rem;
  height: 3rem;
  padding: 1.5rem 1.4rem;
  font-weight: 600;
  margin-right: 3rem;
`;

export const DropdownWrapper = styled.div`
  display: inherit;
`;
