import PropTypes from 'prop-types';

import * as styled from './styles';

export const TextSmartLink = function TextSmartLink(props) {
  return <styled.TextSmartLink {...props} />;
};

TextSmartLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
  underline: PropTypes.bool,
  target: PropTypes.string,
  children: PropTypes.node,
};
